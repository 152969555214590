.dln-delonghi {
  & .dln-shareWishList {
    padding: 0;

    & .dln-popup__head {
      padding: 17px 19px 40px 19px;

      & .dln-btnIcon {
        margin-bottom: 40px;
      }
      &Title {
        @include fontRoman;
        max-width: none;
        line-height: 32px;
        font-size: REM(26px);
        text-align: left;
        padding: 0 123px;
        margin: 0;
        box-sizing: border-box;
      }
    }
    &__inputs {
      margin-bottom: 87px;

      & .dln-inputs:not(.dln-textarea) {
        width: calc(100% - 18px - 124px);
        max-width: none;
        margin: 0 18px 0 0;
      }
      & .dln-btn {
        width: 124px;
        min-width: initial;
        padding: 0 15px;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-shareWishList {
      padding: 0 20px 0 20px;

      & .dln-popup__head {
        padding: 35px 5px 25px 5px;

        & .dln-btnIcon {
          margin-bottom: 156px;
        }
        &Title {
          line-height: 23px;
          // @include fontBold;
          font-size: REM(21px);
          text-align: center;
          padding: 0 15px;
        }
      }
      &__inputs {
        & .dln-inputs:not(.dln-textarea) {
          width: calc(100% - 10px - 100px);
          margin: 0 10px 0 0;
        }
        & .dln-btn {
          width: 100px;
          padding: 0;
        }
      }
    }
  }
}

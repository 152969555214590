.dln-category-browser {

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
		width: 100%;
		justify-content: center;
		//max-width: 1080px;
		height: auto;
		padding-top: 88px;
		margin: 0 0 64px 0;
		margin-right: auto;
		margin-left: auto;

		& > .dln-wrapper {
			width: 100%;
			max-width: 1080px;
		}
	}

	&__header {
		margin-bottom: 50px;

		&--title {
			font-size: 22px;
			line-height: 26px;
			@include fontBold;
		}

		&--text {
			font-size: 16px;
			line-height: 20px;
		}
	}

	&__breadcrumbs {
		@media only screen and (max-width: map-get($grid-breakpoints, sm)){
			:not(:last-child) {
				display: none;
			}
		}
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
		width: 100%;
		height: auto;

		margin-bottom: 50px;

		& .dln-breadCrumbSel {
			margin-right: 25px;
			&__text {
				display: block;
				font-size: 14px;
				line-height: 20px;
			}
			&__arrow--selected {
				@media only screen and (max-width: map-get($grid-breakpoints, sm))
				{
					display: none;
				}				
			}
		}
		& .dln-select {
			margin: 0;
			margin-right: 25px;
			&__value {
				font-size: 14px;
				line-height: 20px;
			}

			.dln-select__container.dln-select--has-value {
				.dln-select__option {
					&:first-child {
						color: $brand-04;
					}
				}
			}
		}
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		height: auto;

		& .dln-categoryBox {
			width: 20%;
			max-width: none;
			border: 1px solid;
			border-color: transparent;
			padding-bottom: 0;
			padding-bottom: 10px;
			margin: 0 0 22px 0;

			display: flex;
			flex-direction: column;

			&__title {
				border-bottom: 1px solid $ui-01;
				padding-bottom: 12px;

				display: flex;
				flex: 1;

				display: block;
				text-align: left;
			}
			&:hover {
				border-color: $brand-02;
			}
		}
	}
}



.dln-kenwood {
	.dln-category-browser { 
			padding-top: 0;
			margin-bottom: 70px;

		 .dln-wrapper {
			border-top: 1px solid $ui-01;
			border-bottom: 1px solid $ui-01;
			padding-top: 70px;
			padding-bottom: 48px;

			&__header {
				&--title {
					@include fontSemibold;
					line-height: 24px;
					font-size: 20px;
					text-align: center;
					margin-bottom: 46px;
				}
				&--text {
					@include fontBold;
					height: 24px;
					line-height: 24px;
					font-size: 20px;
					color: $ui-07;
					margin-right: 7px;
				}
			}

			&__category {
				.dln-categoryBox {
					border: none;
					
					.dln-categoryBox__title {
						line-height: 16px;
						text-align: center;
						border-bottom: none;
						padding-bottom: 0;
						margin-top: 10px;
					}
				}
			}
    }
  }
}

.dln-braun{
	.dln-category-browser__breadcrumbs { 
		.dln-breadCrumbSel {
			&__text {
				&--selected {
					@include fontBold;
					color: $brand-04;
				}
			}

			&__arrow--selected {
				top:2px;
			}
		}
	}
}
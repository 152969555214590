.dln-kenwood {
    & .dln-ordersHist {

        &__title {
            @include fontExtra;
            font-weight: 400;
            font-size: 28px;
        }
        & .dln-tabs__content#dln-ordersHist--openOrders {
            & .dln-ordersHist__title {
                line-height: 32px;
                font-size: 28px;
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-ordersHist {
            & .dln-title {
                line-height: 40px;
                font-size: 24px;
                margin: 32px 0 24px 0;
            }
            &__title {
                line-height: 32px;
                font-size: 24px;
                text-align: center;
            }
            & .dln-tabs__content#dln-ordersHist--openOrders {
                & .dln-ordersHist__title {
                    line-height: normal;
                    font-size: 24px;
                }
            }
        }
    }
}
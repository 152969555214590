.dln-customerSupport {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    min-height: calc(100vh - 90px);
    /* background-color: $brand-03; */
    overflow: hidden;

    &__inner {
        width: 100%;
        margin: 0 auto;
    }

    & .dln-wrapper {
        width: 100%;
        max-width: none;

        > div {
            max-width: 1240px;
        }

        &--fullWidth {
            background: $ui-03;
            padding-top: 48px;
            padding-bottom: 32px;
        }
        &--content {

            .dln-customerSupport__inner {
                padding-top: 32px;
                padding-bottom: 48px;
                border-bottom: 1px solid $ui-01;
                h2 {
                    font-size: 32px;
                }
            }
        }
    }
    &__head {
        width: 100%;
        &Title {
            display: block;
            width: 100%;
            height: auto;
            line-height: 56px;
            font-size: 44px;
            @include fontBold;
        }
        & .dln-customerSupport__title {
            margin: 0;
        }
        &.dln-wrapper {
            @media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: 1340px) {
                & {
                    margin: 0;
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }
        
            @media only screen and (max-width: map-get($grid-breakpoints, md)) {
                & {
                    margin: 0;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
    &__title,
    &__subtitle {
        display: block;
        width: 100%;
        height: auto;
        line-height: 32px;
        font-size: 26px;
        @include fontBold;
        margin: 0 0 16px 0;
    }
    &__subtitle {
        line-height: 26px;
        font-size: 22px;
        margin: 0 0 24px 0;
    }
    &__content {
        width: 100%;
        line-height:150%;
        margin: auto;

        ul,
        ol {
            padding-inline-start: 26px;
            margin-block-end: 1em;
        }

        li {
            line-height: 150%;
            list-style-position: inside;
        }

        p {
            margin-block-start: 1em;
            margin-block-end: 1em;
        }

        h2 {
            font-size: REM(22px);
            line-height: 125%;
            color: var(--brand-04);
        }

        h3 {
            font-size: REM(18px);
            line-height: 200%;
            color: var(--brand-04);
        }

        h4 {
            margin: 0 0 16px;
        }

        table,
        td,
        th {
            border: 1px solid var(--ui-04);
            text-align: left;
            padding: 8px;
            border-collapse: collapse;
        }

        td a,
        p a,
        li a {
            color: inherit;
        }
    }
    &__searchWrap,
    &__contentWrap,
    &__quickLinksWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        > .dln-wrapper {
            max-width: map-get($grid-breakpoints, xl);
        }
    }

    .dln-quickLinks--slider {
        padding-top: 48px;
        padding-bottom: 72px;
    }

    & .dln-customerSupport__searchWrapper {
        padding-top: 0;
        padding-bottom: 0;

        & .dln-customerSupport__inner {
            border: 0;
            padding-top: 29px;
            padding-bottom: 28px;
        }

        & .dln-inputs--searchBar {
            width: 50%;
            margin: 0;

            .dln-inputs__lbl {
                margin-left: 28px;
                display: block;
                transform: none;
            }

            &.dln-inputs--active {
                .dln-inputs__lbl {
                    display: none;
                }
            }
        }
    }
    &__search {
        &Title {
            @include fontBold;
            line-height: 32px;
            font-size: 32px;
            margin: 0 0 26px 0;
        }
    }
}
.dln-delonghi {
  .dln-checkout {
    margin-top: 95px;

    &__aside {
      .dln-orderSumm {
        &__itemDetails {
          .prices {
            display: flex;

            .realPrice {
              .dln-price--discounted {
                color: $ui-02;
              }
            }

            .dln-price--oldPrice {
              margin: 0 9px 0 0;
            }
          }
        }
      }
    }

    .dln-accordion--payment .dln-accordion__title {
      color: $ui-02;

      &.dln-accordion__title--active {
        color: $brand-04;
      }
    }

    .dln-checkbox
      .dln-checkbox__input:checked
      + .dln-checkbox__inputPlaceholder {
      border-color: var(--brand-01);
      border-radius: 2px;

      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        font-size: 11px;
        background-color: var(--brand-01);
        color: #fff;
        top: 50%;
        left: 50%;
      }
    }

    &.noGapHeader {
      margin-top: 0;
    }
    & > .dln-wrapper {
      & > .dln-title {
        @include fontSemibold;
        line-height: 52px;
        font-size: REM(50px);
        margin-bottom: 22px;
      }
    }

    &__content {
      > div:first-child {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
      .dln-accordion {
        &--showInfo {
          .dln-checkout__dataResult {
            .mailInfo {
              p {
                padding: 9px;
                &:nth-child(2) {
                  background-color: $ui-05;
                }
              }
            }
          }
        }

        .dln-radioBtn__lbl:after {
          background-color: var(--brand-01);
        }

        &--showInfo .dln-accordion__title .dln-btn {
          color: var(--brand-01);

          i {
            color: var(--brand-01);
          }
        }

        &__content {
          border-top: 1px solid $ui-01;

          .dln-checkout__form {
            .sectionTitle {
              margin-bottom: 17px;
              line-height: 19px;
            }
          }
        }

        &--shipping-information {
          .dln-accordion__content {
            .dln-checkout__form {
              > div {
                &:nth-last-child(1),
                &:nth-last-child(2),
                &:nth-last-child(3),
                &:nth-last-child(4),
                &:nth-last-child(6),
                &:nth-last-child(7) {
                  @media only screen and (max-width: 599px) {
                    width: 100% !important;
                    margin: 0 0 30px 0;
                  }

                  width: 45% !important;
                }
              }
              .dln-address_info {
                height: 40px;
                align-items: center;

                .dln-tooltip {
                  display: flex;

                  &__anchor {
                    margin-left: 5px;
                  }
                }
              }
            }

            .dln-btn {
              background: $brand-01 !important;
            }
          }

          &.dln-accordion--open {
            .dln-accordion__content {
              border-top-color: transparent;
              margin-top: 0;
            }
          }

          .dln-checkout__dataResult {
            margin-bottom: 40px;
          }

          .dln-checkout__formData {
            padding: 32px 20px;

            .dln-checkout__formDataHead .dln-btn--link {
              color: var(--brand-01);
            }

            .dln-checkout__formValue {
              line-height: 18px;
              font-size: REM(14px);
            }

            & > p:first-child {
              & .dln-checkout__formValue {
                &:first-child {
                  @include fontBold;
                }
              }
            }
          }
          & .dln-chips--iconinside {
            padding: 14px 16px;

            & .dln-icn {
              color: $brand-04;
            }
            & .dln-chips__content {
              & span {
                display: inline-block;
                min-height: 18px;
                line-height: 18px;
                font-size: REM(14px);
                color: $ui-02;
              }
            }
          }
        }
        &--shipping-method {
          .dln-checkout__dataResult {
            .dln-checkout__formData {
              padding: 20px;

              .dln-checkout__formValue {
                line-height: 18px;
                font-size: REM(14px);
              }

              p:first-child {
                .dln-checkout__formValue:first-child {
                  @include fontBold;
                }
              }
            }
          }

          .dln-checkout__formField {
            &.isChecked {
              border: 1px solid var(--brand-01);
            }

            .dln-radioBtn {
              &__lbl {
                &::after {
                  top: 11px;
                }
              }
            }
          }
        }

        &--payment {
          .dln-invoiceRadio {
            .dln-radioBtn__lbl:after {
              background-color: var(--brand-01);
              top: 4px;
              left: 4px;
            }
          }

          .dln-checkout__formField {
            margin-bottom: 15px;

            .dln-checkout__formData {
              &Head {
                .dln-btn {
                  color: $brand-01;
                }
              }
            }

            &--invoice {
              @media only screen and (max-width: 599px) {
                width: 100%;
              }
              display: flex;
              flex-direction: column;
              padding: 0;
              margin-bottom: 0px;

              .dln-checkbox {
                @media only screen and (max-width: 599px) {
                  margin-bottom: 18px !important;
                }
                margin-bottom: 27px !important;

                &__wrap {
                  .dln-checkbox__label {
                    padding-left: 0;
                  }
                }
              }

              .dln-checkout__paragraph {
                color: #000000;
              }

              .dln-checkout__formField {
                &--fiscalCode__row {
                  padding: 0;
                  margin-bottom: 0;
                  width: 40%;
                }
              }

              &--fiscalCode__ita {
                display: flex;
                flex-direction: column;
                padding: 0;

                .dln-checkout {
                  &__generalities,
                  &__birthPlace {
                    @media only screen and (max-width: 599px) {
                      display: inline-block;
                      width: 100%;
                    }
                    display: flex;
                    width: 75%;
                  }

                  &__birthPlace {
                    margin-bottom: 32px;
                  }

                  &__third {
                    @media only screen and (max-width: 599px) {
                      display: inline-block;
                      width: 100%;
                      margin-bottom: 32px;
                    }
                    display: flex;
                    width: 50%;

                    & div:nth-child(2) {
                      & label {
                        top: 12px;
                      }
                      .dln-select__lbl .dln-select__lbl--error {
                        top: auto;
                      }
                    }
                  }

                  &__fiscalCode {
                    @media only screen and (max-width: 599px) {
                      width: 100%;
                    }
                    width: 27%;
                  }
                }
              }
            }

            &--billingAddress {
              .dln-checkbox {
                &__wrap {
                  padding: 0;
                }
              }

              .dln-checkout__form {
                > div {
                  &:nth-last-child(1),
                  &:nth-last-child(2),
                  &:nth-last-child(3),
                  &:nth-last-child(4),
                  &:nth-last-child(6),
                  &:nth-last-child(7) {
                    @media only screen and (max-width: 599px) {
                      width: 100% !important;
                      margin: 0 0 30px 0;
                    }

                    width: 45% !important;
                  }

                  &:nth-last-child(3) {
                    .dln-select__lbl {
                      &--error {
                        top: auto;
                      }
                    }
                  }
                }
                .dln-address_info {
                  height: 40px;
                  align-items: center;
  
                  .dln-tooltip {
                    display: flex;
  
                    &__anchor {
                      margin-left: 5px;
                    }
                  }
                }
              }
            }

            &--billingAddress {
              .dln-guestChoiceForm {
                .row {
                  margin-bottom: 21px;
                }

                .msg {
                  margin-bottom: 17px;
                }

                div[class*='--oneThird'] {
                  @media only screen and (max-width: 599px) {
                    width: 100% !important;
                    margin: 0 0 30px 0;
                  }

                  width: calc(33.333% - 13.333px);
                  max-width: none;
                  margin: 0 20px 32px 0;
                }
              }
            }

            &.isChecked {
              border: 1px solid var(--brand-01);
            }
          }

          .dln-checkout__dataResult {
            .dln-checkout__formData {
              padding: 20px;

              .dln-checkServiceBox__cardItem {
                margin-bottom: 12px;
              }

              .dln-checkout__formValue {
                line-height: 18px;
                font-size: REM(14px);
              }

              p {
                &:first-of-type {
                  & .dln-checkout__formValue:first-child {
                    @include fontBold;
                  }
                }

                &:last-of-type {
                  margin-bottom: 32px;
                }
              }

              .dln-checkout__formField {
                .dln-inputs--securityCode {
                  width: calc(100% - 40px - 98px);
                  max-width: none;
                  margin: 0;
                }

                .dln-btn--icon {
                  margin: 0 0 0 40px;

                  .dln-icn {
                    font-weight: bold;
                  }

                  .dln-btn__lbl {
                    @include fontBold;
                    margin-left: 4px;
                  }
                }
              }
            }
            
            .dln-checkout__defaultCard {
              .dln-btn {
                color: var(--brand-01);
                &__lbl {
                  font-weight: normal;
                }
              }
            }
          }

          .dln-accordion__content--submit {
            .dln-checkout__paragraph {
              line-height: 18px;
              font-size: REM(14px);
              margin-bottom: 20px;

              & > a {
                @include fontBook;
                color: $ui-02;
              }
            }
          }
        }

        #oneyPlaceholder {
          margin-top: 25px;
          margin-bottom: 35px;
        }
      }

      & > .dln-accordion--access-information .dln-accordion__content {
        padding-top: 40px;
        border-top: none;
      }
    }

    &__innerColTitle {
      @include fontSemibold;
      line-height: 23px;
      font-size: REM(21px);
    }
    & .dln-loginForm {
      & + .dln-btn {
        margin-top: 24px;
      }
    }
    &__noticeBox {
      padding-top: 64px;

      & > p {
        line-height: 24px;
        font-size: REM(18px);
        color: $brand-04;
        margin-bottom: 34px;
      }
      & .dln-noticeBox {
        max-width: none;
        box-sizing: border-box;

        &::after {
          display: none;
        }
        &:first-of-type {
          border-right: 1px solid $ui-01;
        }
      }
    }
    &__services {
      & .dln-checkServiceBox {
        &:first-child {
          order: 2;
        }
        &:nth-child(2) {
          order: 1;
        }
        &:nth-child(3) {
          order: 3;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-checkout {
      margin-top: 62px;

      & > .dln-wrapper {
        &:first-child {
          padding-bottom: 0;
        }
      }
      &__aside {
        & .dln-orderSumm {
          &__head {
            & .dln-icn:not(.dln-icn--arrow-down) {
              margin-right: 18px;
            }
            & .dln-orderSumm__title {
              & + .dln-icn {
                width: 16px;
                height: 16px;
                margin-left: 11px;
              }
            }
          }
          &__headPriceLbl {
            font-size: REM(19px);

            & b {
              @include fontBook;
            }
          }
        }
      }

      &__content {
        .dln-accordion {
          &--access-information {
            padding-bottom: 34px;

            .dln-accordion__title {
              padding-top: 20px;
            }

            .dln-accordion__content {
              padding-top: 24px;
              margin-top: 9px;

              .dln-checkout__innerCol {
                .dln-checkout__innerColTitle {
                  @include fontMedium;
                  line-height: 20px;
                  font-size: REM(18px);
                  margin-top: 0;
                }
              }
            }
          }

          &--shipping-information {
            .dln-accordion__content {
              .dln-btn {
                width: 100%;
              }
            }
          }
        }
      }
      &__services {
        & .dln-checkServiceBox {
          &:last-child {
            margin-bottom: 0 !important;
          }
        }
      }
      &__aside {
        &--secondary {
          & .dln-checkout__noticeBox {
            padding-bottom: 18px;

            & > p {
              margin-bottom: 7px;
              font-size: REM(16px);
              line-height: 24px;
            }
            & .dln-noticeBox {
              width: 140px;
              border: none;
              border-bottom: 1px solid $ui-01;
              padding: 14px 0 !important;
              margin: 0 calc(50% - 70px);
              box-sizing: content-box;

              & p {
                margin-bottom: 21px;
              }
              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

// Italian Tax Code Form
.dln-checkout__formField--fiscalCode__ita {
  .dln-select {
    max-width: none;
  }

  .dln-checkout__birthPlace {
    .dln-select:first-of-type {
      @media only screen and (max-width: 599px) {
        margin-bottom: 35px;
      }
    }
  }

  @media only screen and (min-width: 600px) {
    .dln-checkout__generalities,
    .dln-checkout__birthPlace {
      width: 100% !important;
      .dln-inputs,
      .dln-select {
        width: 285px;
        margin-right: 0 !important;
      }

      .dln-inputs:first-of-type,
      .dln-select:first-of-type {
        margin-right: 20px !important;
      }
    }

    .dln-checkout__third {
      width: 100% !important;
      .dln-inputs {
        width: 186px;
        margin-right: 20px !important;
      }

      .dln-select {
        width: 183px;
      }
    }

    .dln-checkout__fiscalCode {
      width: 100% !important;
      .dln-inputs {
        width: 305px;
      }
    }
  }
}

.dln-addressBox {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    border: 1px solid $ui-01;
    background-color: $brand-03;
    padding: 20px 20px 20px 20px;

    &__container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        position: relative;
        height: 100%;
    }

    &__data {
        display: block;
        width: 100%;
        height: auto;
        line-height: REM(22px);
        font-size: REM(15px);
        color: $brand-04;
        position: relative;

        &:last-of-type {
            margin: 0 0 16px 0;
        }
    }
    & .dln-checkbox {
        margin: 0 !important;

        &__wrap {
            padding: 0 0 0 21px;
            height: 24px;
        }

        &__label {
            text-align: left;
        }

        &__inputPlaceholder {
            width: 22px;
            height: 22px;

            &::after {
                top: 0px;
                left: 0px;
                width: 20px !important;
                height: 20px;
                content: 'à';
                @include iconspseudoelements;
                color: var(--brand-03);
            }
        }

        .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
            @include fontRoman;
            color: $brand-02;
        }
    }
    & .dln-btn.dln-btn--link {
        display: inline-flex;
        height: 15px;
        line-height: REM(16px);
        padding: 0 0 0 0;
    }
    & .dln-btn.dln-btn--edit {
        margin: 0 0 0 auto;

        &::after {
            content: '';
            display: inline-flex;
            align-self: center;
            width: 1px;
            height: 16px;
            background-color: $brand-01;
            margin: 0 8px 0 8px;
        }
    }
    form {
        width: 100%;
    }
    &__divider {
        margin: 16px 0 0;
        width: 100%;
    }
    &__logoContainer {
        border: 1px solid $ui-01;
        border-radius: 4px;
        padding: 8px;
        margin: 0 0 16px;
        background-color: $brand-03;
        img {
            width: 32px;
            height: auto;
            display: block;
        }
    }

    &__foot {
        margin-top: auto;
        padding-top: 24px;
        text-align: center;
        display: flex;
        width: 100%;
    }

    &__navButtons {
        display: inline-flex;
        align-items: center;
    }
}
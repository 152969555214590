.dln-delonghi {
  & .dln-popup {
    &__content {
      & .dln-popup__wrapper {
        & .dln-subscription-flow {
          height: auto;
          min-height: 620px;

          &__column-two {
            padding-bottom: 28px;

            & .dln-title {
              line-height: 34px !important;
              font-size: REM(28px) !important;
              margin-bottom: 21px;
            }

            & .dln-subscription-flow__sub-title {
              line-height: 24px;
              font-size: REM(18px);
            }

            & .dln-subscription-flow__service-container {
              justify-content: space-between;
              flex-wrap: wrap;

              & > div {
                margin-left: 0;
                margin-top: 3px;

                & .dln-icn {
                  width: 24px;
                  height: 24px;
                  font-size: REM(24px);
                }

                & > span {
                  font-size: REM(18px);
                }
              }
            }

            & .dln-subscription-flow__input-container {
              & .dln-subscription-flow__row {
                margin-bottom: 0;
              }

              & > .dln-inputs {
                width: calc(100% - 20px);
                max-width: none;
                margin: 0 20px 30px 0;
              }

              & .dln-checkbox {
                margin-bottom: 40px;

                &.tooltipVisible {
                  margin-bottom: 8px !important;
                }

                &__wrap {
                  align-items: flex-start;
                  margin-top: 0;

                  & .dln-checkbox__tooltipIconWrap {
                    top: 16px;
                  }
                }

                & .dln-checkbox__tooltipWrap {
                  padding-top: 8px;
                  margin: 0 !important;
                }
              }

              & .dln-subscription-flow__button-container {
                & .dln-btn {
                  width: 100%;
                  max-width: 222px;
                  margin: 0;
                }
              }
            }
          }

          &--thankYouPage {
            & .dln-subscription-flow__column {
              &-two {
                & .dln-title {
                  margin-bottom: 16px;
                }

                & .dln-subscription-flow__sub-title {
                  margin-bottom: 40px;
                }
              }
            }

            & .dln-subscription-flow__button-container {
              width: 100%;

              & .dln-btn {
                width: 100%;
                max-width: 222px;
              }
            }
          }
        }
      }
    }

    .newsletter-popup-opened.dln-popup--open {
      .dln-popup__content {
        max-width: 720px !important;
      }
    }
  }

  .dln-subscription-flow {
    .gigya-input-checkbox:checked + label.gigya-label:after {
      position: absolute;
      top: 9px !important;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      &__content {
        & .dln-popup__wrapper {
          & .dln-subscription-flow {
            padding-top: 0;

            &__column-two {
              padding-top: 80px;
              position: relative;

              & .dln-btnIcon--close {
                position: absolute;
                top: 5px;
                right: 5px;
              }

              & .dln-title {
                line-height: 40px !important;
                font-size: REM(37px) !important;
                margin-bottom: 24px;
              }

              & .dln-subscription-flow__sub-title {
                line-height: 23px;
                font-size: REM(15px);
                margin-bottom: 16px;
              }
            }

            & .dln-subscription-flow__input-container {
              & .dln-inputs {
                width: 100%;
                margin: 0 0 32px 0;
              }

              & > .dln-inputs {
                margin: 0 0 24px 0;
              }

              & .dln-checkbox {
                padding-top: 0;
              }

              & .dln-subscription-flow__button-container {
                & .dln-btn {
                  max-width: 216px;
                  margin: 0 auto 0 0;
                }
              }
            }

            &--thankYouPage {
              min-height: 100vh;
              padding: 0;

              & .dln-subscription-flow__column {
                &-two {
                  padding: 80px 20px;

                  & .dln-title {
                    margin-bottom: 24px;
                  }
                }
              }

              & .dln-subscription-flow__button-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 40px;

                & .dln-btn {
                  max-width: 216px;
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }

      .newsletter-popup-opened.dln-popup--open {
        .dln-popup__content {
          max-width: none !important;
          width: auto;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-marketingPreferences {
    // &__sub-title {
    // 	@include fontMedium;
    // 	font-size: REM(28px);
    // 	line-height: 1.21;
    // 	margin-top: 40px;
    // 	text-align: center;
    // 	width: 100%;
    // }

    .dln-page__sectionHeadTitle {
      font-size: REM(37px) !important;
      line-height: 1 !important;
      text-align: center;
    }
    .dln-page__sectionHeadInfoText {
      font-size: REM(16px) !important;
      line-height: 1.5 !important;
      text-align: center;
    }

    .dln-page__content .dln-title.dln-title--delonghi {
      @include fontMedium;
      font-size: REM(50px);
      line-height: 1.04;
    }
    .dln-checkbox--image {
      box-shadow: none;
    }

    .dln-checkbox--interactive {
      .dln-checkbox {
        &__tooltipWrap {
          font-size: REM(13px);
          line-height: 1.23;
        }
        &__confirmWrap .dln-checkbox__confirmText {
          font-size: REM(14px);
          line-height: 1.3;
        }
      }
    }

    .dln-page__sectionFooter {
      justify-content: center;
      .dln-btn {
        margin: 0;
        font-size: REM(18px);
        width: 210px;
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-breadcrumbs {
        display: block;
      }

      .dln-page__sectionHeadTitle {
        font-size: REM(28px) !important;
        line-height: 1.07 !important;
      }
      .dln-page__sectionHeadInfoText {
        font-size: REM(15px) !important;
        line-height: 1.6 !important;
      }

      .dln-checkbox--interactive {
        .dln-checkbox {
          &__wrap {
            align-items: flex-start;
          }
          &__tooltipIconWrap {
            bottom: 0;
            top: auto;
          }
          &__tooltipWrap {
            font-size: REM(11px);
            line-height: 1.45;
          }
        }
      }

      .dln-wrapper {
        margin: 0;
        padding: 20px;
      }

      .dln-marketingPreferences {
        &__sub-title {
          font-size: REM(21px);
          line-height: 1.1;
          margin-top: 24px;
          margin-bottom: 40px;
        }
      }

      .dln-page__content .dln-title.dln-title--delonghi {
        @include fontMedium;
        font-size: REM(37px);
        line-height: 1.08;
        letter-spacing: -0.74px;
        margin: 0;
        margin-top: 5px;
      }
      .dln-checkbox--image + .dln-checkbox--image {
        border-top: 1px solid $ui-01;
      }
    }
  }
}

.dln-kenwood {
    .dln-recipe-slides {
        &__title {
            a {
                @include fontExtra;
                color: $brand-04;
                font-size: 28px;
            }
        }

        &__link {
            color: $brand-04;
            font-size: 14px;
            @include fontMedium;
            font-weight: bold;
        }
        .swiper-footer {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            width: 100%;
            max-width: 1240px;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        .swiper-container .dln-icn {
            font-size: 20px !important;
        }

        .swiper-container .swiper-footer .swiper-scrollbar {
            background: $ui-03;
            width: 100%;
            max-width: none;
            margin-top: 0;
        }

        .swiper-button-prev {
            order: 2;
            margin-left: 28px;
            margin-right: 28px;

            &.swiper-button-disabled {
                i {
                    color: $ui-07 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 599px) {
    .dln-kenwood {
        .dln-recipe-slides {
            &__title {
                a {
                    font-size: 24px;
                }
            }
        }
    }
}

.dln-kenwood {
  .dln-page .dln-title {
    text-align: left;
  }
  
  .dln-checkout {
    &__aside {
      .dln-orderSumm {
        &__itemDetails {
          .prices {
            display: flex;
            
            .realPrice {
              .dln-price--discounted {
                color: $brand-04;
              }
            }
            
            .dln-price--oldPrice {
              margin: 0 9px 0 0;
            }
          }
        }
      }
    }

    .dln-giftNoteMessage {

      .dln-checkbox__wrap {
        padding-left: 0!important;
      }

      .dln-checkbox__inputPlaceholder {
        width: 24px!important;
        height: 24px!important;
        position: relative!important;

        &:after {
          top: 0!important;
          left: 0!important;
        }
      }

    }
    
    .dln-checkbox
    .dln-checkbox__input:checked
    + .dln-checkbox__inputPlaceholder {
      border-color: var(--brand-01);
      border-radius: 2px;
      
      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        font-size: 11px;
        background-color: var(--brand-01);
        color: #fff;
      }
    }
    
    &__services {
      & .dln-checkServiceBox {
        padding-top: 20px;
      }
    }
    
    > .dln-wrapper {
      padding-top: 60px;
      padding-bottom: 70px;
    }
    
    .dln-title {
      margin-bottom: 32px;
    }
    
    &__paragraph {
      font-size: 12px;
      line-height: 20px;
    }
    
    &__content {
      width: calc(100% - 387px - 40px);
      margin: 0 40px 0 0;

      > div:first-child {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
      
      &.stepOne {
        width: 100%;
        margin: 0;
      }
      
      .dln-checkout__form {
        .msg {
          color: var(--brand-01);
          
          svg {
            color: var(--brand-01);
          }
        }
        
        .dln-address_info {
          color: var(--brand-01);
          align-items: center;
          
          .dln-tooltip {
            display: flex;
            
            &__anchor {
              margin-left: 5px;
            }
          }
        }
      }

      #oneyPlaceholder {
        margin-top: 25px;
        margin-bottom: 35px;
      }
      
      @media only screen and (max-width: 900px) and (min-width: 599px) {
        width: 100%;
        margin: 0;
        margin-top: 40px;
      }
      
      .dln-accordion {
        border-bottom: 1px solid $ui-07;
        
        &__title {
          span {
            color: var(--brand-04);
            @include fontBold;
            
            &.dln-mandatory {
              font-weight: 400;
              color: var(--brand-02);
            }
          }
          
          button span {
            font-weight: normal;
          }
        }
        
        .dln-radioBtn__lbl:after {
          background-color: var(--brand-01);
        }
        
        &:not(.dln-accordion--open):not(.dln-accordion--showInfo) {
          max-height: 70px;
          .dln-accordion__title {
            padding: 23px 0;
            color: $ui-07;
          }
        }
        
        &--showInfo {
          .dln-accordion__title .dln-btn {
            color: var(--brand-01);
            
            i {
              color: var(--brand-01);
            }
          }
        }
        
        &--shipping-method {
          .dln-checkout__formField.isChecked {
            border: 1px solid var(--brand-01);
          }
        }
        
        &__title {
          font-size: 18px;
          line-height: 22px;
          > .dln-icn {
            @include definePseudoElems;
            width: 16px;
            height: 16px;
            background-color: $support-02;
            border-radius: 50%;
            text-indent: 90px;
            overflow: hidden;
            margin: 0 15px 0 0;
            padding-right: 16px;
            &::before {
              @include fontIcon;
              content: 'à';
              font-size: 13px;
              line-height: 16px;
              width: auto;
              height: auto;
              color: $brand-03;
              text-indent: 0;
              left: 2px;
              top: 0px;
            }
          }
          
          .dln-btn {
            &__lbl {
              @include fontBold;
              color: $brand-01;
            }
          }
        }
        
        &__subTitle {
          font-size: 13px;
          line-height: 19px;
        }
        
        &__content {
          padding: 20px 0 50px;
        }
        
        &--open {
          .dln-accordion__content {
            border-top: none;
            margin-top: 0;
          }
        }
      }
    }
    
    &__form {
      padding: 16px 0 0;
      
      .clearer.clearer--03 {
        margin-bottom: 0 !important;
      }
    }
    
    &__formDataHead {
      .dln-btn--link {
        color: $brand-01;
      }
    }
    
    &__formValue {
      font-size: 13px;
      line-height: 19px;
    }
    
    &__dataResult {
      margin: 0 0 20px;
      p {
        font-size: 13px;
        line-height: 19px;
        margin-bottom: 0;
        margin-bottom: 20px;
      }
      /*  .dln-chips */
    }
    
    .dln-chips {
      margin: 20px 0 0 0;
      max-width: 100%;
      /* Extend as Warning chips */
      border-color: $support-04;
      
      .dln-chips__wrapper {
        align-items: center;
      }
      
      .dln-icn {
        color: transparent !important;
        &::before {
          color: $support-04;
          content: 'y';
        }
      }
      
      span {
        font-size: 12px;
        color: $ui-07;
      }
    }
    
    &__formData {
      border-color: $brand-04;
      position: relative;
      
      p {
        margin-bottom: 0;
      }
      
      .dln-btn--link {
        position: absolute;
        bottom: 24px;
        right: 16px;
      }
    }
    
    .dln-checkout__innerCol {
      width: calc(50% - 25px);
      margin: 0 50px 0 0;
      
      &:last-child {
        margin: 0 0;
      }
      
      &Title {
        font-size: 16px;
        line-height: 22px;
        border: none;
        margin: 0 0 10px;
        padding: 0;
      }
      
      .dln-loginForm__paragraph,
      .dln-newUserForm__paragraph {
        font-size: 14px;
        line-height: 20px;
        color: $brand-04;
      }
    }
    
    .dln-accordion--access-information {
      .dln-checkout__dataResult {
        /*   .dln-btn {
          display: none;
        } */
        p {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 0;
        }
        
        .mailInfo {
          p {
            padding: 9px;
            &:nth-child(2) {
              background-color: $ui-03;
            }
          }
        }
      }
      
      &.dln-accordion--open {
        .dln-accordion__title {
          border-bottom: 1px solid $ui-07;
          padding-bottom: 24px;
        }
      }
      
      .dln-accordion__content {
        margin-top: 10px;
      }
      
      .dln-newUserForm {
        padding: 0;
        &__section {
          &:first-of-type {
            .dln-btn {
              background-color: transparent;
              border: 1px solid $brand-01;
              color: $brand-01;
              
              &:hover {
                background-color: transparent;
                color: $brand-01;
              }
            }
            
            & + .dln-newUserForm__section {
              .dln-btn {
                background-color: $brand-01;
                border-width: 0;
                color: $brand-03;
              }
            }
          }
          
          .dln-inputs {
            margin-top: 40px;
          }
          
          .dln-btn {
            width: 100%;
          }
          
          &:not(.dln-newUserForm__section--main) {
            margin-top: 40px;
          }
        }
      }
      
      .dln-loginForm {
        padding: 0;
        .dln-btn--secondary {
          color: $brand-04;
        }
        
        &__section {
          margin-top: 0;
        }
        
        &__paragraph {
          margin-bottom: 30px;
          &--divider {
            text-indent: 9999999%;
            overflow: hidden;
            display: block;
            width: 100%;
            height: 1px;
            margin-bottom: 40px;
            
            &::before {
              display: none;
            }
            
            &::after {
              width: 100%;
              left: 0;
            }
            
            &Text {
              display: block;
              text-align: left;
            }
          }
        }
        
        &__btnGroup {
          .dln-btn--social:nth-last-child(1),
          .dln-btn--social:nth-last-child(2) {
            margin-bottom: 0;
          }
        }
        
        &__formContainer {
          margin-top: 40px;
        }
        
        .dln-inputs {
          margin-bottom: 40px;
          & + .dln-inputs {
            margin-bottom: 20px;
          }
        }
        
        & + .dln-btn {
          width: 100%;
        }
      }
    }
    .dln-accordion--shipping-information {
      .dln-divider {
        opacity: 0;
      }
      
      div[class*='--twoThird'] {
        width: calc(66.666% - 7px);
        & + div[class*='--oneThird'] {
          width: calc(50% - 10px);
        }
      }
      
      .dln-checkout__form {
        > div {
          &:nth-last-child(1),
          &:nth-last-child(2){
            margin-bottom: 20px;
          }
          &:nth-last-child(3) {
            margin-bottom: 0;
          }
        }
        
        .dln-select.dln-select--oneThird {
          width: calc(33.333% - 13.333px);
        }
        
        .clearer--01,
        .clearer--02 {
          & + .dln-inputs.dln-inputs--oneThird,
          & + .dln-inputs--phoneprefix {
            width: calc(33.333% - 13.333px);
            & + .dln-inputs.dln-inputs--oneThird {
              width: calc(33.333% - 13.333px);
            }
          }
        }
      }
      
      .dln-accordion__content {
        .dln-btn {
          max-width: none;
          margin: 20px 0 0;
        }
      }
      
      .dln-checkout__formData {
        border-color: var(--ui-12);
        
        &--selected {
          border-color: $ui-07;
        }
        
        &Head {
          .dln-radioBtn {
            display: flex;
            line-height: 22px;
            
            label {
              &:before {
                width: 22px;
                height: 22px;
              }
              &:after {
                width: 14px;
                top: 4px;
                height: 14px;
                left: 4px;
              }
            }
          }
        }
      }
      
      .dln-btn--newShippingAddress {
        @include makeAddBox;
        width: calc(50% - 8px);
        margin-bottom: 30px !important;
        border: 1px dashed $ui-07;
        
        & :before {
          display: none;
        }
      }
    }
    
    .dln-accordion--shipping-method,
    .dln-accordion--shipping-information {
      &.dln-accordion--open {
        .dln-accordion__content {
          border-top-color: transparent;
          margin-top: 0;
          
          .dln-checkout__form {
            .dln-checkout__formField {
              .dln-radioBtn {
                &__lbl {
                  &::after {
                    top: 10px;
                  }
                }
              }
            }
          }
        }
      }
      
      .dln-checkout__formField {
        .dln-checkout__formLabel {
          margin: 0 0 0 auto;
          font-size: 14px;
          
          &--small {
            font-size: 12px;
          }
          
          &--validate {
            color: $support-02;
          }
        }
      }
      
      .dln-checkout__form {
        & + .dln-checkout__paragraph {
          font-size: 12px;
          margin-top: 6px;
        }
      }
      
      .dln-accordion__content {
        .dln-btn {
          max-width: none;
          margin: 20px 0 0;
        }
      }
      
      .dln-checkout__formData {
        border: 1px solid var(--ui-12);
        margin-bottom: 20px;
      }
    }
    
    .dln-accordion--payment {
      .dln-accordion__content {
        margin-bottom: 0;
        padding-bottom: 0;
        &--submit {
          padding-bottom: 50px;
          .dln-btn {
            margin-top: 30px;
          }
        }
      }
      
      .dln-checkout__formField {
        &--billingAddress {
          .dln-checkbox {
            align-items: initial;
            min-height: 24px;
            
            &__wrap {
              padding-left: 24px;
            }
            
            &__inputPlaceholder {
              width: 24px;
              height: 24px;
              
              &:after {
                top: 0;
                left: 0;
              }
            }
          }
          
          .dln-checkout__form {
            > div {
              &:nth-last-child(1),
              &:nth-last-child(2),
              &:nth-last-child(3),
              &:nth-last-child(4),
              &:nth-last-child(6),
              &:nth-last-child(7) {
                @media only screen and (max-width: 599px) {
                  width: 100% !important;
                  margin: 0 0 30px 0;
                }
                
                width: 45% !important;
              }
              
              &:nth-last-child(3) {
                .dln-select__lbl .dln-select__lbl--active {
                  top: -19px;
                }
              }
            }
            .dln-address_info {
              height: 40px;
              align-items: center;
              
              .dln-tooltip {
                display: flex;
                
                &__anchor {
                  margin-left: 5px;
                }
              }
            }
          }
        }
        &--invoice {
          .dln-checkout__formField {
            &--fiscalCode__ita {
              .dln-select__lbl--active {
                top: -20px
              }
              
              .dln-checkout__third {
                .dln-select {
                  margin: 0;
                }
                
                & div:nth-child(2) {
                  .dln-select__lbl {
                    &--error {
                      top: auto;
                    }
                    &--active {
                      top:-20px;
                    }
                  }
                  
                } 
              }
            }
          }
        }
      }
      
      .dln-accordion__title {
        .dln-accordion__titleLabel {
          margin-left: 40px;
          font-size: 12px;
          .dln-icn {
            font-size: 20px;
          }
        }
      }
      
      .dln-invoiceRadio{
        .dln-radioBtn{
          label{
            padding: 4px 0 0 34px
          }
          
          .dln-radioBtn__lbl:before {
            width: 22px;
            height: 22px;
          }
          
          .dln-radioBtn__lbl:after {
            background-color: var(--brand-01);
            top: 4px;
            left: 4px;
            position: absolute;
            width: 14px;
            height: 14px
          }
        }
      }
      
      .dln-checkout__formData {
        border-color: $brand-04;
        .dln-btn--link {
          .dln-icn {
            display: inline-block;
          }
        }
      }
      
      .dln-checkout__form {
        padding: 10px 0 0;
        
        .row{
          margin-bottom: 0px;
        }
      }
      
      .dln-checkServiceBox__cardList {
        margin-top: 10px;
      }
      
      .dln-checkServiceBox__cardItem {
        width: 60px;
        height: 40px;
      }
      
      .dln-checkout__formField {
        //margin-top: 20px;
        background-color: $brand-03;
        
        &.isChecked {
          border: 1px solid var(--brand-01);
        }
        
        .dln-checkout__formData {
          border-color: var(--ui-12);
          margin-left: 0;
        }
        
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      
      .dln-checkout__formGroup {
        .dln-checkout__formField {
          margin-bottom: 40px;
          
          .dln-radioBtn {
            &__label {
              height: auto;
            }
            
            &__imgLabel {
              height: 22px;
            }
          }
        }
      }
      
      .dln-checkout__dataResult {
        margin-bottom: 0;
        
        .dln-checkout__formField {
          margin-top: 36px;
          background-color: var(--ui-14);
        }

        .dln-checkout__defaultCard {
          .dln-btn {
            color: var(--brand-01);
            &__lbl {
              @include fontBold;
            }
          }
        }
      }
    }
    
    &__services {
      .dln-checkServiceBox {
        width: calc(33.33% - 10px);
        margin: 0 14px 0 0;
        
        &:last-of-type {
          margin: 0;
        }
      }
    }
    
    &__noticeBox {
      padding: 50px 0 32px;
      align-items: flex-start;
      
      &::after {
        display: none;
      }
      
      p {
        @include fontBold;
        font-size: 20px;
        line-height: 24px;
        color: $brand-04;
      }
      
      a {
        @include fontBold;
      }
      
      .dln-noticeBox {
        padding: 30px 10px 48px 10px;
        
        &__copy {
          min-height: initial;
          margin-bottom: auto;
        }
        
        &__link {
          font-size: 14px;
          margin-top: auto;
        }
        
        &:nth-child(even) {
          &::after {
            opacity: 0;
          }
        }
      }
      
    }
    &--editing {
      .dln-checkout__formData {
        width: calc(50% - 10px);
        margin-bottom: 20px;
        
        &:nth-of-type(odd) {
          margin-right: 20px;
        }
      }
      
      .dln-accordion--payment {
        &.dln-accordion--editing {
          .dln-checkout__dataResult {
            padding: 0;
          }
          
          .dln-btn--stroked {
            @include makeAddBox;
            justify-content: center;
          }
          
          .dln-checkout__dataResult,
          .dln-checkout__formField {
            margin-bottom: 20px;
            
            .dln-btn--stroked {
              width: calc(50% - 32px);
              margin-bottom: 0;
              margin-top: 0;
            }
            
            .dln-checkout__formData {
              width: calc(50% - 32px);
              margin-bottom: 16px;
              margin-top: 0;
            }
            
            .dln-checkout__formData {
              margin-right: 32px;
              
              &Head {
                .dln-radioBtn {
                  display: flex;
                  line-height: 22px;
                  
                  label {
                    &:before {
                      width: 22px;
                      height: 22px;
                    }
                    &:after {
                      width: 14px;
                      top: 4px;
                      height: 14px;
                      left: 4px;
                    }
                  }
                }
              }
            }
          }
          
          .dln-checkout__formField {
            
            .dln-checkbox {
              margin-bottom: 20px;
              
              .dln-checkbox__wrap {
                .dln-checbox__label {
                  padding-left: 25px
                }
              }
            }
            
            &--billingAddress {
              display: -webkit-inline-box;
            }
            
            .dln-btn--stroked {
              margin: 0;
              padding: 0;
              min-height: 46px;
              border: 1px solid var(--brand-05);
              font-size: 18px;
              
              &:before {
                display: none;
              }
            }
          }
          
          .dln-accordion__content--submit {
            padding-top: 0;
            
            .dln-btn {
              min-width: calc(50% - 10px);
              max-width: 100%;
            }
          }
        }
      }
    }
    
    @media only screen and (max-width: 599px) {
      > .dln-wrapper {
        padding-top: 0;
      }
      
      &__paragraph {
        font-size: 12px;
        line-height: 20px;
      }
      
      &__content {
        width: 100%;
        margin: 0;
        
        .dln-accordion {
          &:not(.dln-accordion--open):not(.dln-accordion--showInfo) {
            border-top: 1px solid $ui-07;
            max-height: 62px;
            .dln-accordion__title {
              padding: 20px 0;
            }
          }
          
          &:not(.dln-accordion--showInfo) {
            border-bottom: none;
          }
          
          &__title {
            font-size: 18px;
            line-height: 22px;
            > .dln-icn {
              &::before {
                font-size: 12px;
                line-height: 12px;
                left: calc(50% - 6px);
                top: calc(50% - 6px);
              }
            }
          }
          
          &--open {
            padding-bottom: 0px;
            .dln-accordion__title {
              padding: 20px 0 8px;
            }
          }
          
          &--showInfo {
            .dln-checkout__dataResult {
              padding-inline: 20px;
              margin-inline: 0;
            }
            .dln-accordion__content {
              + .dln-checkout__dataResult {
                > p {
                  padding-left: 35px;
                }
              }
            }
          }
        }
        
        .dln-accordion--payment:not(.dln-accordion--showInfo) {
          border-bottom: 1px solid $ui-07;
        }
      }
      
      .dln-accordion__content {
        .dln-btn:not(.dln-btn--social) {
          max-width: 100%;
          width: 100%;
        }
      }
      
      .dln-accordion--access-information {
        .dln-checkout__dataResult {
          
          > p {
            margin: 0;
          }
          
          > span {
            margin: 0 0 0 auto;
          }
        }
        
        .dln-accordion__content {
          margin-top: 0;
        }
      }
      
      .dln-accordion--shipping-information {
        .dln-divider {
          margin-bottom: 0;
        }
        
        .dln-chips {
          margin-bottom: 36px;
          margin-top: -10px;
        }
        
        div[class*='--twoThird'] {
          width: 100%;
          & + div[class*='--oneThird'] {
            width: 100%;
          }
        }
        
        .dln-checkout__form {
          > div {
            &:nth-last-child(2),
            &:nth-last-child(3) {
              margin-bottom: 36px;
            }
            
            &:nth-last-child(1) {
              margin-bottom: 20px;
            }
          }
          
          .dln-select.dln-select--oneThird {
            width: 100%;
          }
          
          .clearer--01,
          .clearer--02 {
            & + .dln-inputs.dln-inputs--oneThird,
            & + .dln-inputs--phoneprefix {
              width: 100%;
              & + .dln-inputs.dln-inputs--oneThird {
                width: 100%;
              }
            }
          }
        }
        
        &.dln-accordion--showInfo {
          .dln-chips {
            margin-bottom: 0;
            margin-top: 20px;
          }
        }
        
        .dln-checkout__dataResult {
          .dln-checkout--mobileView {
            margin: 20px 0 0;
          }
        }
      }
      
      .dln-accordion--shipping-method {
        .dln-accordion__content {
          padding-top: 0;
        }
        
        .dln-checkout__formField {
          width: 100%;
          margin: 0 0 30px;
        }
      }
      
      .dln-accordion--payment {
        border-bottom: 1px solid $ui-07;
        
        &.dln-accordion--open,
        &.dln-accordion--showInfo {
          border-bottom: 1px solid $ui-07;
        }
        
        .dln-checkout__formField {
          .dln-inputs {
            &--exipirationdate {
              max-width: 100%;
              margin: 0 0 32px 0;
            }
            
            &--securityCode {
              margin-right: 0;
              margin-bottom: 0;
            }
          }
          
          .dln-btn {
            width: auto;
            margin-left: 20px;

            &.dln-btn--vatnumber_check {
              width: 100%;
              margin: 0 0 30px 0;

              @media (min-width: map-get($grid-breakpoints, sm)) {
                margin: 20px 0 1em 0;
              }
            }
          }
        }

        .dln-checkout__formGroup {
          .dln-checkout__formField {
            &:last-of-type {
              margin-bottom: 20px;
            }
          }
        }
        
        .dln-checkout__formData {
          .dln-checkServiceBox__cardItem {
            & + p {
              margin-bottom: 20px;
            }
          }
        }
        
        .dln-accordion__content {
          &--submit {
            .dln-checkout__paragraph {
              text-align: left;
              padding: 0;
            }
          }
        }
      }
      
      &__form {
        padding: 26px 0 0;
        
        div[class*='--oneThird'],
        .dln-inputs--selectInside,
        & > .dln-select {
          margin: 0 0 36px 0;
          width: 100%;
        }
        
        div[class*='--twoThird'] {
          margin: 0 0 36px 0;
          width: 100%;
        }
      }
      
      &__services {
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 10px;
        margin-left: 10px;
        
        .dln-checkServiceBox {
          width: 100%;
          margin: 0 0 16px 0 !important;
          padding: 32px 24px;
          min-height: auto;
          
          &__wrap {
            align-items: flex-start;
          }
          
          &__iconWrap {
            margin: 0 24px 0 0;
          }
          
          &__cardList {
            margin: 0;
          }
          
          &__text,
          .dln-btn {
            width: 100%;
            text-align: left;
          }
        }
      }
      
      &__noticeBox {
        max-width: 100%;
        padding-bottom: 0;
        
        > p {
          font-size: 18px;
          line-height: 22px;
        }
        
        .dln-noticeBox {
          width: 50%;
          position: relative;
          padding-bottom: 0;
          
          &:nth-child(even) {
            &::after {
              width: 1px;
              height: calc(100% - 48px);
              top: calc(50% - 48px);
              right: 0;
            }
          }
        }
      }
      
      .dln-loginForm {
        &__paragraph {
          text-align: center;
          
          &--divider {
            &Text {
              text-align: center !important;
            }
          }
        }
        
        .dln-btn--link {
          width: auto !important;
        }
      }
      
      .dln-checkout {
        &__innerCol {
          width: 100%;
          margin: 0;
          text-align: center;
          
          &Title {
            font-size: 16px;
            line-height: 22px;
            border: none;
            margin: 0 0 10px;
            padding: 0;
          }
          
          .dln-loginForm__paragraph,
          .dln-newUserForm__paragraph {
            font-size: 14px;
            line-height: 20px;
            color: $brand-04;
          }
          
          & + .dln-checkout__innerCol {
            .dln-checkout__innerColTitle {
              border-top: 0;
              margin-top: 60px;
              font-size: 16px;
              line-height: 22px;
            }
          }
          
          .dln-newUserForm {
            .dln-newUserForm__paragraph:not(.dln-newUserForm__paragraph--main) {
              text-align: left;
            }
          }
        }
      }
      
      &--editing {
        .dln-checkout__content {
          .dln-btn:not(.dln-btn--link) {
            width: 100%;
            max-width: 100%;
          }
          
          .dln-accordion--showInfo {
            .dln-checkout__dataResult {
              padding-left: 10px;
            }
          }
        }
        
        .dln-checkout__formData {
          width: 100%;
          margin-bottom: 20px;
          
          &:nth-of-type(odd) {
            margin-right: 0;
          }
        }
        
        .dln-accordion--shipping-information {
          .dln-checkout__formData {
            & + .dln-btn--stroked {
              @include makeAddBox;
              width: 100%;
              margin-bottom: 30px;
            }
          }
        }
        
        .dln-accordion--payment {
          &.dln-accordion--editing {
            .dln-checkout__dataResult {
              padding: 0;
            }
            
            .dln-btn--stroked {
              @include makeAddBox;
              justify-content: center;
            }
            
            .dln-checkout__dataResult,
            .dln-checkout__formField {
              width: 100%;
              margin-bottom: 10px;
              
              .clearer {
                display: none;
              }
              
              .dln-checkout__formData,
              .dln-btn--stroked {
                width: 100%;
              }
              
              .dln-checkout__formData {
                margin-right: 0;
                margin-bottom: 20px;
              }
            }
            
            .dln-checkout__formField {
              .dln-checkbox {
                margin-bottom: 20px;
              }
              
              .dln-btn--stroked {
                margin: 0;
              }
            }
            .dln-accordion__content--submit {
              padding-top: 0;
              
              .dln-btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
    
    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: 1080px) {
      > .dln-wrapper {
        padding-top: 30px;
      }
    }
  }
}

@media only screen and (min-width: 901px) {
  .dln-kenwood {
    .dln-checkout {
      &__form {
        .dln-inputs--phoneprefix:first-of-type {
          display: none;
        }
        
        .dln-inputs--address {
          margin-right: 20px !important;
        }
        
        > .dln-inputs,
        > .dln-select,
        div[class*='--twoThird'] {
          //width: calc(66.666% - 7px);
          margin-left: 0;
          margin-right: 20px;
        }
        
        .dln-checkout__form {
          > div {
            &:nth-last-child(1),
            &:nth-last-child(2),
            &:nth-last-child(3),
            &:nth-last-child(4),
            &:nth-last-child(6),
            &:nth-last-child(7) {
              width: 45% !important;
            }
            
            &:nth-last-child(3) {
              & label {
                top: -20px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-kenwood {
    .dln-checkout {
      .dln-checkout__innerCol .gigya-input-checkbox + label.gigya-label:before {
        top: 1px;
        left: 10px;
      }
      
      .dln-checkout__innerCol .gigya-input-checkbox + label.gigya-label:after {
        top: 4px;
        left: 13px;
      }
      
      .dln-accordion {
        &--access-information {
          .dln-accordion__content {
            .dln-checkout__innerCol:last-child {
              .dln-checkout__innerColTitle {
                padding: 0;
                margin-top: 70px !important;
              }
            }
          }
        }
      }
      
      &__services {
        .dln-checkServiceBox {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
      
      &__content {
        .dln-accordion {
          &__title {
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

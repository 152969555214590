.dln-profile {



    &__section {
        margin: 0 0 32px 0;

        &Head {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            margin: 0 0 20px 0;
            .dln-btn--link {
                color: $brand-04;
                @include fontMedium;
            }

            &Title {
                display: inline-flex;
                width: auto;
                line-height: 35px;
                font-size: 32px;
                @include fontBold;
                margin: 0 auto 0 0;
            }
        }
        & .dln-wishProd {
            // width: calc(50% - 20px - 2px);
            width: calc(50% - 5px);
            margin: 0 10px 10px 0;

            &:nth-of-type(2n+2) {
                margin: 0 0 10px 0;
            }
            &:only-of-type {
                margin: 0 auto 0 0;
            }
            &:last-of-type {
                margin: 0 0 0 0;

                &:nth-of-type(odd) {
                    margin: 0 auto 0 0;
                }
            }
            &:nth-last-of-type(2) {
                margin: 0 10px 0 0;

                &:nth-of-type(odd) {
                    margin: 0 auto 0 0;
                }
            }
        }
        & .dln-profile__recipesList {
            width: 100%;

            &Wrap {
                & .dln-recipe {
                    flex-direction: column;


                    &__details {
                        margin-top: 0 !important;
                    }
                }
            }
        }
        & .dln-profile__recipesListWrap {
            &:not(.swiper-wrapper) {
                display: flex;
                flex-wrap: wrap;
                // align-items: flex-start;
                height: auto;
                // align-content: flex-start;
            }
            width: 100%;
        }
        & .dln-recipe,
        & .dln-recipe.swiper-slide {
            // &:not(.swiper-slide) {
                width: calc(25% - 15px);
                margin: 0 20px 30px 0;

                &:nth-of-type(4n+4) {
                    margin: 0 0 30px 0;
                }
                &:last-of-type {
                    margin: 0 0 0 0;
                }
                &:only-of-type {
                    margin: 0 auto 0 0;
                }
            // }
        }
        & .dln-recipe.swiper-slide {
            &:nth-last-of-type(2),
            &:nth-last-of-type(3),
            &:nth-last-of-type(4) {
                margin: 0 20px 0 0;
            }
        }
        // & .swiper-slide {
        //     & .dln-recipe {
        //         width: 100%;
        //         margin: 0 !important;
        //     }
        // }
    }
    &__section--recipes {
        margin: 0;
    }
    & .dln-wrapper--carousel {
        padding: 0;
        max-width: 990px;
        margin: 0 auto;

        & .dln-carousel {
            padding: 0 16px;
        }
        & .swiper-pagination {
            margin: 16px auto 0;
            display: block;
        }
        & .dln-btn--stroked,
        & .dln-btnTextIcon {
            border-color: $ui-02;
            color: $ui-02;
        }
    }
    &__itemsCarousel {
        width: 100%;
        height: auto;
        background-color: $ui-03;
        padding: 24px 0 32px 0;

        &Title {
            display: block;
            width: 100%;
            height: auto;
            @include cta;
            color: $brand-02;
            text-align: center;
        }
    }

}

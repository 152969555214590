.dln-delonghi {
  & .dln-dropdown {
    max-width: 378px;

    &__wrapper {
      padding-bottom: 35px;
    }
    & .dln-loginForm {
      &__paragraph {
        line-height: 18px;
        font-size: REM(14px);
        margin-bottom: 28px;

        &--divider {
          margin-bottom: 30px;
        }
      }
      &__btnGroup {
        margin-bottom: 32px;

        & .dln-btn:not(.dln-btn--link) {
          min-width: initial;
        }
        & > .dln-btn--social {
          &-fb,
          &-inst {
            margin-bottom: 12px;
          }
        }
      }
      &__form {
        & .dln-inputs {
          & + .dln-inputs {
            margin-bottom: 25px;
          }
        }
        & .dln-btn--link {
          @include fontRoman;
        }
        &Foot {
          margin-top: 27px;
        }
      }
      &__section {
        &--main {
          margin: 0 0 20px 0;
        }
        &--registration {
          justify-content: center;
          margin: 0;

          & .dln-loginForm__paragraph {
            margin: 0 4px 0 0;
          }
          & .dln-btn {
            margin: 0;
          }
        }
      }
    }

    /* user area */
    &--userArea {
      max-width: 469px;

      & .dln-dropdown__arrow {
        margin: 0 68px 0 auto;
      }
      & .dln-dropdown__wrapper {
        padding: 40px 40px 46px 40px;
      }
      & .dln-dropdown__head {
        padding-bottom: 12px;
      }
      & .dln-dropdown__mainTitle {
        line-height: 24px;
        font-size: REM(18px);
      }
      & .dln-dropdown__content {
        padding-top: 20px;
      }
      & .dln-dropdown__list {
        width: calc(50% - 16.5px);
        padding: 0 33px 24px 0;

        &:nth-of-type(2n + 2) {
          padding: 0 0 24px 0;
        }
        &:nth-of-type(3) {
          padding: 0 33px 0 0;
          margin: 0;
        }
        &:last-of-type {
          padding: 15px 0 0 0;
        }
        &--closing {
          margin: 0;
        }
      }
      & .dln-dropdown__liHeading {
        @include fontSemibold;
        line-height: 24px;
        font-size: REM(17px);
        margin-bottom: 6px;
      }
      & .dln-dropdown__li a {
        line-height: 24px;
        font-size: REM(16px);
        padding: 4px 0;
      }
    }
  }
}

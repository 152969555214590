.dln-quickLinks--slider {
    display: flex;
    align-content: flex-start;
    text-align: center;
    width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;

    & .dln-quickLinks__inner {
        width: 100%;
        max-width: 1240px;
        margin: 0 auto;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, xl)) {
        & .dln-wrapper {
            max-width: map-get($grid-breakpoints, xl);
            padding-right: 40px;
            padding-left: 40px;
            margin: 0 auto;
        }
    }
    & > .dln-wrapper {
        width: 100%;
    }
    & .dln-quickLinks__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 26px;
        font-size: 22px;
        margin: 0 0 32px 0;
    }
    &.dln-quickLinks--slider--shrink .dln-wrapper {
        max-width: 1080px;
    }
    & .dln-carousel.swiper-container.dln-carousel--mobileOnly {
        height: initial;
    }
    & .dln-carousel.swiper-container {
        height: auto;

        & .swiper-wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: auto;

            & .swiper-slide {
                align-items: stretch;
                height: auto;

                & .dln-quickLink {
                    height: auto;
                }
            }
        }
        & .swiper-pagination {
            margin-top: 32px !important;

            &:empty {
                display: none;
            }
        }
    }
    &Wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        & .dln-quickLink {
            width: calc(20% - 16px); //20 * 4 / 5
            margin: 0 20px 0 0;

            &:last-of-type {
                margin: 0;
            }
        }
    }

    @media screen and (min-width: 767px) {
        .dln-carousel.swiper-container .swiper-slide.hide-items {
            width: 20%;
            &:nth-of-type(n+6) {
                display: none;
            }
        } 
    }
}

@media only screen and (min-width: 600px) {
    .dln-quickLinks--slider {

        .dln-carousel.swiper-container .swiper-slide:not(.hide-items) {
            width: calc(20% - 16px);
            margin: 0 20px 0 0;

            &:last-of-type {
                margin: 0;
            }
        }
        & .swiper-wrapper {
            display: flex;
            width: 100%;

            & .swiper-slide {
                & .dln-quickLink {
                    border-bottom: 1px solid $ui-01;
                }
            }
        }
    }
}

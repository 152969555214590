.dln-editOrdHead {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;

    &__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 55px;
        font-size: 44px;
        padding-top: 10px;
        margin: 0 0 20px 0;
    }
    &__data {
        display: inline-flex;
        flex-wrap: wrap;
        width: auto;
        line-height: 20px;
        font-size: 16px;
        color: $brand-01;
        margin: 0 50px 30px 0;

        & strong {
            margin: 0 0 0 3px;
        }
    }
    &__text {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 13px;
        margin: 0 0 40px 0;
    }
}

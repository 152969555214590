.dln-delonghi {
    .dln-completeForm {
        .dln-inputs {
            max-width: 100% !important;
            border-color: $brand-04;
        }
        .dln-radioBtn__lbl::after {
            background: $brand-04;
        }
        @media only screen and (max-width:599px) {
            
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-delonghi {
        
    }
}
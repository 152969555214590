@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
	.dln-wishlist--public {
		.dln-wishlist__content {
			max-width: 100%;
			margin: 0 auto 80px auto;

			.dln-wishlist__productWrap {
				.dln-wishProd {
					width: calc(100% - 7.5px);
				}
			}
		}
	}
}

.dln-kenwood {
    & .dln-faqShare {
        padding-bottom: 70px !important;

        & > .dln-popup__head {
            width: 100% !important;
            margin: 0 !important;
        }
        &__content {
            padding: 0;
            padding-top: 30px;

            & .dln-inputs {
                margin-bottom: 41px;

                &.dln-textarea {
                    margin-bottom: 30px;
                }
            }
        }
        &__actions {
            padding: 0;

            & .dln-btn--confirm {
                width: 102px;
                padding: 0;
            }
        }
    }
}


@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-faqShare {
            align-content: flex-start;
            min-height: 100vh;
            padding-top: 90px !important;

            & .dln-popup__head {
                margin-bottom: 32px !important;
            }
        }
    }    
}
.dln-delonghi {
  .dln-noticeBox {
    flex-direction: row;
    border-color: $ui-02;
    position: relative;

    &__icon {
      align-items: flex-start;
      width: auto;
    }

    & .dln-icn {
      color: $ui-02;
      text-align: left;
    }
    &__content {
      width: calc(100% - 48px - 30px);
    }
    &__title {
      @include fontMedium;

      @media only screen and (max-width: 599px) {
        line-height: 23px;
        font-size: REM(18px);
        margin-bottom: 5px;
      }
      line-height: 34px;
      font-size: REM(18px);
      color: $brand-04;
      margin: 0;
      margin-bottom: 8px;
    }
    &__copy {
      @include fontBook;
      line-height: 18px;
      font-size: REM(14px);
      color: $brand-04;
    }

    // &--light {
    //     .dln-noticeBox__content {
    //         color: $brand-04;
    //     }
    // }
    &--light {
      align-content: flex-start;

      & .dln-noticeBox__icon {
        height: 48px;

        & .dln-icn {
          color: $brand-04;
        }
      }
      & .dln-noticeBox__copy {
        line-height: 18px;
        color: $ui-02;
        margin-bottom: 0;
      }
      & .dln-noticeBox__content {
        width: 100%;
        min-height: 36px;
        margin-bottom: 12px;
      }
      & .dln-noticeBox__link {
        @include fontSemibold;
      }
    }
  }
  // .dln-noticeBox + .dln-noticeBox {
  //     margin-top: 16px;
  // }
}

.dln-kenwood {
  .dln-cart__emptyMessage {
    max-width: 70%;
  }

  & .dln-cart {
    & > .dln-wrapper {
      padding-bottom: 70px;
    }
    &__head {
      margin-bottom: 41px;

      & .dln-cart__title {
        @include fontExtra;
        line-height: 36px;
        font-size: 28px;

        & .dln-icn {
          color: $brand-01;
          font-size: 26px;
        }

        &Lbl {
          line-height: 20px;
          font-size: 14px;
          color: $ui-10;
          padding-top: 4px;
          margin-left: 6px;
        }
      }
    }

    &__content {
      & .dln-chips {
        padding: 14.5px 20px;

        &__wrapper {
          & .dln-chips__content {
            & span {
              @include fontLight;
              font-size: 14px;
              color: $brand-04 !important;

              & a {
                @include fontBold;
                color: $brand-04 !important;
              }
            }
          }
        }
      }

      & .dln-cart__emptyMessage {
        display: flex;
        flex-wrap: wrap;
        margin-top: 52px;
        margin-bottom: 70px;

        & > .dln-icn {
          width: 80px;
          height: 78px;
          line-height: 78px;
          margin: 0 auto 20px auto;

          &::before {
            @include fontIcon;
            font-size: 78px;
          }

          & + p {
            @include fontExtra;
            display: block;
            width: 100%;
            line-height: 36px;
            font-size: 28px;
            color: $brand-04;
            text-align: center;
            margin-bottom: 20px;
          }
        }

        & .dln-cart__btnGroup {
          margin-top: 0;
        }

        & + .dln-cart__items {
          display: none;
        }
      }
    }

    &__aside {
      & .dln-cart__noticeBox {
        & > p {
          @include fontSemibold;
          line-height: 24px;
          font-size: 20px;
          color: $brand-04;
        }

        & .dln-noticeBox {
          margin: auto 0;

          &::after {
            display: none;
          }

          &:first-of-type {
            & .dln-noticeBox__icon {
              & .dln-icn {
                &::before {
                  font-size: 46px;
                }
              }
            }
          }

          &:last-child {
            & .dln-noticeBox__icon {
              & .dln-icn {
                &::before {
                  font-size: 46px;
                }
              }
            }
          }

          & .dln-noticeBox__icon {
            & .dln-icn {
              width: 50px;
              height: 46px;
              line-height: 46px;
            }
          }

          & .dln-noticeBox__copy {
            @include fontLight;
            min-height: initial;
            margin-bottom: 5px;
          }

          & .dln-noticeBox__link {
            @include fontMedium;
            font-size: 14px;
            margin: 0;
          }
        }
      }
    }

    &__services {
      justify-content: space-between;
      margin-top: 120px;

      & .dln-checkServiceBox {
        width: calc(33.33% - 13.33px);
        padding: 27px 20px 20px 20px;
      }
    }

    &--empty {
      .dln-cart__aside {
        padding: 0;
      }

      .dln-cart__noticeBox {
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-kenwood {
    & .dln-cart {
      & > .dln-wrapper {
        padding-top: 20px;
        padding-bottom: 38px;
      }

      &__head {
        min-height: initial;
        margin-bottom: 32px;

        & .dln-cart__title {
          line-height: 32px;
          font-size: 24px;
        }
      }

      &__content {
        & .dln-chips {
          &__wrapper {
            & .dln-chips__content {
              & span {
                line-height: 20px;
                font-size: 14px;
              }
            }
          }
        }
      }

      & .dln-cart__emptyMessage {
        padding-top: 0;
        margin-top: 52px;
        max-width: initial;

        & > .dln-icn {
          & + p {
            line-height: 32px;
            font-size: 24px;
            margin-bottom: 24px;
          }
        }

        & .dln-cart__btnGroup {
          //width: 182px;
          margin: 0 auto;

          & .dln-btn {
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }

      &__aside--secondary {
        & .dln-cart__noticeBox {
          display: flex;
          flex-wrap: wrap;

          & > p {
            line-height: 22px;
            font-size: 18px;
          }

          & .dln-noticeBox {
            width: 50%;

            &__link {
              @include fontBold;
            }
          }
        }
      }

      & .dln-cart__services {
        padding-top: 0;
        margin-top: 70px;
        margin-bottom: 70px;

        & .dln-checkServiceBox {
          width: 100%;
          padding: 20px;
          margin: 0;

          &__wrap {
            align-items: flex-start;
          }

          &:nth-of-type(2) {
            & .dln-checkServiceBox__wrap {
              & .dln-checkServiceBox__content {
                & .dln-checkServiceBox__text {
                  margin-bottom: 20px;
                }
              }
            }
          }

          &:last-of-type {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, xs)) {
  .dln-kenwood {
    & .dln-cart {
      &--empty {
        > .dln-wrapper {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;

          .dln-cart__head {
            margin-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;

            .dln-cart__title {
              align-items: baseline;
              font-size: REM(30px);

              .dln-cart__titleLbl {
                @include fontMedium;
                font-size: 13px;
              }

              .dln-icn--iconfont {
                display: block;
                color: var(--brand-01);
                font-size: 23px;
              }
            }
          }

          .dln-cart__content {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 40px;

            & .dln-cart__info {
              @include fontMedium;
              font-size: REM(13px);
              color: var(--brand-04);
              margin: 0 auto;
              width: 475px;
              text-align: center;
              padding: 5px 0;
              border: 1px solid var(--ui-12);
              background-color: var(--ui-03);
              line-height: 1.46;
            }
          }

          .dln-cart__emptyMessage {
            margin-top: 25px;
            margin-bottom: 25px;

            .dln-icn {
              margin-bottom: 0;

              &::before {
                font-size: 42px;
                color: var(--brand-01);
                font-weight: bold;
              }
            }

            p {
              @include fontMedium;
              font-size: REM(13px);
              color: var(--brand-04);
              margin-top: 10px;
              margin-bottom: 15px;
            }

            .dln-cart__btnGroup {
              .dln-btn:first-of-type {
                margin: 0 0 20px;
              }
            }
          }

          .dln-cart__aside {
            margin: 0 !important;

            .dln-cart_paymentBox {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 15px;
              padding: 20px;
              width: 100%;
              color: var(--ui-02);
              background-color: var(--ui-03);
              margin-bottom: 20px;

              span {
                @include fontSemibold;
                font-size: REM(14px);
                color: var(--ui-11);
              }

              ul {
                display: flex;
                flex-wrap: wrap;
                list-style-type: none;
                gap: 5px;
              }
            }
          }

          .dln-cart__aside--secondary,
          .dln-cart__aside {
            .dln-cart__noticeBox {
              padding-left: 20px;
              padding-right: 20px;
              gap: 0;

              p {
                display: none;
              }

              .dln-noticeBox {
                width: 100%;
                align-items: baseline;
                margin-bottom: 15px;

                &__content {
                  .dln-noticeBox__icon {
                    .dln-icn:before {
                      font-size: 43px;
                    }
                  }

                  .dln-noticeBox__description {
                    .dln-noticeBox__title {
                      @include fontBold;
                      font-size: REM(18px);
                      color: var(--brand-04);
                      margin-bottom: 3px;
                    }

                    .dln-noticeBox__copy {
                      @include fontMedium;
                      font-size: REM(13px);
                      color: var(--brand-04);
                    }
                  }
                }

                &__link {
                  @include fontRoman;
                  margin-top: 0;
                  font-size: REM(15px);
                  text-decoration: none;
                  padding-bottom: 1px;
                  border-bottom: 1px solid var(--brand-04);
                  color: var(--brand-04);
                  line-height: 1.47;
                }

                &:nth-child(even) {
                  &::after {
                    height: 0;
                  }
                }
              }

              .dln-noticeBox:first-of-type {
                margin-top: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) {
  .dln-kenwood {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          .dln-cart {
            &__head {
              .dln-cart__title {
                margin: 0 auto;
                max-width: 475px;
              }
            }

            &__content {
              padding-left: 20px;
              padding-right: 20px;

              & .dln-cart__info {
                color: var(--ui-11);
              }
            }

            &__emptyMessage {
              .dln-cart__btnGroup {
                .dln-btn {
                  width: 100%;
                }
              }
            }

            &__aside,
            &__aside--secondary {
              padding-left: 20px;
              padding-right: 20px;

              .dln-cart__noticeBox {
                .dln-noticeBox {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 901px) {
  .dln-kenwood {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          padding-right: 20px;
          padding-left: 20px;

          .dln-cart {
            &__head {
              margin-left: 0;
              padding-left: 0;
              margin-top: 80px;

              .dln-cart__title {
                font-size: REM(36px);
                margin-left: 0;

                .dln-icn {
                  display: block;
                  font-size: 32px;
                }

                .dln-cart__titleLbl {
                  margin-bottom: 12px;
                  font-size: REM(15px);
                }
              }
            }

            &__content {
              width: calc(
                100% - 387px - 20px
              ); //copied by accessories-added-desktop.scss to mantain desktop behaviour
              padding-right: 0;
              margin-right: 0;
              padding-left: 0;
              display: block;

              .dln-cart__info {
                width: 100%;
                font-size: REM(15px);
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }

            &__emptyMessage {
              p {
                @include fontMedium;
                font-size: REM(15px);
              }

              .dln-icn:before {
                font-size: 45px;
              }

              .dln-cart__btnGroup {
                flex-direction: row;
                margin-top: 65px;
                flex-wrap: nowrap;
                justify-content: space-between;
              }
            }

            &__aside {
              padding-left: 20px;
              padding-right: 0;
              width: 407px;

              .dln-cart__noticeBox {
                display: block;

                .dln-noticeBox {
                  &__content {
                    .dln-noticeBox__icon {
                      .dln-icn:before {
                        content: '';
                      }
                    }
                  }
                }
              }

              .dln-cart_paymentBox {
                background-color: var(--ui-03);
                margin: 20px 0 20px 0;
              }
            }

            &__aside--secondary {
              .dln-cart__noticeBox {
                display: none;
              }
            }

            &__aside,
            &__aside--secondary {
              .dln-cart__noticeBox {
                .dln-noticeBox {
                  margin-bottom: 15px;

                  &__content {
                    .dln-noticeBox__icon {
                      .dln-icn {
                        font-size: 36px;
                        margin-top: -5px;
                      }
                    }

                    .dln-noticeBox__description {
                      .dln-noticeBox__copy {
                        font-size: REM(14px);
                        color: var(--brand-04);
                      }
                    }
                  }

                  &__link {
                    @include fontMedium;
                    border-bottom: 1px solid var(--brand-04);
                  }

                  &:nth-child(even) {
                    &::after {
                      height: 0;
                    }
                  }
                }

                .dln-noticeBox:first-of-type {
                  margin-top: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1101px) {
  .dln-kenwood {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          padding-top: 0;

          .dln-cart {
            &__head {
              padding-left: 0;
            }

            &__content {
              margin-right: 20px;
              padding-right: 0;
            }

            &__aside,
            &__aside--secondary {
              padding-right: 0;
              padding-left: 0;

              .dln-cart__noticeBox {
                .dln-noticeBox {
                  margin-top: 15px;
                  margin-bottom: 10px;
                }
              }
            }

            &__aside {
              width: 387px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .dln-kenwood {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          max-width: 1240px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) and (min-width: 1480px) {
  .dln-kenwood {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          max-width: 1240px;
          margin: 0 auto;
        }
      }
    }
  }
}

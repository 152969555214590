@media only screen and (max-width:599px) {
    .dln-wishlist--shared.dln-page--myArea {
    	.dln-wrapper {
    		.dln-wishlist__content {
				max-width: 100%;
				width: 100%;
				margin: 0;
				margin-bottom: 60px;

				.dln-title {
	                margin-top: 12px
	            }
			}
    	}
    }
}
.dln-kenwood {
    & .dln-prodReg {
        &--search {
            & .dln-prodReg__head {
                width: 100%;
                min-width: initial;
                background-color: $brand-03;
                padding-top: 60px;
                margin: 0;
            }
            & .dln-prodReg__content {
                margin-top: 0;
                margin-bottom: 0;
                & .dln-prodReg__subTitle {
                    @include fontExtra;
                    line-height: 40px;
                    font-size: 32px;
                    font-weight: 400;
                }
                & .dln-tabs {
                    margin-bottom: 48px;
                    &__list {
                        .dln-tabs__currentLine {
                            max-width: 261px;
                        }
                    }
                    &__item {
                        width: auto;
                        font-weight: 700;
                    }
                    &__currentLine {
                        height: 4px; 
                    }
                }
                & .dln-tabs__content {
                    min-height: initial;
                    border-bottom: none;
                    padding-bottom: 0;

                    &#dln-prodReg--category {
                        & .dln-prodReg__subTitle {
                            display: none;
                        }
                        & .dln-prodReg__categoryBreadcrumbs {
                            margin-bottom: 40px;
                        }
                    }
                }
            }
            .dln-timeline {
                margin-top: 0;
            }
            & .dln-prodReg__searchModel {
                & .dln-inputs__lbl {
                    width: 100%;
                    line-height: 16px;
                    color: $ui-07;
                    padding: 0;
                    top: -21px;
                }
                & .dln-btn {
                    width: 95px;
                    height: 45px;
                    padding: 0;
                }
                & .dln-tooltip {
                    margin-left: 0;

                    &__anchor {
                        color: $ui-07;
                    }
                    &__container {
                        text-align: center;
                    }
                    &__text {
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
            & .dln-tabs__item {
                width: auto;
            }
            &  .dln-tabs__currentLine {
                height: 4px;
            }
        }

        & .dln-category-browser__breadcrumbs {
            div {
                display: inline-block;
            }
            & .dln-breadCrumbSel {  
                display: inline-block;
        
                &__text {
                    display: inline-block;
                }
            
                &__text--selected {
                    display: inline-block;
                    color: var(--ui-07);
        
                    & + .dln-breadCrumbSel__arrow {
                        transform: rotate(360deg);
                    }
                }
        
                &__arrow {
                    transform: rotate(360deg);
                    display: inline-block;
                    margin-left: 3px;
                    top: -2px;
                    font-size: REM(14px);
                    color: var(--ui-07);
    
                    &--search {
                        top: 0;
                    }
                }   
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-prodReg--search {
            & .dln-prodReg__head {
                padding: 40px 0 0 0;

                & .dln-title {
                    width: 100%;
                    line-height: 40px;
                    padding: 0;
                }
                & .dln-timeline {
                    max-width: none;
                    min-height: 74px;

                    &__step {
                        &::after {
                            // width: calc(50% - 8px);
                            width: 100%;
                            left: 72px;
                            z-index: 2;
                        }
                    }
                    &__label {
                        z-index: 3;

                        &::before {
                            z-index: 4;
                        }
                        & span {
                            display: block;
                            width: 100%;
                            line-height: 16px;
                            font-size: 14px;
                            text-align: center;
                            opacity: 1;

                        }
                    }
                }
            }
            & .dln-prodReg__content {
                margin: 69px 0 0 0;

                & .dln-prodReg__subTitle {
                    line-height: 32px;
                    font-size: 28px;
                    margin-bottom: 32px;
                }
                // & .dln-tab {
                //     min-width: 100vw;
                //     margin-bottom: 24px;

                //     &__links {
                //         font-size: 16px;
                //     }
                // }
                & .dln-tabs__content {
                    &#dln-prodReg--model {
                        & .dln-prodReg__searchModel {
                            & .dln-inputs {
                                order: 1;
                                margin-bottom: 4px;
                            }
                            & .dln-btn {
                                order: 4;
                                padding: 0;
                                margin: 0 0 0 auto;
                            }
                            & .dln-tooltip {
                                order: 2;
                                margin: 0 auto 16px 0;
                            }
                            & .clearer {
                                order: 3;
                            }
                        }
                    }
                    &#dln-prodReg--category {
                        & .dln-prodReg__categoryBreadcrumbs {
                            // display: none;

                            .dln-prodReg__categoryLabel {
                                display: none;
                            }
                        }
                        /*& .dln-prodReg__categoryBreadcrumbs {
                            & .dln-select {
                                display: none;

                                &__value {
                                    font-size: 0;
                                }
                                &:nth-last-of-type(2),
                                &:only-of-type {
                                    display: inline-block;
                                    font-size: 16px;
                                }
                                &:last-of-type {
                                    & .dln-select__value {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }*/
                    }
                }
            }
        }
    }
}
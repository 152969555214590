.dln-delonghi {
  .dln-orderDetailHead {
    padding: 32px 20px;
    position: relative;

    &__number {
      @include fontMedium;
      font-size: REM(28px);
      margin-bottom: 35px;
      width: calc(100% - 150px);
    }

    &__cancel {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $brand-04;
      @include fontMedium;
      position: absolute;
      right: 24px;
      top: 24px;
      width: auto;

      a {
        color: var(--brand-04);
        height: 20px;
      }

      &.position-static {
        position: static;
      }
    }

    &__data,
    &__email {
      @include fontSemibold;

      p {
        @include fontSemibold;
      }
    }

    &__email {
      margin-bottom: 24px;
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-orderDetailHead {
      &__cancel {
        line-height: 32px;
        position: static;
        align-items: flex-start;
      }

      &__number {
        order: 1;
        width: 100%;
      }

      &__email {
        order: 2;
      }
    }
  }
}

.dln-kenwood {
  .dln-category-browser {
    &__wrapper {
      border-top: 1px solid $ui-01;
      border-bottom: 1px solid $ui-01;
      padding-top: 70px;
      padding-bottom: 70px;
      max-width: 1080px !important;
      margin-right: 40px;
      margin-left: 40px;

      @media only screen and (max-width: map-get($grid-breakpoints, md)) {
        padding-top: 60px;
        padding-bottom: 60px;
        margin-right: 20px;
        margin-left: 20px;
      }

      & > .dln-wrapper {
        padding: 0;
        margin: 0;
        width: 100%;
      }

      &.dln-category-browser__wrapper--noHeader.dln-category-browser__wrapper--noBreadcrumbs {
        border: 0;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__header {
      width: 100%;
      margin-bottom: 46px;

      &--title {
        display: block;
        text-align: center;

        font-size: 20px;
        line-height: 24px;
        @include fontSemibold;
      }

      &--text {
        display: block;

        @include fontSemibold;
        line-height: 24px;
        font-size: 20px;
        text-align: center;
      }
    }

    &__breadcrumbs {
      @media only screen and (max-width: map-get($grid-breakpoints, md)){
        :not(:last-child) {
          display: none;
        }
      }
      & .dln-breadCrumbSel {
        &__text {
          display: block;
          @include fontBold;
          height: 24px;
          line-height: 24px;
          font-size: 20px;
          color: $ui-07;
          margin-right: 7px;

          &--selected {
            @include fontSemibold;
            color: $brand-04;
          }
        }

        &__arrow {
          height: 24px;
          line-height: 24px;
          color: $ui-07;

          @media only screen and (max-width: map-get($grid-breakpoints, md)) {
            display: none;
          }

          &--selected {
            color: $brand-04;
            top: 2px;
          }

          @media only screen and (max-width: map-get($grid-breakpoints, md)) {
            line-height: 22px;
            height: 20px;
          }
        }
      }
      & .dln-select {
        height: 24px;

        &__container {
          height: 24px;
        }
        &__value {
          @include fontSemibold;
          line-height: 24px;
          font-size: 20px;
          margin-right: 7px;

          @media only screen and (max-width: map-get($grid-breakpoints, md)) {
            font-size: 18px;
            line-height: 22px;
          }
        }
        &__arrow {
          width: 11px;
          height: 24px;

          &::before {
            display: inline-block;
            line-height: 20px !important;
            padding-top: 4px;

            @media only screen and (max-width: map-get($grid-breakpoints, md)) {
              padding-top: 0px;
            }
          }
        }

        .dln-select__option {
          &:first-of-type {
            &::before {
              content: none;
            }
          }
        }
      }
    }

    &__category {
      & .dln-categoryBox {
        width: calc(20% - 14px);
        align-items: center;
        margin: 0 8px;
        padding: 0;
        border: 0;

        &:nth-child(5n) {
          margin-right: 0;
        }
        &:nth-child(5n + 1) {
          margin-left: 0;
        }

        &__title {
          display: block;
          text-align: center;
          border: 0;
          text-align: center;
        }

        .ken-picture,
        .ken-image__image {
          max-width: 100%;
        }

        @media only screen and (max-width: 599px) {
          width: calc(50% - 10px);

          &:nth-child(5n) {
            margin-right: 8px;
          }
          &:nth-child(5n + 1) {
            margin-left: 8px;
          }

          &:nth-child(2n) {
            margin-right: 0px;
          }
          &:nth-child(2n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }
}

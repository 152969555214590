.dln-delonghi {
  .dln-persDataRecap {
    .dln-inputs {
      overflow: visible;
    }

    &:not(.dln-persDataRecap--editing) {
      padding: 15px 20px 20px 20px;

      .dln-persDataRecap__wrap {
        padding: 0;

        .dln-inputs {
          margin: 0 24px 16px 0;
          width: calc(50% - 24px);
          overflow: hidden;
        }

        .dln-inputs.dln-inputs--disabled,
        .dln-select.dln-select--disabled {
          order: 0;
          width: auto;
          border: 0;
          height: auto;
          padding: 0;
          margin: 0;

          @include fontBook;
          font-size: REM(16px);
          line-height: 30px;
          color: $brand-04;

          @media only screen and (max-width: map-get($grid-breakpoints, md)) {
            width: 100%;
            margin: 0 !important;
          }

          .dln-select__lbl,
          .dln-inputs__lbl {
            opacity: 1;
            line-height: normal;
            height: auto;
            padding: 0;
            margin: 0;
            margin-right: 10px;

            @include fontBook;
            font-size: REM(16px);
            line-height: 30px;
          }

          .dln-inputs__input,
          .dln-select__value {
            opacity: 1;
            margin: 0;
            padding: 0;
            width: auto;
            height: auto;
            line-height: 30px;

            @include fontDemi;
            font-size: REM(16px);
            line-height: 30px;
          }
        }

        .dln-select.dln-select--disabled {
          .dln-select__container {
          }
          .dln-select__head {
            height: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-faqShare {
        padding: 0 0 56px 0;

        & .dln-popup__head {
            margin-left: 20px;
            margin-right: 20px;
            &Title {
                padding: 0;
                font-size: 15px;
            }
        }
        &__content {
            padding: 0 20px;
        }
        &__actions {
            padding: 0 20px;

            & .dln-btn--cancel {
                @include fontBold;
                margin: 0 auto 0 0;
            }
            & .dln-btn--confirm {
                width: 184px;
                margin: 0 0 0 auto;
            }
        }
    }
}
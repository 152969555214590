.dln-instruction-manuals {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    justify-content: center;
    height: auto;
    //min-height: 100vh;
    background-color: $brand-03;
    padding: 40px 0 70px 0;

    &--alert {
        padding-top: 30px !important;
    }

    & > .dln-wrapper {
        width: 100%;
        max-width: 1080px;
    }
    &__chipsWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;

        & > .dln-wrapper {
			width: 100%;
            max-width: 1080px;
        }
    }
    &__main {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        justify-content: center;
        //max-width: 1080px;
        height: auto;
        margin: 0 auto;

        & > .dln-wrapper {
            width: 100%;
            max-width: 1080px;
            .dln-btn--link {
                width: 100%;
                text-align: left;
                @include fontRoman;
                font-size: 12px;
                line-height: 18px;
                color: $brand-02;
                margin-top: 4px;
            }
        }
    }
    & .dln-chips {
        width: 100%;
        border: none;
        margin: 0 0 40px 0;
    }
    &__mainTitle {
        @include fontBold;
        display: block;
        width: 100%;
        font-size: 44px;
        line-height: 55px;
        margin: 0 0 32px 0;
    }
    &__mainSubtitle {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 32px;
        margin: 0 0 20px 0;
    }
    & .dln-inputs--searchBar {
        width: 50%;
    }
    &__inner {
        width: 100%;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        justify-content: center;
        //max-width: 1080px;
        height: auto;
        padding-top: 66px;
        margin: 0 0 83px 0;
        margin-right: auto;
        margin-left: auto;

        & > .dln-wrapper {
            width: 100%;
            max-width: 1080px;
        }
    }
    &__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 26px;
        font-size: 22px;
        margin: 0 0 48px 0;
    }
    &__text {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 14px;
        margin: 0 0 16px 0;
    }
    &__textList {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        list-style: inside disc;
        margin: 0 0 16px 0;

        &Item {
            width: 100%;
        }
    }
    &__breadCrumbSelWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin: 0 0 24px 0;

        & .dln-breadCrumbSel {
            margin: 0 24px 0 0;
        }
        & .dln-select {
            margin: 0 24px 0 0;

            &__value {
                @include fontBold;
            }
        }
    }

    &__product {
        @media only screen and (min-width:map-get($grid-breakpoints, sm)) {
            align-items: flex-start;
        }
    }

    .dln-category-browser {
        & > .dln-wrapper {
            max-width: 1080px;
        }
        &__category {
            & .dln-categoryBox {
                &__imgWrap{
                    background-color: transparent;
                }
            }
        }
    }
    & .dln-instruction-manuals__title--related {
        @include fontSemibold;
    }
    &__resultCounter {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        color: $brand-02;
        text-align: center;
        margin: 0 0 16px 0;

        &+.dln-btn--showMoreResults {
            width: 184px;
            margin: 0 auto;
        }
    }
    &__videoContainer {
        width: 895px;
        height: 494px;
        background-color: $brand-01;
        margin: 0 0 48px 0;
        // padding-bottom: 56.25%;
        // position: relative;
    }
    &__video {
        display: block;
        max-width: 100%;
        height: auto;
    }
    &__quickLinks {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        //max-width: 1080px;
        height: auto;
        margin-right: auto;
        margin-left: auto;

        & .dln-instruction-manuals__title {
            margin-bottom: 32px;
        }
        // & .dln-wrapper {
        //     // width: calc(100% - 40px);
        // }
        & .dln-wrapper {
            max-width: 1080px;
        }
        & .dln-carousel.swiper-container.dln-carousel--mobileOnly {
            height: initial;
        }
        & .swiper-container {
            & .swiper-wrapper {
                // display: flex;
                // align-items: stretch;
                height: auto;

                & .swiper-slide {
                    height: initial;
                    min-height: 100%;

                    & .dln-quickLink {
                        min-height: 180px;
                    }
                }
            }
            & .swiper-pagination {
                margin-top: 32px !important;
            }
        }
        &Wrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            & .dln-quickLink {
                width: calc(20% - 16px); //20 * 4 / 5
                margin: 0 20px 0 0;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
    &__inner {
        width: 100%;
        //max-width: 1080px;
        padding: 0 40px;
        margin: 0 auto;

        & .dln-wrapper {
            max-width: 1080px !important;
        }
    }
    &__contactUs {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        & .dln-wrapper {
            width: 100%;

            &--fullWidth {
                background-color: $ui-03;
                padding-top: 40px;
                padding-bottom: 40px;

            }
        }
        &Title {
            @include fontBold;
            display: block;
            width: 100%;
            line-height: 22px;
            font-size: 18px;
            margin: 0 0 24px 0;
        }
        &Text {
            display: block;
            width: 100%;
            line-height: 20px;
            font-size: 14px;
            margin: 0 0 32px 0;
        }
        & .dln-btn--contactUs {
            width: 124px;
        }
    }
    &__helpSection {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin-bottom: 80px;
        .dln-wrapper {
            max-width: 1080px;
        }
        &Title {
            @include fontBold;
            display: block;
            width: 50%;
            line-height: 32px;
            font-size: 32px;
            padding-right: 50%;
            margin-bottom: 40px;
        }
        &Text {
            display: block;
            width: 100%;
            line-height: 20px;
            font-size: 16px;
            margin-bottom: 40px;
        }
        & .dln-btn.dln-btn--backToSection {
            width: 184px;
        }
    }
    &__list {
        list-style: inside none;
        //width: 50%;

        width: 100%;
        max-width: 480px;

        li {
            position: relative;
            padding: 16px 0;
            border-bottom: 1px solid $ui-01;
            display: flex;
            flex-direction: row;
            align-content: flex-start;
            flex-wrap: wrap;
            &:last-child {
                border: 0;
                padding-bottom: 0;
            }

            a {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            &:hover {
                .dln-instruction-manuals__listText {
                    .dln-instruction-manuals__listText--title {
                        text-decoration: underline;
                    }
                }
            }
        }
        .dln-icn--iconfont {
            font-size: 48px;
            color: $support-02;
            width: 48px;
            height: 48px;
            margin: 0 12px 0 0;
        }
        &Text {
            @include fontBold;
            color: $brand-01;
            font-size: 15px;
            line-height: 20px;
            width: calc(100% - 48px - 12px);
            align-self: center;

            .dln-instruction-manuals__listText--details {
                display: block;
                @include fontRoman;
                color: $ui-04;
                font-size: 13px;
                line-height: 18px;
                margin-top: 2px;
            }
        }
    }
    &__spellCheck {
        @include fontRoman;
        color: $brand-01;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;

        display: block;
        width: 100%;
    }
    &__searchByCategory {
        @include fontRoman;
        color: $brand-01;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 40px;
    }
    &__product {
        display: flex;
        flex-wrap: nowrap;
        align-content: flex-start;
        margin: 33px auto 0;
        padding-bottom: 32px;
        border-bottom: 1px solid $ui-01;
        width: 100%;
        justify-content: center;
        & > div:nth-of-type(2) {
            width: 50%;
            max-width: 489px;
        }
        &Image {
            width: 50%;
            max-width: 489px;
            height: auto;
            margin-right: 20px;
            img {
                width: 100%;
                height: auto;
            }
        }
        &Sku {
            @include fontBold;
            color: $brand-02;
            font-size: 16px;
            line-height: 20px;
            margin: 2px 0 16px 0;
        }
    }
    & .dln-category-browser__breadcrumbs {
        div {
            display: inline-block;
     
        }
     
        & .dln-breadCrumbSel {  
            display: inline-block;
     
            &__text {
                display: inline-block;
            }
        
            &__text--selected {
                display: inline-block;
                color: var(--brand-02);
     
                & + .dln-breadCrumbSel__arrow {
                    transform: rotate(360deg);
                    display: inline-block;
                }
            }
     
            &__arrow {
                transform: rotate(360deg);
                display: inline-block;
                top: -1px;
                margin-left: 1px;
                font-size: REM(8px);
     
                &--search {
                    top: -1px;
                }
            }   
        }
    }
}

.dln-braun .dln-instruction-manuals .dln-category-browser__breadcrumbs {
    .dln-breadCrumbSel {
        margin-right: 5px;
    
    & .dln-breadCrumbSel__arrow--selected {
        top: 0;
        font-size:REM(13px);
    }

    & .dln-breadCrumbSel__arrow--search {
        font-size:REM(13px);
    }

    & .dln-breadCrumbSel__text--selected {
        @include fontRoman;
        color: var(--brand-02);
    }
}
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) {
    .dln-instruction-manuals {
        &__carousel {
            & .swiper-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;


                & .swiper-slide {
                    width: calc(20% - 16px) !important;

                    & .dln-quickLink {
                        border-bottom: 1px solid $ui-01;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .dln-instruction-manuals {
        &__product {
            &Image,
            & > div:nth-of-type(2) {
                width: 100%;
            }
        }
    }
}

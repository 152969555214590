.dln-delonghi {
  .dln-inputsFile {
    padding: 0;
    flex-wrap: nowrap;

    .dln-btn {
      min-width: initial !important;
      margin-right: 16px;

      @media only screen and (max-width: 599px) {
        min-width: initial !important;
        max-width: initial !important;
      }
    }

    &__label {
      flex: 1;

      &:not(.dln-inputsFile__label--file) {
        border: 1px solid $ui-02;
        border-radius: 2px;
        padding: 0 16px;
      }
      &Text {
        display: none;
      }
    }

    &__innerLabel {
      @include fontBook;
      font-size: REM(14px);
      line-height: 18px;
      color: $ui-02;
    }

    &--error {
      .dln-inputsFile__label {
        border-color: $support-01;
      }
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-recipe-plp-see-all {
        &__toolbar {
            margin-top: 30px;
        }

        &__load-more {
            margin: 0 auto;
        }

        &__sorted-by {
            display: none;
        }

        .dln-breadcrumbs {
            font-size: 13px;
            line-height: 1.38;
        }
        .dln-recipe-grid {
            padding-bottom: 40px;
        }

        .dln-site-search__toolbar {
            .dln-site-search__toolbarFilters {
                display: none;
            }
            .dln-btnTextIcon--braun-filters {
                display: block;
            }
        }
    }
}
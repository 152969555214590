.dln-kenwood {
    .dln-address--editing {
        & .dln-address__foot {
            & .dln-checkbox {
                &+.clearer {
                    margin: 0 0 61px;
                }
                .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
                    @include fontRoman;
                    color: $brand-02;
                }
                &__inputPlaceholder {
                    &::after {
                        font-size: REM(18px);
                        color: var(--brand-05);
                    }
                }
            }
            & .dln-btn--confirm {
                width: auto;
            }
        }

        .dln-wrapper {
            .dln-page__content {
                .dln-address__section {
                    .dln-address__form {
                        .dln-address_info {
                            .dln-tooltip {
                                color: var(--brand-01);
                            }
                        }
                    }
                }
            }
        }

        .dln-address__form {
            .rowEdit {
                .secondEdit {
                    display: flex;
                    align-items: center;
                    .dln-tooltip {
                        color: var(--brand-01);
                    }
                }
            }
        }
    }
    @media only screen and (max-width:599px) {
        .dln-address--editing {
            & .dln-address__foot {
                & .dln-checkbox {
                    &+.clearer {
                        margin: 0 0 29px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-regProductList {
        &--editing {

            .dln-regProductList__sectionFoot {
                padding: 30px 0 0;
                margin: 30px 0 0;
            }
            .dln-noticeBox {
                width: 100%;
                margin: 0 0 32px 0;

                &:last-of-type {
                    margin: 0 0 32px 0;
                }
            }
        }
        & .dln-regProductList__section {
            & .dln-noticeBox  {
                padding: 20px 20px 20px 13px;
    
                &__icon {
                    width: 48px;
                    height: 48px;
                    margin: 0 13px 0 0;
    
                    & .dln-icn {
                        width: 48px;
                        height: 48px;
                        line-height: 48px;
                        font-size: 48px;
                    }
                }
                &__content {
                    width: calc(100% - 48px - 13px);
                }
                &__title {
                    margin-bottom: 5px;
                }
                &:first-of-type {
                    & .dln-noticeBox__icon {
                        height: 44px;

                        & .dln-icn {
                            height: 44px;
                            line-height: 44px;
                            font-size: 37px;
                        }
                    }
                }
            }
        }        
    }
}
.gigya-composite-control-checkbox{
  position:relative;
  margin:12px 0;

  .gigya-checkbox-text{
    font-size:13px;
    font-weight:400;
    color:var(--brand-02);
    margin-left:5px;
    transition:color .12s linear;
  }
}

.gigya-input-checkbox{
  width:16px;
  height:16px;

  + label.gigya-label{

    &:before{
      position:absolute;
      content:'';
      display:block;
      width:14px;
      height:14px;
      border:1px solid var(--ui-02);
      border-radius:2px;
      background-color:var(--brand-03);
      top:0;
      left:0;
      z-index:2;
    }

    &:after{
      position:absolute;
      content:'';
      display:block;
      width:8px;
      height:8px;
      top:4px;
      left:4px;
      border-radius:1px;
      background-color:var(--support-02);
      z-index:5;
      transform:scale(0);
      transition:transform .08s ease;
    }
  }

  &:checked{
    // + label .gigya-checkbox-text{
    //   color:var(--support-02);
    //   font-weight:bold;
    // }

    + label.gigya-label{

      &:after{
        transform:scale(1);
      }
    }
  }
}

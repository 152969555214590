@media only screen and (max-width:599px) {
    .dln-custServiceCard {
        padding: 20px;

        &__title {
            line-height: 27px;
            font-size: 22px;
            margin-bottom: 22px;
        }
        &__text {
            margin-bottom: 26px;
        }
        &__actions {
            & > .dln-btn {
                width: calc(100% - 40px);
                max-width: none;
                margin: 0 auto 10px auto;

                &:last-child {
                    margin: 0 auto;
                }
            }
        }
    }
}

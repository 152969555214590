@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-registration {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 32px 0;
        }
        &__section {
            width: calc(40% - 16px);
            margin: 0 0 0 0;
            &--main {
                width: calc(60% - 16px);
                margin: 0 auto 0 0;
            }
        }
    }
}
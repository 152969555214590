@media only screen and (max-width:599px) {
    .dln-forgPsw {
        padding: 0 0 20px 0;
        .dln-popup__head {
            width: calc(100% - 40px);
            padding: 20px 0 16px 0;
            margin: 0 20px 16px 20px;
            &--circleClose {
                align-items: center;
            }
        }
        &__head {
            width: calc(100% - 40px);
            padding: 20px 0 16px 0;
            margin: 0 20px 16px 20px;

            &Title {
                font-size: 13px;
            }
            & .dln-btnIcon,
            & .dln-btnIcon__wrap {
                width: 32px;
                height: 32px;
            }
        }
        &__text {
            margin: 0 0 24px 0;
            width: 100%;
            left: 0; 
        }
        &__form {
            width: calc(100% - 40px);
            margin: 0 20px 0 20px;

            & .dln-btn[type="button"] {
                margin: 0 0 16px 0;
            }
        }
        &__wrap {
            width: calc(100% - 40px);
            margin: 0 20px 0 20px;
        }
    }
}
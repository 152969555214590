.dln-category-list {
    &__wrapper {
        margin-bottom: 30px;
        + .dln-category-list__wrapper {
            padding-top: 30px;
            border-top: 1px solid $ui-01;
        }
    }

    &__label {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #9b9b9b;
        padding-bottom: 15px;
    }

    &__title {
        @include fontBold;
        font-size: 24px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
    }

    a {
        text-decoration: none;
        color: $ui-01;
    }

    &__text {
        padding: 10px 0 15px 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
    }

    .dln-icn {
        width: 40px;
        height: 40px;
        font-size: 20px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: black;
    }


}

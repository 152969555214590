@media only screen and (max-width:599px) {
    .dln-headCheckout {
        align-items: center;
        height: 60px;

        &__logo {
            width: 58px;
            height: 24px;
            margin: 0;

            &Icon {
                width: 58px;
                height: 24px;                
            }
        }
    }
}   
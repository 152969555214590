.dln-textarea {
    width: 100%;
    margin: 0 0 0;
    &--error {
        border-color: $support-01;
        .dln-textarea__lbl {
            color: $support-01;
        }
    }
    &__input {
        display: block;
        background-color: transparent;
        min-height: 100px;

        &::placeholder {
            opacity: 0;
        }
    }
    &__lbl {
        top: 14px;
    }
    &.dln-inputs--active {
        & .dln-textarea__lbl {
            transform: translateY(0) scale(0.75);
            top: -5px;
        }
        & .dln-textarea__input {
            &::placeholder {
                opacity: 1;
            }
        }
    }
    &.dln-textarea--noLabel {
        & .dln-textarea__input {
            &::placeholder {
                opacity: 1;
            }
        }
    }
    .dln-inputs__extraLbl {
        top: auto;
        &--error {
            color: $support-01;
        }
    }
}

.dln-kenwood {
    & .dln-faq {

        &__chipsWrap {
            margin-bottom: 48px;
        }
        & > .dln-wrapper--fullWidth {
            margin-bottom: 48px;
        }
        & .dln-chips--recallInformation {
            max-width: 590px;
            border-left: 3px solid $support-04 !important;
            margin: 0 auto;

            & .dln-chips__wrapper {
                & .dln-icn--mainIcon {
                    font-weight: bold;
                }
                & .dln-btnIcon--close {
                    width: 13px;
                    height: 13px;
                    top: 17px;
                    right: 16px;

                    & .dln-icn {
                        text-indent: initial;
                        width: 13px;
                        height: 13px;
                        font-size: 0;

                        &::before {
                            font-size: 13px;
                            color: $brand-04;
                        }
                    }
                }
                & .dln-chips__content {
                    & .dln-chips__info {
                        padding-top: 0;
                        margin-bottom: 14px;
                    }
                    & .dln-chips__title {
                        padding-top: 0;
                    }
                }
            }
        }

        &__main {
            justify-content: center;
        }
        &__content {
            justify-content: center;

            & .dln-faq__breadCrumbSelWrap {
                & .dln-breadCrumbSel {
                    height: 24px;

                    &__text {
                        @include fontBold;
                        height: 24px;
                        line-height: 24px;
                        font-size: 20px;
                        color: $ui-07;
                        margin-right: 7px;
                    }
                    &__arrow {
                        height: 24px;
                        line-height: 24px;
                        //padding-top: 2px;
                        color: $ui-07;
                    }
                }
                & .dln-select {
                    height: 24px;

                    &__container {
                        height: 24px;
                    }
                    &__value {
                        line-height: 24px;
                        font-size: 20px;
                        margin-right: 7px;
                    }
                    &__arrow {
                        width: 11px;
                        height: 24px;

                        &::before {
                            display: inline-block;
                            line-height: 20px !important;
                            padding-top: 4px;
                        }
                    }
                }
            }
        }
    }
}
.dln-order--detail {
    & .dln-order__detailProducts--cancelled {
        & .dln-orderHistory {
            opacity: 0.3;
            pointer-events: none;

            & .dln-orderRev {
                &__actions {
                    display: none;
                }
            }
        }
    }
}
.dln-order__prodCancelledBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 6px;
    background-color: $ui-03;
    padding: 22px 20px 30px 20px;
    box-sizing: border-box;
    margin-bottom: 33px;

    &Title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 16px;
        margin: 0 0 8px 0;
    }
    &Text {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
    }
}

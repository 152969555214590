.dln-kenwood {
	.dln-wishlist--empty {
		.dln-wishlist__content {
			.dln-title {
				margin: 0 auto;

				&::after {
					display: block;
					@include fontIcon;
					content: 'j';

					width: 80px;
					height: 80px;
					margin: 0 auto;
					box-shadow: inset 0 0 0 3px $ui-01;
					border-radius: 80px;
					line-height: 80px;
					font-size: 40px;
					color: $ui-01;
					margin-top: 39px;
					margin-bottom: 20px;
				}
			}
			.dln-wishlist__subtitle {
				display: block;
				margin: 0 auto;
				margin-bottom: 24px;

				@include fontExtra;
				text-align: center;
				font-size: 28px;
				line-height: 36px;
			}

			@media only screen and (max-width:599px) {
				.dln-title {
					margin-top: 30px;
					font-size: 32px;
					line-height: 40px;

					&::after {
						margin: 20px auto;
					}
				}
				.dln-wishlist__subtitle {
					font-size: 24px;
					line-height: 32px;
					margin-bottom: 28px;
				}
				.dln-wishlist__foot {
					margin-top: 30px;
				}
			}
		}


	}

}
@media only screen and (max-width: 599px) {
    .dln-unsubscribePage.dln-page {
        .dln-page--imageHeader {
            &__content {
                .dln-page__actions {
                    display: inline-flex;
                    flex-wrap: wrap;

                    .dln-btn {
                        margin-right: 20px;
                        margin-bottom: 15px;

                        &:nth-child(1) {
                            order: 2;
                        }
                        &:nth-child(2) {
                            order: 1;
                        }

                        &.-sms-active {
                            margin-right: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-itemRegDetails {
        width: 100%;
        &__head {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            border-bottom: 1px solid $ui-01;
            padding: 0 0 16px;
        }
        &__itemName {
            @include large;
            @include fontBold;
            width: calc(100% - 120px);
            margin: 0 auto 0 0;
        }
        &__content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 16px 0 0;
        }
        &__itemImg {
            align-items: center;
            align-content: center;
            max-width: 250px;
            height: 218px;
        }
        &__details {
            width: calc(100% - 250px - 20px);
            max-width: 100%;
            &--extra {
                width: 100%;
                .dln-itemRegDetails__paragraph {
                    width: 100%;
                }
            }
        }
        &--productRegistration {
            .dln-itemRegDetails__itemImg {
                align-items: center;
                align-content: center;
                max-width: 250px;
            }
            .dln-itemRegDetails__details {
                width: calc(100% - 250px - 20px);
                max-width: 100%;
                &--extra {
                    width: 100%;
                    max-width: 100%;
                    .dln-itemRegDetails__paragraph {
                        width: 100%;
                    }
                }
            }
        }
    }
}
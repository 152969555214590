.dln-kenwood {
    .dln-profile {
        &__content {
            .dln-title {
                @include fontExtra;
                text-align: center;
                font-size: 38px;
            }
        }
        & .dln-persDataRecap {
            margin: 0 0 50px 0;
        }
        .dln-profileCard {
            margin: 0 0 50px 0;
        }
        &__registerProduct {
            border-width: 0;
            text-align: center;

            & .dln-profile__bannerCopy {
                width: 90%;
                line-height: 20px;
                margin: 15px auto 20px auto;
            }
            >.dln-profile__bannerSubTitle {
                margin: 32px 0 24px;
                padding-top: 24px;
                border-top: 1px solid $ui-01;
                font-size: 20px;
            }
            .swiper-pagination {
                margin: 32px auto 0 !important;
            }
        }
        &__bannerTitle {
            @include fontExtra;
        }
        &__benefitsText {
            text-align: left;
            width: calc(100% - 48px - 20px);
        }
        &__benefits {
            padding-right: 60px;
            .dln-icn {
                color: $brand-01 !important;
                margin: 0 20px 0 0;
            }
            .dln-profile__bannerSubTitle,
            .dln-profile__bannerCopy {
                width: 100%;
            }
            .dln-profile__bannerSubTitle {
                font-size: 16px;
                line-height: 20px;
                margin: 0;
            }
            .dln-profile__bannerCopy {
                color: $brand-04;
                margin: 0;
            }
        }
        &__section {
            padding-bottom: 32px;
            border: 0;

            &--title {
                @include fontExtra;
                text-align: center;
            }

            &Head {
                &Title {
                    @include fontExtra;
                }
            }
        }
        &__recipes {

            background-image: url('#{$pathAssets}/images/bg/bg-recipe-banner-kenwood.jpg');
            padding: 64px 32px 64px 32px;

            &Wrap {
                text-align: center;
                display: block;
                color: $brand-03;
            }
            &Subtitle {
                @include fontMedium;
                width: 100%;
                line-height: 22px;
                font-size: 16px;
            }
            &Title {
                @include fontExtra;
                width: 100%;
                line-height: 40px;
                font-size: 32px;
            }
            &Link {
                @include btn;
                height: 45px;
                line-height: 45px;
                font-size: 12px;
                @include fontBold;
                color: $brand-03;
                background-color: $brand-01;
                width: 100%;
                display: inline-block;
                max-width: 170px;
                text-transform: uppercase;
                transition: all 0.3s linear;
                &:hover {
                    background-color: $brand-04;
                }
            }
        }
        &--01 {
            .dln-profile__section {
                border-bottom: 1px solid $ui-01;
                &:last-of-type {
                    border-width: 0;
                }
                .dln-profile__sectionContent {
                    .swiper-pagination {
                        margin: 24px auto 0;
                    }
                }
            }
            .dln-profile__itemsCarousel {
                background-color: $brand-03;
                padding-top: 0;
                .dln-profile__itemsCarouselTitle {
                    @include fontExtra;
                    color: $brand-04;
                    font-size: 28px;
                    margin-top: 32px;
                    margin-bottom: 32px;
                }
                .dln-wrapper--carousel {
                    border-top: 1px solid $ui-01;
                }
                .swiper-pagination {
                    margin: 32px auto 0 !important;
                }
            }
        }
        @media only screen and (max-width:599px) {
            & .dln-profileCard {
                display: flex;
                margin: 0 0 20px 0;
            }
            &:not(.dln-profile--01) .dln-profile__content .dln-title {
                margin-bottom: 20px;
            }
            .dln-profile__bannerTitle {
                font-size: 28px;
            }
            >.dln-wrapper {
                padding-top: 16px !important;
                padding-bottom: 0;
            }
            .dln-profile__aside {
                .dln-profileCard {
                    display: flex;
                    padding-bottom: 16px;
                    margin-bottom: 24px;
                }

            }
            .dln-profile__section {
                padding-bottom: 30px;
                margin-bottom: 30px;
                &--recipes {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }
            .dln-profile__registerProduct {
                >.dln-profile__bannerSubTitle {
                    font-size: 18px;
                }
                .dln-profile__benefits {
                    margin-bottom: 8px;
                }
                .swiper-container {
                    .swiper-pagination {
                        margin: 30px auto 0 !important;
                        width: 100%;
                    }
                }
            }
            .dln-persDataRecap {
                margin-bottom: 40px;
            }
            .dln-profile__recipes {
                margin-bottom: 0px;
                &Title {
                    font-size: 28px;
                }
            }
            .dln-profile__sectionHead {
                margin: 0 0 20px;
            }
            &.dln-profile--01 {
                .dln-profile__itemsCarousel {
                    .dln-profile__itemsCarouselTitle {
                        font-size: 24px;
                    }
                }
            }


        }
        @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
            .dln-profile__sectionHead {
                margin: 0 0 14px;
            }
        }
    }
}
[data-dlg-gigya="dlg-gigya-register-container"] {
	>.gigya-screen-content.gigya-hidden {
		display: block !important;
	}
}

[data-screenset-element-id="gigya-register-screen"] {
	form {
		.gigya-layout-row:nth-child(2) {
			.gigya-composite-control-dropdown.dlg-gigya-input-select-wrapper {
				width: 100%;
			}

			.dlg-gigya-input-select-wrapper {
				width: 47%;
			}

			@include breakpoint('gigya') {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				.gigya-composite-control,
				.gigya-container-enabled {
					width: 47%;

					&--fullWidth {
						width: 100%;
					}

					&--mb-big {
						margin-bottom: 25px;
					}
				}

				.gigya-composite-control.js-sms-component {
					width: 100%;
				}
			}


			.gigya-composite-control,
			.gigya-container-enabled {
				margin-bottom: 10px;

				&--fullWidth {
					width: 100%;
				}

				&--mb-big {
					margin-bottom: 25px;
				}
			}
		}

		.dln-inputs__extraLblFix {
			width: calc(95% - 12px);
			max-width: 200px;
			font-size: 13px;
			line-height: 125%;
			display: block;
			color: var(--brand-02);
			transform: scale(0.75);
			margin: 0;
		}

		.dln-registrationForm__paragraph,
		.dln-registrationForm__subtitle {
			font-size: 10px;
		}

	}

	.dln-registrationForm__paragraph.dln-registrationForm__paragraph--secondary {
		text-align: center;
		margin: 32px 0;

		@include breakpoint('gigya') {
			text-align: left;
		}
	}

	.gigya-composite-control.gigya-composite-control-submit.dlg-gigya-button-wrapper,
	.dln-btn.dln-btn--stroked {

		@include breakpoint('gigya') {
			width: 50%;
		}

	}

	// SMS Label styling
	.gigya-composite-control.js-sms-component.dlg-gigya-input--active {
		label {
			transform: none;
			background-color: transparent;
		}
	}

	.gigya-composite-control.js-sms-component {
		label {
			top: calc(50% - 18px);
			color: var(--brand-02) !important;
		}

		input {
			padding-left: 65px !important;
		}
	}

}

.dln-braun [data-screenset-element-id="gigya-register-screen"] {
	form {
		.gigya-composite-control.js-sms-component {
			label {
				top: calc(50% - 20px);
			}
		}
	}
}

.dln-kenwood [data-screenset-element-id="gigya-register-screen"] {
	form {
		.gigya-composite-control.js-sms-component {
			label {
				top: 36px;
				left: 5px;
			}

			input {
				padding-left: 50px !important;
			}
		}
	}
}

.hr--solid {
	width: 100%;
	height: 1px;
	background-color: lightgrey;
	margin: 24px 0;
}

.dln-inputs__note {
	width: 47%;
	font-size: 13px;
	line-height: 150%;
	color: var(--brand-02);
	margin: 25px 0;
}

.dln-registrationForm__paragraph {

	&--secondary {
		display: block;
		font-size: 10px;
		line-height: 150%;
		color: var(--brand-02) !important;
		margin: 8px 0 24px;

		a {
			color: var(--brand-02) !important;
			text-decoration: underline;
		}
	}
}

.boldText {
	display: block;
	font-size: 10px;
	line-height: 150%;
	color: var(--ui-05);
	margin: 8px 0 24px;

	&:before {
		content: "* ";
	}
}

.gigya-composite-control-multi-choice {

	.gigya-multi-choice-item {
		display: inline-block;
		margin: 20px 48px 20px 0;
		position: relative;

		@supports (display:inline-flex) {
			display: inline-flex;

			.gigya-input-radio+label {
				text-indent: 6px;
			}
		}
	}
}

.h-mb-25 {
	display: block;
	margin-bottom: 25px;
}
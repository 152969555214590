.dln-kenwood {
    .dln-price {
        line-height: 20px;
        font-size: 14px;
        max-height: none;
        &--discounted {
            color: $brand-04;
        }
        &--oldPrice {
            color: #6b6b6b;
            &::after {
                background: #6b6b6b;
                top: calc(50% - 1px);
            }
        }
        &--freeshippingtarget {
            line-height: 16px;
            font-size: 12px;
        }
    }
}
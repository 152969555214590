[data-screenset-element-id="gigya-complete-registration-screen"] {
  form {
    .gigya-required-display {
      visibility: hidden;
    }
  }

  p.boldText {
    margin-top:25px;
  }

  .h-mb-25 {
    display: block;
    margin-bottom: 25px;
  }

  .dln-completeForm .gigya-style-modern {
    width: 100%;
  }
}

.dln-kenwood  {
  [data-screenset-element-id="gigya-complete-registration-screen"] {
    .gigya-input-submit {
      text-transform: uppercase;
    }
  }
}
.dln-kenwood {
    & .dln-page.dln-order {
        &--status {
            & .dln-wrapper {
                &--heading {
                    padding-top: 59px;
                    margin-bottom: 70px;

                    & .dln-order__text {
                        line-height: 20px;
                        color: $brand-04;
                    }
                }
                &--userCards {
                    padding: 0 0 100px 0;

                    & .dln-order__userCard {
                        // width: 50%;
                        width: 358px;
                        // padding: 0 24px 0 0;
                        border: none;
                        // max-width: 358px;
                        padding: 0 24px 0 calc(50% - 358px - 24px);
                        box-sizing: content-box;

                        & .dln-order__title {
                            line-height: 22px;
                            font-size: 16px;
                            color: $brand-04;
                            margin-bottom: 10px;
                        }
                        & .dln-order__text {
                            color: $brand-04;
                            margin-bottom: 30px;
                        }
                        & > .dln-btn {
                            width: 100%;
                        }
                        &--newUser {
                            padding: 0 calc(50% - 358px - 24px) 0 24px;

                            & .dln-order__text {
                                margin-bottom: 30px;
                            }
                            & .dln-order__userCardForm {
                                margin-bottom: 30px;

                                & .dln-inputs {
                                    display: flex;
                                    flex-wrap: wrap;
                                    margin-bottom: 20px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    & .dln-inputs__input {
                                        order: 2;
                                    }
                                    & .dln-inputs__lbl {
                                        order: 1;
                                        line-height: 16px;
                                        margin-bottom: 5px;
                                        position: static;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-page.dln-order {
            &--status {
                & .dln-wrapper {
                    &--heading {
                        padding-top: 30px;
                        margin-bottom: 40px;

                        & .dln-title {
                            margin: 0 0 20px 0;
                        }
                        & .dln-order__text {
                            text-align: center;
                        }
                    }
                    &--userCards {
                        padding: 0 20px;
                        margin: 0;

                        & .dln-order__userCard {
                            width: 100%;
                            padding: 0;
                            margin-bottom: 60px;

                            & .dln-order__title {
                                text-align: center;
                                margin-bottom: 12px;
                            }
                            & .dln-order__text {
                                line-height: 20px;
                                font-size: 14px;
                                text-align: center;
                            }
                            &--newUser {
                                margin-bottom: 0;

                                & .dln-order__text {
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dln-delonghi {
  .dln-advtBox {
    & .dln-icn {
      color: $brand-04;
    }
    &__title {
      @include fontMedium;
      font-size: REM(18px);
      line-height: 1.07;
    }
    &__desc {
      font-size: REM(14px);
      line-height: 1.3;
      color: $brand-04;
    }
  }
}

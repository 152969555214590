.dln-delonghi {
    .dln-search-results {
        &__grid {
            margin-right: -10px;
            margin-left: -10px;
    
            .dln-search-result {
                margin: 20px 10px 0;
                width: calc(50% - 20px);
            }
        }
    }
}
.dln-changePssw {
    align-content: flex-start;

    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {

        & .dln-title {
            margin: 0 0 32px 0;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        margin: 0 0 64px 0;

        &Title,
        & .dln-title {
            display: block;
            width: 100%;
            height: auto;
            line-height: 32px;
            font-size: 32px;
            @include fontBold;
            margin: 0 0 32px 0;
            &--labeled {
                display: flex;
            }
        }
        &--deleteAccount {
            width: 100%;
            border-top: 1px solid $ui-01;
            transition: all .3s;
            & .dln-btn--link {
                margin: 24px 0;
            }
            & .dln-changePssw__paragraph {
                margin: 0 0;
            }
            ul {
                margin: 0 0 24px 0;
                padding: 0 0 0 24px;
                list-style: none;
                font-size: 13px;
                line-height: normal;
                li {
                    padding: 0 0 0 24px;
                    margin: 0 0 8px;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: calc(50% - 5px);
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: $brand-01;
                    }
                }
            }
            & .dln-btn:not(.dln-btn--link) {
                width: 220px;
                max-width: 100%;
                margin: 32px 0 0;
            }
        }
    }
    &__paragraph {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        font-weight: 400;
        color: $brand-04;
        margin: 0 0 20px 0;

        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
    }
    &__underlinedText {
        color: $brand-02;
        text-decoration: underline;

        &:hover {
            color: $brand-04;
        }
    }
    &__subtitle {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 13px;
        @include fontBold;
        // text-transform: uppercase;
    }
    &__radioGroup {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin: 0 0 8px 0;
    }
    & .dln-radioBtn {
        margin: 0 48px 0 0;
    }
    &__section:first-of-type .dln-changePssw__paragraph--info:last-of-type {
        margin: 0;
    }
    & .dln-checkbox {
        margin: 0 0 10px 0;
    }
    & .dln-smsCheck {
        margin: 0 0 10px 0;

        & .dln-checkbox {
            margin: 0 0 0 0;
        }
    }

    &__sectionFoot {
        border-top: 1px solid $ui-01;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 100%;
        height: auto;
        padding: 40px 0 0 0;
        & .dln-btn--cancel {
            @include fontBold;
            margin: 0 auto 0 0;
        }
        & .dln-btn--update {
            width: 184px;
            margin: 0 0 0 10px;
        }
    }
    &__formcontainer {
        width: 100%;
    }
    &__field {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 32px;
        &>.dln-inputs,
        &>.dln-select {
            width: calc(50% - 8px);
            margin: 0 16px 0 0;
            max-width: none;
            &:nth-child(2n+2) {
                margin: 0 0;
            }
        }
    }
    &__middleTitle {
        font-size: 18px;
        line-height: 22px;
        @include fontBold;
        margin: 24px 0;
    }
}
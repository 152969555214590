.dln-kenwood {
    .dln-savedCart {
        &__cart {
            border-width: 0;
            border-bottom: 2px solid $ui-07;
            margin-bottom: 50px;
            padding: 0 0 50px 0;
            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            .dln-accordion {
                border-top: 1px solid $ui-01;
                &__title {
                    @include fontBold;
                    line-height: 16px;
                }
                &__content {
                    padding-bottom: 10px;
                    padding-top: 3px;
                }
                &+.dln-accordion {
                    border-bottom: 1px solid $ui-01;
                    border-top: none;
                }
                &:nth-child(even) {
                    background-color: transparent;
                }
                .dln-price {
                    margin-bottom: 0;
                    @include fontLight;
                    padding-left: 100px;
                    &--secondary {
                        color: $ui-10;
                        padding-left: 76px;
                    }
                }
            }
        }
        &__cartTitle {
            @include fontExtra;
            font-size: 32px;
            line-break: normal;
            margin-bottom: 24px;
        }
        &__cartFoot {
            margin: 32px 0 0;
            .dln-btn {
                @include fontSemibold;
                align-self: flex-end;
                &.dln-btn--link {
                    text-transform: none;
                    text-decoration: underline;
                    line-height: normal;
                    @include fontMedium;
                }
            }
        }
        @media only screen and (max-width:599px) {
            .dln-title {
                margin-top: 11px;
            }
            &__cartTitle {
                font-size: 28px;
                line-height: normal;
                margin-bottom: 8px;
            }
            .dln-accordion {
                &__title {
                    max-width: calc(100% - 16px - 80px);
                }
                &__content {
                    padding-top: 0;
                    margin-top: -5px;
                    padding-bottom: 14px;
                }
                .dln-price {
                    display: block;
                    width: 100%;
                    padding-left: 76px;
                    &--secondary {
                        padding-bottom: 5px;
                    }
                }
            }

            &__aside {
                .dln-vertMenu {
                    margin: 0;
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-totalSect {
        &__title {
            width: 100%;
            margin: 0 0 18px 0;
        }
        &__wrap {
            width: 100%;
            padding: 0;
        }
    }
}

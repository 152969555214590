.dln-btnTextIcon {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% - 14px);
    height: auto;
    height: 40px;
    border: 1px solid $brand-01;
    border-radius: 20px;
    background-color: $brand-03;
    /* padding: 0 10px 0 4px; */
    padding: 0 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;

    &__wrap {
        display: inline-flex;
        align-items: center;
        width: auto;
        max-width: 100%;
        height: 38px;
        font-size: 0;
        padding: 0 0 0 0;
        margin: 0 auto;

        @media (-ms-high-contrast: none) {
            justify-content: center;
            width: 100%;
            margin: 0;
        }
    }
    &__iconWrap {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 38px;
        line-height: 38px;
        /* padding: 7px 0 7px 0; */
        margin: 0 0 0 0;

        & .dln-icn {
            height: 38px;
            line-height: 38px;
            font-size: 16px;
            text-align: center;
        }
        // & .bra-icn {
        //     font-size: 16px;
        // }
    }
    &__text {
        @include fontBold;
        display: inline-block;
        width: auto;
        max-width: calc(100% - 24px);
        height: 33px;
        line-height: 33px;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: top;
        overflow: hidden;
    }
    &--add {
        & .dln-btnTextIcon__iconWrap {
            & .dln-icn {
                font-size: 13px;
            }
        }
    }
    &--reverse {
        & .dln-btnTextIcon__wrap {
            flex-direction: row-reverse;
        }
        & .dln-btnTextIcon__text {
            margin: 0 10px 0 0;
        }
    }
    &--widthAuto {
        width: auto;
        max-width: none;
        border: none;
        border-radius: 0;
        padding: 0;
    }
    &--brand-01 {
        background-color: $brand-01;

        & .dln-btnTextIcon__iconWrap {
            & .dln-icn {
                color: $brand-03;
            }
            & .bra-icn {
                color: $brand-03;
            }
        }
        & .dln-btnTextIcon__text {
            color: $brand-03;
        }
    }
    &--filters {
        & .dln-btnTextIcon__iconWrap {
            width: 20px;
        }
        & .dln-icn {
            width: 20px;
            font-size: 20px;
            // line-height: 20px;
        }
    }
}

@import "./_button-text-icon-kenwood";
@import "./_button-text-icon-delonghi";

@media only screen and (max-width: 599px) {
  .dln-cart {
    padding-right: 0 !important;
    padding-left: 0 !important;

    & > .dln-wrapper {
      padding: 0;
    }

    &__head {
      align-items: center;
      min-height: 60px;
      padding: 0;
      margin: 0;

      & > .dln-chips {
        .dln-chips__wrapper {
          justify-content: space-between;
        }

        &.dln-chips--advantage {
          .dln-chips__content {
            margin: 0;

            span:not(.dln-chips__strong) {
              font-size: 13px;
            }
          }

          .close {
            margin: 0;
            font-size: 10px;
          }
        }
      }
    }

    &__titleWrapper {
      margin-top: 10px;
    }

    &__title {
      line-height: 17px;
      font-size: 17px;
      padding: 0 0 0 0;

      & .dln-icn {
        font-size: 22px;
        margin-right: 5px;
      }

      &Lbl {
        line-height: 20px;
        margin: 0 0 0 4px;
      }

      & + .dln-btn {
        height: 24px;

        & .dln-icn {
          line-height: 24px;
        }
      }
    }

    &__content {
      width: 100%;
      margin: 0 0;
      padding: 0;

      & .dln-cartItem__closeBtn {
        right: 0;
      }
    }

    &__items {
      .dln-cartItem {
        padding: 32px 0;

        & .dln-chips {
          &--lightGray {
            border: none;
          }
        }
      }
    }

    &__services {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      padding-left: 0;

      .dln-checkServiceBox {
        width: 100%;
        min-height: auto;
        padding: 32px 24px;
        margin: 0 0 16px 0 !important;

        .dln-checkServiceBox__iconWrap {
          margin: 0 24px 0 0;
        }
        .dln-checkServiceBox__text,
        .dln-btn {
          width: 100%;
          text-align: left;
        }
      }
    }

    &__aside {
      width: 100%;

      .dln-cart__orderRecap {
        width: 100%;
        border-width: 0;
        padding: 0;
        position: relative;
        &::before,
        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background-color: $ui-03;
          z-index: -1;
          top: 0;
        }

        &::before {
          right: 100%;
        }

        &::after {
          left: 100%;
        }
      }

      > .dln-btn {
        display: block;
        margin: 32px 0 0 20px;
      }

      .dln-cart__noticeBox {
        gap: 20px;
        margin: 0 20px 20px;

        & .dln-noticeBox--light {
          width: 100%;
        }
      }

      &--secondary {
        display: flex;
        max-width: 100%;
        margin: 0 auto;

        .dln-cart__noticeBox {
          display: block;
        }
      }
    }

    & .dln-cart__button-wrapper {
      padding: 0;
    }

    &__noticeBox {
      max-width: 100%;

      > p {
        display: none;
      }

      .dln-noticeBox {
        width: 100%;

        &:nth-child(even) {
          &::after {
            content: '';
            width: calc(100% - 48px);
            height: 0px;
            position: absolute;
            top: auto;
            bottom: 0;
            right: 24px;
          }
        }
      }
    }
  }
}

.dln-braun {
  & .dln-cart__head {
    padding: 12px 20px;
  }

  & .dln-cart__head {
    padding: 12px 20px;
  }

  & .dln-cart__content {
    padding: 0 20px;
  }

  & .dln-cart__title {
    margin-bottom: 30px;
    & .dln-cart__titleLbl {
      font-size: 13px;
    }
  }

  & .dln-cart__aside .dln-cart__noticeBox {
    margin: 0;
  }
}

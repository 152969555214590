.dln-kenwood {
    & .dln-orderDetailInfo {
        background: $ui-03;
        padding: 30px 20px;

        & .dln-orderDetailInfo__col {
            &:not(:first-of-type) {
                padding-top: calc(24px + 12px);
            }
            & .dln-orderDetailInfo__title {
                line-height: 24px;
                font-size: 20px;
                color: $brand-04;
            }
            & .dln-orderDetailInfo__subtitle {
                line-height: 22px;
                font-size: 16px;
                margin-bottom: 5px;
            }
            & .dln-orderDetailInfo__data {
                line-height: 20px;
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-orderDetailInfo {
            & .dln-orderDetailInfo__col {
                width: 100%;
                border-bottom: 1px solid $ui-01;
                padding: 0 0 10px 0 !important;
                margin: 0 0 10px 0;

                &:first-child {
                    border: none;
                    padding: 0 !important;
                    margin-bottom: 14px;
                }
                &:last-child {
                    border-bottom: none;
                    padding-bottom: none;
                    padding-bottom: 0 !important;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.dln-kenwood {
    .dln-btnIcon {
        &__itemCounter {
        	right: -10px;
			top: 2px;
            background-color: $brand-01;
        }
        &__itemCounterText{
        	font-size: 10px
        }
    }

    .dln-btnIcon.hasDropdown:hover {
        position: relative;
        &::after {
            content: '';
            background: rgba(0,0,0,0);
            width: 200%;
            height: 200%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        &.dln-btnIcon--user {
            .dln-drawer {
                display: block;
                visibility: visible;

                left: 50%;
                transform: translateY(0) translateX(-50%);
            }
        }
        
    }
}

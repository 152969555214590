@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-recipe-by-products {
        &__selects-wrap {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            flex-direction: column;

            .dln-select {
                width: 100%;
                max-width: none;
    
                + .dln-select {
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }

        .dln-site-search__toolbar {
            .dln-site-search__toolbarFilters {
                display: none;
            }
            .dln-btnTextIcon--braun-filters {
                display: block;
            }
            .bra-btnView {
                display: flex;
            }
        }
    }
}

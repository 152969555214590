.dln-kenwood {
    & .dln-advtBox {
        height: auto;
        border: none;
        padding: 0 0 0 70px;
        position: relative;

        & .dln-icn {
            width: 50px;
            height: 50px;
            line-height: 50px;
            font-size: 50px;
            color: $brand-01;
            position: absolute;
            top: calc(50% - 25px);
            left: 0;
        }
        &__title {
            margin: 0;
        }
        &__desc {
            font-size: 14px;
            line-height: 20px;
            color: $brand-04;
        }
    }    
}
.dln-delonghi {
  & .dln-faq {
    &.dln-faq--singleFaq {
      padding-bottom: 73px;

      & .dln-faq__content {
        & > .dln-wrapper {
          & .dln-faq__mainSubtitle {
            @include fontMedium;
            margin-bottom: 19px;
          }
          & .dln-faq__mainSubtitle,
          & .dln-faq__text,
          & .dln-faq__textList,
          & .dln-faq__text,
          & .dln-faq__videoContainer,
          & .dln-faq__articleFoot,
          & .dln-faq__title,
          & .dln-list {
            margin-right: auto;
            margin-left: auto;
          }
          & .dln-faq__mainSubtitle {
            & + .dln-faq__text {
              margin-bottom: 0;
            }
          }
          & .dln-faq__text,
          & .dln-faq__text p {
            line-height: 24px;
            font-size: REM(16px);
          }
          & .dln-faq__textList,
          ul {
            list-style-type: none;

            &Item,
            & li {
              line-height: 24px;
              font-size: REM(16px);
              padding-left: 10px;
              position: relative;

              &::before {
                content: '-';
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 10px;
                height: 24px;
                line-height: 24px;
                font-size: REM(16px);
                position: absolute;
                top: 0;
                left: 0;
              }
            }
            & + .dln-faq__text {
              margin-bottom: 24px;
            }
          }

          & .dln-faq__videoContainer {
            margin-bottom: 32px;
          }
          & .dln-faq__articleFoot {
            min-height: 70px;
            padding: 20px 0;
            margin-bottom: 78px;

            & .dln-faq__articleFootText {
              line-height: 18px;
              font-size: REM(14px);
              margin-right: 40px;
            }
            & .dln-btn {
              &--stroked {
                width: 80px;
                min-width: initial;
                height: 30px;
                font-size: REM(15px);

                &:first-child {
                  margin-right: 20px;
                }
              }
              &--link {
                &:first-child {
                  margin-right: 40px;
                }
              }
            }
          }
          & .dln-faq__title--related {
            line-height: 34px;
            font-size: REM(28px);
            margin-bottom: 14px;

            & + .dln-list {
              margin-bottom: 0;
            }
          }
        }
      }
      & .dln-quickLinks--slider {
        & > .dln-wrapper {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    & .dln-faq {
      &.dln-faq--singleFaq {
        padding-bottom: 64px;

        & .dln-faq__content {
          & > .dln-wrapper {
            & .dln-faq__breadCrumbSelWrap {
              margin-bottom: 20px;
            }
            & .dln-faq__mainSubtitle {
              line-height: 30px;
              font-size: REM(28px);
              margin-bottom: 16px;
            }
            & .dln-faq__text {
              line-height: 24px;
            }
            & .dln-faq__text,
            & .dln-faq__textList .dln-faq__textListItem {
              font-size: REM(15px) !important;
            }
            & .dln-faq__textList {
              & + .dln-faq__text {
                margin-bottom: 40px;
              }
            }
            & .dln-faq__videoContainer {
              margin-bottom: 40px;
            }
            & .dln-faq__articleFoot {
              padding: 24px 0;
              margin-bottom: 16px;

              & .dln-faq__articleFootText {
                width: auto;
                height: 30px;
                line-height: 30px;
                margin: 0 auto 0 0;
                margin-bottom: calc(24px + 1px + 5px);
              }
              & .dln-btn {
                &--stroked {
                  margin-bottom: calc(24px + 1px + 5px);

                  &.dln-btn--no {
                    margin: 0;
                  }
                }
                &--link {
                  line-height: 18px;
                  margin-top: 0;
                }
              }
            }
            & .dln-faq__title--related {
              font-size: REM(21px);
              line-height: 23px;
              margin-bottom: 19px;

              & + .dln-list {
                margin-top: 0;
              }
            }
          }
        }
        & .dln-quickLinks--slider {
          & .dln-wrapper {
            & .dln-quickLinks__inner {
              & .dln-quick-links-slider__carousel {
                & .swiper-pagination {
                  display: flex !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

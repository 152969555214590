.dln-delonghi {
  .dln-savedCart {
    &__cart {
      padding: 24px 20px 28px 20px;

      &Title {
        @include fontMedium;
        font-size: REM(28px);
        line-height: 34px;

        margin-bottom: 20px;
      }

      &Foot {
        align-items: flex-end;
        margin-top: 24px;

        .dln-btn:not(.dln-btn--link):not(.dln-btn--stroked):not(.dln-btn--social):not(.dln-btn--paypal):not(.dln-btn--secondary) {
          background: $brand-01;
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    .dln-savedCart {
      &__cart {
        &Title {
          font-size: REM(21px);
          line-height: 23px;
          margin-bottom: 25px;
        }
      }
    }
  }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {

}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width: 1188px) {
    .dln-profile{

        .dln-wishProd{
            display: flex;
            flex-direction: column;
            width: calc(50% - 5px);
            max-width: 100%;
            position: relative;

            &__data {
                flex: 1;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 100%;
                margin: 8px 0 0;
            }

            &__imgLink {
                width: 100%;
                height: 146px;
                margin: 0;
            }
            &__imgWrap {
                display: flex;
                align-items: center;
                width: 100%;
                height: 146px;
                background-color: $ui-03;

                & > img {
                    display: block;
                    max-width: 182px;
                    height: auto;
                    padding: 0;
                    margin: 0 auto;
                    object-fit: initial;
                }
            }
        }

    }
}

$gapField: 24;
.dln-contactUs {
  &__enquiryWrapper {
    width: 100%;
    margin-bottom: 20px;
    .dln-select {
      max-width: unset;
      width: 100%;
      @media screen and (min-width: map-get($grid-breakpoints, md)) {
        width: calc(50% - 24px);
      }
      @media screen and (min-width: map-get($grid-breakpoints, lg)) {
        width: calc(33.3333% - 32px);
      }
    }
  }

  &__fieldsWrapper {
    width: 100%;
    margin-bottom: 30px;

    .dln-select,
    .dln-inputs,
    .dln-textarea,
    .dln-inputsFile {
      width: 100%;
      margin-bottom: 35px;
      max-width: unset;
      padding-bottom: 0;
    }

    .dln-textarea {
      height: 114px !important;
    }

    // this hide the border on the last column, trick since we are using macy.js library for masonry
    &::after {
      content: '';
      position: absolute;
      right: -25px;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: $brand-03;
    }
  }
  &__field {
    // border-right: 1px solid $ui-01;

    &::after {
      content: '';
      position: absolute;
      width: 1px;
      top: 0;
      bottom: 0;
      right: -24px;
      background-color: $ui-01;
    }

    &.--split {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > * {
        width: calc(70% - 15px);
        &:last-child {
          width: 30%;
        }
      }
    }
  }
}

// kenwood
.dln-kenwood {
  .dln-contactUs__fieldsWrapper {
    .dln-inputs {
      height: 45px;
    }
    .dln-select {
      margin-top: 21px;
      margin-bottom: 36px;
    }
    .dln-select__container {
      height: 43px;
    }
    .dln-textarea {
      height: 144px !important;
    }
    .dln-textarea__input {
      height: 100%;
    }

    .dln-inputsFile {
      padding-top: 0;
      margin-top: 18px;
      margin-bottom: 39px;
      .dln-btn {
        height: 43px;
      }
      &__labelText {
        top: -23px;
      }
    }
  }
}

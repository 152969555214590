.dln-delonghi {
  .dln-paginationSlide {
    border: none;
    justify-content: center;
    &__currentLine {
      display: none;
    }

    &__btn {
      color: $brand-03 !important;
      position: relative;
      width: 36px;
      height: 31px;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }
    }

    &__tabs {
      width: auto;
    }
    &__tab {
      background: transparent;
      font-size: REM(21px);
      box-sizing: border-box;
      position: relative;
      color: $ui-02;
      width: 36px;
      height: 27px;

      &.-current {
        color: $brand-04;
        font-weight: 500;

        &::after {
          content: '';
          width: 12px;
          height: 2px;
          background: $brand-04;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-paginationSlide {
    }
  }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-order {
        &--status {
            & .dln-order__userCard {
                width: 50%;

                &--registered {
                    padding-right: 30px;
                }
                &--newUser {
                    padding-left: 30px;
                }
            }
        }
    }
}

.dln-delonghi {
  & .dln-newUserForm {
    &__content {
    }
    &__paragraph {
      line-height: 24px;
      font-size: REM(16px);
      color: $brand-04;
    }
    &__section {
      & .dln-inputs {
        margin: 20px 0 0 0;

        & + .dln-btn--confirm {
          width: 100%;
          margin-top: 27px;
        }
      }
      &:not(.dln-newUserForm__section--main) {
        margin-top: 40px;

        & > .dln-btn--stroked {
          width: 100%;
          margin-top: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-newUserForm {
      &__content {
        & .dln-newUserForm__paragraph {
          font-size: REM(15px);
          line-height: 23px;
        }
      }
      &__section {
        &--main {
          & .dln-btn {
            margin-top: 21px !important;
          }
        }
        &:not(.dln-newUserForm__section--main) {
          margin-top: 30px;
        }
      }
    }
  }
}

.dln-faq {
    &__helpSection {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        height: auto;
        margin: 0 0 64px 0;

        &Title {
            display: block;
            width: 50%;
            line-height: 32px;
            font-size: 32px;
            font-weight: bold;
            padding-right: 50%;
            margin-bottom: 40px;
        }
        &Text {
            display: block;
            width: 100%;
            line-height: 20px;
            font-size: 16px;
            margin-bottom: 40px;
        }
        & .dln-btn.dln-btn--backToSection {
            width: 184px;
        }
    }
    &__content {
        &.-hideBreadcrumbs {
            padding-top: 40px;
            
            & .dln-faq__breadCrumbSelWrap {
                display: none;
            }
        }
    }
}
.dln-delonghi {
  & .dln-wishlist.dln-wishlist--public {
    & .dln-wrapper {
      & .dln-wishlist__content {
        & .dln-wishlist__signInNotice {
          display: flex;
          justify-content: center;
          line-height: 24px;
          font-size: REM(16px);
          margin-bottom: 34px;

          & > .dln-btn--link {
            line-height: 24px;
            font-size: REM(16px);

            &:first-child {
              margin: 0 3px 0 0;
            }
            &:nth-child(2) {
              margin: 0 3px;
            }
          }
        }
        & .dln-wishlist__text {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-wishlist.dln-wishlist--public {
      & .dln-wrapper {
        padding: 0 20px;
        box-sizing: border-box;

        & .dln-wishlist__content {
          & .dln-title {
            margin-bottom: 14px;
          }
          & .dln-wishlist__signInNotice {
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
    .dln-errorPage {
        & .dln-wrapper {
            &--fullWidth {
                /*  background-image: url('#{$pathAssets}/images/bg/bg-error-page-mobile.jpg'); */
                padding-top: 24px;
                padding-bottom: 152px;
            }
        }
        &__wrap {
            width: 100%;

            & .dln-inputs--searchBar {
                width: 100%;
            }
        }
        &__mainTitle {
            line-height: 40px;
            font-size: 35px;
            span {
                font-size: 15px;
            }
        }
        &__title {
            line-height: 26px;
            font-size: 22px;
        }
        & .dln-wrapper--content {
            & .dln-btn--backToHome,
            & .dln-btn--customerService {
                width: 60%;
            }
            & .dln-btn--backToHome {
                margin: 0 auto 16px 0;
            }
            & .dln-btn--customerService {
                margin: 0 auto 0 0;
            }
        }
    }
}

.dln-delonghi {
  .dln-order {
    &--cancellation {
      .dln-order {
        &__backToOrderHistory {
          i,
          span {
            color: $brand-04 !important;
          }

          span {
            @include fontMedium;
          }
        }

        &__cancellationTitle {
          font-size: REM(50px);
          @include fontMedium;
        }

        &__cancellationData {
          color: $ui-02;
          margin-bottom: 24px;

          strong {
            color: $brand-04;
            @include fontMedium;
            font-size: REM(18px);
            margin-left: 6px;
          }
        }

        &__cancellationMessage {
          font-size: REM(16px);
        }

        &__cancellationWrap {
          .dln-btn {
            margin: 0;
            box-sizing: border-box;
            width: 183px;
            min-width: 0;

            padding: 0;

            &--disabled {
              background-color: $ui-04 !important;
            }
          }
          .dln-select__container {
            .dln-select__value {
              color: $ui-02;
            }
          }
          .dln-select__container.dln-select--has-value {
            .dln-select__value {
              color: $brand-04;
            }
          }
        }
      }

      .dln-orderHistory {
        background: $ui-05;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 24px;
        padding-bottom: 10px;
        &__content {
          .dln-orderRev__content {
            justify-content: flex-start !important;
          }
        }
      }

      .dln-totalSect.dln-totalSect--lite {
        border: none;
        padding-top: 0;
        transform: translateY(-20px);

        .dln-totalSect__row.dln-totalSect__row--total {
          margin-top: 0 !important;
        }

        .dln-totalSect__data {
          @include fontMedium;
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    .dln-order {
      &--cancellation {
        .dln-order {
          &__cancellationTitle {
            font-size: REM(37px);
            line-height: 1.08;
          }

          &__cancellationData {
            margin-right: 16px;
            strong {
              @include fontRoman;
            }
          }

          &__cancellationMessage {
            margin-bottom: 24px;
          }
        }

        .dln-orderHistory {
          padding: 16px;
          padding-right: 30px;
          &__content {
            .dln-orderRev__content {
              justify-content: flex-start !important;
            }
            .dln-orderRev__details {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    .dln-order {
      &--cancellation {
        .dln-order {
          &__cancellationWrap {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            height: auto;

            .dln-select.dln-select--selectAMotivation {
              width: 100%;
              margin: 0;
              margin-bottom: 16px;
            }
          }
        }
        .dln-orderHistory {
          &__content {
            .dln-orderRev__content {
              justify-content: center !important;
              width: 100%;
            }
            .dln-orderRev.dln-orderRev--cart-order {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }

        .dln-totalSect.dln-totalSect--lite {
          margin-top: 24px;
          transform: initial;
        }
      }
    }
  }
}

.dln-kenwood {
    .dln-profileCard {
        border-width: 0 0 1px 0;
        background-color: transparent;
        padding: 0 0 32px 0;
        margin: 0 0 32px;
        &__circle {
            background-color: $brand-04;
        }
        &__text {
            font-size: 14px;
        }
        &__userName {
            font-weight: bold;
        }
        @media only screen and (max-width:599px) {
            margin: 0 0 16px;
        }
    }
}
.dln-kenwood {
    & .dln-custServiceCard {
        background-color: $ui-03;
        padding: 30px 20px;

        &__title {
            @include fontExtra;
            line-height: 36px;
            font-size: 28px;
            margin-bottom: 30px;
        }
        &__text {
            margin-bottom: 30px;
        }
        &__actions {
            & .dln-btn {
                &--stroked {
                    background: none;
                    background-color: transparent;
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-custServiceCard {

            &__title {
                line-height: 32px;
                font-size: 24px;
                margin-bottom: 15px;
            }
            &__text {
                margin-bottom: 15px;
            }
            &__actions {
                & .dln-btn {
                    max-width: 216px;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

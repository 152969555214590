.dln-delonghi {
  & .dln-faq {
    &--alert {
      & .dln-faq__content {
        padding-top: 0;
        margin-bottom: 56px;

        & > .dln-wrapper {
          border-bottom: 1px solid $ui-01;
          padding-bottom: 24px;

          & .dln-faq__breadCrumbSelWrap {
            // margin-bottom: 14px;

            & .dln-breadCrumbSel {
              &__arrow {
                font-size: REM(16px);
                color: $brand-04;
              }
            }
            & .dln-select {
              & .dln-select__head {
                & .dln-select__value {
                  font-size: REM(16px);
                  @include fontMedium;
                }
                & .dln-select__arrow {
                  width: 16px;
                  font-size: REM(16px);
                }
              }
            }
          }
        }
        & .dln-list {
          margin-bottom: 62px;

          &Item:last-child {
            margin-bottom: 0;
          }
        }
        & .dln-faq__resultCounter {
          line-height: 24px;
          font-size: REM(16px);
          margin-bottom: 12px;
        }
        & .dln-btn--showMoreResults {
          width: 100%;
          min-width: initial;
          max-width: 184px;
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    & .dln-faq {
      &--alert {
        & .dln-faq__content {
          margin-bottom: 40px;

          & > .dln-wrapper {
            padding-bottom: 40px;

            & .dln-faq__breadCrumbSelWrap {
              margin-bottom: 14px;

              & .dln-select {
                &:not(:last-of-type) {
                  display: none;
                }
              }
            }
          }
          & .dln-list {
            margin-bottom: 56px;
          }
        }
        & .dln-quickLinks--slider {
          & .dln-wrapper {
            & .dln-quickLinks__inner {
              & .dln-quickLinks__title {
                margin-bottom: 21px;
              }
              & .dln-quick-links-slider__carousel {
                & .swiper-pagination {
                  margin-top: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

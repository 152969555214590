.dln-kenwood {
    .dln-chooseCountry {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .dln-wrapper {
            margin:  0 0 0 0 ;
            padding: 0;
            .dln-page__content{
             
                .dln-page__section {
                    margin-top: 0 ;
                }
            }
            
        }

        .dln-title{
          
            margin-top: 40px;
        }

        @media only screen and (max-width: 599px) {

            .dln-wrapper {    
                .dln-page__content{
                    margin-top: 20px;
                }
            }
    
            .dln-title{
                padding:  0 20px;
                font-size: 28px !important;
                margin-bottom: 0;
            }

            .dln-icn.dln-icn--iconfont.dln-icn--arrow-down.dln-icn--u{
                font-size: 14px;
            }
    
        }
    }
}
 
.dln-csmDropdown {
    margin: 16px 0;
    position: relative;
    width: 100%;
    display: block;
    /* &:not(.dln-csmDropdown--open) {
        >.dln-csmDropdown__dropdown {
            pointer-events: none;
        }
    } */
    &--open {
        overflow: visible;
        opacity: 1;
        max-height: 15rem;
        .dln-csmDropdown__list {
            opacity: 1;
            overflow: auto;
            max-height: 15rem;
            top: 0;
        }
        .dln-csmDropdown__arrow::before {
            transform: rotate(180deg);
        }
        .dln-csmDropdown__selected {
            background-color: $ui-01;
        }
        .dln-csmDropdown__list {
            z-index: 9;
        }
    }
    &--hasValue {
        .dln-csmDropdown__list-item--placeholder {
            display: block;
        }
    }
    &__dropdown {
        @include small;
        background-color: $brand-03;
        list-style: none;
        position: relative;
        border: 1px solid $ui-01;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        width: 100%;
        height: 40px;
        cursor: pointer;
        margin: 100px;
        padding: 0;

    }

    &__label {
        position: absolute;
        top: -9px;
        left: 15px;
        background: #fff;
        color: #444;
        font-size: .8em;
    }

    &__arrow {
        position: absolute;
        width: 16px;
        height: 16px;
        top: calc(50% - 8px);
        right: 16px;
        transition: transform 150ms;
        color: $brand-02;
        pointer-events: none;
        &::before {
            @include fontIcon;
            content: 'u';
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            width: 100%;
            height: 100%;
            line-height: 16px;
            transition: transform 200ms;
            transform-origin: 50%;
        }
    }
    &__list {
        width: 100%;
        position: absolute;
        left: -1px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);
        transition: opacity .1s cubic-bezier(0, 0, 0.38, 0.9), max-height .5s cubic-bezier(0, 0, 0.38, 0.9);
        max-height: 0;
        overflow: hidden;
        opacity: 0;
        border-style: solid;
        border-color: $ui-01;
        border-width: 0 1px 1px 1px;
        background-color: $brand-03;
    }
    &__itemLabel {
        display: none;
    }
    &__list-container {
        position: relative;
        overflow: visible;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__list-item {
        padding: 14px 16px;
        transition: background-color .1s linear, color .1s linear;
        list-style-position: inside;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover,
        &:focus {
            background-color: $ui-01;
        }
        &--placeholder {
            color: $ui-02;
            display: none;
        }
    }
    &__selected {
        padding: 10px 16px;
        list-style-position: inside;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: .2s ease;
    }
    .dln-cartItem__plan {
        margin: 0 0;
        align-items: center;
        .dln-icn {
            color: $ui-02;
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
}

@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-order--detail {
        & > .dln-wrapper {
            width: calc(100% - (10px * 2));
        }
    }
}

.dln-regProductList {
    align-content: flex-start;

    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__title {
        @include big;
        @include fontBold;
        margin: 0 0 0;
    }
    &__content {
        & .dln-title {
            margin: 0 0 32px 0;
            line-height: normal;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        margin: 32px 0 64px 0;

        &Title,
        & .dln-title {
            display: block;
            width: 100%;
            height: auto;
            line-height: 32px;
            font-size: 32px;
            @include fontBold;
            margin: 0 0 32px 0;
            &--labeled {
                display: flex;
            }
        }
        & .dln-orderRev {
            margin: 0 0 24px;

            &__itemPic {
                & > img {
                    object-fit: contain;
                }
            }
        }
    }
    .dln-title {
        &+.dln-regProductList__paragraph {
            margin-bottom: 0;
        }
    }
    &__paragraph {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        font-weight: 400;
        color: $brand-04;
        margin: 0 0 20px 0;

        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
    }
    &__underlinedText {
        color: $brand-02;
        text-decoration: underline;

        &:hover {
            color: $brand-04;
        }
    }
    &__subtitle {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 13px;
        @include fontBold;
        // text-transform: uppercase;
    }
    &__foot {
        text-align: center;
        width: 100%;
        margin: 0 0 32px;
        .dln-btn {
            width: auto;
        }
        &--divider {
            margin: 32px 0 0;
            border-top: 1px solid $ui-01;
            text-align: right;
            padding: 32px 0 0;
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-recipe-by-products {
        &__title {
            font-size: 24px;
            line-height: 1.13;
        }

        &__selects-wrap {
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            flex-direction: column;

            .dln-select {
                width: 100%;
                max-width: none;
    
                + .dln-select {
                    margin-left: 0;
                    margin-top: 15px;
                }
            }
        }

        &__toolbar {
            margin-top: 30px;
        }

        &__load-more {
            margin: 0 auto;
        }

        &__sorted-by {
            display: none;
        }

        .dln-breadcrumbs {
            font-size: 13px;
            line-height: 1.38;
        }

        .dln-recipe-grid {
            padding-bottom: 40px;
        }

        .dln-recipe-grid--detail .dln-recipe--big-card {
            .dln-recipe__title {
                font-size: 24px;
                line-height: 1.22;
            }
            .dln-recipe__details {
                display: flex;

                .dln-recipe__detail {
                    flex: 0;
                }
                .dln-recipe__detail--preparationTime {
                    flex: 1;
                }
            }
        }
        
        .dln-site-search__toolbar {
            .dln-site-search__toolbarFilters {
                display: none;
            }
            .dln-btnTextIcon--braun-filters {
                display: block;
            }
        }
    }
}
.dln-terms {
    .dln-breadcrumbs {
        margin: 40px 0 0;
    }

    &__note {
        @include small;
        color: $brand-02;
    }
    &__head {
        width: 100%;
        margin: 24px 0 0;
    }
    .dln-title {
        margin: 0 0 0;
    }
    .dln-btn {
        max-width: 280px;
    }

    &__paragraph {
        @include small;
        margin: 0 0 16px;
        color: $brand-02;
        &:last-of-type {
            margin: 0 0;
        }
    }
    &__block {
        width: 100%;
        margin: 32px 0 0;
        line-height:150%;

        .dln-terms__paragraph:last-of-type {
            margin: 0 0;
        }
      
        ul, ol {
            padding-inline-start: 26px;
            margin-block-end: 1em;
        }
        li {
            line-height: 150%;
            list-style-position: inside;
        }
        p {
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
        h2 {
            font-size: REM(22px);
            line-height: 125%;
            color: var(--brand-04);
        }
        h3 {
            font-size: REM(18px);
            line-height: 200%;
            color: var(--brand-04);
        }
        h4 {
            margin: 0 0 16px;
        }
        table, td, th {
            border: 1px solid var(--ui-04);
            text-align: left;
            padding: 8px;
            border-collapse: collapse;
        }
        td a, p a, li a {
            color: inherit;
        }
    }
    &__content {
        width: calc(100% - 100px);
        max-width: 980px;
        margin: 0 0 100px 100px;
    }
    &__subTitle {
        @include large;
        @include fontBold;
        font-size: 18px;
        @include fontBold;
        margin: 0 0 16px;
        color: $brand-04;
    }

}
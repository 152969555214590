body {
  background: #fff;
}

input::-ms-clear {  display: none; width : 0; height: 0; }
input::-ms-reveal {  display: none; width : 0; height: 0; }

@media only screen and (max-width:599px) {
  .dln-serviceLocator {
    .dln-tabs__content {
      opacity: 0;
      display: none;
      flex-wrap: nowrap;

      &--current {
        opacity: 1;
        display: block;
        flex-wrap: wrap;
      }
    }
  }
}

.dln-braun {
  .dln-account {
    .dln-wrapper {
      padding-top: 48px;
    }
  }
  .dln-profileCard {
    margin-bottom: 32px;
  }
}

.dln-cart__aside {

  .dln-cart__orderRecap {
    width: 100%;
  }

  .dln-cart__noticeBox {
    flex-flow: column;
    padding: 20px;
    gap: 20px;
    border: 1px solid #e5e5e5;

    .dln-noticeBox {
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 5px 0;

      .dln-noticeBox__icon {
        margin: 0;
        width: auto;

        .dln-icn {
          color: var(--brand-01);
        }
      }

      .dln-noticeBox__content {
        margin: 10px 0 0;

        .dln-noticeBox__description {
          display: flex;
          flex-flow: column;
          gap: 5px;
          text-align: left;
        }
        
        .dln-noticeBox__copy {
          text-align: left;
        }

        .dln-noticeBox__title {
          margin: 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 18.2px;
        }
      }
    }

    .dln-noticeBox--light .dln-noticeBox__content {
      display: flex;
      flex: 1;
      gap: 20px;
      margin: 0;
      width: auto;
    }
  }
}

.dln-delonghi .dln-noticeBox--light .dln-noticeBox__link {
  margin: 0;
  color: var(--brand-01);
}


.dln-serviceLocator__map .gm-ui-hover-effect {
  opacity: 1 !important;
  top: 5px !important;
  right: 10px !important;
  z-index: 1;

  img  {
    display: none !important;
  }

  &:before {
    content: 'J';
    font-size: 17px;
    font-family: var(--fontNameIcons);
  }
}

// Braun Compare Table
.bra-compareTray__table {
  width: 100%;
}

.dln-colorRadioBtn__placeholder::after {
  background-color: unset !important;
}

.disable-all {
  cursor: not-allowed!important;
  opacity: .4!important;
  * {
    cursor: not-allowed!important;
  }
}

.bra-radio__placeholder::after {
  background-color: unset !important;
}

.dln-recipe-single__col-content .dln-recipe-single__step-content {
  font-size: 15px;
  line-height: 1.4;
}

// Checkout
.dln-checkout--editing .dln-accordion--payment.dln-accordion--editing .dln-checkout__formField .dln-checkServiceBox__cardList {
    display: inline-flex;
}

.dlg-orderSumm {
  &__boxEntries {
    max-height: 226px;
    overflow-y: auto;

    & .dln-orderSumm__contentRow{
      border-bottom: 0;
    }
  }
}

.dln-checkout .dln-accordion--payment {
  .dln-checkServiceBox__cardItem {
    .dln-checkServiceBox__cardImg {
      max-width: 46px;
      min-width: 30px;
  }

  .adyen-checkout__helper-text,
  .adyen-checkout__label__text {
    line-height: 14px;
  }
}

  @media only screen and (max-width: 599px) {
    .dln-accordion.dln-accordion--payment.dln-accordion--editing.dln-accordion--showInfo {
      border-bottom: none;
    }
    .dln-checkout .dln-accordion--payment.dln-accordion--showInfo .dln-accordion__content .dln-checkout__form {
      margin-right: 10px;
      padding-right: 10px;
    }
    .dln-accordion .dln-accordion--payment.dln-accordion--open .dln-checkout__formField .dln-checkout__formField--billingAddress {
      margin-right: 24px;
    }
    .dln-checkout__content .dln-accordion {
      width: auto;
    }
  }

  .dln-checkout--editing .dln-accordion--payment.dln-accordion--editing .dln-accordion__content--submit .dln-checkout__paragraph {
    display: inline;
    margin-bottom: 24px;
  }

  .dln-checkout__content .dln-accordion--showInfo .dln-accordion__titleLabel {
    display: inline;
  }

    // Checkout Adyen
  .dlg-adyen-checkout .adyen-checkout__card__exp-cvc .adyen-checkout__field:not(.adyen-checkout__field__cvc) {
    display: none;
  }

  #js-adyen-card-checkout, #js-adyen-form-checkout {
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .adyen-checkout__field--50:nth-child(2) {
    margin-left: 0;
  }

  @media only screen and (min-width: 1199px) {
    .dln-checkout__formGroup {
      width: 60%;
    }
  }
}

.dln-checkout-3ds__button-container {
  width: 214px;
  margin-top: 40px;
}

.dln-inputs .dln-inputs__autocompleteContainer {
  z-index: 14 !important;
}

// Order History
.dln-order {
  &__dlgBackTo {
    > span {
      font-family: var(--fontNameBold);
      font-weight: 700;
      font-style: normal;
      line-height: 21px;
      font-size: 14px;
      font-weight: bold;
      color: var(--brand-01);
      text-decoration: underline;
    }

    > .dln-icn {
      width: 10px;
      height: 21px;
      line-height: 21px;
      font-size: 10px;
      color: var(--brand-01);
      text-align: center;
      text-decoration: none;
      margin: 0 8px 0 0;
    }
  }

  .dln-orderRev__actions > a {
    text-decoration: none;
  }
}

// kenwood search override
.dln-kenwood .dln-site-search {
  .dln-tabs,
  .dln-filters-bar,
  .dln-filters-bar__desktop,
  .dln-tabs__content {
    max-width: 1440px!important;
    margin: 0 auto!important;
  }

  .dln-manuals-list {
    padding: 15px 50px 65px 20px;
  }
}

@media only screen and (max-width:599px) {
    .dln-cart--empty {
        & .dln-cart__head {
            border-bottom: none;
        }
        & .dln-cart__emptyMessage {
            padding: 56px 0 0 0;
            margin: 0 auto;

            & p {
                line-height: 20px;
                font-size: 17px;
                margin: 0 0 72px 0;
            }
            & .dln-cart__btnGroup {
                margin: 0;

                & .dln-btn {
                    width: 100%;
                    margin: 0 0 16px 0;

                    &:last-of-type {
                        margin: 0;
                    }
                }
            }
        }
        & .dln-cart__services {
            margin: 0 auto;
            padding: 32px 0 0 0;
        }
    }
}
.dln-breadCrumbSel {
    display: inline-flex;
    flex-wrap: wrap;
    width: auto;
    height: 20px;
    color: $brand-02;
    text-decoration: none;

    &__text,
    &__arrow {
        display: inline-block;
        width: auto;
        height: 20px;
        line-height: 18px;
        font-size: 14px;
        color: $brand-02;
        margin: 0 4px 0 0;
        box-sizing: border-box;
    }
    &__arrow {
        line-height: 20px;
        padding: 0;
        margin: 0;
        font-size: 10px;
    }
    &:hover {
        & .dln-breadCrumbSel__text {
            text-decoration: underline;
        }
    }
    @media only screen and (max-width:599px) {
        &__text {
            display: none;
        }
        &__arrow {
            transform: rotate(-180deg);
        }
    }
}
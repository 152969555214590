.dln-footCheckout {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%;
    height: auto;
    background-color: $brand-01;
    padding: 30px 0 30px 0;

    &__wrap {
        display: inline-flex;
        flex-wrap: wrap;
        width: 50%;
        height: auto;
    }
    &__logo {
        display: inline-flex;
        width: auto;
        height: auto;
        margin: 0 0 10px 0;
        cursor: pointer;

        &Icon {
            display: block;
            width: 85px;
            height: 35px;
        }
    }
    &__description {
        display: inline-block;
        width: auto;
        line-height: 17px;
        font-size: 11px;
        color: $brand-02;
        padding: 0 0 0 0;
    }
    &__list {
        list-style: inside none;
        display: inline-flex;
        align-self: flex-end;
        width: auto;
        margin: 0 0 0 auto;
    }
    &__item {
        display: inline-flex;
        align-items: center;
        width: auto;
        padding: 0 8px 0 8px;
        position: relative;

        &:first-of-type {
            padding: 0 8px 0 0;
        }
        &:last-of-type {
            padding: 0 0 0 8px;
        }
        &::after {
            content: '';
            display: inline-flex;
            width: 1px;
            height: 8px;
            background-color: $brand-02;
            position: absolute;
            top: 5px;
            right: 0;
        }
        &:last-of-type::after {
            display: none;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    &__link {
        display: inline-flex;
        width: auto;
        height: 17px;
        line-height: 17px;
        font-size: 11px;
        color: $brand-02;
        text-decoration: none;
        padding: 0 0 0 0;
    }
    & .dln-select {
        &__container {
            &.dln-select--has-value {
                .dln-select__head {
                    border-color: transparent;
                }
            }
        }
    }
}
.dln-kenwood {
    .dln-orderRev {


        &:hover {
            border-color: $brand-04;
        }
        .dln-orderRev__content {
            padding: 0 0;
        }
        .dln-orderRev__details {
            align-self: auto;
            width: auto;
            max-width: 60%;
            border: none;
            padding: 0 0;
            margin: 0 auto 0 0;
        }
        .dln-orderRev__detailsTitle {
            font-size: 14px;
        }
        .dln-orderRev__reviewTitle {
            width: 100%;
            @include fontMedium;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 5px;
            display: initial;
        }
        & .dln-price--oldPrice {
            color: #6b6b6b;
        }
        .dln-orderRev__wrap--main {
            margin: 0 0 16px;
        }
        .dln-orderRev__actions {
            display: block;
            align-self: auto;
            width: auto;
            max-width: 40%;
            margin: 0 0 0 auto;

            .dln-btn {
                display: block;
                width: 100%;
                min-width: auto;
                max-width: 100%;
                margin: 0 0 10px 0;
            }
        }
        .dln-orderRev__delivery {
            text-align: left;
        }

        .dln-orderRev__itemPic {
            max-height: none;
            width: auto;

            > img {
                object-fit: none;
            }
        }

        // product desctiption variant
        &--productDescription {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            min-height: 150px;
            border: none;
            padding: 0 196px 0 196px;
            position: relative;

            & .dln-orderRev__itemPic {
                position: absolute;
                top: calc(50% - 75px);
                left: 0;
            }
            & .dln-orderRev__content {
                width: 100%;
                float: none;

                & .dln-orderRev__details {
                    width: 100%;
                    max-width: none;
                }
                & .dln-orderRev__wrap--main {
                    margin: 0;
                }
                & .dln-orderRev__detailsTitle {
                    width: 100%;
                    padding: 0;
                    margin: 0;
                }
            }
            & .dln-orderRev__sku {
                display: none;
            }
            & .dln-orderRev__desc {
                width: 100%;
                line-height: 20px;
                font-size: 14px;
                margin: 8px 0 0 0;
                color: $brand-04;
            }
            & .dln-btn--productDescription {
                color: $brand-01;
                border: 1px solid $brand-01;
                background-color: $brand-03;
                margin: 0;
                position: absolute;
                top: calc(50% - 22.5px);
                right: 0;

                &:hover {
                    color: $brand-03;
                    background-color: $brand-01 !important;
                }
            }
        }


        &--registeredproduct {
            .dln-orderRev {
                &__content {
                    margin-top: 10px;
                }

                &__details {
                    flex-direction: column;
                    display: flex;
                    height: 100%;
                    padding-right: 10px;

                    .dln-orderRev__wrap {
                        &--main {}

                        .dln-orderRev__purchaseDate {
                            b {
                                @include fontLight;
                            }
                        }
                        &--registeredproduct {
                            width: 100%;
                        }
                        &--links {
                            margin-top: auto;
                            width: 100%;
                            text-align: left;

                            & .dln-btn--link {
                                display: inline-flex;
                                width: auto;
                                text-align: left;

                                @include fontMedium;

                                line-height: 20px;
                            }

                            & .dln-btn--link + .dln-btn--link {
                                margin-left: 31px;
                            }
                        }
                    }
                }

                &__actions {
                    flex-direction: column-reverse;
                    display: flex;
                    height: 100%;

                    .dln-orderRev__wrap {
                        display: flex;
                        max-width: initial;
                        width: 100%;

                        &--links {
                            margin-top: auto;
                        }

                        &:first-of-type {
                            align-content: flex-end;
                            flex-direction: row-reverse;

                            & .dln-btn--link + .dln-btn--link {
                                margin-right: 31px;
                            }
                        }
                        &:nth-of-type(2) {
                            flex-direction: column-reverse;
                        }

                        & .dln-btn--link {
                            display: inline-flex;
                            width: auto;
                            text-align: left;

                            @include fontMedium;
                            line-height: 20px;
                            margin: 0;
                        }


                    }
                }
            }
        }


        @media only screen and (max-width:599px) {
            border-width: 0;
            .dln-orderRev__itemPic {
                width: auto;
                position: relative;
                height: auto;
            }
            .dln-orderRev__content {
                margin-left: 0;
                width: calc(100% - 120px);
                padding: 0;
            }
            .dln-orderRev__detailsTitle {
                margin: 0 0 8px 0;
            }
            .dln-orderRev__reviewTitle {
                margin-top: 8px;
            }
            .dln-orderRev__details {
                width: 100%;
                max-width: 100%;
            }
            .dln-orderRev__delivery {
                text-align: center;
            }
            .dln-orderRev__actions {
                margin-top: 16px;
                width: 100%;
                max-width: 100%;

                .dln-btn {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 100%;
                }
            }


            &--registeredproduct {
                .dln-orderRev {
                    margin: 0;
                    padding: 29px 0 30px 0;

                    &:first-of-type {
                        padding-top: 0;
                    }

                    &:last-of-type {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }

                    &__itemPic {
                        width: 100%;
                        padding: 0;

                        img {
                            max-width: 100%;
                        }
                    }

                    &__content {
                        width: 100%;
                    }

                    &__wrap {
                        &--main {
                            margin: 0 0 10px 0;
                        }
                        &--registeredproduct {
                            border-bottom: 0;
                            padding: 0;
                            margin: 0 0 40px 0;
                        }

                    }

                    &__detailsTitle {
                        margin: 0;
                    }

                    &__details,
                    &__actions {
                        height: auto;
                        width: 100%;
                        max-width: 100%;
                    }

                    &__details {
                        padding-right: 0;
                        .dln-orderRev__wrap {
                            &--links {
                                display: block;

                                & .dln-btn--link {
                                    display: block;
                                    text-align: center;
                                    margin: 0 0 10px 0;
                                }

                                & .dln-btn--link + .dln-btn--link {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                    &__actions {
                        flex-direction: column;
                        width: auto;
                        margin: 20px auto 0 auto;

                        .dln-btn:first-child {
                            margin: 0;
                        }

                        .dln-orderRev__wrap {
                            &:first-of-type {
                                .dln-btn--link {
                                    display: inline-block;
                                    width: 50%;
                                    text-align: right;
                                    text-align-last: right;
                                }
                                .dln-btn--link + .dln-btn--link {
                                    margin-right: 0;
                                    text-align: left;
                                    text-align-last: left;
                                }
                            }

                            &:last-of-type {
                                margin: 0 0 16px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-orderInfoCard {
        padding: 30px 0;

        &__number {
            line-height: 26px;
            font-size: 18px;
            margin-bottom: 13px;
        }
        &__cell {
            width: 100%;
            padding: 0;
            margin-bottom: 8px;

            &:nth-of-type(2),
            &:nth-of-type(4) {
                width: 100%;
            }
            &:nth-of-type(4) {
                margin-bottom: 12px;
            }
            &:last-of-type {
                width: auto;
                margin: 0 0 0 auto;
            }
            &Title,
            &Data {
                width: auto;
                display: inline-block;
            }
            &Title {
                margin: 0 3px 0 0;
            }
        }
    }
}

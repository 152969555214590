@charset "utf-8";
/* CSS Document */

* {
    margin: 0;
    padding: 0;
}
*:focus {
    outline: none;
}
* {
    word-wrap: break-word;
}
.clearer {
    width: 100%;
    clear: both;
}
html,
body {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
img {
    border: none;
}
header,
nav,
article,
aside,
section,
footer,
main,
figure,
figcaption,
hgroup {
    display: block;
}
.clearfix:before,
.clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
    overflow: hidden;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    zoom: 1;
}

div {
    box-sizing: border-box;
}

[responsive-background-image] img {
    display: none;
}
.dln-completeForm {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: auto;
    border-radius: 2px;
    background-color: $brand-03;
    // padding: 24px 40px 40px 40px;
    padding: 0 40px 40px 40px;

    &--prodReg{
        justify-content: flex-end;
        
        #js-gigya-prod-reg-login-popup-container {
            width: 100%;
        }

        .dln-btnIcon--close {
            margin-right: -39px;
        }
    }

    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        min-height: 22px;
        border-bottom: 1px solid $ui-02;
        border-radius: 2px 2px 0 0;
        padding: 16px 0 16px 0;
        margin: 0 0 16px 0;

        &Title {
            display: block;
            width: calc(100% - 40px - 16px);
            line-height: 19px;
            font-size: 16px;
            @include fontBold;
            padding: 0 0 0 0;
            margin: 0 16px 0 0;
        }
        & .dln-btnIcon {
            margin: 0 0 0 auto;
        }
    }
    &__form {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        padding: 0 20px;
        margin: 0;

        & .dln-inputs {
            margin: 0 0 24px 0;
        }
        & .dln-btn[type="button"] {
            margin: 0 0 0 0;
        }
        & .dln-btn--link {
            margin: 0 auto;
        }
    }
    &__text {
        display: block;
        width: 100%;
        line-height: 20px;
        margin: 0 0 32px 0;
    }
    &__wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .dln-btn {
            margin: 0 auto 0 0;
            &:last-of-type {
                margin: 0 0;
            }
        }
    }
    .dln-checkbox {
        margin: 0 0 24px;
    }

    /* 
     */


    &__paragraph {
        @include extraExtraSmall;
        width: 100%;
        color: $brand-04;
        margin: 0 0 20px 0;
        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
        &--secondary {
            @include extraExtraSmall;
            color: $brand-02;
        }
        a {
            color: $brand-02;

        }
    }
    &__subtitle {
        @include extraExtraSmall;
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 8px;
        @include fontBold;
        // text-transform: uppercase;
    }
    &__form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    &__formContainer {
        width: 100%;
        padding: 32px 0 0;
        margin: 24px 0 0;
        border-top: 1px solid $ui-01;
        .dln-select {
            width: calc(50% - 10px);
            margin: 0 50% 32px 0;
        }
        .dln-inputs {
            margin: 0 0 16px 0;
            &--halfCol {
                width: calc(50% - 10px);
                margin: 0 0 32px 0;
                &:nth-child(even) {
                    margin: 0 20px 32px 0;
                }
            }

            .dln-inputs__extraLbl {
                width: calc(95% - 12px);
                max-width: 300px;
                white-space: normal;
            }
        }
        .dln-checkbox {
            margin: 70px 0 32px;
        }
    }
    &__formFoot {
        width: 100%;
        margin: 60px 0 0;
        .dln-btn {
            width: 50%;
        }
    }
    &__formOptions {
        display: flex;
        flex-wrap: wrap;
        .dln-registrationForm__subtitle,
        .dln-registrationForm__paragraph {
            font-size: 10px;
        }
        .dln-radioBtn {
            margin: 0 40px 32px 0;
        }
    }
}
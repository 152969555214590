.dln-payMethods {
    align-content: flex-start;


    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {
        & .dln-title {
            margin: 0 0 32px 0;
        }
        & .dln-addBox {
            width: calc(50% - 40px - 2px - 8px);
            margin: 0 16px 40px 0;
            box-sizing: content-box;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-between;
        width: 100%;

        &Title {
            display: block;
            width: 100%;
            line-height: 32px;
            font-size: 32px;
            @include fontBold;
            margin: 0 0 40px 0;
        }
    }
    & .dln-addBox {
        width: calc(50% - 40px - 2px - 10px);
        min-height: 184px;
        margin: 0 0 20px 0;
    }
    & .dln-payCardBox {
        width: calc(50% - 10px);
        min-height: 184px;
        margin: 0 20px 20px 0;

        &:nth-of-type(2n+2) {
            margin: 0 0 20px 0;
        }
    }
}
.dln-delonghi {
  .dln-orderDetailInfo {
    &__title {
      color: $brand-04;
      @include fontMedium;
      font-size: REM(21px);
    }

    &__content {
      .dln-orderDetailInfo__data--mb-10 {
        margin-bottom: REM(10px);
      }

      .dln-orderDetailInfo__data--grey {
        opacity: .75;
      }

      // padding-top: 0;
      .dln-orderDetailInfo__data:first-of-type {
        @include fontSemibold;
      }

      &--shippingMethod {
        .dln-orderDetailInfo__data:first-of-type {
          @include fontRoman;
        }
      }
    }

    &__subtitle {
      font-size: REM(16px);
      @include fontMedium;
    }

    &__data {
      @include fontRoman;
      font-size: REM(14px);
    }
  }

  .dln-subscriptionDetailInfo__title {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  .dln-modal-container {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1072;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    outline: 0;
    background-color: rgba(0,0,0,.75);

    .dln-modal-dialog {
      max-width: REM(500px);
      margin: REM(60px) auto;
      position: relative;
      width: auto;
      pointer-events: none;

      .dln-modal-content {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.2);
        outline: 0;
        padding: REM(25px);
        max-width: calc(100% - 20px);
        margin: 0 auto;

        &__title {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: REM(15px);
        }

        &__description {
          font-size: 16px;
          line-height: 18px;
          margin-bottom: REM(15px);
        }

        &__address-box, &__payment-method-box {
          border: 1px solid #eee;
          padding: 20px;
          margin-bottom: REM(15px);

          .dln-modal-content__radio-label {
            font-size: 14px;
            font-weight: 600;
            margin-left: REM(5px);
          }

          .dln-modal-content__data-list {
            margin-top: REM(10px);

            p {
              line-height: 18px;

              &.dln-modal-content__data-list--label {
                margin-top: REM(7px);
                opacity: .75;
              }
            }
          }
        }

        &__create-btn {
          height: REM(30px);
          background: #fff;
          border: 1px solid $brand-01;
          color: $brand-01;
          font-weight: 600;
          font-size: 15px;
          cursor: pointer;
          margin: REM(5px) 0;
          
          a {
            color: $brand-01;
            margin: 0;
            text-decoration: none;
            font-weight: 600;
          }
        }
        &__confirm-btn {
          height: REM(30px);
          background: $brand-01;
          border: 1px solid $brand-01;
          color: #fff;
          font-weight: 600;
          font-size: 15px;
          cursor: pointer;
          margin: REM(5px) 0;
        }

        .del-modal-content__close {
          position: absolute;
          top: REM(10px);
          right: REM(10px);
          height: REM(30px);
          width: REM(30px);
          border: 0;
          background: transparent;
          cursor: pointer;

          .del-icon {
            width: REM(30px);
            height: REM(30px);
          }
        }
      }
    }
  }

  .dln-subscriptionDetailInfo__col {
    padding-bottom: 50px;
    position: relative;

    .dln-subscriptionDetailInfo__edit-button {
      position: absolute;
      bottom: 0;
      left: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-orderDetailInfo {
      &__title {
        font-size: REM(18px);
      }

      &__col {
        border-bottom: 1px solid $ui-01;
        width: 100%;
        margin-right: 0;
        margin-bottom: 12px;
        padding: 0 0 8px 0;

        &:first-of-type {
          border-bottom: none;
        }
      }
    }

    .dln-subscriptionDetailInfo__col .dln-subscriptionDetailInfo__edit-button {
      position: relative;
      margin-top: 10px;
    }
  }
}

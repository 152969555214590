.dln-kenwood {
    .dln-csmDropdown {
        &__label {
            font-size: 12px;
            line-height: 14px;
            transform: none;
            top: -18px;
            left: 0;
            font-size: 12px;
            color: $brand-02;
            background-color: transparent;
        }
    }
}

.dln-kenwood {
    & .dln-country-stripe {
        & .dln-country-stripe__buttons {
            margin-right: 16px;

            & .dln-btn {
                font-size: 12px;
            }
        }
        & .dln-btnIcon.dln-btnIcon--close {
            & .dln-icn.dln-icn--iconfont {
                font-size: 33px;
            }
        }
    }
}


@media only screen and (max-width: map-get($grid-breakpoints, md)){
    .dln-kenwood {
        & .dln-country-stripe {
            & .dln-btnIcon.dln-btnIcon--close {
               top: 16px;
               right: 22px;
            }
        }
    }
}

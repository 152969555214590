.dln-delonghi {
    & .dln-mapPopup {
        max-width: 304px;

        &__wrap {
            &--actions {
                & .dln-btn {
                    min-width: initial;
                    width: calc(50% - 5.5px);

                    &--website {
                        margin: 0 11px 16px 0;
                    }
                    &--writeUs {
                        margin: 0 0 16px 0;
                    }
                    &--googleMaps {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }
    }
}
.dln-delonghi {
  .dln-address__title{
    line-height: 34px;
    font-size: REM(28px);
    margin-bottom: 24px;
  }
  .dln-addressBox {
    border: 1px solid $ui-01;
    padding: 25px 20px 20px 20px;
    position:relative;
    
    &__data {
      @include fontBook;
      font-size: REM(15px);
      line-height: 22px;
      
      strong {
        @include fontDemi;
      }
    }
    
    &__foot {
      margin-top: auto !important;
      display: inline-flex;
      width: 100%;
      .dln-checkbox {
        margin-bottom: 0px !important;
        
        &__wrap {
          padding: 0;
        }
        
        &__inputPlaceholder {
          &::after {
            top:50%;
            left: 50%;
            color: var(--brand-04)
          }
          
        }
      }
      .dln-addressBox__navButtons {
        display: inline-flex;
        align-items: center
      }
    }
    
    & .dln-btn.dln-btn--link {
      @include fontMedium;
      height: auto;
      font-size: REM(14px);
      line-height: 18px;
    }
    .dln-btn.dln-btn--edit {
      &::after {
        content: '';
        background-color: $ui-01;
        margin: 0 10px;
      }
    }
  }
  
  .dln-address--editing {
    .dln-address__section {
      border: 0;
      margin-bottom: 40px;
      
      &Head {
        margin-bottom: 24px;
        
        &Title {
          @include fontMedium;
          font-size: REM(28px);
          line-height: 34px;
          
          @media only screen and (max-width: 599px) {
            font-size: REM(15px);
            line-height: REM(18px);
          }
        }
        &InfoText {
          @include fontBook;
          font-size: REM(13px);
          line-height: 16px;
          height: auto;
        }
      }
    }
    
    .dln-wrapper {
      width: auto;
      .dln-address__content {
        > div:nth-child(3) {
          .dln-address__form {
            @media only screen and (max-width: 599px) {
              width: 100%;
            }
            width: 70%;
          }
        }
      }
    }
    
    .dln-address__form {
      margin: 0;
      padding: 0;

      .rowEdit {
        display: flex;
        width: 100%;
        margin-bottom: 30px;
        & > div {
          width: calc(50% - 10px);
        }
        
        .secondEdit {
          width: calc(50% - 10px);
          .dln-inputs {
            width: 85%;
          }
        }
      }
      
      .dln-address_info {
        display: flex;
        height: 40px;
        align-items: center;
        
        .dln-tooltip {
          display: flex;
          
          &__anchor {
            margin-left: 5px;
          }
        }}
      }
    }
  }
  
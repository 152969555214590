.dln-paginationSlide {
    display: inline-flex;
    flex-wrap: wrap;
    // width: 100%;
    // max-width: 360px;
    width: auto;
    height: 36px;
    border-top: 3px solid $ui-01;
    box-sizing: content-box;
    margin: 0 auto;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 16.67%;
        width: 60px;
        line-height: 1.54;
        font-size: 13px;
        border: none;
        background: none;
        background-color: $brand-03;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;

        &IconWrap {
            display: inline-block;
            width: 6px;
            height: 18px;
            margin: 0 0 0 4px;
        }
        &__icon {
            display: inline-block;
            width: 6px;
            height: 10px;
        }
        &.-prev {
            & .dln-paginationSlide__btnIconWrap  {
                margin: 0 4px 0 0;
            }
            & .dln-paginationSlide__btnIcon  {
                transform: rotateY(-180deg);
                transform-origin: center;
            }
        }
    }
    &__tabs {
        display: inline-flex;
        flex-wrap: wrap;
        width: auto;
        height: 36px;
        position: relative;
        z-index: 1;
    }
    &__currentLine {
        display: inline-block;
        width: 60px;
        height: 3px;
        background-color: $brand-01;
        transition: left 0.22s ease-in-out;
        position: absolute;
        top: -3px;
        left: 0;
        z-index: 2;
    }
    &__tab {
        display: inline-block;
        width: 60px;
        height: 36px;
        border: none;
        background-color: $brand-03;
        box-shadow: none;
        -moz-appearance: none;
        -moz-appearance: none;

        &.-current {
            @include fontBold;
        }
    }
}

.dln-kenwood {
    .dln-checkServiceBox {
        background-color: $brand-03;
        border: 1px solid $ui-09;
        .dln-icn {
            font-size: 50px;
            color: $brand-01;
        }
        &__title,
        &__text {
            font-size: 14px;
            line-height: 20px;
        }
        &__title {
            margin-bottom: 0;
            @include fontSemibold;
        }
        &__text {
            margin-bottom: 10px;
            @include fontLight;
        }
        .dln-btn {
            font-size: 14px;
            color: $brand-04;
            @include fontMedium;
        }
        &__cardItem {
            border: 1px solid $ui-01;
        }
    }
}
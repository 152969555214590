.dln-confirmModal {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: auto;
    padding: 0 32px 32px 32px;
    .dln-popup__head {
        padding-bottom: 0;
        &Title {
            font-size: 24px;
            text-align: center;
            margin: 32px 0 0;
        }

    }
    &__content {
        width: 100%;
        text-align: center;
        .dln-btn {
            margin: 0 auto 16px;
            max-width: 185px;
            display: block;
            &:last-child {
                margin: 0 auto 0;
            }
        }
    }
    &__desc {
        @include mainMenu;
        display: block;
        width: 100%;
        margin: 4px 0 32px 0;
        padding: 0 24px;
        box-sizing: border-box;
    }
    & .dln-inputs {
        margin: 0 16px 0 0;
        width: calc(70% - 16px);
    }

}
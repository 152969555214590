.dln-return-order {
    &__content {
        & .dln-custServiceCard {
            margin-bottom: 28px;
        }
        .dln-btnTextIcon {
            margin-bottom: 8px;

            .dln-icn {
                font-size: 12px;
                color: $brand-04;
            }
            &__text {
                color: $brand-04;
                text-decoration: underline;
            }
        }
    }

    &__section-title {
        @include fontBold;
        line-height: 1.18;
        font-size: 22px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid #e6e6e6;
        padding-top: 30px;
        margin-bottom: 35px;
    }

    &-box-detail {
        width: 100%;
        border-radius: 6px;
        background: #f5f5f5;
        padding: 20px 20px 40px;
        margin-bottom: 30px;

        &__header {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .dln-btn--link {
                text-decoration: underline;
            }
        }
        &__title {
            @include fontBold;
            line-height: 1;
            font-size: 32px;

            & + .dln-btn--link {
                color: $brand-01;
            }
        }
        &__body {
            display: flex;
            margin-top: 50px;
        }
        &__col {
            flex: 0 0 25%;
            line-height: 1.25;
            font-size: 16px;

            &-value {
                @include fontBold;
            }
        }
    }

    &-product-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .dln-orderRev {
            &--cart-order .dln-orderRev__actions .dln-btn {
                max-width: 190px;
            }
            &__quantity {
                line-height: 1.67;
                font-size: 12px;
                color: $brand-02;
            }
        }
        .dln-price {
            line-height: 1.43;
            font-size: 14px;
        }
    }

    // &-total-price {
    //     display: flex;
    //     justify-content: space-between;
    //     width: 100%;
    //     border-top: 1px solid #e6e6e6;
    //     padding-top: 20px;
    //     margin-bottom: 35px;

    //     &__grid {
    //         display: flex;
    //         flex-wrap: wrap;
    //         width: 190px;
    //         padding-top: 10px;

    //         span {
    //             flex: 0 0 50%;
    //             padding-top: 5px;
    //             color: #707070;

    //             &.discount {
    //                 color: $support-01;
    //             }
    //             &.value {
    //                 color: $brand-04;
    //             }

    //             b {
    //                 display: block;
    //                 padding-top: 5px;
    //                 color: $brand-04;
    //             }
    //             &:nth-child(even) {
    //                 text-align: right;
    //             }
    //         }
    //     }
    // }

    &-receipt {
        width: 100%;

        .dln-btn--link,
        &__text {
            line-height: 1.43;
            font-size: 14px;
            color: $brand-04;
        }

        &__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 40px;
        }
    }

    &-info {
        display: flex;
        justify-content: stretch;
        width: 100%;
        border-top: 1px solid #e6e6e6;
        padding-top: 20px;
        margin-bottom: 35px;

        &__col {
            flex: 0 0 25%;
        }
        &__title {
            @include fontBold;
            line-height: 1.29;
            font-size: 14px;
        }
        &__description {
            line-height: 1.38;
            font-size: 13px;
            margin-top: 10px;
        }
    }

    &-returned {
        width: 100%;

        & .dln-orderHistory {
            & .dln-orderRev {
                &__itemPic,
                &__details {
                    opacity: 0.3;
                }
            }
        }
        &:not(.dln-return-order-returned--accept) {
            & .dln-orderHistory {
                & .dln-orderRev {
                    &__actions {
                        display: none;
                    }
                }
            }
        }
        &__box {
            background: #f5f5f5;
            padding: 22px 20px 30px;
            margin-bottom: 30px;
        }
        &__box-title {
            @include fontBold;
            line-height: 1.25;
            font-size: 16px;
            margin-bottom: 8px;
        }
        &__box-description {
            line-height: 1.54;
            font-size: 13px;
        }
    }
}

.dln-kenwood {
    .dln-select {
        margin: 20px auto 20px 0;

        &__option:first-of-type {
            &::before {
                content: '';
                display: block;
                width: calc(100% - 24px);
                height: 1px;
                background: $ui-03;
                left: 12px;                
            }
        }
        &__container {
            height: 45px;
        }
        &__lbl {
            line-height: 14px;
            font-size: 12px;
            color: $ui-07;
            transform: none;
            padding-left: 0;
            top: -20px;
            left: 0;
        }
        &--invalid {
            .dln-select__lbl--error {
                transform: translateX(0) scale(0.75);
                margin: 4px 0 0;
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
            }
        }
        &--has-value {
            .dln-select__head {
                border-color: $brand-04;
            }
            .dln-select__value {
                color: $brand-04;
            }
        }
        &--open {
            .dln-select__head {
                border-width: 1px 1px 0;
                border-color: $brand-04;
            }
            .dln-select__body {
                border-width: 0 1px 1px;
                border-color: $brand-04;
            }
        }

        .dln-select--has-value + .dln-select__lbl--active {
            transform: translateY(calc(50% - 6px));
        }

        .dln-select--open +  .dln-select__lbl--active {
            transform: translateY(calc(50% - 6px));
        }
        
        &--extraLight {
            .dln-select--open {
                .dln-select__body {
                    border-width: 1px 1px 1px;
                }
            }
            .dln-select__container {
                height: 20px;
            }
        }
        &--invalid {
            .dln-select__body {
                border-color: $support-01;
            }
        }
        &--labeled {
            .dln-select__head {
                border-radius: 0;
            }
        }
        &--light,
        &--extraLight {
            &.dln-select--open.dln-select--open-above {
                .dln-select__head {
                    border-width: 0;
                }
            }
            &.dln-select--open-above {
                .dln-select--open {
                    .dln-select__body {
                        border-width: 1px;
                    }
                    .dln-select__head {
                        border-bottom: 1px solid var(--brand-04);
                    }
                }
            }
        }
        &--open-above {
            &.dln-select--open {
                .dln-select__body {
                    border-width: 1px 1px 1px;
                }
                .dln-select__head {
                    border-top: 0;
                    border-bottom: 1px solid var(--brand-04);
                }
            }
        }
        &.dln-select--light,
        &.dln-select--extraLight {
            .dln-select--open-above,
            .dln-select--open-below {
                &.dln-select--open {
                    .dln-select__body {
                        border-width: 1px;
                    }
                    .dln-select__head {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}
.dln-delonghi {
  .dln-wrapper {
    .dln-recipe-details {
      border: none;

      &--constrain .dln-wrapper {
        background: #f7f7f7;
        border-radius: 0;
      }
      &__info {
        & .dln-recipe-details__info-button-desktop {
          & .dln-btn {
            line-height: 40px;
          }
        }
      }
    }
  }
  .dln-recipe-details {
    background: transparent;
    border-top: 1px solid $ui-01;
    border-bottom: 1px solid $ui-01;

    &__title-recipe {
      @include fontMedium;
      font-size: REM(37px);
      line-height: 1;
    }

    &__description {
      margin-bottom: 16px;
      font-size: REM(16px);
      line-height: 1.5;
    }

    &__bottom-info {
      &-col {
        border-color: $ui-07;
      }
      &-label {
        color: $brand-04;
        font-size: REM(14px);
        line-height: 1.3;
      }
      &-value {
        font-size: REM(14px);
        line-height: 1.3;
      }
    }
    .dln-image {
      border-radius: 0;
    }

    .dln-btn {
      background-color: $brand-04;
    }
    .dln-btn--icon {
      @include fontMedium;
      background-color: $brand-03;
      color: $brand-04;
      font-size: REM(14px);
      line-height: 1.29;

      .dln-btn__lbl {
        @include fontMedium;
        font-size: REM(14px);
        line-height: 1.29;
        text-decoration: underline;
      }
      .bra-icn {
        width: 30px;
        height: 30px;
        font-size: REM(26px);
      }
    }
    .dln-btnIcon {
      .dln-icn {
        color: $brand-01;
      }
    }
    &__videoBgContainer {
      background-color: $brand-04;
    }
    &__play-button {
      background: transparent;
      border: 3px solid $brand-03;
      height: 50px;
      width: 50px;
      &:before {
        border-width: 10px 13px 10px 0;
        border-color: transparent $brand-03 transparent transparent;
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    & .dln-recipe-details {
      & .dln-wrapper {
        & .dln-recipe-details__info-button-mobile {
          & .dln-btn {
            display: inline-block;
            line-height: 40px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-recipe-details {
      & .dln-wrapper {
        & .dln-recipe-details__grid {
          & .dln-recipe-details__col {
            & .dln-recipe-details__bottom-info {
              margin-bottom: 19px;
            }
          }
        }
        & .dln-recipe-details__info-button-mobile {
          justify-content: center;

          & .dln-btn {
            display: inline-block;
            line-height: 40px;
          }
        }
      }
    }
  }
}

.swiper-container {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    /* Fix of Webkit flickering */
    z-index: 1;
}
.swiper-wrapper {
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    position: relative;
    z-index: 1;
}

.swiper-wrapper {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
}
.swiper-slide {
    -ms-flex-negative: 0;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
}
  /* Auto Height */
  /* 3D Effects */
  /* IE10 Windows Phone 8 Fixes */
.swiper-button-prev,
.swiper-button-next {
    width: 27px;
    height: 44px;
    background-repeat: no-repeat;
    background-size: 27px 44px;
    background-position: center;
    margin-top: -22px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 10;
}
// .swiper-button-prev {
//     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
//     left: 10px;
//     right: auto;
// }
// .swiper-button-next {
//     background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
//     left: auto;
//     right: 10px;
// }
  /* Common Styles */
  /* Bullets */
  /* Progress */
  /* Scrollbar */
.swiper-scrollbar {
    -ms-touch-action: none;
    border-radius: 10px;
    // background: rgba(0, 0, 0, 0.1);
    position: relative;

    &-drag {
        height: 3px;
    }
}
  /* Preloader */
@keyframes swiper-preloader-spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.swiper-container {
    .swiper-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 85%;
        max-width: 1200px;
        margin: 0 auto;
        margin-top: 55px;

        .swiper-button-prev {
            order: 1;
        }
        .swiper-scrollbar {
            order: 2;
        }
        .swiper-button-next {
            order: 3;
        }

        .swiper-scrollbar {
            width: 85%;
            height: 3px;
            background: $ui-08;
            position: relative;
            bottom: 0;
            left: 0;

            .swiper-scrollbar-drag {
                background: $brand-01;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: auto;
            height: auto;
            color: $brand-01;
            background: none;
            margin: 0;
            position: relative;

            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            &.swiper-button-disabled {
                opacity: .15;
                cursor: auto;
                pointer-events: none;
            }
        }
    }
}

.dln-delonghi {
    .swiper-container {
        .swiper-footer {
            .swiper-scrollbar .swiper-scrollbar-drag {
                background: $brand-04;
            }

            .swiper-button-next,
            .swiper-button-prev {
                color: $brand-04;
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .swiper-container {
        .swiper-header {
            width: calc(100% - 60px);
            .swiper-pagination {
                .swiper-pagination-bullet {
                    font-size: 13px;
                    padding: 12px 10px;
                }
            }
        }
        .swiper-footer {
            justify-content: space-around;
            width: calc(100% - 60px);
            margin-top: 30px;

            .swiper-scrollbar {
                width: 100%;
            }

            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .swiper-container {
        // .swiper-header {
        //     display: none
        // }
        .swiper-footer {
            justify-content: space-around;
            // width: calc(100% - 40px);
            width: 100%;
            margin-top: 30px;

            .swiper-scrollbar {
                width: 100%;
            }

            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }
    }
}

.dln-kenwood {
    .dln-accordion {
        &--item-review {
            .dln-accordion__content {
                .dln-price {
                    display: inline-block;
                    width: auto;
                    padding-left: 76px;
                    margin: 0 0 16px;
                }
            }
            .dln-accordion__head {
                .dln-accordion__title {
                    font-size: 14px;
                }
            }
            &.dln-accordion--open {
                .dln-accordion__head {
                    border-width: 0;
                }
            }
        }
    }
}
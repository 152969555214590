.dln-kenwood {
    .dln-head {
        display: none;

        &--kenwood {
            display: flex;
        }
        &__logo {
            /* margin-right: 120px;     */
            // padding-right: 120px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);

            @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                left: 20px;
                transform: none;
            }
        }
        &__btnNav {
            margin-left: auto;
        }
    }

    .bra-header {
        display: none;
    }
}
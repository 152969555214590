.dln-delonghi {
  & .dln-wishlist {
    & .dln-wrapper {
      & .dln-wishlist__content {
        & .dln-title {
          @include fontMedium;
          line-height: 52px;
          font-size: REM(50px);
          margin: 0 0 24px 0;
        }
        & .dln-wishlist__subtitle {
          @include fontMedium;
          line-height: 37px;
          font-size: REM(37px);
        }
        & > .dln-wishlist__text {
          line-height: 24px;
          font-size: REM(16px);
          text-align: center;
          margin-bottom: 41px;
        }
        & .dln-wishlist__foot {
          justify-content: center;
          padding-top: 24px;
          margin-top: 44px;

          & .dln-btn {
            min-width: 200px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-wishlist {
      & .dln-wrapper {
        & .dln-wishlist__content {
          & .dln-title {
            line-height: 40px;
            font-size: REM(37px);
          }
          & .dln-wishlist__subtitle {
            line-height: 30px;
            font-size: REM(28px);
          }
          & .dln-wishlist__foot {
            border-top: none;
            padding-top: 39px;
            margin: 0 0 32px 0;
          }
          & .dln-wishlist__productWrap {
            & .dln-wishProd {
              padding-bottom: 0;
              margin-bottom: 24px;

              &:not(:nth-of-type(1)):not(:nth-of-type(2)) {
                margin-top: 0;
              }
            }
          }
        }
      }
      &:not(.dln-wishlist--empty),
      &:not(.dln-wishlist--shared) {
        & .dln-wishlist__content {
          padding-top: 40px;
          margin-top: 0;

          & .dln-title {
            margin-bottom: 19px;
          }
          & .dln-wishlist__text {
            margin-bottom: 34px;
          }
          & .dln-wishlist__foot.fixed {
            padding: 15px 0 14px 0;
            margin: 0;

            & .dln-btn {
              width: 200px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }
}

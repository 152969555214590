[data-dlg-gigya="dlg-gigya-reset-password-container"]  {
  .gigya-reset-password-form {
    max-width: 600px!important;
  }

 .dlg-gigya-button-wrapper {
   max-width: 280px;
 }
}


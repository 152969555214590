.dln-kenwood {
    .dln-address {
        &__content {
            .dln-title {
                @include fontExtra;
                text-align: center;
            }
            .dln-addBox {
                /* width: calc(50% - 40px - 2px - 8px);
                margin: 0 16px 40px 0; */
                box-sizing: content-box;
            }
        }
        &--editing {
            .dln-address__section {
                &:nth-last-of-type(3) {
                    border-bottom: none;
                }
                &Head {
                    height: auto;
                    margin: 0 0 40px 0;
                    &Title {
                        font-size: 20px;
                        line-height: normal;
                        height: auto;

                    }
                    &InfoText {
                        line-height: 12px;
                        font-size: 12px;
                        align-self: center;
                    }
                }
            }
            .dln-address__form {
                & .dln-inputs,
                & >.dln-select {
                    margin: 0 20px 42px 0;

                    &:nth-child(2n+2) {
                        margin: 0 0 42px 0;
                    }
                    &:last-child {
                        margin: 0 0 0 0;
                    }
                    &:nth-last-child(2):nth-child(odd) {
                        margin: 0 20px 0 0;
                    }
                }
            }
        }
        &--editing {
            .dln-address__form {
                padding: 0;
                margin: 0;
            }

            @media only screen and (max-width: 599px) {
                .dln-address__form {

                    >.dln-select {
                        width: 100%;
                        margin: 0 0 30px 0 !important;
                    }
                    & .dln-inputs {
                        width: 100%;
                        margin: 0 0 32px 0 !important;
                    }
                }
            }
        }
    }
}

.dln-kenwood {
    @media only screen and (max-width:599px) {
        .dln-address {
            &__content {
                .dln-addBox {
                    width: 100%;
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}

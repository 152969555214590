@media only screen and (max-width:599px) {
    .dln-advtBox  {
        & > .dln-icn {
           width: 80px;
           height: 80px;
           line-height: 80px;
        }
        &__title {
            margin: 20px 0 5px 0;
        }
    }
}
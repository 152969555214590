.dln-prodReg {
    &--selectProduct {
        .dln-prodReg {
            &__head {
                margin: 50px 0 0;
                max-width: none;
            }
            &__content {
                max-width: 980px;
                margin: 60px auto 100px;
                display: flex;
                flex-wrap: wrap;
                >.dln-btn--link {
                    @include fontBold;
                    margin: 0 0 24px;
                }
                >.dln-prodReg__subTitle {
                    width: auto;
                    max-width: calc(100% - 150px);
                    margin: 0 auto 0 0;
                }
                .dln-prodReg__categoryBreadcrumbs {
                    width: auto;
                    max-width: calc(40%);
                    align-items: baseline;
                    align-content: baseline;
                }
            }
            &__productList {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin: 16px 0 0;
            }
            &__productHeading {
                width: calc(100% - 180px - 16px - 10px);
                margin: 0 0 0 auto;
                display: flex;
                flex-wrap: wrap;
                span {
                    @include small;
                    font-size: 12px;
                    color: $brand-02;
                    width: calc(50% - 20px - 5px);
                    margin: 0 20px 0 0;
                    box-sizing: border-box;
                }
            }
            .dln-prodReg__categoryBreadcrumbs {
                padding: 10px;
            }
        }
    }
}
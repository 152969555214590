.dln-prodReg--confirmation {
    display: flex;
    flex-wrap: wrap;
    padding-top: 85px;

    & .dln-confirmation-page__wrapper-content {
        display: flex;
        flex-wrap: wrap;
        // padding: 0 30px;
        // margin-top: 0;
        // max-width: 1440px;
        // margin: 0 auto;
        width: 100%;
        
        & .dln-icn--mainIcon {
            color: var(--support-02);
        }
        & .dln-chips {
            font-size: 14px;
            padding: 0;
            margin: 0 0 41px 0;
    
            & .dln-chips__content {
                padding: 0;
            }
        }
    }    
    & .dln-confirmation-page__title-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background: $ui-03;
        padding: 0 0 117px;
        margin-bottom: 36px;
        box-sizing: border-box;
    
        // & .dln-page {
        //     max-width: 1440px;
        //     background-color: transparent !important;
        //     margin: 0 auto;
        // }
        & > .dln-wrapper {
            width: 100%;
        }
        & .dln-title {
            margin: 0;
        }
    }
    & .dln-confirmation-page__breadCrumbs-wrapper {
        padding-top: 48px;
        margin-bottom: 20px;
    
        & .dln-breadcrumbs {
            margin: 0;
        }
    }
    & .dln-confirmation-page__icon-container {
        font-size: 14px;
    }
    & .dln-confirmation-page__button-container {
        width: 214px;
    }
    & .dln-confirmation-page__wrapper-content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 93px 0;

        & > .dln-wrapper {
            width: 100%;
        }
    }
}
.dln-kenwood {
    .dln-regAdv {
        border-top: 1px solid $ui-01;

        .dln-icn {
            color: $brand-01;
        }

        .dln-regAdv__text {
            font-size: 14px;
            line-height: 20px;
        }

        .dln-regAdv__textWrap {
            border-bottom: none;
            padding: 0;
        }

        .dln-regAdv__item {
            border-bottom: 1px solid $ui-01;
            padding: 12px 0;
        }

        @media only screen and (max-width:599px) {
            .dln-icn {
                font-size: 40px;
                margin-right: 33px;
            }
            .dln-regAdv__textWrap {
                width: calc(100% - 32px - 34px);
            }
        }
    }
}
.dln-kenwood {
    & .dln-tabs {
        &__links {
            margin: 0 64px 0 0;
            font-size: 16px;

            &--active {
                @include fontBold;
            }
        }
        &__line {
            bottom: 0;
        }

        &--icon {
            background-color: $brand-03;

            & .dln-tabs__item--current .dln-icn {
                color: $brand-04;
            }
        }

        @media only screen and (max-width:599px) {
            &__links {
                margin: 0 16px 0 0;
                font-size: 16px;
            }
        }
    }

    @media only screen and (max-width:599px) {
        .dln-tabs {
            &__btn {
                border-left: 1px solid $ui-01;
            }
            &--icon .dln-tabs__item {
                &:first-child {
                    border-right: none;
                }
            }
            & .dln-icn {
                line-height: 40px;
            }
        }
        .dln-icn {
            font-size: 21px;
        }
    }
}
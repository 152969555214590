@media only screen and (max-width:599px) {
    .dln-prodReg {
        &--productDetails {
            .dln-prodReg {
                &__content {
                    >.dln-prodReg__subTitle {
                        max-width: 100%;
                        margin: 0 0 16px;
                        line-height: normal;

                    }
                }
            }
            .dln-itemRegDetails__itemName {
                width: 100%;
            }
        }
    }
}
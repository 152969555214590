.dln-order--detail {
    & .dln-order__detailProducts--returned {
        position: relative;

        & .dln-order__trackingCode {
            position: absolute;
            right: 24px;
            top: 24px;
            width: auto;
            color: var(--brand-04);
            font-weight: 500;
        }

        & .dln-order__returnBox {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            border-radius: 6px;
            padding: 20px 20px 30px 20px;
            background-color: $ui-03;
            margin-bottom: 30px;

            &Title {
                display: block;
                width: 100%;
                @include fontBold;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 8px;
            }
            &Text {
                display: block;
                width: 100%;
                font-size: 13px;
                line-height: 20px;
            }
        }
        & .dln-orderHistory {
            & .dln-orderRev__itemPic {
                opacity: 0.3;
            }
            & .dln-orderRev__content {
                & .dln-orderRev__details {
                    opacity: 0.3;
                }
            }
        }
    }
}

.dln-select {
  display: inline-block;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  position: relative;
  &__container {
    display: inline-block;
    width: 100%;
    height: 40px;
    line-height: 16px;
    font-size: 15px;
    color: #333;
    user-select: none;
    position: relative;
    &.dln-select--has-value {
      .dln-select__head {
        border-color: $ui-02;
      }
      .dln-select__option {
        &:first-child {
          display: block;
          color: $brand-02;
        }
        &.dln-select__option-selected {
          font-weight: bold;
          color: $brand-04;
        }
      }
    }
  }
  &__select {
    width: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: -100%;
  }
  &__head {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    border: 1px solid $ui-01;
    color: $brand-04;
    transition: border-color 200ms;
    padding: 8px 16px 8px;
    overflow: hidden;
    position: relative;
  }
  &__body {
    min-width: 120px;
    /* box-shadow: 0 0 6px rgba(0, 0, 0, 0.08); */
    border: 1px solid $ui-01;
    border-width: 0 1px 1px;
    background: white;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 99;
  }
  &__items-list {
    overflow: auto;
  }
  &__items-list {
    max-height: 0;
    transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    overflow: auto;
  }
  &__option {
    @include small;
    border: 1px solid $ui-01;
    border-width: 0 0 0;
    padding: 12px 16px;
    cursor: pointer;
    position: relative;
    &::after {
      content: '';
      width: calc(100% - 24px);
      height: 1px;
      background: $ui-03;
      position: absolute;
      bottom: 0;
      left: 12px;
    }
    &:first-child {
      /* padding: 4px 16px;
            font-size: 10px; */
      /* display: none; */
      // questa option se nascosta crea conflitto con la libreria
      color: $brand-02;
    }
    &:last-child {
      border-width: 0 0 0;
      &::after {
        display: none;
      }
    }
    &-selected {
      &::before {
        @include fontIcon;
        content: 'u';
        display: none;
        position: absolute;
        top: 0;
        left: 6px;
      }
    }
    &-focused {
      color: $brand-04;
      background-color: $ui-03;
    }
    &:hover {
      background-color: $ui-03;
    }
  }
  &__value {
    @include small;
    /*  font-size: 13px;
        line-height: 100%;
        font: inherit; */
    display: inline-block;
    width: calc(100% - 16px);
    //color: $brand-02;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__arrow {
    width: 16px;
    height: 16px;
    transition: transform 150ms;
    margin: 0 0 0 auto;
    /* position: absolute;
        right: 8px;
        top: calc(50% - 10px); */
    position: relative;
    &::before {
      @include fontIcon;
      content: 'u';
      width: 100%;
      height: 100%;
      line-height: 18px;
      transform-origin: 50%;
      transition: transform 200ms;
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &--disabled {
    opacity: 0.6;
    pointer-events: none;
  }
  &--light {
    & .dln-select__head,
    & .dln-select__body {
      border: none !important;
    }
    & .dln-select--open {
      & .dln-select__body {
        color: $brand-04;
        box-shadow: 0 5px 8px rgba(0, 0, 0, 0.05);
      }
    }
    & .dln-select__container {
      @include fontBold;
    }
    & .dln-select__value {
      @include fontBold;
      opacity: 1;
    }
  }
  &--brand-03 {
    & .dln-select__container {
      color: $brand-03;
    }
    & .dln-select__arrow {
      &::before {
        color: $brand-03;
      }
    }
  }
  &--labeled {
    .dln-select__head {
      border-radius: 2px;
    }
    .dln-select__value {
      opacity: 0;
    }
  }
  &--has-value {
    .dln-select__value {
      opacity: 1;
    }
    & + .dln-select__lbl {
      width: auto;
      background-color: $brand-03;
      transform: translateY(calc(50% - 28px)) scale(0.75);

      @media (-ms-high-contrast: none) {
        transform: translateY(-28px) scale(0.75);
        top: 50%;
      }
    }
  }
  &--open {
    .dln-select__value {
      opacity: 1;
    }
    .dln-select__body {
      opacity: 1;
      transform: scale(1);
      transition: opacity 200ms,
        transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
      /* max-height: 180px; */
      overflow: auto;
      pointer-events: all;
    }
    .dln-select__arrow::before {
      transform: rotate(180deg);
    }
    .dln-select__head {
      border-bottom: 1px solid $ui-03;
    }
    & + .dln-select__lbl {
      width: auto;
      background-color: $brand-03;
      transform: translateY(calc(50% - 28px)) scale(0.75);
    }
  }
  &--invalid {
    .dln-select__head {
      border-color: $support-01 !important;
    }
    .dln-select__container:not(.dln-select--open) {
      .dln-select__value {
        color: $support-01;
      }
    }
    .dln-select__lbl--error {
      display: block;
      color: $support-01;
      transform: translateX(0) scale(0.75);
      margin: 4px 0 0;
      position: absolute;
      /*  color: $support-01; */
      top: auto;
      bottom: auto;
      left: auto;
    }

    &.dln-select--labeled {
      .dln-select__lbl--active {
        top: calc(50% - 8px);
      }
      .dln-select--open,
      .dln-select--has-value {
        & ~ .dln-select__lbl--active {
          display: none;
        }
      }
    }
  }
  &__lbl {
    @include small;
    font: inherit;
    display: inline-block;
    color: $brand-02;
    white-space: nowrap;
    text-overflow: ellipsis;
    transform-origin: 0 0;
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding: 0 4px;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: calc(50% - 9px);
    left: 8px;
    &--error {
      display: none;
    }
  }
  &--innerIcn {
    > .dln-icn--iconfont {
      font-size: 16px;
      color: $ui-02;
      position: absolute;
      top: calc(50% - 8px);
      left: 8px;
    }
    .dln-select__head {
      padding: 8px 16px 8px 32px;
    }
  }

  &--extraLight {
    width: auto;
    height: 20px;

    .dln-select__container {
      height: 20px;
    }
    .dln-select__head {
      align-items: flex-start;
      border: none;
      padding: 0;
    }
    .dln-select__body {
      // border-width: 1px 1px 1px;
      border-top: 1px solid $ui-01;
    }
    .dln-select__value {
      @include fontBold;
      width: auto;
      line-height: 17px;
      color: $brand-04;
      opacity: 1;
      padding: 0;
      margin: 0 4px 0 0;
    }
    & .dln-select__arrow {
      width: 10px;
      // padding-top: 4px;
      height: 20px;
      // height: 16px;
      // line-height: 16px;
      font-size: 11px;

      &::before {
        height: 17px;
        height: 100%;
        line-height: 19px;
        padding: 0 0 0 0;
      }
    }
  }
  &--optionsDisabled {
    .dln-select__body {
      cursor: auto;
      pointer-events: auto;
    }
    .dln-select__option {
      cursor: auto;
      pointer-events: none;
      &:hover {
        background-color: transparent;
      }
    }
  }
  &--open-above {
    .dln-select__body {
      bottom: 100%;
    }
    .dln-select__head {
      border-top: 0;
    }
    &.dln-select--open {
      .dln-select__body {
        border-width: 1px 1px;
      }
      .dln-select__head {
        border-bottom: 1px solid var(--ui-01);
      }
    }
    &.dln-select--light,
    &.dln-select--extraLight {
      .dln-select--open {
        .dln-select__body {
          border-width: 0;
        }
        .dln-select__head {
          border-bottom: 0;
        }
      }
    }
  }
}

.dln-payCardBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    border: 1px solid $ui-01;
    background-color: $brand-03;

    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 60px;
        background-color: $ui-01;
        padding: 0 20px 0 20px;
    }
    &__creditCard {
        display: flex;
        flex-wrap: wrap;
        width: 46px;
        height: 29px;
        border: 1px solid $brand-01;
        border-radius: 3px;
        background-color: $brand-03;
        box-sizing: border-box;

        &Strip {
            align-self: flex-end;
            width: 100%;
            height: 4px;
            border-top: 1px solid $brand-01;
            border-bottom: 1px solid $brand-01;
            margin: 0 0 5px 0;
        }
    }
    &__creditCardImage {
        display: flex;
        flex-wrap: wrap;
        width: 46px;
        height: 29px;
    }
    &__cardInfo {
        display: block;
        width: calc(100% - 46px - 20px);
        height: auto;
        line-height: 20px;
        font-size: 13px;
        @include fontBold;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 5px 0 0 0;
        margin: 0 0 0 20px;
        overflow: hidden;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding: 20px;
    }
    &__field {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        margin: 0 0 16px 0;



        &Label {
            display: block;
            width: 100%;
            height: auto;
            line-height: 16px;
            font-size: 10px;
            font-weight: 400;
            color: $ui-02;
        }
        &Data {
            display: block;
            width: 100%;
            height: auto;
            line-height: 16px;
            font-size: 13px;
        }
    }
    &__foot {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        width: 100%;
        .dln-checkbox {
            width: 100%;
            margin: 0 0 16px;
        }
    }
    & .dln-btn--link {
        display: inline-flex;
        font-size: 13px;

        &:first-of-type {
            margin: 0 0 0 auto;

            &::after {
                content: '';
                display: block;
                display: none;
                width: 1px;
                height: 16px;
                background-color: $brand-01;
                margin: 0 8px;
            }
        }
    }
}
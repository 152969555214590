@media only screen and (max-width:599px) {
    .dln-alert {
        &--addedItem {

            .dln-alert__content {
                &--extra {

                    .dln-btn {
                        margin: 0 auto 0;
                    }

                }
            }
        }
        .dln-addItem {
            width: 100%;
            margin: 0 0 32px 0;
            .dln-btn {
                margin: 8px 0 0;
            }
            &:nth-child(3n+3) {
                margin: 0 0 32px;
            }
        }
        .dln-cartItem {
            &__picture {
                width: 110px;
                margin: 0 0 0 0;
            }
            &__title {
                width: calc(100% - 110px - 24px);
            }
            &__price {
                text-align: left;
                width: calc(100% - 110px - 24px);
                margin: 16px 0 0 16px;
            }
            .dln-csmDropdown {
                width: 100%;
                margin: 8px 0 0 0;
            }
        }
    }
}
.dln-chooseCountry {
	& > .dln-wrapper {
		margin-top: 60px;
		margin-bottom: 70px;
	}

	.dln-page__content {
		width: 100%;
		margin-top: 40px;
	}
}
.dln-kenwood {
    .dln-page--imageHeader__header {
        background-color: $brand-03;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-top: 77px;
        margin-bottom: 70px;
        padding-bottom: 0;

        .dln-wrapper--main {
            margin: 0;
            padding: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
        }

        .dln-mainTitle {
            @include fontExtra;
            font-size: 38px;
            font-weight: 500;
            line-height: 1.21;
            margin: 0;
            color: $brand-04;
            text-align: center;
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .dln-unsubscribePage {
        padding-bottom: 95px;
        position: relative;
        .dln-page--imageHeader__picture {
            position: absolute;
            z-index: 1;
            top: 300px;
            left: 15%;
            width: 450px;
        }

        .dln-page--imageHeader__content {
            position: relative;
            z-index: 3;

        }

        .dln-page__title {
            font-size: 21px;
            line-height: 1.12;
            text-align: center;
            margin-bottom: 50px;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 7px;
        }

        .dln-page__paragraph {
            font-size: 18px;
            line-height: 1.07;
            text-align: center;

        }
        .dln-page__text {
            @include fontRoman;
            font-size: 18px;
            line-height: 1.33;
            text-align: center;
            margin-bottom: 48px;
        }

        .dln-page__actions {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            padding-bottom: 124px;
            margin-top: 0;
        }

        .dln-wrapper {
            padding-top: 0;
            &--content {
                max-width: 1300px;
                margin: 0 auto;
            }
            &--fullWidth {
                .dln-wrapper {
                    max-width: 1300px;
                }
            }
        }
    }

    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
        // padding: 20px;

        .dln-page__actions {
            margin-top: 30px;
        }
    }

    @media only screen and (max-width: 599px) {
        .dln-page--imageHeader__header {
            margin-top: 0;

            .dln-wrapper--main {
                flex-direction: column-reverse;
                padding: 0;
            }

            .dln-page--imageHeader__picture {
                display: block;
                width: 200px;
                height: 200px;
                position: static;
                margin-top: 48px;
            }

            .dln-mainTitle {
                font-size: 32px;
                line-height: 1.25;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: center;
            }
        }

        .dln-unsubscribePage {
            .dln-page--imageHeader__content {
                padding: 0 19px;
                width: 100%;
                margin: 0;
            }

            .dln-page__title {
                font-size: 20px;
                margin-bottom: 30px;
                line-height: 1.2;
                padding: 0;
            }

            .dln-page__paragraph {
                margin: 0 0 10px 0;
            }

            .dln-page__text {
                font-size: 14px;
            }

            .dln-page__actions {
                margin-top: 0;
                padding-bottom: 56px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .dln-btn {
                    width: 255px;
                    margin-right: 0 !important;
                }

                .dln-btn--kenwood {
                    margin-bottom: 8px !important;
                }
            }
        }
    }
}

.dln-delonghi {
  .dln-order {
    &--status {
      .dln-wrapper--heading {
        .dln-title {
          margin-bottom: 28px;
        }
      }
      .dln-order {
        &__text {
          color: $brand-04;
          font-size: REM(16px) !important;
        }

        &__title {
          color: $brand-04;
          font-size: REM(21px);
          @include fontMedium;
          margin-bottom: 16px;
        }

        &__userCard {
          &--registered {
            padding-right: 114px;
          }

          &Form {
            margin-bottom: 0;
          }

          .dln-inputs {
            margin-bottom: 30px;
          }
          .dln-btn {
            width: 100%;
            max-width: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    .dln-order {
      &--status {
        .dln-order {
          &__text {
            font-size: REM(15px) !important;
          }
          &__title {
            font-size: REM(18px);
            margin-bottom: 8px;
          }
          &__userCard {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

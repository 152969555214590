.dln-countrySelection--modal {
	max-width: 1440px;
	margin: 0 auto;
    padding: 30px;

	.dln-popup__head {
		position: relative;
		padding: 0;
		margin-bottom: 40px;

		.dln-popup__headTitle {
			width: auto;
			max-width: calc(100% - 44px);
			padding: 0;
			margin: 0;
		}
		button.dln-btnIcon--close {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 30px;
			margin: 0;
		}
	}
}

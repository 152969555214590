.dln-kenwood {
    & .dln-order {
        &--detail {
            & > .dln-wrapper {
                & .dln-order__content {
                    & .dln-order__detailProducts {
                        &--shipped {
                            & .dln-order__detailTitle {
                                width: calc(100% - 20px - 112px);
                                margin-right: 20px;
                            }
                            & .dln-order__trackingCode {
                                @include fontBold;
                                width: 112px;
                                color: $brand-04;
                            }
                            & .dln-order__downloadLabel {
                                @include fontBold;
                                color: $brand-04;
                            }
                            & .dln-orderHistory {
                                & .dln-orderRev {
                                    &__content {
                                        & .dln-orderRev__details {
                                            & .dln-orderRev__wrap {
                                                &--main {
                                                    margin-bottom: 32px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-order {
            &--detail {
                & > .dln-wrapper {
                    & .dln-order__content {
                        & .dln-order__detailProducts {
                            &--shipped {
                                & .dln-order__detailTitle {
                                    height: 22px;
                                    line-height: 22px;
                                    margin-bottom: 40px;
                                }
                                & .dln-order__trackingCode {
                                    height: 22px;
                                    line-height: 22px;
                                    margin-bottom: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

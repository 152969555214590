.dln-braun {
  .dln-cartItem {
    &__content {
      .dln-cartItem__quantity {
        z-index: 3;

        .dln-inputIncr {
          height: 17px;

          &__btn {
            height: 17px;

            .dln-icn {
              line-height: 17px;
              font-size: 10px;
            }

            &--decrement {
              .dln-icn {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &__price {
      .dln-cart-order-item__vat-included {
        color: $brand-01;
      }

      .dlg-omnibus-price {
        &__old-price {
          flex-direction: row;
        }
      }
    }

    &__closeBtn {
      z-index: 3;
    }
  }

  &
    .dln-cart
    > .dln-wrapper
    .dln-cart__content
    .dln-cart__items
    .dln-cartItem__picture {
    height: unset;
  }
}

@media only screen and (max-width: 599px) {
  .dln-braun {
    .dln-cartItem {
      .dln-popup-entry {
        font-size: REM(13px);
        line-height: 1.46;
        letter-spacing: normal;
        color: #000;
      }

      &__content {
        .dln-cartItem__quantity > i {
          top: 2px;
        }
      }
    }
  }
}

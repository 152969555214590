@media only screen and (max-width:599px) {
    // .dln-recipe {
    //     &__img {
    //         .dln-icn {
    //             width: 34px;
    //             height: 34px;
    //             &:before {
    //                 font-size: 20px;
    //             }
    //         }
    //     }
    //     &__title {
    //         margin: 0 0 8px 0;
    //     }

    //     &--big-card {
    //         .dln-recipe__img {
    //             border-radius: 5px;
    //         }
    //         .dln-recipe__title {
    //             font-size: 13px;
    //             line-height: 16px;
    //             padding-bottom: 3px;
    //         }
    //         .dln-recipe__details {
    //             display: block;
    //             font-size: 13px;
    //             line-height: 24px;

    //             .dln-recipe__detail + .dln-recipe__detail {
    //                 margin-top: 5px;
    //                 margin-left: 0;
    //             }
    //         }
    //     }
    // }
    .dln-recipe {
        &__img {
            & .dln-btnIcon {
                top: 10px;
                right: 10px;
            }
        }
        &__title {
            & a {
                font-size: 13px;
                line-height: 16px;
            }
        }
        &__details {
            & .dln-recipe__detail {
                width: 100%;
                font-size: 13px;

                &--preparationTime {
                    margin-bottom: 0;
                }
            }
        }
    }
}
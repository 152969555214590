.dln-order {
    &--status {
        & .dln-wrapper--heading {
            padding-top: 48px;

            & .dln-title {
                margin-bottom: 20px;
            }
            .dln-order__text {
                font-size: 14px;
                line-height: 1.43;
            }
        }
    }
    &__text {
        display: block;
        width: 100%;
        font-size: 13px;
        line-height: 1.54;
        color: $brand-01;
    }
    & .dln-wrapper--userCards {
        justify-content: center;
        padding-top: 70px;
        padding-bottom: 100px;
    }
    &__userCard {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 50%;
        max-width: 494px;
        padding: 0 110px;
        box-sizing: border-box;

        &--registered {
            width: calc(50% - 1px);
            border-right: 1px solid $ui-01;
            padding: 0 110px 0 0;
        }
        &--newUser {
            padding: 0 0 0 110px;
        }
        & .dln-order__text {
            margin-bottom: 24px;
        }
        &Form {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 24px;

            & .dln-inputs {
                margin: 0 0 16px 0;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    &__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 21px;
        font-size: 17px;
        color: $brand-01;
        margin: 0 0 8px 0;
    }
}

.dln-table {
    width: 100%;
    position: relative;
    display: block;
    padding: 16px 0 0;
    overflow: auto;
    &__tdHeading {
        display: none;
    }
    &__table {
        font-size: 14px;
        width: 100%;
        text-align: left;
        color: $brand-04;
        border-spacing: 0;
        border-collapse: collapse;
        thead {
            th {
                color: $brand-04;
                @include fontBold;
                padding: 0 4px 16px 0;
                border-bottom: 1px solid $ui-02;
                &:nth-child(1) {
                    padding: 0 0 16px 16px;
                }
                &:nth-last-child(1) {
                    padding: 0 16px 16px 0;
                }
            }
        }
        tbody {
            td {
                padding: 16px 0;
                border-bottom: 1px solid $ui-02;
            }
            tr {
                &:nth-child(odd) {
                    td {
                        background-color: $ui-03;
                    }
                }
                & td {
                    &:nth-child(1) {
                        padding: 16px 0 16px 16px;
                    }
                    &:nth-last-child(1) {
                        padding: 16px 16px 16px 0;
                    }
                }
            }
        }
        th,
        td {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 34px;
        }
    }
}
@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .dln-faq {
        & .dln-wrapper--noResults {
            & .dln-faq__mainTitle {
                line-height: 43px;
                font-size: 35px;
                margin-bottom: 20px;
            }
            & .dln-faq__mainSubtitle {
                line-height: 21px;
                font-size: 15px;
                margin-bottom: 10px;
            }
            & .dln-faq__spellCheck {
                font-size: 14px;
                margin-bottom: 20px;
            }
            & .dln-inputs {
                margin-bottom: 40px;
            }
        }
        & .dln-wrapper--noResultsCategory {
            & .dln-faq__text {
                font-size: 14px;
                margin-bottom: 20px;
            }
        }
    }    
}
@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-checkout {
    > .dln-wrapper {
      align-content: flex-start;
      padding-top: 0;
      padding-bottom: 20px;
    }

    &__aside {
      width: 100%;
      order: 2;
    }

    &__content {
      order: 3;
      margin: 0 0;
      width: 100%;

      .dln-checkout__form .dln-address_info {
        margin: 0 0 20px;
      }

      .dln-invoiceForm,
      .dln-guestChoiceForm {
        position: relative;
        width: 100%;

        .row {
          width: 100%;
        }
      }
    }
    .dln-orderSumm__contentRow--taxes,
    .dln-orderSumm__contentRow--shipping {
      .dln-tooltip {
        & .dln-tooltip__container {
          width: 135px;
        }

        & .dln-tooltip__content {
          width: 100%;
          text-align: left;
        }

        & .dln-tooltip__text {
          max-width: none;
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: 1080px) {
  .dln-checkout {
    &__content {
      .dln-checkout__innerCol {
        width: 100%;
        margin: 0 0 0 0;

        &:nth-child(2n + 2) {
          margin: 0 0 0 0;
        }

        & + .dln-checkout__innerCol {
          .dln-checkout__innerColTitle {
            @include cta;
            padding: 20px 0 0;
            border-top: 1px solid $ui-01;
            border-bottom: none;
            margin: 32px 0 16px;
          }
        }
      }

      .dln-invoiceForm,
      .dln-guestChoiceForm {
        position: relative;
        width: 100%;

        .row {
          width: 100%;

          &.region {
            width: 65%;
          }
        }
      }

      .dln-checkout__innerColTitle {
        margin: 26px 0 8px;
        border-bottom: none;
        padding: 0 0 0;
      }
    }

    .dln-accordion--payment {
      .dln-checkout__formField {
        .dln-inputs {
          &--securityCode,
          &--exipirationdate {
            max-width: 200px;
            margin: 0 auto 32px 0;
          }
        }

        &--invoice {
          .dln-checkout__formField {
            .dln-inputs {
              margin: 0 21px 32px 0;
            }

            &--fiscalCode__ita {
              display: flex;
              flex-direction: column;
              padding: 0;

              .dln-checkout {
                &__generalities,
                &__birthPlace {
                  @media only screen and (max-width: 599px) {
                    display: inline-block;
                    width: 100%;
                  }
                  display: flex;
                  width: 75%;
                }

                &__birthPlace {
                  margin-bottom: 32px;
                }

                &__third {
                  @media only screen and (max-width: 599px) {
                    display: inline-block;
                    width: 100%;
                    margin-bottom: 32px;
                  }
                  display: flex;
                  width: 50%;

                  & div:nth-child(2) {
                    & label {
                      top: 12px;
                    }
                    .dln-select__lbl .dln-select__lbl--error {
                      top: auto;
                    }
                  }
                }

                &__fiscalCode {
                  @media only screen and (max-width: 599px) {
                    width: 100%;
                  }
                  width: 27%;
                }
              }
            }
          }
        }
      }
    }

    &__form {
      div[class*='--oneThird'],
      .dln-inputs--selectInside,
      > .dln-select {
        width: 100%;
        margin: 0 0 32px 0;
      }

      div[class*='--oneThird'] {
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4),
        &:nth-last-child(6),
        &:nth-last-child(7) {
          margin-right: 21px;
        }
      }

      div[class*='--twoThird'] {
        width: 100%;
        margin: 0 0 32px 0;
        & + div[class*='--oneThird'] {
          width: 100%;
        }
      }

      div[class*='--last'] {
        margin: 0 0 32px 0;
      }
    }

    .dln-loginForm {
      padding: 0 0 0 0;
      & + .dln-btn {
        width: 100%;
      }
    }
  }
}

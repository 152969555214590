@media only screen and (max-width:599px) {
    .dln-customerSupport {
        & .dln-wrapper {
            padding-right: 20px;
            padding-left: 20px;
            margin: 0;

            &--fullWidth {
                padding-top: 24px;
                padding-bottom: 32px;
            }
            &--content {
                padding-bottom: 32px;
            }
            &--quickLinks {
                padding-top: 0;
                padding-bottom: 64px;
                .dln-customerSupport__inner {
                    padding-top: 0;
                }
            }
        }
        &__head {
            padding: 0 !important;

            &Title {
                line-height: 40px;
                font-size: 35px;
                margin: 0 0 4px 0;
            }
        }
        &__title {
            line-height: 18px;
            font-size: 15px;
        }
        &__subtitle {
            font-size: 15px;
        }
        & .dln-inputs--searchBar {
            width: 100%;
            margin: 0 0 48px 0;
        }

        & .dln-customerSupport__searchWrapper {
            padding-bottom: 8px;

            & .dln-customerSupport__inner {
                padding-top: 20px;
                padding-bottom: 0;

                &Title {
                }
                & .dln-inputs--searchBar {
                    width: 100%;
                }
            }
        }
    }
}
.dln-prdReview {
    align-content: flex-start;

    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {
        & .dln-title {
            margin: 0 0 32px 0;
        }
        & .dln-addBox {
            width: calc(50% - 40px - 2px - 8px);
            margin: 0 16px 40px 0;
            box-sizing: content-box;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        &Title {
            display: block;
            width: 100%;
            line-height: 32px;
            font-size: 32px;
            @include fontBold;
            margin: 0 0 40px 0;
        }
    }
    & .dln-orderRev--productReview {
        margin: 0 0 10px 0;
    }
    &__paragraph {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        font-weight: 400;
        color: $brand-04;
        margin: 0 0 20px 0;

        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
    }
    &__subTitle {
        @include big;
        @include fontBold;
        font-size: 32px;
        margin: 0 0 8px;
    }
    &__foot {
        text-align: center;
        width: 100%;
        margin: 0 0 32px;
        .dln-btn {
            width: auto;
        }
        &--divider {
            margin: 32px 0 0;
            border-top: 1px solid $ui-01;
            text-align: right;
            padding: 32px 0 0;
        }
    }
}
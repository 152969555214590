.dln-browse-by {
    display: flex;
    // flex-direction: column;
    width: 100%;

    &__gradient {
        display: block;
        width: 32px;
        height: 32px;
        opacity: 0;
        transition: opacity 0.12s linear;
        position: absolute;
        top: 0;
        z-index: 4;

        &.-left {
            background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            left: 0;
        }
        &.-right {
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
            right: 0;
        }
        &.-visible {
            opacity: 1;
        }
    }
    &__linksWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: 14px;
    }
    &__link {
        display: inline-block;
        width: 12.5% !important;
        font-size: 16px;
        color: $brand-04;
        line-height: 21px;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 4px 16px 4px;
        box-sizing: border-box;
        overflow: hidden;

        &Prefix {
            display: none;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .dln-browse-by{
        .dln-browse-by{
            flex-direction: row;
            width: 100%;
            max-width: 100%;
            position: relative;
            z-index: 1;

            &__linksWrapper {
                flex-wrap: nowrap;
                justify-content: initial;
                width: auto !important;
                min-width: 100%;
                height: auto;
            }
            &__link {
                padding: 0;
                width: auto !important;
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-checkout {
        &__aside {
            .dln-checkout__noticeBox {
                display: none;
            }
            &--secondary {
                display: flex !important;
                order: 4 !important;
                .dln-checkout__noticeBox {
                    display: flex;
                    margin: 0 auto;
                    width: auto;
                }
            }
        }
        &__noticeBox {
            max-width: 100%;
            .dln-noticeBox {
                width: 100%;
                &:nth-child(even) {
                    &::after {
                        content: '';
                        position: absolute;
                        width: calc(100% - 48px);
                        height: 1px;
                        right: 24px;
                        top: auto;
                        bottom: 0;
                    }
                }
            }
        }
    }
}
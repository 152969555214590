.dln-kenwood {
    & .dln-shipDelivery {
        & .dln-popup__head {
            margin-bottom: 28px;

            &Title {
                display: block;
                text-align: center;
            }
        }
        &__title,
        &__text {
            font-size: 14px;
        }
    }
}
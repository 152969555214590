@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-customerSupport {
        // & .dln-wrapper {
        //     padding-right: 20px;
        //     padding-left: 20px;
        //     margin: 0;

        //     &--fullWidth {
        //         padding-top: 24px;
        //         padding-bottom: 32px;
        //     }
        //     &--content {
        //         padding-bottom: 32px;
        //     }
        //     &--quickLinks {
        //         padding-top: 32px;
        //         padding-bottom: 64px;
        //     }
        // }
        // &__head {            
        //     padding: 0 !important;

        //     &Title {
        //         line-height: 40px;
        //         font-size: 35px;
        //         margin: 0 0 4px 0;
        //     }
        // }
        // &__title {
        //     line-height: 22px;
        //     font-size: 20px;
        // }
        & .dln-inputs--searchBar { 
            width: 100%;
            // margin: 0 0 48px 0;
        }
    }
}

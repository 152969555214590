.dln-contactUs--thankYou {
    & .dln-wrapper--thankYouMessage {
        margin-bottom: 125px;
    }
    & .dln-contactUs__title {
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 32px;
        margin-bottom: 30px;
    }
    & .dln-contactUs__thankYouMessage {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        height: auto;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 70px 0;
        margin: 0 0 30px 0;

        &IconWrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            margin: 0 40px 0 0;

            & .dln-icn {
                width: 48px;
                height: 48px;
                line-height: 48px;
                font-size: 48px;
                color: $support-02;

                &::before {}
            }
        }
        &Wrap {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 48px - 40px);
        }
        &Text {
            display: block;
            width: 100%;
            line-height: 20px;
            font-size: 14px;
        }
    }
    & .dln-btn--backToContactUs {
        @include fontBold;
        font-size: 17px;
    }
}
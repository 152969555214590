@media only screen and (max-width: 599px) {
    .dln-subscription-flow {
        height: auto;
        padding-bottom: 60px;

        &__column-one {
            display: none;
        }
        &__column-two {
            width: 100%;
            padding: 25px 20px 0 20px;

            .dln-title {
                font-size: 17px;
                text-align: center;
            }

            .dln-popup__head {
                padding-bottom: 0px;
            }
            & .dln-title {                
                line-height: 21px;
                font-size: 17px;
                text-align: center;
                margin: 0 0 22px 0;
            }
            & .dln-subscription-flow__sub-title {
                display: block;
                width: 100%;
                line-height: 21px;
                font-size: 14px;
                text-align: center;
                margin: 0 0 20px 0;
            }
            & .dln-subscription-flow__service-container {
                margin: 0 0 32px 0;
            }
            & .dln-subscription-flow__input-container {
                & .dln-subscription-flow__row {
                    flex-wrap: wrap;
                    margin: 0;
                    
                    & .dln-inputs {
                        width: 100%;
                        margin: 0 0 30px 0;

                        & + .dln-inputs {
                            margin: 0 0 30px 0;
                        }
                    }
                    & + .dln-inputs {
                        width: 100%;
                        margin: 0;
                    }
                }
                & > .dln-checkbox {
                    padding-top: 20px;

                    .dln-checkbox__wrap {
                        margin-top: 0 !important;
                    }
                    .dln-checkbox__tooltipWrap {
                        margin: 0 24px !important;
                    }
                }
                & .dln-subscription-flow__checkbox-container {
                    height: auto;

                }
                & .dln-subscription-flow__button-container {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 30px 0 0 0;                    

                    & button.dln-btn {
                        margin: 0 0 0 auto;
                    }
                }
            }
        }
        &--thankYouPage {
            // & .dln-suscription-flow__column-one {
            //     display: none;
            // }
            & .dln-subscription-flow__column-two {
                & .dln-btnIcon--close {
                    position: absolute;
                    top: 25px;
                    right: 20px;
                }
                & .dln-title {
                    margin-bottom: 20px;
                }
                & .dln-subscription-flow__sub-title {
                    margin: 0 0 40px 0;
                    text-align: left;
                }
                & .dln-subscription-flow__button-container {
                    margin: 0 auto;

                    & .dln-btn {
                        margin: 0;
                    }
                }
            }
        }
    }
}
.dln-recipe-by-ingredients {
    margin-top: 5px;

    &__search-section {
        margin-top: 40px;
        margin-bottom: 10px;
        width: 390px;
    }

    .dln-inputs--active .dln-inputs__lbl {
        display: none;
    }
    
    &__title {
        @include fontBold;
        font-size: 36px;
        line-height: 1.22;
        margin-bottom: 20px;
    }

    .dln-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 1300px;
        width: 100%;
        padding: 30px 30px 0;
        margin: 0 auto;
    }

    .dln-filters-bar {
        margin-top: 50px;
        margin-bottom: 10px;
    }

    &__load-more {
        margin: 60px auto 20px;

        .dln-btn {
            margin-top: 20px;
        }
    }
}
.dln-kenwood {
    .dln-contactUs--thankYou {
        & .dln-wrapper--thankYouMessage {
            margin-top: 120px;
            margin-right: auto;
            margin-left: auto;
        }
        & .dln-contactUs__title {
            @include fontExtra;
            font-size: 28px;
            line-height: 36px;
            margin: 0 auto;
            margin-bottom: 24px;
            text-align: center;
        }
        & .dln-contactUs__thankYouMessage {
            position: relative;
            padding-bottom: 39px;
            margin-bottom: 19px;

            &IconWrap {
                position: absolute;
                width: 80px;
                height: 80px;
                font-size: 80px;
                line-height: 80px;
                margin: 0 auto;
                left: 50%;
                transform: translateX(-50%);
                top: -2em;

                & .dln-icn {
                    width: 80px;
                    height: 80px;
                    color: $support-02--bright;
                    font-size: 80px;
                    line-height: 80px;

                    &::before {}
                }
            }
            &Wrap {
                text-align: center;
                max-width: 794px;
                margin: 0 auto;
            }
            &Text {
            }
        }
        & .dln-btn--backToContactUs {
            @include fontMedium;
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            margin: 0 auto;
        }

        @media only screen and (max-width:599px) {
            & .dln-wrapper--fullWidth {
                margin-bottom: 0;
                padding-top: 37px;
            }
            & .dln-wrapper--thankYouMessage {
                margin-top: 115px;
                margin-bottom: 70px;
            }
        }
    }
}
.dln-delonghi {
  & .dln-ordReturnHowItWorks {
    & .dln-popup__head {
      padding-bottom: 26px;
      padding-bottom: 28px;

      & .dln-btnIcon--close {
        margin-bottom: 6px;
      }
      &Title {
        font-size: REM(37px);
        line-height: 37px;
      }
    }
    &__content {
      padding-bottom: 60px;

      & .dln-btn--trackingCode {
        margin-bottom: 40px;
        @include fontMedium;
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-ordReturnHowItWorks {
      & .dln-popup__head {
        padding-bottom: 32px;

        & .dln-btnIcon--close {
          margin-bottom: 40px;
        }
        &Title {
          font-size: REM(28px);
          line-height: 30px;
        }
      }
      &__content {
        padding-right: 30px;

        & .dln-btn--trackingCode {
          display: none;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-countrySelection--modal {
    & .dln-popup__head {
      &Title {
        font-size: REM(50px);
      }
    }
    & .dln-country-list {
      & .dln-accordion {
        &__head {
          & .dln-accordion__title {
            font-size: REM(28px);
          }
        }
        &__content {
          & .dln-flag__list {
            & .dln-flag__listItem {
              & .dln-flag__languages {
                font-size: REM(16px);
              }
            }
          }
        }
      }
      &
        .dln-flag__list
        .dln-flag__listItem
        .dln-flag__languages
        .dln-flag__lang
        strong,
      & .dln-accordion .dln-accordion__head .dln-accordion__title {
        color: $brand-04;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-countrySelection--modal {
      & .dln-popup__head {
        &Title {
          font-size: REM(37px);
        }
      }
    }
  }
}

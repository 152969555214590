.dln-delonghi {
  .dln-registration {
    &__subtitle {
      @include fontMedium;
      font-size: REM(21px);
      line-height: 1.12;
    }
    &__paragraph {
      font-size: REM(16px);
      line-height: 1.5;
    }
    .dln-title {
      @include fontMedium;
      font-size: REM(50px);
      line-height: 1.04;
    }
    .dln-btn {
      width: 100%;
    }
    .dln-loginForm__btnGroup {
      .dln-btn {
        min-width: auto;
        width: calc(50% - 10px);
        padding: 0 15px;
      }
    }
    .dln-regAdv__item .dln-icn {
      color: $brand-04;
    }

    .dln-loginForm__paragraph {
      text-align: left;
      font-size: REM(16px);
      line-height: 1.5;
    }
    .dln-btn--secondary {
      text-align: right;
    }
    .dln-regAdv {
      margin: 0 0 40px;

      &__text {
        font-size: REM(14px);
        line-height: 1.3;
      }
    }
  }
  @media only screen and (max-width: 599px) {
    .dln-registration {
      &__subtitle {
        @include fontMedium;
        font-size: REM(18px);
        line-height: 1.11;
      }
      &__paragraph {
        font-size: REM(15px);
        line-height: 1.53;
      }
      .dln-title {
        font-size: REM(37px);
        line-height: 1.08;
        display: block;
      }
      .dln-regAdv {
        padding-left: 20px;
        padding-bottom: 55px;
      }
    }
  }
}

.dln-delonghi {
  .dln-quickLinks--slider .dln-wrapper {
    .dln-quickLinks__inner {
      max-width: map-get($grid-breakpoints, xl);
      margin: 0 auto;
    }

    .dln-quickLinks__title {
      @include fontMedium;
      font-size: REM(28px);
      line-height: 1.21;
      margin-bottom: 0;
    }

    .dln-quickLink {
      border-bottom: none;

      &__title {
        @include fontMedium;
        color: $brand-04;
        font-size: REM(18px);
        line-height: 1.11;
      }
      &__link {
        @include fontMedium;
        font-size: REM(14px);
        line-height: 1.29;
      }
      &__iconWrap {
        border-color: transparent;
        .dln-icn {
          color: $brand-04;
        }
      }
    }
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
      &__head {
        &Title {
          font-size: REM(37px);
          line-height: 1.08;
        }
      }
      &__title,
      &__searchTitle {
        font-size: REM(21px) !important;
        line-height: 1.1;
      }

      .dln-wrapper {
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;
      }

      .dln-quickLinks__title {
        font-size: REM(21px);
        line-height: 1.1;
      }
    }
  }
}

.dln-prodReg {
    .dln-prodReg__subTitle {
        width: auto;
        font-size: 32px;
        margin: 30px 0 10px;
    }
    .dln-prodReg__confirmation:last-child {
        margin-top: 30px;
    }
    .dln-prodReg__confirmation {
        width: auto;
        display: block;
        a {
            all: unset;
            text-decoration: underline;
            color: black;
            font-weight: 700;
            cursor: pointer;
        }
    }
    &--confirmation {
        padding-top: 0;
        .dln-prodReg {
            &__head {
                max-width: none;
                margin: 50px 0 0;
            }
            &__content {
                display: flex;
                flex-wrap: wrap;
                max-width: 1000px;
                margin: 60px auto 100px;

                .dln-prodReg__contentRow {
                    display: flex;
                    flex-direction: row;
                    margin: 0 50px;
                    width: 100%;
                    border-bottom: 1px solid $ui-01;

                    & div {
                        flex-direction: column;
                    }

                    & .dln-orderRev__itemPic {
                        width: 100%;
                        max-width: 250px;
                        margin: 0 30px 30px 0;
                    }
                }

                .dln-prodReg__lastRow {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                }

                >.dln-btn {
                    width: auto;
                    margin: 0 0 0 auto;

                    &--link {
                        align-self: flex-end;
                        width: auto;
                        margin: 0 auto 0 0;
                    }
                }
                .dln-prodReg__categoryBreadcrumbs {
                    align-items: baseline;
                    align-content: baseline;
                    width: auto;
                    max-width: calc(40%);
                }
                // .dln-prodReg__chips {
                //     width: 100%;
                //     border-bottom: 1px solid $ui-01;
                //     margin: 0 0 32px;

                //     .dln-icn--mainIcon {
                //         color: var(--support-02);
                //     }
                // }
                .dln-prodReg__notice {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    width: 50%;
                    margin: 30px 0 0;

                    .dln-noticeBox {
                        align-items: center;
                        justify-content: space-between;
                        width: calc(100% - 10px);
                        padding: 32px 50px;
                        border: none;

                        &__description {
                            display: flex;
                            align-items: center;
                        }

                        &__content {
                            width: calc(100% - 48px - 36px);
                            margin: 0;
                            flex-direction: row;
                            display: flex;
                        }

                        &__icon {
                            align-items: center;
                            width: auto;
                            padding: 0 15px;
                        }

                        &__title {
                            margin: 0;
                        }

                        &:nth-child(2n+2) {
                            margin: 0 0;
                        }
                        &__copy {
                            min-height: initial;
                        }
                    }
                }
                .dln-prodReg__marketingUpdate{
                    background: $ui-01;
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    padding: 35px;
                    margin: 30px auto 0;
                    max-width: calc(50% - 100px);
                    justify-content: space-evenly;
                    h3 {
                        margin-bottom: 15px;
                    }
                    div {
                        margin: 15px 0;
                    }
                    .dln-preferences {
                        display: flex;
                        margin-bottom: 16px;

                        .dln-text {
                            text-align: start;
                            width: 100%;
                            margin-left: 14px;
                            color: var(--brand-02);
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }
                    .dln-benefitCheckBox {
                        height: 24px;
                        width: 24px;
                    }
                }
            }
        }
    }

}

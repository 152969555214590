@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-recipe-page {
        &__title {
            font-size: 24px;
            line-height: 1.13;
            margin-top: 14px;
        }

        &__details {
            margin-top: 30px;
            .dln-image {
                max-height: 400px;
            }
        }

        &__grid {
            flex-direction: column-reverse;
            max-height: none;
            &-inverse {
                .dln-recipe-page__info,
                .dln-recipe-page__bottom-info {
                    padding-right: 0;
                    padding: 0 20px;
                }
            }
        }
        &__col {
            flex: 1 1 100%;
            min-width: 100%;
            max-width: 100%;
            padding: 0 25px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
        }

        &__bottom-info {
            margin-top: 45px;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            height: auto;

            &-col {
                flex: 1 1 50%;
                margin-bottom: 15px;
            }
            &-col + .dln-recipe-page__bottom-info-col  {
                border-left: none;
                margin-left: 0;
                padding-left: 0;
            }
        }

        &__title-recipe {
            margin-top: 20px;
            font-size: 24px;
            line-height: 1.13;
        }
        &__description {
            margin-top: 15px;
            margin-bottom: 0;
        }

        &__info-button-mobile {
            display: block;
            margin: 40px auto 30px;
        }
        &__info-button-desktop {
            display: none;
        }
        .dln-site-search__toolbar {
            display: none;
        }

        .dln-site-search__toolbar--recipes{
            // display: none;
            width: 100%;
        }
    }
}

.dln-socialAcc {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: auto;
    border-radius: 2px;
    background-color: $brand-03;
    // padding: 24px 40px 40px 40px;
    padding: 0 40px 40px 40px;
    &__socialbtnGroup {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .dln-btn {
            width: calc(50% - 5px);
            margin: 0 10px 10px 0;
            &:nth-child(even) {
                margin: 0 0 10px 0;
            }
            &:nth-last-of-type(1),
            &:nth-last-of-type(2) {
                margin-bottom: 0;
            }
        }
    }
    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        min-height: 22px;
        border-bottom: 1px solid $ui-02;
        border-radius: 2px 2px 0 0;
        padding: 16px 0 16px 0;
        margin: 0 0 16px 0;

        &Title {
            display: block;
            width: calc(100% - 40px - 16px);
            line-height: 19px;
            font-size: 16px;
            @include fontBold;
            padding: 0 0 0 0;
            margin: 0 16px 0 0;
        }
        & .dln-btnIcon {
            margin: 0 0 0 auto;
        }
    }
    &__form {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        margin: 0;

        .dln-inputs {
            margin: 0 0 24px 0;
        }
        .dln-btn[type="button"]:not(.dln-btn--social) {
            margin: 24px 0 24px 0;
            width: 100%;
        }
        .dln-btn--link {
            margin: 0 auto;
        }
        .dln-inputs~.dln-btn[type="button"]:not(.dln-btn--social) {
            margin: 0 0 24px 0;
        }
    }
    &__text {
        display: block;
        width: 100%;
        line-height: 20px;
        margin: 0 0 16px 0;
        &--divider {
            margin: 16px auto;
            text-align: center;
            position: relative;
            &::before,
            &::after {
                content: '';
                position: absolute;
                height: 1px;
                background: $ui-01;
                width: calc(50% - 15px);
                top: calc(50% + 1px);
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
    }
    &__wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .dln-btn {
            margin: 0 auto 0 0;
            &:last-of-type {
                margin: 0 0;
            }
        }
    }
}
.dln-findProductNumber {
    padding: 0 100px 55px 100px;
    .dln-popup__head {
        padding-bottom: 40px;
        .dln-btnIcon--close {
            transform: translateX(80px);
        }
    }
    &__description {
        @include fontRoman;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 34px;
    }
    &__imgWrapper {
        // display: inline-block;
        // margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: transparent;

        & > img {
            width: auto;
            max-width: 100%;
            height: auto;
            object-fit: initial;
            position: initial;
        }
    }
    &__note {
        @include fontRoman;
        line-height: 20px;
        font-size: 14px;
        margin-top: 25px;
    }
}
.dln-delonghi {
    & .dln-prodReg {
      & .dln-breadcrumbs {
        padding-top: 20px;
      }
      &__head {
        width: 100%;
        max-width: none;
        margin-top: 0;
      }
      &__subtitle {
        margin: 64px 0 16px 0;
      }
    }
  }
  
  @media only screen and (max-width: 599px) {
    .dln-delonghi {
      & .dln-prodReg {
        & .dln-breadcrumbs {
          padding-bottom: 30px;
        }
        & .dln-prodReg__head {
          margin-bottom: 47px;
        }
        & .dln-prodReg__subTitle {
          line-height: 23px;
          font-size: REM(21px);
          margin-bottom: 19px;
        }
        & .dln-prodReg__content {
          & .swiper-wrapper {
            margin-bottom: 24px;
  
            & .swiper-slide {
              & .dln-advtBox {
                margin-bottom: 0;
              }
            }
          }
          & .swiper-pagination {
            margin: 0;
          }
        }
      }
    }
  }
  
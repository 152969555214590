.dln-delonghi {
  .dln-page .dln-wrapper .dln-page__content .dln-savedRec__subtitle {
    margin-top: 7px;
    margin-bottom: 30px;

    font-size: REM(16px);
    line-height: 24px;

    @media only screen and (max-width: 599px) {
      font-size: REM(16px);
      line-height: 24px;

      margin-top: 0;
      margin-bottom: 14px;
    }
  }

  .dln-savedRec {
    &__recipeWrap {
      padding: 0;

      & .dln-recipe {
        margin-bottom: 20px;

        &:nth-of-type(4n + 4) {
          margin-bottom: 20px;
        }
      }
    }

    @media only screen and (max-width: 599px) {
      &__recipeWrap {
        border-bottom: 1px solid $ui-01;
      }

      &__content {
        .dln-btn {
          margin-top: 24px;
        }
      }
    }
  }
}

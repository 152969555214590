.dln-cart {
    &__aside {
        & .dln-cart__orderRecap {
            & .dln-orderSumm {
                &__content {
                    &Row {
                        &--taxes {
                            & .dln-orderSumm__field {
                                & .dln-inputs {
                                    &__lbl {
                                        width: calc(100% - 24px);
                                    }
                                    &--active {
                                        & .dln-inputs__lbl {
                                            width: auto;
                                        }
                                    }
                                    &--invalid {
                                        margin-bottom: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .dln-cart__head {
        .dln-chips.dln-chips--advantage {
            .dln-btnIcon--close {
                span {
                    display: none;
                }

                i, i::before, i::after {
                    font-size: 1.4rem;
                    color: var(--brand-04);
                }
            }
        }
    }
}
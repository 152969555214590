.dln-kenwood {
    .dln-repairCenter {
        &__subtitle {
            font-size: 14px;
        }
        &__content {
            & .dln-btn {
                margin: 0 auto;
            }
            &Wrap {
                & .dln-table {
                    &__table {
                        font-size: 13px;
                        & thead {
                            border-bottom: none;

                            & tr {
                                background-color: $brand-03 !important;
                            }
                        }
                        & tr {
                            // display: flex;
                            // flex-wrap: wrap;
                            width: 100%;
                            border-bottom: 1px solid $ui-01;
                            // padding: 0 16px;
                            box-sizing: border-box;

                            &:nth-of-type(odd) {
                                background-color: $ui-03;
                            }
                            & th,
                            & td {
                                min-height: 50px;
                                border: none;
                                background: none;
                                background-color: transparent;
                                padding: 16px;
                                box-sizing: border-box;

                                /* &:first-of-type {
                                    order: 1;
                                    width: 16%;
                                }
                                &:nth-of-type(2) {
                                    order: 3;
                                    width: 17%;
                                }
                                &:nth-of-type(3) {
                                    order: 4;
                                    width: 30%;
                                }
                                &:nth-of-type(4) {
                                    order: 2;
                                    width: 17%;
                                }
                                &:nth-of-type(5) {
                                    order: 5;
                                    width: 20%;
                                    padding: 16px 0 16px 0;
                                } */
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        .dln-repairCenter {
            &__content {
                & .dln-title {
                    margin: 30px 0 20px 0;
                }
                & .dln-btn {
                    width: 50%;
                }
                &Wrap {
                    & .dln-table {
                        &__table {
                            & tr {
                                border-top: 1px solid $ui-01;
                                padding: 14px 10px;

                                &:not(:last-of-type) {
                                    border-bottom: none;
                                }
                                & th,
                                & td {
                                    height: auto;
                                    min-height: initial;
                                    max-height: none;
                                    padding: 0 !important;

                                    &:first-of-type {
                                        width: 100%;
                                        padding: 0 8px 0 0 !important;
                                    }
                                    &:nth-of-type(2) {
                                        width: 100%;
                                        padding: 0 0 0 8px !important;
                                    }
                                    &:nth-of-type(3) {
                                        width: 100%;
                                        padding: 20px 0 !important;
                                    }
                                    &:nth-of-type(4) {
                                        width: 100%;
                                    }
                                    &:nth-of-type(5) {
                                        width: 100%;
                                    }
                                    & .dln-table__tdHeading {
                                        line-height: 12px;
                                        color: $brand-02;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &__subtitle {
                margin: 0 0 30px 0;
            }
        }
    }
}
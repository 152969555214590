.dln-chips {
    width: 100%;
    border: 1px solid $ui-01;
    background-color: $ui-03;
    padding: 16px;
    position: relative;
    &__strong {
        display: block;
        width: 100%;
    }
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .dln-btnIcon {
            width: auto;
            height: auto;
            transform: rotate(-45deg);
            position: absolute;
            right: 16px;
            z-index: 9;
            .dln-icn {
                margin: 0;
            }
        }
    }
    &__content {
        width: 100%;
        position: relative;
    }
    &--iconinside {
        .dln-chips__content {
            width: calc(100% - 26px);
        }
    }
    span:not(.dln-chips__strong) {
        //@include extraExtraSmall;
        line-height: 14px;
        display: block;
        width: 100%;
        color: $ui-06;
        margin: 0 0 16px;
        &:last-child {
            margin: 0 0;
        }
    }
    .dln-icn {
        height: auto;
        font-size: 16px;
        color: $ui-06;
        margin: 0 8px 0 0;
    }
    a {
        color: $ui-06;
    }
    &--light {
        display: inline-block;
        width: auto;
        color: $brand-03;
        padding: 4px 8px;

        span {
            @include fontBold;
            display: inline-block;
            width: auto;
            font-size: 10px;
            color: $brand-03;
            text-transform: uppercase;
        }
    }
    &--lightGray {
        border: none;
        background-color: $ui-02;

        span:not(.dln-chips__strong) {
            color: $brand-03;
        }
    }
    &--warning {
        border-color: $support-04;

        .dln-chips__wrapper {
            align-items: flex-start;
        }
        .dln-icn {
            color: $support-04;
        }
        span {
            font-size: 13px;

            &:not(:first-of-type) {
                color: $brand-04;
            }
            &:first-of-type {
                line-height: 11px;
                padding-top: 2px;
            }
            b {
                color: $brand-04;
            }
        }
    }
    &--advantage {
        border-color: $support-02;
        background-color: $brand-03;
        padding: 8px 16px;
        .dln-chips__content {
            width: auto;
            max-width: calc(100% - 30px);
            margin: 0 auto;
            >span {
                @include mainMenu;
                line-height: 150%;
                color: $brand-04;
                .dln-icn {
                    font-size: 30px;
                    color: $support-02;
                }
            }
        }
    }
    &--largeIcon {
        & .dln-icn--mainIcon {
            width: 48px;
            height: 48px;
            line-height: 48px;
            font-size: 48px;
            // background: rgba(0,0,0,.1);
            margin: 0 32px 0 0;
        }
        & .dln-chips__content {
            width: calc(100% - 80px);
            padding: 0 30px 0 0;
        }
        &.dln-chips--recallInformation {
            & .dln-icn--mainIcon {
                color: $support-02;
            }
            & .dln-chips__info,
            & .dln-chips__title {
                @include fontSemibold;
            }
            & .dln-chips__description {
                @include fontLight;
            }
            & .dln-btn--link {
                @include fontMedium;
            }
        }
        & .dln-btn--link {
            display: inline-block;
            font-size: 13px;
            color: $brand-04;
            margin: 0 0 8px 0;
        }
        & .dln-btnIcon--close {
            top: 12px;
            right: 12px;

            & .dln-icn {
                font-size: 22px;
            }
        }
    }
    &__info {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 16px;
        font-size: 16px;
        color: $brand-02;
        padding: 4px 0 0 0;
        margin: 0 0 8px 0;
    }
    &__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 22px;
        font-size: 18px;
        color: $brand-04;
        padding: 4px 0 0 0;
        margin: 0 0 8px 0;
    }
    &__description {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        color: $brand-04;
        margin: 0 0 16px 0;
    }
    &--extraLight {
        border: none;
        background-color: transparent;
    }
}

@import "_chips-kenwood.scss";
@import "_chips-delonghi.scss";

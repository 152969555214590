.dln-return-full-account {
    &__content {
        flex-direction: column;

        & > .dln-btnTextIcon--backToOrderDetail {
            & .dln-btnTextIcon__iconWrap,
            & .dln-btnTextIcon__icon {
                text-decoration: none;
            }
            & .dln-btnTextIcon__text {
                text-decoration: underline;
            }
        }
    }
    &__title {
        @include fontBold;
        line-height: 1.25;
        font-size: 44px;
        margin-top: 20px;
    }
    &__order-infos {
        display: flex;
        margin-top: 20px;
    }
    &__info {
        line-height: 1.25;
        font-size: 16px;
        + .dln-return-full-account__info {
            margin-left: 50px;
        }
    }
    &__value {
        @include fontBold;
    }
    &__text {
        line-height: 1.54;
        font-size: 13px;
        margin-top: 30px;
    }
    &__total-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid #e6e6e6;
        padding-top: 20px;
        padding-bottom: 40px;
        span {
            @include fontBold;
            line-height: 1.2;
            font-size: 15px;
        }
    }
    &__button-wrap {
        width: 100%;
        .dln-btn {
            width: 100%;
            max-width: 180px;
            line-height: 1.5;
            font-size: 14px;
            span {
                @include fontBold;
                line-height: 1.5;
                font-size: 14px;
            }
        }
    }

    &__product-list {
        padding-top: 20px;
        .dln-orderRev__quantity {
            line-height: 1.67;
            font-size: 12px;
            color: $brand-02;
        }
        .dln-price {
            line-height: 1.43;
            font-size: 14px;
        }
        .dln-select__value {
            color: $ui-04;
        }
    }

    &-modal {
        display: flex;
        justify-content: center;
        padding: 80px 100px 70px;

        &__thankYouMessageIconWrap {
            margin-right: 30px;

            .dln-icn {
                font-size: 48px;
                color: $support-02;
            }
        }
        &__thankYouMessageText {
            line-height: 1.43;
            font-size: 14px;
        }
        &__popup-content {
            max-width: 790px !important;

            .dln-btnIcon.dln-popup__closeBtn .dln-icn:before {
                content: 'J';
                color: $brand-04;
            }
        }

        .dln-btn {
            width: 180px;
            margin-top: 30px;
        }
    }
}

.dln-kenwood {
    .dln-checkout {
        &--express {
            & .dln-wrapper {
                & .dln-checkout__content {
                    & .dln-accordion {
                        &--shipping-information {
                            .dln-chips {
                                width: 100%;
                            }
                            & .dln-checkout__dataResult {
                                & .dln-checkout__formData {
                                    & p:first-child {
                                        & .dln-checkout__formValue:first-child {
                                            @include fontBold;
                                        }
                                    }
                                }
                            }
                        }
                        &--payment {                            
                            & .dln-accordion__content--submit {
                                padding-left: 50px;
                            }
                        }
                    }
                }
            }             
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        .dln-checkout {
            &--express {
                & .dln-wrapper {
                    & .dln-checkout__content {
                        & .dln-accordion {
                            &--payment {  
                                & .dln-checkout__formField {
                                    & .dln-btn--link {
                                        & .dln-btn__lbl {
                                            @include fontBold;
                                        }
                                    }
                                }                          
                            }
                        }
                    }
                }             
            }
        }
    }    
}
@media only screen and (max-width:599px) {
    .dln-prodReg {
        &--selectProduct {
            .dln-prodReg {
                &__content {
                    >.dln-btn--link {
                        font-size: 14px;
                    }
                    >.dln-prodReg__subTitle {
                        font-size: 21px;
                        width: 100%;
                        max-width: 100%;
                        margin: 0 0 8px 0;
                    }
                    .dln-prodReg__categoryBreadcrumbs {
                        display: none;
                    }
                }
                &__productList {
                    margin: 0 0;
                }
            }
            .dln-prodReg__productHeading {
                display: none;
            }
            .dln-prodReg__content {
                .dln-prodReg__categoryBreadcrumbs {
                    align-items: center;
                    align-content: center;
                }
            }
        }

    }
}
.dln-kenwood {
    & .dln-instSteps {
        &__step {
            margin-bottom: 30px;

            &Num {
                width: 35px;
                height: 35px;
                line-height: 35px;
                margin-right: 20px;
                font-size: 16px;
                @include fontSemibold;
            }
            &Text {
                width: calc(100% - 35px - 20px);
                color: $brand-04;
                padding: 7.5px 0;
            }
        }
    }
}

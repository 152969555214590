.dln-kenwood {
    .dln-loginForm {
        &__formContainer {
            margin-top: 24px;
            margin-bottom: 50px;
            padding-top: 40px;
            border-top: 1px solid $ui-01;
        }
        &__formFoot {
            .dln-btn--link {
                text-transform: uppercase;
                font-size: 12px;
                height: 45px;
                &:hover {
                    background-color: $brand-04;
                    color: $brand-03;
                }
            }
        }
        &__section--registration {
            margin-top: 0;
            padding-top: 45px;
            border-top: 1px solid $ui-01;
            &:before {
                content: none;
            }
        }
        &__subtitle {
            font-size: 20px;
            line-height: 24px;
            @include fontSemibold;
        }
        &__paragraph {
            margin-bottom: 36px;
            &--mainTitle {
                margin-bottom: 27px;
            }
        }

        .dln-btn--secondary {
            color: $brand-04;
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-order {
        & .dln-order__aside {
            width: 100%;
            padding-top: 20px;
            margin: 0;

            & .dln-profileCard {
                display: none;
            }
        }
        & .dln-order__content {
            width: 100%;
            padding-top: 29px;
        }
        &--history {
            & .dln-order__content {
                & > .dln-title {
                    margin-bottom: 22px;
                }
            }
        }
        &__historyCardWrap {
            & .dln-orderInfoCard {
                &:first-child {
                    padding-top: 0;
                }
            }
        }
    }
}

@media only screen and (max-width:900px) { 
  .dln-order--history {
    & .dln-order__content {
        width:100%;
        padding-top: 42px;
    }

    & .dln-title {
        text-align: center;
    }
  }
} 

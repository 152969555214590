.dln-delonghi {
  & .dln-faq {
    &--alert {
      & .dln-faq__content.-hideBreadcrumbs {
        margin-bottom: 80px;

        & > .dln-wrapper {
          border-bottom: none;
          padding-bottom: 0;

          & .dln-list {
            margin-bottom: 0;
          }
        }
      }
      & .dln-faq__helpSection {
        border-bottom: 1px solid $ui-01;
        padding-bottom: 40px;
        margin-bottom: 56px;

        & .dln-wrapper {
          & .dln-faq__helpSectionTitle {
            @include fontMedium;
            line-height: 34px;
            font-size: REM(28px);
            margin-bottom: 16px;
          }
          & .dln-faq__helpSectionText {
            line-height: 24px;
            font-size: REM(18px);
            margin-bottom: 23px;
          }
          & .dln-btn--backToSection {
            width: 100%;
            min-width: initial;
            max-width: 184px;
          }
        }
        & + .dln-quickLinks--slider {
          & > .dln-wrapper {
            & .dln-quickLinks__title {
              margin-bottom: 21px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-faq {
    &--alert {
      & .dln-faq__content.-hideBreadcrumbs {
        margin-bottom: 60px !important;

        & > .dln-wrapper {
        }
      }
      & .dln-faq__helpSection {
        margin-bottom: 40px !important;

        & .dln-wrapper {
          & .dln-faq__helpSectionTitle {
            line-height: 23px !important;
            font-size: REM(21px) !important;
          }
          & .dln-faq__helpSectionText {
            font-size: REM(16px) !important;
            margin-bottom: 35px !important;
          }
          & .dln-btn--backToSection {
          }
        }
      }
    }
  }
}

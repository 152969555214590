.dln-sortList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 6px;
    background-color: $brand-03;
    padding: 0 0 16px 0;
    box-sizing: border-box;

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        min-height: 30px;
        padding: 6px 0;
        box-sizing: border-box;
        position: relative;
        z-index: 4;

        &--disabled {
            opacity: .25;
            pointer-events: none;
        }
    }
    &__radio{
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }
    &__placeholder {
        width: 16px;
        height: 14px;
        margin: 0 13px 0 0;
        pointer-events: none;

        & .dln-icn--checkOutline {
            display: inline-block;
            width: 16px;
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            transform: scale(0);
            transform-origin: center;
            transition: transform 0.12s ease;
        }
    }
    &__label {
        width: calc(100% - 13px - 16px);
        line-height: 18px;
        font-size: 15px;
        margin: 0;
        pointer-events: none;
    }
    &__radio:checked {
        & + .dln-sortList__placeholder {
            & .dln-icn--checkOutline {
                transform: scale(1);
            }
            & + .dln-sortList__label {
                @include fontBold;
            }
        }
    }
}
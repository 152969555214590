.dln-kenwood {
    .dln-btnTextIcon {
        padding: 0 20px;
        border-radius: 2px;

        transition: all .3s;

        &__text {
            color: $brand-01;
            font-size: 12px;
            text-transform: uppercase;
        }

        &__iconWrap {
            color: $brand-01;

            & .dln-icn {
                font-size: 13px;
                transition: none;
            }
        }

        &:hover {
            background: $brand-01;

            .dln-btnTextIcon__text,
            .dln-btnTextIcon__iconWrap {
                color: $brand-03;
            }
        }


        &--brand-01 {
            .dln-btnTextIcon__iconWrap {
                .dln-icn {
                    color: $brand-03;
                }
            }
            .dln-btnTextIcon__text {
                color: $brand-03;
            }

            &:hover {
                background: $brand-03;

                transition: all .3s;

                .dln-btnTextIcon__iconWrap .dln-icn,
                .dln-btnTextIcon__text {
                    color: $brand-01;
                }
            }
        }

    }
}
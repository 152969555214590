.dln-delonghi {
  .dln-login-wishlist {
    .dln-popup__headTitle {
      @include fontMedium;
      font-size: REM(16px);
      font-weight: 500;
      line-height: 1.5;
      color: $brand-04;
    }

    .dln-loginForm {
      padding: 0 30px;
      .dln-loginForm__btnGroup {
        .dln-btn {
          width: calc(50% - 8px);
          min-width: auto;
        }
      }

      &__paragraph {
        @include fontRoman;
        font-size: REM(14px);
        line-height: 1.3;
      }
      &__formContainer .dln-loginForm__paragraph {
        text-align: center;
      }

      &__subtitle {
        @include fontMedium;
        font-size: REM(18px);
        line-height: 1.11;

        + .dln-loginForm__paragraph {
          text-align: center;
          color: $brand-04;
        }
      }

      .dln-inputs {
        width: 100%;
        min-width: 100%;
        border-color: $brand-04;
      }

      .dln-btn {
        width: 100%;
        &--secondary {
          @include fontRoman;
          font-size: REM(14px);
          line-height: 1.3;
          width: auto;
        }
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-loginForm {
        padding: 0;

        &__paragraph {
          text-align: left;
        }

        &__formContainer .dln-loginForm__paragraph {
          text-align: center;
          border-top: 1px solid $ui-01;
          margin: 0 auto;
          max-width: 60%;
          padding: 30px 0 25px;
        }
      }
    }
  }
}

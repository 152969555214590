.dln-ratingStars {
    direction: rtl;
    display: inline-flex;
    flex-wrap: wrap;
    width: auto;
    text-align: left;
    margin: 0 auto 8px 0;

    &:hover {
        transform: scale(1);
    }
    & input {
        display: none;
        pointer-events: none;
        position: absolute;
        &:checked,
        &:checked~label {
            .dln-icn {
                &::before {
                    content: 'p';
                }
            }
        }
    }
    &__error {
        @include small;
        display: inline-block;
        width: 100%;
        color: $support-01;
        text-align: left;
    }
    &--error {
        justify-content: flex-end;
    }
    &__star {
        display: inline-block;
        transition: .1s;
        cursor: pointer;
        position: relative;

        & input {
            display: none;
            position: absolute;
            bottom: -100px;
        }
        &--selected,
        &--selected~.dln-ratingStars__star {
            & .dln-icn {
                &::before {
                    content: 'p';
                }
            }
        }
        &--active,
        &--active ~ .dln-ratingStars__star {
            & .dln-icn {
                &::before {
                    content: 'p' !important;
                    color: $brand-01;
                }
            }
        }
    }
    &--static {
        .dln-ratingStars__star {
            cursor: auto;
        }
    }
    & .dln-icn {
        display: inline-block;
        width: 16px;
        height: 16px;
        color: $support-02;
        pointer-events: none;

        &::before {
            content: 'o';
            transition: .3s ease-in;
        }
    }
    &:not(.dln-ratingStars--static) {
        & input {
            pointer-events: auto;

            &:hover,
            &:hover~label {
                & .dln-icn {
                    &::before {
                        content: 'p';
                    }
                }
            }
        }
        & .dln-ratingStars__star {
            &:hover,
            & &:hover~.dln-ratingStars__star {
                .dln-icn {
                    &::before {
                        content: 'p';
                    }
                }
            }
        }
    }
}

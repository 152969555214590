.dln-persDataRecap {
    width: 100%;
    padding: 16px 24px 24px;
    border: 1px solid $ui-01;
    display: flex;
    flex-wrap: wrap;
    &__wrap {
        width: 100%;
        padding: 8px 0 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: 140px;
        overflow: hidden;
        .dln-inputs {
            margin: 0 24px 16px 0;
            width: calc(50% - 24px);
            overflow: hidden;
            /*   &:nth-child(2n+2) {
                margin: 0 0 16px 0;
            } */
        }
        .dln-select {
            margin: 0 24px 16px 0;
            width: calc(50% - 24px);
            max-width: none;
            .dln-select__head {
                border-color: $ui-02;
            }
        }
    }
    & .dln-btn {
        margin: 0 0 0 auto;
    }
    &:not(.dln-persDataRecap--editing) {
        .dln-persDataRecap__wrap {
            .dln-select {
                opacity: 1;
                margin: 0 24px 16px 0;
                width: calc(50% - 16px);
                max-width: none;
                display: inline-flex;
                .dln-select__container {
                    width: 50%;
                    opacity: 1;
                }
                .dln-select__container {
                    height: auto;
                }
                .dln-select__head {
                    padding: 0;
                    border-width: 0;
                }
                .dln-select__arrow {
                    display: none;
                }
                .dln-select__value {
                    @include small;
                    color: $brand-04;
                    @include fontBold;
                }
                .dln-select__lbl {
                    position: relative;
                    transform: none;
                    top: auto;
                    left: auto;
                    order: -1;
                    color: $brand-02;
                    @include small;
                }
            }
        }
    }
    &--editing {
        & .dln-persDataRecap__wrap {
            max-height: 236px;
        }
    }
}
.dln-kenwood {
    .dln-persDataRecap {
        padding: 20px 22px;

        & .dln-inputs,
        & .dln-persDataRecap {
            font-size: 14px;
            line-height: 20px;
        }
        &:not(.dln-persDataRecap--editing) {
            .dln-persDataRecap__wrap {
                .dln-inputs,
                .dln-select {
                    margin: 0 24px 24px 0;

                    .dln-select__container {
                        font-size: 14px;
                        line-height: 20px;
                        flex: 1;
                        .dln-select__head {
                            .dln-select__value {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }
                    }

                    input {
                        display: inline-flex;
                        flex: 1;
                        order: 0;
                        width: auto;
                        border: 0;
                        height: auto;
                        padding: 0;
                        margin: 0;

                        font-size: 14px;
                        line-height: 20px;

                        color: $brand-04;
                    }

                    label {
                        display: inline-flex;
                        order: -1;

                        align-items: center;
                        width: auto;
                        max-width: 50%;
                        transform: none;
                        position: relative;
                        top: auto;
                        left: auto;

                        font-size: 14px;
                        line-height: 20px;

                        color: $brand-04;

                        &:after {
                            content: ':'
                        }
                    }
                }
            }
        }

        @media only screen and (max-width:599px) {
            &__wrap {
                & .dln-inputs.dln-inputs--disabled {
                    .dln-inputs__lbl {
                        margin: 0 8px 0 0;
                    }
                    .dln-inputs__input,
                    .dln-inputs__lbl {
                        width: auto;
                    }
                }
            }
        }
    }

}
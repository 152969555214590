.dln-kenwood {
    & .dln-faq {
        &__content {
            & .dln-wrapper {
                & .dln-faq__breadCrumbSelWrap {
                    & + .dln-faq__mainSubtitle {
                        @include fontExtra;
                        line-height: 40px;
                        margin-bottom: 10px;
                    }
                }    
                & .dln-faq__videoContainer {
                    width: 100%;
                    margin-bottom: 30px;
                }
                & .dln-faq__articleFoot {
                    border: none;
                    padding: 0;
                    margin-bottom: 80px;
                }
                & .dln-faq__title--related {
                    text-align: left !important;
                    margin-bottom: 15px !important;
                }
                & .dln-faq__questionList {
                    margin-bottom: 0;
                }
                & .dln-faq__text {
                    &:last-of-type {
                        margin-bottom: 38px;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-faq {
            &__content {
                padding: 0;

                & .dln-wrapper {
                    & .dln-faq__breadCrumbSelWrap {
                        & + .dln-faq__mainSubtitle {
                            line-height: 34px;
                            // @include fontExtra;
                            // line-height: 40px;
                            // margin-bottom: 10px;
                            font-size: 28px;
                            text-align: center;
                            margin-bottom: 20px;
                        }                        
                    }    
                    & > .dln-faq__text {
                        text-align: center;

                        &:last-of-type {
                            margin-bottom: 25px !important;
                        }
                    }
                    & .dln-faq__videoContainer {                        
                        margin-bottom: 20px;
                    }            
                    & .dln-faq__articleFoot {
                        margin-bottom: 60px;
                    //     border: none;
                    //     padding: 0;
                    //     margin-bottom: 80px;  
                     
                        &::after {
                            display: none;
                        }                  
                        &Text {
                            width: calc(100% - 20px - 70px - 10px - 70px);
                            font-size: 14px;                            
                            margin: 0 20px 0 0;
                        }
                        & .dln-btn {
                            &--stroked {
                                width: 70px;
                                height: 40px;
                                padding: 0;
                                margin: 0;

                                &[title="Yes"] {
                                    margin-right: 10px;
                                }
                            }
                            &--link {
                                margin-top: 30px;

                                &:first-of-type {
                                    margin-right: 39px;
                                }
                            }
                        }                        
                    }
                    // & .dln-faq__title--related {
                    //     text-align: left !important;
                    //     margin-bottom: 15px !important;
                    // }
                    // & .dln-faq__questionList {
                    //     margin-bottom: 0;
                    // }
                }
            }
        }
    }    
}
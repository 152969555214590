@media only screen and (max-width:599px) {
    .dln-filtersPopup {
        height: 100vh;

        &__content {
            height: calc(100vh - 162px);
            overflow: hidden;
            overflow-y: auto;
        }
        &__actions {
            border-radius: 0;
        }
    }
}
.dln-search-result {
    display: flex;
    &:link,
    &:visited,
    &:hover,
    &:active {
        text-decoration: none;
    }

    &__content {
        width: calc(100% - 140px);
        padding-left: 20px;
        box-sizing: border-box;
    }
    &__itemPic {
        width: 140px;

        img {
            width: 100%;
            height: auto;
        }
    }
    &__category {
        display: block;
        font-size: 11px;
        color: #707070;
        margin-bottom: 9px;
    }
    &__detailsTitle {
        line-height: 15px;
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 15px;
        color: $brand-01;
    }
    &__priceWrapper {
        display: flex;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .dln-ratingStars {
        margin: 0;
    }
    &__ratingWrapper {
        display: flex;
        align-items: center;
        line-height: 1;
    }
    &__reviews {
        font-size: 12px;
        color: #707070;
        padding-left: 5px;
    }
    &__bv-review {
        transform: scale(0.65);
        transform-origin: left center;
    }
}

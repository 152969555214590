.dln-braun {
  .dln-popup {
    padding: 0;

    &__content {
      //max-width: 997px !important;

      .dln-popup__wrapper {
        .dln-addedItem {
          .dln-popup__head {
            margin-bottom: 28px;

            &Title {
              justify-content: center;
              height: 35px;
              text-align: center;

              .dln-icn {
                width: 35px;
                height: 35px;
                line-height: 35px;
                font-size: 16px;
                color: $brand-03;
                border-radius: 50%;
                background-color: $support-02;
                margin: 0 14px 0 0;
              }
            }
          }

          &__content {
            border-bottom: 1px solid $ui-01;
            padding: 24px 0 30px 0;
            margin-bottom: 40px;

            & .dln-cartItem {
              &__content {
                position: relative;
              }

              &__picture {
                width: 184px;
                height: 150px;
                margin-right: 20px;

                & > img {
                  object-fit: contain;
                }
              }

              &__title,
              &__price,
              &__quantity {
                width: calc(100% - 184px - 20px);
                margin: 0 auto 0 0;
              }

              &__title {
                @include fontSemibold;
                line-height: 22px;
                font-size: 16px;
                margin-bottom: 10px;
              }

              &__price {
                text-align: left;

                & .dln-price--oldPrice {
                  margin-right: 12px;
                }
              }

              & .dln-csmDropdown {
                width: calc(100% - 184px - 20px);
                margin: 0 auto 0 0;

                &__selected {
                  line-height: 20px;
                }
                & + .clearer {
                  display: none;
                }
              }

              &__btn {
                width: 182px;
                border-top: none;
                margin: 0 0 0 32px;
                position: absolute;
                bottom: 0;
                right: 0;

                .dln-notification {
                  margin-bottom: 20px;

                  &__desc {
                    @include fontLight;
                    font-size: 12px;
                    color: $brand-02;
                  }

                  .dln-btnIcon__checkoutCounter {
                    background-color: var(--support-02);
                  }
                }

                .dln-btn {
                  width: 100%;
                  margin: 0 0 10px 0;

                  &--stroked {
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }

          &__content--extra {
            border-bottom: none;
            background-color: $brand-03;
            padding: 0;
            margin-bottom: 0;

            @media only screen and (min-width: 599px) and (max-width: 1199px) {
              padding-bottom: 20px;
            }

            .dln-addedItem__subTitle {
              @include fontExtra;
              line-height: 36px;
              font-size: 13px;
              color: $brand-04;
              border: none;
              padding: 10px 0;
              width: 100%;
              margin-bottom: 30px;
              border-bottom: solid 1px $ui-01;

              @media only screen and (min-width: 599px) and (max-width: 1199px) {
                max-width: 70%;
              }
            }

            .dln-addItem {
              align-items: center;
              min-height: 150px;
              padding-left: calc(184px + 20px);
              position: relative;
              margin-bottom: 30px;

              &__picture {
                width: 184px;
                height: 150px;
                position: absolute;
                top: 0;
                left: 0;
              }

              &__content {
                width: 100%;
              }

              &__title,
              &__price {
                width: 100%;
              }

              &__title {
                display: block;
                font-size: 14px;
                margin-bottom: 8px;
              }

              .dln-btn {
                display: flex;
                align-self: flex-end;
                justify-content: center;
                width: 150px;
                height: 40px;
                color: $brand-02;
                border-color: $ui-01;
                margin: 0;

                .dln-icn {
                  width: 13px;
                  height: 13px;
                  font-size: 13px;
                  color: $ui-01;
                }

                &--support02 {
                  border-color: $support-02;

                  &:hover {
                    border: none;
                  }

                  .dln-icn {
                    color: $support-02;
                  }
                }

                .dln-btn__lbl {
                  margin: 0;
                  line-height: 1.6;
                }

                &:hover {
                  background-color: $brand-01;

                  .dln-icn {
                    color: $brand-03;
                  }
                  .dln-btn__lbl {
                    color: $brand-03;
                  }
                }
              }
            }

            .dln-btn--link {
              margin: 20px auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, md)) {
  .dln-braun {
    .dln-popup {
      padding: 20px !important;

      &__content {
        margin: 0;
        max-width: 584px !important;
        margin: auto 0px;
      }
      .dln-popup__content {
        .dln-popup__wrapper {
          & > div:first-child .dln-btnIcon {
            .dln-icn {
              color: var(--brand-04);
            }
          }
          .dln-addedItem {
            .dln-popup__head.dln-popup__head--circleClose.dln-popup__head--support02 {
              padding: 16px 40px 0;
              width: 100%;
              margin: 0;

              .dln-popup__headLine {
                background: var(--ui-01);
                display: block;
                width: 100%;
                height: 1px;
                margin: 6px 0 0;
              }
            }

            .dln-popup__headTitle {
              font-size: REM(15px);
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.27;
              letter-spacing: normal;
              color: #000;
              justify-content: start;

              .dln-icn {
                width: 29px;
                height: 24px;
                line-height: 1.3;
                font-size: 16px;
                color: var(--support-02);
                border-radius: 50%;
                background-color: white;
              }
            }

            &__content {
              width: 100%;
              margin: 0;
              padding: 10px 40px 24px;

              & .dln-cartItem {
                width: 100%;
                align-items: center;

                &__figure {
                  display: flex;
                }

                &__content {
                  padding: 0;
                }

                &__picture {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  width: 120px;
                  height: 96px;
                  max-height: none;
                  float: none;
                  margin: 0;
                }

                &__img {
                  width: 100%;
                  max-width: 216px;
                }

                &__title {
                  @include fontBold;
                  text-align: start;
                  position: relative;
                  left: 16px;
                  font-size: 18px;
                }

                &__price {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  text-align: initial;
                  width: auto;
                  top: 60px;
                  position: absolute;
                  left: 135px;
                }

                &__quantity,
                & .dln-csmDropdown {
                  width: 100%;
                }

                &__btn {
                  width: 100%;
                  margin: 14px 0 0 0;
                  position: initial;
                  border-top: 1px solid var(--ui-01);
                  padding-top: 0;
                  justify-content: space-between;

                  .dln-notification {
                    justify-content: start;
                    padding: 20px 0;
                    margin: 0;

                    .dln-btnIcon__checkoutCounter {
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                      width: 16px;
                      height: 16px;
                      color: $brand-03;
                      border-radius: 50%;

                      &Text {
                        display: inline-block;
                        width: 16px;
                        line-height: 17px;
                        text-align: center;
                      }
                    }

                    &__desc {
                      display: inline-block;
                      width: auto;
                      font-size: 13px;
                      color: var(--brand-04);

                      .dln-popup-entry {
                        font-weight: 400;
                      }
                    }
                  }

                  .dln-btn {
                    width: calc(50% - 10px);
                    height: 40px;
                  }
                }
              }

              &--extra {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .dln-addItem {
                  width: calc(50% - 20px);
                  padding: 0;
                  justify-self: start;
                  align-self: center;
                  min-height: 110px;
                  position: relative;
                  min-height: 114px;

                  .dln-btn {
                    width: 100%;
                    align-items: center;
                  }

                  &__content {
                    left: 96px;
                    position: relative;
                  }

                  &__picture {
                    width: 80px;
                    height: 66px;
                  }

                  &__title {
                    font-size: 18px;
                    width: calc(100% - 80px);
                    line-height: 90%;
                    margin: 0;
                    position: absolute;
                    top: -18px;

                    @media only screen and (min-width: 900px) {
                      position: relative;
                      top: 0;
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-braun {
    .dln-popup {
      .dln-popup__content {
        .dln-popup__wrapper {
          .dln-addedItem {
            &__content {
              width: 100%;
              padding: 0;

              & .dln-cartItem__picture {
                margin: 0 auto 10px auto;
              }

              & .dln-cartItem__title {
                width: 100%;
                margin: 0 0;
              }

              & .dln-cartItem {
                width: 100%;

                &__content {
                  padding: 0;
                }
                &__picture {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  width: 216px;
                  max-height: none;
                  height: 176px;
                  float: none;
                  margin: 0 auto 10px auto;
                }

                &__img {
                  width: 100%;
                  max-width: 216px;
                }

                &__title {
                  text-align: center;
                }

                &__price {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  text-align: initial;
                }

                &__quantity,
                .dln-csmDropdown {
                  width: 100%;
                }

                &__btn {
                  width: 100%;
                  margin: 0 0 30px 0;
                  position: initial;

                  .dln-notification {
                    justify-content: center;

                    .dln-btnIcon__checkoutCounter {
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                      width: 16px;
                      height: 16px;
                      color: $brand-03;
                      border-radius: 50%;
                      background-color: $brand-01;

                      &Text {
                        display: inline-block;
                        width: 16px;
                        line-height: 16px;
                        text-align: center;
                      }
                    }

                    &__desc {
                      display: inline-block;
                      width: auto;
                    }
                  }

                  & .dln-btn {
                    width: calc(50% - 5px);
                    &--stroked {
                      margin: 0 5px 0 5px;
                    }
                  }
                }
              }

              &--extra {
                padding: 0;

                .dln-addItem {
                  width: 100%;
                  padding: 0 0 0 calc(98px + 20px);

                  &__picture {
                    width: 98px;
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-braun {
    & .dln-popup {
      padding: 20px !important;

      &__content {
        margin: 0;
      }

      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-addedItem {
            .dln-popup__head.dln-popup__head--circleClose.dln-popup__head--support02 {
              padding: 16px 21px 0;
              margin: 0;

              .dln-popup__headLine {
                background: var(--ui-01);
                display: block;
                width: 100%;
                height: 1px;
                margin: 6px 0 0;
              }
            }

            .dln-popup__headTitle {
              font-size: REM(15px);
              font-weight: bold;
              line-height: 1.27;
              letter-spacing: normal;
              color: #000;
              justify-content: start;
              padding: 0;

              .dln-icn {
                width: 29px;
                height: 24px;
                line-height: 1.3;
                font-size: 16px;
                color: var(--support-02);
                border-radius: 50%;
                background-color: white;
              }
            }

            padding: 0;
            &__content {
              width: 100%;
              padding: 24px 0;
              margin: 0;

              & .dln-cartItem {
                width: 100%;

                &__figure {
                  padding: 0 84px;
                  display: flex;
                }

                &__content {
                  padding: 0;
                }
                &__picture {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  width: 120px;
                  height: 96px;
                  max-height: none;
                  float: none;
                  margin: 0;
                }
                &__img {
                  width: 100%;
                  max-width: 216px;
                }
                &__title {
                  text-align: start;
                  position: absolute;
                  left: 240px;
                }
                &__price {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  text-align: initial;
                  width: auto;
                  top: 2px;
                  position: absolute;
                  left: calc(100% - 100px);
                }
                &__quantity,
                & .dln-csmDropdown {
                  width: 100%;
                }
                &__btn {
                  width: 100%;
                  margin: 0 0 30px 0;
                  position: initial;
                  border-top: 1px solid var(--ui-01);
                  margin-top: 49px;
                  margin-bottom: 0;
                  padding-top: 0;
                  justify-content: center;

                  & .dln-notification {
                    justify-content: start;
                    margin: 0 0 0 6px;
                    padding: 13px 0 18px 15%;

                    & .dln-btnIcon__checkoutCounter {
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                      width: 18px;
                      height: 18px;
                      color: $brand-03;
                      border-radius: 50%;
                      background: var(--support-02);

                      &Text {
                        display: inline-block;
                        width: 16px;
                        line-height: 16px;
                        text-align: center;
                      }
                    }
                    &__desc {
                      display: inline-block;
                      width: auto;
                      color: #000;
                    }
                  }
                  & .dln-btn {
                    width: 33%;
                    margin: 0 8px;
                    height: 40px;

                    &--stroked {
                      margin: 0 5px 0 5px;
                    }
                  }
                }
              }

              &--extra {
                padding: 0;
                flex-direction: column;
                align-items: center;

                .dln-addItem {
                  width: 70%;
                  padding: 0;
                  justify-self: start;
                  align-self: center;
                  min-height: 110px;
                  position: relative;
                  margin: 0 auto 20px;

                  .dln-btn {
                    width: 100%;
                  }

                  &__content {
                    left: 96px;
                    position: relative;

                    .dln-addItem__title {
                      @include fontBold;
                      font-size: 16px;
                      line-height: 1;
                      letter-spacing: normal;
                      color: #1a1d1f;
                    }

                    .dln-addItem__price {
                      .dln-price {
                        font-size: 15px;
                      }
                    }
                  }

                  &__picture {
                    width: 80px;
                    height: 66px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-braun {
    & .dln-popup {
      padding: 20px !important;

      &--mCenter {
        .dln-popup__content {
          margin: 0 !important;
        }
      }

      &__content {
        .dln-popup__wrapper {
          .dln-addedItem {
            padding: 0;

            .dln-popup__head {
              padding: 20px 22px 0;
              margin-top: 0;

              &Title {
                height: auto;
                width: auto;
                font-size: 15px;
                position: relative;
                padding: 0;

                .dln-icn {
                  top: 0;
                  color: var(--support-02);
                  background-color: var(--brand-03);
                }
              }

              .dln-btnIcon--close {
                position: relative;
                top: 0;
                right: 0;
              }
            }

            &__content {
              width: 100%;
              padding: 0 22px;
              margin: 0;

              .dln-cartItem {
                width: 100%;

                &__content {
                  width: 100%;
                  padding: 0;
                }

                &__picture {
                  display: block;
                  width: 110px;
                  max-height: none;
                  height: 88px;
                  float: left;
                  margin: 0;
                }

                &__img {
                  width: 216px;
                  height: 176px;
                }

                &__title {
                  @include fontBold;
                  width: 80%;
                  text-align: start;
                  float: none;
                  margin: 0;
                  font-size: 15px;
                  padding-left: 25px;
                  left: 25px;
                  position: relative;
                  transform: translateY(-3px);
                }

                &__price,
                &__quantity,
                & .dln-csmDropdown {
                  width: 100%;
                  float: none;
                }

                &__price {
                  display: flex;
                  justify-content: start;
                  transform: translate(110px, -14px);
                  padding-left: 25px;
                  position: absolute;
                  top: 85px;
                }

                &__quantity {
                  justify-content: center;
                }

                &__warningMessage {
                  text-align: center;
                }

                &__btn {
                  width: 100%;
                  padding: 20px 0 16px 0;
                  margin: 24px 0 0 0;
                  position: initial;
                  top: initial;
                  right: initial;
                  border-top: 1px solid var(--ui-01);

                  .dln-notification {
                    margin-bottom: 16px;
                    align-items: baseline;

                    &__desc {
                      line-height: 0.6;
                      position: absolute;
                      top: 3px;
                      left: 26px;
                    }

                    .dln-btnIcon__checkoutCounter {
                      display: flex;
                      flex-wrap: wrap;
                      align-items: center;
                      justify-content: center;
                      width: 18px;
                      height: 18px;
                      color: $brand-03;
                      border-radius: 50%;
                      background-color: var(--support-02);

                      &Text {
                        display: inline-block;
                        width: 16px;
                        font-size: 13px;
                        line-height: 1.2;
                        text-align: center;
                      }
                    }

                    &__desc {
                      line-height: 0.6;
                      display: inline-block;
                      width: auto;
                      color: #000;
                    }
                  }

                  .dln-btn {
                    width: 100%;
                    min-width: 0;
                    padding: 0;
                    margin: 4px 0;
                    box-sizing: border-box;
                  }
                }
              }
            }

            &__content--extra {
              margin-bottom: 0;
              padding: 0 20px 20px;

              .dln-addItem {
                width: 100%;
                min-height: 80px;
                border: none;
                padding: 0;
                padding: 0;
                margin: 0;
                margin-bottom: 20px;

                .dln-icn {
                  line-height: 1.6;
                }

                &__title {
                  @include fontBold;
                  display: block;
                  margin-bottom: 8px;
                  font-size: 15px;
                  line-height: 1;
                  color: #000;
                }

                &:hover {
                  border: none;

                  .dln-icn {
                    line-height: 1.8;
                  }
                }

                &:last-of-type {
                  margin: 0;
                }

                &__picture {
                  width: 80px;
                  height: 63px;
                  left: 0;

                  .dln-addItem__img {
                    width: 98px;
                    height: 80px;
                  }
                }

                &__content {
                  height: 63px;
                  width: calc(100% - 80px);
                  position: relative;
                  left: 80px;
                  padding-left: 4px;
                  transform: translateY(-3px);

                  & + .dln-btn {
                    width: 100%;
                    height: 40px;
                    margin-top: 13px;
                    line-height: normal;
                  }
                }

                &__price {
                  margin-bottom: 30px;
                  position: absolute;
                  top: 49px;
                }

                & + .dln-btn {
                  margin-top: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}

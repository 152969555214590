.dln-recipe-by-products {
    margin-top: 5px;
    width: 100%;

    &__selects-wrap {
        margin-top: 0;
        margin-bottom: 10px;
        display: flex;

        .dln-select {
            width: 300px;

            + .dln-select {
                margin-left: 20px;
            }
        }
    }

    &__title {
        @include fontBold;
        font-size: 36px;
        line-height: 1.22;
        margin-bottom: 20px;
    }

    &__browse-product {
        font-size: 15px;
        line-height: 1.4;
        margin-bottom: 20px;
    }

    .dln-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 1300px;
        width: 100%;
        padding: 30px 30px 0;
        margin: 0 auto;
    }

    .dln-filters-bar {
        margin-top: 50px;
        margin-bottom: 10px;
    }

    &__load-more {
        margin: 60px auto 20px;

        .dln-btn {
            margin-top: 20px;
        }
    }
}


.dln-page--overflow {
    overflow:  initial!important;

    .dln-recipe-by-products{
        .dln-select__body {
            z-index: 2!important;
        }

        .dln-select__items-list{
            max-height: 220px !important;
        }
    }

}

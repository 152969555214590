@media only screen and (max-width:599px) {
    .dln-thankYou {
        &__title {
            //@include mainMenu;
            margin: 0 0 36px;
            font-size: 18px;
            line-height: 22px;
        }
        &__successMess {
            margin: 40px 0 16px;

            .dln-icn{
                font-size: 32px;
                color: var(--support-02);
                margin: 0 0 28px;
                border: 2px solid var(--support-02);
                border-radius: 50%;
                width: 62px;
                height: 37px;
                padding-top: 25px;
            }
        }
        .dln-thankYou__items{
            p{
                color: var(--brand-02);
            }
        }
        .dln-wrapper {
            >.dln-thankYou__content {
                margin: 3px 0 0;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
            }
            &--fullWidth {
                margin-top: 0px;
            }
        }
        /*  &>.dln-wrapper {
            margin-right: 0;
            margin-left: 0;
        } */
        &__orderNr {
            order: 4;
            margin: 40px 0 0;
            text-align: left;
        }
        .dln-registrationForm {
            width: 100%;
            .dln-registrationForm__formFoot {
                margin: 24px 0 0;
            }
            &+.dln-btn {
                margin: 24px 0 0;
            }
        }
        .dln-thankYou__regForm {
            border: none;
            .dln-thankYou__subTitle {
                margin: 0 0 8px;
            }

        }
        .dln-registrationForm {
            &__formContainer {
                .dln-select {
                    width: calc(100% - 10px);
                    margin: 0 0 32px 0;
                }
                .dln-inputs {
                    margin: 0 0 16px 0;
                    &--halfCol {
                        width: calc(100% - 10px);
                        margin: 0 0 32px 0;
                        &:nth-child(even) {
                            margin: 0 0 40px 0;
                        }
                    }

                    .dln-inputs__extraLbl {
                        width: calc(100% - 12px);
                        max-width: 300px;
                        white-space: normal;
                    }
                }
                .dln-checkbox {
                    margin: 0 0 32px;
                }
            }
            &__formFoot {
                .dln-btn {
                    width: 100%;
                }
            }
        }
        &__innerWrap {
            .dln-thankYou__content{
                margin-bottom: 16px;
            }

            .dln-thankYou__subTitle {
                font-size: 15px;
                line-height: 19px;
                margin-bottom: 6px;

                a,i{
                    color: var(--support-02);
                }
            }

            .dln-thankYou__content {

                &--delivery,
                &--payment,
                &--aside {
                    width: 100%;
                    margin: 0;
                    &:nth-child(4n+4) {
                        margin: 0 0;
                    }
                }
                &--payment {
                    .dln-addressBox {
                        margin: 0;
                    }
                }
                &--aside {
                    .dln-thankYou__subTitle {
                        margin: 24px 0 12px;
                    }
                }
            }
            >.dln-btn {
                max-width: 100%;
                margin: 24px 20px 0;
            }
        }

        .dln-orderRev{
            margin: 20px 0 0;
            padding-bottom: 20px !important;
            border-bottom: 1px solid #e5e5e5 !important;

            &__detailTitle{
                font-size: 15px;
                line-height: 19px;
            }

            .dln-price{
                font-size: 13px;
                line-height: 19px;
            }

            .dln-cartItem__quantity{
                margin: 10px 0 0;
                font-size: 13px;
                line-height: 19px;
            }
        }
        &__orderDetails {
            width: 100%;
            margin: 0 0 0 0;
            padding: 0;
            background-color: $ui-03;
        }
        &__aside {
            .dln-thankYou__subTitle,
            .dln-thankYou__socialBtn,
            .dln-thankYou__paragraph {
                padding: 0 20px;
            }
        }
        &__orderInfo {
            font-size: 15px;
            text-align: center;
            margin-bottom: 27px;
            width: 100%;
            &--user {
                font-size: 13px;
                width: 100%;
                margin-top: 5px;

                a{
                    color: var(--support-02);
                }
            }
        }
        &__content {
            border: none;

            &--orderSummary {
                & .dln-thankYou__subTitle {
                    //margin: 24px 0;
                }
                & .dln-thankYou__subTitleInnerWrap {
                    //display: none;
                }
                & .dln-orderRev {
                    border-top: none !important;
                }
            }
        }
        .dln-addressBox {
            background-color: $ui-03;
            padding: 0 0 24px 0;
            margin: 0 0 24px;
            border: none;
            border-bottom: 1px solid #e5e5e5;
        }
        .dln-orderRev {
            border-top: none;
            border-bottom: 1px solid $brand-01;
            padding: 0 0 14px;
            &__detailsTitle {
                height: auto;
                white-space: normal;

            }
            &__itemPic {
                width: 100px;
                height: auto;
                margin: 0 20px 0 0;
            }
            &__content {
                padding: 0 0 !important;
                width: calc(100% - 120px);
                text-align: left;
            }
            .dln-price--oldPrice {
                margin: 0 4px 0 0;
            }
        }
        .dln-orderSumm {
            border: none;
            &__content {
                padding: 0 0;
            }
            .dln-orderSumm__contentRow:first-of-type {
                border-top: none;
            }
        }
        &__shareContent {
            .dln-thankYou__paragraph,
            .dln-thankYou__subTitle,
            .dln-thankYou__socialBtn {
                text-align: left;
                margin: 0 auto 24px;
            }
            .dln-thankYou__subTitle {
                margin-top: 0;
            }
        }
        &--user {
            .dln-wrapper {
                >.dln-thankYou__content {
                    .dln-thankYou__orderNr {}
                }
            }
            .dln-thankYou__successMess {
                margin: 20px 0 24px;
                border: none;
                padding: 20px 26px;

                & .dln-thankYou__paragraph{
                    margin: 0 0 28px;
                }
            }
            .dln-thankYou__orderNr {
                display: flex;
                flex-wrap: wrap;
                margin: 0 0;
                .dln-btn {
                    order: -1;
                    margin: 0 0 32px;
                }
            }
            .dln-thankYou__orderInfo {
                &--user {
                    padding: 0;
                    margin: 0;
                    border: none;
                }
            }
        }
        .dln-carousel {
            margin: 32px 0 40px;
            .swiper-pagination {
                margin: 32px 0 0;
            }
        }
    }
}

.dln-errorPage {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: auto;

    & .dln-wrapper {
        &--fullWidth {
            position: relative;
            /* background-image: url('#{$pathAssets}/images/bg/bg-error-page.jpg'); */
            background-color: $brand-04;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            padding-top: 85px;
            padding-bottom: 70px;
            margin: 0 0 48px 0;
            max-width: none;
        }
        &--main {
            width: 100%;
            position: relative;
            z-index: 9;
        }
        &--content {
            width: 100%;
            margin: 0 0 104px 0;
        }
    }
    &__picture {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;

        & > img {
            position: absolute;
            // object-fit: cover;
            // height: auto;
            // width: auto;
            // min-width: 100%;
            object-position: right bottom;
            // min-height: 100%;
            bottom: 0;
            right: 0;
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        width: 60%;
        height: auto;
    }
    &__mainTitle {
        display: block;
        width: 100%;
        line-height: 56px;
        font-size: 44px;
        @include fontBold;
        color: $brand-03;
        margin: 0 0 32px 0;

        & span {
            display: block;
            width: 100%;
            line-height: 32px;
            font-size: 26px;
            @include fontBold;
        }
    }
    &__mainText {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        color: $brand-03;
        margin: 0 0 24px 0;
    }
    & .dln-inputs--searchBar {
        width: 50%;
        max-width: 488px;
    }
    &__title {
        display: block;
        width: 100%;
        line-height: 24px;
        font-size: 22px;
        @include fontBold;
        margin: 0 0 28px 0;
    }
    & .dln-wrapper--content {
        & .dln-btn {
            width: 184px;
            margin: 0 16px 0 0;

            &:last-of-type {
                margin: 0;
            }
        }
    }
}

.dln-kenwood {
    & .dln-category-list {
        &__wrapper {
            padding: 20px calc(96px + 24px) 20px 20px;
            position: relative;
            z-index: 1;
        }
        &__label {
            line-height: 20px;
            font-size: 12px;
            color: $brand-02;
            text-transform: uppercase;
            padding-bottom: 2px;
        }
        &__title {
            @include fontExtra;
            line-height: 40px;
            font-size: 32px;
        }
        &__text {
            line-height: 20px;
            padding: 12px 0 0 0;
        }
        &__icon {
            width: 24px;
            height: 24px;
            position: absolute;
            top: calc((50% + 20px) - 12px);
            right: 48px;

            & .dln-icn {
                width: 24px;
                height: 24px;
                font-size: 18px;
                color: $brand-01;
                background-color: transparent;
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-category-list {
            &__wrapper {
                padding: 20px calc(24px + 16px) 20px 0;
                margin: 0;
            }
            &__label {
                padding-bottom: 6px;
            }
            &__title {
                line-height: 34px;
                font-size: 28px;
            }
            &__text {
                padding-top: 15px;
            }
            &__icon {
                top: 52px;
                right: 0;
            }
        }
    }
}

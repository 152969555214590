.dln-kenwood {
  .dln-addItem {
    .prices {
      display: flex;
    }

    &__picture {
      & .dln-image__image {
        height: 100%;
        object-fit: contain;
      }
    }

    &__price {
      & .dln-price {
        font-size: 13px;

        &--oldPrice {
          margin: 0 12px 0 0;
          font-size: 13px;
          color: $brand-02;

          &::after {
            background-color: $ui-02;
          }
        }
      }
    }
  }
}

.dln-registration--login {
    .dln-registration__section {
        width: 50%;
        margin: 0 0 0 0;
        padding: 0 100px;
        &--main {
            width: 50%;
            margin: 0 0 0 0;
            padding: 0 100px;
            border-right: 1px solid $ui-01;
        }
        .dln-regAdv {
            margin: 0 0 16px;
        }
    }
    .dln-registration__formFoot {
        margin: 48px 0 0;
        .dln-btn {
            width: 100%;
        }
    }
    .dln-loginForm__section--registration {
        display: none;
    }
}
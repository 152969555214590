.dln-price {
    @include extraSmall;
    color: $brand-04;
    position: relative;
    max-height: 16px;
    &--discounted {
        color: $support-01;
    }
    &--oldPrice {
        /*  text-decoration: line-through; */
        // doesn't looks fine
        &::after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: 50%;
            background: $brand-01;
        }
    }
    &--secondary {
        color: $ui-02;
    }
    &--total {
        @include mainMenu
    }
    &--free {
        text-transform: uppercase;
        @include fontBold;
        color: $support-02;
    }
    a {
        color: inherit;
    }
}

@import '_price-label-kenwood.scss';
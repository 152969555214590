.dln-kenwood {
    .dln-titleIcon {
        &__iconWrap {
            border: none;
            .dln-icn {
                color: $brand-04;
                font-size: 37px;
            }
        }
        &__title {
            @include fontExtra;
        }

    }
}
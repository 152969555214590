.dln-delonghi {
  .dln-prodManualsCard {
    &__prodName {
      @include fontMedium;
      font-size: REM(37px);
      line-height: 1;
      margin-bottom: 15px;
    }
    &__sku {
      @include fontRoman;
      font-size: REM(18px);
      line-height: 1.33;
      margin-bottom: 40px;

      & span {
        @include fontRoman;
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    .dln-prodManualsCard {
      &__imageWrap {
        margin: 0;
      }
      &__prodName {
        font-size: REM(28px);
        line-height: 1.07;
      }
      &__sku {
        font-size: REM(16px);
        line-height: 1.5;
        color: $ui-02;
      }
    }
  }
}

.dln-kenwood {
    .dln-checkbox {
        min-height: 18px;
        &__wrap {
            min-height: 18px;
        }
        &__label {
            color: $brand-04;
        }
        &__inputPlaceholder {
            width: 16px;
            height: 16px;
            border: 1px solid $brand-04;
            border-radius: 0;
            background-color: $brand-03;
            &::after {
                @include fontIcon;
                background-color: transparent;
                content: 'à';
                color: $brand-01;
                font-size: 8px;
                line-height: normal;
            }
        }
        .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder {
            border-color: $brand-01;
        }
        .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
            @include fontRoman;
            color: $brand-04;
        }
    }
}
.dln-recipe-by-products {
    margin-top: 10px;

    .dln-select--open,
    .dln-select--has-value {
        + .dln-select__lbl.dln-select__lbl--active {
            display: none;
        }
    }

    &__search-section {
        margin-top: 50px;
        margin-bottom: 10px;
    }

    &__title {
        @include fontBold;
        font-size: 36px;
        line-height: 1.22;
        margin-bottom: 20px;
    }

    &__browse-product {
        font-size: 15px;
        line-height: 1.4;
        margin-bottom: 20px;
    }
}


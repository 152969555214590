.dln-kenwood {
    .dln-vertMenu {
        &__list {
            margin: 0 0 40px;
        }
        &__li {
            &Heading {
                @include fontRoman;
                color: $ui-07;
            }
            &--heading {
                padding: 0 16px 0 0;
            }
            &:not(.dln-vertMenu__li--heading) {
                border-left: none;
                padding-left: 0;
            }
            &::before {
                display: none;
            }
            a {
                position: relative;
                display: inline-block;
                padding: 16px 0 4px;
                letter-spacing: 0.2px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: auto;
                    bottom: 0;
                    background-color: $brand-04;
                    width: 100%;
                    height: 0;
                    transition: 0.3s;
                }
            }
            &.dln-vertMenu__li--active {
                border-left: none !important;

                & > a {
                    font-weight: bold !important;

                    &::before {
                        height: 2px;
                        bottom: 0 !important;
                    }
                }
                // a::before {
                //     height: 2px;
                // }
            }
        }
        @media only screen and (max-width: map-get($grid-breakpoints, md)) {
            &:not(.dln-vertMenu--open) {
                .dln-vertMenu__li a {
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 599px) {
        .dln-vertMenu {
            &__li {
                a {
                    font-size: 14px;
                }
            }
            &--open {
                .dln-icn {
                    width: auto;
                    font-size: 22px;
                    color: $brand-01;
                }
                .dln-vertMenu__container {
                    padding: 60px 20px 40px;
                }
                .dln-vertMenu__li {
                    a {
                        padding: 15px 0 14px 16px;
                    }
                    &--heading {
                        margin-bottom: 5px;
                    }
                    &--active {
                        a {
                            padding: 15px 0 15px 16px;
                            &::before {
                                left: 16px;
                                bottom: 12px;
                                width: calc(100% - 16px);
                            }
                        }
                    }
                }
            }
        }

        .dln-vertMenu__li:not(.dln-vertMenu__li--heading) {
            padding: 0 16px;

            a {
                font-size: 13px;
                @include fontRoman;
                padding-bottom: 0;
                margin-top: 3px;
            }
        }
    }
    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
        .dln-vertMenu {
            &--open {
                .dln-vertMenu__li {
                    a {
                        padding: 16px 0 16px 16px;
                    }
                    &--active {
                        a {
                            padding: 16px 0 16px 16px;
                            &::before {
                                left: 16px;
                                bottom: 12px;
                                width: calc(100% - 16px);
                            }
                        }
                    }
                }
            }
        }

        .dln-vertMenu__li:not(.dln-vertMenu__li--heading) {
            padding: 0 16px;

            a {
                font-size: 13px;
                @include fontRoman;
                padding-bottom: 0;
                margin-top: 3px;
            }
        }
    }
    @media only screen and (max-width: map-get($grid-breakpoints, md)) {
        .dln-vertMenu {
            border: 1px solid $brand-04;
        }
    }
    @media only screen and (min-width: 599px) {
        .dln-vertMenu {
            &__li {
                &:not(.dln-vertMenu__li--heading) {
                    padding-left: 10px;
                }
            }
        }
    }
}

.dln-kenwood {
    .dln-filtersPopup {
    }
}

@media screen and (max-width: 599px) {
    .dln-kenwood {
        .dln-filtersPopup {
            padding: 0 !important;

            &__head {
                background: $ui-03;
                border-bottom: 2px solid $ui-01;

                .dln-btn.dln-btn--brand-03 {
                    background-color: transparent ;
                    text-decoration: underline;
                    text-transform: capitalize;

                    &:active, &:hover {
                        color: $brand-04 !important;
                        background-color: transparent !important;
                    }
                }
            }

            &__title{
                text-transform: capitalize;
            }

            &__actions {
                display: flex;
                justify-content: center;
                border: none;

                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
            }

            h2.dln-accordion__title {
                color: $brand-04 !important;
                font-size: 14px;
                text-transform: uppercase;

            }

            .dln-accordion.dln-accordion--arrow.dln-accordion--activated {
                background: $brand-03;
                position: relative;

                &::before {
                    content: "";
                    width: 4px;
                    height: 100%;
                    background: $brand-04;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .dln-multiSelectList__input:checked + .dln-multiSelectList__wrap {
                background: $brand-03 !important;
            }

            .dln-multiSelectList__wrap,
            .dln-sortList__radio,
            .dln-sortList__placeholder {
                i {
                    color: $support-02;
                }
            }

            .dln-inputs__input {
                padding-right: 30px;
            }

            label{
                font-size: 14px !important;
            }

            .dln-colorRadioBtn__input:checked + .dln-colorRadioBtn__placeholder {
                border: 2px solid $brand-04;
            }

            .dln-btnIcon.dln-btnIcon--close {
                top: 23px !important;
                right: 10px !important;


                i:before {
                    color: $brand-04 !important;
                }
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__content {
                & .dln-popup__wrapper {
                    & .dln-filtersPopup {
                        padding: 0;
                    }
                }
            }
        }
    }
}

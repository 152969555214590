.dln-delonghi {
  .dln-totalSect {
    padding: 22px 0;

    &__title {
      font-size: REM(21px);
      @include fontMedium;
      margin-bottom: 16px;
    }

    &__data {
      font-size: REM(16px);
      @include fontRoman;
      color: $brand-04;
    }

    &__label {
      font-size: REM(14px);
      color: $ui-02;
    }

    &__row--total {
      margin-top: 8px;

      .dln-totalSect__label {
        color: $brand-04;
        @include fontMedium;
        font-size: REM(18px);
      }

      .dln-totalSect__data {
        font-size: REM(18px);
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-totalSect {
      &__title {
        font-size: REM(18px);
      }

      &__row--total {
        .dln-totalSect__label {
          @include fontRoman;
          font-size: REM(16px);
        }
      }
    }
  }
}

.dln-delonghi {
    .dln-connectAcc {
        padding: 0 40px 70px 40px;

        .dln-connectAcc__form,
        .dln-connectAcc__wrap {
            padding: 0 30px;

            .dln-inputs,
            .dln-inputs + .dln-btn {
                max-width: 100%;
                width: 100%;
            }
        }

        @media only screen and (max-width:599px) {
            .dln-connectAcc__form,
            .dln-connectAcc__wrap {
                padding: 0;
            }
            
        }
    }
}
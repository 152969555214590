.dln-kenwood {
    .dln-popup__head {
        position: inherit;
        .dln-btnIcon:not(.dln-btnIcon--circleClose) {
            /* position: absolute;
            top: 20px;
            right: 20px;
            transform: none; */
            margin: 0 0 0 auto;
        }
        &Title {
            @include fontExtra;
        }
        .dln-btnIcon {
            margin: 0;
            .dln-btnIcon__wrap {
                width: auto;
                height: auto;
            }
            .dln-icn {
                font-size: 20px;
                color: $brand-01;
            }
            &--circleClose {
                margin: 0 0 0 auto;
                .dln-icn {
                    color: $ui-01;
                }
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__wrapper > div:first-child {
                & .dln-popup__head {
                    width: 100%;
                    padding-right: 0;
                    padding-left: 0;
                    margin: 0;

                    &Title {
                        height: auto;
                    }
                }
            }
        }
    }
}
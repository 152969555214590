.dln-addressResult {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    text-decoration: none;
    // background: lightblue;
    padding: 0 0 8px 0;
    background: none;
    border: none;

    &:hover {
        background-color: $ui-03;
    }
    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        // background: rgba(0,0,0,.1);
        margin: 0 0 8px 0;
    }
    &__pinIndex {
        display: inline-block;
        width: 23px;
        height: 30px;
        // line-height: 30px;
        line-height: 2.1;
        font-size: 11px;
        @include fontBold;
        color: $brand-03;
        text-align: center;
        background-image: url('#{$pathAssets}/images/icons/icon-pin-brand-01.svg');
        background-size: 23px 30px;
        background-position: center;
        margin: 0 8px 0 0;
    }
    &__company {
        display: inline-block;
        width: auto;
        max-width: calc(100% - 10px);
        line-height: 17px;
        font-size: 14px;
        @include fontBold;
        color: $brand-04;
        padding: 0 0 0 0;
    }
    &__distance {
        display: inline-block;
        width: auto;
        line-height: 17px;
        font-size: 14px;
        color: $brand-02;
        border-left: 1px solid $brand-02;
        padding: 0 0 0 12px;
        margin: 0 16px 0 24px;
    }
    &__head .dln-btnIcon {
        width: 32px;
        height: 32px;
        margin: 0 0 0 auto;

        & .dln-btnIcon__wrap {
            width: 32px;
            height: 32px;
        }
    }
    &__head {
        .dln-icn {
            margin: 0 0 0 auto;
            color: $brand-04;
        }
    }
    &__info {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        // background: rgba(255,255,255,0.2);
        padding: 0 0 0 30px;
        box-sizing: border-box;
    }
    &__data {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: $brand-04;
    }
    &__productCategory {
        display: block;
        width: 100%;
        line-height: 20px;
        color: $brand-02;
        padding: 8px 0 0 0;
    }
}
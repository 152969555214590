.dln-delonghi {
  & .dln-popup {
    & .dln-popup__content {
      & .dln-popup__wrapper {
        & .dln-faqShare {
          padding-bottom: 63px;

          & .dln-popup__head {
            padding: 20px 20px 20px 20px;

            & .dln-btnIcon--close {
              margin-bottom: 16px;
            }
            & .dln-popup__headTitle {
              line-height: 37px;
              font-size: REM(37px);
              text-align: center;
              padding: 0 62px;
            }
          }
          & .dln-faqShare__content {
            padding: 0 102px;

            & .dln-inputs {
              width: 100%;
              max-width: none;
            }
          }
          & .dln-faqShare__actions {
            padding: 0 102px;

            & .dln-btn {
              &--confirm {
                width: 50%;
                min-width: initial;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-faqShare {
            & .dln-popup__head {
              padding-bottom: 40px;
              // padding: 0 20px;
              margin: 0;

              & .dln-btnIcon--close {
                margin-bottom: 40px;
                transform: translate(3px, -3px) !important;
              }
              & .dln-popup__headTitle {
                max-width: none;
                line-height: 30px;
                font-size: REM(28px);
                text-align: left;
                padding: 0;
              }
            }
            & .dln-faqShare__content {
              padding: 0 20px;

              & .dln-inputs {
                margin-bottom: 32px;

                // &:not(.dln-textarea):last-child {
                //     margin-bottom: 23px;
                // }
                &:nth-last-of-type(2) {
                  margin-bottom: 23px;
                }
              }
            }
            & .dln-faqShare__actions {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
}

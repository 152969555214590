@media only screen and (max-width:599px) {
    .dln-order {
        &--return {
            & .dln-order__content {
                & .dln-editOrdHead {
                    margin-bottom: 20px;
                }
                & .dln-orderHistory {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                & .dln-orderRev-input-wrapper--notReturnable {

                    & .dln-orderRev--productDescription.dln-orderRev--notReturnable {
                        margin-bottom: 5px;
                    }
                    & .dln-orderRev__details {
                        height: auto;
                    }
                    & .dln-orderRev-input-wrapper__single-item-content.-notReturnable {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

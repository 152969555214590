.dln-delonghi {
  .dln-profile {
    &__registerProduct {
      border: 0;

      .dln-btn {
        min-width: 15em;
      }
    }

    &__banner {
      &Title {
        @include fontMedium;
        font-size: REM(37px);
        line-height: 37px;

        margin: 0;
        margin-bottom: 15px;

        @media only screen and (max-width: 599px) {
          font-size: REM(28px);
          line-height: 30px;
        }
      }

      &Copy {
        @include fontBook;
        font-size: REM(16px);
        line-height: 24px;

        margin: 0;
        margin-bottom: 17px;
      }

      &SubTitle {
        @include fontMedium;
        font-size: REM(21px);
        line-height: 23px;
        margin: 0;
        margin-top: 44px;
        margin-bottom: 16px;
      }
    }

    &__recipes {
      position: relative;

      @media only screen and (max-width: 599px) {
        padding: 20px;
        min-height: initial;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 0;

        opacity: 0.8;
        mix-blend-mode: multiply;
        background-image: linear-gradient(
          to right,
          #000000 0%,
          rgba(0, 0, 0, 0) 63%
        );
      }

      &Wrap {
        z-index: 1;
        color: white;
        .dln-profile__recipesSubtitle {
          @include fontMedium;
          font-size: REM(21px);
          line-height: 23px;
          color: white;
        }

        .dln-profile__recipesTitle {
          @include fontMedium;
          font-size: REM(28px);
          line-height: 34px;
          color: white;
        }

        .dln-profile__recipesLink {
          @include fontMedium;
          font-size: REM(21px);
          letter-spacing: -0.5px;
          color: white;

          text-decoration: none;

          &:after {
            @include iconspseudoelements;
            content: 'd';
          }
        }

        .dln-profile__recipesSubtitle,
        .dln-profile__recipesTitle,
        .dln-profile__recipesLink {
          @media only screen and (max-width: 599px) {
            margin-right: 0;
            width: 80%;
          }
        }
      }

      .dln-carousel {
        .dln-profile__banner {
          & > div {
            flex-wrap: nowrap;
            @media only screen and (max-width: 599px) {
              margin: 0;
            }
          }
          .dln-icn {
            color: $brand-04 !important;
            margin: 0 40px 0 20px;
          }

          [class$='Text'] {
            width: auto;
          }

          &SubTitle {
            color: $brand-04;
            @include fontMedium;
            font-size: REM(18px);
            line-height: 19px;
            margin: 0;
            margin-bottom: 8px;
          }

          &Copy {
            color: $brand-04;
            @include fontBook;
            font-size: REM(14px);
            line-height: 18px;
            margin: 0;
          }
        }
      }
    }

    .dln-wrapper--carousel {
      .dln-profile__itemsCarouselTitle {
        @include fontMedium;
        font-size: REM(28px);
        line-height: 34px;
        color: $brand-04;
        margin-bottom: 24px;

        @media only screen and (max-width: 599px) {
          font-size: REM(21px);
          line-height: 23px;
          margin-bottom: 17px;
        }
      }
      .dln-btnTextIcon {
        background: $brand-01;
        border-radius: 3px;
        width: auto;
        padding: 0 20px;

        .dln-btnTextIcon__iconWrap {
          display: none;
        }
        .dln-btnTextIcon__text {
          height: auto;
          margin: 0;
          max-width: initial;
          width: 100%;

          @include fontDemi;
          font-size: REM(18px);
          line-height: 24px;
          color: $brand-03;
        }
      }
    }

    .dln-persDataRecap,
    .dln-profile__section,
    .dln-profile__recipes {
      margin-bottom: 40px;
      border: 0;

      @media only screen and (max-width: 599px) {
        margin-bottom: 50px;
      }

      &--title {
        @include fontMedium;
        font-size: REM(37px);
        line-height: 37px;
        letter-spacing: -0.7px;

        @media only screen and (max-width: 599px) {
            font-size: REM(28px);
            line-height: 30px;
            letter-spacing: -0.53px;
        }
      }
    }

    .dln-profile__sectionHead {
      &Title {
        @include fontMedium;
        font-size: REM(37px);
        line-height: 37px;
        letter-spacing: -0.7px;

        &.dln-profile__sectionHeadTitle--smallMobile {
          @media only screen and (max-width: 599px) {
            max-width: initial;
            font-size: REM(28px);
            line-height: 30px;
            letter-spacing: -0.53px;
          }
        }
      }
      .dln-btn--link {
        @include fontMedium;
        font-size: REM(14px);
        line-height: 18px;

        @media only screen and (max-width: 599px) {
          font-size: REM(14px);
          line-height: 18px;
        }
      }
    }
  }
}

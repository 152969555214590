.dln-kenwood {
    .dln-recipe-single {
        padding: 0;

        .dln-wrapper,
        &__product-attachment,
        &__grid-info {
            max-width: 1240px;
            margin-right: auto;
            margin-left: auto;
        }

        &__products-links-description,
        &__products-links-description-mb {
            font-size: 12px;
            color: $brand-02;
        }

        &__product-is-prepared,
        &__product-title,
        &__col-3.padding a,
        &__servings-col span {
            color: $brand-04;
        }

        &__grid {
            .dln-recipe-single__col {
                &:first-of-type {
                    padding-left: 0;
                }
            }
        }

        &__product-is-prepared {
            font-size: 20px;
            padding-left: 25px;
        }

        &__title-content {
            font-size: 20px;
        }

        &__attachment {
            padding-right: 0;

            .swiper-footer {
                display: flex;
                flex-wrap: nowrap;
                width: 100%;

                .swiper-scrollbar {
                    background: $ui-03;
                }

                .swiper-button-prev {
                    order: 2;
                }
            }
        }

        &__col-9 {
            .dln-recipe-single__products-links-label {
                text-transform: uppercase;
            }
        }

        p {
            font-size: 14px;
        }

        .dln-wrapper {
            padding-right: 0;
            padding-left: 0;
        }

        picture {
            border-radius: 0;
        }
        & .dln-recipe-single__videoContainer {
            background-color: $brand-04;
        }
        & .dln-recipe-single__grid-info {
            & .dln-recipe-single__accordion-content {
                & .dln-recipe-single__servings-section {
                    flex-wrap: wrap;

                    & .dln-recipe-single__servings-col {
                        flex: initial;
                        align-items: center;
                        height: 24px;

                        &:first-child {
                            margin-right: 97px;
                        }
                        & .dln-select {
                            margin: 0;

                            & .dln-select__container {
                                & .dln-select__head {
                                    & .dln-select__value {
                                        font-size: 20px;
                                        color: $brand-04;
                                        margin: 0 7px 0 2px;
                                    }
                                    & .dln-select__arrow {
                                        width: 11px;
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                        & > span {
                            margin-left: 3px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 650px) {
}

@media only screen and (min-width: 601px) and (max-width: 1115px) {
    .dln-kenwood {
        .dln-recipe-single {
            &__product-attachment.dln-recipe-single__grid {
                flex-direction: column;

                .dln-recipe-single__col {
                    width: 100%;
                    max-width: none;
                }
            }

            &__grid.dln-recipe-single__products-links {
                flex-direction: column;

                .dln-recipe-single__col-3 {
                    border-right: none;
                }

                > div {
                    width: 100%;
                    max-width: none;
                }
            }

            &__grid-info {
                flex-direction: column;

                .dln-recipe-single__col {
                    max-width: none;
                }

                .dln-recipe-single__col-content {
                    max-width: none;
                    padding-bottom: 20px;
                }
            }

            &__col-gray {
                padding-right: 0;
            }

            &__col-white {
                .dln-recipe-single__col-content {
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .dln-kenwood {
        .dln-recipe-single {
            .dln-wrapper,
            .dln-recipe-single__product-attachment,
            .dln-recipe-single__grid-info {
                width: 100%;
                max-width: none;
                padding-right: 30px;
                padding-left: 30px;
                margin-right: 0;
                margin-left: 0;
            }

            &__product-content,
            &__product-is-prepared {
                padding-left: 0;
            }

            .dln-recipe-details__col:first-of-type {
                padding-left: 30px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .dln-kenwood {
        .dln-recipe-single {
            .dln-wrapper,
            .dln-recipe-single__product-attachment,
            .dln-recipe-single__grid-info {
                width: 100%;
                max-width: none;
                padding-right: 20px;
                padding-left: 20px;
                margin-right: 0;
                margin-left: 0;
            }

            .dln-recipe-details__col:first-of-type {
                padding-left: 20px;
            }

            &__col {
                padding-right: 0;
                padding-left: 0;
            }

            .dln-recipe-single__col.dln-recipe-single__col-gray,
            .dln-recipe-single__col.dln-recipe-single__col-white {
                padding-left: 0;
            }

            .dln-recipe-single__col.dln-recipe-single__col-white {
                .dln-recipe-single__col-content {
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            .swiper-slide.dln-recipe-single__product-content.swiper-slide-active,
            .swiper-slide.dln-recipe-single__product-content.swiper-slide-next {
                padding-left: 0;
            }

            &__product-attachment.dln-recipe-single__grid {
                padding-right: 0;
                padding-left: 0;
            }

            &__col:first-of-type,
            &__product-content,
            &__product-is-prepared {
                padding-left: 20px;
            }
        }
    }
}

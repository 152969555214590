.dln-cart {
    &--empty {        
        .dln-cart__content {
            .dln-chips {
                &__wrapper {                    
                    .dln-chips__content {
                        span {
                            font-size: 13px;
                            color: $ui-06;
                        }
                    }
                }
            }
        }

        .dln-cart__btnGroup {
            gap: 20px;
        }

        .dln-cart_paymentBox {
            margin-top: 20px;
        }
    }
}
.dln-delonghi {
  & .dln-title {
    @include fontMedium;
    font-size: REM(50px) !important;
    line-height: 1.04 !important;
    color: $brand-04;
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-title {
      font-size: REM(37px) !important;
      line-height: 1.08 !important;
    }
  }
}

.dln-delonghi {
  & .dln-cart {
    &--empty {
      .dln-cart {
        &__btnGroup {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;

          .dln-btn {
            box-sizing: border-box;
            min-width: initial;
            padding: 0;
            margin: 0;
          }
        }

        &__emptyMessage {
          max-width: 70%;
          margin: 70px auto 70px;

          .dln-icn.dln-icn--iconfont {
            color: $brand-04;
          }

          p {
            color: $ui-02;
          }
        }
      }

      .dln-cart__content .dln-chips__wrapper .dln-chips__content span {
        color: $ui-02;
        font-size: REM(14px);
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, xs)) {
  .dln-delonghi {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          .dln-cart {
            &__head {
              margin: 0 auto;
              padding: 25px 0 18px;
              width: 475px;

              .dln-cart__title {
                padding-left: 20px;
                padding-right: 20px;

                .dln-cart__titleLbl {
                  font-size: REM(16px);
                  margin: 0 0 0 8px;
                }
              }

              .dln-icn--iconfont {
                display: none;
              }
            }

            &__content {
              .dln-cart__info {
                color: var(--ui-02);
                border: 1px solid var(--ui-01);
                background-color: var(--ui-03);
                @include fontBook;
                font-size: REM(14px);
                line-height: 1.3;
              }
            }

            &__emptyMessage {
              margin: 45px auto;
              padding-top: 0;

              .dln-icn {
                color: var(--brand-04);
                font-size: 48px;
              }

              p {
                color: var(--ui-02);
                font-size: REM(18px);
                margin: 0;
              }
            }

            &__btnGroup {
              flex-direction: column;
              margin-top: 25px;

              .dln-btn {
                width: 100%;
                margin-bottom: 16px;

                &--stroked {
                  margin-bottom: 0;
                }
              }
            }

            &__aside--secondary {
              padding-left: 0;
              padding-right: 0;
            }

            &__aside {
              .dln-cart_paymentBox {
                background-color: var(--ui-03);
                margin-top: 15px;
                margin-bottom: 20px;

                span {
                  font-size: REM(14px);
                  margin-bottom: 10px;
                }
              }
            }

            &__aside,
            &__aside--secondary {
              .dln-cart__noticeBox {
                gap: 0;
                margin-left: 0;
                margin-right: 0;

                p {
                  display: none;
                }

                .dln-noticeBox {
                  align-items: center;

                  &__content {
                    .dln-noticeBox__icon {
                      .dln-icn {
                        font-size: 36px;
                        margin-top: -5px;
                      }
                    }

                    .dln-noticeBox__description {
                      .dln-noticeBox__title {
                        font-size: REM(15px);
                        color: var(--brand-06);
                        margin-bottom: 3px;
                      }

                      .dln-noticeBox__copy {
                        font-size: REM(14px);
                        color: var(--brand-04);
                      }
                    }
                  }

                  &__link {
                    @include fontBook;
                    font-size: REM(14px);
                    text-decoration: none;
                    padding-bottom: 2px;
                    border-bottom: 2px solid var(--brand-01);
                  }

                  &:nth-child(even) {
                    &::after {
                      height: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    .dln-cart {
      &--empty {
        .dln-cart__aside {
          margin: auto 20px;
        }

        > .dln-wrapper {
          .dln-cart {
            &__head {
              padding-bottom: 15px;

              .dln-cart__title {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 901px) {
  .dln-delonghi {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          .dln-cart {
            &__head {
              margin-left: 0;
              padding-left: 20px;

              .dln-cart__title {
                font-size: REM(50px);

                .dln-icn {
                  display: block;
                }

                .dln-cart__titleLbl {
                  margin-bottom: 6px;
                }
              }
            }

            &__content {
              width: calc(100% - 407px);
              padding-right: 0;
              margin-right: 0;
            }

            &__emptyMessage {
              .dln-cart__btnGroup {
                flex-direction: row;
                margin-top: 65px;
              }
            }

            &__aside {
              padding-left: 20px;
              padding-right: 0;
              width: 387px;
              margin: 0;

              .dln-cart__noticeBox {
                display: block;
              }

              .dln-cart_paymentBox {
                background-color: var(--ui-03);
                margin: 20px 0 20px 0;
              }
            }

            &__aside--secondary {
              .dln-cart__noticeBox {
                display: none;
              }
            }

            &__aside,
            &__aside--secondary {
              .dln-cart__noticeBox {
                .dln-noticeBox {
                  &__content {
                    .dln-noticeBox__description {
                      .dln-noticeBox__copy {
                        font-size: REM(14px);
                        color: var(--brand-04);
                      }
                    }
                  }

                  &:nth-child(even) {
                    &::after {
                      height: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1101px) {
  .dln-delonghi {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          padding-top: 0;

          .dln-cart {
            &__head {
              padding-left: 20px;
            }

            &__content {
              margin-right: 20px;
              padding-right: 0;
            }

            &__aside {
              margin: 15px 20px 10px 0;
              width: 367px;
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) and (min-width: 1480px) {
  .dln-delonghi {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          max-width: 1300px;
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-popup {
    &__content {
      & .dln-popup__wrapper {
        & .dln-faqAnnouncement {
          & .dln-popup__head {
            padding: 52px 0 28px 0;
            position: relative;

            & .dln-btnIcon {
              position: absolute;
              top: 20px;
              right: -12px;
            }
            & .dln-popup__headTitle {
              @include fontMedium;
              line-height: 37px;
              font-size: REM(37px);

              & > span {
                @include fontMedium;
              }
            }
          }
          &__content {
            & .dln-faqAnnouncement__text {
              line-height: 24px;
              font-size: REM(16px);
              color: $brand-04;
              padding-bottom: 12px;
              margin-bottom: 0;

              &:last-child {
                padding-bottom: 24px;
              }
              & strong {
                @include fontBook;
              }
              &Link {
                @include fontBook;
                text-decoration: none;
                cursor: default;
                pointer-events: none;
              }
            }
            & .dln-faqAnnouncement__imgWrap {
              margin-top: 39px;
            }
          }
          &__actions {
            padding-top: 24px;
            margin-bottom: 60px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      &__content {
        & .dln-popup__wrapper {
          & .dln-faqAnnouncement {
            & .dln-popup__head {
              padding: 76px 0 24px 0;

              & .dln-btnIcon {
                // position: absolute;
                // top: 20px;
                right: 0;
              }
              & .dln-popup__headTitle {
                width: 100%;
                max-width: none;
                line-height: 30px;
                font-size: REM(28px);
              }
            }
            &__content {
              & .dln-faqAnnouncement__text {
                font-size: REM(15px);
                padding-bottom: 24px;

                &:last-child {
                  padding-bottom: 30px;
                }
              }
              & .dln-faqAnnouncement__imgWrap {
                margin-top: 16px;
              }
            }
            &__actions {
              margin-bottom: 40px;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-category-list {
    .dln-category-list__wrapper {
      position: relative;
    }

    &__label {
      font-size: REM(16px);
      line-height: 1.5;
      color: $ui-02;
    }

    &__title {
      @include fontMedium;
      font-size: REM(37px);
      line-height: 1;
      color: $brand-04;
    }

    &__text {
      font-size: REM(16px);
      line-height: 1.5;
      color: $brand-04;
      padding-bottom: 0;
    }

    &__icon {
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.dln-recipe-single {
    &__buy-now-btn,
    &__plusIcon {
        display: none;
    }

    .dln-recipe-single__print-page{
        display: none;
    }


    .dln-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 1300px;
        width: 100%;
        padding: 30px 30px 0;
        margin: 0 auto;
    }

    &__product-attachment {
        align-items: center;
        align-items: flex-start;
        margin-bottom: 35px;
    }

    &__product,
    &__attachment {
        color: $brand-02;
        span {
            @include fontMedium;
            margin-bottom: 5px;
        }

        .dln-image {
            width: 90px;
            height: 90px;
            background: none;
            align-self: center;
            &__image {
                border-radius: 5px;
                object-fit: contain;
            }
        }
        .dln-recipe-single__product-title {
            @include fontBold;
            color: $brand-01;
            font-size: 15px;
            line-height: 1.4;
            text-align: center;
            margin-top: 5px;
        }
    }
    &__product {
        &-content {
            border-right: 1px solid #e6e6e6;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-right: 50px;
        }
        &-is-prepared {
            @include fontBold;
            color: $brand-01;
            font-size: 18px;
            line-height: 1.22;
            align-items: center;
            flex-direction: row;
        }
    }
    &__attachment {
        // SLIDES
        position: relative;
        .swiper-container {
            position: relative;
            width: 100%;

            .swiper-footer {
                margin-top: 10px;
            }
        }


        .dln-recipe-single__product {
            &-content {
                border-right: none;
            }
        }
    }

    &__grid {
        padding-top: 0;
        margin-left: -25px;
        margin-right: -25px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__col {
        flex: 1 1 50%;
        max-width: 50%;
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        position: relative;

        &-gray,
        &-white {
            flex-direction: row;
            display: flex;
            padding: 0;
        }

        &-gray {
            justify-content: flex-end;
            padding-right: 25px;

            .dln-recipe-single__col-content {
                background: $ui-03;
                padding-right: 60px;
            }
        }

        &-3 {
            flex: 1 1 25%;
            max-width: 25%;

            &.padding {
                padding: 0 25px;
            }
        }
        &-9 {
            flex: 1 1 75%;
            max-width: 75%;
        }
    }

    &__col-content {
        max-width: 645px;
        width: 100%;
        padding: 50px 30px 0;
        p {
            font-size: 15px;
            line-height: 1.4;
        }
    }

    &__title-content {
        @include fontBold;
        font-size: 18px;
        line-height: 1.22;
        padding-bottom: 20px;
    }

    &__grid-info {
        padding-top: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__title-filters {
        @include fontBold;
        font-size: 16px;
        line-height: 1.38;
        margin-top: 10px;
        margin-bottom: 18px;
    }

    &__servings-section {
        display: flex;
        padding-bottom: 20px;
    }

    &__servings-col {
        flex: 0 0 50%;
        font-size: 15px;
        line-height: 1.4;
        color: $brand-02;

        span,
        .dln-select__value {
            @include fontBold;
            font-size: 20px;
            line-height: 1.1;
            color: $brand-01;
            margin-left: 10px;
        }
        .dln-select__arrow {
            font-size: 13px;
            &:before {
                line-height: 24px;
            }
        }
    }

    &__grid-how-to {
        margin-left: -20px;
        margin-right: -20px;


        &.swiper-container {
            margin-left: -20px;
            margin-right: -20px;
        }

        .dln-recipe-single__col {
            padding: 0 20px;
        }

        .dln-image {
            margin-bottom: 10px;
            border-radius: 5px;
            max-height: 185px;
        }

        .swiper-wrapper {
            flex-wrap: wrap;
        }
        .swiper-slide {
            width: 50%;
            padding: 0 20px;
        }
    }

    & .dln-recipe-single__videoContainer {
        width: 100%;
        // height: 100%;
        height: 185px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: $brand-01;
        border-radius: 5px;
        position: relative;
        z-index: 1;
        margin-bottom: 10px;
        overflow: hidden;

        &.-hideVideoPoster {
            & .dln-recipe-single__videoPoster {
                pointer-events: none;
                opacity: 0;
            }
        }
    }
    & .dln-recipe-single__videoPoster {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        transition: opacity 0.22s linear;
        opacity: 1;
        pointer-events: initial;

        &--hidden {
            pointer-events: none;
            opacity: 0;
        }
    }
    & .dln-recipe-single__videoBtn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: $brand-03;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        z-index: 5;
        cursor: pointer;
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        &Icon {
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 7.5px 0 7.5px 13px;
            border-color: transparent transparent transparent $brand-01;
            margin-left: 3px;
        }
    }
    & .dln-recipe-single__videoWrap {
        display: block;
        width: 100%;
        height: 100%;
        // padding-bottom: 56.25%;
        position: relative;
        z-index: 2;
        border-radius: 5px;
    }
    & .dln-recipe-single__video,
    & .dln-recipe-single__iframe {
        width: 100%;
        max-width: 100%;
        height: 100%;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
    }

    &__step {
        + .dln-recipe-single__step {
            margin-top: 30px;
        }
        .dln-image {
            margin-bottom: 10px;
            border-radius: 5px;
        }
        & .dln-recipe-single__videoContainer {
            height: 341px;
        }
    }

    &__step-title {
        @include fontBold;
        font-size: 15px;
        line-height: 1.4;
        margin-bottom: 13px;

        span {
            @include fontRoman;
            color: $brand-02;
        }
    }

    &__products-links {
        margin: 120px 0;
        padding: 25px 0;
        border-top: 1px solid #e6e6e6;
        border-bottom: 1px solid #e6e6e6;
        align-items: center;

        .dln-recipe-single__col-3 {
            border-right: 1px solid #e6e6e6;
        }
        .dln-recipe-single__grid-products {
            padding: 15px 60px;

            .dln-recipe-single__col-3 {
                border-right: none;
                margin-top: 30px;
            }

            a {
                @include fontMedium;
                color: $brand-01;
            }
        }

        &-title {
            @include fontBold;
            font-size: 18px;
            line-height: 1.22;
            padding-bottom: 15px;
            margin-right: 40px;
        }
        &-description {
            font-size: 15px;
            line-height: 1.4;
            margin-top: 30px;
            margin-right: 40px;
            &-dk {
                display: block;
            }
            &-mb {
                display: none;
            }
        }
        &-label {
            font-size: 15px;
            line-height: 1.4;
            color: $brand-02;
            padding: 0 60px;
        }
        &-product {
            font-size: 15px;
            line-height: 1.4;
            color: $brand-02;
            padding: 0 60px;
        }
    }
    &__info-button-mobile {
        display: none;
    }
    &__info-button-desktop {
        display: block;
    }

    hr {
        margin: 30px 0;
        border: 1px solid #e6e6e6;
    }

    &__videoBgContainer {
        display: none;
        width: auto;
        height: 450px;
        position: relative;
        &.show {
            display: block;
        }
    }

    &__videoBg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__image-container {
        position: relative;
        width: 100%;
        height: 100%;

        &.hide {
            display: none;
        }
    }

    &__play-button {
        position: absolute;
        cursor: pointer;
        top: 50%;
        left: 50%;
        height: 120px;
        width: 120px;
        border-radius: 50%;
        background: $brand-03;
        z-index: 10;
        transform: translate(-50%, -50%);

        &:before {
            content:"\A";
            position: absolute;
            top: 50%;
            left: 50%;
            border-style: solid;
            border-width: 20px 35px 20px 0;
            border-color: transparent $brand-01 transparent transparent;
            position: absolute;
            transform: rotate(180deg) translate(40%, 50%);
        }
    }

    .dln-recipe-slides {
        padding-top: 0;
    }
}

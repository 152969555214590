.dln-notification {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    position: relative;
    .dln-btnIcon__checkoutCounter {
        margin: 0 8px 0 0;
        position: relative;
        top: auto;
        right: auto;
    }
    &__desc {
        @include small;
        width: calc(100% - 16px - 8px);
        line-height: 13px;
        color: $brand-04;
    }
}
.dln-radioBtn {
    position: relative;

    &__lbl {
        position: relative;
        display: inline-block;
        height: 16px;
        font-size: 13px;
        line-height: 16px;
        padding: 0 0 0 24px;
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        color: $ui-04;
        transition: .3s;
        &::before,
        &::after {
            position: absolute;
            content: '';
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }
        &::before {
            left: 0;
            top: 0;
            width: 12px;
            height: 12px;
            border: 2px solid $ui-02;
        }
        &::after {
            top: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            transform: scale(0);
            background: $support-02;
        }
    }
    & input[type="radio"] {
        cursor: pointer;
        /* width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        opacity: 0;
        z-index: 5; */
        display: none;
        /* appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none; */
        &:checked~.dln-radioBtn__lbl::before {
            border-color: $ui-02;
            animation: ripple 0.2s linear forwards;
        }
        &:checked~.dln-radioBtn__lbl::after {
            transform: scale(1);
        }
    }
    &--imgLabel {
        .dln-radioBtn__lbl {
            color: transparent;
        }
    }
    &__imgLabel {
        height: 16px;
        width: auto;
    }
    &--square {
        display: inline-flex;
        .dln-radioBtn__lbl {
            height: 14px;
            &::before,
            &::after {
                border-radius: 0;
            }
            &::before {
                border-width: 1px;
            }
            &::after {
                top: 3px;
                left: 3px;
            }
        }
        input[type="radio"] {
            &:checked~.dln-radioBtn__lbl {
                color: $brand-04;
            }
        }
    }
}

@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
    }
    50% {
        box-shadow: 0px 0px 0px 16px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px 16px rgba(0, 0, 0, 0);
    }
}

@import "./_radio-buttons-kenwood.scss";
@import "./_radio-buttons-delonghi.scss";

.dln-kenwood {
    & .dln-popup {
        & .dln-popup__content {
            & .dln-popup__wrapper {
                & .dln-orderCancellationConfirm {
                    padding: 60px 124px 50px 124px;

                    & .dln-popup__head {
                        padding: 0;

                        & .dln-btnIcon--close {
                            right: 14px;
                            top: 14px;

                            & .dln-icn::before {
                                color: $brand-04;
                            }
                        }
                    }
                    &__content {
                        flex-direction: column;
                        padding: 0;

                        & .dln-icn--check-circle {
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            font-size: 50px;
                            margin: 0 auto 10px auto;
                        }
                        & .dln-orderCancellationConfirm__wrap {
                            justify-content: center;
                            width: 100%;
                            margin: 0;

                            & .dln-orderCancellationConfirm__text {
                                text-align: center;
                            }
                            & .dln-btn--orderDetail {
                                width: 137px;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__content {
                & .dln-popup__wrapper {
                    & .dln-orderCancellationConfirm {
                        padding: 150px 20px 20px 20px;

                        & .dln-popup__head {
                            margin: 0;

                            & .dln-btnIcon--close {
                                right: 20px;
                                top: 20px;

                            }
                        }
                        &__content {
                            & .dln-orderCancellationConfirm__wrap {
                                & .dln-orderCancellationConfirm__text {
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dln-kenwood {
    & .dln-tooltip {
        &__container {
            // opacity: 1 !important;
            // visibility: initial;      
            // padding-left: 11px;              
        }
        &__content { 
            border: 1px solid $brand-02;
            position: relative;
            z-index: 1;

            &::before {   
                //
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                position: absolute;
                z-index: 2;
                //             
                border-top: 11px solid $brand-02;
                border-right: 8px solid transparent;
                border-left: 8px solid transparent;
                top: initial;
                bottom: -11px;
                left: calc(50% - 8px);
                right: initial;
                z-index: 2;
            } 
        } 
        & .dln-tooltip__arrow {
            transform: translateY(-1px);
            position: relative;
                z-index: 3;
        }
        &--topAlign {            
            & .dln-tooltip__content {
                width: 100%;
                margin: 0;

                &::before {
                    border-top: none;
                    border-right: 8px solid transparent;
                    border-bottom: 11px solid $brand-02;
                    border-left: 8px solid transparent;                
                    top: -11px;
                    left: calc(50% - 8px);
                    right: initial;
                    z-index: 2;
                } 
            } 
            & .dln-tooltip__arrow {
                transform: translateY(1px);
            }
        }

        &--leftAlign {            
            & .dln-tooltip__content {                
                &::before {
                    border-top: 8px solid transparent;
                    border-right: 11px solid $brand-02;
                    border-bottom: 8px solid transparent;
                    border-left: none;                   
                    top: calc(50% - 8px);
                    left: -11px;
                    right: initial;
                    z-index: 2;
                } 
            } 
            & .dln-tooltip__arrow {
                transform: translateX(1px);
            }
        }

        &--rightAlign {            
            & .dln-tooltip__content {                
                &::before {                  
                    border-top: 8px solid transparent;
                    border-right: none;
                    border-bottom: 8px solid transparent;
                    border-left: 11px solid $brand-02;                                               
                    left: initial;
                    right: -11px;
                    top: calc(50% - 8px);
                }
            }    
            & .dln-tooltip__arrow {      
                transform: translateX(-1px);                
            }
        }        
    }
}
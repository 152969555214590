.dln-kenwood {
    .dln-wishProd {
       height: auto;

        & .dln-btnTextIcon {
            width: 150px;
        }

        &__price,
        &__priceDiscounted {
            font-size: 14px;
            color: $brand-04;
        }
        &__price--discounted {
            color: #6b6b6b;
            &::after {
                top: calc(50% - 1px);
                background-color: #6b6b6b;
            }
        }
        &__outOfStock {
            font-size: 14px;
        }
        &:hover {
            border-color: $brand-04;
        }
        &:not(.dln-wishProd--profile) {
            text-align: center;
            width: 100%;
            flex-direction: column;
            position: relative;
            border-width: 0;

            & .dln-wishProd__imgLink {
                width: 100%;
                height: auto;
                margin: 0 0 10px 0;
            }
            .dln-wishProd__imgWrap {
                display: flex;
                align-items: center;
                width: 100%;
                height: auto;
                background-color: $brand-03;

                & > img {
                    margin: 0 auto;
                    width: auto;
                    height: 100%;
                    max-width: 100%;
                }
            }
          .dln-wishProd__data {
                width: 100%;
                min-height: initial;
                margin: 0 auto;
                margin-top: auto;
                text-align: center;
                display: flex;
                text-align: center;
                flex-direction: column;
                flex-wrap: wrap;
                flex: 1;
            }
            .dln-wishProd__name {
                padding: 0;
                border-width: 0;
                margin-bottom: 5px;
            }
            .dln-wishProd__priceWrap {
                text-align: center;
                display: block;
                margin: 0 0 13px 0;

                &:empty {
                    display: none
                }
                &--outOfStock {
                    z-index: 2;
                }
            }
            .dln-btnTextIcon,
            .dln-btn {
                margin: auto auto 0;
            }
            .dln-btn--stroked {
                height: 40px
            }
            .dln-btn--link {
                @include definePseudoElems;
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 150px;
                right: 0;
                top: 0;
                background-color: transparent;
                text-indent: 9999px;
                overflow: hidden;
                margin: 0;
                padding: 0;
                color: rgba(0,0,0,0);
                z-index: 2;

                &::before {
                    @include fontIcon;
                    content: 'g';
                    text-indent: 0;
                    width: 100%;
                    height: 100%;
                    color: $brand-01;
                    font-size: 30px;
                }
            }
        }
        &--outOfStock {
            .dln-btn--link {
                opacity: .7;
            }
            .dln-wishProd__data {
                .dln-wishProd__priceWrap {
                    &--outOfStock {
                        background-color: $ui-06;
                        position: absolute;
                        top: 88px;
                        left: 50%;
                        font-size: 14px;
                        transform: translateX(-50%);
                        color: $brand-03;
                        margin: 0;
                        display: inline-block;
                        width: auto;
                        p {
                            color: $brand-03;
                            padding: 8px;
                            width: 120px;
                            height: auto;
                            box-sizing: border-box;
                        }
                    }
                }
               .dln-wishProd__price {
                    display: block;
                }
               .dln-btn:last-child {
                    @media only screen and (min-width:600px) {
                        border-width: 0;
                        text-transform: none;
                        padding: 0 0 0 24px;
                        width: auto;
                        max-width: none;
                        color: $brand-04;
                        display: inline-block;
                        position: relative;
                        @include definePseudoElems;
                        text-decoration: underline;
                        font-size: 14px;
                        &::before {
                            @include fontIcon;
                            content: 'Ω';
                            width: 16px;
                            height: 16px;
                            color: $brand-04;
                            font-size: 16px;
                            left: 0;
                        }
                    }

                    @media only screen and (max-width:599px) {
                        font-size: 12px;
                        @include fontBold;
                        opacity: 1;
                        height: 38px;
                        &:hover {
                            border-color: $brand-01;
                            background: $brand-01;
                            color: $brand-03;
                        }
                    }

                }
            }
        }
        &--profile {
            .dln-wishProd__name {
                border-bottom: none;
            }
        }

        @media only screen and (max-width:599px) {
            &:not(.dln-wishProd--profile) {
                padding: 0;
                padding-bottom: 30px;
                .dln-wishProd__data {
                    min-height: auto;
                }
                & .dln-wishProd__imgLink {
                    height: 250px;
                    margin: 0 0 20px 0;
                }
                .dln-wishProd__imgWrap {
                    height: 100%;

                    & > img {
                        max-width: 100%;
                        object-fit: none;
                        margin: 0 auto;
                    }
                }
                .dln-wishProd__priceWrap {
                    margin: 0;
                }
                .dln-btnTextIcon,
                .dln-btn {
                    margin: 20px auto 0 auto;
                }
                .dln-btn--link {
                    margin: 0;
                }
            }
            .dln-btnTextIcon,
            .dln-btn {
                margin: 30px auto 0 auto;
            }
            &__data,
            &__priceWrap {
                text-align: center;
                display: block;
                margin-top: 8px;
            }
        }
    }
}
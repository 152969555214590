.dln-faq {
    &__main--noResults {
        & .dln-faq__mainTitle {
            line-height: 55px;
            font-size: 44px;
            margin-bottom: 32px;
        }
        & .dln-faq__mainSubtitle {
            line-height: 32px;
            font-size: 32px;
            margin-bottom: 20px;
        }
        & .dln-faq__spellCheck {
            display: block;
            width: 100%;
            line-height: 20px;
            font-size: 16px;
            color: $brand-01;
            margin-bottom: 30px;
        }
        & .dln-inputs {
            max-width: 488px;
        }
    }
    &__content--noResultsCategory {
        padding-top: 60px;
        
        & .dln-faq__breadCrumbSelWrap {
            margin-bottom: 0;
        }
        & .dln-faq__text {
            display: block;
            width: 100%;
            line-height: 20px;
            font-size: 16px;
            margin-bottom: 40px;
        }
    }
}
.dln-kenwood {
  .gigya-login-form,
  .gigya-profile-form,
  .gigya-register-form,
  .gigya-reset-password-form {
    .gigya-composite-control-loginID,
    .gigya-composite-control-password,
    .gigya-composite-control-textbox,
    .gigya-composite-control-dropdown {
      > label {
        position: relative;
        left: 0;
        top: 0;
        color: var(--ui-07);
        line-height: 16px;
        font-size: 12px;
        padding-left: 0;
        transform: none;
      }

      input {
        padding: 2px 8px !important;
        height: 43px;

        &.gigya-valid, &:focus {
          border-color: var(--brand-04) !important;
        }

        &.gigya-valid {
          background-image: url('#{$pathAssets}/images/icons/icon-valid-kenwood.svg')!important;
          background-size: 16px 16px;
          background-position: right 10px center;
        }
      }

      &.dlg-gigya-input--active {
        > label {
          transform: none;
        }
      }

      .dln-select__container {
        .dln-select__head,
        .dln-select--open .dln-select__body {
          border-color: var(--ui-02);
        }

        &.dln-select--has-value {
          .dln-select__head {
            border-color: var(--brand-04);
          }
        }
      }

      &.dlg-gigya-input--active {
        input.flatpickr-input {
          border-color: var(--brand-04) !important;
        }
      }

      &::before {
        top: 32px;
      }
    }

    .gigya-forgotPassword {
      color: var(--brand-04);
      font-family: var(--fontNameSemibold); /* due to cascading issue, we can't use the 'fontSemibold' mixin at the moment.*/
      font-weight: 600;
      font-size: 14px;
    }

    .dln-loginForm__paragraph {
      margin-bottom: 15px;
    }

    .gigya-input-submit {
      &:hover {
        opacity: 1;
        background-color: var(--brand-04);
        color: var(--brand-03);
      }
    }
  }

  .gigya-update-profile-screen {
    .gigya-profile-form {
      .gigya-composite-control-submit {
        padding: 0 20px!important;
      }
    }
  }

  .dln-changePssw {
    .gigya-profile-form {
      .gigya-composite-control-submit {
        .gigya-input-submit {
          background: none;

          &:hover {
            background: none;
          }
        }
      }
    }
  }


  [data-screenset-element-id='gigya-link-account-screen'] {
    .dln-completeForm__text {
      text-align: center;
      margin-bottom: 0;
    }

    #js-gigya-login-popup-container {
      margin-top: 0;
      text-align: center;
    }

    button {
      &.dln-btn {
        width: 100%;
      }
    }
  }

  [data-screenset-element-id=gigya-marketing-preferences-screen] {
    .gigya-layout-row {
      &:nth-child(4) {
        border: none;
        border-bottom: 1px solid var(--ui-02);
      }

      &.subscribe-wrapper {
        h2 {
          text-align: center;

          &.dln-page__sectionHeadTitle {
            margin-bottom: 20px;
            font-family: var(--fontNameExtra); /* due to cascading issue, we can't use the 'fontSemibold' mixin at the moment.*/
            font-weight: 400;
          }
        }
      }

      .gigya-composite-control {
        &-checkbox {
          box-shadow: none;
        }

        &-submit {
          padding-top: 30px !important;

          .gigya-input-submit {
            height: 45px;
            font-family: var(--fontNameSemibold); /* due to cascading issue, we can't use the 'fontSemibold' mixin at the moment.*/
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.2px;
            line-height: 1;

            &:hover {
              background-color: var(--brand-04);
            }

            &[disabled], &[disabled]:hover {
              background-color: var(--brand-01);
              color: var(--brand-03);
              cursor: default;
            }
          }
        }
      }
    }
  }

  //.dln-popup,
  .gigya-link-accounts-form,
  [data-screenset-element-id="gigya-complete-registration-screen"] {
    .dln-popup__content {
      max-width: 591px !important;
    }

    h2 {
      text-align: center !important;
      font-weight: 400 !important;
    }

    .gigya-composite-control-label {
      &.main-text {
        text-align: center;
      }
    }
  }

  //.dln-popup__wrapper,
  .gigya-register-form,
  .gigya-profile-form {
    > div.dln-completeForm {
      .gigya-screen-caption {
        line-height: 40px;
        font-weight: 400;
        font-family: var(--fontNameExtra);
        margin: 0 auto !important;
        font-size: 32px !important;
        text-align: center !important;
        border: none;

        @media only screen and (max-width: 599px) {
          font-size: 28px !important;
        }
      }

      .gigya-screen-content {
        .gigya-profile-form {
          .gigya-composite-control-submit {
            background-color: var(--brand-04);
            padding: 0 !important;

            .gigya-input-submit {
              padding: 0;
            }
          }
        }
      }
    }

    .gigya-composite-control-link {
      > button {
        @media only screen and (max-width: 599px) {
          width: 100%;
        }
      }
    }

    .gigya-composite-control-checkbox .gigya-checkbox-text {
      color: var(--brand-04);
    }

    .boldText {
      color: var(--brand-02);
    }



    .gigya-composite-control-multi-choice,
    .gigya-composite-control-checkbox {
      //.gigya-multi-choice-item {

      label > span {
        font-weight: 400;
      }

      .gigya-input-radio + label {
        color: var(--brand-04);
      }

      .gigya-multi-choice-item > label::before {
        border: 1px solid var(--brand-04);
      }

      input[type='radio'],
      input[type='checkbox'] {
        ~ label {
          &::before {
            border-color: var(--brand-04);
          }
        }

        &:checked {
          ~ label {
            &::before {
              border: 1px solid var(--brand-01);
            }

            &::after {
              background: var(--brand-01);
            }
          }
        }
      }

      input[type='radio']:checked ~ label::after {
        top: 3px;
        left: 3px;
      }

      input[type="checkbox"] {
        ~ label {
          &::after {
            font-family: var(--fontNameIcons);
            font-variant: normal;
            text-transform: none;
            background-color: transparent;
            color: var(--brand-01);
            font-size: 8px;
            line-height: normal;
          }
        }

        &:checked {
          ~ label {
            &::after {
              content: "à";
              background: none;
            }
          }
        }
      }

    }
  }

  .dln-popup {
    .gigya-login-form {
      .gigya-composite-control-link {
        .dln-btn {
          width: 100%;
        }
      }
    }
  }

  [data-screenset-element-id="gigya-register-screen"] {
    .dln-registrationForm__paragraph--secondary {
      font-size: 12px;
      margin: 0px auto 16px auto;
      line-height: 16px;
      &.wMargin {
        margin: 50px 0 30px;
      }
    }

    .dln-registrationForm__subtitle {
      font-size: 12px;
      margin-bottom: 0px;
      font-family: var(--fontNameBold);
      font-weight: 700;
      font-style: normal;
      color: var(--ui-05);
    }

    .dln-registrationForm__paragraph {
      font-size: 12px;
      margin-bottom: 16px;
      color: var(--ui-05);
    }
  }

  [data-screenset-element-id="gigya-forgot-password-screen"] {
    .dlg-gigya-link {
      font-family: var(--fontNameSemibold); /* due to cascading issue, we can't use the 'fontSemibold' mixin at the moment.*/
      font-size: 14px;
    }
  }

  .dlg-gigya-button-wrapper {
    input[type='submit'],
    input[type='button'] {
      text-transform: uppercase;
    }
  }

  .dln-checkout {
    .dln-accordion--access-information {
      .dln-checkout__innerCol:nth-child(2) {
        .gigya-input-submit {
          color: var(--brand-01);
          border: 1px solid var(--brand-01);
          background-color: var(--brand-03);
        }

        .dln-btn {
          color: var(--brand-03);
          border: 1px solid var(--brand-01);
          background-color: var(--brand-01);
        }
      }
    }
  }

  .newsletter-popup-opened.dln-popup--open {
   .dln-popup__content {
      max-width: none !important;
      width: auto;
      @media screen and (min-width: 600px) {
        max-width: 720px !important;
      }
    }
  }

  .dln-subscription-flow {
    height: auto;
    min-height: 620px;

    [data-screenset-element-id=gigya-newsletter-screen] {
      #gigya-subscribe-with-email-form {
        > .gigya-layout-row {
          .gigya-layout-row {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(6) {
              @media only screen and (min-width: 1024px) {
                width: calc(50% - 24px);
              }
            }

            &:nth-child(4) {
              @media only screen and (min-width: 1024px) {
                width: calc(100% - 24px);
              }
            }

            &:nth-child(5) {
              .gigya-composite-control-checkbox {
                margin: 0;

                .gigya-input-checkbox {
                  display: none;
                }

                .gigya-label {
                  display: flex;
                  justify-content: flex-end;

                  &::before {
                    $size: 14px;
                    width: $size;
                    height: $size;
                    border-color: var(--brand-01);
                    border-radius: 0;
                    top: 5px;
                  }

                  &::after {
                    color: var(--brand-01);
                    font-size: 8px;
                    top: 3px;
                    left: 4px;
                  }

                  &-text {
                    display: flex;
                    align-items: center;
                    width: 95%;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: justify;

                    i {
                      flex-shrink: 0;
                      margin-left: 10px;
                    }
                  }
                }
              }
            }

            &:nth-child(6) {
              .gigya-input-submit {
                height: 45px;
                border-radius: 0;
                background-color: var(--brand-01);
                font-size: 12px;
                letter-spacing: 0.2px;

                &:hover {
                  opacity: 1;
                  background-color: var(--brand-04);
                }
              }
            }
          }
        }
      }

      .gigya-input-checkbox.subscription-checkbox:checked:before {
        color: var(--brand-01);
        font-size: 8px;
        left: 5px;
        top: -1px;
      }

      .subscription-name-label {
        font-size: 12px !important;
        color: var(--brand-04) !important;
        font-family: var(--fontNameRoman);

        &:before {
          border-color: var(--brand-01);
          border-radius: 0 !important;
          width: 14px;
          height: 14px;
        }

        i:hover {
          color: #1a1d1f !important;
          border: 1px solid #1a1d1f;
        }
      }
    }

    .dlg-gigya-input-text-wrapper {
      .gigya-label {
        position: static;
        top: unset;
        left: unset;
        padding: 0;
        transform-origin: unset;
        transition: none;

        .gigya-label-text,
        .gigya-required-display {
          line-height: 14px;
          font-size: 12px;
          color: var(--ui-07);
        }
      }

      &.dlg-gigya-input--active {
        .gigya-label {
          background: none;
          transform: none;
        }
      }

      .gigya-input-text {
        height: 43px;
        border: 1px solid var(--brand-04) !important;
        border-radius: 0 !important;
        padding: 2px 8px;
      }
    }
  }

  #gigya-marketing-preferences-screen{
    .gigya-input-checkbox {
      width: 0;
      height: 0;
    }
    .gigya-label {
      &:before {
        border-radius: 0; 
        width: 16px;
        height: 16px;
      }
    }
    .gigya-checkbox-text {
      margin-left: 24px;
      line-height: 20px;
      position: relative;
      @media screen and (min-width: 768px) {
        width: calc(100% - 8px);
        position: absolute;
        line-height: 16px;
      }
    }
    .gigya-layout-row:nth-child(4) {
      @media screen and (min-width: 768px) {
        padding-bottom: 50px;
      }
    }
    .gigya-toggle-content.is-open {
      @media screen and (min-width: 768px) {
        margin-top: 36px !important; 
      }
    }
  }

  .adyen {
    &-checkout {
      &__input {
        border-radius: 0;
      }
    }
  }
}

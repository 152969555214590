@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-persDataRecap {
        &__wrap {
            flex-direction: row;
            max-height: none;
            overflow: auto;
            & .dln-inputs {
                width: 100%;
                max-width: none;
                margin: 0 0 16px 0 !important;
            }
            & .dln-inputs.dln-inputs--disabled {
                & .dln-inputs__input,
                & .dln-inputs__lbl {
                    width: 100%;
                    max-width: none;
                    padding: 0;
                }
            }
            .dln-select {
                width: 100%;
                max-width: none;
                padding: 0;
                margin: 0 0 16px 0 !important;
            }
        }
        &--editing {
            & .dln-persDataRecap__wrap {
                max-height: none;
            }
        }
    }
}
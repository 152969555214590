.dln-search {
    max-width: 690px;
    margin: 0 auto;

    .simple-list {
        margin-top: 30px;

        // a, &__item {
        //     text-decoration: underline;
        //     color: $brand-04;
        //     @include fontSemibold;
        //     cursor: pointer;

        //     &:visited {
        //         color: $brand-04;
        //     }
        // }
    }

    .dln-popup__head {
        position: static;
        padding: 100px 0 26px 0;

        &Title {
            text-align: left;
            font-size: 26px;
            margin: 0;
            padding: 0;
        }
    }

    .dln-popup__head .dln-btnIcon {
        position: absolute;
        top: 14px;
        right: 14px;
    }

    &__withResult-section {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 40px;
        margin: 0 -10px;

        .simple-list {
            width: calc(50% - 20px);
            padding: 0 10px;
            margin-top: 20px;
        }
    }

    &--noResults {
        .dln-popup__head {
            padding-bottom: 26px;

            &Title {
                text-align: left;
                margin: 0;
                padding: 0;
            }
        }

        &__subTitle {
            font-weight: 700;
            font-size: 18px;
            font-stretch: normal;
            padding-top: 20px;
            padding-bottom: 6px;
        }

        p {
            font-size: 14px;
        }
    }

    .prices {
        display: flex;
        flex-flow: column;

        .dln-price--oldPrice {
            order: 2;
            &:after {
                width: 86%;
            }
        }
    }
}

.dln-braun {
    .dln-search {
        .dln-search-result {
            &__detailsTitle {
                margin-bottom: 5px;
            }

            &__modelNumber {
                color: var(--brand-01);
                font-size: REM(12px);
                font-weight: 700;
            }

            .dln-price--discounted {
                margin-left: 8px;
            }

           .prices {
            flex-flow: row-reverse;

                .dln-price--oldPrice {
                    order: 2;
                    &:after {
                        width: 100%;
                    }
                }
            }
        };
    }
}   

@media only screen and (max-width:599px) {
    .dln-recipe-slides {
        padding-top: 50px;

        &__title {
            font-size: 24px;
            line-height: 1.13;
        }
        .dln-filters-bar__mobile {
            display: none;
        }
        & .swiper-container {
            .swiper-button-next,
            .swiper-button-prev {
                display: none !important;
            }

            .swiper-slide{
                max-width: none;
                width: calc(50% - 7.5px)!important;
                margin: 0 10px 0 0;






            }
        }


    }
}

.dln-kenwood {
    .dln-addressBox {
        border: 1px solid $ui-07;

        &__data {
            font-size: REM(15px);
            line-height: REM(22px)
        }
        & .dln-btn.dln-btn--link {
            @include fontMedium;
            height: 20px;
            line-height: REM(19px);
            font-weight: 500;
            font-size: 13px;
        }
        .dln-btn.dln-btn--edit {
            &::after {
                content: '';
                background-color: $brand-04;
                margin: 0 10px;
            }
        }

        .dln-checkbox .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
            color: var(--brand-04);
        }

        &__foot {
            .dln-checkbox .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder {
                border-color: var(--brand-05);

                &::after {
                    color: var(--brand-05);
                    font-size: REM(18px);
                }
            }
        }
    }
}
.dln-drawer {
    display: none;
    visibility: hidden;

    position: absolute;
    top: calc(100% + 23px);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    min-width: 170px;
    padding: 20px;

    //box-shadow: 0 0 20px -10px rgba(0,0,0,0.2);
    box-shadow: 0 4px 7px 0 rgba(82, 90, 102, 0.46);

    color: $brand-01;

    text-align: center;

    z-index: 2000;

    &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 6px;
        margin-left: -6px;
    }

    .txt_container {
        .icon {
            display: block;

            .dln-icn {
                color: $brand-04;
            }
        }
        .text {
            @include fontBold;
            font-size: 15px;
            line-height: 21px;
        }

        & + .cta_container {
            margin-top: 10px;
        }
    }
    .cta_container {
        .text {
            display: block;

            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;

            a {
                font-size: 14px;
                line-height: 20px;
                color: $brand-01;
            }
        }

        .ctas {
            .dln-btn {
                display: block;

                @include cta;
                @include fontBold;
                outline: none;
                width: auto;
                line-height: 21px;
                font-size: 14px;
                color: $brand-03;
                text-align: center;
                /* reset */
                border: none;
                background-color: $brand-01;
                box-shadow: none;
                transition: all 0.3s linear;
                padding: 10px;
                // margin: 10px 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                user-select: none;

                text-decoration: none;
                white-space: nowrap;
            }
        }
    }
    .userNotLogged {
        > .dln-btn {
            display: block;

            @include cta;
            @include fontBold;
            outline: none;
            width: auto;
            line-height: 21px;
            font-size: 14px;
            color: $brand-03;
            text-align: center;
            /* reset */
            border: none;
            background-color: $brand-01;
            box-shadow: none;
            transition: all 0.3s linear;
            padding: 10px;
            // margin: 10px 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            overflow: hidden;
            overflow: hidden;
            cursor: pointer;
            user-select: none;

            text-decoration: none;
            white-space: nowrap;
        }
    }


    &--slim {
        padding: 15px;
        .txt_container {
            display: flex;
            align-items: center;

            .icon {
                margin-right: 9px
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-drawer {
    	top: calc(100% + 17px);
    }
}

@import "_drawer-kenwood";
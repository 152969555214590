.dln-delonghi {
  & .dln-radioBtn {
    &__lbl {
      display: inline-flex;
      align-items: center;
      height: 22px;
      line-height: 22px;
      color: $brand-04;
      padding-left: calc(22px + 9px);
      font-size: REM(14px);

      &::before {
        width: 22px;
        height: 22px;
        border: 1px solid $ui-02;
        box-sizing: border-box;
      }
      &::after {
        width: 14px;
        height: 14px;
        background-color: $brand-04;
      }
    }
    & input[type='radio'] {
      &:checked + .dln-radioBtn__lbl::before {
        border-color: $brand-04;
      }
    }
  }
}

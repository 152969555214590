.dln-orderRev {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid;
  border-color: transparent;
  transition: border-color 0.12s linear;
  padding: 10px;
  position: relative;

  &:hover {
    border-color: $ui-02;
  }
  &__itemPic {
    display: inline-flex;
    align-items: center;
    width: 180px;
    height: auto;
    max-height: 142px;
    margin: 0 16px 0 0;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    & > img {
      // width: auto;
      width: 100%;
      height: 100%;
      // height: auto;
      // max-height: 150px;
      // margin: 0 auto;
      object-fit: contain;
    }
  }
  &__detailsTitle {
    @include mainMenu;
    @include fontBold;
    display: block;
    width: 100%;
    font-size: 15px;
    text-overflow: ellipsis;
    margin: 0 0 4px 0;
    overflow: hidden;
  }
  &__reviewTitle {
    display: none;
  }
  & .dln-price {
    display: inline-flex;
    width: auto;
    height: 16px;
    max-height: initial;
    line-height: 16px;
    // padding: 4px 0 0 0;
    margin: 4px 0 0 0;

    &::after {
      background-color: $brand-02;
    }
  }
  & .dln-price--oldPrice {
    color: $brand-02;
    // margin: 0 8px 0 0;
    margin: 4px 8px 0 0;
  }
  &__delivery {
    display: block;
    width: 100%;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    color: $brand-02;
    text-align: right;
    padding: 4px 0 0 0;
    margin: 0 0 0 auto;
  }
  &__content {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: calc(100% - 180px - 16px);
    padding: 8px 0 0 0;
  }
  &__details {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid $ui-01;
    padding: 0 0 8px;
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &:nth-of-type(2) {
      align-items: flex-end;
    }
  }
  &__rating {
    display: inline-flex;
    flex-wrap: wrap;
    width: auto;
    margin: 0 auto 8px 0;

    & .dln-icn {
      color: #ffd446;
    }
  }
  &__actions {
    display: flex;
    align-self: flex-end;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    width: 100%;
    margin: 16px 0 0;
    & .dln-btn {
      width: auto;
      min-width: 180px;
      margin: 0 0 0 0;

      &.dln-btn--link {
        margin: 0 16px 0 auto;
      }
      &.dln-btn--action-done {
        width: auto;
        pointer-events: none;
      }
      &:not(:last-child) {
        min-width: initial;
        //margin: 0 16px 0 auto;
        margin: 0 auto 0 0;
      }
    }
  }
  &--productReview {
    & .dln-orderRev__delivery {
      margin: 0 0 10px 0;
    }
    & .dln-orderRev__actions {
      & .dln-btn {
        &--stroked {
          width: 184px;
        }
        &--link {
          align-self: flex-end;
          min-width: initial;
          margin: 0 auto 0 0;
        }
      }
    }
  }
  &__productCode {
    @include mainMenu;
    width: 100%;
    margin: 0 0 10px;
  }
  &--cart-order {
    .dln-orderRev__content {
      padding: 0 0;
    }
    .dln-orderRev__details {
      align-self: auto;
      width: auto;
      max-width: 60%;
      border: none;
      padding: 0 0;
      margin: 0 auto 0 0;
    }
    .dln-orderRev__wrap--main {
      margin: 0 0 16px;
    }
    .dln-orderRev__actions {
      @media only screen and (min-width: 599px) {
        text-align: right;
      }
      display: flex;
      align-self: auto;
      flex-wrap: wrap;
      width: auto;
      max-width: 40%;
      margin: 0 0 0 auto;

      .dln-btn {
        display: block;
        width: 100%;
        margin: 0 0 10px 0;
      }
    }
  }
  &--returned {
    & > .dln-image,
    & .dln-orderRev__details {
      // opacity: 0.3;
    }
    & .dln-orderRev__actions {
      width: 40%;

      & .dln-btn {
        // max-width: none;
        width: 184px;
      }
    }
    & .dln-orderRev__howItWorks {
      display: inline-flex;
      width: 88px;
      color: $brand-01;
      text-decoration: underline;
      border: none;
      background: none;
      background-color: transparent;
      margin: 0 calc((184px - 88px) / 2) 0 auto;
      -webkit-appearance: none;
      -moz-appearance: none;
      margin-top: 10px;
    }
  }
  &--simpleview {
    .dln-orderRev__content {
      align-content: center;
      padding: 0;
    }
    .dln-orderRev__details {
      border-bottom: none;
      padding: 0;
    }
  }
  &--registeredproduct {
    border-width: 0;
    padding: 0;

    &:hover {
      border-width: 0;
    }
    .dln-orderRev__details {
      padding: 0 0 16px;

      .dln-orderRev__wrap {
        &--main {
          margin: 0 0 16px;
        }
        &--registeredproduct,
        &--links {
          width: 50%;
        }
        &--links {
          display: inline-block;
          width: auto;
          text-align: right;
          margin: 0 0 0 auto;

          .dln-btn--link {
            display: block;
            text-align: right;
          }
        }
        .dln-orderRev__purchaseDate {
          width: 100%;
          color: $brand-02;
          margin: 0 0 4px;

          b {
            color: $brand-04;
          }
        }
      }
    }
    .dln-orderRev__actions {
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      .dln-orderRev__wrap--links {
        display: inline-block;
        width: auto;
        max-width: calc(100% - 100px);
        text-align: right;
        margin: 0 auto 0 0;

        &:last-of-type {
          width: 60%;
          margin: 0 0;
        }
        .dln-btn {
          width: calc(50% - 16px);
          min-width: auto;
        }
      }
    }
  }
  &--productDescription {
    display: inline-block;
    padding: 10px;

    .dln-orderRev__content {
      display: block;
      width: calc(100% - 180px - 16px);
      float: left;
    }
    .dln-orderRev__details {
      border: none;
      padding: 0;
    }
    .dln-orderRev__itemPic {
      display: block;
      width: 180px;
      float: left;
    }
    .dln-orderRev__detailsTitle {
      width: calc(50% - 20px);
      margin: 0 20px 0 0;
    }
    .dln-orderRev__sku {
      @include small;
      font-size: 14px;
      color: $brand-02;
    }
    .dln-orderRev__desc {
      @include small;
      display: block;
      width: calc(100% - 180px - 16px - 24px - 180px);
      font-size: 12px;
      color: $brand-02;
      float: left;
      margin: 16px 24px 0 0;
    }
    > .dln-btn {
      display: block;
      width: 180px;
      float: right;
      margin: 16px 0 0 0;
    }
    .dln-orderRev__actions {
      width: 100%;
      max-width: 180px;
      .dln-btn {
        margin: 0 0;
      }
    }
  }
}

.dln-orderRev-input-wrapper {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;

  &--notReturnable {
    & > .dln-checkbox {
      opacity: 0;
      pointer-events: none;
    }
  }
  & + .dln-orderRev-input-wrapper {
    border-top: 1px solid #e6e6e6;
  }
  &__single-item-content {
    flex: 0 0 calc(33.33% - 15px);
    align-self: flex-start;
  }
  & .dln-orderRev {
    flex: 0 0 calc(66.66% - 15px);

    &__wrap {
      margin-bottom: 15px;
    }
    &__detailsTitle {
      width: 100%;
    }
  }
  & .dln-orderRev {
    padding: 0;

    &:hover {
      border-color: transparent;
    }
  }
  &__select-quantity {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & .dln-inputIncr {
      max-width: 120px;
      border: 1px solid #e6e6e6;
      border-radius: 22.5px;
      padding: 10px 20px;
      margin-left: 20px;
    }
  }
  &__single-item-content.-notReturnable {
    & .dln-orderRev-input-wrapper__select-motivation {
      pointer-events: none;
    }
    & .dln-orderRev__notReturnSel {
      width: 100%;
      color: $ui-02;
      border: 1px solid $ui-01;
      background-color: $brand-03;
      padding: 12px 16px;
      box-sizing: border-box;
    }
    & .dln-orderRev__notReturnBox {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      & .dln-orderRev__notReturnText {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        padding: 15px 0 20px 0;
      }
      & .dln-btn {
        width: 184px;
        margin: 0 0 0 auto;
      }
    }
  }
}

.dln-orderRev--weeeTax {
  display: flex;
  gap: 10px;
  font-size: 12px;
  margin-top: 5px;
  align-items: center;

  .dln-tooltip {
    color: var(--brand-01);
    margin-top: -4px;
  }
}

.dln-loading-spinner {
  position: fixed;
  z-index: z('ajax-loader');
  overflow: hidden;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.55);

  &--visible {
    display: flex;
  }

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border: 5px solid rgba(255, 255, 255, 0.2);
    border-left-color: #ffffff;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }

  &__text {
    font-size: 22px;
    color: #f1f1f1;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

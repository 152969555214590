.dln-kenwood {
    & .dln-recipe-details {
        &__videoBgContainer {
            background-color: $brand-04;
        }
    }
    .dln-recipe-details,
    .dln-recipe-details--print {
        * {
            box-sizing: border-box;
        }
        height: 544px;

        .dln-wrapper {
            margin: 0 auto !important;
            padding: 0 !important;
        }

        .dln-wrapper,
        .dln-recipe-details__grid {
            width: 100%;
            height: 100%;
            max-height: initial;
        }

        .dln-recipe-details__grid {
            margin: 0;
            justify-content: space-between;
        }

        .dln-recipe-details__image-container {
            max-width: 591px;
            .dln-image {
                width: 100%;
                max-height: 544px;
                border-radius: 0;
            }
        }

        .dln-recipe-details__col {
            padding: 0;
        }

        .dln-recipe-details__col:nth-of-type(1) {
            padding: 100px 50px 50px 0;
            width: 50%;
        }

        .dln-recipe-details__col:nth-of-type(2) {
            width: 50%;
            max-width: 591px;
            min-width: unset;
        }

        .dln-recipe-details__title-recipe {
            margin-top: 0;
            @include fontExtra;
            font-size: 32px;
        }

        .dln-recipe-details__description {
            font-size: 14px !important;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43 !important;
        }

        .dln-btn.dln-btn--link {
            border-radius: 0;
        }
    }

    .dln-recipe-details {
        &__info-button-desktop {
            .dln-btn {
                line-height: 4;
            }
        }
    }

    .dln-recipe-details--inverted {
        .dln-recipe-details__col:nth-of-type(1) {
            padding: 100px 0 50px 50px;
            width: 50%;
        }
    }

    .dln-recipe-details__play-button {
        background: rgba(255, 255, 255, 0.5);
        border: 2px solid $ui-01;
        width: 60px;
        height: 60px;
    }

    .dln-recipe-details--print {
        .dln-wrapper {
            max-width: 1240px;
        }

        .dln-recipe-details__info-button-desktop {
            .dln-btn.dln-btn--link.dln-btn--icon {
                //margin: 0 auto;
            }
            span,
            i {
                color: $brand-04;
            }

            span {
                display: inline-block;
                margin-left: 8px;
                font-size: 14px;
                text-decoration: underline;
            }

            i {
                font-size: 30px;
                font-weight: normal;
            }
        }
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .dln-kenwood .dln-recipe-details .dln-recipe-details__col:nth-of-type(2),
    .dln-kenwood .dln-recipe-details .dln-recipe-details__image-container {
        max-width: none;
    }

    .dln-kenwood {
        .dln-recipe-details,
        .dln-recipe-details--print {
            height: auto;
            padding: 0 !important;
            max-height: unset;

            &--constrain {
                max-height: unset;
            }

            &__info-button-mobile {
                .dln-btn.dln-btn--link {
                    margin: 0 auto;
                }
            }
        }

        .dln-recipe-details__bottom-info {
            margin-left: auto;
            margin-right: auto;
            width: 50%;
            /*
            &-col{
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;


            } */
        }
    }
}

@media screen and (max-width: 900px) {
    .dln-kenwood {
        .dln-recipe-details,
        .dln-recipe-details--print {
            height: auto;
            padding: 0 !important;
            max-height: unset;

            &--constrain {
                max-height: unset;
            }

            &__image-container {
                width: 100%;
            }

            .dln-recipe-details__info-button-desktop {
                .dln-btn.dln-btn--link.dln-btn--icon {
                    margin: 0 auto;
                }
            }

            .dln-recipe-details__col:nth-of-type(1),
            .dln-recipe-details__col:nth-of-type(2) {
                //padding: 100px 0 50px 50px;
                width: 100%;
            }

            .dln-recipe-details__col:nth-of-type(1) {
                padding: 20px;

                > div {
                    padding: 0px;
                }
            }

            .dln-recipe-details__col:nth-of-type(2) {
                height: 280px;
            }

            &__bottom-info-label,
            &__bottom-info-label {
                font-size: 14px;
            }

            &__info {
                text-align: center;
            }

            .dln-btnIcon {
                right: 20px;
            }

            &__info-button-mobile {
                margin-top: 40px;
                display: flex;
                justify-content: center;

                .dln-btn{
                    padding: 20px;
                    margin: 0 auto;
                }

            }
        }
    }

    .dln-kenwood .dln-recipe-details .dln-recipe-details__title-recipe {
        font-size: 28px;
    }


}

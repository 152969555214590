.dln-delonghi {
  .dln-errorPage {
    &__title {
      @include fontRoman;
      font-size: REM(18px);
      line-height: 1.33;
    }

    &__mainTitle {
      @include fontMedium;
      font-size: REM(50px);
      line-height: 1.04;
      span {
        @include fontMedium;
        font-size: REM(37px);
        line-height: 1;
        margin-top: 15px;
      }
    }
    &__mainText {
      font-size: REM(16px);
      line-height: 1.5;
    }

    & .dln-wrapper {
      &--fullWidth {
        background-color: $brand-05;
        padding-left: 0;
        padding-top: 110px;
        padding-bottom: 110px;
        margin-bottom: 40px;
      }
      &--main {
        max-width: 1300px;
        margin: 0 auto;
        padding: 0 20px;
      }

      &--content {
        max-width: 1300px;
        margin: 0 auto 110px;

        .dln-btn {
          width: 220px;
        }
      }
    }
    &__picture {
      z-index: 0;
      content: none;
      left: 50%;
      transform: translateX(-50%);
      max-width: 1300px;
      background: transparent;

      & > img {
        top: 50%;
        width: 570px;
        transform: translateY(-50%);
        object-fit: contain;
        object-position: center;
        min-width: initial;
        min-height: initial;
        right: 0;
        max-width: 40vw;
      }
    }
    .dln-inputs {
      max-width: 490px;
      width: 100%;
      &--active .dln-inputs__lbl {
        display: none;
      }
    }

    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
      .dln-wrapper {
        width: auto;
        padding: 10px 21px 44px 20px;

        .dln-errorPage__mainTitle {
          margin-bottom: 16px;

          span {
            padding-top: 16px;
            font-size: REM(28px);
            line-height: 1.07;
            font-weight: 500;
            letter-spacing: -0.53;
          }
        }

        .dln-inputs {
          margin-bottom: 0;
          max-width: none;
        }

        &.dln-wrapper--fullWidth {
          background-color: $brand-05;
          margin-bottom: 0;
          padding: 0 20px 44px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        &.dln-wrapper--main {
          padding: 0;
          margin: 0;
        }
        &.dln-wrapper--content {
          padding-top: 30px;
        }
      }

      .dln-errorPage__wrap {
        width: 100%;
      }

      .dln-errorPage__picture {
        width: auto;
        height: auto;
        display: block;
        content: none;
        position: relative;
        left: auto;
        transform: none;
        img {
          object-fit: cover;
          top: 0;
          transform: none;
          position: relative;
        }
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-wrapper {
        width: auto;
        padding: 10px 21px 44px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .dln-errorPage__mainTitle {
          margin-bottom: 16px;
          font-size: REM(37px);

          span {
            font-size: REM(28px);
            padding-top: 16px;
            font-size: REM(28px);
            line-height: 1.07;
            font-weight: 500;
            letter-spacing: -0.53;
          }
        }

        .dln-inputs {
          margin-bottom: 0;
        }

        &.dln-wrapper--fullWidth {
          background-color: $brand-05;
          margin-bottom: 0;
          padding: 0 20px 44px;
        }
        &.dln-wrapper--main {
          padding: 0;
          margin: 0;
        }
        &.dln-wrapper--content {
          padding-top: 16px;
          margin: 0;
          .dln-errorPage__title {
            font-size: REM(18px);
            font-weight: normal;
            margin-bottom: 16px;
          }

          .dln-btn--backToHome {
            margin-bottom: 16px;
          }

          .dln-btn--customerService {
            margin: 0 auto 0 0;
          }
        }
      }

      .dln-errorPage__picture {
        width: auto;
        height: auto;
        display: block;
        content: none;
        position: relative;
        left: auto;
        transform: none;
        img {
          object-fit: cover;
          top: 0;
          transform: none;
          width: 250px;
          height: 219px;
          position: relative;
          max-width: none;
        }
      }
    }
  }
}

h1,
.dln-title-h1 {
    @include extraBig;
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        font-size: 35px;
    }
}
h2,
.dln-title-h2 {
    @include big;
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        font-size: 21px;
    }
}
h3,
.dln-title-h3 {
    @include large;
}
h4,
.dln-title-h4 {
    @include medium;
}

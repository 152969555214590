.dln-prodReg {
    overflow: initial;

    .dln-timeline {
        margin: 40px auto 0;
    }
    &--search {
        .dln-prodReg {
            &__head {
                margin: 50px 0 0;
                max-width: none;
            }
            &__content {
                max-width: 980px;
                margin: 60px auto 100px;
            }
            &__searchModel {
                display: flex;
                flex-wrap: wrap;
                .dln-inputs {
                    max-width: 380px;
                    margin: 0 20px 0 0;
                }
                .dln-btn {
                    max-width: 180px;
                }
            }
            &__inner {
                width: 100%;
                opacity: 1;
                transition: opacity .4s cubic-bezier(.25, .8, .25, 1);
                .dln-prodReg__categoryWrap {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                }
                .dln-categoryBox {
                    margin: 0 0 0;
                    max-width: 20%;
                    cursor: pointer;
                }
                &--hide {
                    opacity: 0;
                }
            }
            &__tabContent {
                width: 100%;
            }
        }

        .dln-tooltip {
            margin: 4px 0 0 8px;
            &__anchor {
                font-size: 10px;
                color: $ui-02;
                text-decoration: underline;
            }
        }
        .dln-tabs__content {
            padding: 24px 0 40px;
            border-bottom: 1px solid $ui-01;
            min-height: 180px;

            .dln-prodReg__subTitle {
                font-size: 22px;
                margin-top: 0;
            }
            .dln-category-browser__wrapper {
                padding-top: 0;

                .dln-wrapper {
                    padding: 0;

                    .dln-category-browser__header {
                        margin-bottom: 24px
                    }
                    .dln-category-browser__breadcrumbs {
                        margin-bottom: 10px
                    }
                }
            }
        }
        .dln-timeline {
            margin: 40px auto 0;
        }
    }
    & .dln-category-browser__breadcrumbs {
        div {
            display: inline-block;
        }

        & .dln-breadCrumbSel {  
            display: inline-block;

            &__text {
                display: inline-block;
            }
        
            &__text--selected {
                display: inline-block;
                color: var(--brand-02);

                & + .dln-breadCrumbSel__arrow {
                    transform: rotate(360deg);
                    display: inline-block;
                }
            }

            &__arrow {
                transform: rotate(360deg);
                display: inline-block;
                top: -1px;
                margin-left: 3px;

                &--search {
                    top: -1px;
                }
            }   
        }
    }
}

.dln-braun .dln-prodReg .dln-category-browser__breadcrumbs {
    & .dln-breadCrumbSel__arrow--selected {
        top: -1px;
    }

    & .dln-breadCrumbSel__text--selected {
        @include fontRoman;
        color: var(--brand-02);
    }
}

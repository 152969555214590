@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-recipe-by-ingredients {
        .dln-site-search__toolbar {
            .dln-site-search__toolbarFilters {
                display: none;
            }
            .dln-btnTextIcon--braun-filters {
                display: block;
            }
            .bra-btnView {
                display: flex;
            }
        }
    }
}

.dln-delonghi {
  .dln-checkbox {
    margin-bottom: 10px !important;

    &__wrap {
      padding: 0;
    }
    &__inputPlaceholder {
      width: 24px;
      height: 24px;
      position: relative;
      top: 0;
      border-color: $brand-04;

      &::after {
        width: 16px;
        height: 16px;
        top: 50%;
        left: 50%;
        transform: scale(0, 0) translate(-50%, -50%);
        transform-origin: 0 0;

        background: none;
        box-shadow: none;

        content: 'à';
        @include iconspseudoelements;
      }
    }
    &__label {
      @include fontBook;
      font-size: REM(13px);
      line-height: 16px;
      color: $brand-04;
    }
    .dln-checkbox__input:checked + .dln-checkbox__inputPlaceholder {
      border-color: $brand-04;
      &::after {
        transform: scale(1, 1) translate(-50%, -50%);
      }
    }
    .dln-checkbox__input:checked
      + .dln-checkbox__inputPlaceholder
      + .dln-checkbox__label {
      @include fontRoman;
      color: $brand-04;
    }

    &--image {
      .dln-checkbox__wrap {
        padding: 20px;
      }
    }

    &--interactive {
      border: 1px solid $ui-01;

      .dln-checkbox__wrap .dln-checkbox__tooltipIconWrap {
        top: auto;
        bottom: 0;

        .dln-icn {
          color: $brand-04;
        }
      }
    }
  }
}

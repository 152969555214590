.dln-prodManualsCard {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid $ui-01;
    padding: 0 0 32px 0;

    &__imageWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 21px 0 0;
        position: relative;
        z-index: 1;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0,0,0,0.04);
            z-index: 2;
        }
    }
    &__image {
        display: inline-block;
        max-width: 100%;
        height: auto;
        object-fit: contain;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: calc(100% - 489px - 21px);
    }
    &__prodName {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 32px;
        margin: 0 0 26px 0;
    }
    &__sku {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 16px;
        color: $brand-02;
        text-transform: uppercase;
        margin: 0 0 32px 0;

        & span {
            @include fontBold;
        }
    }
}

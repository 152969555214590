.dln-inputsFile {
    @include small;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    border-radius: 2px;
    transition: all .4s cubic-bezier(.25, .8, .25, 1);    
    position: relative;

    padding-bottom: 32px;

    &__input {
        display: none;
    }
    .dln-btn {
        width: 130px;
        padding: 0;
        margin: 0 auto 0 0;
        // max-width: none;
    }
    &__label {
        @include small;
        display: inline-flex;
        align-items: center;
        width: calc(100% - 130px - 24px);
        color: $brand-02;
        box-sizing: border-box;
        position: relative;

        &Text {
            display: none;            
            width: calc(100% + 2px);
            height: 16px;
            // line-height: 16px;
            line-height: 1.6;
            font-size: 12px;
            color: $ui-07;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            position: absolute;
            top: -20px;
            left: -1px;
        }
        .dln-tooltip {
            order: 2;
            color: $brand-02;
            margin: 0 0 0 auto;
            z-index: 9;
        }
        &:not(.dln-inputsFile__label--file) {
            border: 1px solid $ui-01;
            padding: 0 8px;
        }
    }
    &--fileUploaded {
        .dln-inputsFile__label:not(.dln-tooltip) {
            color: transparent;
        }
    }
    &__innerLabel {
        order: 1;
        width: calc(100% - 24px);
        // max-width: 100%;
        height: 20px;
        line-height: 1.6;
        font-size: 12px;
        color: $brand-02;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        // position: absolute;
        // top: calc(50% - 10px);
        // left: 8px;
        // opacity: 0;
        // z-index: 8;
    }
    &--error {
        .dln-inputsFile__label {
            border-color: $support-01;
        }
        .dln-inputsFile__innerLabel,
        .dln-inputsFile__error {
            color: $support-01;
        }
    }
    &__error {
        @include small;
        display: block;
        color: $brand-02;
        white-space: nowrap;
        text-overflow: ellipsis;
        transform: translate(8px, 0) scale(0.75);
        transform-origin: 0 0;
        transform-origin: bottom left;
        overflow: hidden;
        position: absolute;
        top: 40px;
        bottom: auto;
        left: 0;
    }
    &--standard  {
        //     
    }
}
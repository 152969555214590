@media only screen and (max-width:599px) {
    .dln-orderCancellationConfirm {
        align-content: flex-start;

        & .dln-popup__head {
            padding: 38px 35px 115px 35px;

            & .dln-btnIcon--close {
                margin-bottom: 0;
            }
        }
        &__content {
            padding: 0 20px;
        }
        &__wrap {
            width: calc(100% - 48px - 25px);
            margin: 0 0 0 25px;
        }
    }
}

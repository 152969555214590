.dln-delonghi {
  .dln-multiSelectList {
    &__label {
      font-size: REM(18px);
      line-height: 2.56;
      padding: 0;
    }
    &__wrap {
      padding: 0;
    }
    &__item {
      border-bottom: none;
    }

    &__input:checked {
      + .dln-multiSelectList__wrap {
        background: $brand-03;

        .dln-multiSelectList__label {
          @include fontRoman;
        }
      }
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-writeReview {
        padding: 24px;
        .dln-orderRev {
            &__itemPic {
                // width: auto;
                width: 100%;
                height: 183px;
                margin: 0 auto;

                & > img {
                    // width: 183px;
                    // height: 183px;
                    object-fit: contain;
                }
            }
        }
    }
}

.dln-delonghi {
  .dln-order--detail {
    .dln-order {
      &__content--lite {
        min-width: 600px;
        .dln-orderDetailHead {
          &__number {
            margin-bottom: 16px !important;
          }
        }
      }

      &__detailProducts--shipped {
        padding-top: 24px;
        position: relative;

        .dln-order {
          &__trackingCode {
            color: $brand-04;
            @include fontMedium;
            position: absolute;
            right: 24px;
            top: 24px;
            width: auto;
          }
          &__downloadLabel {
            color: $brand-04;
            @include fontMedium;
          }
          &__detailTitle {
            border: none;
            padding-top: 0;
            @include fontMedium;
          }
        }

        .dln-orderRev {
          &__content {
            justify-content: flex-start;
          }

          &__productCode {
            font-size: REM(14px);
            color: $ui-02;
            @include fontMedium;
            margin-bottom: 0;
          }
          &__detailsTitle {
            margin-bottom: 0;
          }

          &__itePic {
            width: 185px;
            height: 148px;
            margin-right: 11px;
          }

          &__quantity {
            color: $brand-04 !important;
          }

          &__wrap--main {
            .dln-price--oldPrice {
              font-size: REM(16px);
            }

            .dln-price--discounted {
              font-size: REM(19px);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    .dln-order--detail {
      .dln-order {
        &__content--lite {
          .dln-orderDetailHead {
            &__number {
              margin-bottom: 16px;
            }
          }
        }
        &__detailProducts--shipped {
          .dln-order {
            &__trackingCode {
              margin-top: 3px;
              margin-left: 0;
              position: static;
            }

            &__detailTitle {
              font-size: REM(18px);
              white-space: nowrap;
            }
          }

          .dln-orderRev {
            &__productCode {
              margin-top: 8px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    .dln-order--detail {
      .dln-order {
        &__content--lite {
          min-width: 0;
          width: 100%;
          max-width: none;
        }
      }
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-return-authorization {
        &.dln-registration .dln-wrapper {
            padding-top: 20px;
        }
        .dln-registration {
            

            &__content {
                .dln-title {
                    display: block;
                    font-size: 24px;
                    line-height: 1.13;

                    + .dln-registration__paragraph {
                        margin-bottom: 40px;
                    }
                }
            }

            &__section {
                width: 100%;
                margin: 32px 0 0 0;
                padding: 32px 0 0 0;
                display: block;
                border-top: 1px solid $ui-01;
                &--main {
                    margin: 0 0 0 0;
                    padding: 0 0 0 0;
                    border: none;
                }
                .dln-regAdv {
                    margin: 0 0 0;
                }
            }

            &__formFoot {
                margin: 48px 0 0;
                .dln-btn {
                    width: 100%;
                }
            }
        }

        .dln-profileCard,
        .dln-loginForm__section--registration {
            display: none;
        }
    }
}
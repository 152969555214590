.dln-kenwood {
    .dln-serviceLocator {
        .dln-wrapper {
            height: initial;
            min-height: initial;
        }
        &__title {
            text-align: center;
            font-size: 38px;
            line-height: 46px;
            @include fontExtra;
            text-transform: capitalize;
            margin: 35px auto 30px auto;
        }
        &__aside {
            .dln-inputs {
                &--searchBar {
                    width: calc(100% - 152px);
                }
                &__input {
                    height: 41px;
                }
                &__lbl {
                    display: none;
                }
            }
            .dln-btnTextIcon {
                height: 43px;
                width: 131px;
                margin-left: 20px;
                .dln-icn {
                    font-size: 20px;
                }
            }
            .dln-btn--reset {
                pointer-events: initial;
                .dln-btn__lbl {
                    text-decoration: none;
                }
            }
            .dln-accordion {
                background: none;
                &__icon {
                    font-size: 20px;
                    margin-left: 5px;
                }
                &__title {
                    font-size: 14px;
                    line-height: 20px;
                    @include fontRoman;
                    margin-left: 5px;
                    margin-right: 27px;
                    span {
                        @include fontRoman;
                    }
                }
                &__wrapTitle {
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 15px;
                }
                .dln-icn {
                    &--arrow-down {
                        font-size: 10px;
                    }
                }
                .dln-radioBtn {
                    margin-bottom: 10px;
                    &__lbl {
                        line-height: 15px;
                    }
                }
            }
        }
        &__map {
            margin-left: 20px;
            width: calc(100% - 386px - 20px);
            height: 533px;
        }

        .dln-quickLinks--slider {
            margin-top: 70px;
        }

        .dln-addressResult {
            padding: 15px 20px;
            border-bottom: 1px solid $ui-01;
            margin: 0 10px 0 0;
            &__head {
                margin: 0;
            }
            &__company {
                line-height: 16px;
            }
            &__distance {
                margin-left: 20px;
                padding-left: 20px;
                border-color: $ui-01;
            }
            &__pinIndex {
                background-image: url("#{$pathAssets}/images/icons/icon-pin-brand-01-kenwood.svg");
                width: 27px;
                height: 40px;
                background-size: 27px 40px;
                font-size: 14px;
            }
            &__productCategory {
                @include fontLight;
                font-size: 12px;
                line-height: 16px;
            }
            &__data {
                @include fontLight;
                span {
                    @include fontLight;
                }
            }
            &::after {
                background-color: transparent;
            }
        }
        .dln-mapPopup {
            padding: 20px 0 20px 15px;
            .dln-mapPopup__company {
                font-size: 14px;
                line-height: 20px;
            }
            .dln-mapPopup__distance {
                @include fontLight;
                font-size: 14px;
                line-height: 20px;
            }
            &__wrap {
                &--info {
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    .dln-mapPopup__data {
                        margin-bottom: 0;
                        font-size: 14px;
                        line-height: 20px;
                        &--address {
                            margin-bottom: 10px;
                        }
                    }
                }
                &--hours {
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    .dln-mapPopup__data {
                        @include fontLight;
                        line-height: 16px;
                    }
                }
                &--actions {
                    margin-bottom: 5px;
                    button,button:hover {
                        transition: none;
                    }
                    .dln-mapPopup__data {
                        @include fontLight;
                        line-height: 16px;
                    }
                    .dln-btn--googleMaps {
                        width: 100%;
                    }
                }
            }
        }
        @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
            &__map {
                width: 100%;
                margin: 0;
            }
            &__aside {
                .dln-inputs {
                    &--searchBar {
                        width: calc(100% - 218px);
                    }
                }
            }
        }
        @media only screen and (max-width:599px) {
            padding-top: 19px;
            .dln-serviceLocator__title,
            .dln-serviceLocator__description {
                margin: 0 0 38px 0;
            }
            &__aside {
                .dln-accordion {
                    &__icon {
                        font-size: 21px;
                        margin-top: -8px;
                    }
                    padding-top: 0;
                    border-top: 1px solid $ui-01;
                    border-bottom: 1px solid $ui-01;
                    &__head {
                        width: 100%;
                    }
                    &__content {
                        padding-top: 15px;
                    }
                }
                .dln-inputs {
                    &--searchBar {
                        width: 100%;
                        margin-left: 15px;
                        margin-bottom: 30px;
                    }
                }
                .dln-btnTextIcon {
                    margin-left: 16px;
                }
            }
            &__map {
                margin: 0;
                width: 100%;
            }


            .dln-addressResult {
                border: 0;
                padding: 15px 20px;
            }
            .dln-btnIcon__checkoutCounter {
                background-color: $brand-04;
            }
        }
    }
}

.dln-kenwood {
    .dln-privacy {
        &__sectionTitle {
            @include fontExtra;
            font-size: 28px;
            line-height: 36px;
        }
        &__paragraph {
            font-size: 14px;
        }
        &__radioGroup {
            &+.dln-privacy__paragraph {
                font-size: 12px
            }
        }
        &__subtitle {
            font-feature-settings: 14px;
        }
        &__section--advPreferences {
            margin: 0 0 40px 0;
        }
        @media screen and (max-width: 599px) {
            &__sectionTitle {
                font-size: 24px;
            }
        }
    }
}
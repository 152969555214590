.dln-prodReg {
    &--productDetails {
        .dln-prodReg {
            &__head {
                margin: 50px 0 0;
                max-width: none;
            }
            &__content {
                max-width: 1000px;
                margin: 60px auto 100px;
                display: flex;
                flex-wrap: wrap;
                >.dln-btn {
                    width: 200px;
                    margin: 0 0 0 auto;
                    &--link {
                        width: auto;
                        margin: 0 auto 0 0;
                        align-self: flex-end;
                    }
                }
                >.dln-prodReg__subTitle {
                    width: 100%;
                    max-width: 100%;
                    margin: 0 auto 0 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin: 0 0 30px 0;
                    
                    .dln-prodReg__lbl {
                        @include small;
                        font-size: 10px;
                        color: $brand-02;
                        margin: 0 0 0 auto;
                    }
                }
                .dln-prodReg__categoryBreadcrumbs {
                    width: auto;
                    max-width: calc(40%);
                    align-items: baseline;
                    align-content: baseline;
                }
            }
        }
        .dln-itemRegDetails {
            padding-bottom: 32px;
            margin: 0 0 32px;
            border-bottom: 1px solid $ui-01;

        }
    }

}
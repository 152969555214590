.dln-delonghi {
  .dln-addItem {
    max-width: 236px;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: flex-start;
    justify-content: space-between;

    .prices {
      display: flex;
      align-items: baseline;
    }

    &__title {
      font-size: REM(16px);
      @include fontMedium;
    }

    &__price {
      margin-top: 6px;

      .dlg-omnibus-price {
        flex-direction: column;
        align-items: flex-start;

        &__main-price {
          font-size: REM(19px);
        }

        &__old-price {
          font-size: REM(12px);
        }
      }

      .dln-price {
        &--oldPrice {
          font-size: REM(15px);
          color: $ui-02;
          margin-right: 0;

          &::after {
            background-color: $ui-02;
            top: 49%;
          }

          &-frequency {
            font-size: 14px;
            white-space: pre;

            &::after {
              top: unset;
              bottom: 51%;
            }
          }
        }

        &--discounted {
          font-size: REM(16px);
          color: $brand-04;
          font-weight: normal;
          margin-left: 26px;
        }
      }
    }

    &__content {
      transform: translateY(-3px);
    }

    .dln-btn.dln-btn {
      width: 100%;
      border: none;
      height: 32px;
      align-self: flex-end;

      &--light {
        background: var(--brand-01) !important;
        border: none;
      }

      i {
        display: none;
      }
    }

    .dln-btn.dln-btn--support02 {
      opacity: 0.8;
      background: $brand-03 !important;
      border: 1px solid $ui-02;

      .dln-btn__lbl {
        color: var(--support-02);
        font-weight: bold;
      }

      i {
        display: inline-block;
      }
    }

    &__picture {
      width: 73px;
      height: 61px;

      @media only screen and (max-width: 599px) {
        width: 80px;
        height: 63px;
      }
    }

    &--basic {
      height: auto;
      max-width: none;
      align-items: center;
    }

    @media only screen and (max-width: 599px) {
      .dln-btn.dln-btn--light.dln-btn--support02 {
        .dln-btn__lbl {
          font-size: REM(15px);
        }
      }
    }
  }
}

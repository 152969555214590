.dln-kenwood {
    .dln-regProductList {
        &--editing {

            @media only screen and (max-width:599px) {
                .dln-title {
                    margin: 30px 0 20px 0;
                }
            }
            .dln-title {
                margin: 0 0 22px 0;
            }
            .dln-regProductList__section {
                margin: 24px 0 46px 0;
            }
            .dln-regProductList__sectionFoot {
                padding: 28px 0 0;
                margin: 30px 0 0;
            }
        }
        & .dln-regProductList__section {
            & .dln-noticeBox  { 
                align-items: flex-start;
                padding: 50px 31px 44px 31px;

                &__icon {
                    width: 50px;
                    height: 50px;
                    margin: 0 20px 0 0;

                    & .dln-icn {
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 50px;
                    }
                }
                &__content {
                    width: calc(100% - 50px - 20px);
                }
            }
        }        
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-regProductList {
            & .dln-regProductList__section {
                & .dln-noticeBox  { 
                    padding: 40px 30px 40px 30px;
            
                    &__icon {
                        width: 50px;
                        height: 50px;
                        margin: 0 20px 0 0;
            
                        & .dln-icn {
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            font-size: 50px;
                        }
                    }
                    &:first-of-type {
                        & .dln-noticeBox__icon {
                            width: 50px;
                            height: 50px;
                            margin: 0 20px 0 0;
                
                            & .dln-icn {
                                width: 48px;
                                height: 50px;
                                line-height: 50px;
                                font-size: 48px;
                            }
                        }                        
                    }
                }
            }   
        }
    }
}
.dln-profileCard {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border: 1px solid $ui-01;
    background-color: $ui-03;
    padding: 10px;

    &__circle {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: $brand-01;
        margin: 0 10px 0 0;

        &Text {
            width: 100%;
            line-height: 2.6;
            font-size: 20px;
            @include fontBold;
            color: $brand-03;
            text-align: center;
        }
    }
    &__text {
        display: block;
        width: calc(100% - 52px - 10px);
        height: auto;
        line-height: 16px;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
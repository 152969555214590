.dln-kenwood {
  & .dln-popup {
    & .dln-popup__content {
      & .dln-popup__wrapper {
        & .dln-addAccessories {
          max-width: none;
          padding: 0 0 0 0;

          & .dln-popup__head {
            padding: 80px 40px 0 40px;
            margin: 0 0 28px 0;

            &Title {
              justify-content: center;
              width: 100%;
              line-height: 40px;
              font-size: 32px;
              text-align: center;
              padding: 0 0 0 0;
            }

            & .dln-btnIcon--circleClose {
              width: 22px;
              height: 22px;
              top: 18px;
              right: 20px;

              & .dln-btnIcon__wrap {
                width: 22px;
                height: 22px;

                & .dln-icn {
                  width: 22px;
                  height: 22px;
                  font-size: 0;

                  &::before {
                    @include fontIcon;
                    content: 'æ';
                    width: 22px;
                    height: 22px;
                    font-size: 22px;
                    color: $brand-01;
                  }
                }
              }
            }

            &Line {
              display: none;
            }
          }

          &__content {
            width: calc(100% - 200px);
            background-color: $brand-03;
            padding: 0;
            margin: 0 100px;
            margin: 0 auto;

            & .dln-addAccessories__subTitle {
              display: none;
            }

            & .dln-addItem {
              width: 50%;
              margin: 0 0 10px 0;

              &__picture {
                top: 10px;
              }
            }

            & .dln-cartItem {
              &__content {
                max-width: 430px;
                margin: 0 auto;
                flex-direction: row;
              }

              &__title {
                @include fontSemibold;
                line-height: 22px;
                font-size: 16px;
              }

              &__picture {
                width: auto;
                height: auto;
                max-height: none;
                margin-left: 0;

                & > img {
                  max-width: 100%;
                }
              }
            }

            &--extra {
              padding-bottom: 80px;

              .dln-addItem {
                align-items: flex-start;
                padding: 10px;
                border: none;
                padding-left: calc(10px + 184px + 20px);
                position: relative;

                &:hover {
                  border: none;
                }

                &__picture {
                  width: 184px;
                  height: auto;
                  margin-right: 20px;
                  position: absolute;
                  top: 10px;
                  left: 10px;

                  & > img {
                    display: block;
                    object-fit: contain;
                  }
                }

                &__content {
                  width: 100%;
                  padding-top: 10px;
                  margin-bottom: 16px;
                  margin-top: 10px;

                  + button {
                    bottom: 10px;
                  }
                }

                &__title {
                  display: block;
                  font-size: 15px;
                  margin-bottom: 8px;
                  position: relative;
                }

                .dln-btn {
                  align-self: flex-end;
                  width: 100%;
                  margin: 0 5px;

                  &--light {
                    display: flex;
                    justify-content: center;
                    border-color: $brand-01;
                    align-self: auto;
                    margin: 3px 0 0;
                    color: $brand-01;

                    .dln-icn {
                      width: 13px;
                      height: 13px;
                      font-size: 13px;
                      color: $brand-01;
                    }

                    .dln-btn__lbl {
                      @include fontBold;
                      margin: 0 5px;
                    }

                    &:hover {
                      background-color: $brand-01;
                      color: $brand-03;

                      .dln-btn__lbl,
                      .dln-icn {
                        color: $brand-03;
                      }
                    }

                    &.dln-btn--support02 {
                      color: $brand-01;

                      .dln-btn__lbl,
                      .dln-icn {
                        color: $brand-01;
                      }

                      &:hover {
                        background-color: $brand-01 !important;
                        color: $brand-03 !important;

                        .dln-btn__lbl,
                        .dln-icn {
                          color: $brand-03 !important;
                        }
                      }
                    }
                  }
                }

                & + .dln-btn {
                  margin: 34px auto 0 auto;
                }
              }
            }
          }

          &
            .dln-addAccessories__content:not(.dln-addAccessories__content--extra) {
            border-bottom: 1px solid $ui-01;
            padding: 11px 15px 19px;
            margin: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, lg)) {
  .dln-kenwood {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-addAccessories {
            max-width: none;
            padding: 0 0 0 0;

            & .dln-popup__head {
              padding: 27px 0 17px;
              margin: 0 40px;
              width: auto;

              &Title {
                justify-content: center;
                width: 100%;
                line-height: 40px;
                font-size: 18px;
                text-align: start;
                padding: 0 0 0 0;
              }

              & .dln-btnIcon--circleClose {
                width: 22px;
                height: 22px;
                top: 18px;
                right: 20px;

                & .dln-btnIcon__wrap {
                  width: 22px;
                  height: 22px;

                  & .dln-icn {
                    width: 22px;
                    height: 22px;
                    font-size: 0;

                    &::before {
                      @include fontIcon;
                      content: 'æ';
                      width: 22px;
                      height: 22px;
                      font-size: 22px;
                      color: $brand-01;
                    }
                  }
                }
              }

              &Line {
                display: none;
              }
            }

            &__content {
              width: calc(100% - 200px);
              background-color: $brand-03;
              padding: 0;
              margin: 0 100px;
              margin: 0 auto;

              & .dln-addAccessories__subTitle {
                display: none;
              }

              & .dln-addItem {
                width: 50%;
                margin: 0 0 10px 0;

                &__picture {
                  top: 10px;
                }
              }

              & .dln-cartItem {
                &__content {
                  max-width: 450px;
                  margin: 0 auto;
                  align-content: flex-start;
                  flex-direction: row;
                }

                &__title {
                  @include fontSemibold;
                  width: 50%;
                  align-self: flex-start;
                  line-height: 22px;
                  font-size: 16px;
                  margin-left: 41px;
                  margin-top: 15px;
                }

                &__picture {
                  width: auto;
                  height: auto;
                  max-height: none;
                  margin-left: 0;

                  & > img {
                    max-width: 100%;
                  }
                }
              }

              &--extra {
                padding: 30px 41px 24px;
                margin: 0;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 446px);

                .dln-addItem {
                  align-items: flex-start;
                  border: none;
                  padding: 0;
                  position: relative;
                  width: 100%;

                  &:hover {
                    border: none;
                  }

                  &__picture {
                    width: 184px;
                    height: auto;
                    margin-right: 20px;
                    position: relative;
                    top: 0;
                    left: 0;

                    & > img {
                      display: block;
                      object-fit: contain;
                    }
                  }

                  &__content {
                    width: 50%;
                    padding: 0;
                    margin: 0;
                    position: relative;

                    + button {
                      bottom: 10px;
                    }
                  }

                  &__title {
                    display: block;
                    font-size: 18px;
                    margin-bottom: 8px;
                    position: relative;
                  }

                  .dln-btn {
                    align-self: flex-end;
                    width: 100%;

                    &--light {
                      display: flex;
                      justify-content: center;
                      border-color: $brand-01;
                      align-self: auto;
                      margin: 13px 0 0;
                      color: $brand-01;

                      .dln-icn {
                        width: 13px;
                        height: 13px;
                        font-size: 13px;
                        color: $brand-01;
                      }

                      .dln-btn__lbl {
                        @include fontBold;
                        color: $brand-01;
                      }

                      &:hover {
                        background-color: $brand-01;
                      }
                    }

                    &.dln-btn--support02 {
                      i {
                        display: inline-block;
                      }
                    }
                  }

                  & + .dln-btn {
                    margin: 12px auto 0 auto;
                  }
                }
              }
            }

            &
              .dln-addAccessories__content:not(.dln-addAccessories__content--extra) {
              border-bottom: 1px solid $ui-01;
              padding: 12px 63px 19px;
              margin: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
  .dln-kenwood {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-addAccessories {
            & .dln-popup__head {
              padding: 17px 0 16px;
              margin: 0 21px;
              width: auto;

              &Title {
                justify-content: center;
                width: 100%;
                line-height: 40px;
                font-size: 15px;
                text-align: start;
                padding: 0;
              }
            }

            & .dln-addAccessories__content {
              width: 100%;
              margin: 0;
              padding: 30px 136px;
            }

            &__content {
              width: 100%;
              padding: 0 50px;

              &--extra {
                padding: 0 50px 80px 25px;
              }

              & .dln-cartItem__title {
                width: 50%;
                margin: 0 0;
                text-align: start;
                font-size: 18px;
              }

              & .dln-addItem {
                width: 100%;
                padding: 0;
                margin: 0 0 20px;

                &:hover {
                  border: none;
                }

                &:last-of-type {
                  margin: 0;
                }

                &__picture {
                  width: 98px;
                  height: auto;
                  left: 0;
                  top: 0;
                  position: relative;

                  & .dln-addItem__img {
                    width: 98px;
                    height: 80px;
                  }
                }

                &__content {
                  margin: 0;
                  padding: 0;
                  position: relative;
                  width: 50%;

                  & + .dln-btn {
                    &--support02 {
                      i {
                        display: inline-block;
                      }
                    }
                  }
                }

                & + .dln-btn {
                  margin-top: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-kenwood {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-addAccessories {
            & .dln-popup__head {
              padding: 22px 0 16px;
              margin: 0 20px;
              width: auto;

              &Title {
                line-height: 34px;
                font-size: 15px;
                text-align: start;
              }

              & .dln-btnIcon {
                top: 28px;
                right: 31px;
              }
            }

            &__content {
              width: calc(100% - 40px);
              padding-bottom: 24px;
              margin-bottom: 40px;

              & .dln-cartItem {
                &__picture {
                  width: 124px;
                  height: 103px;
                }

                &__img {
                  width: 216px;
                  height: 176px;
                }

                &__title {
                  text-align: start;
                  margin: 0 0 0 10px;
                  font-size: 15px;
                  align-self: flex-start;
                  width: 50%;
                }
              }
            }

            &__content--extra {
              width: 100%;
              padding: 32px 20px 23px;
              margin: 0;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;

              & .dln-addItem {
                width: 100%;
                padding: 0 0 0 0;
                margin: 0 0 32px;
                justify-content: space-between;
                align-items: flex-start;

                &:hover {
                  border: none;
                }

                &:last-of-type {
                  margin: 0;
                }

                &__picture {
                  width: 85px;
                  height: 71px;
                  left: 0;
                  position: relative;
                  margin: 0 4px 0 0;
                  top: 0px;

                  & .dln-addItem__img {
                    width: 98px;
                    height: 80px;
                  }
                }

                &__content {
                  width: calc(100% - 91px);
                  position: relative;
                  padding: 0;
                  margin: 0;
                  max-height: 70px;

                  & + .dln-btn {
                    &--support02 {
                      i {
                        display: inline-block;
                      }
                    }
                  }
                }

                & + .dln-btn {
                  margin: 24px 0 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
    .dln-order {
        &__content {
            .dln-title {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 599px) {
    .dln-delonghi {
        .dln-order {
            &__content {
                .dln-title {
                    text-align: left;
                }
            }
        }
    }
}

.dln-club-membership {
  &__section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__column {
    width: calc(50% - 60px);

    .dln-benefits-list {
      width: 100%;

      &__title {
        @include fontBold;
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 8px;
      }

      &__subtitle {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 20px
      }

      &__foot {
        margin: 30px 0 0;
      }
    }
  }

  &__form {
    width: 100%;
  }

  &__title {
    @include fontExtra;
    margin: 15px 0 25px;
  }

  &__paragraph {
    @include fontLight;
    margin: 0 0 19px;
  }

  &__input-wrapper {
    display: flex;
    width: 100%;
    height: 32px;
    margin: 0 0 39px;
  }
}

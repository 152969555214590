.dln-recipe-slides {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-top: 60px;
    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 25px;
    }
    &__title {
        @include fontBold;
        font-size: 36px;
        line-height: 0.78;

        a {
            @include fontBold;
            text-decoration: none;
            color: $brand-01;
        }
    }
    &__link {
        @include fontMedium;
        font-size: 15px;
        line-height: 1.4;
        color: $brand-01;
    }

    .swiper-container {
        position: relative;



        .swiper-pagination {
            display: none !important;
        }
        .swiper-button-next,
        .swiper-button-prev {
             position: relative !important;
             height: auto !important;
             display: block !important;
             padding: 0 !important;
        }

        .swiper-footer{
            &.-hidden{
                display: none!important;
            }
        }

        .swiper-slide{
            max-width: none;
            width: calc(25% - 7.5px)!important;
            margin: 0 10px 0 0;
        }

    }



}

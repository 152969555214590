.dln-kenwood {
    .dln-errorPage {
        // background: url("/assets/images/bg/bg-error-page-kenwood.png");
        // background-repeat: no-repeat;
        // background-position: left bottom;
        &__picture {
            content: none;
            bottom: 0;
            padding-top: 216px;
            background-color: $brand-03;

            & > img {
                object-fit: none;
                min-width: initial;
                object-position: left bottom;
                min-height: initial;
                right: initial;
            }
        }
        &__mainTitle {
            color: $brand-04;
            font-size: 38px;
            line-height: 46px;
            @include fontExtra;
            margin-bottom: 50px;
            span {
                color: $brand-04;
                font-size: 32px;
                line-height: 40px;
                @include fontExtra;
                padding-top: 31px;
            }
        }
        &__wrap {
            width: 100%;
        }
        &__mainText {
            color: $brand-04;
            font-size: 14px;
            line-height: 20px;
            @include fontLight;
            margin-top: 10px;
        }
        &__title {
            text-align: center;
            @include fontLight;
            font-size: 14px;
            line-height: 20px;
        }
        .dln-wrapper {
            &--main {
                text-align: center;
            }
            &--fullWidth {
                background-color: transparent;
                margin-bottom: 0;
            }
            &--content {
                z-index: 5;
                margin-bottom: 123px;
                max-width: 100%;
                .dln-btn {
                    &--backToHome {
                        margin: 0 10px 0 auto;
                    }
                    &--customerService {
                        margin: 0 auto 0 10px;
                    }
                }
            }
        }
        .dln-inputs {
            &--searchBar {
                margin: 20px auto 0 auto;
            }
            &__input {
                padding-left: 46px;
                line-height: 15px;
                @include fontRoman;
                color: $ui-10;
            }
        }
        @media only screen and (max-width:599px) {
            // background-position: center top;
            // background-size: contain;
            // padding-top: calc(52%);

            &__picture {
                position: relative;
                padding-top: 0;
                height: auto;
                margin-bottom: 12px;

                & > img {
                    object-fit: cover;
                    position: relative;
                    width: 100%;
                }
            }

            &__mainTitle {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 26px;
                span {
                    color: $brand-04;
                    font-size: 24px;
                    line-height: 32px;
                    padding-top: 30px;
                }
            }
            &__mainText {
                margin-top: 0;
                margin-bottom: 28px;
            }
            &__title {
                margin-bottom: 22px;
            }
            .dln-wrapper {
                &--fullWidth {
                    padding-bottom: 50px;
                }
                .dln-btn {
                    &--backToHome {
                        margin: 8px auto;
                    }
                    &--customerService {
                        margin: 0 auto;
                    }
                }
                &--content {
                    margin: 0 10px 70px 10px;

                }
            }
            .dln-inputs {
                &--searchBar {
                    margin: 0 auto;
                }
            }
        }
    }
}

.dln-kenwood {
    .dln-serviceLocator {
        &--results {
            .dln-serviceLocator__aside {
                .dln-serviceLocator__resultCounter {
                    font-size: 12px;
                    line-height: 16px;
                    display: block;
                }
            }
        }
    }
}
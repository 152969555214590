.dln-delonghi {
  .dln-serviceLocator {
    & > .dln-wrapper {
      max-width: map-get($grid-breakpoints, xl);
    }
    &__title {
      @include fontMedium;
      font-size: REM(50px);
      line-height: 1.04;
    }
    &__aside {
      .dln-btnTextIcon {
        background: $brand-04;
        border-color: $brand-04;
      }
      .dln-accordion {
        &__head {
          background: $ui-03;
          border: 0;
          margin-bottom: 0;
        }
        &__title {
          font-size: REM(16px);
          padding-top: 0;

          & span {
            @include fontBook;
          }
        }
        &__wrap {
          background: $ui-03;
        }
        .dln-tab__links {
          background: $ui-03;
        }
        & .dln-tabs {
          display: none;
        }
        &__content {
          & .dln-accordion__wrap {
            & .dln-radioBtn {
              &__lbl {
                color: $brand-04;

                &::before {
                  width: 22px;
                  height: 22px;
                  background-color: transparent;
                }
              }
              & .dln-radioBtn__input:checked {
                & + .dln-radioBtn__lbl {
                  color: $brand-04 !important;
                }
              }
            }
          }
        }
      }
    }
    &__resultCounter {
      font-size: REM(14px);
    }
    &__results {
      &#tab-results {
        & .dln-addressResult {
          border-bottom: 1px solid $ui-01;

          & .dln-addressResult__distance {
            border-left: 1px solid $brand-04;
          }
        }
      }
    }

    .dln-inputs--searchBar {
      width: calc(100% - 149px);
      margin: 0 0 16px 0;
    }

    .dln-btnTextIcon--nearMe {
        width: 131px;
        margin: 0 0 16px 16px;
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    & .dln-serviceLocator {
      &__map {
        width: 100%;
        margin: 0;
      }
      & .dln-accordion {
        &__head {
          width: 100%;
          box-sizing: border-box;
        }
        & .dln-tabs {
          display: inline-flex;
          width: auto;

          &__item {
            box-sizing: content-box;

            &:first-child {
              border-left: 4px solid $brand-03;
            }
            &--current {
              & .dln-tabs__btn {
                & .dln-icn {
                  color: $brand-04;
                }
              }
            }
          }
        }
      }
      &__mobileBnt {
        margin-left: 0;

        .dln-btnIcon__checkoutCounter {
          display: flex;
          position: absolute;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          font-size: REM(8px);
          background: $brand-04;
        }
      }
      .dln-inputs--searchBar {
        width: calc(100% - 191px);
        margin: 0 20px 24px 20px;
      }
      .dln-btnTextIcon--nearMe {
        width: 131px;
        margin: 0 20px 24px 0;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-serviceLocator {
      & > .dln-wrapper {
        & .dln-serviceLocator__title {
          margin-bottom: 16px;
        }
      }
      &__aside {
        & .dln-inputs {
          width: calc(100% - 40px);
          margin: 0 20px 10px 20px;
        }
        & .dln-btnTextIcon--nearMe {
          width: 33.33%;
          min-width: 123px;
          margin: 0 auto 24px 20px;
        }
      }
      & .dln-breadcrumbs {
        padding: 10px 20px;
      }
      &__aside {
        .dln-btnTextIcon {
          background: $brand-04;
          border-color: $brand-04;
        }
        .dln-accordion {
          border-bottom: none;

          &__head {
            width: 100%;
            box-sizing: border-box;
          }
          &__title {
            @include fontBook;
          }
          & .dln-tabs {
            display: inline-flex;
            width: auto;

            &__item {
              box-sizing: content-box;

              &:first-child {
                border-left: 4px solid $brand-03;
              }
              &--current {
                & .dln-tabs__btn {
                  & .dln-icn {
                    color: $brand-04;
                  }
                }
              }
            }
          }
          &__wrap {
            padding-top: 0;
          }
        }
      }
      &__mobileBnt {
        margin-left: 0;

        .dln-btnIcon__checkoutCounter {
          display: flex;
          position: absolute;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          font-size: REM(8px);
          background: $brand-04;
        }
      }
      & .dln-quickLinks--slider {
        margin-top: 40px;
        justify-content: center;

        & > .dln-wrapper {
          // width: 100%;
          // box-sizing: border-box;
          // padding: 0 20px;
        }
        & .dln-quickLink {
          &__title {
            font-size: REM(16px);
          }
          &__link {
            font-size: REM(14px);
          }
        }
      }

      .dln-btnTextIcon--nearMe {
        width: 132px;
      }
    }
  }
}

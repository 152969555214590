@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-address--editing {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 32px 0;
        }
        & .dln-address__content {
            & .dln-btn--address {
                margin: 32px 0 10px 0;
            }
        }
    }

}
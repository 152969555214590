.dln-kenwood{
    .dln-thankYou{
        .dln-wrapper {
            >.dln-thankYou__content {
                border: none;
                display: flex;
                padding: 0 75px;
                margin: 24px 75px 0;
                justify-content: space-between;
            }
            &--fullWidth {
                margin-top: 60px;
                margin-bottom: 40px;
                border-top: 1px solid $ui-01;
                border-bottom: 1px solid $ui-01;
                background-color: var(--ui-14);
                justify-content: center;
            }
        }
        & .dln-thankYou__successMess {
            padding: 12px 24px 46px;
            margin: 0;
            border: none;
            width: 100%;
            
            & .dln-thankYou__title {
                @include fontMedium;
                line-height: 30px;
                font-size: REM(24px);
                margin: 29px 0 16px;
            }
            & .dln-thankYou__paragraph {
                line-height: 19px;
                font-size: REM(13px);
                margin-bottom: 36px;
                
                & > a {
                    @include fontBook;
                    text-decoration: none;
                }
            }
            .dln-icn{
                margin: 0;
                color: var(--brand-01);
                border: 2px solid var(--brand-01);
                border-radius: 50%;
                width: 77px;
                height: 55px;
                padding-top: 22px;
            }
        }
        & .dln-thankYou__orderNr {
            & .dln-thankYou__orderInfo {
                width: 100%;
                line-height: 22px;
                font-size: REM(18px);
                margin-bottom: 60px;
                text-align: center;
                padding: 0;
                border: none;
                
                & span {
                    font-size: REM(15px);
                    line-height: 22px;
                }
                &--user {
                    line-height: 22px;
                    font-size: REM(15px);
                    padding: 0;
                    margin-bottom: 0px;
                    
                    a{
                        color: var(--brand-01);
                    }
                }
            }
            & > .dln-btn {
                margin-bottom: 40px;
            }
        }
        
        & .dln-wrapper{
            
            &#mainContent {
                border: none;
                margin-top: 34px;
                margin-bottom: 24px;
                
                & .dln-thankYou__innerWrap {
                    margin: 0 60px 40px;
                    padding: 0 60px;
                    
                    & .dln-thankYou__orderDetails {
                        padding: 0;
                        margin: 0;
                        background-color: var(--ui-14);
                        & .dln-thankYou__content {
                            &--orderSummary {
                                margin: 23px 0 0;
                                
                                & .dln-thankYou__subTitle {
                                    font-size: REM(18px) !important;
                                    line-height: 22px;
                                    margin-top: 0;
                                    padding-top: 0px;
                                    margin-bottom: 0;
                                    font-weight: bold;
                                    
                                    &InnerWrap{
                                        i,a{
                                            color: var(--brand-01);
                                        }
                                    }
                                }
                                
                                & .dln-thankYou__items{
                                    font-size: REM(15px);
                                    line-height: 22px;
                                    color: var(--ui-13);
                                    
                                    p{
                                        margin: 0;
                                    }
                                }
                                
                                & .dln-orderRev {
                                    
                                    &.dln-cartItem__quantity{
                                        font-size: REM(13px);
                                        color: $ui-02;
                                        margin-top: 13px;
                                    }
                                    
                                    &--cart-order {
                                        border-bottom-color: $ui-01;
                                        padding: 32px 0 36px;
                                        margin-top: 15px;
                                        align-items: flex-start;
                                        &:hover{
                                            border-top-color: $ui-01;
                                        }
                                        &.dln-orderRev__itemPic {
                                            margin-right: 15px;
                                        }
                                        
                                        & .dln-orderRev__content {
                                            width: calc(100% - 200px);
                                            
                                            & .dln-orderRev__details {
                                                & .dln-orderRev__wrap--main {
                                                    padding-left: 0px;
                                                    display: flex;
                                                    flex-direction: unset;
                                                    
                                                    & .dln-orderRev__detailsTitle {
                                                        font-size: REM(18px);
                                                        line-height: 22px;
                                                    }
                                                    & .dln-orderRev__advice {
                                                        margin-bottom: 21px;
                                                    }
                                                    & .dln-price {
                                                        flex: initial;
                                                        width: auto;
                                                        font-size: REM(15px);
                                                        line-height: 22px;
                                                        
                                                        &--oldPrice {
                                                            font-size: REM(15px);
                                                            margin: 0 13px 0 0;
                                                            color: $ui-02;
                                                            max-width: fit-content;
                                                        }
                                                        &--discounted {
                                                            margin-top: 0;
                                                            font-size: REM(15px);
                                                        }
                                                    }
                                                    .dln-cartItem__label{
                                                        font-size: REM(15px);
                                                        line-height: 22px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &--delivery {
                                margin-bottom: 24px;
                                margin-top: 50px;
                                padding: 0;
                                width: calc(33.333% - 27px);
                                
                                & .dln-thankYou__subTitle {
                                    line-height: 22px;
                                    font-size: REM(18px);
                                    margin-bottom: 27px;
                                    font-weight: bold;
                                }
                                & .dln-addressBox {
                                    border: 1px solid var(--ui-12);
                                    padding: 14px 22px 24px 20px;
                                    margin-bottom: 24px;
                                    background-color: var(--ui-14);
                                    
                                    & .dln-addressBox__data {
                                        font-size: 13px;
                                        color: var(--ui-13);
                                        &:first-child {
                                            @include fontBook;
                                        }
                                    }
                                    &:last-child {
                                        margin-bottom: 24px;
                                        & .dln-addressBox__data b {
                                            @include fontBook;
                                        }
                                    }
                                    &__divider {
                                        display: none;
                                    }
                                }
                            }
                            &--payment {
                                width: calc(33.333% - 27px);
                                padding-bottom: 10px;
                                margin-bottom: 23px;
                                margin-top: 50px;
                                & .dln-thankYou__subTitle {
                                    font-size: REM(18px);
                                    line-height: 22px;
                                    margin-bottom: 27px;
                                    font-weight: bold;
                                }
                                & .dln-addressBox {
                                    background-color: var(--ui-14);
                                    border: 1px solid var(--ui-12);
                                    padding: 20px 51px 14px 20px;
                                    
                                    & .dln-addressBox__data {
                                        line-height: 18px;
                                        font-size: 13px;
                                        color: var(--ui-13);
                                        
                                        &:first-of-type {
                                            @include fontMedium;
                                        }
                                    }
                                }
                            }
                            &--aside {
                                margin-top: 50px;
                                width: calc(33.333% - 27px);
                                & .dln-thankYou__subTitle{
                                    margin-bottom: 5px;
                                    line-height: 22px;
                                    font-size: REM(18px);
                                    font-weight: bold;
                                    margin-bottom: 27px;
                                }
                                
                                & .dln-orderSumm {
                                    &__content {
                                        background-color: var(--ui-14);
                                        &Row {
                                            &--subtotal {
                                                border-top: none
                                            }
                                            &--promo {
                                                .dln-orderSumm__rowTitle{
                                                    @include fontRoman;
                                                    font-size: REM(13px);
                                                }
                                            }
                                            & .dln-price {
                                                @include fontBook;
                                                font-size: REM(13px);
                                                
                                                &--oldPrice {
                                                    font-size: REM(15px);
                                                }
                                                &--secondary {
                                                    font-size: REM(14px);
                                                }
                                            }
                                            &--total {
                                                border-top: 1px solid var(--ui-13);
                                                border-bottom: 1px solid var(--ui-13);
                                                & .dln-orderSumm__rowTitle,
                                                & .dln-price--total {
                                                    @include fontBold;
                                                    font-size: REM(18px) !important;
                                                    color: var(--brand-04)!important;
                                                }
                                            }
                                        }
                                    }
                                    &__rowTitle {
                                        color: var(--ui-13)!important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)){
    .dln-kenwood{
        .dln-thankYou{
            .dln-wrapper {
                >.dln-thankYou__content {
                    border: none;
                    display: flex;
                    padding: 0 2px;
                    margin: 24px 2px 0;
                    justify-content: space-between;
                }
                &--fullWidth {
                    margin-top: 60px;
                    margin-bottom: 40px;
                    border-top: 1px solid $ui-01;
                    border-bottom: 1px solid $ui-01;
                    background-color: var(--ui-14);
                }
                & .dln-thankYou__orderNr {
                    & .dln-thankYou__orderInfo {
                        width: 100%;
                        line-height: 29px;
                        font-size: REM(21px);
                        margin-bottom: 64px;
                        text-align: center;
                        padding: 0;
                        border: none;
                        
                        & span {
                            font-size: REM(13px);
                            line-height: 19px;
                        }
                        &--user {
                            line-height: 19px;
                            font-size: REM(13px);
                            padding: 0;
                            margin-bottom: 0px;
                            
                            a{
                                color: var(--brand-01);
                            }
                        }
                    }
                    & > .dln-btn {
                        margin-bottom: 40px;
                    }
                }
                & .dln-thankYou__successMess {
                    padding: 16px 24px 0;
                    margin: 0;
                    border: none;
                    width: 100%;
                    
                    & .dln-thankYou__title {
                        @include fontMedium;
                        line-height: 22px;
                        font-size: REM(18px);
                        margin: 35px 0 24px;
                    }
                    & .dln-thankYou__paragraph {
                        line-height: 19px;
                        font-size: REM(13px);
                        margin-bottom: 30px;
                        
                        & > a {
                            @include fontBook;
                            text-decoration: none;
                        }
                    }
                }
            }
            & .dln-wrapper{
                
                &#mainContent {
                    border: none;
                    margin-top: 24px;
                    margin-bottom: 24px;
                    
                    & .dln-thankYou__innerWrap {
                        margin: 0 10px 40px;
                        padding: 0 10px;
                        
                        & .dln-thankYou__orderDetails {
                            padding: 0;
                            background-color: var(--ui-14);
                            & .dln-thankYou__content {
                                &--orderSummary {
                                    margin: 23px 0 15px;
                                    border-bottom: none;
                                    
                                    & .dln-thankYou__subTitle {
                                        font-size: REM(16px) !important;
                                        line-height: 19px;
                                        margin-top: 0;
                                        padding-top: 0px;
                                        margin-bottom: 0;
                                        
                                        &InnerWrap{
                                            i,a{
                                                color: var(--brand-01);
                                            }
                                        }
                                    }
                                    
                                    & .dln-thankYou__items{
                                        font-size: REM(13px);
                                        line-height: 19px;
                                        color: var(--ui-02);
                                        
                                        p{
                                            margin: 0;
                                        }
                                    }
                                    
                                    & .dln-orderRev {
                                        
                                        &.dln-cartItem__quantity{
                                            font-size: REM(13px);
                                            color: $ui-02;
                                            margin-top: 13px;
                                        }
                                        
                                        &--cart-order {
                                            padding: 0 0 8px 0 ;
                                            margin-top: 10px;
                                            align-items: flex-start;
                                            border-top: none;
                                            border-bottom: 1px solid var(--ui-12);
                                            
                                            &.dln-orderRev__itemPic {
                                                margin-right: 15px;
                                            }
                                            
                                            & .dln-orderRev__content {
                                                width: calc(100% - 100px - 20px) !important;
                                                
                                                & .dln-orderRev__details {
                                                    & .dln-orderRev__wrap--main {
                                                        padding-left: 0px;
                                                        display: flex;
                                                        flex-direction: unset;
                                                        
                                                        & .dln-orderRev__detailsTitle {
                                                            font-size: REM(15px);
                                                            line-height: 19px;
                                                        }
                                                        & .dln-orderRev__advice {
                                                            margin-bottom: 21px;
                                                        }
                                                        & .dln-price {
                                                            flex: initial;
                                                            width: auto;
                                                            font-size: REM(13px);
                                                            
                                                            &--oldPrice {
                                                                font-size: REM(13px);
                                                                margin: 0 13px 0 0;
                                                                color: $ui-02;
                                                                max-width: fit-content;
                                                            }
                                                            &--discounted {
                                                                margin-top: 0;
                                                                font-size: REM(13px);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &--delivery {
                                    border-bottom: 1px solid var(--ui-12);
                                    margin-bottom: 24px;
                                    margin-top: 0;
                                    padding: 0;
                                    width: 100%;
                                    
                                    & .dln-thankYou__subTitle {
                                        line-height: 24px;
                                        font-size: REM(15px);
                                        margin-bottom: 6px;
                                        font-weight: bold;
                                    }
                                    & .dln-addressBox {
                                        border: none;
                                        padding: 0 !important;
                                        margin-bottom: 24px;
                                        background-color: var(--ui-14);
                                        
                                        & .dln-addressBox__data {
                                            font-size: 13px;
                                            color: var(--ui-13);
                                            &:first-child {
                                                @include fontBook;
                                            }
                                        }
                                        &:last-child {
                                            margin-bottom: 24px;
                                            & .dln-addressBox__data b {
                                                @include fontBook;
                                            }
                                        }
                                        &__divider {
                                            display: none;
                                        }
                                    }
                                }
                                &--payment {
                                    width: 100%;
                                    padding-bottom: 0;
                                    border-bottom: 1px solid var(--ui-12);
                                    margin: 0 0 23px;
                                    & .dln-thankYou__subTitle {
                                        font-size: REM(15px);
                                        margin-bottom: 12px;
                                        font-weight: bold;
                                    }
                                    & .dln-addressBox {
                                        background-color: var(--ui-14);
                                        border: none;
                                        padding: 0 !important;
                                        
                                        & .dln-addressBox__data {
                                            line-height: 18px;
                                            font-size: 13px;
                                            color: var(--ui-13);
                                            
                                            &:first-of-type {
                                                @include fontMedium;
                                            }
                                        }
                                    }
                                }
                                &--aside {
                                    width: 100%;
                                    margin-top: 3px;
                                    & .dln-thankYou__subTitle{
                                        margin-bottom: 5px;
                                        font-weight: bold;
                                        font-size: 15px;
                                        line-height: 22px;
                                    }
                                    
                                    & .dln-orderSumm {
                                        &__content {
                                            background-color: var(--ui-14);
                                            &Row {
                                                &--subtotal {
                                                    border-top: none
                                                }
                                                &--promo {
                                                    .dln-orderSumm__rowTitle{
                                                        @include fontRoman;
                                                        font-size: REM(13px);
                                                    }
                                                }
                                                & .dln-price {
                                                    @include fontBook;
                                                    font-size: REM(13px);
                                                    
                                                    &--oldPrice {
                                                        font-size: REM(15px);
                                                    }
                                                    &--secondary {
                                                        font-size: REM(14px);
                                                    }
                                                }
                                                &--total {
                                                    border-top: 1px solid var(--ui-13);
                                                    border-bottom: 1px solid var(--ui-13);
                                                    & .dln-orderSumm__rowTitle,
                                                    & .dln-price--total {
                                                        @include fontBold;
                                                        font-size: REM(18px) !important;
                                                        color: var(--brand-04)!important;
                                                    }
                                                }
                                            }
                                        }
                                        &__rowTitle {
                                            color: var(--ui-13)!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }   
    }
}

@media only screen and (max-width: 599px) {
    .dln-kenwood{
        .dln-thankYou{
            .dln-wrapper {
                >.dln-thankYou__content {
                    border: none;
                    display: flex;
                    padding: 0;
                    margin: 0;
                    justify-content: space-between;
                }
                &--fullWidth {
                    margin-top: 0px;
                    margin-bottom: 40px;
                    border-top: 1px solid $ui-01;
                    border-bottom: 1px solid $ui-01;
                    background-color: var(--ui-14);
                }
                & .dln-thankYou__successMess {
                    padding: 46px 24px 24px;
                    margin: 0;
                    border: none;
                    width: 100%;
                    
                    & .dln-thankYou__title {
                        @include fontMedium;
                        font-weight: bold;
                        line-height: 22px;
                        font-size: REM(18px);
                        margin: 17px 0 36px;
                    }
                    & .dln-thankYou__paragraph {
                        line-height: 19px;
                        font-size: REM(13px);
                        margin-bottom: 28px;
                        
                        & > a {
                            @include fontBook;
                            text-decoration: none;
                        }
                    }
                    .dln-icn{
                        font-size: 32px;
                        color: var(--brand-01);
                        margin: 0;
                        border: 2px solid var(--brand-01);
                        border-radius: 50%;
                        width: 62px;
                        height: 46px;
                        padding-top: 16px;
                    }
                }
                & .dln-thankYou__orderNr {
                    & .dln-thankYou__orderInfo {
                        width: 100%;
                        line-height: 19px;
                        font-size: REM(15px);
                        margin-bottom: 28px;
                        text-align: center;
                        padding: 0;
                        border: none;
                        
                        & span {
                            font-size: REM(13px);
                        }
                        &--user {
                            line-height: 19px;
                            font-size: REM(13px);
                            padding: 0;
                            margin-bottom: 0px;
                            
                            a{
                                color: var(--brand-01);
                            }
                        }
                    }
                    & > .dln-btn {
                        margin-bottom: 40px;
                    }
                }
            }
            & .dln-wrapper{
                
                &#mainContent {
                    border: none;
                    margin-top: 24px;
                    margin-bottom: 24px;
                    
                    & .dln-thankYou__innerWrap {
                        margin: 0 10px 40px;
                        padding: 0 10px;
                        
                        & .dln-thankYou__orderDetails {
                            padding: 0;
                            background-color: var(--ui-14);
                            & .dln-thankYou__content {
                                &--orderSummary {
                                    margin-bottom: 12px !important;
                                    margin-top: 0;
                                    
                                    & .dln-thankYou__subTitle {
                                        font-size: REM(15px) !important;
                                        line-height: 19px;
                                        margin-top: 0;
                                        padding-top: 26px;
                                        margin-bottom: 0;
                                        
                                        &InnerWrap{
                                            i,a{
                                                color: var(--brand-01);
                                            }
                                        }
                                    }
                                    
                                    & .dln-thankYou__items{
                                        font-size: REM(13px);
                                        line-height: 19px;
                                        color: var(--ui-02);
                                        
                                        p{
                                            margin: 6px 0 0;
                                            font-size: 13px;
                                        }
                                    }
                                    
                                    & .dln-orderRev {
                                        
                                        &.dln-cartItem__quantity{
                                            font-size: REM(13px);
                                            color: $ui-02;
                                            margin-top: 13px;
                                        }
                                        
                                        &--cart-order {
                                            border-bottom-color: $ui-01;
                                            padding: 0 0 18px 0 !important;
                                            margin-top: 20px;
                                            align-items: flex-start;
                                            
                                            &.dln-orderRev__itemPic {
                                                margin-right: 15px;
                                            }
                                            
                                            & .dln-orderRev__content {
                                                width: calc(100% - 100px - 20px) !important;
                                                
                                                & .dln-orderRev__details {
                                                    & .dln-orderRev__wrap--main {
                                                        padding-left: 0px;
                                                        display: flex;
                                                        flex-direction: unset;
                                                        
                                                        & .dln-orderRev__detailsTitle {
                                                            font-size: REM(15px);
                                                            line-height: 19px;
                                                        }
                                                        & .dln-orderRev__advice {
                                                            margin-bottom: 21px;
                                                        }
                                                        & .dln-price {
                                                            flex: initial;
                                                            width: auto;
                                                            font-size: REM(13px);
                                                            
                                                            &--oldPrice {
                                                                font-size: REM(13px);
                                                                margin: 0 13px 0 0;
                                                                color: $ui-02;
                                                                max-width: fit-content;
                                                            }
                                                            &--discounted {
                                                                margin-top: 0;
                                                                font-size: REM(13px);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                &--delivery {
                                    border-bottom: 1px solid $ui-01;
                                    margin-bottom: 24px;
                                    padding: 0;
                                    width: 100%;
                                    margin-top: 13px;
                                    
                                    & .dln-thankYou__subTitle {
                                        line-height: 24px;
                                        font-size: REM(15px);
                                        margin-bottom: 6px;
                                    }
                                    & .dln-addressBox {
                                        border: none;
                                        border-bottom: none;
                                        padding: 0 !important;
                                        margin-bottom: 24px;
                                        background-color: var(--ui-14);
                                        
                                        & .dln-addressBox__data {
                                            font-size: 13px;
                                            color: var(--ui-13);
                                            &:first-child {
                                                @include fontBook;
                                            }
                                        }
                                        &:last-child {
                                            margin-bottom: 24px;
                                            & .dln-addressBox__data b {
                                                @include fontBook;
                                            }
                                        }
                                        &__divider {
                                            display: none;
                                        }
                                    }
                                }
                                &--payment {
                                    width: 100%;
                                    margin: 0;
                                    padding-bottom: 24px;
                                    border-bottom: 1px solid var(--ui-01);
                                    & .dln-thankYou__subTitle {
                                        font-size: REM(15px);
                                        margin-bottom: 16px;
                                    }
                                    & .dln-addressBox {
                                        background-color: var(--ui-14);
                                        border: none;
                                        padding: 0 !important;
                                        
                                        & .dln-addressBox__data {
                                            line-height: 18px;
                                            font-size: 13px;
                                            color: var(--ui-13);
                                            
                                            &:first-of-type {
                                                @include fontMedium;
                                            }
                                        }
                                    }
                                }
                                &--aside {
                                    width: 100%;
                                    margin-top: 0;
                                    & .dln-thankYou__subTitle{
                                        margin: 24px 0 5px;
                                        font-size: REM(15px);
                                    }
                                    
                                    & .dln-orderSumm {
                                        &__content {
                                            background-color: var(--ui-14);
                                            &Row {
                                                /*&--subtotal {
                                                    padding: ?;
                                                }*/
                                                &--promo {
                                                    .dln-orderSumm__rowTitle{
                                                        @include fontRoman;
                                                        font-size: REM(13px);
                                                    }
                                                }
                                                & .dln-price {
                                                    @include fontBook;
                                                    font-size: REM(13px);
                                                    
                                                    &--oldPrice {
                                                        font-size: REM(15px);
                                                    }
                                                    &--secondary {
                                                        font-size: REM(14px);
                                                    }
                                                }
                                                &--total {
                                                    & .dln-orderSumm__rowTitle,
                                                    & .dln-price--total {
                                                        @include fontBold;
                                                        font-size: REM(18px) !important;
                                                        color: var(--brand-04)!important;
                                                    }
                                                }
                                            }
                                        }
                                        &__rowTitle {
                                            color: var(--ui-13)!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

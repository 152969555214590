.dln-delonghi {
  .dln-orderInfoCard {
    &__number {
      display: inline-block;
      color: $brand-04;
      font-size: REM(21px);
      @include fontMedium;

      span.del-icon.icon-recurring {
        height: 18px;
        width: 18px;
        margin-left: 5px;
      }
    }

    &__cellData {
      font-weight: normal;
      margin-top: 4px;
      @include fontSemibold;
    }

    &__viewDetail {
      color: $brand-04;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-orderInfoCard {
      &__number {
        font-size: REM(18px);

        span.del-icon.icon-recurring {
          height: 16px;
          width: 16px;
        }
      }
      &__cellData {
        margin-top: 0;
        font-size: REM(14px);
      }

      &__cellTitle,
      &__viewDetail {
        font-size: REM(14px);
      }
    }
  }
}

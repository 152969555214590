@media only screen and (max-width:599px) {
    .dln-prodReg {
        .dln-timeline {
            max-width: calc(100% - 60px);
            margin-left: auto;
            margin-right: auto;
        }
        &--search {
            .dln-prodReg {
                &__content {
                    max-width: 100%;
                    margin-bottom: 100px;
                    position: relative;
                }
                &__searchModel {
                    display: flex;
                    flex-wrap: wrap;
                    .dln-inputs {
                        max-width: 100%;
                        margin: 0 0 20px 0;
                    }

                }
                &__inner {
                    margin: 16px 0 0;
                    min-height: 213px;
                    .dln-prodReg__categoryWrap {
                        &.swiper-wrapper {
                            flex-wrap: nowrap;
                        }
                    }
                    .dln-categoryBox {
                        width: 50%;
                        max-width: none;
                        &.swiper-slide {
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            max-width: none;
                            &:nth-of-type(even) {
                                margin: 0 0 0;
                            }
                            &:hover {
                                border: none;
                            }
                        }
                    }
                }
            }
            // .dln-tab {
            //     overflow: auto;
            //     overflow-y: hidden;
            //     -webkit-overflow-scrolling: touch;
            //     display: block;

            //     & > ul {
            //         display: table;
            //         font-size: 0;
            //         overflow-x: scroll;
            //         white-space: nowrap;
            //     }
            //     &__content {
            //         display: block;
            //         position: absolute;
            //         width: 100%;
            //         height: auto;
            //         &--active {
            //             position: relative;
            //         }
            //         .dln-prodReg__subTitle {
            //             font-size: 15px;
            //         }
            //     }
            //     &__links {
            //         @include small;
            //         font-size: 15px;
            //         height: 18px;
            //         white-space: nowrap;
            //         text-overflow: ellipsis;
            //         overflow: hidden;
            //     }
            // }
            // .dln-tab .dln-tooltip {
            //     margin: 8px 0 0 0;

            //     &__anchor {
            //         font-size: 10px;
            //         color: $ui-02;
            //         text-decoration: underline;
            //     }
            // }
        }
    }
}
.dln-delonghi {
  .dln-popup {
    &__content {
      .dln-popup__wrapper {
        .dln-addedItem {
          .dln-popup__head.dln-popup__head--circleClose.dln-popup__head--support02 {
            padding-top: 28px;

            .dln-popup__headLine {
              background: $ui-01;
            }

            .dln-popup__headTitle {
              font-size: REM(16px);
              transform: translateY(-5px);
              transform: translateX(-16px);
              padding: 0;

              .dln-icn {
                font-size: REM(13px);
                margin-top: 3px;
              }
            }
          }

          &__content {
            border-bottom: 1px solid $ui-01;

            .dln-cartItem {
              .dln-cartItem__btn {
                &--stroked {
                  background-color: var(--brand-04) !important;
                }
              }

              &__content {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 0;

                @media only screen and (min-width: 599px) and (max-width: 1199px) {
                  padding: 20px 40px;
                }
              }

              &__figure {
                display: flex;
                max-width: 70%;
              }

              &__picture {
                display: flex;
                order: 1;
                align-items: flex-start;
                width: 112px;
                height: 95px;
                position: relative;
                top: 0;
              }

              &__title {
                order: 2;
                align-self: flex-start;
                padding: 0;
                margin: 0;
                margin-left: 25px;
                width: auto;
                max-width: 80%;
                font-size: REM(17px);
              }

              &__priceBox {
                max-width: 30%;

                .dln-cartItem__price {
                  display: flex;
                  flex-direction: column;
                  width: auto;

                  .prices {
                    flex-direction: column;
                  }
                }
              }

              &__free-gift {
                display: flex;
                align-items: center;
                font-family: var(--fontNameBold);
                font-size: REM(12px);
                line-height: 12px;
                padding: 5px;
                padding-top: 7px;
                background-color: var(--brand-09);
                color: var(--brand-03);
                text-align: center;
                border-radius: 2px;
              }

              &__price {
                order: 3;
                align-self: flex-start;
                padding: 0;
                margin: 0;
              }

              &__quantity {
                display: none;
              }

              .dln-csmDropdown {
                order: 5;
                max-width: 334px;
                height: 40px;
                margin: 0;
                position: absolute;
                top: 55px;
                left: 137px;

                &__dropdown {
                  height: 40px;
                  box-sizing: border-box;
                }

                &__selected {
                  font-size: REM(14px);
                }

                .dln-price.dln-price--free {
                  font-weight: normal;
                }
              }

              &__btn {
                order: 6;

                .dln-btn:last-of-type {
                  background: $brand-01 !important;
                }

                .dln-notification {
                  flex-wrap: nowrap;

                  &__desc {
                    b {
                      font-weight: normal;
                    }
                  }

                  .dln-btnIcon__checkoutCounter {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background: $brand-01;

                    i {
                      font-weight: bold;
                      color: $brand-03;
                    }
                  }
                }

                .dln-btn.dln-btn--stroked {
                  background-color: #000;
                  color: $brand-03;
                  border: none;
                }
              }
            }

            &--extra {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              background: $brand-03;

              &-padding-top {
                padding-top: 20px;
              }

              .dln-addedItem__subTitle {
                @include fontMedium;
                font-size: REM(16px);
                color: $brand-04;
                margin-top: 20px;

                @media only screen and (min-width: 1200px) {
                  margin-top: 0;
                }
              }
            }

            &--show-additional-bundle {
              text-align: center;
              display: block;
              margin: 20px auto;
              border: 0;
              background-color: transparent;
              outline: none;
              text-decoration: underline;
              cursor: pointer;
            }

            .dln-price {
              &--discounted {
                margin-left: 6px;
              }
            }

            .dln-addItem__discount-message,
            .dln-addItem__discount-message p {
              font-weight: bold;
              margin-top: 10px;
            }

            .dln-addItem__subscription-description {
              font-size: REM(14px);
              margin-top: 13px;
              line-height: 1.625;

              ul {
                list-style: inside;
              }
            }

            .dln-addItem__subscription-bundle-features {
              font-size: REM(11px);
              margin-top: 13px;
              line-height: 1.625;

              ul {
                list-style: inside;
                line-height: 1.625;
              }

              p {
                line-height: 1.625;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    .dln-popup {
      &__content {
        .dln-popup__wrapper {
          .dln-addedItem {
            .dln-popup__head.dln-popup__head--circleClose.dln-popup__head--support02 {
              padding-top: 16px;
            }

            &__content {
              & .dln-cartItem {
                &__priceBox {
                  & .dln-cartItem {
                    &__price {
                      flex-direction: row;
                    }
                  }
                }
              }
            }

            .dln-addItem {
              max-width: 70%;
              height: 106px;

              &.dln-addItem--bundle-reference {
                height: auto;

                .prices {
                  white-space: pre;

                  .dln-price {
                    margin-right: 0;

                    &.dln-price--oldPrice {
                      margin-left: 0;
                    }
                    &.dln-price--discounted {
                      margin-left: 6px;
                    }
                  }
                }
              }

              &__price {
                margin-top: 10px;

                .dln-price {
                  font-size: 16px;
                }
              }
            }

            .dln-addedItem__content {
              .dln-cartItem__btn {
                &--stroked {
                  background-color: var(--brand-04) !important;
                }

                .dln-notification {
                  .dln-btnIcon__checkoutCounter {
                    justify-content: end;
                  }
                }
              }
            }

            .dln-cartItem {
              &__content {
                .dln-cartItem__figure {
                  padding: 0 14px;

                  .dln-cartItem__picture {
                    width: auto;
                    height: auto;
                  }
                }
              }

              &__btn {
                .dln-btn {
                  width: calc(50% - 10px);
                  justify-content: center;

                  .dln-notification {
                    padding: 13px 0 18px 0;
                    align-items: flex-end;
                    padding-left: 16.6%;

                    .dln-notification__desc {
                      line-height: 10px;
                    }
                  }

                  .dln-btn {
                    margin: 0 8px;
                    line-height: 1px;
                    width: 32%;
                  }
                }
              }
            }

            &__content--extra {
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 0 40px 20px;

              &-padding-top {
                padding-top: 20px;
              }
            }

            .dln-popup__head.dln-popup__head--circleClose.dln-popup__head--support02 {
              padding: 16px 21px 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    & .dln-popup {
      &__content {
        & .dln-popup__wrapper {
          & .dln-addedItem {
            .dln-popup__head.dln-popup__head--circleClose.dln-popup__head--support02 {
              padding: 20px 22px 0 22px;
            }

            .dln-btnIcon.dln-btnIcon--close.dln-btnIcon--circleClose {
              margin-bottom: 0;
            }

            .dln-popup__headTitle {
              padding: 0;
            }

            .dln-popup__headLine {
              margin-top: 15px;
            }

            &__content {
              .dln-cartItem {
                padding: 0 22px;

                &__priceBox {
                  position: absolute;
                  right: 0;
                  top: 21px;

                  .dln-cartItem {
                    &__price {
                      padding: 0;
                      transform: none;
                    }
                  }
                }

                &__figure {
                  margin-bottom: 24px;
                  max-width: 70%;
                }

                &__content {
                  padding-top: 21px;
                  display: block;
                  position: relative;

                  .dln-csmDropdown {
                    max-width: none;
                    position: relative;
                    top: initial;
                    left: initial;

                    &--open {
                      .dln-csmDropdown__list-item.dln-csmDropdown__list-item--placeholder {
                        display: none;
                      }
                    }

                    &__dropdown {
                      border: 1px solid $brand-04;
                    }

                    &__selected {
                      background: $brand-03;
                    }

                    &__list {
                      border: 1px solid $brand-04;
                      border-top: none;
                      box-shadow: none;
                    }
                  }
                }

                &__picture {
                  width: 110px;
                  height: 88px;
                }

                &__title {
                  margin: 0;
                  left: 5px;
                  position: relative;
                }

                &__price {
                  .dln-price {
                    font-size: REM(15px);
                  }
                  transform: translateX(126px);
                  transform: translateY(-10px);
                  transform: translate(110px, -14px);
                  padding-left: 25px;

                  .dln-price--oldPrice::after {
                    top: 50% !important;
                  }
                }

                &__btn {
                  display: flex;
                  flex-direction: column;
                  padding-bottom: 16px;

                  .dln-notification {
                    margin-bottom: 16px;
                    align-items: flex-end;
                    line-height: 1.5;
                    padding: 0;

                    .dln-btnIcon__checkoutCounterText {
                      font-size: REM(13px);
                      line-height: 0.9rem;
                      padding-top: 4px;
                    }

                    &__desc {
                      line-height: 0.6;
                    }
                  }

                  .dln-btn {
                    width: 100%;
                    min-width: 0;
                    padding: 0;
                    margin: 4px 0;
                    box-sizing: border-box;
                  }
                }
              }

              &--extra {
                padding: 0 20px 20px;

                &-padding-top {
                  padding-top: 20px;
                }

                .dln-addItem {
                  width: 100%;
                  max-width: none;
                  margin-top: 0;

                  .dln-btn.dln-btn--light {
                    align-self: initial;
                  }

                  &:last-of-type {
                    margin-bottom: 0;
                  }

                  &__price {
                    .dln-price--oldPrice::after {
                      top: 50% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .dln-faq.dln-faq--stepFour {
        .dln-faq__mainSubtitle {
            font-size: 21px;
        }
        .dln-faq__content {
            .dln-faq__breadCrumbSelWrap {
                margin: 0 0 8px 0;
            }
        }
    }

}
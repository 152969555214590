// .dln-alert--createShippingAddress {
    .dln-createShippingAddress {
        padding: 16px 40px 40px 40px;
        
        &__fillBtn {
            display: flex;
            align-items: center;
            text-align: left;
            background: none;
            border: none;
            cursor: pointer;
            padding-bottom: 17px;
            gap: 5px;
            
            &:hover {
                background: none;
            }
            
            svg{
                color: $brand-01;
            }
        }
        
        .dln-popup__head {
            padding: 0;
        }
        
        .dln-mandatory {
            display: block;
            text-align: right;
            font-size: REM(13px);
            margin-bottom: 20px;
        }
        
        .dln-createShippingAddress__content {
            padding: 32px 0 0;
        }
        
        .dln-divider ~ .dln-inputs {
            margin-bottom: 0;
        }
        
        .dln-createShippingAddress__form {
            display: flex;
            flex-wrap: wrap;
            
            .row {
                @media only screen and (max-width: 599px) {
                    width: 100%;
                    display: block;
                }
                display: flex;
                
                &.region{
                    width: 100%;
                    margin-bottom: 30px;
                    
                    .dln-inputs{
                        width: 100%;
                        max-width: unset;
                    }
                }
                
                .dln-inputs {
                    &:first-of-type {
                        @media only screen and (max-width: 599px) {
                            width: 100%;
                        }
                        width: 55%;
                    }
                }
                
                .second{
                    .dln-inputs{
                        &:first-of-type{
                            @media only screen and (max-width: 599px) {
                                width: 90%;
                            }
                            width: 85%;
                            max-width: unset;
                        }
                    }
                    .dln-tooltip {
                        @media only screen and (max-width: 599px) {
                            margin-left: 10px;
                        }
                        color: $brand-01;
                    }
                }
            }
            
            > .dln-select {
                width: calc(50% - 8px);
                max-width: none;
                margin: 0 16px 32px 0;
                
                & + .dln-select {
                    margin: 0 0 32px 0;
                }
            }
            .dln-inputs {
                width: calc(50% - 8px);
                margin: 0 14px 32px 0;
                
                &:nth-child(even),
                &:last-of-type {
                    margin: 0 0 32px;
                }
            }
            .dln-chips {
                margin: 0 0 24px;
                
                & + .dln-inputs {
                    margin: 0 16px 32px 0;
                    
                    // &:nth-child(odd) {
                        //     margin: 0 0 32px;
                        // }
                    }
                }
                
                .sectionTitle {
                    position: relative;
                    display: block;
                    width: 100%;
                    margin-bottom: 30px;
                }
                
                .dln-inputs--city {
                    margin-right: calc(50% + 8px);
                }
                
                .dln-inputs--provinceState {
                    margin: 0 16px 32px 0 !important;
                }
                
                .dln-select--country {
                    margin: 0 0 32px 0;
                }
                
                > .dln-checkbox {
                    margin: 24px 0 0;
                }
                
                .dln-checkout__paragraph {
                    margin: 24px 0 40px;
                }
                
                .dln-btn {
                    width: calc(50% - 8px);
                    margin: 0 16px 0 0;
                    
                    &:nth-of-type(even) {
                        margin: 0 0;
                    }

                    &--confirm {
                        margin: 0 0 0 16px;
                    }

                    &--vatnumber_check {
                        margin: 0 0 0 1em;
                        min-width: auto;
                        max-width: 25%;

                        @media only screen and (max-width:map-get($grid-breakpoints, lg)) {
                            max-width: 100%;
                        }
                    }
                }
                
                .dln-checkout_rowForm {
                    position: relative;
                    margin-bottom: 32px;
                    width: 100%;
                    display: flex;
                    
                    > .dln-inputs {
                        margin-bottom: 0;
                        width: 100%;
                        max-width: none;

                        &--uppercase {
                            input {
                                text-transform:uppercase;
                            }
                        }
                    }
                    
                    > .dln-select--country {
                        margin-bottom: 0;
                    }

                    &__jpnFormat {
                        @media only screen and (max-width: 599px) {
                            flex-direction: column;
                            margin-bottom: 32px;
                        }
                        display: flex;
                        width: 100%;

                        .dln-select--country, 
                        .dln-select--provinceState {
                            @media only screen and (max-width: 599px) {
                                width: 100%;
                                margin-right: 0px;
                            }
                            width: calc(50% - 8px);
                            margin: 0 16px 32px 0;
                        }

                        .dln-inputs--last {
                            margin-bottom: 32px;
                        }
                    }
                }
                
                .dln-address_info {
                    display: flex;
                    color: var(--brand-01);
                    gap: 5px;
                    line-height: 10px;

                    .dln-tooltip {
                        display: flex;
                        gap: 8px;
                    }
                    
                    p {
                        text-decoration: underline;
                    }
                }
            }

            .full-space {
                width: 100%;
                margin-bottom: 35px;

                .dln-inputs {
                    width: 100%; 
                    max-width: 100%;
                }
            }

            .dln-address_verify,
            .dln-vatnumber_check {
                width: 100%;
                padding: 1em;
                color: var(--brand-04);
                border: 1px solid var(--support-04);
                background: var(--ui-03);

                p {
                    display: flex;
                    font-size: 0.9rem;

                    &::before {
                        color: var(--support-04);
                        font-family: var(--fontNameIcons);
                        margin: 0 0.5em 0 0;
                        content: 'y';
                    }
                }
            }

            .dln-address_verify {
                display: none;
                margin: 0.5em auto 1em;
            }

            .dln-vatnumber_check {
                display: inherit;
                margin: 0 auto 3em;

                &:not(.visible) {
                    display: none !important;
                }

                @media (min-width: map-get($grid-breakpoints, sm)) {
                    margin-top: 0;
                }
            }
        }
        
        .dln-braun .dln-createShippingAddress {
            
            .dln-address_info {
                align-items: center;
                
                .dln-icn {
                    height: auto;
                }
            }
        }
        
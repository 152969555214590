@media only screen and (max-width:599px) {
    .dln-addNewCard {
        .dln-divider~.dln-inputs {
            margin-bottom: 0;
        }
        .dln-addNewCard__form {
            &>.dln-select {
                width: 100%;
                max-width: 100%;
            }
            .dln-inputs {
                width: 100%;
                margin: 0 0 32px;
                &--oneThird {
                    max-width: 50%;
                    margin: 0 auto 32px 0;
                    &+.dln-btn {
                        max-width: calc(100% - 50% - 24px);
                    }
                }
            }
            .dln-btn {
                width: 100%;
                margin: 0 0 16px 0;
                &:nth-of-type(even) {
                    margin: 0 0 0 0;
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
	.dln-category-browser {
		&__wrapper {
			& .dln-wrapper {
				width: calc(100% - 20px);
			}
		}
		&__header {
			margin-bottom: 20px;

			&--title {
				font-size: 15px;
				line-height: 21px;
			}
			&--text {
				font-size: 14px;
				line-height: 20px;
			}
		}
		&__breadcrumbs {
			margin-bottom: 20px;

			& .dln-breadCrumbSel {
				@include fontBold;
			}
			& .dln-select {
				display: none;
				visibility: hidden;
				&:last-of-type {
					display: inline-block;
					visibility: visible;
				}
			}
		}
		&__category {
			& .dln-categoryBox {
				width: calc(50% - 4px);
				margin: 0 8px 0 0;
				padding: 10px 0;
                border-width: 0;

				&:nth-of-type(2n+2) {
					margin-right: 0;
				}
				&:hover {
					border-width: 0;
				}
			}
		}
	}
}

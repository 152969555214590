.dln-foot {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: auto;
    background: $brand-04;
    padding-top: 32px;
    padding-bottom: 32px;
    overflow: hidden;
    .dln-accordion,
    .dln-accordion__head,
    .dln-accordion__content {
        background-color: transparent;
    }
    &__copyright {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: calc(16.66% - 16.66px);
        height: auto;
        margin: 0 20px 48px 0;
        box-sizing: content-box;

        &Logo {
            display: block;
            margin: 0 0 20px 0;
        }
        &Text {
            display: block;
            width: 100%;
            height: auto;
            font-size: 11px;
            line-height: 16px;
            color: $brand-02;
        }
    }
    &__list {
        list-style: inside none;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;

        &Item {
            display: flex;
            width: 100%;
            height: auto;
            margin: 0 0 4px 0;

            &:last-of-type {
                margin: 0;
            }
        }
        &Link {
            display: block;
            width: 100%;
            height: auto;
            line-height: 16px;
            font-size: 13px;
            color: $brand-02;
            text-decoration: none;
            padding: 0 0 0 0;
        }
    }
    &__separator {
        display: block;
        width: 100%;
        height: 1px;
        background: $brand-02;
        margin: 0 0 24px 0;

        &:last-of-type {
            margin: 0 0 10px 0;
        }
    }
    &__middleSection {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    &__socialIcn {
        display: inline-flex;
        align-items: center;
        margin: 0 auto 24px 0;
        & ul {
            list-style: none;
            display: inline-flex;
            width: 100%;
        }
        & ul li {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            list-style: none;
            background-color: $brand-02;
            text-align: center;
            display: inline-flex;
            align-items: center;
            transition: all .3s;
            margin: 0 8px 0 0;
            &:hover {
                opacity: .6;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
        & ul li a {
            margin: 0 auto;
            height: auto;
            width: 100%;
            text-decoration: none;
            display: inline-flex;
            align-items: center;
        }
        .dln-icn {
            color: $brand-04;
            font-size: 16px;
            margin: 0 auto;
        }

    }
    &__icn {
        width: auto;
        height: 20px;
        margin: 0 auto;
        display: block;
        &[alt="Youtube - Delonghi"] {
            height: 10px;
        }
        &[alt="Facebook - Delonghi"] {
            height: 15px;
        }
    }
    &__creditCards {
        list-style: inside none;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        width: auto;
        height: 40px;
        margin: 0 auto 24px 0;

        &Item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 60px;
            max-width: calc(20% - 10px);
            height: 40px;
            border: 1px solid $brand-02;
            border-radius: 4px;
            margin: 0 10px 0 0;
            &:last-of-type {
                margin: 0 0;
            }
        }
        &Img {
            display: block;
        }
    }
    &__subscribe {
        display: inline-flex;
        align-items: center;
        width: 473px;
        height: 42px;
        margin: 0 0 24px 0;

        & .dln-inputs {
            width: 200px;
            margin: 0 10px 0 0;
            border-color: $brand-02;

            &__input {
                background-color: transparent;
                color: $brand-02;
            }
            &__lbl {
                color: $brand-02;
            }
            &--active {
                & .dln-inputs__lbl {
                    background-color: $brand-01;
                }
            }
        }
        &Text {
            display: inline-flex;
            width: auto;
            height: 37px;
            line-height: 37px;
            font-size: 15px;
            @include fontBold;
            color: $brand-03;
            padding: 0 0 0 0;
            margin: 0 16px 0 0;
        }
        & .dln-btn {
            width: 140px;
        }
    }
    &__footer-selects {
        list-style: inside none;
        display: inline-flex;
        align-self: flex-end;
        width: auto;
        margin: 0 0 0 auto;
        & .dln-btn--selects {
            display: none;
        }
    }
    &__infoList {
        list-style: inside none;
        display: inline-flex;
        align-self: flex-end;
        width: auto;
        height: 40px;
        margin: 0 auto 0 0;
        &Item {
            display: inline-flex;
            align-items: center;
            width: auto;
            padding: 0 8px 0 8px;
            position: relative;

            &:first-of-type {
                padding: 0 8px 0 0;
            }
            &:last-of-type {
                padding: 0 0 0 8px;
            }
            &::after {
                content: '';
                display: inline-flex;
                width: 1px;
                height: 8px;
                background-color: $brand-02;
                position: absolute;
                top: 16px;
                right: 0;
            }
            &:last-of-type::after {
                display: none;
            }
            &:hover {
                text-decoration: underline;
            }
        }
        &Link {
            display: inline-flex;
            width: auto;
            height: 37px;
            line-height: 37px;
            font-size: 11px;
            color: $brand-02;
            text-decoration: none;
            padding: 0 0 0 0;
        }
    }
    & .dln-btn--ok {
        width: 56px;
        margin: 0 0 0 16px;
    }
    & .dln-select {
        width: auto;
        &__body {
            color: $brand-02;
        }
        &__container {
            font-size: 11px;
            color: $brand-02;
            font-weight: normal;
        }
        &__value {
            /*  line-height: 37px; */
            padding: 0 0 0 0;
            font-weight: normal;
        }
        &__arrow {
            height: 12px;
        }
    }
    &__lang {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 8px 0 0;

    }
    &__rights {
        font-size: 12px;
        color: $brand-02;
        margin: 0 auto 0 0;
    }
    &__accordionWrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    &--kenwood {
        display: none;
        background-color: $ui-03;
        .dln-foot__copyright {
            width: 100%;
            margin: 0 0 0 0;
            order: -1;
            .dln-foot__socialIcn {
                width: auto;
                max-width: calc(100% - 154px);
                margin: 0 0 0 auto;
                li a {
                    display: inline-flex;
                    align-items: center;
                    .dln-icn {
                        margin: 0 auto;
                    }
                }
            }
            &Text {
                font-size: 12px;
                line-height: 18px;
                color: $brand-04;
                display: none;
            }
            &Logo {
                width: auto;
                height: 20px;
                margin: 0 auto 0 0;
            }
        }
        .dln-foot__list {
            &Link {
                font-size: 12px;
                line-height: 15px;
                color: $brand-04;
            }
            &Item {
                margin: 0 0 8px 0;
            }
        }
        .dln-select {
            .dln-select__value {
                color: $brand-02;
            }
            &__arrow {
                height: 17px;
            }
        }
        .dln-accordion {
            &--open {
                .dln-accordion__head {
                    &:hover {
                        .dln-accordion__title {
                            color: $brand-04;
                        }
                    }
                }
            }
            &__head {
                padding: 0;
                &:hover {
                    .dln-accordion__title {
                        color: $brand-04;
                    }
                }
                .dln-accordion__title {
                    color: $brand-04;
                    text-transform: uppercase;
                    font-size: 12px;
                    &:hover {
                        color: $brand-04;
                        border-bottom: none;
                    }
                }
            }
        }
        .dln-foot__socialIcn {
            & ul li {
                background-color: $brand-04;
                width: 32px;
                height: 32px;
            }
            .dln-icn {
                color: $brand-03;
            }
        }
        .dln-foot__middleSection {
            margin: 32px auto 0;
            padding: 16px 0;
            border-top: 1px solid $ui-01;
            border-bottom: 1px solid $ui-01;
            align-items: center;
            .dln-foot__socialIcn {
                width: 25%;
                margin: 0;
                display: none;
            }
            .dln-foot__creditCards {
                margin: 0 auto 0 0;
                max-width: 350px;
                &Item {
                    box-sizing: border-box;
                    max-width: calc(20% - 8px);
                    background-color: $brand-03;
                    border-color: $ui-01;
                }
            }
            .dln-foot__subscribe {
                max-width: 100%;
                width: auto;
                margin: 0 0 0 auto;
                @media only screen and (min-width:960px) {
                    max-width: calc(100% - 510px - 10%);
                }
                &Text {
                    color: $brand-04;
                    font-size: 14px;
                    white-space: nowrap;
                    margin: 0 8px 0 0;
                }
                .dln-inputs {
                    border-color: $ui-01;
                    &__input {
                        height: 38px;
                        background-color: $brand-03;
                    }
                }
                .dln-btn {
                    background-color: transparent;
                    height: 40px;
                }
            }
        }
    }
}

@media only screen and (min-width:991px) {
    .dln-foot {
        .dln-foot__accordionWrap {
            width: 75%;
        }
        & .dln-accordion {
            align-content: flex-start;
            width: calc(16.66% - 16.66px);
            max-height: initial;
            border-bottom: none;
            // background: lightgrey;
            margin: 0 20px 48px 0;

            &--last {
                margin: 0 0 48px 0;
            }
            &__head {
                height: 20px;
                // background: lightcoral;
                pointer-events: none;
                user-select: none;

                & .dln-accordion__title {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 13px;
                    border-bottom: none;
                    padding: 0;

                    &:hover {
                        color: $brand-03;
                        border-bottom: none;
                    }
                }
                & .dln-icn {
                    display: none;
                }
            }
            &__content {
                width: 100%;
                opacity: 1;
                padding: 10px 0 0 0;
            }
        }
        &--kenwood {
            .dln-foot__copyright {
                width: 25%;
                padding: 0;
                &Text {
                    display: block;
                    color: $ui-08;
                }
                .dln-foot__socialIcn {
                    display: none;
                }
                &Logo {
                    margin: 0 0 20px 0;
                }
            }
            .dln-accordion {
                width: calc(25% - 52.5px);
                margin: 0 70px 0 0;

                &:last-of-type {
                    margin: 0 0;
                }
                &__head {
                    height: 20px;
                    padding-bottom: 10px;
                    & .dln-accordion__title {
                        color: $brand-04;
                        font-size: 12px;
                        &:hover {
                            color: $brand-03;
                            border-bottom: none;
                        }
                    }
                }
            }
            .dln-foot__middleSection {
                .dln-foot__socialIcn {
                    display: inline-flex;
                }
            }
        }
    }
}

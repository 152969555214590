.dln-delonghi {
  .dln-order {
    &--detail {
      .dln-order {
        &__backToOrderHistory {
          i,
          span {
            color: $brand-04;
          }
        }
        &__content {
          .dln-totalSect,
          .dln-orderDetailInfo {
            background-color: $ui-05;
            padding-left: 20px;
            padding-right: 20px;
            margin-top: 24px;
            border: none;
          }
        }

        &__detailProducts {
          border: none;
          background: $ui-05;
          margin-bottom: 0;
          padding: 24px 20;
          padding-top: 24px;
          padding-left: 20px;
          padding-right: 20px;
          & .dln-orderHistory {
            & .dln-orderRev {
              &__quantity {
                color: $ui-02;
              }
              &__actions {
                max-width: none;
                .dln-btn {
                  background: $ui-05;
                }
              }
            }
          }

          &--last {
            padding-top: 0;
            .dln-order__detailTitle {
              border-top: 1px solid $ui-01;
              padding-top: 24px;
            }
          }
        }

        &__detailTitle {
          color: $brand-04;
          font-size: REM(21px);
          @include fontMedium;
        }

        .dln-order__detailProducts .dln-orderHistory .dln-orderRev__quantity {
          color: $brand-04 !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 784px) and (max-width: 1024px) {
  .dln-delonghi {
    .dln-order {
      &--detail {
        .dln-order {
          &__detailProducts {
            & .dln-orderHistory {
              & .dln-orderRev {
                &__content {
                  flex-wrap: wrap;
                  justify-content: flex-start;
                }

                &__actions {
                  width: 184px;
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 599px) and (max-width: 784px) {
  .dln-delonghi {
    .dln-order {
      &--detail {
        .dln-order {
          &__detailProducts {
            & .dln-orderHistory {
              & .dln-orderRev {
                flex-direction: column;
                align-items: center;
                &__content {
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  width: 100%;
                }

                &__actions {
                  width: 100%;
                  align-items: center;
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-order {
      &--detail {
        .dln-order {
          &__content {
            .dln-orderDetailHead__number {
              font-size: REM(21px) !important;
            }
          }

          &__detailProducts {
            & .dln-orderHistory {
              & .dln-orderRev {
                &__wrap {
                  padding-left: 10px;
                }

                &__actions {
                  margin-top: 0;
                  align-items: center;
                  width: calc(100% - 80px) !important;

                  .dln-btn {
                    max-width: 254px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dln-address--editing {
    overflow: visible;
    .dln-wrapper {
        
        .dln-address__content {
            & >div:nth-last-child(3) {
                & >div:last-child {
                    @media only screen and (max-width:599px) {
                        width: 100%;
                    }
                    width: 70%;
                    padding-bottom: 0;
                }    
            } 
        }

        .dln-address__form {
            .dln-address_info {
                display: flex;
                margin-bottom: 40px;
                height: 40px;
                align-items: center;
                
                .dln-tooltip {
                    display: flex;
                    color: var(--support-02);
                    &__anchor {
                        margin-left: 5px;
                        text-decoration: underline;
                    }
                }
            }
        }

        .dln-address_verify,
        .dln-vatnumber_check {
            width: 100%;
            padding: 1em;
            color: var(--brand-04);
            border: 1px solid var(--support-04);
            background: var(--ui-03);

            p {
                display: flex;
                font-size: 0.9rem;

                &::before {
                    color: var(--support-04);
                    font-family: var(--fontNameIcons);
                    margin: 0 0.5em 0 0;
                    content: 'y';
                }
            }
        }

        .dln-address_verify {
            display: none;
            margin: 0.5em auto 1em;
        }

        .dln-vatnumber_check {
            display: inherit;
            margin: 0 auto 3em;

            &:not(.visible) {
                display: none !important;
            }

            @media (min-width: map-get($grid-breakpoints, sm)) {
                margin-top: 0;
            }
        }

        .dln-btn--vatnumber_check {
            min-width: auto;
            max-width: 25%;

            @media only screen and (max-width:map-get($grid-breakpoints, lg)) {
                max-width: 100%;
            }
        }
    }

    .dln-page__content {
        .dln-title {
            @include fontRoman;
        }
    }



    @media only screen and (max-width:599px) {
        .dln-page__content {
        padding-top: 0px;
            .dln-title {
                font-size: REM(18px);
                line-height: REM(22px);
                padding-bottom: 24px;
                border-bottom: 1px solid var(--ui-02);
                text-align: left;
                display: inline-block !important;
                margin-bottom: 15px;
            }
        }
    }
    
    
    & .dln-address__section {
        &+.dln-address__section {
            margin: 19px 0 40px 0;
        }
        &:nth-last-of-type(2) {
            border-bottom: none;
        }
        
        &Head {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            height: 40px;
            margin: 0 0 20px 0;
            @media only screen and (max-width:599px) {
                margin-bottom: 20px
            }
            
            &Title {
                display: inline-flex;
                width: auto;
                max-width: calc(100% - 80px - 16px);
                height: 35px;
                line-height: REM(35px);
                font-size: REM(32px);
                @include fontRoman;
                padding: 0 0 0 0;
                margin: 0 auto 0 0;

                @media only screen and (max-width:599px) {
                    font-size: REM(15px);
                    line-height: REM(19px);
                }
            }
            &InfoText {
                display: inline-flex;
                align-self: flex-end;
                width: auto;
                height: 10px;
                line-height: REM(10px);
                font-size: REM(10px);
                color: $brand-02;
                margin: 0 0 0 16px;
            }
        }
    }
    & .dln-address__form {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        height: auto;
        padding: 0 0 40px 0;

        .rowEdit {
            @media only screen and (max-width:599px) {
                flex-direction: column;
                margin-bottom: 0px;
            }
            display: flex;
            width: 100%;
            margin-bottom: 30px;
            & > div {
                width: calc(50% - 10px);
            }
            
            .secondEdit {
                @media only screen and (max-width:599px) {
                    display: flex;
                    width: 65%;
                    align-items: center;
                    
                    .dln-tooltip {
                        bottom: 10px;
                    }
                }
                width: calc(50% - 10px);
                
                .dln-inputs {
                    width: 85% !important;
                    margin-right: 10px !important;
                }
                
                .dln-tooltip {
                    color: var(--support-02);
                }
            }
        }
        
        & .dln-inputs,
        & > .dln-select {
            width: calc(50% - 10px);
            max-width: initial;
            margin: 0 20px 30px 0;
            
            &:nth-child(2n+2) {
                margin: 0 0 30px 0;
            }
            &:last-child {
                margin: 0 0 0 0;
            }
            &:nth-last-child(2):nth-child(odd) {
                @media only screen and (max-width:599px) {
                    width: 100%;
                }
                margin: 0 20px 0 0;
                width: calc(50% - 10px);
            }
            &--jpFormat {
                @media only screen and (max-width:599px) {
                    width: 100%;
                }
            }
        }
        
        &:not(:first-of-type) .dln-inputs {
            &:nth-child(1),
            &:nth-child(2),
            &--companyInputs {
                @media only screen and (max-width: 599px) {
                    width: 100%;
                }
                width: 70%;
            }  
            &:last-child {
                width: calc(50% - 10px);
            }       
        }

        .dln-inputs .dln-inputs--jpFormat {
            width: calc(50% - 10px);
        }
        
        &.dln-tooltip {
            &__anchor {
                i {
                    color: $brand-01;
                    padding-top: 10px;
                }
            }
        }
        
        
    }
    & .dln-address__foot {
        @media only screen and (max-width: 599px) {
            flex-direction: column;
        }
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        
        & .dln-checkbox {
            width: calc(45% - 16px);
            margin: 0 16px 32px 0;
            &+.clearer {
                @media only screen and (max-width: 599px) {
                    background: none;
                }
                width: 100%;
                height: 1px;
                background-color: $ui-01;
                margin: 0 0 16px;
            }
            
            &__inputPlaceholder {
                width: 22px;
                height: 22px;
                
                &::after {
                    top: 0px;
                    left: 0px;
                    width: 20px !important;
                    height: 20px;
                    content: 'à';
                    @include iconspseudoelements;
                    color: $brand-03;
                }
            }
            
            &__wrap {
                height: 24px;
                padding: 0 0 0 21px;
            }
            
            .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
                @include fontRoman;
                color: $brand-02;
            }
        }
        & .dln-btn--link {
            @media only screen and (max-width: 599px) {
                margin: auto;
                order: 3;
            }
            align-self: flex-end;
            margin: 0 auto 0 0;
            
        }
        & .dln-btn--confirm {
            @media only screen and (max-width: 599px) {
                margin: 0 0 22px;
                width: 100% !important;
            }
            width: 280px;
            margin: 0 0 0 auto;
        }
    }
}

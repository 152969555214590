@media only screen and (max-width:599px) {
	.dln-wishlist--public {
		.dln-wishlist__content {
			max-width: 100%;
			width: 100%;
			margin: 0;
			margin-bottom: 60px;

			.dln-title {
				margin-top: 42px;
				margin-bottom: 28px;

				font-size: 35px;
				line-height: 43px;
			}
			.dln-wishlist__text {
				margin-bottom: 20px;
            }
            .dln-wishlist__productWrap {
                padding: 0;
                border: 0;

                .dln-wishProd {
                    width: calc(50% - 4px);

                    &:nth-of-type(2n+2) {
                        margin-left: 8px;
                    }
                }
            }
		}

		// &.dln-wishlist--empty {
		// 	&__foot {
		// 		position: fixed;
		// 		left: 0;
		// 		width: 100%;
		// 		background: white;
		// 		box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.1);
		// 		bottom: 0;
		// 		z-index: 100;
		// 		padding: 15px 0;
		// 	}
		// }
	}
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-orderRev {
        &__actions {
            & .dln-btn {
                max-width: calc(50% - 16px);
            }
        }
        &--registeredproduct {
            .dln-orderRev__actions {
                .dln-orderRev__wrap--links {
                    .dln-btn {
                        min-width: calc(50% - 16px);
                    }
                }
            }
        }
        &--productDescription {
            padding: 0 0 24px;
            margin: 0 0 24px;
            border-width: 0;
            &:hover {
                border-width: 0;
            }
            .dln-orderRev__content {
                width: calc(100% - 120px - 16px);
                padding: 16px 0 0;
            }
            .dln-orderRev__itemPic {
                width: 120px;
            }
            .dln-orderRev__desc {
                margin: 16px 16px 0 0;
                width: calc(100% - 16px - 100px);
            }
            .dln-orderRev__detailsTitle {
                width: calc(100% - 120px - 20px);
            }
            .dln-orderRev__sku {
                width: 120px;
            }
            >.dln-btn {
                width: 100px;
                margin: 16px 0 0 0;
            }
            .dln-orderRev__actions {
                width: 100%;
                max-width: 180px;
                .dln-btn {
                    margin: 0 0;
                }
            }
        }
        &--returned {
            & .dln-orderRev__actions {
                & > .dln-btn {
                    max-width: none;
                }
            }
        }
    }
}

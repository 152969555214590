.dln-filters-bar {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &__filters-wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .section-label {
        padding-right: 27px;
        color: #9b9b9b;
        font-size: 15px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
    }

    .dln-filterDropdown + .dln-filterDropdown {
        margin-left: 30px;
    }

    &__desktop {
        & .section-label,
        & .dln-filterDropdown {
            margin-bottom: 10px;
        }
    }
    &__mobile {
        display: none;
    }

    &__applied-filters {
        display: flex;
        align-self: center;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding: 0;
        margin-top: 30px;

        &.swiper-container {
            margin-top: 30px;
            padding: 0;

        }

        .swiper-pagination,
        .swiper-scrollbar {
            display: none;
        }
        .swiper-wrapper {
            align-items: center;
        }
        .swiper-slide {
            width: auto!important;
            // margin-right: 10px;

        }

        .dln-btn {
            @include fontBold;
            height: auto;
            display: inline-block;
            padding: 0;
            margin-left: 10px;
            text-decoration: none;
            font-size: 15px;
        }
    }

    &__sort-section {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;
        font-size: 15px;
        line-height: 1.4;
    }

    &__sorted-by-label {
        display: inline-block;
        margin-right: 5px;
        font-size: 15px;
        line-height: 1.4;
        color: #9b9b9b;
    }

    .dln-select {
        &__value {
            @include fontRoman;
            font-size: 15px;
            line-height: 1.4;
        }
        &__head {
            align-items: center;
        }
        &__arrow {
            width: 18px;
            height: 15px;
            font-size: 18px;
        }
    }
}

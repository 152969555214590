@media only screen and (max-width:599px) {
    .dln-inputAction {
        & .dln-inputAction__wrap--component {}
        &--showInput {
            & .dln-inputAction__wrap--component {
                width: 100%;
                padding: 4px 0 0 0;
                & .dln-inputAction__input {
                    width: calc(100% - 130px);
                }
                & .dln-btn {
                    width: 115px;
                    transition: none;
                }
            }

        }
    }
}
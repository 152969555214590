.dln-kenwood {
    .dln-chips {
        background-color: $ui-03;
        padding: 20px;

        span,
        span:not(.dln-chips__strong) {
            color: $ui-07;
            font-size: 12px;
            line-height: 16px;
            text-transform: initial;
        }

        .dln-chips__strong {
            @include fontBold;
            color: $ui-07;
            margin: 0 0 8px 0;
        }
        .dln-icn {
            font-size: 30px;
            line-height: 30px;
        }
        &--iconinside {
            .dln-chips__content {
                width: calc(100% - 40px);
            }
        }

        &:not(.dln-chips--warning):not(.dln-chips--recallInformation):not(.dln-chips--advantage) {
            span:not(.dln-chips__strong) {
                color: $ui-07;
            }
            a {
                color: $ui-07;
            }
            .dln-icn {
                color: $ui-07;
            }
        }

        .dln-btnIcon {
            transform: none;
            right: 18px;

            &--close {
                .dln-icn {
                    color: $brand-01;

                    font-size: 22px;
                    line-height: 1;
                    width: 1em;
                    height: 1em;
                    text-indent: 999px;
                    overflow: hidden;

                    @include definePseudoElems;
                    &::before {
                        @include fontIcon;
                        content: 'æ';
                        text-indent: 0;
                        color: $brand-01;
                    }
                }
            }
        }

        &--advantage {
            padding: 10px;

            .dln-chips__content {
                span,
                span:not(.dln-chips__strong) {
                    color: $brand-04;
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: initial;

                    b {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                >span {
                    .dln-icn {
                        font-size: 50px;
                        line-height: 36px;
                        vertical-align: middle;
                    }
                }
            }
        }

        &--recallInformation {
            background: #fff4e5;
            border: 0;
            border-left: 3px solid #ee9a20;
            padding: 20px 15px;
            padding-bottom: 28px;

            .dln-chips__wrapper {
                .dln-chips__content {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    font-size: 14px;
                    line-height: 20px;

                    .dln-chips__info {
                        font-size: 12px;
                        line-height: 16px;
                        color: $support-04;
                    }
                    .dln-chips__title {
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 2px;
                    }
                    .dln-chips__description {
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 20px;
                    }
                    .dln-btn--link {
                        font-size: 14px;
                        line-height: 20px;
                        text-align: left;
                        text-align-last: left;
                        margin: 0;
                    }
                }
                .dln-icn--mainIcon {
                    display: inline-block;
                    text-align: center;
                    text-align-last: center;
                    margin-right: 15px;
                    background: $support-04;
                    border-radius: 100%;
                    line-height: 1.5;
                    color: white;
                    font-size: 22px;
                    width: 35px;
                    height: 35px;
                }
            }
        }



        @media only screen and (max-width:599px) {

            &--advantage {
                .dln-btnIcon {
                    top: 20px;
                }
                .dln-chips__content {
                    text-align: center;
                    max-width: initial;

                    >span {
                        .dln-icn {
                            display: block;
                            margin-bottom: 10px;
                        }
                    }
                }
            }

            &--recallInformation {
                padding: 23px 15px;
                padding-bottom: 33px;

                .dln-chips__wrapper {
                    .dln-chips__content {
                        .dln-chips__title {
                            margin-bottom: 4px;
                        }
                    }
                }
            }

        }


    }
}
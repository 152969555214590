.dln-wishlist {
    align-content: flex-start;

    .dln-wrapper {
        position: relative;
    }
    
    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {
        .dln-title {
            @include big;
            @include fontBold;
            font-size: 44px;
            line-height: 55px;
            margin: 0 0 30px 0;
        }
        .dln-wishlist__subtitle {
            @include big;
            @include fontBold;
            font-size: 32px;
            line-height: 40px;
            margin: 0 0 8px 0;
        }
        .dln-wishlist__paragraph {
            font-size: 14px;
            line-height: 20px;

            &:last-of-type {
                margin: 0;
            }
        }
        .dln-wishlist__text {
            display: block;
            width: 100%;
            height: auto;
            font-size: 14px;
            line-height: 20px;
            margin: 0 0 32px 0;
        }
    }
    
    &__section {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
    }
    &__productWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 30px 0;

        & .dln-wishProd {
            width: calc(50% - 7.5px);
            margin: 0 10px 10px 0;

            &:nth-of-type(2n+2) {
                margin: 0 0 10px 0;
            }
        }
    }
    &__foot {
        width: 100%;
        display: flex;
        padding: 0;
        text-align: center;
        margin: 32px 0 0 0;

        &--divider {
            margin: 58px 0 32px;
            border-top: 1px solid $ui-01;
            text-align: right;
            padding: 32px 0 0;
        }

        & .dln-btn {
            width: auto;
            max-width: 250px;
            margin: 0 0 0 auto;
        }
    }
}
@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .dln-faq {
        padding-top: 0;

        & .dln-chips {
            & .dln-icn--mainIcon {
                margin: 0 20px 0 0;
            }
            &__wrapper {
                align-items: flex-start;
                padding-top: 16px;
            }
            &__content {
                width: calc(100% - 70px);
                padding: 0 8px 0 0;
            }
        }
        & .dln-inputs--searchBar {
            width: 100%;
            // margin-bottom: 50px;
        }
        &__mainTitle {
            font-size: 35px;
            margin: 32px 0 0;
        }
        & .dln-faq__mainSubtitle {
            margin: 0 0 20px 0;
        }
        &__content {
            & .dln-wrapper {
                width: calc(100% - 20px);
            }
        }
        & .dln-faq__category {
            & .dln-categoryBox {
                width: calc(50% - 4px);
                margin: 0 8px 0 0;
                padding: 10px 0;
                border-width: 0;
                &:nth-of-type(2n+2) {
                    margin-right: 0;
                }
                &:hover {
                    border-width: 0;
                }
            }
        }

        &__title {
            font-size: 15px;
            margin-bottom: 0;
            &--related {
                font-size: 22px;
            }
        }
        &__mainSubtitle {
            font-size: 15px;
        }
        &__videoContainer {
            width: 100%;
            height: 0;
            background-color: $brand-01;
            padding-bottom: 56.25%;
            margin: 0 0 48px 0;
            position: relative;
        }
        &__video {
            display: block;
            width: 100%;
            max-width: none;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &__inner {
            margin-right: 10px;
            margin-left: 10px;
            padding-right: 10px;
            padding-left: 10px;
        }
        &__breadCrumbSelWrap {
            margin: 0 0 8px 0;
        }
        & .dln-faq__articleFoot {
            border-bottom: none;
            align-items: flex-start;
            position: relative;
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: $ui-01;
                left: 0;
                top: calc(16px + 40px + 16px);
            }
            &Text {
                width: calc(100% - 40% - 16px - 16px);
                height: 40px;
                padding: 0;
                display: inline-flex;
                align-items: center;
            }
            /* 
            & .dln-btn--yes {
                width: calc(50% - 8px);
                margin: 0 16px 32px 0;
            }
            & .dln-btn--no {
                width: calc(50% - 8px);
                margin: 0 0 32px 0;
            }
            & .dln-btn--print {
                margin: 0 32px 0 0;
            } */
            .dln-btn--print {
                margin: 16px 32px 0 auto
            }
            .dln-btn--share {
                margin-top: 16px;
            }
            .dln-btn--stroked {
                width: 20%;
                margin-bottom: 16px;

            }
        }

        .dln-breadcrumbs {
            display: none;
        }
        .dln-faq__main {
            .dln-chips {
                margin: 0;
                padding-right: 0;
                padding-left: 0;
            }
            >.dln-wrapper--fullWidth {
                background-color: $ui-03;
                margin-bottom: 0;
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-serviceLocator {
        &__aside {
            width: 100%;
        }
        .dln-wrapper {
            padding: 0;
            margin: 0;
        }
        .dln-breadcrumbs {
            width: calc(100% - 40px);
            margin: 0 20px;
            a {
                font-size: 14px;
            }
        }
        &__title {
            line-height: 40px;
            font-size: 35px;
            padding: 0 20px;
            margin: 8px 0 24px 0;
        }
        &__description {
            padding: 0 20px;
            margin: 8px 0 24px 0;
        }
        &__results {
            width: 100%;
            max-height: calc(100vh - 40px);
            padding: 20px 0;
            .dln-addressResult {
                padding: 8px 20px;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: calc(100% - 40px);
                    left: 20px;
                    bottom: 0;
                    background-color: $ui-01;
                }
            }
        }
        &__map {
            width: 100%;
            margin: 0;
            // display: block;
            // opacity: 1;
        }
        &__mobileBnt {
            display: inline-flex;
            >ul {
                border: none;
            }
        }
        &__aside {
            height: auto;
            min-height: auto;
            .dln-inputs--searchBar {
                width: calc(100% - 20px - 20px - 98px - 20px);
                margin: 0 20px 24px 20px;
            }
            .dln-btnTextIcon--nearMe {
                width: 98px;
                margin: 0 20px 24px 0;
            }
            .dln-accordion {
                margin: 0;
                max-height: 40px;
                border-bottom: 2px solid $brand-03;
                &__head {
                    height: 40px;
                    width: 100%;
                    border-bottom: none;
                    padding-left: 20px;
                }
                &__wrap {
                    border: none;
                    padding: 8px 20px 16px;
                    &Title {
                        padding-top: 8px;
                        border-top: 1px solid $ui-01;
                    }
                }
                &--titleIcon {
                    .dln-accordion__icon {
                        width: 12px;
                        height: 12px;
                        line-height: normal;
                        margin-right: 8px;
                    }
                }
            }
        }
        &__resultCounter {
            padding: 0 20px;
        }
        &--results {
            .dln-serviceLocator__aside {
                .dln-accordion {
                    max-height: 40px;
                }
            }
        }
    }
}
// for desktop only
@media only screen and (min-width:900px) {
    .dln-serviceLocator {
        .dln-tabs__content {
            opacity: 1;
            display: flex !important;
            flex-wrap: wrap;
        }
    }

}

.dln-kenwood {
    .dln-btn {
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.2px;
        width: auto;
        border-radius: 0;
        @include fontSemibold;
        align-items: center;
        /* &:not(.dln-btn--link),
        &:not(.dln-btn--stroked),
        &:not(.dln-btn--social),
        &:not(.dln-btn--paypal) {
            &:hover {
                background-color: $brand-04;
                color: $brand-03;
            }
        } */
        &:not(.dln-btn--link):not(.dln-btn--apply-voucher) {
            height: 43px;
            line-height: 1;
            padding: 0 20px;
        }

        &:not(.dln-btn--link):not(.dln-btn--stroked):not(.dln-btn--social):not(.dln-btn--paypal) {
            &:hover {
                opacity: 1;
                background-color: $brand-04;
                color: $brand-03;
            }
        }

        &.dln-btn--vatnumber_check {
            height: 43px;
        }

        &--stroked {
            color: $brand-01;
            border: 1px solid $brand-01;
            background-color: $brand-03;

            &:hover {
                color: $brand-04;
                border: 1px solid $brand-04;
            }
        }
        &--link {
            text-transform: initial;
            line-height: normal;
            font-size: 14px;
            letter-spacing: 0;
        }
        &--action-button {
            & .dln-icn {
                color: $support-02;
            }
            &.dln-btn--action-done {
                color: $support-02;
                border: 1px solid $support-02;
            }
        }
        &--social {
            border-radius: 0;
            display: flex;
            align-content: center;
            padding: 0 20px 0 20px;
            position: relative;
            .dln-btn__lbl {
                @include fontBold;
                text-transform: none;
                margin: 0 0 0 3em;
            }
            .dln-icn {
                width: 22px;
                height: 22px;
                line-height: 22px;
                font-size: 22px;
                color: $brand-03;
                top: calc(50% - 11px);
                left: 20px;
                /* position: relative;
                top: auto;
                left: auto; */
                &::before {
                    background-size: auto 90%;
                }
            }
            &.dln-btn--social-google {
                height: 40px;
                color: $brand-04;
                border: 1px solid $ui-02;
                background-color: $brand-03;

                /*  & .dln-btn__icon {
                    position: relative;
                    top: auto;
                    left: auto;
                } */
            }
            &.dln-btn--social-fb {
                height: 40px;
            }
            &.dln-btn--social-tw {
                height: 40px;
            }
            &.dln-btn--social-inst {
                height: 40px;
            }
            &.dln-btn--centeredIcn {
                .dln-icn {
                    left: initial;
                }
            }
        }
        &--paypal,
        &.dln-btn--paypal:not(.dln-btn--link) {
            padding: 8px 20px;
            background: #eeeeee;

            &::after {
                content: 'Checkout';
                font-size: 12px;
                letter-spacing: 0.2px;
                text-transform: initial;
                margin-bottom: 3px;
                margin-left: 5px;
            }
            .dln-btn__icon {
                height: 1.7em;
            }

            .dln-btn__lbl {
                display: none;
            }

        }
    }
}
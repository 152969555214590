h1.gigya-screen-caption.gigya-screen-caption {
  font-family: var(--fontNameBold);
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  font-size: 17px;
  text-align: left;
  padding-bottom: 10px;
  margin: 21px 0 32px;
  width: 100% !important;
}

.gigya-composite-control-multi-choice {

  .gigya-required-display {
    visibility: hidden;
  }

}
.dln-faq {
    background: red;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    justify-content: center;
    height: auto;
    min-height: 100vh;
    //background-color: $brand-03;
    padding: 40px 0 100px 0;

    & .dln-wrapper {
        max-width: 1080px;
    }

    &--alert {
        padding-top: 30px !important;
    }

    & .dln-wrapper {
        width: 100%;
    }
    &__chipsWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        
        & .dln-wrapper {
            max-width: 1040px;
        }
    }
    &__main {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        justify-content: center;
        //max-width: 1080px;
        height: auto;
        margin: 0 auto;
    }
    & .dln-chips {
        width: 100%;
        border: none;
        margin: 0 0 40px 0;
    }
    &__mainTitle {
        @include fontBold;
        display: block;
        width: 100%;
        font-size: 44px;
        margin: 0 0 32px 0;
    }
    &__mainSubtitle {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 32px;
        margin: 0 0 24px 0;

        & + .dln-inputs {
            width: 50%;
            // margin: 0 0 88px 0;
        }
    }
    &__inner {
        width: 100%;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        justify-content: center;
        //max-width: 1080px;
        height: auto;
        padding-top: 88px;
        margin: 0 0 64px 0;
        margin-right: auto;
        margin-left: auto;
    }
    &__maxWidth1000 {
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
    }
    &__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 26px;
        font-size: 22px;
        margin: 0 0 48px 0;
    }
    &__text {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 14px;
        margin: 0 0 16px 0;
    }
    &__textList {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        list-style: inside disc;
        margin: 0 0 16px 0;

        &Item {
            width: 100%;
        }
    }
    &__breadCrumbSelWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin: 0 0 24px 0;
        align-items: baseline;

        & .dln-breadCrumbSel {
            margin: 0 24px 0 0;
        }
        & .dln-select {
            margin: 0 24px 0 0;

            &__value {
                @include fontBold;
            }
        }
    }
    .dln-category-browser__category {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;

        & .dln-categoryBox {
            padding: 6px;
            border: 1px solid;
            border-color: transparent;
            margin: 0 0 22px 0;
            width: 50%;

            picture {
                max-height: 132px;

                @media screen and (min-width: 768px) {
                    max-height: none;
                }

                img {
                    height: 100%;
                    max-width: none;
                    width: auto;
                }
            }

            @media screen and (max-width: 599px) {
                &:nth-child(5n) {
                    margin-right: 0;
                }
                &:nth-child(5n + 1) {
                    margin-left: 0;
                }
            }

            @media screen and (min-width: 768px) {
                width: 20%;
                max-width: none;
                max-height: none;
                border: 1px solid;
                border-color: transparent;
                padding: 10px;
                margin: 0 0 22px 0;
            }

            &__imgWrap{
                background: transparent;
            }

            &__title {
                border-bottom: 1px solid $ui-01;
                padding-bottom: 12px;
            }

            &:hover {
                border-color: $brand-02;
            }
        }
    }

    & .dln-faq__title--related {
        @include fontSemibold;
    }

    &__resultCounter {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        color: $brand-02;
        text-align: center;
        margin: 0 0 16px 0;

        &+.dln-btn--showMoreResults {
            width: 184px;
            margin: 0 auto;
        }
    }
    &__videoContainer {
        width: 895px;
        height: 494px;
        background-color: $brand-01;
        margin: 0 0 48px 0;
        // padding-bottom: 56.25%;
        // position: relative;
    }
    &__video {
        display: block;
        max-width: 100%;
        height: auto;
    }
    &__articleFoot {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: auto;
        border-top: 1px solid $ui-01;
        border-bottom: 1px solid $ui-01;
        padding: 16px 0;
        margin: 0 0 48px 0;

        &Text {
            display: inline-block;
            width: auto;
            line-height: 17px;
            padding: 0 0 0 0;
            margin: 0 16px 0 0;
        }
        & .dln-btn--stroked {
            width: 88px;

            &.dln-btn--yes {
                margin: 0 16px 0 0;
            }
            &.dln-btn--no {
                margin: 0 auto 0 0;
            }
        }
        & .dln-btn--print {
            margin: 0 32px 0 0;
        }
        & .dln-btn--print,
        & .dln-btn--share {
            @include fontMedium;
        }
    }

    &__inner {
        width: 100%;
        //max-width: 1080px;
        padding: 0 40px;
        margin: 0 auto;

        & .dln-wrapper {
            max-width: 1080px;
        }
    }
    &__contactUs {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        & .dln-wrapper {
            width: 100%;

            &--fullWidth {
                background-color: $ui-03;
                padding-top: 40px;
                padding-bottom: 40px;

            }
        }
        &Title {
            @include fontBold;
            display: block;
            width: 100%;
            line-height: 22px;
            font-size: 18px;
            margin: 0 0 24px 0;
        }
        &Text {
            display: block;
            width: 100%;
            line-height: 20px;
            font-size: 14px;
            margin: 0 0 32px 0;
        }
        & .dln-btn--contactUs {
            width: 124px;
        }
    }
}

@media only screen and (min-width: 600px) {
    .dln-faq {
        &__carousel {
            & .swiper-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;

                & .swiper-slide {
                    width: calc(20% - 16px) !important;

                    & .dln-quickLink {
                        border-bottom: 1px solid $ui-01;
                    }
                }
            }
        }
    }
}

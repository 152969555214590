.dln-kenwood {
    & .dln-totalSect {
        border: none;
        background-color: $ui-03;
        padding: 30px 20px;

        &__title {
            width: calc(100% - 266px);
            line-height: 24px;
            font-size: 20px;
        }
        &__wrap {
            width: 266px;
            padding: 28px 0 0 0;
        }
        &__row {
            margin-bottom: 0;

            &--total {
                padding-top: 12px;

                & .dln-totalSect__label,
                & .dln-totalSect__data {
                    line-height: 24px;
                    font-size: 20px;
                    color: $brand-04;
                }
                & .dln-totalSect__label {
                    text-transform: lowercase;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
        &__label,
        &__data {
            line-height: 26px;
            font-size: 14px;
        }
        &__label {
            color: $ui-10;
        }
        &__data {
            color: $brand-04;
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-totalSect {
            &__title {
                width: 100%;
                line-height: 22px;
                font-size: 18px;
                margin-bottom: 6px;
            }
            &__wrap {
                width: 100%;
                padding: 0;
            }
            &__row {
                &--total {
                    padding-top: 14px;

                    & .dln-totalSect__label,
                    & .dln-totalSect__data {
                        line-height: 22px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

.dln-kenwood {

    .dln-registration + .dln-popup .dln-popup__content {
        max-width: 591px !important;
    }

    .dln-forgPsw {
        padding: 40px 70px !important;
        
        &__wrap {
            .dln-btn {
                margin: 0 auto;
            }
        }
        .dln-popup__headLine {
            display: none;
        }
        .dln-popup__headTitle {
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
        }
        .dln-btnIcon {
            position: absolute;
            top: 5px !important;
            right: 10px !important;

            .dln-icn {
                font-size: 22px;
                width: 22px;
                height: 22px;
                text-indent: 999px;
                overflow: hidden;
                @include definePseudoElems;

                &::before {
                    @include fontIcon;
                    content: 'J';
                    text-indent: 0;
                    color: $brand-01;
                }
            }
        }
        .dln-inputs {
            max-width: 267px;
            margin-right: 10px;
            margin-bottom: 40px;
        }
        .dln-forgPsw__text {
            font-size: 14px;
            margin-bottom: 50px;
        }
        &__wrap {
            margin: 0;
            width: 100%;

            .dln-forgPsw__text {
                margin-bottom: 30px;
            }
            .dln-btn--backToLogin {
                width: 145px;
                margin: 0 auto;
            }
        }
    }

    @media only screen and (max-width:599px) {
        .dln-forgPsw {
            padding: 30px 0 50px 0 !important;
            margin-left: 20px;
            margin-right: 20px;

            .dln-forgPsw__form {
                width: 100%;
                margin: 0px;
            }
            .dln-popup__headTitle {
                font-size: 28px;
                line-height: 34px;
            }
            .dln-popup__head--circleClose {
                margin-top: 90px;
                margin-bottom: 32px;
            }
            .dln-forgPsw__text {
                margin-bottom: 41px;
            }
            .dln-inputs {
                margin-right: 0px;
                margin-bottom: 20px;
                max-width: 100%;
            }
            .dln-btn {
                width: 100%;
                max-width: 100%;
                margin-bottom: 40px;

                &--link {
                    margin: 0;
                }
                &--backToLogin {
                    margin: 0;
                }
            }
        }
    }
}
@mixin make-container() {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    /* padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2); */
}

@mixin make-row() {
    display: flex;
    flex-wrap: wrap;
    margin-right: ($grid-gutter-width / 2);
    margin-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
}

@mixin makePage {
    @include make-container();
    >.dln-wrapper {
        padding-top: 48px;
        padding-bottom: 130px;
        @media only screen and (max-width: map-get($grid-breakpoints, md)) {
            padding-top: 20px;
            padding-bottom: 70px;
        }
    }
}
@mixin makePageKenwood {
    >.dln-wrapper {
        padding-top: 32px;
        padding-bottom: 70px;
        @media only screen and (max-width: map-get($grid-breakpoints, md)) {
            padding-top: 20px;
            padding-bottom: 70px;
        }
    }
}
@mixin makeAside {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 264px;
    margin: 0 40px 0 0;
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        width: 100%;
        margin: 0;
    }
    @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
        width: 100%;
        margin: 0;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) and (orientation: portrait) {
        width: 264px;
        margin: 0 40px 0 0;
    }
}
@mixin makeContent {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: calc(100% - 304px);
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        width: 100%;
    }
    @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
        width: 100%;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) and (orientation: portrait) {
        width: calc(100% - 304px);
    }
}
@mixin makeAsideKenwood {
    margin: 0 40px 0 0;
    width: 264px;
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        width: 100%;
        margin: 0;
    }
    @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
        width: 100%;
        margin: 0;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) and (orientation: portrait) {
        margin: 0 40px 0 0;
        width: 264px;
    }
}
@mixin makeContentKenwood {
    width: calc(100% - 304px);
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        width: 100%;
    }
    @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
        width: 100%;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) and (orientation: portrait) {
        width: calc(100% - 304px);
    }
}

@mixin make-row--fullWidth() {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    max-width: 100%;
}
@mixin makeAddBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border: 1px dashed $ui-04;
    background: $brand-03;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    position: relative;
    font-size: 16px;
    color: $brand-04;
    min-height: 175px;
    height: auto;
    width: 100%;
    max-width: 100%;
    padding: 110px 20px 50px;
    text-transform: none;
    @include definePseudoElems;
    &::before {
        @include fontIcon;
        content: '6';
        font-size: 50px;
        line-height: 50px;
        top: 50px;
        left: calc(50% - 25px);
        color: $brand-01;
    }
}
@mixin definePseudoElems {
    &::before,
    &::after {
        content: '';
        position: absolute;
    }
}
@mixin iconspseudoelements {
    font-family: $fontNameIcons;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* Font smoothing */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
        font-family: $fontNameIcons;
    }
}


@mixin fontIcon {
    font-family: var(--fontNameIcons);
    font-variant: normal;
    text-transform: none;
}
@mixin fontRoman {
    font-family: var(--fontNameRoman);
    font-weight: 400;
    font-style: normal;
}
@mixin fontLight {
    font-family: var(--fontNameLight);
    font-weight: 300;
    font-style: normal;
}
@mixin fontMedium {
    font-family: var(--fontNameMedium);
    font-weight: 500;
    font-style: normal;
}
@mixin fontSemibold {
    font-family: var(--fontNameSemibold);
    font-weight: 600;
    font-style: normal;
}
@mixin fontBold {
    font-family: var(--fontNameBold);
    font-weight: 700;
    font-style: normal;
}
@mixin fontExtra {
    font-family: var(--fontNameExtra);
    font-weight: 400;
    font-style: normal;
}


/* DELONGHI TOFIX */
@mixin fontBook {
    font-family: var(--fontNameDelonghi);
    font-weight: 400;
    font-style: normal;
}
@mixin fontMedium {
    font-family: var(--fontNameDelonghi);
    font-weight: 500;
    font-style: normal;
}
@mixin fontDemi {
    font-family: var(--fontNameDelonghi);
    font-weight: 600;
    font-style: normal;
}

@mixin cta {
    font-size: 17px;
    line-height: 125%;
}

@mixin mainMenu {
    font-size: 15px;
    line-height: 125%;
}

@mixin serviceMenu {
    font-size: 13px;
    line-height: 125%;
}

@mixin small {
    font-size: 13px;
    line-height: 125%;
}

@mixin extraSmall {
    font-size: 12px;
    line-height: 125%;
}

@mixin extraExtraSmall {
    font-size: 10px;
    line-height: 125%;
}

@mixin medium {
    font-size: 18px;
    line-height: 125%;
}

@mixin large {
    font-size: 22px;
    line-height: 125%;
}

@mixin big {
    font-size: 32px;
    line-height: 125%;
}

@mixin extraBig {
    font-size: 44px;
    line-height: 125%;
}

@mixin btn {
    @include cta;
    outline: none;
    width: auto;
    line-height: normal;
    font-size: 14px;
    @include fontBold;
    cursor: pointer;
    user-select: none;
    width: 100%;
    height: 40px;
    line-height: 17px;
    /*  padding-top: 13px; */
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius: 2px;
    overflow: hidden;
    background-color: $brand-01;
    text-align: center;
}

@mixin hover-supported {
    /*
     * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer
     * coarse: The primary input mechanism includes a pointing device of limited accuracy.
     * + IE11 support
     */
    @media not all and (pointer: coarse), not all and (hover: none), (-ms-high-contrast: none) {
        &:hover {
            @content;
        }
    }
}


//test print size

@mixin calcPrintSize($property, $value) {
    #{$property}: printSize($value) + rem;

}

@function printSize($value){
    $value:  (($value / 100) * 66.66) / 16;
    @return $value;
}

@media only screen and (max-width:599px) {
    .dln-orderDetailHead {
        padding: 20px 20px 25px 20px;

        &__number {
            order: 1;
            width: 100%;
            line-height: 27px;
            font-size: 22px;
            margin: 0 0 22px 0;
        }
        &__cancel {
            order: 4;
        }
        &__email {
            order: 2;
            margin-bottom: 25px;
        }
        &__wrap {
            order: 3;
            margin-bottom: 20px;
        }
        &__dataCell {
            width: 50%;
            padding-bottom: 20px;
        }
        &__dataLabel,
        &__data {
            line-height: 18px;
            font-size: 15px;
        }
        &__dataLabel {
            margin-bottom: 4px;
        }
        &--shipped {
            & .dln-orderDetailHead__number {
                margin-bottom: 10px;
            }
        }
    }
}

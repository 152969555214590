.dln-kenwood {
    & .dln-cart {
        &.dln-cart--taxes {
            & .dln-cart__content {
                & .dln-cart__items {                                        
                    & .dln-cartItem {      
                        &__content {
                            & > .dln-chips {
                                min-width: calc(100% + 200px);    
                                border: 1px solid $support-04;
                                padding: 24px 38px 24px 51px;
                                margin-top: 63px;
                                margin-left: -200px;
                                
                                & .dln-chips__wrapper {
                                    & .dln-icn {
                                        width: 50px;
                                        height: 44px;
                                        line-height: 44px;
                                        font-size: 50px;
                                        margin-right: 31px;
                                    }
                                    & .dln-chips__content {
                                        width: calc(100% - 50px - 31px);

                                        & span {
                                            &:first-of-type {
                                                color: $ui-10 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }                  
                    }
                }                
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-cart {
            &.dln-cart--taxes {
                & .dln-cart__content {
                    & .dln-cart__items {                                        
                        & .dln-cartItem {                                  
                            &__content {
                                margin-bottom: 30px;
                                
                                & > .dln-chips {
                                    min-width: initial;
                                    padding: 20px;
                                    margin-top: 24px;
                                    margin-left: 0px;

                                    & .dln-chips__wrapper {
                                        & .dln-icn {
                                            width: 100%;
                                            height: 44px;
                                            margin: 0 0 13px 0;
                                        }
                                        & .dln-chips__content {
                                            width: 100%;

                                            & span {
                                                width: 100%;
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }                  
                        }
                    }                
                }
            }
        }
    }
    
}

// .dln-kenwood {
//     & .dln-cart {
//         &.dln-cart--taxes {
//             & > .dln-wrapper {
//                 padding-top: 29px;

//                 & .dln-cart__head {
//                     margin: 0 0 44px 0;

//                     & .dln-chips {
//                         display: flex;
//                         flex-wrap: wrap;
//                         align-items: center;
//                         justify-content: center;
//                         height: 55px;
//                         background-color: $brand-03;
//                         padding: 0 20px;

//                         & .dln-chips__wrapper {
//                             display: inline-flex;
//                             width: auto;

//                             & .dln-chips__content {
//                                 display: inline-flex;
//                                 max-width: none;

//                                 & span {
//                                     display: inline-flex;
//                                     align-items: center;
//                                     width: auto;
//                                     line-height: 2;

//                                     & b {
//                                         margin-left: 5px;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     & .dln-cart__title {
//                         &Lbl {
//                             color: $brand-04;
//                         }
//                     }
//                     & .dln-btn--link.dln-btn--icon {                        
//                         & .dln-icn {
//                             font-weight: bold;
//                         }                        
//                         & .dln-btn__lbl {
//                             @include fontBold;
//                             font-weight: bold !important;                            
//                         }
//                     }
//                 }
//             }
//             & .dln-cart__content {
//                 align-self: flex-start;
//                 margin-bottom: 70px;

//                 & .dln-cart__items {
//                     & .dln-cartItem {
//                         border-top: none;
//                         padding: 0;

//                         &__content {
//                             margin-bottom: 35px;
//                         }
//                         &__title {
//                             line-height: 16px;
//                             font-size: 14px;
//                             margin-bottom: 9px;
//                         }
//                         &__closeBtn {
//                             top: 0;
//                             right: 10px;

//                             & .dln-icn {
//                                 width: 22px;
//                                 height: 22px;
//                                 font-size: 0;

//                                 &::before {
//                                     @include fontIcon;
//                                     content: 'æ';
//                                     line-height: 22px;
//                                     font-size: 22px;
//                                     color: $brand-01;
//                                 }
//                             }
//                         }
//                         &__chips {
//                             margin-bottom: 15px;

//                             & .dln-chips {
//                                 height: 16px;
//                                 background-color: transparent;                                
//                                 padding: 0;

//                                 &__content {
//                                     & span {
//                                         @include fontRoman;
//                                         line-height: 16px;
//                                         color: $ui-06 !important;
//                                     }
//                                 }
//                             }
//                         }
//                         &__price {
//                             margin-top: 0;
//                             margin-bottom: 15px;

//                             & .dln-price {
//                                 &--oldPrice {
//                                     margin: 0 12px 0 0;
//                                 }
//                             }
//                         }
//                         &__quantity {
//                             & .dln-inputIncr {
//                                 &__input {
//                                     @include fontBold;
//                                 }
//                             }
//                         }
//                         &__accessories {                            
//                             padding-bottom: 20px;
//                             margin-bottom: 17px;
//                             position: relative;
                            
//                             &::after {
//                                 content: '';
//                                 width: 100%;
//                                 max-width: 488px;
//                                 height: 1px;
//                                 background-color: $ui-01;
//                                 position: absolute;
//                                 bottom: 0;
//                                 left: 0;
//                             }
//                             & .dln-select {
//                                 max-width: 285px;
//                             }
//                             & .dln-btnTextIcon {
//                                 max-width: 183px;
//                                 height: 45px;

//                                 &__wrap {
//                                     width: 100%;
//                                     margin: 0;
//                                 }
//                                 &__iconWrap {                                    
//                                     & .dln-icn {
//                                         color: $brand-01;

//                                         &::before {
//                                             content: '4';
//                                         }
//                                     }
//                                 }
//                                 &__text {
//                                     margin: 0 0 0 10px;
//                                 }
//                                 &:hover {
//                                     & .dln-btnTextIcon__iconWrap {                                    
//                                         & .dln-icn {
//                                             color: $brand-03;
//                                         }
//                                     }
//                                 }
//                             }                            
//                         }
//                         &__warranty {
//                             margin-top: 0;

//                             &Head {
//                                 border-bottom: none;
//                                 padding-bottom: 0;
//                             }
//                         }
//                         &__plan {
//                             margin-bottom: 10px;
//                         }
//                     }
//                     & + .dln-btn {
//                         margin-top: 20px;

//                         & .dln-btn__lbl {
//                             @include fontBold; 
//                         }                       
//                     }
//                 }
//             }
//             & .dln-cart__aside {
//                 & .dln-cart__orderRecap {
//                     border: none;
//                     padding: 15px 20px 31px 20px;
//                     margin-bottom: 50px;

//                     & .dln-orderSumm {
//                         background-color: transparent;
                        
//                         &--light.dln-orderSumm--cart {
//                             margin-bottom: 33px;
//                         }
//                         &__content {
//                             &Row {              
//                                 padding: 15px 0;                  

//                                 &--promo {
//                                     padding: 0 0 20px 0;
//                                     // opacity: 0.2;

//                                     & .dln-orderSumm__rowTitle {
//                                         line-height: 22px;
//                                         margin-bottom: 8px;
//                                     }
//                                     & .dln-price--checkout {
//                                         & a {
//                                             @include fontBold;
//                                         }
//                                     }
//                                     & .dln-orderSumm__field {
//                                         margin-top: 21px;

//                                         & .dln-inputs {
//                                             width: calc(100% - 10px - 95px);
//                                             margin: 0 10px 0 0;
//                                         }
//                                         & .dln-inputs__lbl {
//                                             line-height: 16px;                                            
//                                         }
//                                         & .dln-btn {
//                                             color: $brand-01;
//                                             border: 1px solid $brand-01;
//                                             background-color: $brand-03;
//                                         }
//                                     }
//                                 }
//                                 // &--subtotal {
                                    
//                                 // }
//                                 &--taxes {
//                                     padding-bottom: 20px;

//                                     & .dln-orderSumm__rowTitle {
//                                         margin-bottom: 8px;

//                                         & + .dln-price {
//                                             margin-bottom: 8px;
//                                         }
//                                     }
//                                     & .dln-orderSumm__field {
//                                         margin-top: 21px;

//                                         & .dln-inputs {
//                                             &__lbl {
//                                                line-height: 16px; 
//                                             }
//                                         }
//                                     }
//                                     & .dln-btn {
//                                         color: $brand-01;
//                                         border: 1px solid $brand-01;
//                                         background-color: $brand-03;
//                                     }
//                                 }
//                                 &--total {
//                                     border-bottom: none;
//                                     padding-bottom: 0;
//                                     // margin-bottom: 33px;

//                                     & .dln-orderSumm__rowTitle {
//                                         line-height: 24px;
//                                         font-size: 20px;
//                                         color: $brand-04;
                                        
//                                         & .dln-icn,
//                                         & a {
//                                             color: $brand-02;
//                                         }                                        
//                                     }
//                                     & .dln-price--total {
//                                         line-height: 24px;
//                                         font-size: 20px;
//                                     }
//                                 }
//                             }
//                         }
//                         & + .dln-btn {
//                             width: 100%;                            

//                             & + .dln-btn--paypal {
//                                 display: flex;
//                                 justify-content: center;                                
//                                 width: 100%;

//                                 & .dln-btn__icon {
//                                     margin: 0 8px 0 0;
//                                 }
//                                 &::after {
//                                     margin: 0 0 3px 0;
//                                 }
//                             }
//                         }                        
//                     }
//                 }
//                 & .dln-cart__noticeBox {
//                     & .dln-noticeBox {
//                         &__link {
//                             @include fontBold;
//                         }
//                     }
//                 }
//             }
//             & .dln-cart__services {
//                 margin-top: 0;
//             }
//         }
//     }
// }

// @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:1080px) {
//     .dln-kenwood {        
//         & .dln-cart {
//             &.dln-cart--taxes {
//                 & > .dln-wrapper {
//                     & .dln-cart__content {           
//                         & .dln-cart__items {
//                             & .dln-cartItem {                        
//                                 &__accessories {
//                                     & .dln-select {
//                                         width: 100%;
//                                         margin: 0 0 20px 0;   
//                                     }
//                                     & .dln-btnTextIcon {
//                                         width: 100%;
//                                     }
//                                 }
//                             }
//                         }
//                     }                
//                 }
//             }
//         }
//     }
// }

// @media only screen and (max-width:599px) {
//     .dln-kenwood {
//         & .dln-cart {
//             &.dln-cart--taxes {
//                 & > .dln-wrapper {
//                     padding-top: 22px;

//                     & .dln-cart__head {
//                         border-bottom: none;

//                         & .dln-chips {
//                             height: auto;
//                             padding: 12px 20px;
//                             margin: 0 0 20px 0;

//                             &__content {
//                                 & span {
//                                     width: 100%;
//                                     padding-top: 48px;
//                                     position: relative;

//                                     & .dln-icn {
//                                         position: absolute;
//                                         top: 0;
//                                         left: calc(50% - 25px);
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     & .dln-cart__content {
//                         margin-bottom: 0;

//                         & .dln-cart__items {
//                             & .dln-cartItem {
//                                 &__content {
//                                     margin-bottom: 50px;
//                                 }
//                                 &__picture {
//                                     width: 98px;
//                                     height: 80px;
//                                     margin-bottom: 65px;
//                                 }
//                                 &__title,
//                                 &__chips,
//                                 &__price,
//                                 &__quantity {
//                                     width: calc(100% - 98px - 20px - 16px - 22px);
//                                     margin-left: 20px;
//                                 }
//                                 &__closeBtn {
//                                     right: 0;
//                                 }
//                                 &__accessories {
//                                     padding-bottom: 0;

//                                     & .dln-select {
//                                         max-width: none;
//                                     }
//                                     & .dln-btnTextIcon {
//                                         max-width: none;                                        
//                                     }
//                                 }
//                                 &__warranty {
//                                     & .dln-cartItem__plan {
//                                         &:last-child {
//                                             margin-bottom: 0;
//                                         }
//                                     }
//                                 }
//                             }                            
//                         }
//                     }     
//                     & .dln-cart__aside {
//                         & .dln-cart__orderRecap {
//                             width: 100vw;
//                             min-width: 100vw;
//                             margin-bottom: 30px;
//                             margin-left: calc(50% - 50vw);

//                             & + .dln-btn {
//                                 margin: 0 auto 60px 0;

//                                 & .dln-btn__lbl {
//                                     @include fontBold;
//                                 }
//                             }
//                         }   
//                     }                    
//                 }
//             }
//         }
//     }    
// }
@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-head {
        &__nav {
            display: none;
        }
        & .dln-btnIcon--mobileMenu {
            display: block;
        }
        &--kenwood {
            height: 60px;
            .dln-wrapper {
                height: 60px;
            }
            .dln-head__topListWrap {
                display: none;
            }
            .dln-head__navList {
                display: none;
            }
            .dln-head__logo {
                width: 150px;
                height: auto;
                margin: 0 auto;

                &Icon {
                    width: 100%;
                    height: auto;
                }
            }
            .dln-head__btnNav {
                margin: 0;
            }
            .dln-btnIcon--search {
                display: none;
            }
            .dln-btnIcon--mobileMenu {
                .dln-icn {
                    font-size: 24px;
                }
            }
        }
    }
}



@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .bra-header {
        padding: 23px 20px;
        .bra-quickLinks__wrapper {
            display: none;
        }
    }
}

.dlg-hidden {
  display: none!important;
}

.dlg-gigya-link {
  @include gigya-dln-btn;
  @include gigya-dln-btn-link;
}

.dlg-gigya-button-wrapper {
  input[type='submit'],
  input[type='button'] {
    @include gigya-dln-btn;
    @include gigya-dln-btn-not-link;
  }
}

.dlg-gigya-captcha-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.gigya-captcha-wrapper .gigya-captcha > div > div {
  margin:auto !important;
}

div.gigya-screen-dimmer {
  position: fixed!important;
  z-index: z('ajax-loader')!important;
  overflow: hidden!important;
  margin: auto!important;
  top: 0!important;
  left: 0!important;
  bottom: 0!important;
  right: 0!important;
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  background-color: rgba(0, 0, 0, 0.55)!important;

  opacity: 1!important;
  filter: none;
  width: initial!important;
  height: initial!important;
}


div.gigya-screen-loader,
div.gigya-screen-loader:after {
  border-radius: 50%!important;
  width: 50px!important;
  height: 50px!important;
  z-index: z('notification')!important;
}

div.gigya-screen-loader {
  border: 5px solid rgba(255, 255, 255, 0.2)!important;
  border-left-color: var(--brand-03)!important;
  animation: load8 1.1s infinite linear;

  margin: 0!important;
  position: absolute!important;
  top: calc(50% - 25px)!important;
  left: calc(50% - 25px)!important;
  transform: translate(-50%, -50%)!important;

  background: none!important;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// social section
.gigya-social-login-container {
  margin: 0 auto!important;

  .gigya-login-provider-row {
    margin-bottom: 16px;

    .gigya-login-provider:not(:last-child) {
      margin-right: 16px!important;
    }
  }
}

.h-hr--line {
  display:block;
  height:1px;
  background-color:var(--ui-01);
  width:100%;
  margin: 15px 0 40px;
}


.gigya-toggle {
  display: block!important;
  margin: 10px 0!important;
  cursor: pointer;
  text-decoration: none!important;
  color: var(--brand-02)!important;

  &-content {
    display: none!important;
    &.is-open {
      display: block!important;
    }
  }
}

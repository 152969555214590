@media only screen and (max-width:599px) {
    .dln-profile {
        .dln-profileCard {
            display: none;
        }
        &__content {
            & .dln-title {
                line-height: 34px;
                font-size: 35px;
                padding: 6px 0 0 0;
            }
            & .dln-persDataRecap {
                box-sizing: content-box;
            }
            & .dln-addBox {
                min-height: 209px;
                margin: 0 0 32px 0;

                &--productRegister {
                    margin: 0;
                }
            }
        }
        &__section {}
        &__recipes {
            min-height: 216px;
            padding: 64px 20px 64px 20px;

            &Subtitle,
            &Link {
                line-height: 20px;
                font-size: 13px;
            }
            &Title {
                line-height: 22px;
                font-size: 17px;
            }
        }
        &__benefits {
            width: 100%;
            margin: 0 0 32px 0;
            .dln-profile__bannerSubTitle,
            .dln-profile__bannerCopy {
                width: 100%;
                margin: 0 0 8px;
            }
        }
        &:not(.dln-profile--01) {
            padding-right: 0;
            padding-left: 0;
            >.dln-wrapper {
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
            }
            .dln-profile__aside {
                margin-right: 20px !important;
                margin-left: 20px !important;
            }
            .dln-profile__content {
                .dln-title {
                    margin-right: 20px;
                    margin-left: 20px;
                }
                & .dln-persDataRecap {
                    margin-right: 20px;
                    margin-left: 20px;
                    width: calc(100% - 48px - 2px - 40px);

                }
            }
            .dln-profile__content .dln-title {
                margin-bottom: 32px;
            }

            /* .dln-profile__aside,
            .dln-persDataRecap {
                margin-right: 20px;
                margin-left: 20px;
            } */
            .dln-profile__registerProduct {
                padding: 30px 20px;
            }
        }
    }
}
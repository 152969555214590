.dln-country-list {
  display: block;
    width: 100%;

  .dln-accordion {
    width: 100%;

    .dln-accordion__head {
      width: 100%;
      pointer-events: none;
      height: auto;
      padding: 0;
      border-top: 0;

      .dln-accordion__title {
        max-width: calc( 100% - 44px );
        padding: 0;
        height: auto;

        @include fontBold;
        font-size: 26px;
        line-height: 32px;

        color: $brand-01;
      }

      i {
        display: none;
      }
    }
    .dln-accordion__content {
      width: 100%;
      padding: 0;
      margin: 0;
      padding-top: 25px;
      opacity: 1;
    }


    &--open {
      .dln-accordion__head {
        border-top: 1px solid $ui-01;
        border-bottom: 0;
      }
    }
  }

  .dln-accordion + .dln-accordion {
    .dln-accordion__head {
      border-top: 0;
    }
  }

  .dln-flag__list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .dln-flag__listItem {
      display: flex;
      position: relative;
      width: 25%;
      margin-bottom: 40px;

      .dln-flag__listLink {
        color: $brand-02;
        text-decoration: none;

        &:hover * {
          text-decoration: underline;
        }
      }

      .dln-flag {
        flex-shrink: 0;
        height: 19px;
        font-size: 19px;
        border: 1px solid #d8d8d8;
        background-size: cover;
        margin-right: 10px;
      }
      .dln-flag__languages {
        @include fontRoman;
        font-size: 14px;
        line-height: 20px;
        color: $brand-02;

        .dln-flag__lang {
          display: inline-block;
          margin-right: 10px;

          a {
            @include fontRoman;
            text-decoration: none;
            color: $brand-02;
            font-weight: 400;
          }

          strong {
            color: $brand-01;
            @include fontBold;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

#restOfTheWorld {
    .dln-accordion__content {
        display: block
    }
    .dln-btn--internationalWebsite {
        margin-top: 35px;
        margin-bottom: 40px;
        width: auto;
    }
}

@media only screen and (min-width:599px) {
    .dln-country-list {
        & .dln-accordion {
            max-height: none !important;

            & + .dln-accordion {
                & .dln-accordion__head {
                    padding-top: 44px;
                }
            }
        }
    }
}

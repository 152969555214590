.dln-kenwood {
    .dln-payMethods {
        .dln-title {
            margin: 0 0 31px 0;
        }
        &__section {
            &Title {
                @include fontSemibold;
                font-size: 16px;
                line-height: 22px;
                margin: 0 0 20px 0;
            }
        }

        @media only screen and (max-width:599px) {
            &__aside {
                .dln-vertMenu {
                    margin: 0 0 30px 0;
                }
            }
            .dln-title {
                margin: 0 0 30px 0;
            }

            &__content {
                .dln-addBox {
                    margin: 0;
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-delonghi {
        & .dln-chooseCountry {
            & .dln-wrapper {
                & .dln-title {
                    display: none;
                }
            }
        }
    }
}

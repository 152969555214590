.dln-regAdv {
    list-style: inside none;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        min-height: 64px;
    }
    & .dln-icn {
        width: 64px;
        height: 64px;
        line-height: 64px;
        font-size: 48px;
        color: $support-02;
        margin: 0 40px 0 0;
    }
    &__textWrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: calc(100% - 64px - 40px);
        height: auto;
        border-bottom: 1px solid $ui-01;
        padding: 21.5px 0 21.5px 0;
    }
    &__item:last-of-type {
        & .dln-regAdv__textWrap {
            border-bottom: none;
        }
    }
    &__text {
        line-height: 16px;
        font-size: 13px;
        color: $brand-02;
        padding: 4px 0 0 0;

        & span {
            @include fontBold;
            color: $brand-04;
        }
    }
}

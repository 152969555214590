@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-order--detail {
        & .dln-order__aside {
            width: 100%;
            margin-right: 0;
            padding-top: 0;

            & .dln-profileCard {
                margin-bottom: 30px;
            }
            & .dln-vertMenu {
                margin-bottom: 0;
            }
        }
        & .dln-order__content {
            width: 100%;

            & .dln-order__detailProducts {
                & .dln-orderRev__content {
                    & .dln-orderRev__details {
                        width: calc(100% - 16px - 184px);
                        margin-right: 16px;
                    }
                    & .dln-orderRev__actions {
                        width: 184px;
                        max-width: none;

                        & > .dln-btn {
                            width: 100%;
                            max-width: none;
                        }
                    }
                }
            }
        }
    }
}

.dln-delonghi {
  .dln-instruction-manuals {
    & > .dln-wrapper,
    &__content > .dln-wrapper {
      max-width: map-get($grid-breakpoints, xl);
    }
    &__main {
      & > .dln-wrapper {
        max-width: map-get($grid-breakpoints, xl);

        & .dln-btn.dln-btn--link {
          font-size: REM(11px);
        }
      }
    }
    &__mainTitle {
      @include fontMedium;
      line-height: 1.04;
      font-size: REM(50px);
    }
    &__mainSubtitle,
    &__helpSectionTitle {
      @include fontMedium;
      line-height: 1.21;
      font-size: REM(28px);
    }
    & .dln-category-browser {
      & .dln-category-browser__header {
        &--title {
          font-size: REM(21px);
        }
      }
    }
    & .dln-category-browser__category {
      & .dln-categoryBox {
        // max-width: 184px;
        width: 16.66%;

        &:nth-of-type(5n + 5) {
          margin-right: 16.66%;
        }
      }
    }
    &__helpSection {
      &Title,
      &Text {
        margin-bottom: 20px;
      }
    }
    &__spellCheck {
      line-height: 1.33;
      font-size: REM(18px);
      color: $brand-04;
    }
    & .dln-inputs {
      margin-bottom: 0;
    }

    & .dln-category-browser__breadcrumbs {
          div {
            display: inline-block;
        }
    
        & .dln-breadCrumbSel {  
            display: inline-block;
    
            &__text {
                display: inline-block;
            }
        
            &__text--selected {
                display: inline-block;
                color: var(--brand-02);
    
                & + .dln-breadCrumbSel__arrow {
                    transform: rotate(360deg);
                    display: inline-block;
                }
            }
    
            &__arrow {
                transform: rotate(360deg);
                display: inline-block;
                top: -1px;
                margin-left: 3px;
                font-size: REM(8px);
    
                &--search {
                    top: -1px;
                }
            }   
        }
      }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    & .dln-instruction-manuals {
      & .dln-category-browser__category {
        & .dln-categoryBox {
          width: 20%;

          &:nth-of-type(5n + 5) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-instruction-manuals {
      &__mainTitle {
        line-height: 1.08;
        font-size: REM(37px);
      }
      &__mainSubtitle {
        line-height: 1.1;
        font-size: REM(21px);
      }
      & .dln-category-browser__wrapper {
        padding-top: 48px;
      }
      & .dln-category-browser {
        & .dln-category-browser__header {
          &--title {
            font-size: REM(18px);
          }
        }
      }
      & .dln-category-browser__category {
        & .dln-categoryBox {
          width: calc(50% - 4px);

          &:nth-of-type(5n + 5) {
            margin-right: 8px;
          }
        }
      }
      &__helpSectionTitle {
        line-height: 1.11;
        font-size: REM(18px);
      }
      &__helpSection {
        &Title,
        &Text {
          margin-bottom: 20px;
        }
      }
      &__spellCheck {
        line-height: 1.5;
        font-size: REM(16px);
      }
      /* instruction-manuals-03 */
      &.dln-instruction-manuals-03 {
        & .dln-instruction-manuals__mainTitle {
          margin-bottom: 24px;
        }
        & .dln-instruction-manuals__content {
          padding-top: 0;

          & .dln-prodManualsCard {
            padding-top: 25px;
          }
        }
      }
    }
  }
}

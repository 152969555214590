.dln-alert {
    width: 100%;
    max-width: 585px;
    padding: 16px 40px 40px;
    box-shadow: 0 7px 11px 0 rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    margin: 0 0 16px;
    background-color: $brand-03;
    
    .dln-popup__head {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 0 0;
        position: relative;
    }
    &--success {
        background: $support-02--light;
        .dln-alert__message {
            color: $support-02;
        }
        .dln-popup__headTitle {
            .dln-icn {
                &::before {
                    content: 'T';
                    color: $support-02;
                    font-size: 38px;
                }
            }
        }

    }
    &--error {
        background: $support-01--light;
        .dln-alert__message {
            color: $support-01;
        }
        .dln-popup__headTitle {
            .dln-icn {
                &::before {
                    content: 'y';
                    color: $support-01;
                    font-size: 38px;
                }
            }
        }

    }
    &__head {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 0 16px;
        position: relative;
    }
    .dln-popup__closeBtn {
        position: relative;
        top: auto;
        right: auto;
        margin: 0 0;
        .dln-icn {
            height: auto;
        }
    }
    &__content {
        width: 100%;
        padding: 16px 0 0;
        @include small;
    }
    &__message {
        @include cta;
        @include fontBold;
        width: 100%;
        display: flex;
        align-items: center;
        .dln-icn {
            font-size: 38px;
            width: auto;
            height: auto;
            line-height: normal;
            margin: 0 4px 0 0;
        }
    }
    &__subtilte {
        @include fontBold;
    }
    &__copy {
        margin: 24px 0 0;
    }
    &--minimal {
        padding: 16px 40px;
        .dln-popup__head {
            .dln-popup__headLine {
                display: none;
            }
            .dln-popup__headTitle {
                .dln-icn {
                    margin: 0 8px 0 0;
                }
            }
            .dln-btnIcon {
                margin: 0 0 0 auto;
                .dln-icn {
                    text-indent: 30px;
                    overflow: hidden;
                    width: 20px;
                    &::before {
                        content: 'J';
                        text-indent: initial;
                        color: $brand-02;
                    }
                }
            }
        }
        &.dln-alert--success {
            .dln-popup__headTitle {
                color: $support-02;
            }
        }
        &.dln-alert--error {
            .dln-popup__headTitle {
                color: $support-01;
            }
        }
    }
}
.dln-mapPopup {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    max-height: 400px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    &__head {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        height: auto;
        margin: 0 0 12px 0;
    }
    &__company {
        display: inline-block;
        width: auto;
        font-size: 12px;
        @include fontBold;
        color: $brand-04;
        text-transform: uppercase;
    }
    &__distance {
        display: inline-block;
        width: auto;
        font-size: 12px;
        color: $brand-02;
        border-left: 1px solid $brand-02;
        padding: 0 0 0 16px;
        margin: 0 0 0 16px;
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 4px 0;
        margin: 0 0 8px 0;
    }
    &__data {
        display: block;
        width: 100%;
        line-height: 17px;
        font-size: 12px;
        color: $brand-04;

        &--brand-02 {
            color: $brand-02;
        }
    }
    &__wrap--info {
        & .dln-mapPopup__data {
            margin: 0 0 4px 0;

            &--address {
                margin: 0 0 12px 0;
            }
        }
    }
    &__wrap--actions {
        border-bottom: none;
        padding: 0;
        margin: 0;

        & .dln-mapPopup__data {
            margin: 0 0 16px 0;
        }
        & .dln-btn {
            transition: none !important;
            
            &--website,
            &--writeUs {
                width: calc(50% - 5px);
                margin: 0 0 10px 0;
            }
            &--website {
                margin: 0 10px 10px 0;
            }
        }
    }
    &__triangle {
        height: 0;
        border-style: solid;
        border-width: 4px 4px 4px 0;
        border-color: transparent #ffffff transparent transparent;
        position: absolute;
        top: 124px;
        left: -4px;
        z-index: 2;
    }
}
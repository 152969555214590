.dln-recipe-by-ingredients {
    margin-top: 10px;
    .dln-inputs--active .dln-inputs__lbl {
        display: none;
    }

    &__search-section {
        margin-top: 50px;
        margin-bottom: 10px;
    }
    
    &__title {
        @include fontBold;
        font-size: 36px;
        line-height: 1.22;
        margin-bottom: 20px;
    }
} 
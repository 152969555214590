[class^="dln-icn"],
[class*=" dln-icn"] {
    @include iconspseudoelements;
}


body {
    @include fontRoman;
    color: $brand-04;
    -webkit-font-smoothing: antialiased;
    p {
        @include small;
    }
    input,
    textarea,
    select,
    button,
    *,
    ::before,
    ::after {
        @include fontRoman;
    }
    b,
    strong {
        @include fontBold;
    }
}

@font-face {
    src: url('#{$pathAssets}/fonts/fonts-icon/bra-icons.eot?85022927');
    src: url('#{$pathAssets}/fonts/fonts-icon/bra-icons.eot?85022927#iefix') format('embedded-opentype'),
         url('#{$pathAssets}/fonts/fonts-icon/bra-icons.woff2?85022927') format('woff2'),
         url('#{$pathAssets}/fonts/fonts-icon/bra-icons.woff?85022927') format('woff'),
         url('#{$pathAssets}/fonts/fonts-icon/bra-icons.ttf?85022927') format('truetype'),
         url('#{$pathAssets}/fonts/fonts-icon/bra-icons.svg?85022927#bra-icons') format('svg');
    font-family: $fontNameIconsBraunNew;
    font-weight: normal;
    font-style: normal;
}
.dln-checkout {
  &__payment-methods-wrapper {
    width: 100%;
    max-width: 600px;
  }

  // hack to allow the last real adyen payment to have radius when payroll is selected
  .adyen-checkout__payment-method:has(+ .dln-checkout__payment-method-payroll.adyen-checkout__payment-method--selected) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  // payroll
  &__payment-method-payroll {
    .adyen-checkout__payment-method__image__wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      img {
        width: 40px;
      }
    }
  }
}

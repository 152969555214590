.dln-delonghi {
  .dln-recipe-by-ingredients {
    margin-top: 0;

    & > .dln-wrapper {
      padding-top: 0;

      & .dln-breadcrumbs {
        margin-bottom: 30px !important;
      }
    }
    &__search-section {
      margin: 0 auto;
      text-align: center;
    }

    &__title {
      @include fontMedium;
      font-size: REM(37px);
      line-height: 1;
    }

    @media only screen and (max-width: 599px) {
      &__search-section {
        width: 100%;
      }

      .dln-inputs {
        margin-bottom: 0;
      }

      &__title {
        font-size: REM(28px);
        line-height: 1.07;
        letter-spacing: -0.53px;
      }

      .dln-recipe__title {
        a {
          font-size: REM(18px);
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-recipe-by-ingredients {
      &__search-section {
        width: 100%;
      }
      & .dln-inputs {
        margin-bottom: 0;
      }
      &__title {
        font-size: REM(28px);
        line-height: 1.07;
        letter-spacing: -0.53px;
      }
    }
  }
}

.dln-delonghi {
  .dln-tabs {
    &__item {
      &--current .dln-tabs__btn {
        @include fontMedium;
        color: $brand-04;
      }
    }
    &__btn {
      @include fontBook;
      font-size: REM(16px);
      line-height: 24px;
      color: $brand-04;

      span {
        @include fontMedium;
      }
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-verification {
        padding: 0 0 20px;
        .dln-popup__head {
            width: 100%;
            padding: 20px 0 16px 0;
            margin: 0 20px 16px 20px;
        }
        &__head {
            width: 100%;
            padding: 20px 0 16px;
            margin: 0 20px 16px 20px;

            &Title {
                font-size: 13px;
            }

        }
        &__text {
            margin: 0 0 24px;
        }
        &__form {
            width: calc(100% - 40px);
            margin: 0 20px;

            button.dln-btn[type="button"] {
                margin: 0 0 16px;
            }
        }
        & div:nth-child(2) form {
            width: calc(100% - 40px);
            margin: 0 20px;
        }
        .dlg-gigya-link {
            font-weight: bold;
        }
        &__wrap {
            width: calc(100% - 40px);
            margin: 0 20px;
        }
    }
}
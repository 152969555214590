.dln-delonghi {
  .dln-regAdv__textWrap {
    border: none;
  }

  // Registration / Login
  .dln-registration {
    &--login {
      .dln-registration__section--main {
        border: none;
      }
    }

    .dln-registrationForm__formFoot .dln-btn {
      width: 50%;
    }

    .gigya {
      &-login-spacer {
        border: none;
      }

      &-composite-control-link {
        font-size: toRem(14px);
        font-weight: 600;
        line-height: 21px;

        .dln-btn {
          width: 50%;
        }
      }

      &-input {
        &-text,
        &-password {
          border-color: var(--brand-04) !important;
        }

        &-submit {
          padding: 0 25px;
          background-color: var(--brand-04);
          font-size: toRem(18px);
          font-weight: 600;
          line-height: 1;
        }
      }
    }
  }

  .dln-popup {
    // Login popup
    .gigya {
      &-input {
        &-text,
        &-password {
          border-color: var(--brand-04) !important;
        }

        &-submit {
          padding: 0 25px;
          background-color: var(--brand-04);
          font-size: toRem(18px);
          font-weight: 600;
          line-height: 1;
        }
      }
    }

    .dln-registration__paragraph {
      margin-bottom: 25px;
      font-size: toRem(14px);
      line-height: 1.3;
      text-align: center;
    }

    // Registration completion
    .dln-registrationForm__paragraph--secondary {
      color: var(--brand-02);

      a {
        color: inherit;
      }
    }

    .gigya-login-form .dln-btn {
      width: 100% !important;
    }

    // Subscription popup
    .dln-subscription-flow {
      #gigya-subscribe-with-email-form {
        .gigya-layout-row {
          .gigya-layout-row {
            &:nth-child(1),
            &:nth-child(2) {
              margin-right: 0;
            }

            &:nth-child(4) {
              width: 100%;
            }

            &:nth-last-child(2) {
              width: 100%;
              max-width: 222px;
            }
          }
        }

        .gigya-input {
          &-text {
            height: 38px;
            padding: 10px 35px 10px 16px;
            border-color: var(--ui-02);
          }

          &-checkbox {
            position: absolute;
          }
        }

        .gigya-composite-control-checkbox {
          .gigya-label {
            display: flex;
            justify-content: flex-end;

            &::before {
              top: 4px;
              width: 24px;
              height: 24px;
              border-radius: 2px;
            }

            &::after {
              top: 3px;
              left: 4px;
              width: 16px;
              height: 16px;
            }

            span {
              display: flex;
              align-items: center;
              width: 93%;
              line-height: 16px;
              text-align: justify;

              i {
                flex-shrink: 0;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  // Checkout
  .dln-checkout {
    &__innerColTitle {
      font-size: toRem(23px) !important;
      line-height: 21px !important;
      font-weight: 600 !important;
    }

    .dln-registrationForm__paragraph--secondary {
      color: var(--brand-02);

      a {
        color: inherit;
      }
    }

    .gigya {
      &-input {
        &-text,
        &-password {
          border-color: var(--brand-04) !important;
        }

        &-submit {
          padding: 0 25px;
          font-size: toRem(18px);
          font-weight: 600;
          line-height: 1;
        }
      }

      &-forgotPassword {
        font-size: toRem(14px);
        font-weight: 600;
        line-height: 21px;
      }
    }
  }

  // Marketing Preferences
  .dln-account {
    .dln-btn--update {
      text-align: center;
    }

    .gigya {
      &-layout-row {
        &.subscribe-wrapper {
          h2 {
            text-align: center;
          }

          .gigya-entitlement-checkbox {
            box-shadow: none;
          }
        }
      }

      &-input {
        &-submit {
          padding: 0 25px !important;
          background-color: var(--brand-04) !important;
          font-family: var(--fontNameBold) !important;
          font-size: toRem(18px) !important;
          font-weight: 600 !important;
          line-height: 1 !important;
        }
      }
    }
  }

  // Account Edit
  .dln-changePssw {
    .gigya {
      &-input {
        &-text {
          border-color: var(--brand-04) !important;
        }
      }
    }
  }
}

.dln-checkout__form-step-1 {
  .dln-checkout__innerCol--dln {
    width: calc(50% - 35px);
    margin: 0 70px 0 0;
    padding: 0 70px 0 0;
    border-right: 1px solid var(--ui-01);

    &.dln-checkout__secondCol {
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 1080px) {
    .dln-checkout__innerCol--dln {
      width: 100%;
      margin: 0 0 0 0;

      &.dln-checkout__secondCol {
        margin: 0 0 0 0;
      }
    }
  }

  @media only screen and (max-width: 599px) {
    .dln-checkout__innerCol--dln {
      width: 100%;
      margin: 0;
      padding: 0 0 30px 0;
      border-right: 0;

      &.dln-checkout__secondCol {
        margin: 0;
      }
    }
  }

  #dln-checkout__innerCol-guest {
    display: block;
  }

  #js-dlg-gigya-register-container {
    display: none;
  }

  &.dln-checkout--has-subscription {
    #dln-checkout__innerCol-guest {
      display: none;
    }

    #js-dlg-gigya-register-container {
      display: block;
    }
  }
}

.dln-delonghi {
  .dln-checkout {
    #js-dlg-gigya-register-container_content {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;

      .gigya-input-submit {
        background-color: var(--brand-04);
      }
    }

    @media screen and (max-width: 1080px) {
      #js-dlg-gigya-register-container_content {
        margin-top: toRem(35px);
        padding-top: toRem(35px);
        border-top: 1px solid lightgrey;
      }
    }

    [data-screenset-element-id='gigya-register-screen']
      .dln-btn.dln-btn--stroked,
    [data-screenset-element-id='gigya-register-screen']
      .gigya-composite-control.gigya-composite-control-submit.dlg-gigya-button-wrapper {
      width: 100%;
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-registration--login {
        .dln-profileCard {
            display: none;
        }
        .dln-registration__section {
            width: 100%;
            margin: 32px 0 0 0;
            padding: 32px 0 0 0;
            display: block;
            border-top: 1px solid $ui-01;
            &--main {
                margin: 0 0 0 0;
                padding: 0 0 0 0;
                border: none;
            }
            .dln-regAdv {
                margin: 0 0 0;
            }
        }
        .dln-registration__formFoot {
            margin: 48px 0 0;
            .dln-btn {
                width: 100%;
            }
        }
        .dln-loginForm__section--registration {
            display: none;
        }
    }
}
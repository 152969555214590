.dln-kenwood {
    .dln-prodReg {
        & .dln-wrapper {
            padding-top: 0;
            padding-bottom: 72px;
            position: relative;
            display: flex;
            justify-content: center;
            margin: 0 auto;

            & .dln-breadcrumbs {
                display: none;
            }
        }
        &__head {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100vw;
            min-width: 100vw;
            max-width: none;
            background-color: $ui-03;
            padding: 56px 20px 72px 20px;
            margin: 0 0 60px calc(-50vw + 50%);

            & .dln-title,
            & .dln-prodReg__paragraph {
                width: 70%;
                text-align: center;
                padding: 0 15%;
                margin: 0 0 32px 0;
            }
        }
        & .dln-advtBox {
            padding: 15px 0 15px 70px;
            &__title {
                font-size: 16px;
            }
        }
        & .dln-prodReg__subTitle {
            line-height: 24px;
            font-size: 20px;
            text-align: center;
            margin: 0 auto 24px auto;
        }
        &__content {
            margin: 0;
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-prodReg {
            &__head {
                padding-bottom: 48px;

                & .dln-title {
                    margin-bottom: 16px;
                    width: 100%;
                    line-height: 40px;
                    padding: 0;
                }
                & .dln-prodReg__paragraph {
                    width: 100%;
                    line-height: 20px;
                    font-size: 14px;
                    padding: 0;
                }
            }
            & .dln-prodReg__subTitle {
                margin-bottom: 24px;
                line-height: 22px;
                font-size: 18px;
            }
            & .dln-advtBox {
                height: auto !important;
                padding: 0 0 0 66px;

                &__title {
                    line-height: 22px;
                    font-size: 16px;
                }
                &__desc {
                    line-height: 20px;
                    font-size: 14px;
                }
            }
        }
    }
}
.dln-kenwood {
    .dln-checkout {
        .dln-accordion.dln-accordion--payment.dln-accordion--showInfo.dln-accordion--editing {
            .dln-accordion__subTitle {
                display: flex !important;
                padding-left: 50px;
                margin-top: 8px;
            }
        }
    }
}

.dln-kenwood {
    .dln-alert {
        position: relative;
        box-shadow: none;
        padding: 23px 52px 23px 18px;
        border-left: 3px solid $support-04;
        background-color: #fff4e5;
        border-radius: 0;


        &::before {
            @include fontIcon;
            text-indent: 0;
            color: var(--support-02);
            font-size: 38px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }


        .dln-popup__head {
            margin: 0;
            width: calc(100% - 54px);
            margin-left: auto;

            .dln-popup__headTitle {
                padding: 0;
                @include fontSemibold;
                font-size: 12px;
                line-height: 16px;
                color: $support-04;
                width: 100%;

                .dln-icn {
                    display: none
                }
            }

            &--circleClose {
                .dln-popup__headLine {
                    margin: 20px 0 0 0;
                    display: none;
                }
            }
        }

        .dln-btnIcon {
            transform: translate(35px, -5px);
            position: absolute;
            right: 0;

            .dln-icn {
                color: transparent;
                @include definePseudoElems;
                &::before {
                    @include fontIcon;
                    content: 'J';
                    text-indent: 0;
                    color: $brand-04;
                    font-size: 17px;
                }
            }
        }
        &--success {
            border-left: 3px solid $support-02;
            background-color: $support-02--light;
            .dln-popup__head {
                .dln-popup__headTitle {
                    color: $support-02;
                }
            }

            &::before {
                content: 'T';
            }
        }
        &--error {
            border-left: 3px solid $support-01;
            background-color: $support-01--light;

            &::before {
                content: 'g';
                color: $support-01;
            }

            .dln-popup__head {
                .dln-popup__headTitle {
                    color: $support-01;
                }
            }
        }
        &--minimal {
            .dln-popup__head {
                .dln-popup__headTitle {
                    font-size: 14px;
                    line-height: 16px;
                }
                .dln-btnIcon--close {
                    transform: translate(35px, -50%);
                    top: 50%;
                }
            }
        }
        &__copy {
            @include fontLight;
            color: $brand-02;
            font-size: 12px;
            line-height: 16px;
            margin: 0;
        }
        &__subtilte {
            display: none;
        }
        &__content {
            padding: 0;
            width: calc(100% - 54px);
            margin-left: auto;
        }
    }
}

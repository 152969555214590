.dln-kenwood {
    .dln-noticeBox {
        border-color: $ui-08;
        padding: 44px 31px;
        position: relative;

        &__icon {
            align-items: flex-start;
            width: auto;
        }

        & .dln-icn {
            color: $brand-02;
            text-align: left;
        }
        &__content {
            margin-bottom: 5px;

            &:hover {
                .dln-noticeBox__title {
                    text-decoration: underline;
                }
            }

        }
        &__title {
            line-height: 22px;
            font-size: 16px;
            color: $brand-04;
            margin: 0;
        }
        &__copy {
            min-height: 60px;
            line-height: 20px;
            font-size: 14px;
            color: $brand-04;
        }


        &--light {
            .dln-noticeBox__content {
                color: $brand-04;
            }
        }
    }
}


@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-profile {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 32px 0;
        }

        &__aside {
            & .dln-profileCard {
                width: 100%;
                border: none;
                border-bottom: 1px solid $ui-01;
                padding: 15px 20px 15px 20px;
                margin: 0 0 20px 0;

                &__circle {
                    width: 40px;
                    height: 40px;

                    &Text {
                        height: 34px;
                        line-height: 34px;
                        padding: 6px 0 0 0;
                    }
                }
            }
        }
        &__content {

            & .dln-title {
                width: calc(100% - 40px);
                line-height: 34px;
                font-size: 35px;
                padding: 6px 0 0 0;
                margin: 0 20px 32px 20px;
            }
            & .dln-persDataRecap {
                width: calc(100% - 2px);
                margin: 0 0 32px 0;
                box-sizing: content-box;
            }
            & .dln-addBox {
                width: calc(100% - 40px);
                min-height: 209px;
                margin: 0 0 32px 0;

                &--productRegister {
                    margin: 0;
                }
            }
        }
        /*   &__section {
            width: calc(100% - 40px);
            margin: 0 20px 40px 20px !important;
        } */
        &__sectionHead {
            margin: 0 0;
        }
        &__recipes {
            min-height: 216px;
            padding: 64px 20px 64px 20px;
            margin: 0 0 0 0;

            &Subtitle,
            &Link {
                line-height: 20px;
                font-size: 13px;
            }
            &Title {
                line-height: 22px;
                font-size: 17px;
            }
        }
        &:not(.dln-profile--01) {
            padding-right: 0;
            padding-left: 0;
            .dln-profile__content .dln-title {
                margin: 0 20px 32px 20px;
            }
            .dln-wrapper {
                /*  margin-right: 0;
                margin-left: 0;
                padding: 48px 0 0 0; */
                padding-top: 48px;
            }
            .dln-profile__aside,
            .dln-persDataRecap {
                margin-right: 20px;
                margin-left: 20px;
            }
            .dln-profile__registerProduct {
                padding: 30px 20px;
            }
        }
        &:not(.dln-profile--01) {
            padding-right: 0;
            padding-left: 0;
            >.dln-wrapper {
                margin-left: 0;
                margin-right: 0;
                padding-left: 0;
                padding-right: 0;
            }
            .dln-profile__aside {
                margin-right: 20px !important;
                margin-left: 20px !important;
            }
            .dln-profile__content {
                .dln-title {
                    /* margin-right: 10px;
                    margin-left: 10px; */
                    padding-right: 10px;
                    padding-left: 10px;
                }
                & .dln-persDataRecap {
                    margin-right: 20px;
                    margin-left: 20px;
                    width: calc(100% - 48px - 2px - 40px);

                }
            }
            .dln-profile__content .dln-title {
                margin-bottom: 32px;
            }
            .dln-wrapper {
                /* margin-right: 0;
                margin-left: 0;
                padding: 48px 0 0 0; */
                padding-top: 48px;
            }
            /* .dln-profile__aside,
            .dln-persDataRecap {
                margin-right: 20px;
                margin-left: 20px;
            } */
            .dln-profile__registerProduct {
                padding: 30px 20px;
            }
        }
    }
}
.dln-inputs {
    @include small;
    display: inline-block;
    width: 100%;
    text-align: left;
    border: 1px solid $ui-01;
    border-radius: 2px;
    transition: all .4s cubic-bezier(.25, .8, .25, 1);
    // margin: 16px 0;
    position: relative;

    &--active {
        border-color: $ui-02;
        // background-color: red;

        & .dln-inputs__lbl {

            @media (-ms-high-contrast: none) {
                transform: translateY(-28px) scale(0.75);
                transition: top .4s ease-in-out, transform .4s ease-in-out, color .4s ease-in-out, width .4s ease-in-out;
                top: 50%;
            }
            width: auto;
            background-color: $brand-03;
            transform: translateY(calc(50% - 28px)) scale(.75);
        }
    }
    &--disabled {
        display: inline-flex;
        flex-wrap: wrap;
        border-color: transparent;

        & .dln-inputs__lbl {
            display: inline-flex;
            order: -1;
            align-items: center;
            width: auto;
            max-width: 50%;
            transform: none;
            position: relative;
            top: auto;
            left: auto;
        }
        & .dln-inputs__input {
            width: auto;
            max-width: 50%;
            height: auto;
            padding: 0;
            margin: 0;

            &[value] {
                @include fontBold;
                color: $brand-04;
            }
        }
    }
    &__button {
        outline: none;
        width: auto;
        border: none;
        background-color: transparent;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        user-select: none;
    }
    &__input {
        @include small;
        outline: 0;
        width: 100%;
        height: 38px;
        line-height: 16px;
        border: none;
        background-color: transparent;
        padding: 12px 8px 8px;
        box-sizing: border-box;

        &::placeholder {
            @include extraSmall;
            color: $ui-02;
            opacity: 0;
        }
        &[disabled] {
            opacity: .8;
            &+.dln-inputs__lbl {}
        }
        &:-webkit-autofill,
        &:-moz-autofill {
            &~.dln-inputs__lbl {
                width: auto;
                transform: translateY(calc(50% - 28px)) scale(.75);
            }
        }
    }
    &__lbl {
        @include small;
        font: inherit;
        display: inline-block;
        width: calc(100% - 16px);
        color: $brand-02;
        white-space: nowrap;
        text-overflow: ellipsis;
        transform-origin: 0 0;
        transition: top .4s cubic-bezier(.25, .8, .25, 1), transform .4s cubic-bezier(.25, .8, .25, 1), color .4s cubic-bezier(.25, .8, .25, 1), width .4s cubic-bezier(.25, .8, .25, 1);
        padding: 0 4px;
        box-sizing: border-box;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 8px;
    }
    &__extraLbl {
        @include small;
        display: block;
        color: $brand-02;
        white-space: nowrap;
        text-overflow: ellipsis;
        transform: translate(12px, 0) scale(0.75);
        transform-origin: 0 0;
        transform-origin: bottom left;
        overflow: hidden;
        position: absolute;
        top: 40px;
        bottom: auto;
        left: 0;

        &:not(.dln-inputs__extraLbl--link) {
            pointer-events: none;
        }
        &--link {
            transform: translate(0, 24px) scale(1);
            right: 0;
        }
        a {
            color: $brand-02;
        }
    }
    .dln-btnIcon {
        background: none;
        // background: lightblue;
        background-color: transparent;
        position: absolute;
        top: -1px;
        right: -1px;
    }
    &__button {
        position: absolute;
        top: calc(50% - 8px);
        right: 16px;
    }
    .dln-tooltip {
        position: absolute;
        top: calc(50% - 8px);
        right: 16px;

        .dln-icn {
            color: $ui-01;
        }
    }

    &--icon {
        & .dln-inputs__input {
            padding-right: 40px;
        }
        .dln-icn {
            pointer-events: none;
        }
    }
    .dln-inputs__autocompleteContainer {
        display: none;
        width: calc(100% + 2px);
        border: 1px solid $ui-02;
        border-width: 0 1px 1px;
        background-color: $brand-03;
        box-shadow: 0 2px 6px rgba(255, 255, 255, 0.6);
        visibility: hidden;
        margin-left: -1px;
        box-sizing: border-box;
        overflow: hidden;
        position: absolute;
        z-index: 9;

        .dln-inputs__autocompleteItem {
            display: block;
            line-height: 30px;
            font-size: 14px;
            color: $brand-02;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            background: none;
            border: none;
            border-top: 1px solid $ui-01;
            padding: 6px 8px;
            overflow: hidden;
            width: 100%;
            cursor: pointer;

            &:hover {
                background-color: #fafafa;
            }

            &:empty {
                display: none;
            }
        }
    }
    &--writing {
        .dln-inputs__autocompleteContainer {
            display: block;

            height: auto;
            max-height: 240px;
            visibility: visible;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    &--autocomplete {

        .dln-inputs__autocompleteTable {
            display: none;
        }
        &.dln-inputs--active {
            & .dln-inputs__autocompleteTable {
                display: block;
            }
        }
        &.dln-inputs--writing {
            border-radius: 2px 2px 0 0;
            /* border-width: 1px 1px 0; */
        }
    }
    &--icon {
        &>.dln-icn {
            width: 16px;
            height: 16px;
            font-size: 16px;
            color: $ui-01;
            cursor: pointer;
            position: absolute;
            top: calc(50% - 8px);
            right: 16px;
        }
    }
    &--datepicker {
        .dln-inputs__input {
            &::-webkit-datetime-edit {
                opacity: 0;
            }
        }
        &.dln-inputs--active {
            .dln-inputs__input {
                &::-webkit-datetime-edit {
                    opacity: 1;
                }
            }
        }
        .dln-btnIcon {
            pointer-events: none;
        }
    }
    &--showHideValue {
        .dln-icn {
            &::after {
                content: '';
                width: 2px;
                height: 24px;
                color: $brand-04;
                background: $ui-01;
                opacity: 0;
                transform: rotate(45deg);
                transition: all .3s linear;
                position: absolute;
                top: -5px;
                left: 5px;
            }
            &.-showPassw {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    &--validate {
        .dln-icn {
            color: $support-02;
            opacity: 0;
            transform: scale(.5);
        }
        &.dln-inputs--validated {
            .dln-icn {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
    &--invalid,
    &--successful {
        border: 1px solid;

        .dln-inputs__extraLbl {
            &--error,
            &--success {
                display: block;
                font-size: 10px;
                transform: none;
                padding: 3px 0 0 0;
                left: 0;
                right: auto;
            }
        }
    }
    &--invalid {
        border-color: $support-01;

        .dln-inputs__lbl,
        .dln-inputs__extraLbl--error {
            color: $support-01;
        }
    }
    &--successful {
        border-color: $support-02;

        .dln-inputs__lbl,
        .dln-inputs__extraLbl--success {
            color: $support-02;
        }
    }
    &--searchBar {
        border: 1px solid $ui-02;
        background-color: $brand-03;

        .dln-inputs__input {
            padding-left: 40px;

            &::-webkit-search-cancel-button {
                width: 16px;
                height: 16px;
                background-image: url('/assets/images/icons/icon-close-circle-ui-02.svg');
                background-repeat: no-repeat;
                background-size: 16px;
                background-position: center;
                -webkit-appearance: none;
                position: relative;
                z-index: 1;
            }
        }
        .dln-inputs__button {
            top: calc(50% - 12px);
            left: 8px;
            right: auto;
        }
        .dln-icn {
            font-size: 25px;
            color: $support-02;
        }

        .dln-inputs__input-has-value.dln-icn {
            font-size: 16px;
            color: $ui-02;
            transform: translateY(-50%);
            position: absolute;
            top: 50%;
            right: 37px;
        }
    }
    .dln-select {
        /* position: absolute; */
        display: none;
        width: auto;
        top: 0;
        left: 0;

        &__head {
            text-align: center;
            border-width: 0 1px 0 0 !important;
            padding: 8px;
        }
        &__container {
            @include small;
            height: 34px;
        }
    }
    &--selectInside {
        display: flex;
        align-items: center;

        .dln-select {
            display: inline-block;
            order: -1;
            width: 80px;
        }
        .dln-inputs__input {
            width: calc(100% - 80px);
        }
        .dln-inputs__lbl {

            @media (-ms-high-contrast: none) {
                transform: translateY(-28px) scale(0.75);
                transition: top .4s ease-in-out, transform .4s ease-in-out, color .4s ease-in-out, width .4s ease-in-out;
                top: 50%;
            }
            width: auto;
            background-color: $brand-03;
            transform: translateY(calc(50% - 28px)) scale(.75);
        }
    }
    &--phoneprefix {
        /* padding: 0 0 0 80px; */
    }
    &--loadFile {
        border: none;
        cursor: pointer;

        .dln-inputs__input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            /* padding: 0 0;
            color: transparent;
            opacity: 0; */
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
        & [type="button"],
        & ::-webkit-file-upload-button {
            /* @extend .dln-btn;
            max-width: 130px; */
        }
        .dln-btn {
            width: 130px;
        }
        .dln-inputs__lbl {
            display: inline-flex;
            align-items: center;
            width: calc(100% - 140px);
            height: 100%;
            border: 1px solid $ui-01;
            transform: translateY(-50%) translateX(140px) scale(1);
            padding: 0 24px;
            box-sizing: border-box;
            cursor: pointer;
            top: 50%;
            left: 0;
        }
    }
    /* google autocomplete searchbar */
    &--googleAutocomplete {
        &.dln-inputs--searchBar {
            & .dln-inputs__lbl {
                // display: none;
                left: 40px;
            }
        }
    }
    &--rightIcon {
        &.dln-inputs--active {
            .dln-icn {
                color: $support-02;
            }
        }
        .dln-icn {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            color: $brand-01;
            top: 1px;
        }

        .dln-inputs__button {
            left: auto;
            right: 8px;
        }
        .dln-inputs__input {
            padding-right: 40px;
            padding-left: 8px;
        }
    }
}
.pac-logo:after {
    display: none !important;
}
.pac-container {
    border: 1px solid $ui-02;
    border: 0;
    border-radius: 0;
    border-width: 0 1px 1px;
    box-shadow: 0 2px 6px rgba(255, 255, 255, 0.6), 0 0 0 1px var(--ui-02);
    box-sizing: border-box;

    & .pac-item {
        border-color: $ui-01;
        padding: 6px 8px;
        cursor: pointer;

        &:first-child {
            border-top: 0;
        }

        & .pac-icon.pac-icon-marker {
            display: none;
        }
    }
}

.pca {
    .pcanotification {
        display: none !important;
    }
    .pcaautocomplete {
        border-width: 0px 1px 1px 1px !important;
        border-color: $ui-02 !important;
        box-shadow: none !important;

        .pcanotification {
            display: none !important;
        }
        & .pcalist {
            max-height: 150px;
            background-color: $brand-03;

            &::-webkit-scrollbar {
                width: 5px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: $brand-03;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background: $brand-04;
            }
        }
        & .pcaitem {
            border-bottom-color: $ui-01;
            padding: 10px 22px 10px 10px;

            &.pcafirstitem {
                border-top-color: $ui-01;
            }
        }
    }
}

*[class^="dln-"] {
    /* Change autocomplete styles in WebKit */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: $brand-04;
        -webkit-box-shadow: 0 0 0px 1000px $brand-03 inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

.flatpickr-next-month,
.flatpickr-prev-month {
    &:hover {
        svg {
            fill: $ui-02 !important;
        }
    }

}

@import "./_input-fields-kenwood.scss";
.flatpickr-day {
    &.today {
        border-color: $support-02 !important;
    }
    &.selected {
        color: $brand-03 !important;
        border-color: $support-02 !important;
        background-color: $support-02 !important;
    }
    &:not(.selected):hover {
        color: $brand-04 !important;
        background-color: $ui-03 !important;
    }
}

@import "./_input-fields-delonghi.scss";

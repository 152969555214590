@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    // .dln-recipe {
    //     &--big-card {
    //         .dln-recipe__title {
    //             font-size: 15px;
    //             line-height: 19px;
    //             padding-bottom: 7px;
    //         }
    //         .dln-recipe__details {
    //             font-size: 13px;
    //         }
    //     }
    // }

    .dln-recipe {
        &__title {
            & a {
                line-height: 19px;
                font-size: 15px;
            }
        }
    }
}

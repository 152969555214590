.dln-breadcrumbs {
    width: 100%;
    margin: 0 0 16px;
    font-size: 15px;

    ol {
        list-style: none;
        display: inline-flex;
    }
    &__li {
        display: none;
        position: relative;
        color: $brand-01;
        &.previousActive {
            display: inline-flex;

            @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                &:before {
                    content: '‹';
                    display: inline-flex;
                    margin: 0 .6em 0 0;
                    text-align: center;
                    position: relative;
                    bottom: 1px;
                    font-weight: 700;
                }
            }
        }

        @media only screen and (min-width:map-get($grid-breakpoints, md)) {
            display: inline-flex;
        }

        &:not(:first-child) a:before,
        &:not(:first-child) span:before {
            content: '‹';
            display: inline-flex;
            margin: 0 .6em 0 0;
            text-align: center;
            position: relative;
            bottom: 1px;

            @media only screen and (min-width:map-get($grid-breakpoints, md)) {
                content: '•';
                margin: 0 .6em;
            }
        }

        &:not(:first-child):not(.active) {
            display: inline-flex;
        }

        a,
        span {
            @include fontBold;
            color: $brand-01;
            text-decoration: none;
            transition: color 0.22s linear 0.08s;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
        }

        @include hover-supported() {
            a:hover {
                color: $support-02;
                text-decoration: underline;

                &::before {
                    text-decoration: none;
                }
            }
        }
    }
}

// reset styles for delonghi and kenwood
.dln-kenwood,
.dln-delonghi {
    .dln-breadcrumbs__li {
        color: $ui-07;

        &:not(:first-child) a:before,
        &:not(:first-child) span:before {
            display: none;
        }

        &:not(:first-child):before {
            content: '/';
            color: $brand-02;
            display: inline-flex;
            margin: 0 0.3em;
        }

        a:hover {
            color: $brand-02;
        }
        a,
        span {
            @include fontRoman;
            color: $brand-02;
            text-decoration: none;
        }
    }
}

@import "_breadcrumbs-kenwood.scss";
@import "_breadcrumbs-delonghi.scss";
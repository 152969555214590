.dln-recipe-details {
    width: 100%;
    border-radius: 5px;
    background: $ui-03;
    margin-top: 40px;
    position: relative;

    .dln-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1300px;
        padding: 0 30px !important;
        margin: 0 auto;
    }

    .dln-image {
        max-height: 450px;
        border-radius: 5px;
        overflow: hidden;
    }

    .dln-btn {
    //     width: 100%;
    //     max-width: 230px;
    //     background-color: $brand-01;
    //     color: $brand-03;
    //     text-decoration: none;
    //     height: 40px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     &__lbl {
    //         @include fontBold;
    //         text-decoration: none;
    //         margin-left: 5px;
    //     }
        // text-decoration: none;

        &[title="Print"] {
            width: auto;
            height: auto;
            color: $brand-01;
            background-color: transparent;
        }
    }

    .dln-btnIcon--bookmark-active {
        .dln-icn {
            color: $support-02;
        }
    }
    .dln-btnIcon {
        width: 40px;
        height: 40px;
        font-size: 18px;
        border-radius: 50%;
        background-color: $brand-03;
        position: absolute;
        top: 20px;
        right: 50px;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-height: 450px;
        padding-top: 0;
        margin-right: -25px;
        margin-left: -25px;
        &-inverse {
            flex-direction: row-reverse;
            border-radius: 5px;

            .dln-recipe-page__info,
            .dln-recipe-page__bottom-info {
                padding-right: 50px;
            }
        }
    }
    &__col {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        min-width: 50%;
        max-width: 50%;
        padding: 0 25px;
        position: relative;
    }

    &__bottom-info {
        display: flex;
        height: 70px;
        margin-bottom: 50px;

        &-col + .dln-recipe-details__bottom-info-col  {
            border-left: 1px solid #e6e6e6;
            padding-left: 17.5px;
            margin-left: 17.5px;
        }

        &-label,
        &-value {
            line-height: 1.4;
            font-size: 15px;
        }
        &-label {
            color: $brand-02;
        }
        &-value {
            @include fontBold;
        }

        .dln-icn {
            height: auto;
            font-size: 40px;
            color: #9b9b9b;
            margin-right: 15px;
        }
    }

    &__bottom-info-col {
        display: flex;
        align-items: center;
    }

    &__title-recipe {
        @include fontBold;
        line-height: 1.22;
        font-size: 36px;
        margin-top: 70px;
    }
    &__description {
        margin-top: 16px;
        margin-bottom: 30px;

        & > p {
            display: block;
            width: 100%;
            font-size: 15px;
            line-clamp: 21px;
        }
    }

    &__videoBgContainer {
        display: none;
        width: auto;
        height: 450px;
        position: relative;
        background-color: $brand-01;

        &.show {
            display: block;
        }
    }

    &__videoBg {
        width: 100%;
        height: auto;
        max-height: 100%;
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 0;
    }

    &__image-container {
        width: 100%;
        height: 100%;
        position: relative;

        &.hide {
            display: none;
        }
    }

    &__play-button {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: $brand-03;
        transform: translate(-50%, -50%);
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;

        &:before {
            content:"\A";
            border-style: solid;
            border-width: 20px 35px 20px 0;
            border-color: transparent $brand-01 transparent transparent;
            transform: rotate(180deg) translate(40%, 50%);
            position: absolute;
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }
    &__info-button-mobile {
        display: none;

        & .dln-btn {
            line-height: 24px;
            box-sizing: border-box;
        }
    }
    &__info-button-desktop {
        display: inline-flex;
        width: auto;
        height: auto;

        & .dln-btn {
            line-height: 24px;
            box-sizing: border-box;
        }
    }

    &--constrain {
        max-width: 1300px;
        background: none;
        margin: 0 auto;

        .dln-wrapper {
            border-radius: 5px;
            background: $ui-03;
        }
    }

    &--inverted {
        .dln-wrapper {
            width: 100% !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .dln-recipe-details__grid {
            flex-direction: row-reverse;
            border-radius: 5px;

            .dln-recipe-details__info,
            .dln-recipe-details__bottom-info {
                padding-right: 50px;
            }
        }
    }
}

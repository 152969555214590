.dln-address {
    align-content: flex-start;

    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {
        & .dln-title {
            margin: 0 0 32px 0;
            text-align: center;
            @media only screen and (max-width: 599px) {
                display: none !important;
            }
        }
        & .dln-addBox {
            width: calc(50% - 40px - 2px - 8px);
            margin: 0 16px 40px 0;
            box-sizing: content-box;
        }
        .dln-page__content {
            @media only screen and (max-width: 599px) {
                padding-top: 0px;
            }
        }
        .dln-breadcrumbs {
            @media only screen and (max-width: 599px) {
                display: none;
            }
        }

        @media only screen and (max-width: 599px) {
            .dln-address__title {
            line-height: REM(19px);
            font-size: REM(15px);
        }
        

        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        width: 100%;

        @media only screen and (max-width:599px) {
            .dln-addBox__button {
            .dln-icn {
                font-size: REM(15px);
            }

            .dln-addBox__title {
                font-size: REM(15px);
                line-height: REM(19px);
            }
        }
        }
        
    }
    & .dln-addBox {
        width: calc(50% - 40px - 2px - 10px);
        min-height: 184px;
        margin: 0 0 20px 0;
    }
    & .dln-addressBox {
        width: calc(50% - 10px);
        margin: 0 20px 20px 0;
        &:nth-child(even) {
            margin: 0 0 20px 0;
        }
    }
    &__title {
        margin: 26px 0;
        font-size: REM(24px);
        line-height: REM(30px);
    }
}
@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-regAdv {
        & .dln-icn {
            width: 32px;
            font-size: 32px;
            margin: 0 20px 0 0;
        }
        &__textWrap {
            width: calc(100% - 32px - 20px);
        }
    }
}
.dln-delonghi {
  .dln-recipe {
    max-width: initial;

    &__img {
      margin: 0;
      padding: 0;
      height: 0;
      padding-bottom: 100% !important;

      .dln-image {
        border-radius: 0;
      }

      .dln-btnIcon {
        margin: 0px 0px 0px auto;
        .dln-icn {
          background-color: white;
          color: $brand-01;

          width: 40px;
          height: 40px;

          &::before {
            font-size: REM(20px);
          }
        }
        &--bookmark-active {
          .dln-icn {
            color: $brand-01;
          }
        }

        &--close {
          .dln-icn {
            color: $ui-02;
          }
        }
      }
    }

    &__title {
      margin: 16px 0;

      a {
        @include fontMedium;
        font-size: REM(21px);
        line-height: 1.14;
        color: $brand-04;
      }

      @media only screen and (max-width: 599px) {
        font-size: REM(18px);
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 12px;
      }
    }

    &__details {
      @include fontLight;
      color: $ui-02;
    }
  }
}

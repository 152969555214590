.dln-kenwood {
    & .dln-page.dln-order {
        &--history {
            & .dln-wrapper {
                padding-top: 32px;

                & .dln-order__aside {
                    padding-top: 0;
                }

                & .dln-order__content {
                    padding: 0;

                    & .dln-title {
                        margin-bottom: 14px;
                    }

                    & .dln-ordersHist {
                            &__title,
                            &__subTitle {
                            @include fontExtra;
                            text-align: center;
                        }

                        &__subTitle {
                            font-size: 28px;
                            font-weight: 400;
                        }

                        &__paragraph {
                            text-align: center;
                        }

                        &__foot {
                            &--divider {
                                border-top: none;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        & .dln-page.dln-order {
            &--history {
                & .dln-wrapper {

                    &.dln-vertMenu__container { 
                       width: 100% !important;
                       display: block !important;
                    }

                    & .dln-order__content {
                        width: 100%;
                        padding-top: 42px;
                    }
                }
            }
        }

        & .dln-page--myArea {

            & .dln-profileCard {
                display: none !important;
            } 

            & .dln-vertMenu.dln-vertMenu--open {
                & .dln-vertMenu {
                    &__container { 
                        margin-top: 100px;
                    }

                    &__list {
                        margin: 0 0 40px;
                    }
                }
            }

            & .dln-vertMenu__list {
                margin: 0;
            }

            & .dln-vertMenu__li.dln-vertMenu__li--active {
                display: block !important;
                padding-bottom: 10px;
                padding-right: 3px;
            }
        }
    }
}

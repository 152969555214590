.dln-addNewCard {
    padding: 0 20px 20px;
    .dln-addNewCard__content {
        padding: 0 0 0;
    }
    .dln-divider~.dln-inputs {
        margin-bottom: 0;
    }
    &__foot {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 32px 0 0;
    }
    .dln-addNewCard__form {
        display: flex;
        flex-wrap: wrap;
        &>.dln-select {
            width: calc(50% - 8px);
            max-width: none;
            margin: 0 0 32px 0;
        }
        .dln-inputs {
            // width: calc(100% - 8px);
            margin: 0 0 32px 0;
            &:last-of-type {
                margin: 0 0;
            }
            &--oneThird {
                max-width: 250px;
                margin: 0 auto 32px 0;
                &+.dln-btn {
                    width: auto;
                    max-width: calc(100% - 250px - 24px);
                    margin: 0 0 32px 24px;
                }
            }
        }
        .dln-chips {
            margin: 0 0 24px;
        }
        &>.dln-checkbox {
            margin: 16px 0 0;
        }

        .dln-checkout__paragraph {
            margin: 24px 0;
        }

        .dln-btn {
            width: calc(50% - 8px);
            margin: 0 16px 0 0;
            &:nth-of-type(even) {
                margin: 0 0 0;
            }
        }
    }
}
.dln-icn {
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    width: auto;
    height: 24px;
    font-style: normal;
    transition: all .3s ease;
    position: relative;
    margin: 0;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: auto 95%;
        background-position: 50% 50%;
        position: absolute;
        top: 0;
        left: 0;
    }
    &--iconfont {
        width: auto;
        height: auto;
        line-height: normal;
        width: auto;
        font-size: 16px;
    }
    &--pw {
        color: black;
        display: block;
        position: absolute;
        margin: -41 0 0 334;
        cursor: pointer ;
        transform: translateY(-50%);
        right: 20px;
        user-select: none;
    } 
}








[class*="bra-icn__"]:before {
font-family: $fontNameIconsBraunNew;
font-style: normal;
font-weight: normal;
speak: none;

display: inline-block;
text-decoration: inherit;
width: auto;
text-align: center;
/* opacity: .8; */

/* For safety - reset parent styles, that can break glyph codes*/
font-variant: normal;
text-transform: none;

/* fix buttons height, for twitter bootstrap */
line-height: 1.07em;

/* Animation center compensation - margins should be symmetric */
/* remove if not needed */

/* you can be more comfortable with increased icons size */
/* font-size: 120%; */

/* Font smoothing. That was taken from TWBS */
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;

/* Uncomment for 3D effect */
/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.bra-icn__account:before { content: '\e800'; } /* '' */
.bra-icn__hamburger-menu:before { content: '\e801'; } /* '' */
.bra-icn__cart:before { content: '\e802'; } /* '' */
.bra-icn__logo:before { content: '\e804'; } /* '' */
.bra-icn__search:before { content: '\e805'; } /* '' */
.bra-icn__social--facebook:before { content: '\e806'; } /* '' */
.bra-icn__social--twitter:before { content: '\e807'; } /* '' */
.bra-icn__social--youtube:before { content: '\e808'; } /* '' */
.bra-icn__chat:before { content: '\e80d'; } /* '' */
.bra-icn__arrow--down:before { content: '\e803'; } /* '' */
.bra-icn__arrow--left:before { content: '\e80e';} /* '' */
.bra-icn__arrow--right:before { content: '\e80f';margin-right: 0;} /* '' */
.bra-icn__arrow--up:before { content: '\e810'; } /* '' */
.bra-icn__plus:before { content: '\e809'; } /* '' */
.bra-icn__no:before { content: '\e80a'; } /* '' */
.bra-icn__yes:before {
    content: '\e80b';
    position: relative;
    font-size: 90%;
}

.bra-icn__wishlist:before { content: '\e832'; }
.bra-icn__print:before { content: '\e80c'; }
.bra-icn__share:before { content: '\e811'; }
@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-contactUs {
        & .dln-wrapper {
            padding-right: 20px;
            padding-left: 20px;
            margin: 0;

            &--fullWidth {
                padding-top: 32px;
                padding-bottom: 24px;
                margin-bottom: 20px;
            }
        }
        &__head {            
            padding: 0 !important;
        }
        &__form {
            padding-bottom: 0 !important;
        }
    }
}

.dln-delonghi {
  .dln-contactUs--thankYou {
    & .dln-btn--backToContactUs {
      @include fontMedium;
      line-height: 20px;
      font-size: REM(14px);
    }
    & .dln-wrapper {
      &--thankYouMessage {
        & .dln-contactUs__title {
          line-height: 34px;
          font-size: REM(28px);
        }
        & .dln-contactUs__thankYouMessage {
          &Text {
            line-height: 24px;
            font-size: REM(18px);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-contactUs--thankYou {
      & .dln-wrapper {
        &--fullWidth {
          padding-bottom: 0;
          margin-bottom: 32px;
        }
        &--thankYouMessage {
          & .dln-contactUs__title {
            line-height: 23px;
            font-size: REM(21px);
            margin-bottom: 24px;
          }
          & .dln-contactUs__thankYouMessage {
            padding-bottom: 24px;
            margin-bottom: 24px;

            &Text {
              line-height: 24px;
              font-size: REM(16px);
            }
            & .dln-btn--backToContactUs {
              line-height: 21px;
              font-size: REM(15px);
            }
          }
        }
      }
    }
  }
}

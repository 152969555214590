.dln-filterDropdown {
    display: inline-flex;
    flex-wrap: wrap;
    width: auto;
    position: relative;
    z-index: 4;

    &__trigger {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: $brand-03;
        width: auto;
        height: 21px;
        border: none;

        &Text {
            display: inline-block;
            width: auto;
            line-height: 21px;
            font-size: 15px;
            margin: 0 8px 0 0;
            pointer-events: none;
        }
        & .dln-icn--arrow-top {
            display: inline-block;
            width: 16px;
            height: 10px;
            line-height: 10px;
            transform: rotateX(180deg);
            transform-origin: center;
            transition: transform 0.22s ease;
            pointer-events: none;
        }
    }
    &__dropdown {
        display: flex;
        flex-wrap: wrap;
        width: 250px;
        max-height: 300px;
        overflow: hidden;
        overflow-y: auto;
        border-radius: 6px;
        background-color: $brand-03;
        opacity: 0;
        transform: translateY(-16px);
        transform-origin: 0 0;
        transition: transform 0.18s ease, opacity 0.12s linear;
        padding: 0 18px 30px 18px;
        box-sizing: border-box;
        pointer-events: none;
        position: absolute;
        top: 30px;
        left: 0;
        z-index: 13;
        box-shadow: 0 4px 7px 0 rgba(82, 90, 102, 0.46);
        &--pushToLeft {
            left: auto;
            right: 0;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        list-style: inside none;
        padding: 4px 0 0 0;
        // background: rgba(black, .1);
        margin: 0 0 30px 0;
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        min-height: 48px;
        border-bottom: 1px solid $ui-01;
        padding: 15px 0;
        box-sizing: border-box;
        position: relative;
        z-index: 14;

        &--disabled {
            opacity: .25;
            pointer-events: none;
        }
    }
    &__checkbox {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 15;
    }
    &__label {
        width: calc(100% - 8px - 16px);
        line-height: 18px;
        font-size: 15px;
        margin: 0 auto 0 0;
        pointer-events: none;
    }
    &__placeholder {
        width: 16px;
        height: 14px;
        margin: 0 0 0 8px;
        pointer-events: none;

        & .dln-icn--checkOutline {
            width: 16px;
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            transform: scale(0);
            transform-origin: center;
            transition: transform 0.12s ease;
        }
    }
    &__actions {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        & .dln-btn {
            width: 100px;
            padding: 0;
            margin: 0 0 0 auto;

            &--brand-03 {
                display: inline-flex;
                width: auto;
                border-radius: 0;
                background: none;
                background-color: transparent;
                padding: 0;
                margin: 0 auto 0 0;
                font-size: 15px;
            }
        }
    }
    &__checkbox:checked {
        & + .dln-filterDropdown__label {
            @include fontBold;

            & + .dln-filterDropdown__placeholder {
                & .dln-icn--checkOutline {
                    transform: scale(1);
                }
            }
        }
    }
    &--range {
        & .dln-filterDropdown__dropdown {
            width: 350px;
            padding: 30px 18px 30px 18px;
        }
    }
    &--search {
        & .dln-filterDropdown__dropdown {
            width: 350px;
            padding: 18px 18px 30px 18px;

            & .dln-inputs--searchBar {
                margin: 0 0 20px 0;
            }
        }
    }
    &--rightOrigin {
        & .dln-filterDropdown__dropdown {
            left: initial;
            right: 0;
        }
    }
    &--open {
        z-index: 20;

        & .dln-filterDropdown__dropdown {
            opacity: 1;
            transform: translateY(0);
            pointer-events: initial;
        }
        & .dln-icn--arrow-top {
            transform: rotateX(0deg);
        }
    }

    /* input range */
    & .dln-filterDropdown__range {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding: 0 10px;
        margin-bottom: 20px;
        box-sizing: border-box;

        &Bar {
            width: 100%;
            // max-width: calc(366px - 16px);
            margin-bottom: 20px;
            position: relative;

            &::before,
            &::after {
                content: '';
                width: 8px;
                height: 6px;
                background-color: $ui-07;
                position: absolute;
                top: calc(50% - 3px);
                left: -8px;
            }
            &::after {
                left: initial;;
                right: -8px;
            }
        }
        &LabelWrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 15px;
            position: relative;

            & .dln-filterDropdown__rangeLabel {
                display: inline-block;
                height: 15px;
                line-height: 15px;
                transform: translateX(-50%);
                position: absolute;
                top: 0;

                &.-min {
                    left: 0;
                }
                &.-max {
                    // transform: translateX(-50%);
                    left: 100%;
                }
            }
        }
    }
    & .dln-filterDropdown__rangeBar.noUi-target.noUi-ltr.noUi-horizontal {
        height: 6px;
        border: none;
        border-radius: 0;
        background-color: $ui-07;
        box-shadow: none;
    }
    & .noUi-connect {
        background-color: $brand-01;
    }
    & .noUi-handle.noUi-handle-lower,
    & .noUi-handle.noUi-handle-upper {
        width: 20px;
        height: 20px;
        border: 1px solid $ui-09;
        border-radius: 50%;
        box-shadow: none;
        box-sizing: border-box;
        top: -6px;
        right: -10px !important;
    }
    & .noUi-handle.noUi-handle-lower::before,
    & .noUi-handle.noUi-handle-lower::after,
    & .noUi-handle.noUi-handle-upper::before,
    & .noUi-handle.noUi-handle-upper::after {
        display: none;
    }
}

@import './_filter-dropdown-delonghi.scss';

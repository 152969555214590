$custom-disabled-color: #f5f5f5;

.dln-itemRegDetails {
    width: 100%;
    &__head {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 16px;
    }
    &__itemName {
        @include large;
        @include fontBold;
        width: calc(100% - 120px);
        margin: 0 auto 0 0;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 16px 0 0;
    }
    &__itemImg {
        display: flex;
        align-content: center;
        width: 100%;
        max-width: 349px;
        height: 305px;
        background-color: $ui-03;
        margin: 0 20px 0 0;
        position: relative;
        z-index: 1;

        &::after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.04);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
        & > img {
            display: inline-block;
            width: 100%;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            margin: 0 auto;
            object-fit: contain;
        }
    }
    &__paragraph {
        @include small;
        color: $brand-02;
    }
    &__sku {
        @include small;
        width: auto;
        max-width: 120px;
        font-size: 14px;
        color: $brand-02;
    }
    &__details {
        width: calc(100% - 385px - 20px);
        .dln-itemRegDetails__title {
            @include cta;
            @include fontBold;
            font-size: 18px;
            margin: 0 0 32px;
        }
        .dln-inputs,
        .dln-inputsFile,
        .dln-select {
            width: 100%;
            max-width: 100%;
            margin: 0 0 32px;
        }

        &--extra {
            width: 100%;
            margin: 16px 0 0;
            .dln-itemRegDetails__paragraph {
                width: 385px;
            }
        }
    }
    .dln-tooltip {
        &__container {
            transform: none;
            left: auto;
            right: -24px;
        }
        &__arrow {
            margin: 0 24px 0 auto;
        }
    }
    &--productRegistration {
        .dln-itemRegDetails__itemImg {
            max-width: 450px;
        }
        .dln-itemRegDetails__details {
            width: calc(100% - 450px - 20px);
            max-width: 400px;
            margin: 0 0 0 auto;
            &--extra {
                width: 100%;
                margin: 16px 0 0;
                .dln-itemRegDetails__paragraph {
                    width: 450px;
                }
            }
        }
    }

    .dln-inputs__input[disabled],
    .dln-select--disabled,
    .dln-btn--disabled+.dln-inputsFile__label {
        opacity: 1 !important;
        background-color: $custom-disabled-color !important;
    }

    &__sn {
        margin-top: 32px;

        .dln-inputs {
            margin-bottom: 0 !important;
        }

        > a {
            font-size: 10px;
            color: $ui-02;
            text-decoration: underline;
        }
    }
}

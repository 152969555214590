.dln-delonghi {
  .dln-recipe-by-products {
    &__search-section {
      margin: 0 auto;
      text-align: center;
    }

    &__title {
      @include fontMedium;
      font-size: REM(37px);
      line-height: 1;
      letter-spacing: -0.7px;
    }

    &__browse-product {
      font-size: REM(18px);
      line-height: 1.33;
    }

    @media only screen and (max-width: 599px) {
      &__title {
        font-size: REM(28px);
        line-height: 1.07;
        letter-spacing: -0.53px;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-recipe-by-products {
      &__title {
        font-size: REM(28px);
        line-height: 1.07;
        letter-spacing: -0.53px;
      }
    }
  }
}

.dln-image {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    // background-color: $ui-03;
    background-color: $brand-03;
    overflow: hidden;
    position: relative;

    @media (-ms-high-contrast: none) {
        position: relative;
        height: auto;
    }

    &__image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        // position: absolute;
        // top: 0;
        // left: 0;

        @media (-ms-high-contrast: none) {
            position: relative;
            min-width: 100%;
            min-height: 100%;
            max-height: none;
            max-height: initial;
            width: 100%;
            height: auto;

            align-self: center;
            flex-shrink: 0
        }
    }
}

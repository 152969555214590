.dln-kenwood {
    .dln-dropdown {
        &__mainTitle {
            font-size: 16px;
            line-height: 22px;
        }
        &__head {
            padding-bottom: 20px;
        }
        &__content {
            padding-top: 20px;
        }
        &__liHeading {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 20px;
            color: $ui-07;
        }
        &__list {
            &--closing {
                margin: 50px 0 0 0 !important;
                padding-top: 20px;
                border-top-width: 1px;
            }
        }
        &__li {
            &:nth-last-child(1) {
                & a {
                    margin-bottom: 0;
                }
            }
            & a {
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 20px;
                padding: 0;
            }
        }
        &--userArea {            
            max-width: 500px;

            & .dln-dropdown__wrapper {                
                padding: 40px 40px 60px 40px;

                & .dln-dropdown__li {                    
                    &Heading {
                        @include fontRoman;
                    }
                    & a {
                        color: $brand-04;
                    }
                }
            }
        }
    }
}
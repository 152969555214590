.dln-braun {
  .dln-popup__content {
    .dln-popup__wrapper {
      .dln-addAccessories {
        &__content {
          &--extra {
            .dln-addItem {
              .dln-btn {
                &--support02 {
                  border: solid 1px $support-02;
                }

                .dln-btn__lbl {
                  color: $brand-02;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dln-colorRadioBtn {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;

    &__input {
        display: block;
        width: 100%;
        height: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 34;
    }
    &__placeholder {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid $ui-09;
        border-radius: 50%;
        margin: 0 10px 0 0;
        padding: 2px;
        background-clip: content-box;
        box-sizing: border-box;
        position: relative;
        z-index: 2;


        // &::after {
        //     display: none;
        //     content: '';
            // display: inline-block;
            // display: none;
            // width: 14px;
            // height: 14px;
            // border-radius: 50%;
            // background-color: $ui-09;
            // position: absolute;
            // top: calc(50% - 7px);
            // left: calc(50% - 7px);
            // z-index: 3;
        // }
    }
    &__input:checked + .dln-colorRadioBtn__placeholder {
        border: 2px solid $brand-01;
        padding: 1px;
    }
    &__label {
        display: block;
        width: calc(100% - 20px - 10px);
        line-height: 20px;
        font-size: 15px;
    }
    // &[class*="color"] {
    //     & .dln-colorRadioBtn__placeholder::after {
    //         display: inline-block;
    //     }
    // }
    // &--color-black {
    //     & .dln-colorRadioBtn__placeholder::after {
    //         background-color: $brand-01;
    //     }
    // }
    // &--color-white {
    //     & .dln-colorRadioBtn__placeholder::after {
    //         background-color: $brand-03;
    //     }
    // }
    // &--color-gray {
    //     & .dln-colorRadioBtn__placeholder::after {
    //         background-color: $brand-02;
    //     }
    // }
    // &--color-blue {
    //     & .dln-colorRadioBtn__placeholder::after {
    //         background-color: $bra-lochmara;
    //     }
    // }
}

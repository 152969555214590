.dln-delonghi {
  & .dln-filterDropdown {
    &__dropdown {
    }
    &__label {
      font-size: REM(16px);
    }
    &__list {
      margin-bottom: 12px;
    }
    &__item {
      min-height: 32px;
      border: none;
      padding: 4px 0;

      &:first-child {
        padding-top: 14px;
      }
    }
    &__checkbox:checked {
      & + .dln-filterDropdown__label {
        @include fontBook;
      }
    }
    &__actions {
      & .dln-btn--reset {
        align-self: flex-end;
        min-width: initial;
        height: auto;
        line-height: 18px;
        font-size: REM(14px);
        color: $brand-04 !important;
        text-decoration: underline;
        background: none;
        background-color: $brand-03 !important;
        padding: 0;
      }
      & .dln-btn--apply {
        width: 100px;
        min-width: initial;
        height: 32px;
        line-height: 32px;
        font-size: REM(15px);
      }
    }
  }
}

.ken-layout-pagewrapper {
  .dln-club-membership {
    &__title {
      font-size: 28px;
      font-stretch: normal;
      line-height: 1.29;
      letter-spacing: normal;

      @media only screen and (max-width:599px) {
        font-size: 24px;
        line-height: 1.33;
        text-align: center;
      }
    }

    &__paragraph {
      font-size: 14px;
      font-stretch: normal;
      line-height: 1.43;
      letter-spacing: normal;
    }

    &__input-wrapper {
      .dln-radioBtn {
        min-width: 102px;
        max-width: 50%;

        &__lbl {
          &::before {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}

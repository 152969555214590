.dln-delonghi {
  .dln-manuals-list {
    &__title {
      @include fontMedium;
      font-size: REM(14px);
      line-height: 1.29;
    }
    &__details {
      font-size: REM(12px);
      line-height: 1.5;
    }
    .dln-icn {
      color: $brand-04;
    }
  }
}

.dln-kenwood {
    & .dln-faq {
        & .dln-faq__content {
            & > .dln-wrapper {
                padding-bottom: 70px;
            }
            & .dln-faq__questionList {
                margin-bottom: 70px;

                & .dln-faq__questionItem { 
                    height: 24px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    & .dln-faq__questionIcon {
                        width: 20px;
                        height: 24px;
                        font-size: 0;

                        &::before {
                            @include fontIcon;
                            content: 'π';
                            width: 20px;
                            height: 24px;
                            line-height: 24px;
                            font-size: 22px;
                            color: $brand-01;
                        }
                    }
                    & .dln-faq__questionLink {
                        @include fontBold;

                        &:hover {
                            color: $brand-01;
                        }
                    }
                }
            }
            & .dln-faq__resultCounter {
                color: $brand-04;
                margin-bottom: 10px;
            }
        }
    }
}

@media only screen and (max-width:599px) {
	.dln-country-list {
		.dln-accordion {
            max-height: 87px;

			.dln-accordion__head {
				height: auto;
				padding: 30px 0;
				border-top: 1px solid $ui-01;
				pointer-events: all;

				.dln-accordion__title {
					font-size: 21px;
					line-height: 1.3;
				}

				i {
					display: inline;
				}
			}
			.dln-accordion__content {
				padding: 0;
				margin: 0;
				padding-top: 25px;
            }
		}

		.dln-flag__list {
			.dln-flag__listItem {
				width: 100%;
			}
		}
	}
}
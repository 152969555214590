.dln-braun {
  .dln-cart__emptyMessage {
    max-width: 70%;
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, xs)) {
  .dln-braun {
    & .dln-cart {
      &--empty {
        > .dln-wrapper {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0;

          .dln-cart__head {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 10px;

            .dln-cart__title {
              font-size: 24px;
              align-items: baseline;

              .dln-cart__titleLbl {
                font-size: 13px;
                margin: 0 0 0 16px;
              }

              .dln-icn--iconfont {
                display: block;
                color: var(--brand-01);
                font-size: 28px;
                margin: 0 10px 0 0;
              }
            }
          }

          .dln-cart__content {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 30px;

            & .dln-cart__info {
              font-size: REM(13px);
              color: var(--brand-04);
              margin: 0 auto;
              width: 475px;
              text-align: center;
              padding: 5px 0;
              border: 1px solid var(--ui-12);
              background-color: var(--ui-03);
              line-height: 1.46;
            }
          }

          .dln-cart__emptyMessage {
            max-width: 70%;
            padding: 38px 0 0;

            .dln-icn {
              color: var(--brand-05);
              font-size: 48px;
            }

            p {
              color: var(--brand-04);
              font-weight: bold;
              font-size: REM(15px);
              margin: 0 0 35px;
            }

            .dln-cart__btnGroup {
              .dln-btn:first-of-type {
                margin: 0 0 18px;
                letter-spacing: 0;
              }

              .dln-btn:last-of-type {
                letter-spacing: 0;
              }
            }
          }

          .dln-cart__aside {
            margin: 0;

            .dln-cart_paymentBox {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-top: 15px;
              padding: 20px;
              width: 100%;
              background-color: var(--ui-03);
              margin-bottom: 18px;

              span {
                font-size: REM(14px);
                color: var(--ui-11);
              }

              ul {
                display: flex;
                flex-wrap: wrap;
                list-style-type: none;
                gap: 5px;
              }
            }
          }

          .dln-cart__aside--secondary,
          .dln-cart__aside {
            .dln-cart__noticeBox {
              gap: 0;
              margin: 0;

              p {
                display: none;
              }

              .dln-noticeBox {
                width: 100%;
                align-items: center;
                margin-bottom: 15px;

                &__content {
                  .dln-noticeBox__icon {
                    justify-content: flex-start;
                    flex-basis: 0;

                    .dln-icn {
                      font-size: 40px;
                    }
                  }

                  .dln-noticeBox__description {
                    .dln-noticeBox__title {
                      font-size: REM(18px);
                      color: var(--brand-04);
                      margin-bottom: 3px;
                    }

                    .dln-noticeBox__copy {
                      font-size: REM(13px);
                      color: var(--brand-04);
                    }
                  }
                }

                &__link {
                  margin-top: 0;
                  font-size: REM(13px);
                  text-decoration: none;
                  padding-bottom: 1px;
                  border-bottom: 1px solid var(--brand-04);
                  color: var(--brand-04);
                  line-height: 1.47;
                }

                &:nth-child(even) {
                  &::after {
                    height: 0;
                  }
                }
              }

              .dln-noticeBox:first-of-type {
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) {
  .dln-braun {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 0;

          .dln-cart {
            &__head {
              .dln-cart__title {
                max-width: 475px;
                font-size: REM(30px);
              }
            }

            &__content {
              padding-left: 20px;
              padding-right: 20px;

              & .dln-cart__info {
                width: 100%;
                margin: 0;
                padding-top: 15px;
                padding-bottom: 15px;
              }
            }

            &__emptyMessage {
              margin-top: 10px;
              margin-bottom: 25px;

              p {
                margin-bottom: 0px;
              }

              .dln-cart__btnGroup {
                margin-top: 30px;

                .dln-btn {
                  width: 100%;
                }
              }
            }

            &__aside--secondary,
            &__aside {
              padding-left: 20px;
              padding-right: 20px;

              .dln-cart__noticeBox {
                .dln-noticeBox {
                  max-width: 100%;
                }
              }
            }

            &__aside {
              .dln-cart_paymentBox {
                span {
                  color: var(--brand-01);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 901px) {
  .dln-braun {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          padding-left: 30px;
          padding-right: 30px;

          .dln-cart {
            &__head {
              margin-left: 0;
              padding-left: 0;

              .dln-cart__title {
                font-size: REM(36px);
                margin-left: 0;

                .dln-icn {
                  display: block;
                  font-size: 32px;
                  margin-right: 20px;
                }

                .dln-cart__titleLbl {
                  font-size: REM(15px);
                  font-weight: bold;
                }
              }
            }

            &__content {
              width: calc(
                100% - 387px - 20px
              ); //copied by accessories-added-desktop.scss to mantain desktop behaviour
              padding-right: 0;
              margin-right: 0;
              padding-left: 0;
              display: block;

              .dln-cart__info {
                width: 100%;
                font-size: REM(15px);
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }

            &__emptyMessage {
              p {
                @include fontSemibold;
                font-size: REM(18px);
              }

              .dln-icn:before {
                font-size: 45px;
              }

              .dln-cart__btnGroup {
                flex-direction: row;
                margin-top: 65px;
                flex-wrap: nowrap;
                justify-content: space-between;
              }
            }

            &__aside {
              padding-left: 20px;
              padding-right: 0;
              width: 407px;

              .dln-cart__noticeBox {
                display: block;

                .dln-noticeBox {
                  &__content {
                    .dln-noticeBox__icon {
                      .dln-icn:before {
                        content: '';
                      }
                    }
                  }
                }
              }

              .dln-cart_paymentBox {
                margin: 20px 0 20px 0;

                span {
                  color: var(--ui-11);
                  font-weight: 600;
                  font-size: REM(14px);
                }
              }
            }

            &__aside--secondary {
              .dln-cart__noticeBox {
                display: none;
              }
            }

            &__aside,
            &__aside--secondary {
              .dln-cart__noticeBox {
                .dln-noticeBox {
                  margin-bottom: 15px;

                  &__content {
                    .dln-noticeBox__icon {
                      .dln-icn {
                        font-size: 36px;
                        margin-top: -20px;
                      }
                    }

                    .dln-noticeBox__description {
                      .dln-noticeBox__copy {
                        font-size: REM(14px);
                        color: var(--brand-04);
                      }
                    }
                  }

                  &:nth-child(even) {
                    &::after {
                      height: 0;
                    }
                  }
                }

                .dln-noticeBox:first-of-type {
                  margin-top: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1101px) {
  .dln-braun {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          padding-top: 0;

          .dln-cart {
            &__head {
              padding-left: 0;
            }

            &__content {
              margin-right: 20px;
              padding-right: 0;
            }

            &__aside,
            &__aside--secondary {
              padding-right: 0;
              padding-left: 0;

              .dln-cart__noticeBox {
                .dln-noticeBox {
                  margin-top: 15px;
                  margin-bottom: 10px;
                }
              }
            }

            &__aside {
              width: 387px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .dln-braun {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          max-width: 1240px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) and (min-width: 1480px) {
  .dln-braun {
    .dln-cart {
      &--empty {
        > .dln-wrapper {
          max-width: 1240px;
          margin: 0 auto;
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {    
    // .dln-faq {
    //     &__quickLinks {    
    //         & .dln-wrapper {
    //             width: calc(100% - 40px);
    //         }
    //     }           
    // }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {    
    // .dln-faq {
    //     &__quickLinks {    
    //         & .dln-wrapper {
    //             width: calc(100% - 20px);
    //         }
    //     }           
    // }
}

@media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
    .dln-faq {
        &__chipsWrap {
            & > .dln-wrapper {
                margin-left: 40px;
                margin-right: 40px;
            }
        }
    }
}
  
@media only screen and (max-width: map-get($grid-breakpoints, md)) {
    .dln-faq {
        &__chipsWrap {
            & > .dln-wrapper {
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }
}
.dln-delonghi {
  .dln-breadcrumbs {
    @include fontBook;
    font-size: REM(15px);
    line-height: 21px;
    padding: 10px 0;
    margin: 0 !important;
    margin-bottom: 20px !important;

    @media only screen and (max-width: 599px) {
      margin-bottom: 4px !important;
    }

    &__li {
      color: $ui-07;

      &::before {
        padding-right: 0.05em;
        padding-left: 0.05em;
        color: $ui-07;
      }

      a,
      span {
        font-size: REM(15px);
        line-height: 21px;
      }

      &--active,
      &:last-of-type {
        &::before {
          color: $brand-04;
        }
        a,
        span {
          text-decoration: none;
          color: $brand-04;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

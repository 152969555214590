.dln-kenwood {
    & .dln-faq {

            & .dln-faq__helpSection {
                margin-bottom: 60px !important;
                & .dln-wrapper {
                    padding-top: 0;
                    & .dln-faq__helpSectionTitle {
                        line-height: 32px;
                        font-size: 24px;
                        text-align: left;
                    }
                    & .dln-faq__helpSectionText {
                        text-align: left;
                    }
                    & .dln-btn {
                        margin: 0 auto 0 0;
                    }
                }
            }

    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-faq {

                & .dln-faq__helpSection {
                    & .dln-wrapper {
                        & .dln-faq__helpSectionTitle,
                        & .dln-faq__helpSectionText {
                            text-align: center;
                        }
                        & .dln-btn--backToSection {
                            margin: 0 auto;
                        }
                    }
                }

        }
    }
}
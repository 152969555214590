.dln-delonghi {
  .dln-itemRegDetails {
    &__itemName {
      @include fontMedium;
      line-height: 19px;
      font-size: REM(19px);
    }
    &__details {
      & .dln-itemRegDetails__title {
        @include fontMedium;
        line-height: 18px;
        font-size: REM(18px);
        margin-bottom: 40px;
      }
      & .dln-inputs,
      & .dln-inputsFile {
        margin-bottom: 30px;
      }
      & .dln-select {
        margin: 0;
        margin-top: 5px;
      }
    }
    &--productRegistration {
      & .dln-itemRegDetails__head {
        border-bottom: none;
      }
      & .dln-itemRegDetails__content {
        padding-top: 0;
      }
      & .dln-itemRegDetails__details {
        &--extra {
          max-width: 450px;
        }
      }
      & .dln-itemRegDetails__paragraph {
        width: 100%;
        line-height: 18px;
        font-size: REM(14px);
        color: $brand-04;
      }
    }
  }

  .dln-select--open,
  .dln-select--has-value {
    & + .dln-select__lbl {
      transform: translateY(calc(50% - 24px));
      margin-top: 3px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-itemRegDetails {
      &__itemName {
        line-height: 16px;
        font-size: REM(16px);
      }
      &__details {
        & .dln-itemRegDetails__title {
          line-height: 18px;
          font-size: REM(16px);
          margin-bottom: 26px;
        }
        & .dln-select {
          margin-top: 15px;
        }
      }
      &__content {
        & .dln-itemRegDetails__itemImg {
          height: 272px;
        }
      }
      &--productRegistration {
        & .dln-itemRegDetails__sku {
          font-size: REM(13px);
        }
        & .dln-itemRegDetails__details {
          &--extra {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}

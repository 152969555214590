.dln-tooltip {
    display: inline-block;
    cursor: pointer;
    position: relative;

    &__anchor {
        margin: 0 auto;
        font-size: REM(12px);
        line-height: 125%;
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        width: 210px;
        text-align: left;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-50%);
        transition: .3s linear;
        position: absolute;
        /*  */
        top: auto;
        bottom: 24px;
        left: 50%;
    }
    &:hover {
        & .dln-tooltip__container {
            opacity: 1;
            visibility: visible;
            z-index: 100;
        }
    }
    &__content {
        @include small;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        width: calc(100% - 9px);
        color: $brand-02;
        background-color: $brand-03;
        box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 0 auto;

        .dln-icn {
            margin: 0 8px 0 0;
        }
    }
    &__text {
        max-width: calc(100% - 16px - 8px);
        line-height: 16px;
        font-size: REM(12px);
        color: $brand-02;        
    }
    &__arrow {
        margin-left: auto;
        margin-right: auto;
        width: 0;
        height: 0;
        border-top: 9px solid $brand-03;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
    }
    &--topAlign {
        .dln-tooltip__container {
            top: calc(100% + 8px);
            bottom: auto;
        }
        .dln-tooltip__content {
            box-shadow: 0 7px 7px 0 rgba(0, 0, 0, 0.1);
        }
        .dln-tooltip__arrow {
            order: -1;
            border-top: none;
            border-bottom: 9px solid $brand-03;
        }
    }
    &--rightAlign {
        .dln-tooltip__container {
            transform: translate(0, -50%);
            top: 50%;
            bottom: auto;
            left: auto;
            right: calc(100% + 8px);
        }
        .dln-tooltip__content {
            width: calc(100% - 9px);
            box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
            margin: 0;
            .dln-icn {
                /* order: 2; */
                margin: 0 8px 0 0;
            }
        }
        .dln-tooltip__arrow {
            border-top: 7px solid transparent;
            border-right: none;
            border-bottom: 7px solid transparent;
            border-left: 9px solid $brand-03;
            margin: auto 0;
        }
    }
    &--leftAlign {
        .dln-tooltip__container {
            text-align: right;
            transform: translate(0, -50%);
            top: 50%;
            bottom: auto;
            left: calc(100% + 8px);
            right: auto;
        }
        .dln-tooltip__content {
            width: calc(100% - 9px);
            box-shadow: 7px 0px 7px 0px rgba(0, 0, 0, 0.1);
            margin: 0;
            .dln-icn {
                order: 2;
                margin: 0 0 0 8px;
            }
        }
        .dln-tooltip__arrow {
            order: -1;
            border-top: 7px solid transparent;
            border-right: 9px solid $brand-03;
            border-bottom: 7px solid transparent;
            border-left: none;
            margin: auto 0;
        }
    }
}
.del-filters-groups {
  display: flex;
  flex-direction: column;
  padding: 0 6px;
  text-align: center;
  align-items: center;
  margin: 0 auto 48px;
  max-width: 1400px;

  &__title,
  &__paragraph {
    width: 100%;
    text-align: left;
    font-weight: normal;
    font-size: REM(16px);
    margin: 8px 0;
    line-height: 24px;
  }

  &__title {
    margin: 24px auto 4px;
    font-weight: bold;
  }

  &__paragraph {
    margin: 0 auto 24px;

    > * {
      font-size: 1em;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 50%;
    align-items: center;
    flex: inherit;
    padding: 0 6px 24px;
    position: relative;

    &__picture {
      position: relative;
      width: 90%;
      margin: 0 auto 10px;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }

    &__image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &__title {
      color: var(--brand-04);
      font-weight: bold;
      font-size: REM(18px);
      line-height: 1.25em;
      margin-bottom: 4px;
    }

    &__paragraph {
      color: var(--brand-04);
      font-size: REM(14px);
      line-height: 1.25em;
      width: 80%;
      flex-grow: 2;

      > * {
        font-size: 1em;
      }
    }

    &__cta {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      line-height: 1;
      width: 100%;
      transition: all 0.5s ease 0s;

      &:hover {
        opacity: .8;
      }
    }
  }

  &__blue-del,
  &__green-nb,
  &__pink-nb,
  &__grey-kw,
  &__black-br,
  &__grey-br,
  &__grey-br-2 {
    .del-filters-groups__title,
    .del-filters-groups__paragraph {
      width: auto;
      text-align: center;
      font-size: REM(16px);
      color: var(--brand-03);
      line-height: 1.25em;
    }

    .del-filters-groups__title {
      font-size: REM(27px);
    }

    .del-filters-groups__item {
      &__title {
        font-size: REM(18px);
        color: var(--brand-03);
        font-weight: bold;
      }

      &__paragraph {
        font-size: REM(14px);
        color: var(--brand-03);
      }

      &__cta {
        width: auto;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  &__blue-del {
    background-image: radial-gradient(var(--brand-05), var(--brand-04));

    .del-filters-groups__title {
      font-weight: normal;
    }

    .del-filters-groups__item {
      &__cta {
        color: var(--brand-04);
        background-color: var(--brand-03);
        font-size: REM(13px);
        padding: 10px 16px;
        border-radius: 3px;
        letter-spacing: 1px;
      }
    }
  }

  &__green-nb {
    background: var(--support-02);
  }

  &__pink-nb {
    background: var(--support-03--light);
  }

  &__green-nb,
  &__pink-nb {
    letter-spacing: initial;

    .del-filters-groups {
      &__title {
        font-size: REM(34px);
        font-weight: normal;
      }

      &__paragraph {
        font-size: REM(21px);
      }
    }

    .del-filters-groups__item__cta {
      text-decoration: none;
      color: var(--brand-03);
      background-color: var(--brand-01);
      border: 1px solid var(--brand-01);
      border-radius: 50px;
      padding: 8px 25px;
      font-size: REM(18px);
      letter-spacing: initial;
      line-height: 1.5;
    }
  }
  
  &__grey-kw {
    background: var(--ui-03);
    max-width: 1440px;
    
    .del-filters-groups {
      &__title {
        color: var(--brand-04);
        margin-bottom: 8px;
      }

      &__paragraph {
        color: var(--brand-04);
      }
    }

    .del-filters-groups__item {
      &__title,
      &__paragraph {
        color: var(--brand-04);
      }

      &__cta {
        color: var(--brand-03);
        border: 1px solid var(--brand-01);
        background-color: var(--brand-01);
        padding: 10px 20px;
        font-size: 12px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 0.5px;
        line-height: 23px;

        &:hover {
          opacity: 1;
          border: 1px solid var(--brand-04);
          background-color: var(--brand-04);
        }
      }
    }
  }

  &__black-br,
  &__grey-br,
  &__grey-br-2 {
    background: var(--brand-01);
    max-width: none;

    .del-filters-groups {
      &__title {
        font-size: REM(34px);
        margin-bottom: 8px;
      }

      &__paragraph {
        font-size: REM(21px);
      }
    }

    .del-filters-groups__item__cta {
      color: var(--brand-03);
      background-color: var(--support-02);
      border: 1px solid var(--support-02);
      border-radius: 2em;
      padding: 0 15px;
      font-size: 15px;
      text-decoration: none;
      min-height: 40px;
      min-width: 100px;

      &:hover {
        opacity: 1;
        color: var(--support-02);
        background-color: var(--brand-03);
        border: 1px solid var(--brand-03);
      }
    }
  }

  &__grey-br,
  &__grey-br-2 {
    background: var(--ui-12);

    .del-filters-groups__title,
    .del-filters-groups__paragraph,
    .del-filters-groups__item__title,
    .del-filters-groups__item__paragraph {
      color: var(--brand-01);
    }
  }

  &__grey-br-2 {
    .del-filters-groups__item__cta {
      background-color: var(--ui-13);
      border: 1px solid var(--ui-13);

      &:hover {
        color: var(--ui-13);
        background-color: var(--brand-03);
        border: 1px solid var(--brand-03);
      }
    }
  }

  @media only screen and (min-width: map-get($grid-breakpoints, sm)) {
    padding: 24px 24px 6px;

    &__title,
    &__paragraph {
      margin: 0 auto;
    }

    &__items {
      width: 90%;
      flex-wrap: wrap;
      margin: auto;
    }

    &__item {
      width: 25%;
      padding: 24px 2%;
    }

    &__blue-del {
      .del-filters-groups {
        &__title {
          font-size: REM(34px);
        }

        &__paragraph {
          font-size: REM(20px);
        }
      }

      .del-filters-groups__item {
        padding: 12px;

        &__title {
          font-size: REM(22px);
        }
  
        &__paragraph {
          font-size: REM(17px);
        }

        &__cta {
          font-size: REM(16px);
          padding: 16px 24px;
        }
      }
    }

    &__green-nb,
    &__pink-nb {
      .del-filters-groups {
        &__title {
          font-size: REM(48px);
        }

        &__paragraph {
          font-size: REM(29px);
        }
      }

      .del-filters-groups__item {
        padding: 24px 4%;

        &__title {
          font-size: REM(22px);
        }
  
        &__paragraph {
          font-size: REM(17px);
        }
      }
    }
  }
}

.dln-advtBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 250px;
    text-decoration: none;
    border: 1px solid $ui-02;
    background-color: $brand-03;
    padding: 8px 32px;
    box-sizing: border-box;
    cursor: pointer;
    
    & .dln-icn {
        font-size: 80px;
        color: $support-02;
    }
    &__title {
        @include large;
        @include fontBold;
        width: 100%;
        font-size: 18px;
        color: $brand-04;
        margin: 16px 0 8px;
    }
    &__desc {
        @include small;
        color: $brand-02;
    }
}
.dln-kenwood {
    .dln-addNewCard {
        padding: 0px 40px 70px 40px;

        & .dln-popup__head--circleClose {
            padding: 0;
            margin-top: 80px;
            margin-bottom: 3px !important;
        }

        & .dln-popup__headLine {
            display: none;
        }

        & .dln-popup__headTitle {
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
            display: block;
        }

        & .dln-btnIcon {
            position: absolute;
            top: 20px;
            right: 20px;

            & .dln-icn {
                font-size: 22px;
                width: 22px;
                height: 22px;
                text-indent: 999px;
                overflow: hidden;
                @include definePseudoElems;

                &::before {
                    @include fontIcon;
                    content: 'J';
                    text-indent: 0;
                    color: $brand-01;
                }
            }
        }
        &__content {
            //margin-top: 30px;
            padding-top: 28px;

            & .dln-inputs {
                margin-bottom: 41px;

                &--securityCode {
                    margin-bottom: 32px !important;
                }
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-popup {
        & .dln-popup__wrapper {
            & .dln-addNewCard {
                & .dln-inputs.dln-inputs--securityCode {
                    width: calc(100% - 104px - 16px);

                    & + .dln-btn {
                        max-width: none;
                        margin-left: 16px;
                    }
                }
            }
        }
    }
}
.dln-btnIcon {
    display: block;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    box-sizing: content-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    position: relative;

    .dln-icn {
        font-size: 17px;
        color: $brand-04;
        margin: 0 auto;
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
    }
    &__user {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $brand-03;

        &Letter {
            width: 100%;
            height: 15px;
            line-height: 100%;
            font-size: 15px;
            @include fontBold;
            color: $brand-04;
            padding: 0 0 0 0;
            margin: 0 auto 0 auto;
            font-style: inherit;
            font-family: sans-serif;
        }
    }
    &--cart {
        & .dln-btnIcon__icon {
            margin: 0 4px 0 0;
        }
    }
    &__itemCounter {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $support-01;
        position: absolute;
        top: 0px;
        right: 0px;
        display: flex;
        align-content: center;
        align-items: center;

        &Text {
            width: auto;
            font-size: 11px;
            @include fontBold;
            color: $brand-03;
            text-align: center;
            margin: 0 auto;
            font-style: inherit;
            /*  */
            display: inline-block;
            padding: 0 0;
            height: 11px;
            line-height: 100%;
            font-family: sans-serif;
        }
    }
    &__circle {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.7);
    }
    &--search {
        .dln-icn {
            color: $brand-03;
            font-size: 24px;
        }
    }
    &--wishlist {
        .dln-icn {
            color: $brand-03;
            font-size: 24px;
        }
    }
    &--cart {
        .dln-icn {
            color: $brand-03;
            font-size: 24px;
        }
    }
    &--delete {
        .dln-icn {
            color: $brand-04;
        }
    }
    &--close {
        .dln-icn {
            color: $brand-04;
        }
    }
    &--calendar {
        .dln-icn {
            color: $brand-04;
        }
    }
    &--info {
        .dln-icn {
            color: $ui-02;
        }
    }
    &--close-circle {
        .dln-icn {
            color: $brand-04;
        }
    }


    &.hasDropdown {
        &:hover {
            z-index: 2100;
        }
    }
    &.displayNotificationDrawer {
        .dln-drawer {
            display: block;
            visibility: visible;
            z-index: 2000;
        }
    }


    .dln-drawer {
        display: none;
        visibility: hidden;
    }
}

@import "./_button-icon-kenwood";

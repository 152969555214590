.dln-kenwood {
  .dln-orderSumm {
    border-width: 0;

    &__head {
      background-color: $ui-03;
    }

    &__title {
      font-size: 20px;
      line-height: 24px;
    }

    &__content {
      padding: 0 20px 20px;
    }

    &__total-title span {
      @include fontBold;
    }

    &__contentRow {

      &--promo {
        .dln-orderSumm__rowTitle {
          @include fontRoman;
          font-size: REM(18px);
          color: $brand-04;
        }

        .dln-price {
          color: $brand-04;
        }
      }

      &--subtotal {
        .dln-price {
          &--discounted {
            color: $brand-04;
          }
        }
      }

      &--taxes svg {
        margin-left: 10px;
        path {
          fill: $brand-01;
        }
      }
    }

    &__rowTitle {
      font-size: 15px;
      line-height: 22px;
      color: $brand-04;
      &--secondary {
        color: $ui-08;
        font-size: 12px;
      }
    }

    .dln-icn--coupon {
      color: var(--support-02);
    }

    &--checkout {
      .dln-orderSumm__head {

        .dln-orderSumm__title {
          max-width: calc(100% - 24px - 8px);
          width: auto;
          display: inline-block;
        }
        @media only screen and (min-width: 599px) {
          .dln-orderSumm__headPriceLbl {
            display: none;
          }
        }
      }

      .dln-orderSumm__contentRow {
        padding: 10px 0;
        font-size: 13px;
        line-height: 19px;
        &--total {
          padding-bottom: 10px;
          span {
            font-size: 18px;
            line-height: 22px;
            max-height: none;
            color: $brand-04;
          }
        }
      }

      .dln-orderSumm__itemPicWrap {
        max-width: 100px;
        width: 100px;
        height: auto;
        margin: 0 20px 0 0;

        & > img {
          width: 100%;
          height: auto;
        }
      }

      .dln-orderSumm__itemDetails {
        width: calc(100% - 100px - 20px);
        h5 {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 8px;
        }
      }
    }

    &--light {
      .dln-orderSumm__contentRow {
        &:first-of-type {
          border-top: none;
        }
      }
    }

    @media only screen and (max-width: 599px) {
      background-color: $ui-03;
      &__title {
        font-size: 16px;
        line-height: 22px;
      }

      &__item {
        background-color: $brand-03;
      }

      &__content {
        padding: 0 0 20px;
      }

      &--checkout {
        .dln-orderSumm__contentRow {
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 0;

          .dln-orderSumm .dln-cart__payment-wrapper {
            padding: 0 20px 15px;
          }

          &--promo,
          &--subtotal,
          &--shipping,
          &--total {
            @include definePseudoElems;
            position: relative;
            &::after {
              width: calc(100% - 40px);
              height: 1px;
              left: 20px;
              bottom: 0;
              background-color: $ui-01;
            }
          }
          &--total {
            border-top: none;
            &::after {
              bottom: auto;
              top: 0;
              background-color: $brand-02;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-kenwood {
    & .dln-orderSumm {
      &--checkout {
        & .dln-orderSumm__head {

          .dln-orderSumm__title {
            max-width: 100%;
          }

          .titleCounter > div {
            display: flex;
            flex-direction: column;
          }

          .dln-orderSumm__headPriceLbl {
            font-size: 16px;
          }
        }
      }

      .dln-cart__payment-wrapper {
        padding: 0 20px 15px;
      }

      &__contentRow--taxes,
      &__contentRow--shipping {
        .dln-tooltip {
          & .dln-tooltip__container {
              width:100%;
              min-width: 150px;
          }
    
          & .dln-tooltip__content {
              width:100%;
              text-align: left;
          }
    
          & .dln-tooltip__text {
              max-width: none;
          }
        }
      }
    }
  }
}

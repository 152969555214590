.dln-ordersHist {
    align-content: flex-start;

    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__title {
        @include big;
        @include fontBold;
        display: block;
        width: 100%;
        margin: 26px 0 40px 0;
    }
    &__subTitle {
        @include big;
        @include fontBold;
        font-size: 32px;
        margin: 0 0 8px;
    }
    &__content {
        & .dln-title {
            line-height: normal;
            margin: 0 0 32px 0;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        margin: 0 0 64px 0;

        &Title,
        & .dln-title {
            @include fontBold;
            display: block;
            width: 100%;
            height: auto;
            line-height: 32px;
            font-size: 32px;
            margin: 0 0 0 0;
            &--labeled {
                display: flex;
            }
        }
    }
    & .dln-title {
        & + .dln-ordersHist__paragraph {
            margin-bottom: 0;
        }
    }
    &__paragraph {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        font-weight: 400;
        color: $brand-04;
        margin: 0 0 20px 0;

        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
    }
    &__underlinedText {
        color: $brand-02;
        text-decoration: underline;

        &:hover {
            color: $brand-04;
        }
    }
    &__subtitle {
        @include fontBold;
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 13px;
        // text-transform: uppercase;
    }
    & .dln-tabs__content {
        & .dln-ordersHist__title {
            opacity: 0;
            transition: opacity .3s ease .1s;
        }
        &--current {
            & .dln-ordersHist__title {
                opacity: 1;
            }
        }
    }
    &__foot {
        width: 100%;
        text-align: center;
        margin: 0 0 32px;

        & .dln-btn {
            width: auto;
        }
        &--divider {
            text-align: right;
            border-top: 1px solid $ui-01;
            padding: 32px 0 0;
            margin: 32px 0 0;
        }
    }
    &--empty {
        .dln-ordersHist__content {
            & .dln-title {
                margin: 0 0 28px 0;
            }
        }
    }
}

@import "_orders-history-kenwood.scss";
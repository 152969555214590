@media only screen and (max-width: 599px) {
    .dln-prodReg--confirmation {
        padding-top: 64px;
        
        & .dln-confirmation-page__breadCrumbs-wrapper {
            padding-top: 21px;
        }
        & .dln-confirmation-page__title-container {
            padding-bottom: 65px;
            margin: 0 0 20px 0;

            & .dln-title {
                font-size: 35px;
                line-height: 41px;
            }
        }
        & .dln-confirmation-page__wrapper-content {
            & .dln-chips {
                & .dln-icn {
                    margin: 0 20px 0 0;
                }
                &__content {
                    width: calc(100% - 68px);
                    line-height: 20px;
                }
            }
        }    
        & .dln-confirmation-page__wrapper-content {
            margin: 0 0 70px 0;
        }
    }
    // .confirmation-page{
    //     &__wrapper-content {
    //         padding: 0 30px;
    //         margin-bottom: 71px;

            
    //         .dln-icn--mainIcon {
    //             color: var(--support-02);
    //         }
            
    //         .dln-chips {
    //             padding: 0px;
    //         }
    //     }

    //     &__breadCrumbs-wrapper {
    //         padding-top: 21px;
    //         padding-bottom: 11px;
    //     }

    //     &__title-container {
    //         margin-top: 78px;
    //         padding: 0 30px 65px;
    //         margin-bottom: 20px;
    //     }

    //     &__breadCrumbs-wrapper {
    //         padding-top: 21px;
    //         padding-bottom: 11px;
    //     }

    //     &__button-container {
    //         margin-top: 41px;
    //         max-width: 217px;

    //         .dln-btn {
    //             max-width: 217px;
    //         }
    //     }

    //     &__icon-container {
    //         font-size: 14px;
    //         margin-top: 85px;
    //     }
    // }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-prodReg {
        &--selectProduct {
            .dln-prodReg {
                &__productHeading {
                    width: calc(100% - 120px - 16px);
                    margin: 0 0 0 auto;
                    span {
                        width: calc(50% - 20px - 5px);
                        margin: 0 20px 0 0;
                        &:first-of-type {
                            width: calc(100% - 120px - 20px);
                        }
                        &:last-of-type {
                            width: 120px;
                            margin: 0;
                        }
                    }
                }
                .dln-prodReg__categoryBreadcrumbs {
                    padding: 10px;
                }
            }
        }

    }
}
@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-site-search {
        &__products {
            & .bra-prodTile {
                width: calc(33.33% - 13.34px);

                &:nth-of-type(4n+4) {
                    margin: 0 20px 20px 0
                }
                &:nth-of-type(3n+3) {
                    margin: 0 0 20px 0
                }
            }
        }
        .dln-tabs {
            &__btn {
                font-size: 15px;
                line-height: 21px;
            }
        }
    }
}

@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-site-search {
        & .dln-btnTextIcon--braun-filters {
            display: block;
        }
        // & .dln-tab {
        //     &__links {
        //         margin-right: 40px;
        //     }
        // }
    }
}
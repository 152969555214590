.dln-orderHistory {
    width: 100%;
    margin: 0 0 40px;

    &__reference {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 0 10px;
    }
    &__codes {
        width: auto;
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0 auto 0 0;
        max-width: calc(100% - 80px);
    }
    &__code {
        @include extraSmall;
        color: $brand-02;
        padding: 4px 4px 4px 0;
        position: relative;
        &:nth-child(1n)~.dln-orderHistory__code {
            padding: 4px 4px;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 12px;
            background-color: $brand-02;
            top: calc(50% - 6px);
            right: 0;
        }
    }
    &__totalprice {
        @include mainMenu;
        @include fontBold;
    }
    &__content {
        width: 100%;
        position: relative;
    }
    .dln-orderRev {
        padding: 0;
        border-width: 0;
        margin: 0 0 20px;
    }
    &--openorder {
        margin: 0 0 20px;
        padding: 0 0 2px;
        border-bottom: 1px solid $ui-01;
        &:last-of-type {
            border-width: 0;
        }
        .dln-orderHistory__reference {
            max-height: 20px;
            position: absolute;
            width: calc(100% - 200px - 16px);
            left: 216px;
        }
        .dln-orderRev__content {
            padding-top: 30px;
        }
    }
    &--noButtons {
        & .dln-orderRev__actions {
            display: none;
        }
    }
    &--disabled {
        opacity: 0.3;
        pointer-events: none;
    }
}

@import "_order-history-kenwood.scss";

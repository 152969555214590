.dln-profile {
    align-content: flex-start;

    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {
        & .dln-title {
            margin: 0 0 32px 0;
        }
        & .dln-addBox {
            width: calc(50% - 40px - 2px - 8px);
            margin: 0 16px 40px 0;
            box-sizing: content-box;

            &--productRegister {
                margin: 0 0 40px 0;
            }
        }
    }
    & .dln-persDataRecap {
        margin: 0 0 32px 0;
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        // background: rgba(0,0,0,.1);

        &--employee-sales {
            padding: 30px;
            background-color: var(--ui-03);
            border: 1px solid var(--ui-01);
            display: block;
        }

        &--title {
            @include fontBold;
            font-size: REM(32px);
            line-height: 32px;
            margin: 0 0 24px;
        }

        &--paragraph {
            margin: 0 0 20px;
        }

        &--percentage {
            max-width: 200px;
            height: 10px;
            border: 1px solid var(--ui-04);
            background-color: var(--ui-01);
            margin: 0 0 20px;

            span {
                background-color: var(--brand-01);
                height: 100%;
                display: block;
            }
        }

        &--list {
            list-style-type: none;

            li {
                line-height: 150%;
            }
        }
    }
    &__recipes {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 456px;
        background-image: url('#{$pathAssets}/images/bg/bg-recipe-banner.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 64px 32px 64px 32px;

        &Wrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
        }
        &Subtitle {
            display: block;
            width: 40%;
            height: auto;
            line-height: 24px;
            font-size: 18px;
            margin: 0 50% 8px 0;
        }
        &Title {
            display: block;
            width: 40%;
            height: auto;
            line-height: 40px;
            font-size: 32px;
            @include fontBold;
            margin: 0 50% 16px 0;
        }
        &Link {
            display: inline-flex;
            width: auto;
            height: auto;
            line-height: 32px;
            font-size: 22px;
            @include fontBold;
            color: $brand-04;
        }

        &List.dln-carousel--mobileOnly {
            .dln-recipe.swiper-slide {
                max-width: 25%;
                @media only screen and (max-width:599px) {
                    max-width: initial
                }
            }
        }
    }
    &__registerProduct {
        width: 100%;
        border: 1px solid $ui-01;
        background-color: $ui-03;
        padding: 30px;

        .dln-carousel {
            height: auto !important;
        }
        .swiper-pagination {
            margin: 32px 0 0 !important;
        }
        .dln-btn {
            width: auto;
            max-width: 100%;
        }
    }
    &__banner {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

    }
    &__bannerTitle {
        @include big;
        width: 100%;
        @include fontBold;
    }
    &__bannerSubTitle {
        @include large;
        width: 100%;
        @include fontBold;
        margin: 40px 0 16px;
    }
    &__bannerCopy {
        @include small;
        line-height: 20px;
        font-size: 14px;
        margin: 24px 0;

    }
    &__benefitsText {
        width: calc(100% - 48px - 32px);
        margin: 0 0 0;
    }
    &__benefits {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        /* width: calc(50% - 16px);
        margin: 0 32px 0 0;
        &:nth-child(2n+2) {
            margin: 0 0;
        } */
        width: 100%;
        .dln-icn {
            font-size: 48px;
            color: $support-02 !important;
            margin: 0 32px 0 0;
        }
        .dln-profile__bannerSubTitle,
        .dln-profile__bannerCopy {
            width: 90%;
            margin: 0 0 8px;
        }
        .dln-profile__bannerSubTitle {
            @include cta;
        }
        .dln-profile__bannerCopy {
            color: $brand-02;
        }
    }
}
.dln-inputIncr {
    display: flex;
    display: flex;
    flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    align-items: center;
    width: 100%;
    max-width: 60px;

    &__input {
        outline: 0;
        width: calc(100% - 32px);
        height: 11px;
        text-align: center;
        border: none;
        background-color: transparent;
        margin: 0 auto;
        box-sizing: border-box;

        &::-webkit-inner-spin-button {
            display: none;
        }
    }
    &__btn {
        outline: none;
        width: 14px;
        height: 14px;
        @include fontBold;
        color: #FFF;
        text-align: center;
        border: none;
        box-shadow: none;
        transition: all 0.3s linear;
        overflow: hidden;
        cursor: pointer;
        user-select: none;
        background-color: transparent;

        .dln-icn {
            line-height: 14px;
            font-size: 14px;
            color: $brand-01;
            pointer-events: none;
        }
        &--decrement {
            transform: rotate(-90deg);
        }
        &--disabled {
            pointer-events: none;
            & .dln-icn {
                color: $ui-02;
            }
        }
    }
}

.dln-delonghi {
  .dln-return-full-account {
    .dln-btnTextIcon__wrap {
      i,
      span {
        font-weight: 500;
      }

      i {
        margin-top: 2px;
      }
    }

    &__title {
      @include fontMedium;
      font-size: REM(50px);
    }

    &__label {
      font-size: REM(16px);
      color: $ui-02;
    }

    &__value {
      @include fontRoman;
      font-size: REM(18px);
    }

    &__text {
      font-size: REM(16px);
    }

    &__product-list {
      width: 100%;
      background: $ui-05;
      padding: 0 20px;
      margin-top: 40px;

      .dln-orderRev {
        &__productCode {
          @include fontMedium;
          color: $ui-02;
          margin-top: 4px;
        }

        &__content {
          width: auto;
        }

        &__details {
          width: 180px;
        }

        &__wrap--main {
          .dln-price {
            width: auto;
            min-width: 61px;
            height: auto;
            margin-top: 0;
            margin-right: 8px;
          }
        }

        &__wrap--quantity {
          margin-top: 12px;
        }
      }

      .dln-orderRev-input-wrapper {
        border: none;
        padding-bottom: 0;
        padding-top: 20px;

        & .dln-orderRev {
          margin-bottom: 0;
        }
        &__single-item-content {
          margin-top: 10px;
        }
        &__select-quantity {
          span {
            font-size: REM(14px);
          }
          .dln-inputIncr {
            width: 100px;
            height: 40px;
            border-radius: 2px;
            border-color: $brand-04;
            background: $brand-03;
            padding: 0 12px;

            button {
              i {
                font-weight: bold;
                color: $brand-04;
              }
            }

            &__input {
              @include fontMedium;
              height: auto;
              font-size: REM(18px);
            }
          }
        }

        &--notReturnable {
          justify-content: flex-end;
          padding-top: 0;
        }

        &__single-item-content.-notReturnable {
          margin-top: 27px;
        }

        .dln-select__value,
        .dln-orderRev__notReturnSel {
          font-size: REM(14px);
          border-color: $ui-02;
        }

        .dln-orderRev__notReturnText {
          font-size: REM(16px);
          line-height: 24px;
          padding-top: 10px;
        }

        .dln-orderRev__notReturnBox {
          .dln-btn {
            background: $ui-05;
          }
        }

        .dln-select__container {
          background: $brand-03;
        }
      }
    }

    &__total-row {
      border: none;
      span {
        @include fontMedium;
        font-size: REM(18px);
      }
    }

    &__button-wrap {
      display: flex;
      justify-content: center;

      .dln-btn {
        background: $ui-06 !important;

        &.-hasItems {
          background: $brand-04 !important;
          opacity: 1 !important;
        }
      }

      .dln-btn,
      span {
        @include fontMedium;
        font-size: REM(18px);
      }
    }
  }

  .dln-return-full-account-modal__thankYouMessageText {
    font-size: REM(18px);
  }
}

@media screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: 1020px) {
  .dln-delonghi {
    .dln-return-full-account {
      .dln-orderRev-input-wrapper {
        flex-direction: column;
        flex-wrap: wrap;
        border-bottom: 1px solid $ui-01;
        padding-bottom: 24px;

        &:last-of-type {
          border-bottom: none;
        }

        &__single-item-content {
          width: 100%;
        }
      }

      .dln-orderRev__notReturnBox {
        button {
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) and (min-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    .dln-return-full-account {
      .dln-orderRev__detailsTitle {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    .dln-return-full-account {
      &__title {
        line-height: 1.08;
        font-size: REM(37px);
        letter-spacing: -0.74px;
      }

      .dln-orderRev-input-wrapper {
        justify-content: flex-start;
        border-bottom: 1px solid $ui-01;
        padding-top: 0;
        padding-bottom: 24px;
        position: relative;

        .dln-orderRev {
          margin-bottom: 16px;
        }

        .dln-orderRev.dln-orderRev--productDescription {
          margin-top: 12px;
          margin-left: 24px;
        }

        &__single-item-content {
          margin-top: 0;
          margin-left: 29px;
        }

        .dln-orderRev__wrap--main {
          margin-bottom: 0;
          .dln-orderRev__detailsTitle {
            order: 1;
          }
          .dln-orderRev__productCode {
            order: 2;
          }

          .dln-price.dln-price--oldPrice {
            order: 3;
          }

          .dln-price.dln-price--discounted {
            order: 4;
          }
        }

        .dln-checkbox.dln-checkbox--productDescription {
          position: absolute;
        }

        .-notReturnable {
          margin-top: 0;
        }

        &--notReturnable {
          padding-top: 0px;
          .dln-checkbox {
            display: none;
          }

          .dln-orderRev__notReturnBox {
            .dln-btn {
              width: 100%;
              max-width: none;
            }
          }
        }
      }

      & .dln-return-full-account__product-list {
        padding-bottom: 32px;

        & .dln-orderHistory {
          margin-bottom: 0;

          & .dln-orderRev-input-wrapper {
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }

    .dln-popup__content.dln-return-full-account-modal__popup-content {
      width: 100vw;
      height: 100vh;
      max-height: none !important;

      .dln-return-full-account-modal {
        padding: 20px;
        padding-top: 138px;
      }

      .dln-return-full-account-modal__thankYouMessageText {
        font-size: REM(16px);
      }
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-orderRev {
        border-width: 0 0 1px 0;
        border-color: $ui-01;
        padding: 10px 10px 10px 10px;
        margin: 0 0 8px;

        &:hover {
            border-color: $ui-01;
        }
        &__itemPic {
            width: 100%;
            height: 206px;
            // max-height: 190px;
            // padding: 8px 0;
            margin: 0 0 0 0;

            & img {
                // max-height: 190px;
                margin: 0 auto;
            }
        }
        &__price {
            width: 100%;
        }
        &__content {
            width: 100%;
            text-align: center;
        }
        &__delivery {
            text-align: center;
            margin: 0 0 4px 0 !important;
        }
        & .dln-price {
            &--oldPrice {
                margin: 0 4px 0 auto;
            }
            &--discounted {
                margin: 0 auto 0 0;
            }
        }
        &__details {
            border: none;
            padding: 0 0 16px 0;

            &Title {
                font-size: 13px;
                @include fontBold;
            }
        }
        &__wrap {
            width: 100%;
            margin: 0 0 4px 0;

            &:nth-of-type(2) {
                margin: 0 0 0 0;
            }
        }
        &__rating {
            margin: 0 auto 0 auto;
        }
        .dln-ratingStars {
            margin: 0 auto;
        }
        &__actions {
            flex-wrap: wrap;

            & .dln-btn {
                max-width: 100%;
                margin: 0 0 0 0;

                &:first-child {
                    margin: 16px 0 8px 0;
                }
            }
        }
        &__actions {
            justify-content: center;

            & .dln-btn--link {
                order: 2;
            }
            & .dln-btn--stroked {
                width: 100% !important;
                order: 1;
            }
        }
        &--cart-order {
            .dln-orderRev__quantity {
                width: 100%;
                text-align: center;
            }
            .dln-orderRev__content {
                padding: 8px 0 0 0;
            }
            .dln-orderRev__details {
                width: 100%;
                max-width: 100%;
                align-self: auto;
                margin: 0 0 0 0;
            }
            .dln-orderRev__wrap--main {
                margin: 0 0 16px;
            }
            .dln-orderRev__actions {
                width: 100%;
                max-width: 100%;
                margin: 16px 0 0;
                .dln-btn {
                    display: block;
                    margin: 0 0 10px 0;
                }
            }
        }
        &--registeredproduct {
            border-width: 0 0 1px 0 !important;
            .dln-orderRev__details {
                padding: 0 0;
                .dln-orderRev__wrap {
                    &--registeredproduct {
                        border-bottom: 1px solid $ui-01;
                        width: 100%;
                        padding: 0 0 16px;
                        margin: 0 0 16px;
                    }
                    &--links {
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        .dln-btn--link {
                            margin: 0 auto 0 0;
                            &:last-of-type {
                                margin: 0 0;
                            }
                        }
                    }
                }
            }
            .dln-orderRev__actions {
                margin: 0 0;
                .dln-orderRev__wrap--links {
                    width: 100%;
                    max-width: none;
                    display: flex;
                    flex-wrap: wrap;
                    &:last-of-type {
                        order: -1;
                        margin: 0 0 24px;
                        width: 100%;
                    }
                    .dln-btn--link {
                        margin: 0 auto 0 0;
                        min-width: auto;
                        &:last-of-type {
                            margin: 0 0;
                        }
                    }
                }
            }
        }
        &--productDescription {
            padding: 0 0 24px;
            margin: 0 0 24px;
            .dln-orderRev__content {
                width: calc(100% - 90px - 16px);
                text-align: left;
                padding: 0;
                .dln-orderRev__details {
                    height: 90px;
                    padding-right: 8px;
                    align-items: flex-start;
                    text-align: left;
                }
            }
            .dln-orderRev__itemPic {
                width: 90px;
                height: 90px;
                margin: 0 16px 0 0;
            }
            .dln-orderRev__sku {
                margin: 0 0;
            }
            .dln-orderRev__detailsTitle {
                width: 100%;
                margin: 0 0 16px;
                font-size: 14px;
            }
            .dln-orderRev__desc {
                margin: 16px 24px 0 0;
                width: calc(100% - 24px - 100px);
            }
            >.dln-btn {
                width: 100px;
                margin: 16px 0 0 0;
            }
        }
        &--returned {
            & .dln-orderRev__actions {
                & .dln-orderRev__howItWorks {
                    margin: 0 auto;
                }
            }
        }
    }

    .dln-orderRev-input-wrapper {
        align-items: flex-start;
        justify-content: flex-end;
        flex-wrap: wrap;

        &__single-item-content {
            flex: 0 0 calc(100% - 30px);
            margin-top: 30px;
        }
        &__select-quantity,
        &__select-motivation {
            margin-left: 10px;
            .dln-select {
                max-width: 100%;
            }
        }
        .dln-checkbox {
            flex: 0;
            margin-top: 28px;
        }
        .dln-orderRev {
            flex: 0 0 calc(100% - 30px);
        }
        &__single-item-content.-notReturnable {
            & .dln-orderRev__notReturnSel {
                margin: 0;
            }
            & .dln-orderRev__notReturnBox {
                & .dln-btn {
                    width: calc(100% - 40px);
                    margin: 0 auto 0 0;
                }
            }
        }
    }
}

.dln-cart__aside {
  & .dln-cart__amazonPay {
    margin-top: 10px;

    span {
        display: block;
        margin-bottom: 10px;
        text-align: center;
    }
  }

  & .dln-cart__orderRecap {
    margin-bottom: 24px;
  }

  & .dln-cart_paymentBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    width: 100%;
    color: var(--ui-02);
    background-color: $ui-03;

    & ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      gap: 5px;
    }
  }

  .dln-cart__button-wrapper {
    margin-top: 30px;
    padding: 0 20px;
  }

  .dln-orderSumm__price-wrapper {
    padding: 24px;
    background-color: $ui-03;
  }

  .dln-orderSumm__contentRow {
    &:first-of-type{
      border-bottom: 0;
    }

    &--promo {
      margin-bottom: 16px;
      padding: 24px;
      background-color: $ui-03;
    }
  }
}

.dln-orderSumm {
  padding-top: 15px;
  width: 100%;
  background-color: $ui-03;
  border: 1px solid $ui-01;

  .dln-cart__payment-wrapper {
    margin: 15px 0;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--ui-01);

    span {
      font-size: REM(15px);
      color: $ui-02;
    }

    ul {
      list-style: none;
      display: flex;
      gap: 10px;
    }
  }

  &--light {
    .dln-orderSumm__content {
      padding: 40px 24px 40px;
    }
  }

  &__head {
    @include mainMenu;
    display: none;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 60px;
    background-color: $ui-01;
    padding: 16px 24px;

    & .dln-icn {
      width: 24px;
      &--cart {
        font-size: 24px;
      }
      &--arrow-down {
        display: none;
      }
    }

    & .dln-orderSumm__title {
      @include fontBold;
      margin: 0 0 0 16px;
    }

    & .dln-orderSumm__headPriceLbl {
      margin: 0 0 0 auto;
    }
  }

  &__boxEntries .dln-orderSumm__contentRow {
    border-bottom: 0;
  }
  
  &__total-title {
    margin-bottom: 15px;
    font-size: REM(18px);

    & > i {
      margin-right: 10px;
      font-size: 24px;
    }
  }

  &__content {
    width: 100%;
    padding: 0 24px 40px;

    & .dln-orderSumm__lbl {
      & + .dln-btn--link {
        margin: 0 0 0 auto;
        padding: 0;
      }
    }
  }

  &__recap {
    width: 100%;
  }

  &__item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__itemPicWrap {
    display: inline-flex;
    align-items: center;
    max-width: 80px;
    height: 80px;
    background-color: $brand-03;
    margin: 0 24px 0 0;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    & > img {
      margin: 0 auto;
      object-fit: contain;
    }
  }

  &__itemDetails {
    width: calc(100% - 80px - 24px);

    h5 {
      @include mainMenu;
      @include fontBold;
      margin: 4px 0 16px;
      padding-right: 30px;
    }
    & .dln-price {
      margin: 0 8px 0 0;
    }
  }

  &__contentRow {
    @include small;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $ui-01;
    padding: 16px 0;

    .dln-price {
      margin: 0 0 0 auto;

      & + .dln-price {
        margin: 0 0 0 10px;
      }

      &--secondary {
        font-size: 12px;
      }

      &--removeLink {
        @include fontBold;
        margin: 0 auto 0 8px;
        a {
          font-weight: bold;
        }
      }
    }

    &:nth-last-child(2) {
      position: relative;
      border: none;
    }

    &--promo {
      & .dln-price {
        @include fontBold;
        color: $support-01;
        margin: 0 0 0 auto;

        a {
          color: $brand-04;
        }

        &--removeLink {
          margin: 0 auto 0 0;
        }
      }

      .dln-btn--apply-voucher {
        padding: 0;
      }

      & .dln-price--coupon {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 100px;

        & > span {
          margin-bottom: 10px;
          font-size: 18px;
          color: $brand-02;
        }

        & > div {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          color: $support-02;
          font-size: 14px;

          span {
            margin-left: 10px;
          }

          > i {
            font-size: 26px;
            color: $brand-04;
            cursor: pointer;
          }
        }
      }
    }

    &--subtotal {
      & .dln-price {
        &--discounted {
          @include fontBold;
          color: $support-01;
        }
      }
    }

    &--shipping {
      & .dln-price,
      & .dln-orderSumm__rowTitle {
        line-height: 23px !important;

        & > i {
          color: $brand-01;
        }
      }

      & .dln-price__free {
        position: absolute;
        right: 0;
        top: 19px;
        font-size: 19px;
        color: $support-02;
      }

      & .dln-price--secondary {
        color: $brand-02;
      }
    }

    &--taxes {
      justify-content: space-between;

      & .dln-orderSumm__rowTitle {
        width: auto;
        height: 16px;
        line-height: 16px;
        margin-right: 0;
        align-items: baseline;
      }

      & .dln-tooltip {
        margin: 0 auto 0 0;
      }

      .dln-orderSumm__field {
        margin: 16px 0;
        width: 75%;

        & .dln-inputs__extraLbl--error {
          width: 100%;
        }
      }
    }

    &--total {
      border-style: solid;
      border-width: 1px;
      border-width: 1px 0 1px;
      border-color: $brand-02;

      & span {
        @include mainMenu;
        @include fontBold;
        color: $brand-04;

        a,
        .dln-icn {
          font-size: 16px;
          color: $ui-02;
        }

        a {
          font-size: 10px;
          margin: 0 0 0 4px;
        }
      }
    }
  }

  &__field {
    display: flex;
    //overflow: hidden;
    flex-wrap: wrap;
    width: 100%;
    margin: 16px 0 0;

    .dln-btn {
      width: 105px !important;
      min-width: 91px !important;
      //overflow: hidden;
      text-overflow: initial;
      height: 48px;
    }

    .dln-inputs {
      width: calc(100% - 115px);
      margin: 0 8px 0 0;
    }
  }

  &__rowTitle {
    @include small;
    display: inline-flex;
    align-items: center;
    align-content: center;
    color: $brand-02;
    margin: 0 10px 0 0;

    &--secondary {
      color: $ui-02;
      font-size: 12px;
    }

    & .dln-icn {
      // width: 15px;
      // height: 15px;
      height: 16px;
      line-height: 16px;
    }

    & > .dln-icn {
      margin: 0 0 0 8px;
    }

    & .dln-tooltip {
      margin: 0 0 0 8px;
    }

    .dln-price {
      margin: 0 4px;
      &--secondary {
        font-size: 12px;
      }
    }

    + .dln-tooltip {
      margin-left: 8px;
    }
  }
}

.dln-braun {
  .dln-orderSumm {
    &__contentRow {
      &--taxes svg {
        margin-left: 10px;
        path {
          fill: $brand-04;
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .dln-orderSumm {
    max-height: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .dln-orderSumm__contentRow--taxes,
    .dln-orderSumm__contentRow--shipping {
      .dln-tooltip {
        & .dln-tooltip__container {
            width: 120px;
        }

        & .dln-tooltip__content {
            width: 100%;
            text-align: left;
        }

        & .dln-tooltip__text {
            max-width: none;
        }
      }
    }
  }

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-recipe-grid {
        .dln-recipe {
            width: calc(33.33% - 13.34px);
            margin-bottom: 30px;

            &:nth-of-type(4n+4) {
                margin: 0 20px 30px 0;
            }
            &:nth-of-type(3n+3) {
                margin: 0 0 30px 0;
            }
        }
    }
}

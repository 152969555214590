.dln-kenwood {
    .dln-marketingPreferences {
        // &__sub-title {
        // 	@include fontMedium;
        // 	font-size: 28px;
        // 	line-height: 1.21;
        // 	margin-top: 40px;
        // 	text-align: center;
        // 	width: 100%;

        .dln-checkbox {
            border: none;
            padding: 0;

            .dln-icn.dln-icn--iconfont {
                font-size: 18px;
                font-weight: 700;
                color: $brand-02;
            }

            .dln-checkbox__tooltipWrap {
                font-size: 12px;
            }
        }

        .dln-checkbox__confirmActions {
            margin-top: 24px;
            padding: 20px 0 20px 0;
            border-top: 1px solid $ui-01;
            align-items: flex-end;

            .dln-btn.dln-btn--confirm {
                width: 102px;
            }
        }

        .dln-checkbox.dln-checkbox--image.dln-checkbox--hideLabel {
            box-shadow: none;
        }

        #marketingComunicationSources__wrapper {
            margin-top: 0;
            padding-top: 50px;
            border-top: 1px solid $ui-01;

            .dln-page__sectionHead {
                text-align: center;
            }

            .dln-page__sectionHeadTitle {
                font-family: Merriweather;
                margin-bottom: 20px;
                @include fontExtra;
            }
        }

        @media only screen and (max-width: 599px) {
            .dln-title {
                margin-top: 0;
            }

            .dln-page__section {
                margin-bottom: 50px;
            }

            .dln-page__sectionHead {
                margin-bottom: 0;
                &Title {
                    font-size: 24px !important;
                }
            }

            .dln-checkbox.dln-checkbox--image.dln-checkbox--hideLabel {
                border-bottom: 1px solid $ui-01;
                padding-bottom: 20px;
                margin-top: 22px;
            }

            .dln-page__sectionFooter {
                margin-top: 20px;
                &::before {
                    content: none;
                }
            }

            .dln-checkbox__confirmWrap{
                margin-top: 15px;
            }

            .dln-checkbox__confirmActions{
                padding-bottom: 0;
            }
        }
    }

    .dln-marketingPreferences ~ .dln-popup--open {
        padding: 20px;
        width: 450px;

        .dln-alert::before {
            left: 15px;
        }

        .dln-popup__wrapper > div:first-child {
            padding: 0;

            .dln-popup__head {
                padding: 15px 0;
                width: 100%;
                margin: 0;
            }

            .dln-btnIcon {
                right: 50px;
                top: 33px;

                .dln-icn.dln-icn--iconfont {
                    color: $brand-04;

                    font-size: 0;

                    &::before,
                    &::after {
                        font-size: 22px;

                        color: $brand-04;
                    }

                    &::before {
                        content: "Z";
                    }
                }
            }

            .dln-popup__headTitle {
                @include fontSemibold;
                font-size: 14px;
                line-height: 35px;
                padding-left: 65px;
            }
        }
    }

    @media only screen and (max-width: 599px) {
        .dln-marketingPreferences ~ .dln-popup--open {
            width: 100%;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
            top: 10px;

            .dln-alert.dln-alert--success.dln-alert--minimal {
                margin-bottom: 0;
            }
        }
    }
}

.bra-btnIcon {
    --bgColor: #{$brand-03};
    --txtColor: #{$brand-01};
    --supportColor: #{$support-02};

    display: inline-block;
    font-size: 20px;
    width: 1em;
    border: none;

    background-color: transparent;
    box-sizing: content-box;

    appearance: none;
    cursor: pointer;
    color: var(--txtColor);
    text-decoration: none;

    position: relative;

    .bra-icn {
        font-size: 1em;
        color: var(--txtColor);
        margin: 0;
        line-height: 1;
    }
    &__wrap {
        display: block;
    }
    &--cart {
        position: relative;
        & .bra-btnIcon__icon {
            margin: 0 4px 0 0;
        }
    }
    &__checkoutCounter {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: $support-02;
        position: absolute;
        top: -8px;
        right: -8px;
        display: flex;
        align-content: center;
        align-items: center;

        &Text {
            width: auto;
            font-size: 11px;
            @include fontBold;
            color: $brand-03;
            text-align: center;
            margin: 0 auto;
            font-style: inherit;
            /*  */
            display: inline-block;
            padding: 0 0;
            height: 11px;
            line-height: 100%;
            font-family: sans-serif;
        }
    }


    &--chat {
        width: 1.8em;
        .bra-icn {
            color: $support-02;
        }
    }

    @media (hover: hover) {
        &:hover {
            color: rgba(0,0,0,.7);
            .bra-icn {
                color: rgba(0,0,0,.7);
            }
        }
    }
    &:active {
        color: var(--supportColor);
        .bra-icn {
            color: var(--supportColor);
        }
    }

    &:disabled,
    &:disabled:hover {
        cursor: default;
        .bra-icn {
            color: $brand-02 !important;
        }
    }

    &--circle {
        width: auto;
        height: auto;
        overflow: hidden;
        border-radius: 150px;

        @media (hover: hover) {
            &:hover {
                background: var(--bgColor);
                transition: background .2s ease-in-out;
                .bra-btnIcon__circle {
                    background: var(--bgColor);
                    transition: background .2s ease-in-out;
                    .bra-icn {
                        //background: var(--supportColor);
                        color: var(--txtColor);
                        transition: color .2s ease-in-out;
                    }
                }
                .bra-icn {
                    //background: var(--supportColor);
                    color: var(--txtColor);
                    transition: color .2s ease-in-out;
                }
            }
        }
    }

    &__circle {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        background-color: var(--txtColor);
        transition: background .2s ease-in-out;

        .bra-icn {
            color: var(--bgColor);
        }

        @media (hover: hover) {
            &:hover {
                background: var(--bgColor);
                transition: background .2s ease-in-out;

                .bra-icn {
                    //background: var(--supportColor);
                    color: var(--txtColor);
                    transition: color .2s ease-in-out;
                }
            }
        }
    }

    &--text {
        width: auto;
        display: inline-flex;
        align-items: baseline;
        margin-right: 20px;

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
            }
        }
        &:active {
            color: var(--supportColor);
            .bra-icn {
                color: var(--supportColor);
            }
        }
    }

    &__text {
        @include fontBold;

        margin-left: 0.5em
    }


    &--hamburger-menu {

        .bra-btnIcon__wrap {
            width: 1em;
            height: 1em;
            position: relative;
            justify-content: flex-start;

            input {
                display: block;
                width: 2em;
                height: 2em;
                position: absolute;
                top: 0;
                left: 0;

                cursor: pointer;

                opacity: 0; /* hide this */
                z-index: 2; /* and place it over the hamburger */

                -webkit-touch-callout: none;
            }

            b {
                display: block;
                width: 1em;
                height: 0.15em;
                background: var(--txtColor);
                margin-bottom: 0.25em;
                position: relative;
                border-radius: 1px;

                //transition: all .2s ease-in-out;

                &:nth-last-child(1) {
                    margin-bottom: 0;
                    width: 0.5em;
                }
            }

            // input:checked ~ b:nth-last-child(2) {
            //     display: none;
            // }

        }
    }

    &.displayNotificationDrawer {
        .dln-drawer {
            display: block;
            visibility: visible;
        }
    }
}

@media only screen and (max-width:599px) {
    .bra-btnIcon {
    	font-size: 15px;
    }
}
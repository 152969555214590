.dln-kenwood {
    .dln-payCardBox {
        min-height: 220px;
        border-color: $ui-07;

        &__head {
            width: calc( 100% - 40px );
            height: auto;
            background-color: transparent;
            padding: 20px 0;
            margin: 0 20px;
            border-bottom: 1px solid $ui-01;
        }
        &__creditCard {
            display: flex;
            flex-wrap: wrap;
            width: 50px;
            height: 36px;
            border: 2px solid $brand-04;
            border-radius: 4px;
            background-color: $brand-03;
            position: relative;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                background: $brand-04;
                height: 2px;
                left: 4px;
            }
            &::before {
                top: 16px;
                width: 10px;
                box-shadow: 12px 0 0 0 $brand-04;
            }
            &::after {
                top: 20px;
                width: 14px;
                border-right: 2px solid $brand-03;
                box-shadow: 6px 0 0 0 $brand-04;
            }

            &Strip {
                align-self: flex-start;
                width: 100%;
                height: 5px;
                border-top: 2px solid $brand-04;
                border-bottom: 2px solid $brand-04;
                margin: 4px 0 0 0;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    border: 2px solid $brand-04;
                    height: 6px;
                    width: 10px;
                    bottom: 3px;
                    right: 3px;
                }
            }
        }
        &__cardInfo {
            width: calc(100% - 50px - 40px);
            margin: 0;
            padding: 0 20px;
            font-size: 14px;
            @include fontSemibold;
        }
        &__content {
        }
        &__field {
            flex: 0;
            width: auto;
            padding-right: 20px;

            &:first-child {
                flex: 1;
                padding-right: 0;
            }

            &Label {
                @include fontLight;
                color: $ui-08;
                font-size: 12px;
            }
            &Data {
                @include fontLight;
                font-size: 14px;
                line-height: 20px;
            }
        }
        &__foot {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            width: 100%;
            .dln-checkbox {
                width: 100%;
                margin: 0 0 16px;
            }
        }
        & .dln-btn--link {
            display: inline-flex;
            font-size: 13px;

            &:first-of-type {
                margin: 0 0 0 auto;

                &::after {
                    content: '';
                    display: block;
                    display: none;
                    width: 1px;
                    height: 16px;
                    background-color: $brand-01;
                    margin: 0 8px;
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-search {
        padding: 0 20px !important;
        max-width: 590px;

        .dln-popup__head {
            padding: 57px 0 20px 0;

            &Title {
                font-size: 24px;
            }
        }

        &__withResult-section {
            flex-direction: column;
            .dln-search-result:nth-last-child(odd) {
                width: 100%;
                margin-top: 35px;
            }

            .dln-search-result:nth-last-child(even) {
                display: none;
            }

            .simple-list {
                width: 100%;
                &:first {
                    padding-top: 25px;
                }
                &:last-child {
                    font-size: 13px;
                    padding-top: 20px;
                    padding-bottom: 104px;
                }
            }
        }
    }
}

.dln-kenwood {
    & .dln-orderInfoCard {
        padding: 20px 0;

        &__number {
            line-height: 24px;
            font-size: 20px;
            color: $brand-04;
            margin-bottom: 20px;
        }
        & .dln-orderInfoCard__table {
            & .dln-orderInfoCard__cell {
                & .dln-orderInfoCard__cellTitle,
                & .dln-orderInfoCard__cellData {
                    line-height: 20px;
                    font-size: 14px;
                }
                & .dln-orderInfoCard__cellTitle {
                    margin-bottom: 2px;
                }
                & .dln-orderInfoCard__viewDetail {
                    line-height: 20px;
                    color: $brand-04;
                    @include fontBold;
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-orderInfoCard {
            &__number {
                font-size: 18px;
                line-height: 22px;
            }
            & .dln-orderInfoCard__table {
                & .dln-orderInfoCard__cell {
                    margin-bottom: 6px;

                    &:last-of-type {
                        padding-top: 14px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

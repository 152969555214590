.dln-delonghi {
    .dln-page.dln-regProductList {
        &--empty {
            .dln-wrapper {
                .dln-page__content {
                    .dln-regProductList__subTitle {
                        display: none
                    }
                }
            }
        }
    }
}

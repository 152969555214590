.dln-contactUs {
  &__accordion {
    width: 100%;
    margin: 15px 0;
    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      margin-right: 40px;
    }
    @media screen and (min-width: map-get($grid-breakpoints, xl)) {
      margin-right: 0;
    }
  }

  &__accordionTrigger {
    width: 100%;
    background: none;
    border: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;

    .dln-icn--main {
      font-size: 2rem;
    }

    .dln-icn--arrow-down {
      @media screen and (min-width: map-get($grid-breakpoints, sm)) {
        display: none;
      }
    }

    &[data-state='open'] {
      .dln-icn--arrow-down {
        transform: rotate(180deg);
      }
    }
  }

  &__accordionTitle {
    flex: 1;
    text-align: left;
  }

  &__accordionContent {
    padding-top: 20px;
    position: relative;
    z-index: 4;
  }

  &__accordionDivider {
    width: 100%;
    margin: 15px 0;
    height: 1px;
    background-color: var(--ui-01);
  }

  &__infoAccordionContent {
    position: static!important;
  }
}

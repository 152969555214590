.dln-delonghi {
  .dln-popup__content {
    max-width: 490px;

    .dln-forgPsw {
      .dln-inputs,
      .dln-btn {
        max-width: 100%;
        width: calc(100% - 40px);
        padding: 0 20px;
        margin: 0 auto;
        + .dln-btn {
          margin: 30px auto 0;
        }
      }

      .dln-popup__headTitle {
        width: calc(100% - 48px);
        line-height: 20px;
        font-size: REM(17px);
        text-align: left;
        padding: 10px 0;
        margin: 0 8px 0 0;
        display: flex;
        align-items: center;
      }

      .dlg-gigya-link {
        font-weight: bold;
      }

      @media only screen and (min-width: 599px) {
        .dln-forgPsw {
          padding: 0 40px 24px;
        }
      }

      @media only screen and (max-width: 599px) {
        .dln-forgPsw {
          padding: 0 0 20px 0;
        }
        .dln-inputs,
        .dln-btn {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}

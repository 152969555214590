@media only screen and (max-width:599px) {
    .dln-orderDetailInfo {
        &__col {
            width: 50%;
            margin-bottom: 30px;

            &:first-child {
                width: 100%;
                margin-bottom: 30px;
            }
            &:nth-child(2) {
                padding-right: 5px;
                margin-right: calc(50% + 5px);
            }
            &:nth-child(3) {
                padding-right: 5px;
                margin-bottom: 0;
            }
            &:nth-child(4) {
                padding-left: 5px;
                margin-bottom: 0;
            }
        }
        &__content {
            padding-top: 0;
        }
    }
}

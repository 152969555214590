.dln-delonghi {
  .dln-ratingStars {
    .dln-icn {
      width: 35px;
      height: 35px;
      color: $ui-01;
      &::before {
        font-size: REM(35px);
        content: 'p';
      }
    }

    &:not(.dln-ratingStars--disabled) .dln-ratingStars__star {
      &:hover .dln-icn::before,
      &:hover ~ .dln-ratingStars__star .dln-icn::before {
        color: $brand-01;
      }
    }

    input {
      display: none;
      position: absolute;
      pointer-events: none;
      &:checked,
      &:checked ~ label {
        .dln-icn {
          &::before {
            color: $brand-01;
          }
        }
      }
    }
    &__star {
      &--selected,
      &--selected ~ .dln-ratingStars__star {
        .dln-icn {
          &::before {
            color: $brand-01;
          }
        }
      }
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-prodReg {
        &__head {
            margin-bottom: 60px;
            /* .dln-btn {
                max-width: 100%;
                width: auto;
            } */
        }
        &__advantage {
            .dln-advtBox {
                margin: 0 0 20px;
            }
            &.swiper-wrapper {
                flex-wrap: nowrap;
                .dln-advtBox {
                    margin: 0 0;
                }
            }

        }
        &__subTitle {
            margin-bottom: 16px;
            margin-top: 0;
        }
        .dln-advtBox {
            width: 100%;
            padding: 8px 16px;
            height: 250px !important;
        }
        .dln-carousel--mobileOnly {
            .swiper-pagination {
                padding: 0;
                margin-top: 16px;
            }
        }
    }
}
.dln-giftnoteinfo {
  padding: 20px;

  &__title-box {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 0 10px;
    color: $ui-02;
  }

  &__icon {
    font-size: REM(24px);

    &::before {
      content: "w";
      position: static;
    }
  }

  &__title {
    font-size: REM(16px);
  }

  &__text {
    font-size: REM(14px);
  }
}

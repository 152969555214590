@media only screen and (max-width:599px) {
    .dln-repairCenter {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 32px 0;
        }
        &__content {
            .dln-btn {
                max-width: 100%;
                margin: 0;
            }
        }
    }

}
@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-footCheckout {
        padding: 20px 0 10px 0;

        &__wrap {
            justify-content: center;
            width: 100%;
            border-bottom: 1px solid $brand-02;
            padding: 0 0 24px 0;            
            margin: 0 0 10px 0;
        }
        &__logo {
            width: 68px;
            height: 28px;

            &Icon {
                width: 68px;
                height: 28px;
            }
        }
        &__description {
            text-align: center;            
        }
        &__list {
            display: flex;
            width: 100%;
        }
        &__item {
            display: flex;
            width: 25%;

            &::after {
                top: 16px;
            }
        }
        &__link {                    
            height: 37px;
            line-height: 37px;
            margin: 0 auto;            
        }
    }    
}

@media only screen and (max-width:599px) {
    .dln-paginationSlide {
        display: flex;
        justify-content: center;
        width: 100%;

        &__btn {
            width: 56px;
        }
        &__currentLine {
            width: 56px;
        }
        &__tab {
            width: 56px;
        }
    }
}

.dln-delonghi {
  & .dln-custServiceCard {
    padding: 32px 20px;

    &__title {
      @include fontMedium;
      line-height: 34px;
      font-size: REM(28px);
      margin-bottom: 18px;
    }
    &__text {
      font-size: REM(16px);
      line-height: 24px;
      margin-bottom: 32px;
    }
    &__actions {
      & > .dln-btn {
        @include fontMedium;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-custServiceCard {
      &__actions {
        & > .dln-btn {
          min-width: initial;
          width: 100%;
          max-width: 254px;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-popup__content {
    .dln-popup__wrapper {
      .dln-addedItem__content {
        .dln-cartItem__btn {
          .dln-btn:last-of-type {
            color: var(--brand-03);
            border: 1px solid var(--brand-01);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
    .dln-recipe-by-products {
        .dln-btnTextIcon--braun-filters {
            display: block;
        }

        .dln-breadcrumbs {
            font-size: 13px;
            line-height: 1.38;
        }

        .dln-recipe-slides {
            margin-top: 30px;
        }

        &__title {
            font-size: 24px;
            line-height: 1.13;
        }

        &__search-section {
            width: 100%;
            margin-top: 30px;
        }
    }
}

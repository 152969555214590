.dln-savedCart {
    align-content: flex-start;

    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {

        & .dln-title {
            margin: 0 0 32px 0;
        }
        & .dln-addBox {
            width: calc(50% - 40px - 2px - 8px);
            margin: 0 16px 40px 0;
            box-sizing: content-box;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        &Title {
            display: block;
            width: 100%;
            line-height: 32px;
            font-size: 32px;
            @include fontBold;
            margin: 0 0 40px 0;
        }
    }
    & .dln-orderRev--productReview {
        margin: 0 0 10px 0;
    }
    &__cart {
        width: 100%;
        padding: 22px 20px;
        margin: 0 0 24px;
        border: 1px solid $ui-01;
        & .dln-accordion {
            &:nth-child(even) {
                background-color: $ui-03;
            }
        }
    }
    &__cartTitle {
        font-size: 26px;
        line-height: 26px;
        @include fontBold;
        margin: 0 0 10px;
    }
    &__cartFoot {
        width: 100%;
        margin: 16px 0 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        & .dln-btn {
            width: auto;
            &:not(.dln-btn--link) {
                margin: 0 0 0 auto;
            }
        }
    }
}
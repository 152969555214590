.dln-order {
    .dln-order__content--lite {
        width: 100%;
        max-width: 793px;
        margin: 0 auto 100px;
        padding-right: calc((100% - 793px) / 2);
        padding-left: calc((100% - 793px) / 2);
        box-sizing: content-box;
    }
}
.dln-order--detail {
    & > .dln-wrapper {
        width: calc(100% - (20px * 2));
    }
    & .dln-order__content--lite {
        & .dln-order__detailProducts {
            &--shipped {
                & .dln-orderHistory {
                    & .dln-orderRev {
                        &__actions {
                            display: none;
                        }
                    }
                }
                & .dln-order__detailTitle {
                    width: calc(100% - 30px - 90px);
                    margin-right: 30px;
                }
            }
        }
    }
}
.dln-order__trackingCode {
    display: inline-block;
    width: 90px;
    height: auto;
    line-height: 21px;
    font-size: 14px;
    color: $brand-01;
    text-align: center;
    text-decoration: underline;
    margin: 0 0 34px auto;
}

.dln-order__detailWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: map-get($grid-breakpoints, sm)) {
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
  }
}

.dln-order__downloadLabelWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin-bottom: 20px;

  @media only screen and (min-width: map-get($grid-breakpoints, sm)) {
    align-items: flex-end;
    margin-bottom: 0;
  }
}

.dln-order__downloadLabel {
  font-size: 14px;
  color: $brand-01;
  text-decoration: underline;
}

.dln-kenwood {
    .dln-changePssw {
        &__section {
            &Title,
            & .dln-title {
                @include fontSemibold;
                font-size: 20px;
                line-height: 24px;
                margin: 0 0 51px 0;

                &__innerLabel {
                    @include fontLight;
                    font-size: 12px;
                    color: $ui-08;
                }
            }

            &Foot {
                border-bottom: 1px solid $ui-07;
                border-top: 0;
                padding: 0 0 69px 0;

                .dln-btn--link {
                    @include fontMedium;
                }

                .dln-btn--update {
                    width: auto
                }
            }

            &--deleteAccount {
                border: 0;
                margin: 0;

                & .dln-btn--link {
                    margin: 0 0 15px 0;
                    @include fontMedium;
                }
                & .dln-changePssw__paragraph {
                    @include fontLight;
                    font-size: 14px;
                }

                ul {
                    @include fontLight;
                    padding: 0;
                    font-size: 14px;
                    line-height: 20px;

                    li {
                        margin: 0;

                        &::before {
                            top: calc(50% - 2px);
                            width: 1px;
                            height: 1px;
                            background: $brand-04;
                        }
                    }
                }
                & .dln-btn:not(.dln-btn--link) {
                    float: right;
                    margin: 31px 0 0;
                }
            }

            &:nth-last-child(2):not(.dln-changePssw__section--deleteAccount) {
                margin: 0 0 30px 0;

                .dln-changePssw__formcontainer {
                    padding-bottom: 9px;
                    border-bottom: 1px solid $ui-01;
                    margin-bottom: 30px;
                }
            }
        }

        &__field {
            margin: 0 0 41px 0;

            &>.dln-inputs,
            &>.dln-select {
                width: calc(50% - 11px);
                margin: 0 22px 0 0;
                max-width: none;
                &:nth-child(2n+2) {
                    margin: 0 0;
                }
            }
        }

        &__confirmDeleting {
            
            &.-extraContent {
                width: 100%;
            }

            .dln-changePssw__middleTitle {
                margin: 30px 0 22px 0;
                font-size: 16px;
                line-height: 22px;
            }
        }



        @media only screen and (max-width:599px) {
            &.dln-page {
                .dln-title {
                    margin-bottom: 32px;
                    &--labeled {
                        margin-bottom: 46px;
                    }
                }
            }
            &__section {
                &Title,
                & .dln-title {
                    @include fontSemibold;
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            &__field {
                &>.dln-inputs,
                &>.dln-select {
                    width: 100%;
                    margin: 0;
                    max-width: none;
                    &:nth-child(2n+2) {
                        margin-top: 41px;
                    }
                }
            }

        }
    }
}
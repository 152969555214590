.dln-delonghi {
  .dln-addBox {
    border: 1px solid $ui-01;
    background-color: $brand-03;

    .dln-icn {
      text-indent: 9999px;
      width: 50px;
      height: 50px;
      background: $brand-05;
      border-radius: 50%;
      margin: 0 0 24px 0;
      overflow: hidden;
      &::before {
        text-indent: 0;
        content: '8';
        width: auto;
        height: auto;
        font-size: REM(16px);
        color: $brand-03;
        top: calc(50% - 8px);
        left: calc(50% - 8px);
      }
    }

    &__title {
      color: $brand-04;
      @include fontMedium;
      font-size: REM(28px);
      line-height: 34px;
      margin: 0;
    }
    &__text {
      color: $brand-04;
    }

    &__button{
      display: -webkit-box;
      height: fit-content;
      cursor: pointer;

      .dln-addBox__title {
        line-height: 19px;
        font-size: REM(13px);
        color: $brand-01;
      }

      .dln-icn{
        color: $brand-01;
      }
    }
  }
}

.dln-totalSect {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    height: auto;
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: $ui-01;
    background-color: $brand-03;
    padding: 30px 0;

    &__title {
        @include fontBold;
        display: block;
        width: calc(100% - 184px);
        height: auto;
        line-height: 26px;
        font-size: 22px;
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        width: 184px;
        height: auto;
        padding-top: 10px;
    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin: 0 0 5px 0;

        &:last-child {
            margin: 0;
        }
    }
    &__label,
    &__data {
        display: inline-block;
        width: 50%;
        line-height: 18px;
        font-size: 13px;
    }
    &__label {
        color: $brand-02;
    }
    &__data {
        color: $brand-01;
        text-align: right;
    }
    &__row--discount {
        & .dln-totalSect__data {
            color: $brand-04;
        }
    }
    &__row--total {
        & .dln-totalSect__label,
        & .dln-totalSect__data {
            @include fontBold;
            font-size: 15px;
        }
        & .dln-totalSect__label {
            color: $brand-01;
            text-transform: uppercase;
        }
    }
    &--lite {
        padding: 20px 0;
        border-bottom: none;

        & .dln-totalSect__wrap {
            width: 100%;
            padding: 0;
        }
    }
}

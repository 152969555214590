.dln-delonghi {
  .dln-filtersPopup {
    &__head {
      height: 65px;
      border-bottom: 1px solid $ui-01;

      .dln-btn {
        @include fontMedium;
        width: auto;
        min-width: auto;
        line-height: 1.29;
        font-size: REM(14px);
        color: $brand-04 !important;
        text-decoration: underline !important;
        background-color: transparent !important;
        padding: 0;
      }
    }
    &__title {
      @include fontMedium;
      line-height: 1.19;
      font-size: REM(21px);
      color: $brand-04;
    }
    &__content {
      height: calc(100vh - 146px);
      // padding-top: 30px;
      padding: 30px 16px 8px 16px;
      overflow: hidden;
      overflow-y: auto;
    }
    &__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $ui-01;

      .dln-btn {
        width: 230px;
      }
    }

    .noUi-connect {
      background-color: $brand-04;
    }

    .dln-accordion {
      &__title {
        @include fontMedium;
        display: flex;
        align-items: center;
        line-height: 1.19;
        font-size: REM(21px);
        color: $brand-04;
        padding: 0;
      }
      &:not(:first-child) {
        & .dln-accordion__head {
          & .dln-icn {
            position: initial;
            top: initial;
            left: initial;
          }
        }
        & .dln-accordion__title {
          width: auto !important;
          max-width: calc(100% - 10px - 16px);
          margin: 0 10px 0 0;
        }
      }
    }
  }
}

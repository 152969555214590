.dln-registration {
    align-content: flex-start;

    & .dln-wrapper {
        padding-top: 48px;
        padding-bottom: 128px;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        // align-items: flex-start;
        align-content: flex-start;
        width: 100%;

        & .dln-title {
            margin: 0 0 32px 0;
        }
    }
    &__paragraph {
        @include small;
        width: 100%;
        color: $brand-04;
        margin: 0 0 20px 0;
        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
        &--secondary {
            @include extraExtraSmall;
            color: $ui-05;
        }
        a {
            color: $ui-05;

        }
    }
    &__subtitle {
        @include cta;
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 8px;
        @include fontBold;
        // text-transform: uppercase;
    }
    &__foot {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 100%;
        height: auto;
    }
    &__btnGroup {
        width: 65%;
        display: flex;
        flex-wrap: wrap;
        margin: 24px auto 0 0;
        .dln-btn {
            width: calc(50% - 8px);
            margin: 0 16px 16px 0;
            &:nth-child(even) {
                margin: 0 0 16px 0;
            }
        }
    }
    &__section {
        width: calc(40% - 16px);
        margin: 0 0 0 0;
        &--main {
            width: calc(50% - 16px);
            margin: 0 auto 0 0;
        }
    }
    &__form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid $ui-01;
        margin: 24px 0 0;
        .dln-registration__paragraph {
            margin: 32px 0;
        }
    }
    &__formContainer {
        width: 100%;
        padding: 32px 0 0;
        margin: 24px 0 0;
        border-top: 1px solid $ui-01;
        .dln-select {
            width: calc(50% - 10px);
            margin: 0 50% 32px 0;
        }
        .dln-inputs {
            margin: 0 0 16px 0;
            &--halfCol {
                width: calc(50% - 10px);
                margin: 0 0 32px 0;
                &:nth-child(even) {
                    margin: 0 20px 32px 0;
                }
            }

            .dln-inputs__extraLbl {
                max-width: 250px;
                white-space: normal;
            }
        }
        .dln-checkbox {
            margin: 70px 0 32px;
        }
    }
    &__formFoot {
        width: 100%;
        margin: 60px 0 0;
        .dln-btn {
            width: 50%;
        }
    }
    &__formOptions {
        display: flex;
        flex-wrap: wrap;
        .dln-registration__subtitle,
        .dln-registration__paragraph {
            font-size: 10px;
        }
        .dln-radioBtn {
            margin: 0 40px 32px 0;
        }
    }
    .dln-registrationForm__formContainer {
        border-top: none;
        margin-top: 0;
        padding-top: 0;
    }
}
@media only screen and (max-width:599px) {
    .dln-orderHistory {
        &--multiple {
            .dln-orderRev {
                &:nth-child(odd) {
                    background-color: $ui-03;
                }
            }
        }
        .dln-orderRev {
            padding: 10px;
            &__reference {
                padding: 0 10px;
            }
            &__itemPic {
                max-width: none;
                width: 60px;
                height: 50px;
                position: absolute;

                // img {
                    // height: 50px;
                    // width: auto;
                // }
            }
            &__content {
                width: 100%;
                padding: 0 0;
            }
            &__details {
                width: calc(100% - 70px);
                margin: 0 0 0 68px;
                text-align: left;

                &Title {
                    margin: 0 0;
                    padding: 0;
                    @include mainMenu;
                }
                .dln-price {
                    font-size: 14px;
                    line-height: 1.2;

                    &--oldPrice {
                        margin: 0 4px 0 0;
                    }
                    &--discounted {
                        margin: 0 0 0 0;
                    }
                }
            }
            &__productCode {
                // order: 10;
                // margin: 10px 0 0;
                margin: 0 0 10px 0;
                font-size: 15px;
                // @include extraSmall;
            }
            &__quantity {
                width: auto;
                text-align: left;
                @include extraSmall;
            }
            &__actions {
                margin: 16px auto 0;
                width: calc(100% - 80px);
            }
        }
        &--openorder {
            .dln-orderHistory__reference {
                max-height: none;
                position: relative;
                width: 100%;
                left: auto;
                padding: 0 10px;
            }
            .dln-orderRev__content {
                padding-top: 0;
            }
        }

        .dln-orderRev--return-flow {
            .dln-orderRev {
                &__productCode {
                    order: initial;
                    margin: 0 0 10px;
                    font-size: 15px;
                    line-height: auto;
                }
            }
            .dln-price {
                font-size: 14px;
                line-height: 1.2;
            }
        }
    }
}

.dln-delonghi {
  & .dln-checkout--express {
    & .dln-accordion--payment {
      & .dln-checkout__dataResult {
        margin-bottom: 40px;
      }
      & .dln-accordion__content--submit {
        border-top: none;

        & > p {
          margin-bottom: 40px;
        }
        & > .dln-btn[title^='Place'] {
          width: 100%;
          max-width: 285px;
          margin: 0;
          background-color: $brand-01 !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-checkout--express {
      & .dln-wrapper {
        & .dln-checkout__content {
          & .dln-accordion {
            &--access-information {
              padding-bottom: 0;

              & .dln-checkout__dataResult {
                padding-top: 4px;
                margin-bottom: 24px;

                & > p {
                  font-size: REM(14px);
                  line-height: 18px;
                }
              }
            }
            &--shipping-information {
              & .dln-checkout__dataResult {
                margin-bottom: 24px;

                & .dln-chips {
                  padding: 16px 16px 10px 16px;

                  &__wrapper {
                    align-items: flex-start;

                    & .dln-chips__content span {
                      font-size: REM(13px);
                      line-height: 16px;

                      & b {
                        @include fontMedium;
                      }
                    }
                  }
                }
              }
            }
            &--shipping-method {
              & .dln-checkout__formData {
                & p:nth-child(2) {
                  & .dln-checkout__formValue {
                    & b {
                      @include fontBook;
                    }
                  }
                }
              }
            }
            &--payment {
              margin-bottom: 40px;

              & .dln-checkout__dataResult {
                margin-bottom: 24px;
              }
              & .dln-accordion__content--submit {
                & .dln-checkout__paragraph {
                  margin-bottom: 24px;
                }
                & .dln-btn {
                  max-width: none;
                }
              }
            }
          }
        }
      }
      & .dln-checkout__services {
        & .dln-checkServiceBox {
          &__wrap {
            & .dln-checkServiceBox__content {
              & .dln-checkServiceBox__title {
                text-align: left;
                @include fontMedium;
              }
            }
          }
        }
      }
    }
  }
}

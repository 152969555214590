.dln-delonghi {
  & .dln-verification {
    & .dln-popup__head {
      padding: 21px 0 24px 0;

      &Title {
        line-height: 24px;
        font-size: REM(18px);
        padding: 0;
        margin-bottom: 20px;
      }
      &Line {
        background-color: $ui-01;
        margin: 0;
      }
      & .dln-btnIcon--close {
        margin-bottom: 20px;
      }
    }
    &__text {
      line-height: 24px;
      font-size: REM(16px);
      margin: 0 0 33px 0;

      & + .dln-inputs:not(.dln-textarea) {
        width: calc(100% - 27px - 27px);
        max-width: none;
        margin: 0 auto 29px auto;
      }
    }
    &__form {
      & .dln-btn {
        width: calc(100% - 27px - 27px);
        margin: 0 auto 34px auto;
      }
    }
  }
}

.dln-kenwood {
    .dln-miniCart {
        &__arrow {
            display: none;
        }
        &__wrapper {
            border-radius: 0;
            background-color: $brand-03;
            box-shadow: none;
            padding: 20px 20px 30px;
        }
        &__head {
            width: 100%;
            padding: 0 0 15px;
        }
        &__content {
            min-height: auto;
            padding: 0;
            padding: 20px 0;
        }
        &__footer {
            padding: 0;

            & .dln-orderSumm {
                &__contentRow {
                    &--total {
                        border-top: 1px solid $brand-04;
                    }
                }
            }
        }
        &__mainTitle {
            line-height: 20px;
            font-size: 14px;
        }
        &__cta {
            padding: 0;
            margin-top: 15px;

            .dln-btn {
                order: 1;
                width: 100%;
                margin: 0 0 15px 0;
                // margin: 0 0 15px 0;

                // &:nth-child(even) {
                //     margin: 0;
                // }
                &--stroked {
                    order: 2;
                    margin: 0;
                }
            }
        }
        .dln-addItem {
            .dln-addItem__title {
                line-height: 16px;
                font-size: 14px;
            }
            .dln-addItem__quantity {
                line-height: 16px;
                font-size: 14px;
            }
            .dln-addItem__price {
                margin: 8px 0 16px;
                .dlg-omnibus-price {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            .dln-addItem__picture {
                width: 100px;
                margin-right: 20px;
            }
            .dln-addItem__content {
                width: calc(100% - 100px - 20px);
            }
            &+.dln-addItem {
                padding: 20px 0 0;
                margin: 20px 0 0;
            }
        }
        .dln-orderSumm {
            margin: 0;
            &__rowTitle {
                color: $ui-10;
            }
            &__contentRow {
                padding: 20px 0;
                &--total {
                    padding: 15px 0;
                    .dln-orderSumm__rowTitle {
                        line-height: 24px;
                        font-size: 20px;
                        color: $brand-04;
                    }
                    span .dln-icn,
                    span a {
                        color: $ui-08;
                    }
                    span a {
                        line-height: 16px;
                        font-size: 12px;
                    }
                }
                .dln-price--total {
                    line-height: 24px;
                    font-size: 20px;
                    color: $brand-04;
                }
            }
        }
        &--empty {
            .dln-miniCart__content {
                padding: 30px 0 0;

                .dln-miniCart__message {
                    @include fontExtra;
                    line-height: 36px;
                    font-size: 28px;
                    color: $brand-04;
                    margin: 20px 0 0;
                }
                .dln-icn {                    
                    width: 80px;
                    // height: auto;                    
                    height: 80px;
                    //
                    line-height: normal;
                    // font-size: 80px;
                    font-size: 0;
                    //
                    color: $ui-01;
                    margin: 0 auto;      

                    &::before {
                        content: '#';
                        font-size: 80px;
                        left: -14px;                        
                    }
                }
            }
        }
    }
}
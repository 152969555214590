.dln-kenwood {
    .dln-drawer {
        color: $brand-04;
        min-width: 206px;
        box-shadow: none;
        border: solid 1px $ui-01;

        position: absolute;
        top: calc(100% + 16px);
        left: 12px;
        transform: translateX(-75%);

        @media only screen and (max-width:map-get($grid-breakpoints, md)) {
            top: calc(100% + 10px);
        }

        &:after,
        &:before {
            bottom: 100%;
            left: 75%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #ffffff;
            border-width: 6px;
            margin-left: -6px;
        }
        &:before {
            border-bottom-color: $ui-01;
            border-width: 8px;
            margin-left: -8px;
        }

        .txt_container {
            .icon {
                margin-bottom: 16px;
                svg {
                    path {
                        fill: $brand-01;
                    }
                }
                .dln-icn {
                    font-size: 24px;
                    color: $brand-01;
                }
            }
            .text {
                font-size: 20px;
                line-height: 24px;
                @include fontSemibold;
            }

            & + .cta_container {
                margin-top: 17px;
            }
        }
        .cta_container {
            .text {
                @include fontLight;
                margin-bottom: 30px;

                a {
                    color: $brand-04;
                    @include fontMedium;
                }
            }

            .ctas {
                .dln-btn {
                    font-size: 12px;
                    line-height: 23px;
                    padding: 11px;
                    text-transform: uppercase;
                    @include fontSemibold;
                }
            }
        }


        &--slim {
            padding: 14px 25px 13px 20px;
            text-align: left;

            .txt_container {
                .icon {
                    margin-bottom: 0;
                    margin-right: 15px;
                }
                .text {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        &--user {
            border-top: 1px solid black;
            box-shadow: 0 -1px 0 0 black;

            &:before {
                content: none;
            }
            &:after {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: #000000;
                border-width: 6px;
                margin-left: -6px;
            }

            .userNotLogged {
                button {
                    width: 100%
                }
                .text {
                    margin-top: 5px;
                    margin-bottom: 10px;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: left;

                    a {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
                hr {
                    height: 0;
                    border: 0;
                    background: none;
                    border-bottom: 1px solid $ui-01;
                    margin-bottom: 10px;
                }
            }

            .utilityLinks {
                text-align: left;

                a {
                    text-align: left;
                    display: block;
                    font-size: 12px;
                    line-height: 30px;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
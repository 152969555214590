.dln-kenwood {
    .dln-browse-by {
        border-bottom: 1px solid $ui-01;

        &__linksWrapper {
            justify-content: initial;
        }
        &__link {
            width: 12% !important;
            max-width: none;
            line-height: 1.57;
            font-size: 14px;
            font-weight: 600;
            border-right: 1px solid $ui-01;
            padding: 0;
            padding: 0 12px;
            margin-bottom: 16px;

            &:first-of-type {
                text-align: left;
                padding-left: 0;
            }
            &:nth-of-type(3) {
                width: 16% !important;
            }
            &:last-of-type {
                width: 9%;
                text-align: right;
                border-right: none;
                padding-right: 0;
            }
            &Prefix {
                display: initial;
                font-weight: 600;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .dln-kenwood {
        & .dln-browse-by{
            & .dln-browse-by{
                &__link {
                    width: auto !important;
                    border-right: none;
                    padding: 0;
                }
            }
        }
    }
}

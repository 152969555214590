.dln-kenwood {
    & .dln-return-full-account {
        & > .dln-wrapper {
            &:first-child {
                padding-bottom: 100px;
            }
        }
        & .dln-return-full-account__content {
            & .dln-btnTextIcon {
                height: 20px;
                padding: 0;

                &__wrap {
                    height: 20px;
                }
                &__iconWrap {
                    width: 12px;
                    height: 20px;
                    line-height: 20px;

                    & .dln-icn {
                        height: 20px;
                        line-height: 20px;
                        font-size: 12px;
                        color: $brand-04;
                        margin: 0 7px 0 0;
                    }
                }
                &__text {
                    width: auto;
                    max-width: initial;
                    height: 20px;
                    line-height: 20px;
                    color: $brand-04;
                    text-transform: lowercase;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
            & .dln-return-full-account__title {
                @include fontExtra;
                line-height: 46px;
                font-size: 38px;
                margin-top: 40px;
            }
            & .dln-return-full-account__order-infos {
                margin-top: 29px;

                & .dln-return-full-account__info {
                    & .dln-return-full-account__value {
                        @include fontSemibold;
                    }
                }
            }
            & .dln-return-full-account__text {
                line-height: 20px;
                font-size: 14px;
                margin-top: 20px;
                margin-bottom: 40px;
            }
            & .dln-return-full-account__product-list {
                width: 100%;
                background-color: $ui-03;
                padding: 15px 20px 15px 22px;

                & * {
                    // background: rgba(0,0,0,.1);
                }
                & .dln-orderHistory--multiple {
                    margin-bottom: 0;

                    & .dln-orderRev-input-wrapper {
                        align-items: flex-start;
                        border: none;
                        padding: 15px 0;
                        position: relative;

                        &::after {
                            content: '';
                            width: calc(100% - 14px - 22px);
                            height: 1px;
                            background-color: $ui-01;
                            position: absolute;
                            bottom: 0;
                            left: calc(14px + 22px);
                        }
                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }
                        & .dln-checkbox {
                            margin-top: calc(75px - 7px) !important;

                            &--productDescription {
                                width: 14px;
                                height: 14px;
                                min-height: initial;
                                margin: 0 22px 0 0;

                                & .dln-checkbox__wrap {
                                    width: 14px;
                                    max-width: none;
                                    height: 14px;
                                    min-height: initial;
                                    padding: 0;
                                }
                                & .dln-checkbox__inputPlaceholder {
                                    flex-shrink: initial;
                                    width: 14px;
                                    height: 14px;
                                    box-sizing: border-box;
                                    top: 0;
                                }
                            }
                        }
                        & .dln-orderRev--productDescription {
                            flex: initial;
                            width: calc(100% - 266px);
                            margin: 0;

                            & .dln-orderRev__itemPic {
                                margin: 0 20px 0 0;
                                position: initial;
                            }
                            & .dln-orderRev__content {
                                width: calc(100% - 180px - 20px);
                                // padding-right: 70px;
                                max-width: 180px;
                                padding-top: 5px;
                                padding-right: 20px;
                                margin: 0 auto 0 0;
                                box-sizing: border-box;

                                & .dln-orderRev__details {
                                    flex-direction: column;
                                    width: 100%;
                                    height: 100%;

                                    & .dln-orderRev__wrap {
                                        &--quantity {
                                            margin-top: auto;
                                            margin-bottom: 20px;
                                        }
                                    }
                                }
                            }
                        }
                        & .dln-orderRev-input-wrapper__single-item-content {
                            flex: initial;
                            width: 266px;

                            & .dln-orderRev-input-wrapper__select-quantity {
                                flex-wrap: wrap;
                                width: 100%;

                                & > span {
                                    display: block;
                                    width: 100%;
                                    line-height: 16px;
                                    font-size: 12px;
                                    color: $ui-07;
                                    margin-bottom: 5px;
                                }
                                & .dln-inputIncr {
                                    width: 100%;
                                    max-width: 120px;
                                    height: 45px;
                                    border-radius: 0;
                                    border-color: $ui-01;
                                    background-color: $brand-03;
                                    margin: 0;

                                    & .dln-inputIncr__input {
                                        @include fontSemibold;
                                        height: 24px;
                                        line-height: 24px;
                                        font-size: 20px;
                                    }
                                }
                            }
                            & .dln-orderRev-input-wrapper__select-motivation {
                                & .dln-select.dln-select--productDescription {
                                    margin: 0;

                                    & .dln-select__container {
                                        border-color: $ui-01;
                                        background-color: $brand-03;

                                        & .dln-select__value {
                                            color: $brand-04;
                                        }
                                    }
                                }
                            }
                            & .dln-orderRev__notReturnBox {
                                & .dln-orderRev__notReturnText {
                                    padding-top: 20px;
                                }
                                & > .dln-btn {
                                    &--stroked {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            & .dln-return-full-account__total-row {
                border-top: none;
                padding-top: 9px;
                padding-bottom: 30px;

                & > span {
                    &:first-child {
                        text-transform: lowercase;

                        &:first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
            }
            & .dln-return-full-account__button-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;

                & .dln-btn {
                    width: 160px;
                    font-size: 12px;
                    padding: 0;
                    background-color: $brand-01;
                }
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-return-full-account.dln-page {
            & > .dln-wrapper {
                & .dln-return-full-account__aside {
                    width: 100%;
                    margin: 0;
                }
                & .dln-return-full-account__content.dln-page__content {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-return-full-account.dln-page {
            & > .dln-wrapper {
                padding-bottom: 70px;

                & .dln-return-full-account__aside {
                    margin-bottom: 48px;
                }
                & .dln-return-full-account__content.dln-page__content {
                    & .dln-btnTextIcon--backToOrderDetail {
                        & .dln-btnTextIcon__text {
                            font-size: 14px;
                            padding-top: 2px;
                        }
                    }
                    & .dln-return-full-account__title {
                        line-height: 40px;
                        font-size: 32px;
                        text-align: center;
                        margin-top: 20px;
                    }
                    & .dln-return-full-account__order-infos {
                        & .dln-return-full-account__info {
                            line-height: 24px;
                            font-size: 16px;
                        }
                    }
                    & .dln-return-full-account__text {
                        margin-bottom: 30px;
                    }
                    & .dln-return-full-account__product-list {
                        width: calc(100% + (22px * 2));
                        padding: 10px 22px;
                        margin-left: -22px;
                        margin-bottom: 10px;

                        & .dln-orderHistory.dln-orderHistory--multiple {
                            & .dln-orderHistory__content {
                                & .dln-orderRev-input-wrapper {
                                    border-bottom: 1px solid $ui-01;
                                    padding: 20px 0 20px 0;

                                    &::after {
                                        display: none;
                                    }
                                    &:last-of-type {
                                        border-bottom: none;
                                    }
                                    & .dln-checkbox {
                                        margin-right: 14px;
                                        margin-top: calc(40px - 7px) !important;
                                    }
                                    & .dln-orderRev--productDescription {
                                        width: calc(100% - 14px - 14px);
                                        min-height: initial;

                                        & .dln-orderRev__content {
                                            width: calc(100% - 100px - 20px);
                                            max-width: none;
                                            padding: 0;

                                            & .dln-orderRev__wrap {
                                                &--main {
                                                    & .dln-orderRev__detailsTitle {
                                                        margin-bottom: 32px;
                                                    }
                                                }
                                                &--quantity {
                                                    margin: 16px 0 0 0;
                                                }
                                            }
                                        }
                                    }
                                    & .dln-orderRev-input-wrapper__single-item-content {
                                        width: calc(100% - (14px + 14px));
                                        margin-left: calc(14px + 14px);


                                        & .dln-orderRev-input-wrapper__select-quantity {
                                            margin-left: 0;
                                        }
                                        & .dln-orderRev-input-wrapper__select-motivation {
                                            margin-left: 0;
                                        }
                                        & .dln-orderRev__notReturnBox {
                                            & .dln-btn {
                                                width: calc(100% - 100px);
                                                margin: 0 0 0 auto;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & .dln-return-full-account__total-row {
                        & span {
                            font-size: 18px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-return-full-account.dln-page {
            & > .dln-wrapper {
                padding-bottom: 0;
            }
        }
    }
}

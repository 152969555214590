.dln-notification-center {
  position: fixed;
  z-index: z('notification');
  top: 100px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__notification {
    position: relative;
    width: 150px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    &--success {
      background-color: #c8e6c9;
    }

    &--error {
      background-color: #ffcdd2;
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-savedRec {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 32px 0;
        }
        &__recipeWrap {
            // & .dln-recipe {
            //     width: calc(50% - 7.5px);

            //     &:nth-of-type(2n+2) {
            //         margin: 0 0 10px 0;
            //     }
            // }
        }
    }
}
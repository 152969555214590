.dln-privacy {
    align-content: flex-start;
    overflow: visible;
    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {
        & .dln-title {
            margin: 0 0 32px 0;
        }
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 40px 0;
        margin: 0 0 32px 0;

        &Title {
            display: block;
            width: 100%;
            height: auto;
            line-height: 32px;
            font-size: 32px;
            @include fontBold;
            margin: 0 0 32px 0;
        }
    }
    &__paragraph {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        font-weight: 400;
        color: $brand-04;
        margin: 0 0 20px 0;

        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
    }
    &__underlinedText {
        color: $brand-02;
        text-decoration: underline;

        &:hover {
            color: $brand-04;
        }
    }
    &__subtitle {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 13px;
        @include fontBold;
        // text-transform: uppercase;
    }
    &__radioGroup {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin: 0 0 8px 0;
    }
    & .dln-radioBtn {
        margin: 0 48px 0 0;
    }
    &__section:first-of-type .dln-privacy__paragraph--info:last-of-type {
        margin: 0;
    }
    & .dln-checkbox {
        margin: 0 0 10px 0;
    }
    & .dln-smsCheck {
        margin: 0 0 10px 0;

        & .dln-checkbox {
            margin: 0 0 0 0;
        }
    }
    &__section--advPreferences {
        margin: 0 0 48px 0;
        & .dln-checkbox,
        & .dln-inputAction {
            margin: 0 0 10px 0;
        }
        >.dln-inputAction {
            margin-top: 8px;
        }
        & .dln-privacy__sectionTitle+.dln-privacy__subtitle {
            margin: 0 0 8px;
        }
        & .dln-privacy__subtitle {
            margin: 24px 0 8px;
        }
    }
    &__foot {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 100%;
        height: auto;

        & .dln-btn--cancel {
            @include fontBold;
            margin: 0 auto 0 0;
        }
        & .dln-btn--update {
            max-width: 200px;
            width: auto;
            margin: 0 0 0 10px;
        }
    }
}
.dln-kenwood {
    .dln-popup__content {
        .dln-popup__wrapper {
            > div:first-child.dln-mapPopup {
                padding: 20px 15px;
                max-height: initial;
            }
            .dln-mapPopup {
                padding: 20px 0 20px 15px;
                .dln-mapPopup__company {
                    font-size: 14px;
                    line-height: 20px;
                }
                .dln-mapPopup__distance {
                    @include fontLight;
                    font-size: 14px;
                    line-height: 20px;
                }
                &__wrap {
                    &--info {
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                        .dln-mapPopup__data {
                            margin-bottom: 0;
                            font-size: 14px;
                            line-height: 20px;
                            &--address {
                                margin-bottom: 10px;
                            }
                        }
                    }
                    &--hours {
                        margin-bottom: 15px;
                        padding-bottom: 15px;
                        .dln-mapPopup__data {
                            @include fontLight;
                            line-height: 16px;
                        }
                    }
                    &--actions {
                        margin-bottom: 5px;
                        .dln-mapPopup__data {
                            @include fontLight;
                            line-height: 16px;
                        }
                        .dln-btn--googleMaps {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

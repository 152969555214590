.dln-kenwood {
    & .dln-return-order {
        &__content {
            & .dln-btnTextIcon--braun-filters {
                height: 20px;
                padding: 0;
                margin-bottom: 40px;

                & .dln-btnTextIcon__wrap,
                & .dln-btnTextIcon__iconWrap {
                    height: 20px;
                }
                & .dln-btnTextIcon__iconWrap .dln-icn {
                    height: 20px;
                    line-height: 20px;
                }
                & .dln-btnTextIcon__text {
                    height: 20px;
                    line-height: 20px;
                    text-transform: lowercase;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
            & .dln-custServiceCard {
                margin-bottom: 20px;
            }
            & .dln-return-order-box-detail {
                padding: 30px 20px;
                margin-bottom: 20px;

                & .dln-return-order-box-detail__header {
                    margin-bottom: 30px;

                    & .dln-return-order-box-detail__title {
                        @include fontExtra;
                        line-height: 36px;
                        font-size: 28px;
                    }
                    & .dln-btn--link {
                        @include fontSemibold;
                        color: $brand-04;
                        margin-bottom: 5px;
                    }
                }
                & .dln-return-order-box-detail__body {
                    margin-top: 0;
                }
            }
            & .dln-return-order-product-list {
                background-color: $ui-03;
                padding: 30px 20px;
                margin-bottom: 20px;

                & .dln-return-order__header {
                    border-top: none;
                    padding-top: 0;
                    margin-bottom: 30px;

                    & .dln-return-order__section-title {
                        line-height: 24px;
                        font-size: 20px;
                    }
                }
                & .dln-orderHistory--multiple {
                    margin-bottom: 0;

                    & .dln-orderHistory__content {
                        & .dln-orderRev--cart-order {
                            margin-bottom: 30px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                            & .dln-image.dln-orderRev__itemPic {
                                height: 150px;
                                max-height: none;
                            }
                            & .dln-orderRev__content {
                                padding-top: 5px;

                                & .dln-orderRev__details {
                                    width: calc(100% - 20px - 184px);

                                    & .dln-orderRev__wrap {
                                        &--main {
                                            margin-bottom: 33px;

                                            & .dln-orderRev__detailsTitle {
                                                margin-bottom: 15px;
                                            }
                                            & .dln-price {
                                                &--oldPrice {
                                                    color: $ui-08;
                                                    margin-right: 10px;
                                                }
                                                &--discounted {
                                                    @include fontBold;
                                                }
                                            }
                                        }
                                        &--quantity {
                                            & .dln-orderRev__quantity {
                                                color: $ui-08;
                                            }
                                        }
                                    }
                                }
                                & .dln-orderRev__actions {
                                    width: 184px;
                                    margin: 0 0 0 20px;

                                    & .dln-btn {
                                        width: 100%;
                                        max-width: none;

                                        &--stroked {
                                            background: none;
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            & .dln-totalSect {
                margin-bottom: 20px;
            }
            & .dln-return-order-receipt {
                background-color: $ui-03;
                padding: 30px 20px;
                margin-bottom: 20px;

                & .dln-return-order__header {
                    border: none;
                    padding: 0;
                    margin-bottom: 15px;

                    & .dln-return-order__section-title {
                        line-height: 24px;
                        font-size: 20px;
                    }
                }
                & .dln-return-order-receipt__content {
                    margin-bottom: 0;
                }
            }
            & .dln-return-order-info {
                border: none;
                background-color: $ui-03;
                padding: 30px 20px;

                & .dln-return-order-info__col {
                    &:first-child {
                        & .dln-return-order__section-title {
                            line-height: 24px;
                            font-size: 20px;
                        }
                    }
                    & .dln-return-order-info__title {
                        line-height: 22px;
                        font-size: 16px;
                    }
                    & .dln-return-order-info__description {
                        line-height: 20px;
                        font-size: 14px;
                        margin-top: 5px;
                    }
                }
            }
        }
        /* returned */
        &-returned {
            background-color: $ui-03;
            padding: 30px 20px 0 20px;
            margin-bottom: 20px;

            & .dln-return-order__header {
                border: none;
                padding: 0;
                margin-bottom: 20px;

                & .dln-return-order__section-title {
                    line-height: 24px;
                    font-size: 20px;
                }
                & > .dln-btn--link {
                    display: none;
                }
            }
            & .dln-return-order-returned__box {
                padding: 0;
                margin-bottom: 32px;

                &-title {
                    @include fontSemibold;
                    line-height: 20px;
                    font-size: 14px;
                    margin-bottom: 6px;
                }
            }
            & .dln-orderHistory {
                margin-bottom: 32px;

                &__content {
                    & .dln-orderRev--returned {
                        margin-bottom: 0;

                        & .dln-image.dln-orderRev__itemPic {
                            height: 150px;
                        }
                        & .dln-orderRev__content {
                            & .dln-orderRev__details {
                                & .dln-orderRev__wrap {
                                    &--main {
                                        margin-bottom: 33px;

                                        & .dln-orderRev__detailsTitle {
                                            margin-bottom: 15px;
                                        }
                                        & .dln-price {
                                            &--oldPrice {
                                                color: $ui-08;
                                                margin-right: 10px;
                                            }
                                            &--discounted {
                                                @include fontBold;
                                            }
                                        }
                                    }
                                    &--quantity {
                                        & .dln-orderRev__quantity {
                                            color: $ui-08;
                                        }
                                    }
                                }
                            }
                            & .dln-orderRev__actions {
                                & .dln-btn--stroked {
                                    background: none;
                                    background-color: transparent;
                                }
                                & .dln-orderRev__howItWorks {
                                    @include fontSemibold;
                                    width: auto;
                                    max-width: none;
                                    color: $brand-04;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
            &--accept {
                & .dln-return-order__header {
                    & > .dln-btn--link {
                        display: inline-flex;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-return-order {
            & > .dln-wrapper {
                padding: 19px 0 0 0 !important;
                margin: 0;

                & .dln-return-order__aside.dln-page__aside {
                    width: calc(100% - 40px);
                    margin: 0 auto;
                }
            }
            &__content {
                & .dln-btnTextIcon--braun-filters {
                    margin: 40px auto 20px 20px;

                    & .dln-btnTextIcon__iconWrap {
                        width: 12px;
                        margin: 0 7px 0 0;
                    }
                    & .dln-btnTextIcon__text {
                        max-width: none;
                    }
                }
                & .dln-return-order-box-detail {
                    padding-bottom: 88px;

                    & .dln-return-order-box-detail__header {
                        margin-bottom: 25px;

                        & .dln-return-order-box-detail__title {
                            line-height: 32px;
                            font-size: 24px;
                        }
                        & .dln-btn--link {
                            bottom: 30px;
                        }
                    }
                    & .dln-return-order-box-detail__body {
                        & .dln-return-order-box-detail__col {
                            margin: 0 0 12px 0;

                            &:nth-child(n+2) {
                                margin: 0;
                            }
                        }
                    }
                }
                & .dln-return-order-product-list {
                    & .dln-return-order__header {
                        margin-bottom: 40px;

                        & .dln-return-order__section-title {
                            line-height: 22px;
                            font-size: 18px;
                        }
                    }
                    & .dln-orderHistory--multiple {
                        & .dln-orderHistory__content {
                            & .dln-orderRev--cart-order {
                                padding-left: calc(100px + 20px);
                                margin-bottom: 32px;
                                position: relative;

                                & .dln-image.dln-orderRev__itemPic {
                                    height: 80px;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                }
                                & .dln-orderRev__content {
                                    width: 100%;
                                    padding-top: 2px;

                                    & .dln-orderRev__details {
                                        width: 100%;
                                        margin-bottom: 15px;

                                        & .dln-orderRev__wrap {
                                            &--main {
                                                margin-bottom: 16px;

                                                & .dln-orderRev__detailsTitle {
                                                    margin-bottom: 32px;
                                                }
                                            }
                                        }
                                    }
                                    & .dln-orderRev__actions {
                                        width: 100%;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                & .dln-return-order-receipt {
                    & .dln-return-order__header {
                        margin-bottom: 17px;

                        & .dln-return-order__section-title {
                            line-height: 22px;
                            font-size: 18px;
                        }
                    }
                    & .dln-return-order-receipt__content {
                        & .dln-btn--link {
                            margin-top: 20px;
                        }
                    }
                }
                & .dln-return-order-info {
                    flex-wrap: wrap;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    & .dln-return-order-info__col {
                        flex: initial;
                        width: 100%;
                        border-bottom: 1px solid $ui-01;
                        padding: 10px 0;
                        margin: 0;

                        &:first-child {
                            border: none;
                            padding: 0 0 4px 0;

                            & .dln-return-order__section-title {
                                line-height: 22px;
                                font-size: 18px;
                            }
                        }
                        &:last-child {
                            border-bottom: none;
                            padding-bottom: 0;
                        }
                    }
                }
            }
            /* returned */
            &-returned {
                padding-bottom: 0;

                & .dln-return-order__header {
                    & .dln-return-order__section-title {
                        line-height: 22px;
                        font-size: 18px;
                        margin-bottom: 0;
                    }
                }
                & .dln-orderHistory {
                    margin-bottom: 32px;

                    &__content {
                        & .dln-orderRev--returned {
                            margin-bottom: 0;

                            & .dln-image.dln-orderRev__itemPic {
                                height: 80px;
                            }
                            & .dln-orderRev__content {
                                & .dln-orderRev__details {
                                    & .dln-orderRev__wrap {
                                        &--main {
                                            margin-bottom: 16px;

                                            & .dln-orderRev__detailsTitle {
                                                margin-bottom: 32px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

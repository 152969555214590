@media only screen and (max-width:599px) {
    .dln-wishlist {
        .dln-profileCard {
            display: none;
        }
        &__aside {
            & .dln-profileCard {
                display: none;
            }
            & .dln-vertMenu {
                margin: 0 0 0 0;
            }
        }
        &__content {
            margin-top: 26px;
            padding-bottom: 40px;

            .dln-title {
                font-size: 36px;
                line-height: 44px;
                margin: 0 0 28px 0;
            }
            .dln-wishlist__subtitle {
                font-size: 22px;
                line-height: 28px;
                margin: 0 0 10px 0;
            }
            .dln-wishlist__paragraph {
                font-size: 14px;
                line-height: 20px;

                &:last-of-type {
                    margin: 0;
                }
            }
            .dln-wishlist__text {
                margin: 0 0 20px 0;
            }
        }
        &__foot {
            &--divider {
                margin: 54px 0 32px 0;
                padding: 0;
                border: 0;
            }
            .dln-btn {
                margin: 0 auto;
                width: auto;
            }
        }
        &__productWrap {
            border-bottom: none;
            padding: 0;

            & .dln-wishProd {
                width: calc(50% - 8px);
                padding: 0;
                margin: 0 8px 0 0;
                border: none;

                &:nth-of-type(2n+2) {
                    margin: 0 0 0 0;
                }
                &:not(:nth-of-type(1)):not(:nth-of-type(2)) {
                    margin-top: 50px
                }

                & .dln-btnIcon {
                    &__circle {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }

        &.dln-page--myArea:not(.dln-wishlist--empty) {
            .dln-title {
                // margin-top: 12px
            }

            .dln-wishlist__foot {
                position: absolute;
                left: 0;
                width: 100vw;
                background: white;
                box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0);
                bottom: 0;
                z-index: 100;
                padding: 15px 0;
                margin: 0;

                transform: translateX(-10px) translateZ(0);
                transition: box-shadow .5s ease-in-out;

                &.fixed {
                    position: fixed;
                    transform: translateX(0) translateZ(0);
                    box-shadow: 0 -3px 4px 0 rgba(0, 0, 0, 0.1);

                    transition: box-shadow .5s ease-in-out;
                }
            }
        }
    }
}
.dln-checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    height: auto;
    min-height: 16px;

    &__wrap {
        display: inline-flex;
        align-items: center;
        width: auto;
        max-width: calc(100% - 16px);
        min-height: 16px;
        padding: 0 0 0 16px;
        box-sizing: content-box;
        position: relative;
        z-index: 1;
    }
    &__input {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
    }
    &__inputPlaceholder {
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid $ui-02;
        border-radius: 2px;
        background-color: $brand-03;
        position: absolute;
        top: 2px;
        left: 0;
        z-index: 2;
        box-sizing: border-box;
        transition: .3s;
        flex-shrink: 0;

        &::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 1px;
            background-color: $support-02;
            transform: scale(0, 0);
            transform-origin: center;
            transition: transform .08s ease;
            transition: .3s;
            position: absolute;
            top: calc(50% - 4px);
            left: calc(50% - 4px);
            z-index: 3;
        }
    }
    &__input:checked+.dln-checkbox__inputPlaceholder::after {
        transform: scale(1, 1);
    }
    &__label {
        display: inline-block;
        width: auto;
        max-width: 100%;
        min-height: 15px;
        line-height: 15px;
        font-size: 13px;
        font-weight: 400;
        color: $brand-02;
        transition: .2s linear;
        margin: 0 0 0 8px;
    }
    & .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
        color: $support-02;
        @include fontBold;
    }
    &--support-02 {
        & .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
            @include fontBold;
            color: $support-02;
        }
    }


    &--interactive {
        display: flex;
        flex-direction: column;

        padding: 21px;
        border: 1px solid $ui-02;

        &.dln-checkbox--tooltip {
            .dln-checkbox__label {
                margin-right: 26px;
                line-height: 16px;
            }
        }

        .dln-checkbox__wrap {
            width: 100%;
        }

        .dln-checkbox__input {
            width: calc( 100% - 16px );

            &:checked + .dln-checkbox__inputPlaceholder + .dln-checkbox__label {
                @include fontRoman;
                font-weight: 400;

                color: $brand-02;
            }
        }

        .dln-checkbox__tooltipIconWrap {
            position: absolute;
            right: 0;
            top: 0;

            cursor: pointer;

            .dln-icn {
                color: $ui-06;
            }

            input:hover + .dln-icn {
                color: $brand-01;
            }

            .dln-checkbox__tooltipController {
                position: absolute;
                right: 0;
                opacity: 0;

                cursor: pointer;

                width: 100%;
                height: 100%;
                z-index: 10;

                &:checked + .dln-icn {
                    //color: $support-02;
                }
            }
        }
        .dln-checkbox__confirmWrap,
        .dln-checkbox__tooltipWrap {
            width: 100%;
            height: 0;
            margin-top: 0;
            transition: height 0.22s ease-out,
                        margin-top 0.22s ease-out;
            box-sizing: content-box;
            overflow: hidden;
        }

        .dln-checkbox__tooltipWrap {
            font-size: 11px;
            line-height: 16px;
            @include fontRoman;
            color: $brand-02;

            @media only screen and (max-width:599px) {
                font-size: 13px;
            }
        }

        .dln-checkbox__confirmWrap {
            width: 100%;

            .dln-checkbox__confirmText {
                @include fontBold;
                font-size: 13px;
                line-height: 16px;
            }
            .dln-checkbox__confirmActions {
                display: flex;
                align-items: baseline;
                justify-content: space-between;

                margin-top: 20px;

                .dln-btn {
                    width: auto;
                }

                .dln-btn--confirm {
                    width: 285px;
                    @media only screen and (max-width:599px) {
                        width: 180px;
                    }
                }
            }
        }


        &.tooltipVisible {
            .dln-checkbox__tooltipWrap {
                margin-top: 21px;
                transition: height 0.22s ease-out;
            }
        }

        &.confirmVisible {
            .dln-checkbox__confirmWrap {
                margin-top: 30px;
                transition: height 0.22s ease-out;
            }
        }
    }

    &--hideLabel {
        .dln-checkbox__wrap {
            .dln-checkbox__label {
                position: absolute;
                width: 0;
                height: 0;
                overflow: hidden;
                opacity: 0;
            }
        }
    }

    &--without-border {
        border: none !important;
        padding: 0;

        .dln-checkbox__tooltipIconWrap {
            bottom: 0;
            top: auto;
        }
        .dln-checkbox__tooltipWrap {
            font-size: 9px;
            line-height: 10px;
            letter-spacing: -0.1px;
            margin-top: 9px !important;
            margin-bottom: -20px;
        }

        @media only screen and (max-width:599px) {

            .dln-checkbox__tooltipWrap {
                margin-left: 24px;
                margin-right: 24px;
            }
        }
    }

    &--image {
        width: auto;
        min-width: 180px;
        box-shadow: 0 0 0 1px $ui-02;

        transition: box-shadow .3s linear;

        &:not(.checked) {
            box-shadow: 0 0 0 1px $ui-01;
            transition: box-shadow .3s linear;
        }

        .dln-checkbox__wrap {
            width: 100%;
            padding: 0;
            max-width: none;
            display: flex;
            flex-direction: column;
            padding: 20px;
            min-height: initial;

            .dln-checkbox--image__imagewrapper {
                position: relative;
                height: 0;
                padding-bottom: 45%;
                padding-top: 45%;
                justify-content: center;
                align-content: center;
                align-items: center;
                display: flex;

                .dln-checkbox--image__image {
                    position: absolute;
                    width: 160px;
                    height: 60px;
                }
            }

            .dln-checkbox__inputPlaceholder {
                position: relative;
                flex-shrink: 0;
            }
            .dln-checkbox__label {
                margin: 8px 0;
            }
        }

        @media only screen and (max-width:599px) {
            width: 100%;

            .dln-checkbox__wrap {
                min-height: 83px;
                align-items: center;
                justify-content: center;

                .dln-checkbox--image__imagewrapper {
                    height: auto;
                    padding: 0;
                    margin-bottom: 20px;

                    .dln-checkbox--image__image {
                        position: relative
                    }
                }
            }
        }
    }
    @media (-ms-high-contrast: none) {
        &--image {
            height: 186px;

            & > .dln-checkbox__wrap {
                height: 100%;
                box-sizing: border-box;

                & .dln-checkbox--image__imagewrapper {
                    justify-content: center;
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: auto 0;
                    position: static;

                    & .dln-checkbox--image__image {
                        position: static;
                    }
                }
            }
        }
    }
}

.dln-checkboxes__wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    .dln-checkbox {
        margin: 0 10px;
        min-width: initial;
        max-width: 180px;
        width: calc( 33.3% - 20px );
    }

    @media only screen and (max-width:599px) {
        flex-direction: column;

        .dln-checkbox {
            margin: 0;

            min-width: initial;
            max-width: initial;
            width: 100%;
        }

        .dln-checkbox + .dln-checkbox {
            margin-top: 10px;
        }
    }
}


@import "./_checkboxes-kenwood.scss";
@import "./_checkboxes-delonghi.scss";

.dln-manuals-list {
    display: flex;
    flex-wrap: wrap;    
    width: 100%;
    list-style: none;

    & .dln-icn {
        width: 48px;
        height: 48px;
        line-height: 48px;
        font-size: 48px;
        color: $support-02;
        margin: 0 40px 0 0;
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        &Link {            
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    &__wrapText {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 48px - 40px);        
        border-bottom: 1px solid $ui-01;
        padding: 5px 0 15px 0;
        box-sizing: border-box;
        // margin-left: 34px;        
    }
    &__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 18px;
        font-size: 14px;
    }
    &__details {
        display: block;
        width: 100%;
        line-height: 18px;
        font-size: 12px;
        color: $brand-02;
    }
    &--compact {
        & .dln-manuals-list__item {
            border-bottom: 1px solid $ui-01;
            padding: 0 0 15px 0;
        }
        & .dln-icn {
            margin: 0 15px 0 0;            
        }
        & .dln-manuals-list__wrapText {
            align-items: center;
            width: calc(100% - 48px - 15px);    
            border: none;        
            padding: 5px 0 0 0;
        }
        & .dln-manuals-list__title {
            line-height: 20px;
            font-size: 15px;
            margin: 0 0 2px 0;
        }
        & .dln-manuals-list__details {
            font-size: 13px;            
        }
    }
}

.dlg-adyen__add-card__wrapper {
  width: 100%;
  margin-bottom: 32px;

  .adyen-checkout__label__text {
    font-size: 13px;
  }

  .adyen-checkout__input--wrapper {
    width: 100%!important;

    .adyen-checkout__input {
      width: 100%!important;
      border-radius: 0!important;

      &.adyen-checkout__input--valid {
        border-bottom-color: #b9c4cb!important;
      }
    }

  }

  .adyen-checkout__field__cvc {
    margin-right: 0!important;
  }
}
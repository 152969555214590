.dln-delonghi {
  .dln-return-authorization {
    .dln-wrapper {
      .dln-registration {
        &__content {
          .dln-title {
            + .dln-registration__paragraph {
              font-size: REM(16px);
            }
          }
        }

        &__subtitle {
          margin-bottom: 20px;
        }

        &__section {
          > div {
            margin: 0 0 30px 0;
          }
        }
      }
    }
  }
}

/* @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .dln-delonghi {
        .dln-order {
            &--status {
                .dln-order {
                    &__text {
                        font-size: REM(15px) !important;
                    }
                    &__title {
                        font-size: REM(18px);
                        margin-bottom: 8px;
                    }
                    &__userCard {
                        width: 100%;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
 */

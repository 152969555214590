@media only screen and (max-width:599px) {
	.dln-chooseCountry {
		& > .dln-wrapper {
			margin-top: 40px;
		}

		.dln-title {
			margin: 0;
		}

		#chooseCountry__restOfWorld_wrapper {
			margin-top: 40px;

			.dln-page__sectionHead {
				.dln-page__sectionHeadTitle {
					@include fontBold;
					font-size: 15px;
					line-height: 21px;
				}
			}
			.dln-page__sectionContent {
				margin-top: 26px;

				.dln-btn--internationalWebsite {
					margin-top: 40px;
					width: auto;
				}
			}
		}

	}
}
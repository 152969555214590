.dln-forgPsw {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: auto;
    border-radius: 2px;
    background-color: $brand-03;
    padding: 0 40px 24px 40px;
   
    & div:nth-child(2) form {
        width: calc(100% - 40px);
        margin: 0 20px;
    }

    .dlg-gigya-link {
        font-weight: bold;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        margin: 0;

        & .dln-inputs {
            margin: 0 0 24px 0;
        }
        & .dln-btn[type="button"] {
            margin: 0 0 24px 0;
        }
        & .dln-btn--link {
            margin: 0 auto;
        }
    }
    &__text {
        display: block;
        width: 100%;
        line-height: 20px;
        margin: 0 0 32px;
    }
    &__wrap {
        display: none;
        flex-wrap: wrap;
        align-content: flex-start;
        width: calc(100% - 80px);
        margin: 0 40px;
    }
}

.dln-delonghi {
    & .dln-wishlist.dln-wishlist--empty {
        & .dln-wishlist__content {
            & .dln-wishlist__title {
                margin-bottom: 40px;
            }
            & .dln-wishlist__subtitle {
                margin-bottom: 12px;          
            }
        }
    }
}
.dln-recipe-page {
    .dln-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 1300px;
        width: 100%;
        padding: 30px 30px 0;
        margin: 0 auto;
    }

    &__title {
        @include fontBold;
        font-size: 36px;
        line-height: 1.22;
        margin-top: 24px;
    }

    .dln-recipe-page__big-margin {
        margin-top: 60px;
    }


    &__title-filters {
        @include fontBold;
        font-size: 16px;
        line-height: 1.38;
        margin-top: 10px;
        //margin-bottom: 18px;

        & + .dln-browse-by {
            .dln-browse-by__link {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .dln-filters-bar__desktop .section-label {
        display: none;
    }
}

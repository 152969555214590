.dln-braun {
  .dln-addItem {
    .prices{
      display: flex;
    }
  }
  .dln-cartItem {
    &__picture {
      & .dln-image__image {
        height: 100%;
        object-fit: contain;
      }

      @media only screen and (max-width: 599px) {
        width: 80px;
        height: 63px;
      }
    }

    &__priceBox {
      .dln-price {
        &--discounted {
          color: var(--brand-01);
          @include fontBold;
        }

        &--oldPrice {
          margin: 0 12px 0 0;
          color: $ui-02;
          @include fontBold;

          &::after {
            background-color: $ui-02;
          }
        }
      }
    }
  }
}

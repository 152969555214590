.dln-kenwood {
  .dln-itemRegDetails {
    &__itemImg {
      max-width: 265px;
      height: auto;

      @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) { 
        max-width: 265px;
        height: 232px;
      }

      @media only screen and (min-width:map-get($grid-breakpoints, md)) {
        max-width: 265px;
        height: 232px;
      }
    }
    &__details {
      @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) { 
        width: calc(100% - 290px);
      }
    }

    .dln-inputs__lbl {
      top: calc(50% - 13px);
      left: 8px;
      width: auto;
      padding: 0 9px;
      background-color: var(--brand-03);
    }

    .dln-inputs {
      &--active {
        & .dln-inputs__lbl {
            font-size: 12px;
            transform: translateY(calc(50% - 27px));
            background-color: var(--brand-03);
        }
      }
    }

    .dln-select__lbl {
      margin-top: 35px;
      margin-left: 8px;
    }

    .dln-select--open, 
    .dln-select--has-value {
      & +.dln-select__lbl {
        transform: translateY(calc(50% - 28px));
      }
    }
  }
}

.dln-delonghi {
  & .dln-cartItem {
    position: relative;

    &__chips {
      order: 3;
    }

    .dln-chips.dln-chips--lightGray.dln-chips--light {
      background: transparent;
      padding: 0;

      .dln-chips__content {
        span {
          color: $support-02;
          font-weight: normal;
          font-size: REM(13px);
        }
      }
    }

    .dln-chips.dln-chips--iconinside.dln-chips--warning {
      order: 7;
      margin-top: 41px;
      width: calc(100% + 180px);
      transform: translateX(-180px);
    }

    &__price {
      .prices {
        align-items: baseline;

        .dlg-omnibus-price {
          flex-direction: column;
          align-items: flex-start;

          &__main-price {
            font-size: REM(19px);
          }

          &__old-price {
            font-size: REM(12px);
          }
        }

        .dln-price {
          &--oldPrice {
            &-frequency {
              font-size: 14px;
              white-space: pre;

              &::after {
                top: unset;
                bottom: 51%;
              }
            }
          }
        }

        .realPrice {
          .dln-price__frequency {
            font-size: 14px;
            white-space: pre;
          }
        }
      }

      .dln-price--oldPrice {
        font-size: REM(16px);
        color: $ui-02;

        &::after {
          background-color: $ui-02;
          top: 49%;
        }
      }

      .dln-price--discounted {
        font-size: REM(16px);
        color: $brand-04;
        font-weight: normal;
      }
    }

    &__quantity {
      order: 4;
      float: none;
      .dln-inputIncr {
        > button,
        input {
          font-size: REM(13px);
          color: $brand-04;

          i {
            font-size: REM(13px);
            color: $brand-04;
          }
        }

        .dln-inputIncr__btn.dln-inputIncr__btn--increment {
          margin-bottom: 1px;
        }

        .dln-inputIncr__input {
          height: auto;
          font-size: 12px;
        }
      }

      & > i {
        font-size: 22px;
      }
    }

    &__label {
      font-size: REM(14px);
    }

    &__accessories {
      order: 5;
      margin: 15px 0 13px 0;
      width: 100%;

      .dln-btnTextIcon {
        border: 1px solid $brand-04;
        border-radius: 2px;
        margin-bottom: 0;
      }

      .dln-select {
        .dln-select__head {
          border-radius: 2px;
        }

        .dln-select__value {
          font-size: REM(14px);
        }
      }
    }

    &__titleWrapper {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    &__title {
      @include fontMedium;
      font-weight: 500;
      font-size: REM(19px);
      order: 1;
    }

    &__topCta {
      flex-shrink: 0;
    }

    &__warranty {
      width: 50%;
      padding-left: 24px;
      margin-left: 0;
      margin-top: 15px;
      order: 6;
      .dln-price {
        font-weight: normal;
        font-size: REM(18px);
      }
    }

    &__tooltip {
      display: flex;
      align-items: center;
      .dln-icn.dln-icn--iconfont {
        color: $brand-04;
      }
    }

    &__closeBtn {
      top: 44px;
      right: 38px;

      i {
        font-size: REM(24px);
      }
    }
  }

  .dln-popup {
    .dln-cartItem {
      &__price {
        .prices {
          .dlg-omnibus-price {
            align-items: flex-start;

            &__main-price {
              font-size: REM(17px);
            }
          }
        }
      }
    }
  }

  .dln-cart__bundle-terms-conditions {
    position: relative;
    display: block;
    width: 100%;
    margin: 10px 0;

    &-label {
      display: flex;
      align-items: center;
    }

    input {
      margin-right: 5px;
    }

    &--error {
      color: $support-01;
      margin: 10px 0;
    }
  }

  .dln-cartItem__bundleHeader {
    .priceBox {
      .discountedPrice {
        background-color: var(--brand-08);
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, xs)) and (max-width: 1100px) {
  .dln-delonghi {
    & .dln-cartItem {
      &__content {
        position: relative;
      }

      &__title {
        font-size: REM(16px);
      }

      &__price,
      &__quantity,
      &__chips {
        width: 100%;
      }

      /* &__accessories,
      &__warranty {
        width: 100%;
        transform: translateX(-116px);
        padding-left: 0;
      } */

      &__warranty {
        position: relative;

        .dln-price {
          font-size: REM(16px);
        }
      }

      &__closeBtn {
        top: 27px;
        right: 16px;
      }

      .dln-chips.dln-chips--iconinside.dln-chips--warning {
        order: 7;
        width: calc(100% + 120px);
        transform: translateX(-120px);
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-cartItem {
      &__title {
        transform: translateY(0px);
      }

      .dlg-omnibus-price {
        &__old-price {
          align-items: flex-start;
          gap: 0;
          text-align: start;
        }
      }
    }
  }

  .dln-nutribullet {
    & .dln-cartItem {
      .dlg-omnibus-price {
        &__old-price {
          align-items: flex-start;
          text-align: start;
        }
      }
    }

    .dln-popup {
      & .dln-cartItem {
        .dlg-omnibus-price {
          &__old-price {
            align-items: flex-start;
            text-align: start;
          }
        }
      }
    }
  }
}

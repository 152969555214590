.dln-filterTag {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    width: auto;
    height: 30px;
    border: 1px solid $ui-09;
    border-radius: 5px;
    padding: 0 10px 0 8px;
    box-sizing: border-box;

    &__remove {
        font-size: 10px;
        display: inline-flex;
        align-items: center;
        line-height: 1;
        width: 13px;
        height: 13px;
        border: none;
        background: none;
        background-color: transparent;
        margin: 0 10px 0 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
    }

    + .dln-filterTag {
        margin-left: 10px;
    }
    .dln-icn {
        font-size: 10px;
        line-height: 10px;
        height: auto;
    }
}

.dln-delonghi {
    .js-filter-remove * {
        pointer-events: none;
    }
}

.dln-kenwood {
    .dln-filterTag {
        background: $ui-03;
        border: none;
        border-radius: 0;

        &__remove {
            order: 2;
            margin-right: 0;
            margin-left: 15px;

            i {
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
}

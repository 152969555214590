.del-header__securityCheck {
  align-self: center;
  padding-top: 10px;
  font-size: 19px;
  line-height: 18px;
}
.dln-delonghi {
  & .dln-thankYou--guest {
    .dln-wrapper {
      > .dln-thankYou__content {
        border: none;
        display: flex;
        padding: 0 75px;
        margin: 24px 75px 0;
        justify-content: space-between;
      }
      &--fullWidth {
        margin-top: 60px;
        margin-bottom: 40px;
        border-top: 1px solid $ui-01;
        border-bottom: 1px solid $ui-01;
        background-color: $ui-03;
        justify-content: center;
      }
    }
    & .dln-thankYou__successMess {
      padding: 24px 24px 28px 24px;
      margin: 0;
      border: none;
      background-color: $ui-03;
      width: 55%;
      max-height: 482px;

      .dln-icn {
        margin: 22px 0 0;
        border: 2px solid var(--brand-01);
        padding-top: 22px;
        border-radius: 50%;
        height: 55px;
        justify-content: center;
        width: 77px;
        color: var(--brand-01);
      }

      & .dln-thankYou__title {
        @include fontMedium;
        line-height: 34px;
        font-size: REM(18px);
        margin: 35px 0 24px;
      }
      & .dln-thankYou__paragraph {
        line-height: 19px;
        font-size: REM(13px);
        margin-bottom: 56px;

        & > a {
          @include fontBook;
          text-decoration: none;
          pointer-events: none;
          cursor: default;
          text-decoration: none;
        }
      }
    }

    & .dln-thankYou__orderNr {
      & .dln-thankYou__orderInfo {
        @include fontBook;
        line-height: 29px;
        font-size: REM(21px);
        margin-bottom: 22px;

        & > b {
          @include fontBook;
        }
        & > span {
          line-height: 18px;
          font-size: REM(14px);
          color: $ui-02;
          margin-top: 6px;
        }
        &--user {
          line-height: 19px;
          font-size: REM(13px);
          border-top: none;
          margin: 0 0 42px 0;
          padding: 42px auto 0;

          a {
            @include fontBook;
            color: var(--brand-01);
          }
        }
      }
      & > .dln-btn--user {
        width: 100%;
        max-width: 386px;
        margin: 0 auto;
      }
    }

    & .dln-thankYou__regForm {
      text-align: center;
      border-top: none;
      width: 39%;

      .dln-thankYou__subTitle {
        display: block;
        margin: 40px 0 8px;
      }

      > .dln-btn {
        max-width: 380px;
        margin: 0 auto;
        display: none;
      }

      & .dln-thankYou__subTitle {
        @include fontBook;
        line-height: 29px;
        font-size: REM(21px);
        margin-top: 24px;
        margin-bottom: 6px;
        display: none;

        & + .dln-thankYou__paragraph {
          line-height: 18px;
          font-size: REM(14px);
          display: none;
        }
      }

      .dln-benefitGuest {
        .dln-benefitTitle {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 17px;
          text-align: start;
        }
        .dln-advtContainer {
          display: flex;
          flex-wrap: wrap;
          .dln-advtBox {
            height: auto;
            border: none;
            display: flex;
            width: 50%;
            padding: 0;
            margin-bottom: 17px;

            & .dln-icn {
              font-size: 12px;
              padding: 8px 7px;
              border: 1px solid var(--brand-01);
              border-radius: 50%;
              background-color: var(--brand-01);
              color: white;
            }
            & .dln-advtBox__title {
              @include fontMedium;
              line-height: 22px;
              font-size: REM(15px);
              padding: 0;
              margin: 0 0 0 12px;
              width: auto;
            }
            & .dln-advtBox__desc {
              line-height: 18px;
              font-size: REM(14px);
              color: $ui-02;
              padding: 0;
            }
          }
        }

        .dln-benefitMail {
          text-align: start;
          margin-top: 11px;
          margin-bottom: 17px;
          .dln-mailTitle {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 8px;
          }

          .dln-mailSubtitle {
            font-size: 13px;
            line-height: 19px;
          }
        }
      }

      .dln-benefitForm {
        width: 80%;
        .dln-names {
          display: flex;
          flex-direction: row;
          gap: 23px;
          margin-bottom: 0;

          .dln-inputs {
            width: calc(50% - 10px);
            height: 40px;
          }
        }

        .dln-inputs {
          i {
            cursor: pointer;
            margin-left: -30px;
            position: absolute;
          }
        }

        .dln-preferences {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 16px;
          margin-top: 10px;

          .dln-text {
            text-align: start;
            width: calc(100% - 38px);
            margin-left: 14px;
            color: var(--ui-02);
            font-size: 13px;
            line-height: 19px;
          }
        }

        .dln-benefitCheckBox {
          height: 24px;
          width: 24px;
          align-self: flex-start;
        }

        .dln-submitButton {
          width: 100%;
          background-color: var(--brand-01);
          color: white;
          height: 48px;
          font-size: 18px;
          line-height: 22px;
          border-radius: 5px;
          border: none;
          font-weight: 600;
        }
      }
    }

    & .dln-wrapper {
      &#mainContent {
        border-top: none;
        margin-top: 124px;
        margin-bottom: 40px;

        & .dln-thankYou__innerWrap {
          margin: 0 60px;
          padding: 0 60px;

          & .dln-thankYou__orderDetails {
            & .dln-thankYou__content {
              &--orderSummary {
                margin-bottom: 47px;

                & .dln-thankYou__subTitle {
                  @include fontMedium;
                  line-height: 22px;
                  font-size: REM(18px);
                  padding-top: 33px;
                  margin-bottom: 4px;
                }

                & .dln-thankYou__subTitleInnerWrap {
                  & a {
                    @include fontBook;
                    font-size: REM(14px);
                    color: var(--brand-01);
                  }

                  & i {
                    color: var(--brand-01);
                  }
                }

                & .dln-thankYou__items {
                  position: relative;
                  width: 100%;

                  p {
                    font-size: 13px;
                    line-height: 19px;
                    color: var(--ui-02);
                  }
                }

                & .dln-orderRev--cart-order {
                  border-top-color: $ui-01;
                  padding: 30px 0 43px;
                  align-items: flex-start;

                  & .dln-orderRev__itemPic {
                    margin-right: 20px;
                  }

                  & .dln-orderRev__content {
                    width: calc(100% - 200px);
                    padding: 1px 0 0;
                  }

                  & .dln-orderRev__advice {
                    & .dln-icn {
                      color: $brand-04;
                      margin-left: 4px;
                    }
                  }
                }
              }

              &--delivery {
                width: calc(33.333% - 27px);
                border: none;
                margin: 0 40px 0 0;

                & .dln-thankYou__subTitle {
                  @include fontMedium;
                  line-height: 22px;
                  font-size: REM(18px);
                  margin-bottom: 28px;
                }

                & .dln-addressBox {
                  padding: 11px 58px 26px 24px;

                  &__data {
                    line-height: 18px;
                    font-size: REM(13px);
                  }

                  &:last-child {
                    padding: 20px 51px 17px 20px;
                  }
                }
              }

              &--payment {
                width: calc(33.333% - 27px);
                border: none;
                margin: 0 40px 0 0;

                & .dln-thankYou__subTitle {
                  @include fontMedium;
                  line-height: 22px;
                  font-size: REM(18px);
                  margin-bottom: 28px;
                }

                & .dln-addressBox {
                  padding: 20px 51px 13px 20px;

                  & .dln-addressBox__logoContainer {
                    margin-bottom: 12px;
                  }

                  & .dln-addressBox__data:first-of-type {
                    font-size: REM(13px);
                  }
                }
              }

              &--aside {
                width: calc(33.333% - 27px);
                border: none;

                & .dln-thankYou__subTitle {
                  @include fontMedium;
                  line-height: 22px;
                  font-size: REM(18px);
                  margin-bottom: 28px;
                }

                & .dln-orderSumm {
                  &__contentRow {
                    padding: 10px 0;

                    &--promo {
                      & .dln-orderSumm__rowTitle {
                        margin-right: auto;
                      }
                    }

                    &--taxes {
                      & .dln-tooltip {
                        display: none;
                      }
                    }

                    &--total {
                      & .dln-orderSumm__rowTitle {
                        font-size: REM(18px) !important;
                      }
                    }

                    & .dln-orderSumm__rowTitle {
                      font-size: REM(13px);
                      line-height: 19px;
                    }

                    & .dln-price {
                      font-size: REM(15px);
                      line-height: 22px;

                      &--removeLink {
                        display: none;
                      }

                      &--secondary {
                        font-size: REM(14px);
                      }

                      &--oldPrice {
                        font-size: REM(16px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
  .del-header__securityCheck {
    align-self: center;
    padding-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }

  .dln-delonghi {
    & .dln-thankYou--guest {
      padding-top: 64px;

      & .dln-wrapper {
        & .dln-thankYou__content {
          padding: 0;
          margin: 59px 0 0;
          display: flex;
          flex-wrap: wrap;

          & .dln-thankYou__successMess {
            padding: 20px 24px;
            margin: 0;
            border: none;
            width: 100%;

            & .dln-icn {
              line-height: 32px;
            }

            & .dln-thankYou__title {
              @include fontBook;
              line-height: 19px;
              font-size: REM(15px);
              margin: 37px 0 28px;
            }

            & .dln-thankYou__paragraph {
              line-height: 19px;
              font-size: REM(13px);
            }
          }

          & .dln-thankYou__orderNr {
            & .dln-thankYou__orderInfo {
              width: 100%;
              line-height: 19px;
              font-size: REM(15px);
              margin-bottom: 24px;

              & span {
                font-size: REM(13px);
                line-height: 19px;
              }

              &--user {
                line-height: 19px;
                font-size: REM(13px);
                padding-top: 50px;
                padding-bottom: 0;
                margin-bottom: 35px;

                a {
                  color: var(--brand-01);
                }
              }
            }

            & > .dln-btn {
              margin-bottom: 40px;
            }
          }
        }

        & .dln-thankYou__regForm {
          width: 100%;
          margin-top: 32px;

          .dln-benefitGuest {
            .dln-benefitTitle {
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 17px;
              text-align: start;
            }

            .dln-advtContainer {
              display: flex;
              flex-wrap: wrap;

              .dln-advtBox {
                height: auto;
                border: none;
                display: flex;
                width: 50%;
                padding: 0;
                margin-bottom: 17px;

                & .dln-icn {
                  font-size: 12px;
                  padding: 8px 7px;
                  border: 1px solid var(--brand-01);
                  border-radius: 50%;
                  background-color: var(--brand-01);
                  color: white;
                }

                & .dln-advtBox__title {
                  @include fontMedium;
                  line-height: 22px;
                  font-size: REM(15px);
                  padding: 0;
                  margin: 0 0 0 12px;
                  width: auto;
                }

                & .dln-advtBox__desc {
                  line-height: 18px;
                  font-size: REM(14px);
                  color: $ui-02;
                  padding: 0;
                }
              }
            }

            .dln-benefitMail {
              text-align: start;
              margin-top: 11px;
              margin-bottom: 17px;

              .dln-mailTitle {
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 8px;
              }

              .dln-mailSubtitle {
                font-size: 13px;
                line-height: 19px;
              }
            }
          }

          .dln-benefitForm {
            width: 100%;

            .dln-names {
              display: flex;
              flex-direction: row;
              gap: 23px;
              margin-bottom: 20px;

              .dln-firstName {
                width: calc(50% - 10px);
                height: 40px;
                padding-left: 10px;
              }

              .dln-lastName {
                width: calc(50% - 10px);
                height: 40px;
                padding-left: 10px;
              }
            }

            .dln-passwordLine {
              width: calc(50% - 14px);
              display: flex;
              align-items: baseline;

              .dln-password {
                height: 40px;
                width: 100%;
                margin-bottom: 20px;
                padding-left: 10px;
              }

              i {
                margin-left: -22px;
                right: 12px;
                cursor: pointer;
                font-size: 18px;
              }
            }

            .dln-benefitCheckBox {
              height: 24px;
              width: 24px;
              align-self: flex-start;
            }

            .dln-submitButton {
              width: 100%;
              background-color: var(--brand-01);
              color: white;
              height: 48px;
              font-size: 18px;
              line-height: 22px;
              border-radius: 5px;
              border: none;
              font-weight: 600;
            }
          }
        }
      }

      & .dln-wrapper#mainContent {
        border-bottom: none;
        margin-top: 24px;
        margin-bottom: 24px;

        & .dln-thankYou__innerWrap {
          margin: 0 21px 40px;
          padding: 0 21px;

          & .dln-thankYou__orderDetails {
            & .dln-thankYou__content {
              &--orderSummary {
                margin: 26px 0 33px;

                & .dln-thankYou__subTitle {
                  font-size: REM(18px) !important;
                  line-height: 22px;
                  margin: 0;
                  padding: 0;
                }

                & .dln-thankYou__items {
                  font-size: REM(13px);
                  line-height: 19px;
                  color: var(--ui-02);

                  p {
                    margin: 0;
                  }
                }

                & .dln-orderRev {
                  &.dln-cartItem__quantity {
                    font-size: REM(13px);
                    color: $ui-02;
                    margin-top: 13px;
                  }

                  &--cart-order {
                    padding: 0 0 15px 0 !important;
                    margin-top: 15px;
                    align-items: flex-start;
                    border-top: none;
                    border-bottom: 1px solid var(--ui-01);

                    &.dln-orderRev__itemPic {
                      margin-right: 20px;
                    }

                    & .dln-orderRev__content {
                      width: calc(100% - 200px) !important;
                      padding: 1px 0 0;

                      & .dln-orderRev__details {
                        & .dln-orderRev__wrap--main {
                          flex-direction: column;
                          padding-left: 0px;
                          display: inline-block;

                          & .dln-orderRev__detailsTitle {
                            font-size: REM(15px);
                            order: 1;
                          }

                          & .dln-orderRev__advice {
                            order: 2;
                            margin-bottom: 21px;
                          }

                          & .dln-price {
                            flex: initial;
                            order: 3;
                            width: auto;
                            font-size: REM(13px);
                            margin-top: 13px;

                            &--oldPrice {
                              order: 3;
                              font-size: REM(13px);
                              margin: 0 7px 0 0;
                              color: $ui-02;
                            }

                            &--discounted {
                              order: 4;
                              margin-top: 0px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              &--delivery {
                border-bottom: 1px solid $ui-01;
                margin: 0 0 25px;
                width: 100%;

                & .dln-thankYou__subTitle {
                  line-height: 19px;
                  font-size: REM(15px);
                  margin-bottom: 6px;
                }

                & .dln-addressBox {
                  border: none;
                  padding: 0 !important;
                  margin-bottom: 24px;

                  & .dln-addressBox__data {
                    &:first-child {
                      @include fontBook;
                    }
                  }

                  &:last-child {
                    margin-bottom: 24px;
                    & .dln-addressBox__data b {
                      @include fontBook;
                    }
                  }

                  &__divider {
                    display: none;
                  }
                }
              }

              &--payment {
                margin: 0 0 10px;
                width: 100%;

                & .dln-thankYou__subTitle {
                  font-size: REM(15px);
                  margin-bottom: 12px;
                }

                & .dln-addressBox {
                  border: none;
                  border-bottom: 1px solid var(--ui-01);
                  margin: 0;
                  padding: 0 0 24px 0 !important;

                  & .dln-addressBox__data {
                    line-height: 18px;

                    &:first-of-type {
                      @include fontMedium;
                    }
                  }
                }
              }

              &--aside {
                margin: 0;
                width: 100%;

                & .dln-orderSumm {
                  &__content {
                    &Row {
                      & .dln-price {
                        @include fontBook;
                        font-size: REM(13px);

                        &--oldPrice {
                          font-size: REM(15px);
                        }

                        &--secondary {
                          font-size: REM(14px);
                        }
                      }

                      &--total {
                        & .dln-orderSumm__rowTitle {
                          font-size: REM(15px) !important;
                        }
                      }
                    }
                  }
                }

                & .dln-thankYou__subTitle {
                  @include fontMedium;
                  line-height: 19px;
                  font-size: REM(15px);
                  margin-bottom: 28px;
                  padding-top: 0px;
                }
              }
            }
          }
        }
      }

      & .dln-wrapper {
        & .dln-thankYou__shareContent {
          & .dln-thankYou__paragraph {
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .del-header__securityCheck {
    align-self: center;
    padding-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .dln-delonghi {
    & .dln-thankYou--guest {
      & .dln-wrapper {
        & .dln-thankYou__content {
          margin: 24px 0 0;
          padding: 0;
          & .dln-thankYou__successMess {
            padding: 20px 24px;
            margin: 17px 0 26px;
            border: none;
            width: 100%;

            & .dln-icn {
              width: 62px;
              height: 47px;
              line-height: 32px;
              margin: 10px 0;
            }
            & .dln-thankYou__title {
              @include fontBook;
              line-height: 19px;
              font-size: REM(15px);
              margin: 24px 0 65px;
            }
            & .dln-thankYou__paragraph {
              line-height: 19px;
              font-size: REM(13px);
              margin-bottom: 28px;
            }
          }
          & .dln-thankYou__orderNr {
            & .dln-thankYou__orderInfo {
              width: 100%;
              line-height: 19px;
              font-size: REM(13px);
              margin-bottom: 24px;
              text-align: center;

              & span {
                font-size: REM(13px);
              }
              &--user {
                line-height: 19px;
                font-size: REM(13px);
                padding: 24px 0 0;
                margin-bottom: 0px;

                a {
                  color: var(--brand-01);
                }
              }
            }
            & > .dln-btn {
              margin-bottom: 40px;
            }
          }
        }

        & .dln-thankYou__regForm {
          margin-top: 0;
          width: 100%;

          & .dln-thankYou__subTitle {
            line-height: 24px;
            font-size: REM(18px);
            text-align: left;
            margin-top: 0;
            margin-bottom: 12px;

            & + .dln-thankYou__paragraph {
              text-align: left;
            }
          }
          .dln-benefitGuest {
            .dln-benefitTitle {
              font-size: 15px;
              line-height: 19px;
              margin-bottom: 17px;
              text-align: start;
            }
            .dln-advtContainer {
              display: flex;
              flex-wrap: wrap;
              .dln-advtBox {
                height: auto;
                border: none;
                display: flex;
                width: 50%;
                padding: 0;
                margin-bottom: 17px;

                & .dln-icn {
                  font-size: 12px;
                  width: 24px;
                  height: 24px;
                  line-height: 24px;
                  padding: 0;
                  border: 1px solid var(--brand-01);
                  border-radius: 50%;
                  background-color: var(--brand-01);
                  color: white;
                }
                & .dln-advtBox__title {
                  @include fontMedium;
                  line-height: 19px;
                  font-size: REM(13px);
                  padding: 0;
                  margin: 0 0 0 12px;
                  width: auto;
                }
                & .dln-advtBox__desc {
                  line-height: 18px;
                  font-size: REM(14px);
                  color: $ui-02;
                  padding: 0;
                }
              }
            }

            .dln-benefitMail {
              text-align: start;
              margin-top: 11px;
              margin-bottom: 17px;
              .dln-mailTitle {
                font-size: 15px;
                line-height: 19px;
                margin-bottom: 8px;
              }

              .dln-mailSubtitle {
                font-size: 13px;
                line-height: 19px;
              }
            }
          }

          .dln-benefitForm {
            width: 100%;
            .dln-names {
              display: flex;
              flex-direction: row;
              gap: 23px;
              margin-bottom: 20px;

              .dln-firstName {
                width: 50%;
                height: 40px;
              }

              .dln-lastName {
                width: 50%;
                height: 40px;
              }
            }

            &.dln-passwordLine {
              .dln-password {
                height: 40px;
                width: 100%;
                margin-bottom: 20px;
              }

              i {
                margin-left: -30px;
                cursor: pointer;
              }
            }

            .dln-benefitCheckBox {
              height: 24px;
              width: 24px;
              align-self: flex-start;
            }

            .dln-submitButton {
              width: 100%;
              background-color: var(--brand-01);
              color: white;
              height: 48px;
              font-size: 18px;
              line-height: 22px;
              border-radius: 5px;
              border: none;
              font-weight: 600;
            }
          }
        }
      }
      & .dln-wrapper {
        &#mainContent {
          border-bottom: none;
          margin-top: 24px;
          margin-bottom: 24px;

          & .dln-thankYou__innerWrap {
            margin: 0 10px 40px;
            padding: 0 10px;

            & .dln-thankYou__orderDetails {
              & .dln-thankYou__content {
                &--orderSummary {
                  margin-bottom: 26px !important;

                  & .dln-thankYou__subTitle {
                    font-size: REM(15px) !important;
                    margin-top: 0;
                    padding-top: 26px;
                    margin-bottom: 0;
                  }

                  & .dln-thankYou__items {
                    font-size: REM(13px);
                    line-height: 19px;
                    color: var(--ui-02);

                    p {
                      margin: 6px 0 0;
                    }
                  }

                  & .dln-orderRev {
                    &.dln-cartItem__quantity {
                      font-size: REM(13px);
                      color: $ui-02;
                      margin-top: 13px;
                    }

                    &--cart-order {
                      border-bottom-color: $ui-01;
                      padding: 0 0 18px 0 !important;
                      margin-top: 20px;
                      align-items: flex-start;

                      &.dln-orderRev__itemPic {
                        margin-right: 15px;
                      }

                      & .dln-orderRev__content {
                        width: calc(100% - 100px - 20px) !important;

                        & .dln-orderRev__details {
                          & .dln-orderRev__wrap--main {
                            flex-direction: column;
                            padding-left: 0px;
                            display: inline-block;

                            & .dln-orderRev__detailsTitle {
                              font-size: REM(15px);
                              order: 1;
                            }
                            & .dln-orderRev__advice {
                              order: 2;
                              margin-bottom: 21px;
                            }
                            & .dln-price {
                              flex: initial;
                              order: 3;
                              width: auto;
                              font-size: REM(13px);
                              margin-top: 13px;

                              &--oldPrice {
                                order: 3;
                                font-size: REM(13px);
                                margin: 0 7px 0 0;
                                color: $ui-02;
                              }
                              &--discounted {
                                order: 4;
                                margin-top: 0px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &--delivery {
                  border-bottom: 1px solid $ui-01;
                  margin-bottom: 24px;
                  width: 100%;

                  & .dln-thankYou__subTitle {
                    line-height: 24px;
                    font-size: REM(15px);
                    margin-bottom: 6px;
                  }
                  & .dln-addressBox {
                    border-bottom: none;
                    padding: 0 !important;
                    margin-bottom: 24px;

                    .dln-addressBox__data {
                      &:first-child {
                        @include fontBook;
                      }
                    }
                    &:last-child {
                      margin-bottom: 24px;
                      & .dln-addressBox__data b {
                        @include fontBook;
                      }
                    }
                    &__divider {
                      display: none;
                    }
                  }
                }
                &--payment {
                  width: 100%;
                  .dln-thankYou__subTitle {
                    font-size: REM(15px);
                    margin-bottom: 12px;
                  }
                  .dln-addressBox {
                    // border-bottom-color: $ui-01;
                    border-bottom: none;
                    padding: 0 0 24px 0 !important;

                    .dln-addressBox__data {
                      line-height: 18px;

                      &:first-of-type {
                        @include fontMedium;
                      }
                    }
                  }
                }
                &--aside {
                  width: 100%;
                  .dln-orderSumm {
                    &__content {
                      &Row {
                        /*&--subtotal {
                          padding: ?;
                        }*/
                        & .dln-price {
                          @include fontBook;
                          font-size: REM(13px);

                          &--oldPrice {
                            font-size: REM(15px);
                          }
                          &--secondary {
                            font-size: REM(14px);
                          }
                        }
                        &--total {
                          .dln-orderSumm__rowTitle {
                            font-size: REM(15px) !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

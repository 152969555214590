@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-recipe-details {
        .dln-wrapper {
            padding: 20px 20px 0 !important;
        }
        .dln-image {
            max-height: 330px;
        }
        &__grid {
            flex-direction: column-reverse;
            max-height: none;
            width: calc(100% + 40px);
            margin-left: -20px;
            margin-right: -20px;
            &-inverse {
                .dln-recipe-page__info,
                .dln-recipe-page__bottom-info {
                    padding-right: 0;
                    padding: 0 20px;
                }
            }
        }
        
        &__col {
            flex: 1 1 100%;
            min-width: 100%;
            max-width: 100%;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;
        }
        
        &__bottom-info {
            margin-top: 45px;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            height: auto;
        
            &-col {
                flex: 1 1 50%;
                margin-bottom: 15px;
            }
            &-col + .dln-recipe-details__bottom-info-col  {
                border-left: none;
                margin-left: 0;
                padding-left: 0;
            }
        }
        
        &__title-recipe {
            margin-top: 20px;
            font-size: 24px;
            line-height: 1.13;
        }
        
        &__description {
            margin-top: 15px;
            margin-bottom: 0;
        }

        &__info-button-mobile {
            display: block;
            margin-bottom: 20px;
        }
        &__info-button-desktop {
            display: none;
        }

        &--inverted {
            .dln-wrapper {
                padding: 0 !important;
            }
            .dln-recipe-details__grid {
                flex-direction: column-reverse;
            }
        }

        &--print {
            .dln-recipe-details__info-button-mobile {
                display: none;
            }
            .dln-recipe-details__info-button-desktop {
                display: block;
                padding-top: 30px;
            }    
        }
    }
}

@media only screen and (max-width: 599px) {
  .dln-checkout {
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-top: 65px;

    .dln-orderSumm__contentRow--taxes,
    .dln-orderSumm__contentRow--shipping {
      .dln-tooltip {
        & .dln-tooltip__container {
          width: 100%;
          min-width: 120px;
        }

        & .dln-tooltip__content {
          width: 100%;
          text-align: left;
        }

        & .dln-tooltip__text {
          max-width: none;
        }
      }
    }

    > .dln-wrapper {
      /*  padding: 0; */
      /*             margin: 0; */
      padding-right: 0;
      padding-left: 0;
      padding-top: 0;
      margin-right: 0;
      margin-left: 0;

      > .dln-title {
        padding-right: 10px;
        padding-left: 10px;
        margin-right: 10px;
        margin-left: 10px;

        &.firstStep {
          display: block;
          margin: 0;
          padding: 0px 25px 30px;
          border-bottom: 1px solid $ui-01;
        }
      }
    }
    &__services {
      padding-right: 10px;
      padding-left: 10px;
      margin-right: 10px;
      margin-left: 10px;
      .dln-checkServiceBox {
        width: 100%;
        margin: 0 0 16px 0 !important;
        padding: 32px 24px;
        min-height: auto;
        .dln-checkServiceBox__iconWrap {
          margin: 0 24px 0 0;
        }
        .dln-checkServiceBox__text,
        .dln-btn {
          width: 100%;
          text-align: left;
        }
      }
    }
    &__aside {
      order: -1;
      width: 100%;
      .dln-checkout__noticeBox {
        display: none;
      }

      &--secondary {
        order: 3;
        display: initial;
        .dln-checkout__noticeBox {
          display: flex;
          padding-right: 10px;
          padding-left: 10px;
          margin-right: 10px;
          margin-left: 10px;
          width: auto;
        }
      }

      .dln-orderSumm__price-wrapper {
        > .dln-orderSumm__total-title {
          display: none;
        }
      }
    }
    &__formData {
      max-width: 100%;
    }

    &__dataResult {
      .dln-chips {
        max-width: 100%;
      }
    }

    &__content {
      margin: 0;
      width: 100%;

      .dln-checkout__form .dln-address_info {
        margin: 0 0 15px;
      }

      .dln-accordion {
        border-top: 1px solid $ui-01;
        border-bottom: none;
        &__content {
          padding: 0 0 0;
        }
        &:not(.dln-accordion--open) {
          &:last-of-type {
            border-bottom: 1px solid $ui-01;
          }
        }
        &--open {
          padding: 0 20px 20px;
          border-width: 0;

          .dln-accordion__title.dln-stepTitle {
            margin-bottom: 15px;

            .dln-mandatory {
              margin: 0;
              font-size: 12px;
            }
          }
        }
        &--showInfo {
          padding: 0px 20px;

          .dln-checkout__dataResult {
            padding: 0px;

            .mailInfo {
              margin-top: 0;
              flex-flow: column;
            }
          }
        }
      }

      .dln-checkout__innerCol {
        width: 100%;
        margin: 0;
        padding: 0 0 30px 0;
        border-right: 0;

        &:nth-child(2n + 2) {
          margin: 0;
        }

        & + .dln-checkout__innerCol {
          .dln-checkout__innerColTitle {
            @include cta;
            padding: 20px 0 0;
            border-top: 1px solid $ui-01;
            border-bottom: none;
            margin: 32px 0 16px;
          }
        }
      }

      .dln-checkout__innerColTitle {
        margin: 26px 0 8px;
        border-bottom: none;
        padding: 0 0 0;
      }

      .dln-invoiceForm,
      .dln-guestChoiceForm {
        width: 100%;

        .row {
          flex-flow: column;
          width: 100%;

          &.region {
            width: 100%;
          }
        }
      }
    }

    .dln-newUserForm {
      padding: 0;
    }
    .dln-accordion--access-information {
      .dln-checkout__dataResult {
        align-items: center;
        p {
          margin: 16px 0 0;

          a {
            font-weight: normal;
          }
        }
        > span {
          margin: 16px 0 0;
        }
        > p {
          margin: 0;
        }
        > span {
          margin: 0 0 0 auto;
        }
      }
    }
    .dln-accordion--shipping-information {
      padding: 0 20px 20px;
      &.dln-accordion--open {
        .dln-accordion__content {
          border-width: 0;
        }
      }

      .dln-checkout__paragraph--smaller {
        margin-bottom: 10px;
      }

      .dln-checkout__form {
        > .dln-chips {
          margin: 0 0 32px 0;
        }
      }
      .dln-accordion__content {
        .dln-btn {
          max-width: 100%;
          width: 100%;
        }
      }
      .dln-checkout__dataResult {
        .dln-checkout--mobileView {
          width: 100%;
          max-width: none;
          margin: 32px 0 0;
        }
      }
    }

    .dln-accordion--shipping-method {
      padding: 0 20px 20px;
      &.dln-accordion--open {
        .dln-accordion__content {
          margin-top: 16px;
        }
      }
      .dln-accordion__content {
        .dln-btn {
          max-width: 100%;
          width: 100%;
        }
      }
    }
    .dln-accordion--payment {
      padding: 0 20px 20px;
      border-bottom: 1px solid #eaeaea;

      &.dln-accordion--open {
        border-bottom: none;

        .dln-accordion__content {
          margin-top: 10px;
          padding: 0;

          &--submit {
            padding-bottom: 0;

            .dln-checkout__paragraph {
              padding: 10px 0 0;
            }
          }

          .dln-btn {
            margin-top: 30px;
            width: 100%;
          }
        }
      }

      &.dln-accordion--showInfo {
        .dln-accordion__content {
          &--submit,
          .dln-checkout__form {
            padding-bottom: 0;
          }
        }
      }

      .dln-checkout__formField {
        &--billingAddress {
          flex-flow: column;
          margin-top: 20px;
          padding: 0;

          .dln-checkout__formData {
            margin: 10px 0 0 0;
          }
        }

        .dln-inputs {
          &--securityCode,
          &--exipirationdate {
            max-width: 200px;
            margin: 0 auto 32px 0;
          }
        }

        &--invoice {
          .dln-checkout__formField {
            .dln-inputs {
              margin: 0 0 32px 0;
            }

            .dln-checkout__birthPlace {
              > div:first-child {
                margin-bottom: 32px;
                padding: 0;
              }
              > div:nth-child(2) {
                padding: 0;
              }
            }
          }
        }
      }
      .dln-accordion__content {
        margin: 0 0 0;
        .dln-checkout__paragraph {
          text-align: center;
          padding: 0 16px;
        }
        .dln-btn {
          max-width: 100%;
        }
      }
      .dln-checkout__dataResult {
        .dln-checkout__formField {
          margin: 0 0;
          .dln-inputs {
            margin: 0 auto 0 0;
            &--securityCode,
            &--exipirationdate {
              max-width: 150px;
            }
          }
        }
      }
    }
    &__form {
      div[class*='--oneThird'],
      .dln-inputs--selectInside,
      > .dln-select {
        width: 100%;
        margin: 0 0 32px 0;
      }
      div[class*='--twoThird'] {
        width: 100%;
        margin: 0 0 32px 0;
        & + div[class*='--oneThird'] {
          width: 100%;
        }
      }
      div[class*='--last'] {
        margin: 0 0 21px 0;
      }
    }
    .dln-loginForm {
      padding: 0 0 0 0;
      & + .dln-btn {
        width: 100%;
      }
    }
    &__noticeBox {
      max-width: 100%;
      .dln-noticeBox {
        width: 100%;
        &:nth-child(even) {
          &::after {
            content: '';
            position: absolute;
            width: calc(100% - 48px);
            height: 1px;
            right: 24px;
            top: auto;
            bottom: 0;
          }
        }
      }
    }
  }

  .dln-braun {
    .dln-checkout__content {
      margin: 0;
    }
  }
}

[data-dlg-gigya="dlg-gigya-login-container"] {
  > .gigya-screen-content.gigya-hidden {
    display: block !important;
  }
}

[data-screenset-element-id="gigya-login-screen"],
[data-screenset-element-id="gigya-loginHK-screen"] {

  // some spacer
  .gigya-login-spacer {
    height: 1px;
    border-top: 1px solid var(--ui-01);
    margin: 30px 0;
  }

  .gigya-clear-spacer {
    clear: both;
  }

  // inputs
  .dlg-gigya-input-text-wrapper {
    @include dlg-gigya-input-text-wrapper;
  }

  //forgot password link
  .gigya-forgotPassword {
    @include gigya-dln-btn;
    @include gigya-dln-btn-link;

    color: var(--brand-02);

    float: right;
  }

  /* login cta */
  .dlg-gigya-button-wrapper {
    width: 100%;
    margin: 0;
    text-align: center;

    input[type='submit'] {
      width: 100%;
      margin: 0 auto;
    }

  }
}

[data-screenset-element-id='gigya-subscribe-with-email-form'] {
  .gigya-spacer-divider {
    margin: 0 0 20px;
  }
}

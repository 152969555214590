.dln-instruction-manuals-03 {
    & .dln-instruction-manuals__content {
        padding-top: 22px;
    }
    & .dln-prodManualsCard {
        padding-top: 32px;
    }
    & .dln-quickLinks--slider {
        & > .dln-wrapper {
            padding: 0;
        }
    }
}
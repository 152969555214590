.dln-kenwood {
    & .dln-prodReg--selectProduct {
        & .dln-prodReg__head {
            width: 100%;
            min-width: initial;
            background-color: $brand-03;
            padding-bottom: 40px;
            margin-top: 0;
            padding-top: 60px;
        }
        .dln-timeline {
            margin-top: 0;
        }
        & .dln-prodReg__content {
            margin-top: 0;

            & .dln-prodReg__subTitle {
                @include fontExtra;
                margin-bottom: 56px;
                display: block;
                width: 100%;
                max-width: none;
                line-height: 40px;
                font-size: 32px;
                text-align: center;
            }
            & .dln-prodReg__categoryBreadcrumbs,
            & .dln-prodReg__productHeading {
                display: none;
            }
            & .dln-orderRev--productDescription {
                margin: 0 0 30px 0;
                @media screen and (max-width:599px) {
                    .dln-orderRev__itemPic {
                        max-width: 335px;
                        height: 272px;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-prodReg--selectProduct {
            & .dln-prodReg__head {
                padding: 40px 0 0 0;
                margin: 0;

                & .dln-title {
                    width: 100%;
                    line-height: 40px;
                    padding: 0;
                }
                & .dln-timeline {
                    margin-bottom: 40px;
                    max-width: none;
                    min-height: 74px;

                    &__step {
                        &::after {
                            width: calc(50% - 8px);
                            left: 72px;
                        }
                    }
                    &__label {
                        & span {
                            display: block;
                            width: 100%;
                            line-height: 14px;
                            font-size: 12px;
                            text-align: center;
                            opacity: 1;
                        }
                    }
                }
            }
            & .dln-prodReg__content {
                margin-bottom: 0;

                & .dln-prodReg__subTitle {
                    margin-bottom: 32px;
                    line-height: 32px;
                    font-size: 28px;
                }
            }
            & .dln-prodReg__productList {
                & .dln-orderRev {
                    margin-bottom: 32px;
                    padding: 0;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                    &__itemPic {
                        width: 100%;
                        height: auto;
                        max-height: none;
                        padding: 0;
                        margin: 0 0 16px 0;
                        position: initial;
                        top: initial;
                        left: initial;
                        // order: 2;

                        & img {
                            width: 100%;
                            height: auto;
                            max-height: none;
                        }
                    }
                    &__details {
                        height: auto;

                        &Title {
                            text-align: center;

                            margin: 0 0 8px 0;
                        }
                    }
                    &__desc {
                        line-height: 20px;
                        font-size: 14px;
                        text-align: center;
                        margin: 0 0 16px 0;
                    }
                    & .dln-btn--productDescription {
                        width: 50%;
                        margin: 0 auto 0 auto;
                        position: initial;
                        top: initial;
                        left: initial;
                        background-color: transparent;
                        z-index: 1;
                    }
                }
            }
        }
    }
}
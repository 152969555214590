@media only screen and (max-width:map-get($grid-breakpoints, sm)) {
    body {
        &.ov-hidden {
            overflow: hidden;
        }
    }
    .dln-vertMenu {
        border: 1px solid $ui-02;
        border-radius: 2px;

        &:not(.dln-vertMenu--open) {
            & .dln-vertMenu__li {
                display: none;
                padding: 0 16px;

                &--active {
                    display: block;
                }
                &::before {
                    display: none;
                }
                &:not(.dln-vertMenu__li--heading) {
                    border-left: none;
                }
                a {
                    pointer-events: none;
                }
            }
            & .dln-icn {
                transition: none;
            }

        }
        &__container {
            max-height: 50px;
            padding: 0;
            box-sizing: border-box;
            overflow: hidden;
            position: relative;

            & .dln-icn {
                display: inline-block;
                width: 32px;
                position: absolute;
                top: calc(50% - 6.5px);
                right: 0;
            }
        }
        &__li {
            &:not(.dln-vertMenu__li--heading) {
                a {
                    display: inline-block;
                    padding: 16px 0;
                }
            }
            &--heading {
                display: none;
                padding: 16px 0;
            }
            &--active {
                border-left: none !important;

            }
        }
        &--open {
            border-color: transparent;

            & .dln-vertMenu__container {
                height: 100vh;
                max-height: none;
                background-color: $brand-03;
                opacity: 0;
                animation: smoothFadeIn .3s linear forwards;
                padding: 80px 32px 40px 32px;
                overflow: auto;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 151;
            }
            & .dln-icn {
                opacity: 0;
                animation: smoothFadeInAndScale .5s linear forwards .4s;
                top: 70px;
                right: 24px;
            }
            & .dln-vertMenu__li--heading {
                display: block;
            }
            & .dln-vertMenu__li {
                a {
                    padding: 16px 0 16px 16px;
                }
                &--active {
                    a {
                        padding: 16px 0 16px 20px;
                    }
                }
            }
        }
        &:not(.dln-vertMenu--open) {
            & .dln-vertMenu__list {
                &:not(.-activeList) {
                    margin-bottom: 0;
                }
                &.-activeList {
                    margin-bottom: 40px;
                }
            }
        }
    }
}

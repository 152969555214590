@media only screen and (max-width:599px) {
    .dln-prodReg__userDetails {
        margin: 0 auto 60px;
        max-width: fit-content;
        width: 85%;
    }
    .dln-prodReg_form_logged-row {
        overflow-wrap: break-word;
        max-width: 100%;
        .dln-prodReg__label_userInfo {
            margin-right: 50px !important;
        }
    }
    .dln-kenwood {
        .dln-prodReg__userDetails {
            margin: 0 auto;
            width: 80%;
        }
    }
    .separator {
        margin: 40px -8%;
    }
}
.dln-delonghi {
  .simple-list {
    &__title {
      font-size: REM(14px);
      line-height: 1.29;
      color: $brand-02;
    }
    &__item {
      font-size: REM(14px);
      line-height: 1.43;
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-marketingPreferences {
        .dln-profileCard,
        .dln-breadcrumbs {
            display: none;
        }
        & .dln-page__aside {
            & .dln-vertMenu {
                margin: 0 0 26px 0;
            }
        }
        & .dln-title {
        	font-size: 35px;
			line-height: 43px;
            margin: 0 0 28px 0;
        }
    }
}
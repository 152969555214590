.dln-kenwood {
    .dln-prodReg--confirmation {
        padding-left: 20px;
        padding-right: 20px;
        .dln-confirmation-page__title-container,
        .dln-wrapper {
            padding: 0 !important;
            margin: 0 auto;
        }

        .dln-title,
        .dln-chips__strong {
            text-align: center;
        }

        .dln-chips__content {
            width: 100%;
            margin-top: 5px;

            .dln-chips__strong {
                margin-bottom: 0;
                color: $brand-04;
                font-size: 14px;
                @include fontLight;
            }
        }

        .dln-title {
            @include fontExtra;
            font-size: 38px;
        }

        .dln-title,
        .dln-wrapper,
        .dln-chips.dln-chips--extraLight {
            background: $brand-03;
        }

        .dln-icn.dln-icn--mainIcon,
        .dln-confirmation-page__button-container {
            margin: 0 auto;
            text-align: center;
        }

        .dln-icn.dln-icn--mainIcon {
            width: 80px;
            height: 80px;
            font-size: 80px;
            margin-top: 45px;
            color: $support-02 !important;
        }

        @media only screen and (max-width: 599px) {
            padding-top: 37px;
            padding-left: 14px;
            padding-right: 14px;

            .dln-title {
                @include fontExtra;
                font-size: 32px;
            }
            .dln-confirmation-page__wrapper-content {
                margin-top: 20px;
            }

            .dln-chips.dln-chips--largeIcon.dln-chips--iconinside.dln-chips--extraLight {
                margin-bottom: 30px;
            }

            .dln-chips__content {
                margin-top: 10px;
            }
        }
    }
}

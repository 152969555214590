.dln-kenwood {
    & .dln-quickLink {
        height: auto;
        min-height: 140px;
        text-align: center;
        border: 0;
        cursor: pointer;

        &__title {
            @include fontLight;
        }
        &__link {
            @include fontMedium;
        }
        &__iconWrap {
            width: 50px;
            height: 50px;
            border: 0;
            margin: 0 auto 20px auto;

            & .dln-icn {
                color: $brand-01;
            }
        }
        &__title,
        &__link {
            font-size: 14px;
            color: $brand-04;
        }
        &:hover {
            & .dln-quickLink__title {
                color: $brand-01;
            }
            & .dln-quickLink__link {
                color: $brand-01;
            }
        }
        &--store {
            & .dln-quickLink__iconWrap {
                & .dln-icn {
                    width: 48px;
                    height: 48px;
                    font-size: 0;

                    &::before {
                        @include fontIcon;
                        content: '-';
                        width: 48px;
                        height: 48px;
                        font-size: 48px;
                    }
                }
            }
        }
    }
}
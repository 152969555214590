.dln-delonghi {
  .dln-wishProd {
    @media only screen and (max-width: 599px) {
      &:not(.dln-wishProd--profile) {
        padding: 0;
        padding-bottom: 30px;
        .dln-wishProd__data {
          min-height: auto;
        }
        .dln-wishProd__imgLink {
          height: 110px;
          margin: 0 0 20px 0;
        }
        .dln-wishProd__imgWrap {
          height: 110px;
        }
        .dln-wishProd__img {
          margin: 0 auto 0 auto;
        }
        .dln-wishProd__priceWrap {
          margin: 0;
        }
        .dln-btnTextIcon,
        .dln-btn {
          margin: 20px auto 0 auto;
        }
        .dln-btn--link {
          margin: 0;
        }
      }
      .dln-btnTextIcon,
      .dln-btn {
        margin: 13px auto 0 auto;
      }
      &__data,
      &__priceWrap {
        display: block;
        text-align: center;
        margin-top: 8px;
      }

      &__priceWrap {
        margin-bottom: 0;
      }
    }
    height: auto;
    border: none;

    &:hover {
      border: none;
    }
    &__imgWrap {
      background-color: transparent;
    }
    .dln-btnTextIcon {
      width: 124px;
      border-radius: 3px;
      background: $brand-01;
      transition: opacity 0.3s ease 0s;
      padding: 0;
      box-sizing: border-box;

      .dln-btnTextIcon__iconWrap {
        display: none;
      }
      .dln-btnTextIcon__text {
        @include fontDemi;
        width: 100%;
        max-width: initial;
        height: auto;
        line-height: 24px;
        font-size: REM(18px);
        color: $brand-03;
        margin: 0;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    & .dln-btn--stroked {
      width: 124px;
      min-width: initial;
      padding: 0;
      box-sizing: border-box;
    }
    & .dln-btn--link {
      margin: 0 24px 0 0;
    }
    &__name {
      @include fontMedium;
      line-height: 19px;
      font-size: REM(19px);
      padding: 0;
      margin: 0;
      margin-bottom: 16px;
    }
    &__price {
      @include fontBook;
      line-height: 18px;
      font-size: REM(19px);
      color: $brand-04;

      &Wrap {
        margin-bottom: 28px;
      }
      &--discounted {
        @include fontBook;
        line-height: 18px;
        font-size: REM(16px);
        color: $ui-02;

        text-decoration: line-through;
        margin: 0;
        margin-right: 14px;
        &::after {
          content: none;
        }
      }
      &Discounted {
        @include fontBook;
        line-height: 18px;
        font-size: REM(19px);
        color: $brand-04;
      }
    }

    &__outOfStock {
      width: auto;
      height: 20px;
      line-height: 20px;
      font-size: REM(12px);
      color: $brand-03;
      text-transform: uppercase;
      border-radius: 2px;
      background: $ui-02;
      padding: 0 10px;
    }
    &:hover {
      border-color: $brand-04;
    }
    &--outOfStock {
      & .dln-wishProd__img {
        opacity: 1;
      }
      & .dln-wishProd__name {
        color: $brand-04;
      }
    }
    & .dln-wishProd__name {
      @include fontMedium;

      @media only screen and (max-width: 599px) {
        line-height: 16px;
        font-size: REM(16px);
      }
      line-height: 19px;

      font-size: REM(19px);
      border-bottom: none;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-wishProd {
      padding-top: 10px !important;

      & .dln-btn.dln-btn--link {
        display: flex;
        justify-content: center;
        width: 24px;
        height: 24px;
        right: 0;
        top: 0;
        z-index: 10;

        &::before {
          content: 'g';
          width: auto !important;
          height: auto !important;
          font-size: REM(24px);
          color: $brand-04;
        }
      }
      & .dln-image > img {
        height: 100%;
        object-fit: contain;
      }
      & .dln-wishProd__name {
        margin-bottom: 24px;
      }
      & .dln-wishProd__outOfStock {
        width: 100px;
        margin: 0 auto;
        box-sizing: border-box;
      }
      & .dln-btnTextIcon {
        margin-top: 11px !important;
      }
    }
  }
}

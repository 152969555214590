.dln-faqAnnouncement {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & .dln-popup__head {
        width: calc(100% - 64px);
        padding: 32px 0 32px 0;
        margin: 0 32px 0 32px;

        &Title {
            line-height: 36px;
            font-size: 32px;
            text-align: left;
            padding: 0 72px 0 72px;

            & span {
                @include fontBold;
            }
        }
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 104px 0 104px;
        box-sizing: border-box;
    }
    &__text {
        display: block;
        width: 100%;
        line-height: 21px;
        font-size: 14px;
        margin: 0 0 24px 0;

        &:last-of-type {
            margin: 0 0 40px 0;
        }
        &Link {
            @include fontBold;
            display: inline-block;
            width: auto;
            line-height: 21px;
            font-size: 14px;
            color: $brand-04;
            text-decoration: underline;
        }
    }
    &__imgWrap {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        margin: 0 0 40px 0;

        & > img {
            display: block;
            width: 100%;
            max-width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    &__actions {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid $ui-01;
        padding: 32px 0 0 0;
        margin: 0 104px 96px 104px;
    }
}
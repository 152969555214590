.dln-kenwood {
    .dln-loginForm {
        &__paragraph {
            font-size: 14px;
            line-height: 20px;
        }
        &--simplified {
            .dln-loginForm__paragraph--divider {
                margin-bottom: 36px;
            }
        }
        &__formContainer {
            .dln-inputs {
                margin-bottom: 36px;
                &+.dln-inputs {
                    margin-bottom: 10px;
                }
            }
        }
        &__section {
            &--registration {
                margin-top: 40px;
            }
        }
    }
}
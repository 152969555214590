.dln-kenwood {
    .dln-inputs {
        border-radius: 0;
        border: 0;
        align-self: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px;

        &__input {
            border: 1px solid $ui-01;
            height: 43px;
            padding: 2px 8px;
            background-color: $brand-03;
        }
        &__lbl {
            line-height: 14px;
            font-size: 12px;
            color: $ui-07;
            background-color: transparent;
            transform: none;
            padding-left: 0;
            top: -20px;
            left: 0;
        }
        &__extraLbl {
            line-height: 12px;
            font-size: 11px;
            transform: none;
            // top: auto;
            // bottom: -14px;
            margin-top: 3px;
            padding: 3px 0 0 0;
            position: relative;
            top: initial;
            bottom: initial;
            left: initial;
        }

        &--selectInside {
            .dln-inputs__input {
                border-left: 0;
            }
            .dln-select {
                height: 43px;
                border: 1px solid $ui-01;
                border-right: 0;
                margin: 0;
            }
        }

        &--active {
            .dln-inputs__input {
                border: 1px solid $brand-04;
            }

            .dln-select {
                border-color: $brand-04;
                &__body {
                    top: calc(100% + 5px);
                    left: -1px;
                }
            }
        }
        &--copyClipboard {
            .dln-inputs__input {
                border-color: $brand-04;
                opacity: 1;
                color: $brand-04;
                &::placeholder {
                    color: $brand-04;
                }
            }
        }
        &--disabled {
            .dln-inputs__lbl {
                // display: inline-flex;
                // order: -1;
                // align-items: center;
                // width: auto;
                // max-width: 50%;
                position: absolute;
                // top: auto;
                // left: auto;
            }
            .dln-inputs__input {
                display: flex;
                width: 100%;
                max-width: initial;
                //max-width: 50%;
                // height: auto;
                // padding: 0;
                // margin: 0;

                &[value] {
                    @include fontBold;
                    color: $brand-04;
                }
            }
        }


        .dln-select,
        .dln-select--has-value {
            .dln-select__head {
                border-color: $ui-01;
                // padding: 4px 8px;
                // margin: 4px 0px;
            }
            .dln-select__arrow {
                &::before {
                    font-size: 8px;
                }
            }
            &__container {
                height: 28px;
                margin-top: 6px;
            }
            &__body {
                top: calc(100% + 8px);
                left: -1px;
            }
        }


        &--icon {
            &>.dln-icn {
                /* font-size: 10px; */
                right: 10px;
                top: 14px;
            }
        }
        &--searchBar {
            .dln-inputs__input {
                padding-left: 43px;
            }
            .dln-inputs__lbl {
                left: 0;
            }

            .dln-icn {
                font-size: 25px;
                color: $brand-01;
            }

            .dln-inputs__autocompleteContainer {
                top: 43px;
            }
        }

        &--invalid,
        &--successful {
            .dln-inputs__extraLbl {
                position: static;

                &--error,
                &--success {
                    bottom: -16px;
                }
            }
        }

        &--invalid.dln-inputs--active {
            .dln-inputs__input {
                border: 1px solid $support-01;
            }
            .dln-select {
                border-color: $support-01;
            }
        }

        &--successful.dln-inputs--active {
            .dln-inputs__input {
                border: 1px solid $support-02;
            }
            .dln-select {
                border-color: $support-02;
            }
        }

        .dln-inputs__autocompleteContainer {
            width: calc(100% + 2px);
            display: none;
            visibility: hidden;
            position: absolute;
            overflow: hidden;
            overflow-y: auto;
            max-height: 224px;
            border: 1px solid $ui-02;
            border-width: 0 1px 1px;
            box-shadow: 0 2px 6px rgba(255, 255, 255, 0.6);
            box-sizing: border-box;
            background-color: $brand-03;
            z-index: 9;
            margin-left: -1px;

            .dln-inputs__autocompleteItem {
                padding: 6px 8px;
                cursor: pointer;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                line-height: 30px;
                text-align: left;
                background: none;
                border: none;
                border-top: 1px solid $ui-01;
                font-size: 13px;
                color: $brand-02;
                width: 100%;

                &:hover {
                    background-color: #fafafa;
                }

                &:empty {
                    display: none;
                }
            }
        }
        &--autocomplete {
            &.dln-inputs--writing {
                & .dln-inputs__autocompleteContainer {
                    width: 100%;
                    margin: 0;
                    display: block;
                    visibility: initial;
                }
            }
        }
    }

    .flatpickr-day {
        border-radius: 0 !important;
        &.today {
            border-color: var(--brand-01) !important;
            color: var(--brand-01);
        }
        &.selected {
            background-color: var(--brand-01) !important;
            border-color: var(--brand-01) !important;
        }
    }

    .pac-container {
        transform: translateX(1px);
    }
}
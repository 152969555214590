@media only screen and (max-width:599px) {
    .dln-savedCart {
        .dln-profileCard {
            display: none;
        }
        &__aside {
            & .dln-profileCard {
                display: none;
            }
            & .dln-vertMenu {
                margin: 0 0 24px 0;
            }
        }
        &__content {

            & .dln-title {
                line-height: 34px;
                font-size: 35px;
                padding: 6px 0 0 0;
                margin: 0 0 24px 0;
            }
            & .dln-addressBox,
            & .dln-addBox {
                width: 100%;
            }
        }
        &__section {
            &Title {
                line-height: 20px;
                font-size: 20px;
                margin: 0 0 24px 0;
            }
        }
        &__cartTitle {
            font-size: 15px;
            line-height: 16px;
        }
        &__cartFoot {
            margin: 24px 0 0;
            & .dln-btn {
                margin: 0 auto;
                &:not(.dln-btn--link) {
                    margin: 0 auto;
                }
                &.dln-btn--link {
                    width: 100%;
                    display: block;
                    order: 2;
                    margin: 24px auto 0;
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-savedRec {
        .dln-profileCard {
            display: none;
        }
        &__aside {
            & .dln-profileCard {
                display: none;
            }
            & .dln-vertMenu {
                margin: 0 0 0 0;
            }
        }
        &__content {

            & .dln-title {
                line-height: 34px;
                font-size: 35px;
                padding: 6px 0 0 0;
                margin: 0 0 24px 0;
            }
            .dln-btn {
                margin: 32px auto 0;
            }
        }
        &__recipeWrap {
            border-bottom: none;
            padding: 0;

            // & .dln-recipe {
            //     width: calc(50% - 5px);
            //     padding: 0;
            //     margin: 0 10px 30px 0;
            //     border: none;

            //     &:nth-of-type(2n+2) {
            //         margin: 0 0 30px 0;
            //     }
            //     &:nth-last-of-type(1),
            //     &:nth-last-of-type(2) {
            //         margin-bottom: 0;
            //     }
            //     &__img {
            //         padding: 6px;
            //     }
            //     & .dln-btnIcon {
            //         &__circle {
            //             width: 32px;
            //             height: 32px;
            //         }
            //     }
            // }
        }
    }
}
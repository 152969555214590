$prefix: dln;

.dln-delonghi {
  .dln-popup {
    padding: 20px;

    &__content,
    &__wrapper {
      border-radius: 2px;
    }
    &__content {
      max-width: 427px;
      .dln-inputs:not(.dln-textarea) {
        max-width: 70%;

        @media only screen and (max-width: 599px) {
          max-width: 100%;
        }
      }
    }

    &__head {
      padding-top: 26px;

      &Title {
        @include fontMedium;
        font-size: REM(28px);
        line-height: 34px;

        @media only screen and (max-width: 599px) {
          font-size: REM(21px);
          line-height: 23px;
        }
      }

      &--bigTitle {
        @media only screen and (max-width: 599px) {
          padding-top: 0;
        }

        .dln-popup__headTitle {
          @media only screen and (max-width: 599px) {
            @include fontMedium;
            font-size: REM(37px);
            line-height: 40px;
          }
        }
      }

      .dln-btnIcon {
        .dln-icn {
          color: $brand-04;
        }

        &--close {
          @media only screen and (max-width: 599px) {
            transform: none !important;
          }
        }
      }
    }
  }
}

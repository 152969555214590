.dln-kenwood {
  .dln-popup {
    padding: 80px 101px 49px 102px;

    &__head {
      width: calc(100% + 103px);
      padding: 0;
      margin: 0 -51px 49px -52px;
    }

    &__content {
      border-radius: 0;
    }

    &__wrapper {
      > div:first-child {
        padding: 80px 100px;

        .dln-popup__head {
          width: calc(100% + 100px);
          margin: 0 -50px;
          padding-bottom: 30px;

          &--closeOnly {
            padding-bottom: 0;
            .dln-btnIcon--close {
              transform: none;
            }
          }
        }

        .dln-popup__head
          + *:not([class*='__form']):not([class*='__paragraph']):not([class*='__text']):not([class*='__content']) {
          margin-top: 30px;
        }

        .dln-completeForm__text {
          margin: 0 0 32px 0;
        }

        .dln-popup__headLine {
          display: none;
        }

        .dln-verification__text {
          margin-top: 0;
        }

        .dln-popup__headTitle {
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          width: 100%;
          max-width: 100%;
          margin: 0;
          padding: 0;
        }

        .dln-btnIcon {
          position: absolute;
          top: 20px;
          right: 20px;

          .dln-icn {
            font-size: 22px;
            width: 22px;
            height: 22px;
            text-indent: 0;
            @include definePseudoElems;

            &::before {
              @include fontIcon;
              content: 'J';
              text-indent: 0;
              color: $brand-01;
            }
          }
        }

        @media only screen and (max-width: 599px) {
          padding: 20px 20px 50px;
          .dln-popup__headTitle {
            font-size: 28px;
            line-height: 34px;
            margin-top: 0;
          }
          .dln-popup__head {
            padding: 0;
            margin: 0;
            margin-top: 20px;
            margin-bottom: 25px;
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  .dln-kenwood {
    & .dln-popup {
      & .dln-popup__wrapper {
        & > div:first-child {
          padding: 80px 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-kenwood {
    .dln-popup {
      &__wrapper {
        > div:first-child {
          .dln-popup__headLine {
            display: block;
            background: var(--ui-01);
            margin-top: 15px;
          }

          .dln-btnIcon {
            .dln-icn {
              font-size: 15px;
              &::before {
                color: $brand-04;
              }
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
    & .dln-prodReg {
        &--search {
            & .dln-tabs__currentLine {
                background: $brand-04;
            }
            & .dln-prodReg__content {
                & .dln-prodReg__subTitle {
                    margin: 69px 0 27px 0;
                }
                & .dln-tabs {
                    &__content {
                        padding-top: 40px;
                        border-bottom: none;

                        & .dln-prodReg__searchModel {
                            & .dln-tooltip {
                                margin: 0;
                            }
                        }
                        &#dln-prodReg--category {
                            & .dln-category-browser__wrapper {
                                & .dln-wrapper {
                                    & .dln-category-browser__header {
                                        display: none;
                                    }

                                    & .dln-category-browser__breadcrumbs {
                                        margin-bottom: 6px;
                                        div {
                                            display: inline-block;
    
                                        }
                                    }

                                    & .dln-breadCrumbSel {  
                                        display: inline-block;

                                        &__text {
                                            display: inline-block;
                                        }
                                    
                                        &__text--selected {
                                            display: inline-block;
                                            color: var(--brand-02);
                                
                                            & + .dln-breadCrumbSel__arrow {
                                                transform: rotate(360deg);
                                                display: inline-block;
                                            }
                                        }
                                
                                        &__arrow {
                                            transform: rotate(360deg);
                                            display: inline-block;
                                            top: -1px;
                                            margin-left: 3px;
                                            font-size: REM(8px);

                                            &--search {
                                                top: -1px;
                                            }
                                        }
                                    }

                                    & .dln-category-browser__category {
                                        @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
                                            .dln-categoryBox {
                                                width: auto;
                                                margin-left: 9px;
                                                margin-right: 9px;

                                                &__imgWrap {
                                                    width: 134px;
                                                    height: 117px;

                                                    img {
                                                        width: 100%;
                                                        height: 117px;
                                                        object-fit: unset;
                                                    }
                                                }
                                            }
                                        }

                                        @media only screen and (min-width:map-get($grid-breakpoints, md)) {
                                            .dln-categoryBox {
                                                width: auto;
                                                margin-left: 9px;
                                                margin-right: 9px;

                                                &__imgWrap {
                                                    width: 166px;
                                                    height: 145px;

                                                    img {
                                                        width:100%;
                                                        height:145px;
                                                        object-fit: unset;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-delonghi {
        & .dln-prodReg {
            & .dln-btn--productDescription {
                background: var(--brand-01) !important;
            }

            &--search {
                & .dln-prodReg__head {
                    & .dln-prodReg__paragraph {
                        margin-bottom: 39px;
                    }
                    & .dln-timeline {
                        margin-top: 0;
                    }
                }
                & .dln-prodReg__content {
                    margin-top: 0;

                    & .dln-prodReg__subTitle {
                        margin-top: 0;
                    }
                    & .dln-tabs__content {
                        &#dln-prodReg--model {
                            & .dln-prodReg__tabContent {
                                & .dln-prodReg__searchModel {
                                    & .dln-inputs {
                                        order: 1;
                                        margin: 0;
                                    }
                                    & .dln-btn {
                                        order: 4;
                                    }
                                    & .clearer {
                                        order: 3;
                                    }
                                    & .dln-tooltip {
                                        order: 2;
                                        margin-bottom: 30px;
                                    }
                                }
                            }
                        }
                        &#dln-prodReg--category {
                            & .dln-category-browser__wrapper {
                                & > .dln-wrapper {
                                    margin: 0;
                                    width: 100%;
                                    & .dln-category-browser__category {
                                        & .dln-categoryBox {
                                            width: calc(50% - 5px);
                                            max-width: 266px;
                                            padding: 0;
                                            margin: 0 10px 36px 0;
        
                                            &:nth-child(2n+2) {
                                                margin: 0 0 36px 0;
                                            }

                                            &__imgWrap {
                                                width: 100%;
                                                max-width: 266px;
                                                height: auto;
                                                object-fit: unset;

                                                img {
                                                    width: 100%;
                                                    max-width: 266px;
                                                    height: auto;
                                                    object-fit: unset;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            & .dln-category-browser__breadcrumbs {
                                margin-bottom: 20px !important;

                                & .dln-breadCrumbSel {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-registration {
        .dln-profileCard {
            display: none;
        }
        & .dln-wrapper {
            padding-top: 48px;
            padding-bottom: 70px;
        }
        &__btnGroup {
            width: 100%;
        }
        &__section {
            width: 100%;
            margin: 0 0 0 0;
            display: none;
            &--main {
                width: 100%;
                margin: 24px 0 0;
                display: block;
            }
        }
        &__content {
            .dln-title {
                display: none;
            }
        }
        &__formContainer {
            .dln-registration__paragraph {
                text-align: center;
                &--secondary {
                    text-align: left;
                }
            }
            .dln-select {
                width: 100%;
                margin: 0 0 24px 0;
            }
            .dln-checkbox {
                margin: 0 0 32px;
            }
            .dln-inputs {
                width: 100%;
                margin: 0 0 24px 0;
                &:nth-child(even) {
                    margin: 0 0 24px 0;
                }
                &--extralabel {
                    margin: 0 0 40px !important;
                }
            }
        }
        &__formOptions {
            .dln-registration__subtitle,
            .dln-registration__paragraph {
                text-align: left;
            }
        }
        &__formFoot {
            margin: 24px 0 0;
            .dln-registration__paragraph {
                text-align: center;
                font-size: 13px;
                margin: 32px 0;
            }
            .dln-btn {
                width: 100%;
            }
        }
    }
}
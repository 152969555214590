.dln-ordReceiptCard {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 26px 0 39px 0;
    border-bottom: 1px solid $ui-01;

    &__title {
        display: block;
        width: 100%;
        font-size: 22px;
        line-height: 26px;
        @include fontBold;
        margin-bottom: 16px;
    }
    &__text {
        display: block;
        width: calc(100% - 25px - 96px);
        font-size: 14px;
        line-height: 20px;
    }
    & > .dun-btn {
        width: 96px;
        line-height: 20px;
    }
}

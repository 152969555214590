.dln-delonghi {
  .dln-order--detail {
    .dln-order__detailProducts {
      &--cancelled {
        .dln-order__detailTitle {
          border-top: none;
          margin-bottom: 18px;
          font-size: REM(24px);
        }
        .dln-order__prodCancelledBox {
          padding: 0;
          margin-bottom: 24px;
          &Text {
            font-size: REM(14px);
          }
        }

        .dln-orderHistory {
          opacity: 0.7;
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-order--detail {
      .dln-order__detailProducts {
        &--cancelled {
          .dln-order__detailTitle {
            font-size: REM(18px);
          }

          .dln-order__prodCancelledBoxTitle {
            @include fontMedium;
          }
        }
      }
    }
  }
}

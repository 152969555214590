.dln-popup {
  max-height: unset!important;
  min-height: unset!important;
}

@media only screen and (max-width:599px) {
  .dln-writeReview {
    .dln-popup__head {
      .dln-btnIcon--close {
        transform: translateX(0);
      }
    }
  }
}
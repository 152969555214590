.dln-kenwood {
    & .dln-popup {
        &__content {
            & .dln-popup__wrapper {
                & .dln-ordReturnHowItWorks {
                    & .dln-popup__head {
                        & .dln-btnIcon--close {
                            top: 14px;
                            right: 14px;
                        }
                    }
                    &__content {
                        padding: 0;

                        & .dln-btn--trackingCode {
                            @include fontBold;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-popup {
            &__content {
                & .dln-popup__wrapper {
                    & .dln-ordReturnHowItWorks {
                        & .dln-popup__head {
                            padding-top: 70px;
                            margin: 0 0 30px 0;

                            & .dln-btnIcon--close {
                                top: 20px;
                                right: 20px;
                            }
                        }
                        &__content {
                            & .dln-btn--trackingCode {
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dln-kenwood {
	.dln-wishlist--shared.dln-page--myArea {
		.dln-wrapper {

			.dln-btn.dln-btn--link.dln-btn--link {
				display: none
			}

			.dln-wishlist__content {
				.dln-wishlist__productWrap {
					.dln-wishProd {
						border-bottom: 0;
					}
				}
			}

		}
	}
}
@media print {
    @page {
        size: A4;
        //margin: 0 10.16mm;
    }
}

body.custom-print {
    @media print {
        * {
            -webkit-print-color-adjust: exact;
        }

        width: 21cm;
        //padding: 0 10.16mm;
        //height: 29.7cm;

        :root {
            font-size: 16px;
        }

        //  margin: 0 10.16mm;

        /* BRAUN PRINT STYLES */
        & .bra-header {
            background: #ffffff;
            padding-right: 0 !important;
            padding-left: 0 !important;

            & .bra-header__buttons-container {
                display: none;
            }
            & svg.bra-logo.bra-logo--white g g g path {
                fill: #000000;
            }
        }

        & .bra-footer,
        & .dln-foot.dln-footCheckout,
        & .dln-foot--delonghi {
            display: none;
        }

        .dln-faq--singleFaq {
            padding: 0;

            & > .dln-wrapper,
            .dln-faq__chipsWrap,
            .dln-faq__main,
            .dln-quickLinks--slider,
            .dln-faq__articleFoot,
            .dln-faq__title--related,
            .dln-faq__questionList,
            .dln-list {
                display: none;
            }
            .dln-faq__content {
                page-break-inside: avoid;
                padding: 0;
                margin: 0;

                & > .dln-wrapper {
                    max-width: initial !important;
                    border-bottom: none;
                    padding: 0px !important;
                }

                img,
                picture {
                    width: 100%;
                    height: auto;
                }
                .dln-faq__videoContainer {
                    page-break-before: always;
                    page-break-after: always;
                    margin-bottom: 0;
                }
            }
        }

        .dln-thankYou {
            & > .dln-wrapper {
                display: none;
            }
            #mainContent {
                display: block;
                border: 0;
                background: transparent;

                .dln-thankYou__innerWrap {
                    max-width: initial;
                    padding: 0;
                    margin: 0;

                    .dln-thankYou__orderDetails {
                        .dln-thankYou__subTitle {
                            .dln-thankYou__subTitleInnerWrap {
                                display: none;
                            }
                        }
                    }

                    .dln-orderSumm {
                        background: transparent;
                    }
                }
            }
            #orderIntro {
                display: block;

                .dln-thankYou__successMess,
                .dln-thankYou__orderInfo--user,
                button {
                    display: none;
                }
            }
        }

        .dln-recipe-single {
            .dln-normal-page-wrapper {
                display: none;
            }

            .dln-recipe-single__print-page {
                display: block;
            }

            > .dln-wrapper:last-of-type {
                height: 0 !important;
            }

            .dln-recipe-details--print {
                background: $brand-03;
                margin-top: 0;

                .dln-image {
                    height: 0;
                    padding-bottom: 84.6%;

                    img {
                        position: absolute;
                        top: 0;
                        right: 0px;
                    }
                }
            }

            .dln-wrapper {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }

            .dln-recipe-details__grid {
                max-height: 350px;
                border-bottom: 1px solid $ui-01;
                overflow: hidden;
            }

            .dln-recipe-details__play-button,
            .dln-recipe-single__grid.dln-recipe-single__products-links,
            .dln-recipe-slides {
                display: none;
            }

            .dln-recipe-single {
                &__discover-more {
                    @include calcPrintSize(padding-bottom, 100);
                    @include calcPrintSize(padding-top, 40);
                    @include calcPrintSize(margin-top, 71);
                    @include calcPrintSize(font-size, 26);
                    display: block;
                    width: 794px;
                    text-align: center;
                    border-top: 1px solid $ui-01;
                }
                &__print-notes {
                    @include calcPrintSize(padding-left, 120);
                    display: block !important;
                    width: 50%;
                    box-sizing: border-box;
                    .dln-recipe-single__title-content {
                        @include calcPrintSize(font-size, 18);
                        @include calcPrintSize(margin-bottom, 70);
                        padding: 0;
                    }

                    &-field {
                        @include calcPrintSize(height, 70);
                        border-top: 1px solid $ui-01;
                    }
                }

                &__instructions-wrap {
                    @include calcPrintSize(padding-top, 50);
                    display: flex;
                    flex-wrap: nowrap;
                    border-top: 1px solid $ui-01;

                    .dln-recipe-single__col-content {
                        width: 50%;
                        padding: 0;
                    }

                    .dln-recipe-single__step {
                        &-title,
                        > p {
                            @include calcPrintSize(font-size, 15);
                        }
                    }

                    .dln-image {
                        @include calcPrintSize(width, 591);
                        @include calcPrintSize(height, 350);
                    }
                }

                &__title-content {
                    @include calcPrintSize(font-size, 26);
                }

                &__servings-section {
                    width: 25%;
                    position: absolute;
                    top: 210px;

                    .dln-recipe-single__servings-col {
                        @include calcPrintSize(font-size, 15);
                        display: flex;
                        flex-direction: column;
                        * {
                            margin: 0;
                        }

                        span {
                            @include calcPrintSize(font-size, 15);
                            display: inline-block;
                        }
                    }
                    .dln-select__arrow {
                        display: none;
                    }

                    .dln-select__value {
                        @include calcPrintSize(font-size, 15);
                    }
                }

                &__attachment {
                    @include calcPrintSize(gap, 70);
                    @include calcPrintSize(row-gap, 35);

                    grid-template-columns: repeat(3, 1fr);
                    display: grid;
                    padding-left: 0.9999rem !important;
                }

                &__accordion-content {
                    p,
                    span {
                        @include calcPrintSize(font-size, 15);
                    }
                }

                &__ingredients-wrap {
                    @include calcPrintSize(min-height, 1200);

                    @include calcPrintSize(padding-top, 50);
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    justify-content: space-between;
                }

                &__ingredients {
                    width: 50%;
                    .dln-recipe-single__col-content {
                        width: 100%;
                        padding: 0;
                    }
                }

                &__utensils-products-wrap {
                    @include calcPrintSize(padding-left, 60);
                    display: flex;
                    flex-direction: column;
                    width: 50%;
                }

                &__utensils {
                    @include calcPrintSize(padding-bottom, 30);
                    @include calcPrintSize(margin-bottom, 30);

                    border-bottom: 1px solid $ui-01;
                }

                &__print-detail-wrap {
                    position: relative;
                }

                &__print-footer {
                    @include calcPrintSize(padding-top, 50);
                    border-top: 1px solid $ui-01;

                    .dln-recipe-single__print-how-too {
                        @include calcPrintSize(min-height, 420);
                    }

                    .swiper-slide {
                        @include calcPrintSize(width, 366);
                        @include calcPrintSize(margin-bottom, 30);
                        .dln-image {
                            @include calcPrintSize(height, 185);
                        }
                    }
                }
            }

            .dln-breadcrumbs {
                @include calcPrintSize(margin-bottom, 30);

                ol {
                    a {
                        @include calcPrintSize(font-size, 15);
                    }
                }
            }

            .dln-recipe-details {
                &__bottom-info-col {
                    height: 40px;
                    margin-top: 40px;
                    i {
                        @include calcPrintSize(font-size, 40);
                    }
                }

                &__description {
                    @include calcPrintSize(margin-top, 15);
                    @include calcPrintSize(margin-bottom, 100);
                }

                &__description,
                &__bottom-info-label,
                &__bottom-info-value {
                    //@include calcPrintSize(font-size, 15);
                    font-size: 0.62494rem !important;
                }

                &__title-recipe {
                    @include calcPrintSize(font-size, 36);
                    @include calcPrintSize(margin-top, 70);
                }

                &__info-button-desktop {
                    display: none;
                }
            }

            &__product-attachment.dln-recipe-single__grid {
                flex-direction: column;
                margin-right: 0;
                margin-left: 0;
                position: relative;
                z-index: 2;

                .dln-recipe-single__product.dln-recipe-single__grid {
                    margin-right: 0;
                    margin-left: 0;
                    .dln-recipe-single__product-content {
                        border: none;
                        padding: 0;

                        > span {
                            @include calcPrintSize(font-size, 26);
                            @include fontSemibold;
                            color: $brand-04;
                        }

                        > .dln-recipe-single__product-title {
                            @include calcPrintSize(font-size, 15);
                            @include fontSemibold;
                        }
                    }
                }

                .dln-recipe-single__col {
                    max-width: none;
                    border: none;
                    padding: 0;
                }

                .swiper-footer {
                    display: none;
                }

                .dln-recipe-single__product-content.dln-recipe-single__product-is-prepared {
                    display: none;
                }

                .dln-recipe-single__product-content {
                    border: none;
                    padding: 0;

                    .dln-image {
                        @include calcPrintSize(width, 91);
                        @include calcPrintSize(height, 80);
                    }

                    > span {
                        @include calcPrintSize(font-size, 26);
                        @include fontSemibold;
                        @include calcPrintSize(margin-bottom, 30);
                    }
                }

                .dln-recipe-single__product-title {
                    @include fontSemibold;
                    font-size: 0.62494rem !important;
                }
            }

            &__col.dln-recipe-single__attachment {
                max-width: 100% !important;
                > span {
                    display: none;
                }
            }

            &__grid-info {
                flex-direction: column;
                .dln-wrapper {
                    height: 0 !important;
                }
            }

            &__col.dln-recipe-single__col-gray {
                width: 100%;
                max-width: none;
                background: $brand-03;
                .dln-recipe-single__col-content {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 100%;
                    max-width: none;

                    background: $brand-03;
                    padding: 0;

                    .dln-recipe-single__accordion {
                        position: relative;

                        &:nth-of-type(2) {
                            padding-left: 38px;
                            &::before {

                                @include calcPrintSize(bottom, -50);
                                content: "";
                                display: block;
                                height: 1px;

                                background: $ui-01;
                                position: absolute;
                                left: -384px;
                                z-index: 10;
                            }
                        }

                        &:last-of-type {
                            width: 100%;

                            .swiper-wrapper {
                                display: flex !important;
                                flex-wrap: wrap;
                            }
                            .swiper-slide {
                                @include calcPrintSize(width, 366);
                                @include calcPrintSize(margin-right, 40);
                                width: calc(100% / 3);
                                padding: 0;
                            }

                            .dln-image {
                                @include calcPrintSize(height, 185);
                            }
                        }
                    }
                }
            }
        }

        /* KENWOOD PRINT STYLES */
        &.dln-kenwood {
            .dln-head {
                height: auto;
                border: 0;

                .dln-head__topListWrap,
                .dln-head__navList,
                .dln-head__btnNav {
                    display: none;
                }

                > .dln-wrapper{
                    padding-left: 0;
                    margin-left: 0;
                }

                .dln-head__logo {
                    transform: none;
                    padding: 20px;
                    padding-left: 0;
                    margin-left: 0;
                    position: relative;
                    left: initial;
                }
            }

            .dln-faq--singleFaq {
                border: 0;
                padding: 0 !important;

                .dln-faq__content {
                    padding: 0;
                    margin-bottom: 0;

                    & > .dln-wrapper {
                        padding: 0 !important;
                        padding-top: 40px !important;
                    }

                    .dln-wrapper:last-child {
                        border: 0 !important;
                    }
                }
            }

            .dln-thankYou {
                #orderIntro {
                    padding: 70pt 0 35pt 0;
                }
                #mainContent {
                    .dln-thankYou__innerWrap {
                        .dln-thankYou__orderDetails {
                            .dln-thankYou__subTitle {
                                display: none;
                            }
                        }
                    }
                }
            }

            .dln-foot--kenwood {
                display: none !important;
            }

            .dln-recipe-single__print-detail-wrap {
                .dln-recipe-details.dln-recipe-details--print {
                    max-height: 350px;
                }
                .dln-recipe-details__grid {
                    .dln-recipe-details__col:first-of-type {
                        padding: 0;
                    }
                }
            }
        }

        /* delonghi */
        &.dln-delonghi {
            /* single-recipe */
            & .dln-recipe-single {
                & .dln-recipe-single__print-page {
                    & > .dln-wrapper {
                        &:first-child {
                            padding-top: 0;

                            & .dln-breadcrumbs {
                                @include calcPrintSize(padding-top, 19);
                                @include calcPrintSize(padding-bottom, 19);
                                margin-bottom: 0 !important;

                                &__li {
                                    &::before {
                                        color: rgba($brand-04, 0.5);
                                    }
                                    & a {
                                        @include calcPrintSize(line-height, 20);
                                        @include calcPrintSize(font-size, 15);
                                        color: rgba($brand-04, 0.5);
                                    }
                                    &:last-child {
                                        &::before {
                                            color: $brand-04;
                                        }
                                        & a {
                                            color: $brand-04;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & .dln-recipe-single__print-detail-wrap {
                        & .dln-recipe-details {
                            &--print {
                                @include calcPrintSize(padding-bottom, 40);
                                border-top: none;

                                & .dln-wrapper {
                                    & .dln-recipe-details__grid {
                                        border: none;

                                        & .dln-recipe-details__col {
                                            flex: initial !important;
                                            width: 50%;

                                            & .dln-recipe-details__info {
                                                & .dln-recipe-details__title-recipe {
                                                    @include calcPrintSize(font-size, 50);
                                                    @include calcPrintSize(line-height, 52);
                                                    @include calcPrintSize(margin-bottom, 16);
                                                }
                                                & .dln-recipe-details__description {
                                                    @include calcPrintSize(line-height, 24);
                                                    font-size: 0.666rem !important;
                                                }
                                            }
                                            & .dln-recipe-details__bottom-info {
                                                padding-top: 1rem;
                                                margin-bottom: 0;

                                                &-col {
                                                    margin-top: 0;

                                                    & .dln-recipe-details__bottom-info-label {
                                                        @include calcPrintSize(line-height, 18);
                                                        font-size: 0.58327rem !important;
                                                    }
                                                    & .dln-recipe-details__bottom-info-value {
                                                        @include calcPrintSize(line-height, 18);
                                                        @include fontBold;
                                                        font-size: 0.58327rem !important;
                                                    }
                                                }
                                            }
                                            & .dln-recipe-details__image-container {
                                                & .dln-image  {
                                                    z-index: 1;

                                                    &__image {
                                                        z-index: 2;
                                                    }
                                                    &::after {
                                                        content: '';
                                                        display: block;
                                                        width: 100%;
                                                        height: 100%;
                                                        background-color: rgba(0,0,0, 0.6);
                                                        opacity: 0.999;
                                                        position: absolute;
                                                        top: 0;
                                                        left: 0;
                                                        z-index: 3;
                                                    }
                                                }
                                            }
                                            & .dln-btnIcon {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        & .dln-recipe-single__servings-section {
                            top: 13.5rem;

                            & .dln-recipe-single__servings-col {
                                @include calcPrintSize(font-size, 18);
                                color: $brand-04;

                                & .dln-select__value,
                                & > span {
                                    @include calcPrintSize(font-size, 28);
                                    @include calcPrintSize(line-height, 34);
                                    @include fontMedium;
                                }
                            }
                        }
                    }
                    & .dln-recipe-single__ingredients-wrap {
                        @include calcPrintSize(padding-top, 64);
                        @include calcPrintSize(padding-bottom, 64);
                        min-height: initial;

                        & .dln-recipe-single__title-content {
                            @include calcPrintSize(font-size, 28);
                            @include calcPrintSize(line-height, 34);
                            @include calcPrintSize(padding-bottom, 8);
                        }
                        & .dln-recipe-single__accordion-content {
                            > p {
                                @include calcPrintSize(font-size, 16);
                                @include calcPrintSize(line-height, 24);
                            }
                        }
                        & .dln-recipe-single__utensils-products-wrap {
                            & .dln-recipe-single__utensils {
                                @include calcPrintSize(padding-bottom, 40);
                                @include calcPrintSize(margin-bottom, 40);

                            }
                            & .dln-recipe-single__products {
                                & .dln-recipe-single__product-attachment {
                                    padding-top: 0;

                                    & .dln-recipe-single__product-content {
                                        & span {
                                            @include fontMedium;
                                            @include calcPrintSize(margin-bottom, 22);
                                            text-transform: lowercase;

                                            &:first-letter {
                                                text-transform: uppercase;
                                            }
                                        }
                                        & .dln-image {
                                            @include calcPrintSize(width, 84);
                                            @include calcPrintSize(height, 69);
                                        }
                                        & .dln-recipe-single__product-title {
                                            font-size: 0.79159rem !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & .dln-recipe-single__instructions-wrap {
                        & .dln-recipe-single__col-content {
                            & .dln-recipe-single__title-content {
                                @include calcPrintSize(padding-bottom, 18);
                            }
                            & .dln-recipe-single__step {
                                & + .dln-recipe-single__step {
                                    @include calcPrintSize(margin-top, 40);
                                }
                                & .dln-recipe-single__step-title {
                                    @include calcPrintSize(font-size, 18);
                                    @include calcPrintSize(line-height, 24);
                                    @include calcPrintSize(margin-bottom, 11);

                                    & span {
                                        @include calcPrintSize(font-size, 18);
                                        @include calcPrintSize(line-height, 24);
                                    }
                                }
                                & .dln-image {
                                    @include calcPrintSize(margin-bottom, 10);
                                }
                                & p {
                                    @include calcPrintSize(font-size, 16);
                                    @include calcPrintSize(line-height, 24);
                                }
                            }
                        }
                        & .dln-recipe-single__print-notes {
                            & .dln-recipe-single__title-content {
                                @include calcPrintSize(font-size, 28);
                                @include calcPrintSize(line-height, 34);
                                @include calcPrintSize(margin-bottom, 54);
                            }
                        }
                    }
                    & .dln-recipe-single__print-footer {
                        @include calcPrintSize(padding-top, 64);

                        & .dln-recipe-single__print-how-too {
                            & .dln-recipe-single__accordion {
                                & .dln-recipe-single__accordion-header {
                                    & .dln-recipe-single__title-content {
                                        @include calcPrintSize(font-size, 28);
                                        @include calcPrintSize(line-height, 34);
                                        @include calcPrintSize(padding-bottom, 10);
                                    }
                                }
                            }
                            & .dln-recipe-single__grid-how-to {
                                margin: 0;

                                & .swiper-slide {
                                    @include calcPrintSize(width, 366);
                                    @include calcPrintSize(margin-right, 40);
                                    padding: 0;

                                    &:last-child {
                                        margin-right: 0;
                                    }
                                    & .dln-image {
                                        @include calcPrintSize(width, 366);
                                        @include calcPrintSize(height, 185);
                                        @include calcPrintSize(margin-bottom, 10);
                                    }
                                    & > p {
                                        @include calcPrintSize(font-size, 16);
                                        @include calcPrintSize(line-height, 24);

                                        & strong {
                                            @include calcPrintSize(margin-bottom, 11);
                                            @include fontBold;
                                            display: block;
                                            width: 100%;
                                            line-height: inherit;
                                            font-size: inherit;
                                        }
                                        & br {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        & .dln-recipe-single__discover-more.dln-recipe-single__title-content {
                            @include calcPrintSize(margin-top, 8);
                            @include calcPrintSize(padding-top, 64);
                            @include calcPrintSize(padding-bottom, 100);
                            @include calcPrintSize(font-size, 28);
                            @include calcPrintSize(line-height, 34);
                        }
                    }
                }
            }
        }

        /* kenwood */
        &.dln-kenwood {
            & .dln-head--kenwood {
                @include calcPrintSize(height, 24);

                & .dln-head__logo {
                    padding: 0;
                    margin: 0 auto;

                    & .dln-head__logoIcon {
                        @include calcPrintSize(height, 24);
                        width: auto;
                    }
                }
            }
            /* single recipe */
            & .dln-recipe-single {
                & .dln-recipe-single__print-page {
                    & > .dln-wrapper {
                        &:first-child {
                            @include calcPrintSize(padding-top, 100);

                            & .dln-breadcrumbs {
                                @include calcPrintSize(height, 24);
                                @include calcPrintSize(margin-bottom, 60);

                                & .dln-breadcrumbs__li  {
                                    @include calcPrintSize(height, 24);

                                    &:not(:first-of-type) {
                                        &::before {
                                            @include calcPrintSize(height, 24);
                                            @include calcPrintSize(width, 7);
                                            @include calcPrintSize(margin-right, 30);
                                            @include calcPrintSize(font-size, 12);
                                            @include fontIcon;
                                            content: 'd';
                                            display: inline-block;
                                            color: $ui-07;
                                        }
                                    }
                                    & a {
                                        @include calcPrintSize(font-size, 20);
                                        @include calcPrintSize(line-height, 24);
                                        @include fontBold;
                                        display: inline-block;
                                        color: $ui-07;
                                    }
                                    &:last-child {
                                        opacity: 1;

                                        & > a {
                                            color: $brand-04;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & .dln-recipe-single__print-detail-wrap {
                        & .dln-recipe-details--print {
                            height: auto;
                            max-height: none;
                        }
                        & .dln-recipe-details__grid {
                            @include calcPrintSize(padding-bottom, 60);
                            max-height: none;
                        }
                        & .dln-recipe-details__info {
                            @include calcPrintSize(padding-right, 20);
                            box-sizing: border-box;

                            & .dln-recipe-details__title-recipe {
                                @include calcPrintSize(margin-top, 95);
                                @include calcPrintSize(margin-bottom, 20);
                                @include calcPrintSize(font-size, 38);
                                @include calcPrintSize(line-height, 46);
                                @include calcPrintSize(padding-right, 100);
                            }
                            & .dln-recipe-details__description {
                                @include calcPrintSize(font-size, 14);
                                @include calcPrintSize(line-height, 20);
                                @include calcPrintSize(padding-right, 100);
                            }
                        }
                        & .dln-recipe-single__servings-section {
                            padding-bottom: 0;
                            top: 240px;

                            & .dln-recipe-single__servings-col {
                                @include calcPrintSize(font-size, 16);
                                @include calcPrintSize(line-height, 24);

                                & .dln-select__value,
                                & span {
                                    @include calcPrintSize(font-size, 24);
                                    @include calcPrintSize(line-height, 30);
                                    color: $brand-04;
                                }
                            }
                        }
                        & .dln-recipe-details__bottom-info {
                            @include calcPrintSize(height, 50);
                            margin-bottom: 0;

                            & .dln-recipe-details__bottom-info-col {
                                @include calcPrintSize(height, 50);
                                border: none;
                                margin: 0;

                                &:not(:first-of-type) {
                                    @include calcPrintSize(padding-left, 30);
                                }
                                & .dln-icn {
                                    @include calcPrintSize(height, 50);
                                    @include calcPrintSize(font-size, 50);
                                    color: $brand-04;
                                }
                            }
                        }
                    }
                    & .dln-recipe-single__ingredients-wrap {
                        min-height: initial;

                        & .dln-recipe-single__title-content,
                        & .dln-recipe-single__product-content > span {
                            @include fontExtra;
                            @include calcPrintSize(font-size, 28);
                            @include calcPrintSize(line-height, 36);
                            @include calcPrintSize(padding-bottom, 18);
                        }
                        & .dln-recipe-single__accordion-content {
                            & > p {
                                @include calcPrintSize(font-size, 14);
                                @include calcPrintSize(line-height, 22);
                            }
                        }
                        & .dln-recipe-single__utensils-products-wrap{
                            padding: 0;
                        }
                        & .dln-recipe-single__utensils {
                            @include calcPrintSize(padding-bottom, 40);
                            @include calcPrintSize(margin-bottom, 50);
                        }
                        & .dln-recipe-single__product-content {
                            @include calcPrintSize(width, 184);
                            @include calcPrintSize(margin-right, 20);
                            @include calcPrintSize(margin-bottom, 24);

                            & > span {
                                @include calcPrintSize(margin-bottom, 18);
                                padding: 0;
                            }
                            & > .dln-image {
                                @include calcPrintSize(width, 82);
                                @include calcPrintSize(height, 82);
                            }
                            & .dln-recipe-single__product-title {
                                @include calcPrintSize(font-size, 14);
                                @include calcPrintSize(line-height, 18);
                                @include fontBold;
                            }
                        }
                        & .dln-recipe-single__col.dln-recipe-single__attachment {
                            flex: initial;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            width: 100%;
                            padding: 0 !important;

                            & .swiper-slide {
                                @include calcPrintSize(margin-bottom, 24);

                                &:nth-of-type(3n+3) {
                                    margin-right: 0;
                                }
                                & .dln-recipe-single__product-title {
                                    @include fontRoman;
                                }
                            }
                        }
                    }
                    & .dln-recipe-single__instructions-wrap {
                        & .dln-recipe-single__col-content {

                            & .dln-recipe-single__title-content {
                                @include fontExtra;
                                @include calcPrintSize(font-size, 28);
                                @include calcPrintSize(line-height, 36);
                                @include calcPrintSize(padding-bottom, 18);
                            }
                            & .dln-recipe-single__step-title {
                                @include calcPrintSize(font-size, 16);
                                @include calcPrintSize(line-height, 22);
                                @include calcPrintSize(margin-bottom, 13);
                                display: flex;
                                flex-wrap: wrap;
                                width: 100%;

                                & > span {
                                    @include calcPrintSize(font-size, 12);
                                    align-self: flex-end;
                                    color: $ui-07;
                                }
                            }
                        }
                        & .dln-recipe-single__print-notes {

                            & .dln-recipe-single__title-content {
                                @include calcPrintSize(font-size, 20);
                                @include calcPrintSize(line-height, 24);
                                // @include calcPrintSize(padding-bottom, 18);
                            }
                        }
                    }
                    & .dln-recipe-single__print-footer {
                        & .dln-recipe-single__print-how-too {
                            min-height: initial;

                            & .dln-recipe-single__title-content {
                                @include fontExtra;
                                @include calcPrintSize(font-size, 28);
                                @include calcPrintSize(line-height, 36);
                                @include calcPrintSize(margin-bottom, 20);
                                padding: 0;
                            }
                            & .dln-recipe-single__grid.dln-recipe-single__grid-how-to {
                                margin: 0;
                            }
                            & .dln-recipe-single__accordion-content {
                                & .swiper-wrapper {
                                    width: 100%;
                                }
                                & .swiper-slide {
                                    @include calcPrintSize(margin-right, 40);
                                    width: calc(33.33% - ((1.66rem * 2) / 3));
                                    padding: 0;
                                    margin-bottom: 0;

                                    &:nth-child(3n+3) {
                                        margin: 0;
                                    }
                                    & .dln-image {
                                        @include calcPrintSize(height, 185);
                                    }
                                }
                            }
                        }
                        & .dln-recipe-single__discover-more {
                            @include calcPrintSize(margin-top, 60);
                            @include calcPrintSize(padding-top, 80);
                            @include calcPrintSize(font-size, 20);
                            @include calcPrintSize(line-height, 24);
                        }
                    }
                }
            }
        }
    }
}


.dln-delonghi {
    .dln-prdReview {
    	@media only screen and (max-width:599px) {
    		.dln-orderRev {
    			padding-bottom: 20px;
	    		border-bottom: 1px solid $ui-01;
	    	}
    	}
    }
}
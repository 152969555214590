.dln-delonghi {
  & .dln-faq {
    padding-bottom: 77px;

    & .dln-wrapper {
      max-width: map-get($grid-breakpoints, xl);
    }
    &__chipsWrap {
      & > .dln-wrapper {
        max-width: calc(#{map-get($grid-breakpoints, xl)} - 80px);
      }
    }
    &__main {
      & > .dln-wrapper {
        & .dln-faq__mainTitle {
          @include fontMedium;
          line-height: 52px;
          font-size: REM(50px);
          margin-bottom: 40px;
        }
        & .dln-faq__mainSubtitle {
          @include fontMedium;
          line-height: 34px;
          font-size: REM(28px);
          margin-bottom: 16px;
        }
        & .dln-inputs--searchBar {
          width: 100%;
          max-width: 488px;
          margin-bottom: 64px;
        }
      }
    }
    & .dln-category-browser__wrapper {
      padding-top: 0;
      margin-bottom: 56px;

      & > .dln-wrapper {
        & .dln-category-browser__header {
          margin-bottom: 14px;

          & .dln-category-browser__header--title {
            @include fontMedium;
            line-height: 23px;
            font-size: REM(21px);
          }
        }
        & .dln-category-browser__category {
          padding-bottom: 42px;

          & .dln-categoryBox {
            margin-bottom: 0;
          }
        }
      }
    }
    & .dln-quickLinks--slider {
      & .dln-wrapper {
        & .dln-quickLinks__inner {
          & .dln-quickLinks__title {
            margin: 0 0 20px 0;
          }
          & .dln-quick-links-slider__carousel {
            & .swiper-wrapper .swiper-slide {
              & .dln-quickLink {
                min-height: 199px;
                padding: 21px 11px 28px 11px;
                box-sizing: border-box;

                & .dln-quickLink__wrap {
                  margin-bottom: 22px;

                  & .dln-quickLink__iconWrap {
                    width: 48px;
                    height: 48px;
                    border-radius: 0;
                    margin-bottom: 22px;
                  }
                  & .dln-quickLink__title {
                    line-height: 18px;
                    font-size: REM(16px);
                  }
                }
              }
            }
          }
        }
      }
    }
    &--alert {
      & .dln-wrapper {
        & .dln-breadcrumbs {
          margin-bottom: 10px !important;
        }
      }
      & .dln-faq__chipsWrap {
        & > .dln-wrapper--fullWidth {
          & .dln-wrapper {
            & .dln-chips {
              padding: 29px 20px 20px 20px;
              margin-bottom: 53px;

              &__wrapper {
                & .dln-icn {
                  color: $brand-04;
                  margin-bottom: 9px;
                }
              }
              &__content {
                & .dln-chips__info {
                  @include fontMedium;
                  color: $ui-02;
                  padding-top: 0;
                  margin-bottom: 16px;
                }
                & .dln-chips__title {
                  @include fontBook;
                  line-height: 24px;
                  padding-top: 0;
                  margin-bottom: 0;
                }
                & .dln-chips__description {
                  @include fontBook;
                  line-height: 20px;
                  margin-bottom: 24px;
                }
                & .dln-btn--link {
                  line-height: 18px;
                  font-size: REM(14px);
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {
  .dln-delonghi {
    & .dln-faq {
      &__chipsWrap {
        & > .dln-wrapper {
          margin-left: 40px;
          margin-right: 40px;
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    & .dln-faq {
      &__chipsWrap {
        & > .dln-wrapper {
          margin-left: 20px;
          margin-right: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    & .dln-faq {
      padding-top: 10px;
      padding-bottom: 64px;

      & > .dln-wrapper:first-child {
        & .dln-breadcrumbs {
          display: flex;
          margin-bottom: 0;
        }
      }
      &__main {
        & .dln-wrapper {
          & .dln-faq__mainTitle {
            line-height: 40px;
            font-size: REM(37px);
            margin: 24px 0;
          }
          & .dln-faq__mainSubtitle {
            line-height: 23px;
            font-size: REM(21px);
          }
          & .dln-inputs--searchBar {
            margin-bottom: 43px;
          }
        }
      }
      & .dln-category-browser__wrapper {
        margin-bottom: 41px;

        & > .dln-wrapper {
          & .dln-category-browser__header {
            margin-bottom: 6px;

            & .dln-category-browser__header--title {
              line-height: 20px;
              font-size: REM(18px);
            }
          }
          & .dln-category-browser__category {
            padding-bottom: 9px;

            & .dln-categoryBox {
              width: calc(50% - 5.5px);
              margin: 0 11px 16px 0;

              &:nth-of-type(2n + 2) {
                margin: 0 0 16px 0;
              }
            }
          }
        }
      }
      & .dln-quickLinks--slider {
        & .swiper-pagination {
          display: flex !important;
        }
      }
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-return-full-account {
        .dln-profileCard {
            display: none;
        }
        &__title {
            font-size: 24px;
            line-height: 1.13;
        }
        &__info {
            font-size: 15px;
            line-height: 1.2;
        }
    }
}
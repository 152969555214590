.dln-checkServiceBox {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: calc(100% - 40px);
    height: auto;
    min-height: 150px;
    background-color: $ui-03;
    padding: 40px 20px 20px 20px;
    /*  box-sizing: content-box; */

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .dln-icn {
        font-size: 44px;
        color: $support-02;
    }
    &__iconWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: auto;
        height: 48px;
        margin: 0 0 10px 0;
    }
    &__icon {
        display: block;
        width: 100%;
        height: auto;
    }
    &__content {
        width: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    &__title {
        @include small;
        @include fontBold;
        margin: 8px auto 8px;
    }
    .dln-btn {
        margin: 0 auto;
    }
    &__text {
        @include small;
        display: block;
        width: 100%;
        height: auto;

        color: $brand-04;
        text-align: center;
        margin: 0 auto 20px auto;
    }
    &__cardList {
        list-style: inside none;
        display: inline-flex;
        flex-wrap: wrap;
        width: auto;
        margin: 0 auto;
    }
    &__cardItem {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 24px;
        border-radius: 4px;
        background-color: $brand-03;
        margin: 0 4px 0 0;

        &:last-of-type {
            margin: 0;
        }
    }
    &__cardImg {
        display: block;

        &--verisignSecured {
            margin-bottom: 3px;
        }
    }
}
.dln-loginForm {
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
    }
    &__paragraph {
        @include small;
        width: 100%;
        color: $brand-04;
        margin: 0 0 20px 0;
        text-align: center;
        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
        &--secondary {
            @include extraExtraSmall;
            color: $ui-05;
        }
        a {
            color: $ui-05;
        }
    }
    &__subtitle {
        @include cta;
        text-align: center;
        display: block;
        width: 100%;
        height: auto;
        @include fontBold;
        // text-transform: uppercase;
    }
    &__foot {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 100%;
        height: auto;
    }
    &__btnGroup {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0;
        .dln-btn {
            width: calc(50% - 8px);
            margin: 0 16px 16px 0;
            &:nth-child(even) {
                margin: 0 0 16px 0;
            }
        }
    }
    .dln-btn--secondary {
        margin: 0 0 0 auto;
    }
    .dln-checkbox {
        width: auto;
    }
    &__section {
        width: 100%;
        margin: 24px 0 0 0;
        &--main {
            margin: 0 0 0 0;
        }
        &--registration {
            padding: 32px 0 0;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                max-width: 250px;
                height: 1px;
                background-color: $ui-01;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            .dln-loginForm__paragraph {
                color: $brand-02;
            }
            .dln-loginForm__formFoot {
                margin: 32px 0 0;
            }
        }
    }
    &__form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    &__formContainer {
        width: 100%;
        padding: 32px 0 0;
        margin: 24px 0 0;
        border-top: 1px solid $ui-01;
        .dln-select {
            width: calc(50% - 10px);
            margin: 0 50% 32px 0;
        }
        .dln-inputs {
            margin: 0 0 16px 0;
            &--halfCol {
                width: calc(50% - 10px);
                margin: 0 0 32px 0;
                &:nth-child(even) {
                    margin: 0 20px 32px 0;
                }
            }
            .dln-inputs__extraLbl {
                max-width: 250px;
                white-space: normal;
            }
        }
    }
    &__formFoot {
        width: 100%;
        margin: 30px 0 0;
        text-align: center;
        .dln-btn {
            width: 100%;
            /*  max-width: 350px; */
            margin: 0 auto;
        }
    }
    &--simplified {
        .dln-loginForm__formContainer {
            padding: 0 0;
            border: 0;
            margin: 12px 0 0;
        }
        .dln-loginForm__paragraph--divider {
            margin: 0 0 24px 0;
            position: relative;
            &::before,
            &::after {
                content: '';
                position: absolute;
                height: 1px;
                background: $ui-01;
                width: calc(50% - 15px);
                top: calc(50% - 1px);
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
            &Text {
                display: none;
            }
        }
        .dln-loginForm__section--registration {
            display: flex;
            padding: 0 0;
            align-items: center;
            &::before {
                display: none;
            }
            .dln-loginForm__paragraph,
            .dln-btn {
                display: inline-block;
                width: auto;
                margin: 0 auto;
            }
            .dln-loginForm__paragraph {
                margin: 0 8px 0 auto;
            }
            .dln-btn {
                margin: 0 auto 0 0;
            }
        }
    }
}
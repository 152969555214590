.dln-orders-sent {
    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 30px 0;
        background-color: $ui-03;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 24px;
        border: none;
    }

    &--paragraph {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 16px;
        margin-bottom: 10px;

        a {
            color: $brand-04;
        }

        &.dln-small-text {
            line-height: 16px;
            font-size: 13px;
            margin-bottom: 1px;
        }

        .text-strong {
            @include fontBold;
        }
    }

    &--select {
        width: 180px;
        max-width: 100%;
        height: 22px;
        margin-bottom: 10px;

        option {
            font-family: Arial, sans-serif; // Override dln font only for option elements
        }
    }
}
.dln-delonghi {
  .dln-btn {
    @include fontDemi;
    width: auto;
    line-height: 24px;
    font-size: REM(18px);
    text-transform: none;
    border-radius: 3px;

    &:not(.dln-btn--link) {
      @media only screen and (max-width: 599px) {
        min-width: 188px;
        height: 40px;
        font-size: REM(18px);
        padding: 0 22px;
      }
      width: auto;
      min-width: 212px;
      height: 40px;
      line-height: 1;
      padding: 0 25px;
    }
    &:not(.dln-btn--link):not(.dln-btn--stroked):not(.dln-btn--social):not(.dln-btn--paypal):not(.dln-btn--secondary) {
      color: $brand-03;
      /* background-color: $del-color-bright-blue; */

      &:hover {
        opacity: 0.8;
        //background-color: #005eb3;
        /* DELONGHI TOFIX */
      }
    }
    &:not(.dln-btn--link) {
      &.dln-btn--secondary {
        color: $brand-03;
        background-color: $brand-01;
      }
      &.dln-btn--disabled {
        color: $brand-03;
        background-color: $ui-02;
      }
    }

    &--stroked {
      @include fontDemi;
      line-height: 24px;
      font-size: REM(18px);

      //color: $brand-04;
      text-transform: none;
      //border: 1px solid $del-color-bright-blue;
      background-color: $brand-03;

      padding: 0 30px;

      &:not(.dln-btn--link) {
        height: 40px;
      }

      &:hover {
        color: $brand-04;
        border: 1px solid $brand-04;
      }
    }
    &--link {
      height: auto;
      line-height: normal;
      line-height: 21px;
      font-size: REM(14px);
      letter-spacing: 0;
      text-transform: initial;
      padding: 0;
    }
    // &--action-button {
    //     & .dln-icn {
    //         color: $support-02;
    //     }
    //     &.dln-btn--action-done {
    //         color: $support-02;
    //         border: 1px solid $support-02;
    //     }
    // }
    &--social {
      padding: 0 12px !important;

      & .dln-icn {
        margin-right: 16px;
        position: relative;
        left: initial;
      }
      & .dln-btn__lbl {
        font-size: REM(13px);
        padding: 0 34px 0 0;
      }
      &-google {
        border-color: $ui-04;

        & .dln-btn__lbl {
          padding: 0;
        }
      }
    }
    // &--social {
    //     border-radius: 0;
    //     display: flex;
    //     align-content: center;
    //     padding: 0 20px 0 20px;
    //     position: relative;
    //     .dln-btn__lbl {
    //         @include fontBold;
    //         text-transform: none;
    //         margin: 0 0 0 3em;
    //     }
    //     .dln-icn {
    //         width: 22px;
    //         height: 22px;
    //         line-height: 22px;
    //         font-size: REM(22px);
    //         color: $brand-03;
    //         top: calc(50% - 11px);
    //         left: 20px;
    //         &::before {
    //             background-size: auto 90%;
    //         }
    //     }
    //     &.dln-btn--social-google {
    //         height: 40px;
    //         color: $brand-04;
    //         border: 1px solid $ui-02;
    //         background-color: $brand-03;
    //     }
    //     &.dln-btn--social-fb {
    //         height: 40px;
    //     }
    //     &.dln-btn--social-tw {
    //         height: 40px;
    //     }
    //     &.dln-btn--social-inst {
    //         height: 40px;
    //     }
    //     &.dln-btn--centeredIcn {
    //         .dln-icn {
    //             left: initial;
    //         }
    //     }
    // }
    // &--paypal,
    // &.dln-btn--paypal:not(.dln-btn--link) {
    //     padding: 8px 20px;
    //     background: #eeeeee;

    //     &::after {
    //         content: 'Checkout';
    //         font-size: REM(12px);
    //         letter-spacing: 0.2px;
    //         text-transform: initial;
    //         margin-bottom: 3px;
    //         margin-left: 5px;
    //     }
    //     .dln-btn__icon {
    //         height: 1.7em;
    //     }

    //     .dln-btn__lbl {
    //         display: none;
    //     }
    // }
  }
}

.dln-delonghi {
    .dln-regProductList {

        &__content {
            & .dln-title {
                margin: 0 0 21px 0;
            }
        }

        &__section {
            margin-top: 10px;
            margin-bottom: 40px;

            .dln-orderRev--registeredproduct {
                margin-bottom: 40px;

                &:last-of-type {
                    margin: 0;
                }
            }
        }

        &__foot {
            border-top: 1px solid $ui-01;
            padding-top: 24px;
        }

    }
}
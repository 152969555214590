@media only screen and (max-width:599px) {
    .dln-terms {
        &__content {
            width: 100%;
            max-width: 100%;
            margin: 0 0 100px 0;
        }
        &__subTitle {
            font-size: 15px;
        }
        &__block {
            margin: 24px 0 0;
        }
    }

    .dln-delonghi {
        .dln-terms--delonghi {
            .dln-wrapper {
                margin: 0;
                padding: 0 20px;

                .dln-title--delonghi {
                    font-size: 37px;
                    @include fontMedium;
                }
            }
            .dln-terms{
                &__head {
                    padding-left: 0;
                    max-width: 100%;
                }
                &__content {
                    max-width: 100%;
                }
            }
        }
    }
}
.dln-delonghi {
  .dln-singProductReview {
    .dln-popup__head {
      .dln-btnIcon--close {
        @media only screen and (max-width: 599px) {
          transform: none;
        }
      }
    }

    &__title {
      margin-top: 16px;

      @include fontMedium;
      font-size: REM(28px);
      line-height: 34px;

      @media only screen and (max-width: 599px) {
        font-size: REM(21px);
        line-height: 23px;
      }
    }

    .dln-ratingStars {
      margin-top: 8px;
    }
    .dln-singProductReview__detailsTitle {
      margin-bottom: 16px;

      @include fontDemi;
      font-size: REM(14px);
      line-height: 18px;
    }

    .dln-singProductReview__desc {
      @include fontBook;
      font-size: REM(14px);
      line-height: 18px;
    }
  }
}

.dln-addedItem {
  padding: 0 0;

  @media only screen and (max-width: 599px) {
    &--addedItem {
      & .dln-addedItem__content {
        &--extra {
          & .dln-btn {
            margin: 0 auto 0;
          }
        }
      }
    }
  }

  .dln-popup__head {
    padding: 16px 40px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media only screen and (min-width: 1199px) {
      padding: 20px 40px;
    }

    &--extra {
      display: flex;
      flex-wrap: wrap;
      background-color: $ui-03;

      @media only screen and (min-width: 900px) and (max-width: 1199px) {
        padding: 0 40px 20px;
      }

      .dln-btn {
        margin: 16px auto;

        .dln-icn {
          height: auto;
        }

        &.dln-btn--link {
          margin: 20px auto 0;
        }
      }

      .dln-addedItem__subTitle {
        @include mainMenu;
        @include fontBold;
        width: 100%;
        color: $brand-02;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 16px;
        margin: 0 0 24px;
      }
    }
  }

  &.dln-addedItem--suggestion {
    .dln-cartItem__content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .dln-cartItem__picture {
      margin: 0 0 0 auto;
    }

    .dln-cartItem__title {
      margin: 0 auto 8px 24px;
    }
  }

  .dln-addItem {
    width: 100%;
    margin: 0 0 32px 0;

    @media only screen and (min-width: 599px) {
      width: calc(50% - 20px);
      margin: 0 16px 16px 0;
    }

    .dln-btn {
      margin: 8px 0 0;
    }

    .dln-btn--link {
      width: 100%;
    }
  }

  .dln-cartItem {
    border-width: 0;
    padding: 0 0 0;

    &__picture {
      width: 110px;
      height: 88px;
      margin: 0 0 0 0;

      @media only screen and (min-width: 599px) {
        width: 120px;
        height: 96px;
      }
    }

    &__title {
      width: calc(100% - 134px);

      @media only screen and (min-width: 599px) {
        width: calc(100% - 240px);
      }
    }

    &__price {
      width: calc(100% - 134px);
      text-align: left;
      margin: 16px 0 0 16px;

      @media only screen and (min-width: 599px) {
        width: 80px;
        text-align: right;
        margin: 0 0 0 16px;
      }

      .realPrice {
        font-size: REM(19px);
        display: flex;
      }

      .dln-price--oldPrice {
        font-size: REM(16px);
      }
    }

    &__quantity {
      display: flex;
      flex-wrap: wrap;

      & .dln-price {
        margin: 0 0 4px 0;
      }
    }

    &__warningMessage {
      display: block;
      width: 100%;
      color: $support-01;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 8px;
      overflow: hidden;
    }

    &__btn {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-top: 1px solid $ui-01;
      padding: 16px 0 0;

      @media only screen and (min-width: 599px) {
        margin: 24px 0 0;
      }

      .dln-btn {
        width: calc(50% - 10px);
        margin: 16px 20px 0 0;

        &:last-of-type {
          margin: 16px 0 0;
        }
      }
    }

    .dln-csmDropdown {
      float: left;
      width: 100%;
      margin: 8px 0 0 0;

      @media only screen and (min-width: 599px) {
        width: calc(100% - 120px - 24px);
        margin: 8px 0 0 24px;
      }
    }
  }
}

.dln-popup {
  .dln-addedItem {
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;
  }
}

.dln-delonghi {
  .dln-profileCard {
    border-width: 0;
    background-color: $ui-03;
    padding: 10px;
    margin: 0;
    margin-bottom: 30px;

    &__circle {
      background-color: $brand-05;

      &Text {
        @include fontMedium;
        font-size: REM(21px);
        line-height: 23px;
      }
    }
    &__text {
      @include fontBook;
      font-size: REM(14px);
      line-height: 18px;
    }
    &__userName {
      font-weight: bold;
    }
    @media only screen and (max-width: 599px) {
      margin: 0 0 16px;
    }
  }
}

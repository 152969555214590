.dln-instruction-manuals__searchWrapper {
  width: 100%;
}

.dln-instruction-manuals__helpSection {
  .dln-btn--link {
    color: var(--brand-03);
    text-decoration: none;
  }
}

.dln-repairCenter {
    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {
        .dln-title {
            margin: 0 0 16px 0;
        }
        .dln-btn {
            max-width: 200px;
            margin: 0 0 0 auto;
        }
    }
    &__subtitle {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 13px;
        margin: 0 0 16px 0;
    }
    &__contentWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding: 0 0 30px 0;
    }
}

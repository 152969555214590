@media only screen and (max-width:599px) {
    .dln-order {
        &--cancellation {
            & .dln-editOrdHead {
                margin-bottom: 20px;
            }
            & .dln-order__cancellationTitle {
                line-height: 27px;
                font-size: 24px;
                padding: 0;
                margin-bottom: 16px;
            }
            & .dln-order__cancellationData {
                line-height: 18px;
                font-size: 15px;
                margin: 0 42px 30px 0;

                &:last-child {
                    margin: 0 0 30px 0;
                }
            }
            & .dln-order__cancellationMessage {
                margin-bottom: 38px;
            }
            & .dln-order__cancellationWrap {
                height: auto;
                margin-bottom: 40px;

                & .dln-select--selectAMotivation {
                    width: 100%;
                    max-width: none;
                    margin: 0 0 20px 0;
                }
                & .dln-btn--cancelOrder {
                    width: 50%;
                    margin: 0 auto 0 0;
                }
            }
            & .dln-order__cancellationProducts {
                border-top: none;
                border-bottom: 1px solid $ui-01;
                padding: 0;
                margin-bottom: 36px;

                &--last {
                    border-bottom: none;
                    margin-bottom: 0;
                }
                & .dln-orderHistory {
                    margin-bottom: 40px;

                    &:last-child {
                        margin-bottom: 30px;
                    }
                    & .dln-orderRev {
                        padding: 0;

                        &__details {
                            margin: 0 0 0 70px;
                        }
                        &__detailsTitle {
                            order: 1;
                            line-height: 20px;
                            margin-bottom: 2px;
                        }
                        & .dln-price {
                            order: 3;
                            line-height: 20px;
                            font-size: 14px;
                        }
                        &__productCode {
                            order: 2;
                            line-height: 20px;
                            font-size: 15px;
                            margin: 0 0 8px 0;
                        }
                        &__quantity {
                            color: $brand-02;
                        }
                        &__actions {
                            margin-top: 26px;

                            & .dln-btn {
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dln-delonghi {
  .dln-recipe-slides {
    padding-top: 30px;

    &__title a {
      @include fontMedium;
      font-size: REM(28px);
      line-height: 1.21;
      color: $brand-04;
    }
    &__link {
      @include fontMedium;
      font-size: REM(14px);
      line-height: 1.29;
      color: $brand-04;
    }

    .swiper-container {
      .swiper-pagination {
        display: flex !important;
      }
      .swiper-scrollbar,
      .swiper-button-next,
      .swiper-button-prev {
        display: none !important;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-recipe-slides {
      &__title a {
        @include fontMedium;
        font-size: REM(21px);
        line-height: 1.1;
      }
    }
  }
}

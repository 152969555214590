.dln-delonghi {
  & .dln-popup {
    & .dln-popup__content {
      & .dln-popup__wrapper {
        & .dln-addAccessories {
          .dln-popup__head.dln-popup__head--circleClose {
            padding-top: 28px;
          }

          .dln-popup__headLine {
            background: $ui-01;
          }

          &__content {
            border-bottom: 1px solid $ui-01;
            padding-bottom: 24px;
            padding-left: 97px;

            .dln-cartItem {
              &__content {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                padding: 0;
                align-items: flex-start;
              }

              &__title {
                padding: 0;
                margin: 0;
                width: auto;
                @include fontMedium;
                margin-left: 12px;
                max-width: 50%;
              }

              &__picture {
                position: relative;
                margin: 0;
                width: 141px;
                height: 114px;
                float: none;
                display: flex;
                align-items: stretch;
              }
            }

            &--extra {
              padding-bottom: 34px;
              padding-top: 32px;
              padding-left: 40px;

              .dln-addAccessories {
                &__subTitle {
                  display: none;
                }
              }

              .dln-addItem {
                max-width: 236px;
                width: auto;
                margin: 0;

                .dln-btn.dln-btn-- {
                  width: 100%;
                  margin: 13px auto;
                }

                button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 10px;
                  margin-top: 13px;

                  span {
                    margin: 4px 0 0;
                    font-size: 15px;
                  }

                  i {
                    font-size: 14px;
                  }
                }
              }

              background: $brand-03;

              display: grid;
              grid-template-columns: repeat(2, 240px);
              gap: 21px;
              row-gap: 32px;

              > .dln-btn.dln-btn--link {
                margin: 0 auto;
                grid-column: span 2;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
  .dln-delonghi {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-addAccessories {
            &__content {
              border-bottom: 1px solid $ui-01;
              padding-bottom: 24px;
              padding-left: 97px;

              &--extra {
                padding: 30px 137px;

                .dln-addAccessories {
                  &__subTitle {
                    display: none;
                  }
                }

                .dln-addItem {
                  max-width: none;
                  width: 100%;
                  margin: 0;

                  .dln-btn.dln-btn-- {
                    width: 100%;
                    margin: 13px auto;
                  }

                  button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    margin-top: 13px;

                    span {
                      margin: 0;
                      font-size: 15px;
                    }

                    i {
                      font-size: 14px;
                    }
                  }
                }

                background: $brand-03;

                display: flex;
                row-gap: 32px;

                > .dln-btn.dln-btn--link {
                  margin: 0 auto;
                  grid-column: span 2;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-popup {
      & .dln-popup__content {
        & .dln-popup__wrapper {
          & .dln-addAccessories {
            .dln-popup__head.dln-popup__head--circleClose {
              padding: 20px 22px 0 22px;
            }

            .dln-btnIcon.dln-btnIcon--close.dln-btnIcon--circleClose {
              margin-bottom: 0;
            }

            .dln-popup__headTitle {
              padding: 0;
            }

            .dln-popup__headLine {
              margin-top: 15px;
            }

            &__content {
              padding: 13px 15px;

              .dln-image.dln-cartItem__picture {
                width: 124px;
                height: 100px;
              }

              .dln-cartItem__title {
                font-size: REM(16px);
                margin-left: 16px;
              }

              &--extra {
                display: flex;
                flex-direction: column;
                padding: 32px 20px 23px;

                .dln-addItem {
                  width: 100%;
                  max-width: none;
                  margin-top: 0;

                  .dln-btn.dln-btn--light {
                    align-self: initial;
                  }

                  .dln-btn.dln-btn-- {
                    width: 100%;
                    margin: 13px auto;
                  }

                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }

                .dln-btn.dln-btn--link {
                  margin: 12px auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

.dln-kenwood {
    .dln-registration--login {
        .dln-registration__section--main {
            border-right: 0px;
        }

        .dln-inputs__lbl {
            line-height: 16px;
        }

        .dln-loginForm__paragraph {
            text-align: left;
            margin-bottom: 35px;
        }

        .dln-registration__foot {
            .dln-btn--confirm {
                margin-top: 14px;
                width: 100%;
            }
        }
        @media only screen and (max-width:599px) {
            .dln-registration {
                &__section {
                    border-top: 0px;
                }
                &__section--main {
                    margin-top: 24px;
                }
                &__subtitle {
                    text-align: center;
                    display: block;
                }
                &__paragraph {
                    line-height: 20px;
                }
                &__foot {
                    .dln-btn--confirm {
                        margin-top: 36px;
                    }
                }
            }
            .dln-loginForm {
                &__paragraph {
                    text-align: center;
                }
                &__formContainer {
                    .dln-inputs + .dln-inputs {
                        margin-bottom: 15px;
                    }
                }
                &__formFoot {
                    margin-top: 39px;
                }
            }
        }
    }
}
#js-gigya-screenset-marketing-privacy {
  width: 100%;
}

[data-screenset-element-id='gigya-marketing-preferences-screen'] {
  .gigya-layout-row:nth-child(4) {
    display: flex;
    flex-wrap: wrap;
    padding: 21px;
    border: 1px solid var(--ui-02);

    .gigya-custom-checkbox {
      width: calc(100% - 30px);
      margin: 0 !important;

      label {
        font-family: var(--fontNameRoman);
        font-weight: 400;
        font-style: normal;
        color: var(--brand-02);
        font-size: 13px;
      }
    }

    .gigya-toggle {
      width: 30px;
      text-align: right;
      margin: 0 !important;
    }

    .gigya-toggle-content {
      font-size: 11px;
      line-height: 16px;
      font-family: var(--fontNameRoman);
      font-weight: 400;
      font-style: normal;
      color: var(--brand-02);
      margin: 21px 0 0 !important;
    }

    .is-hidden {
      display: none !important;
    }

    .dln-checkbox__confirmText {
      margin-top: 30px;
      margin-bottom: 20px;
      font-family: var(--fontNameBold);
      font-weight: 700;
      font-style: normal;
      font-size: 13px;
      line-height: 16px;
      width: 100%;
    }

    .dln-btn--link {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .dln-btn--confirm {
      width: 80%;
      display: flex;
      justify-content: flex-end;

      input {
        @include gigya-dln-btn;
        width: 180px;
        font-family: var(--fontNameBold);
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        height: 40px;

        @media (min-width: 600px) {
          width: 280px;
        }
      }
    }
  }

  .gigya-layout-row.subscribe-wrapper {
    display: flex;
    justify-content: center;
    margin: 50px 0 20px;
    flex-wrap: wrap;

    .dln-page__sectionHeadTitle {
      font-family: var(--fontNameBold);
      font-weight: 700;
      font-style: normal;
      font-size: 32px;
      line-height: 32px;
      margin: 0 0 33px 0;
      width: 100%;
    }

    .dln-page__sectionHeadInfoText {
      font-family: var(--fontNameRoman);
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      width: 100%;
      margin-bottom: 30px;
    }

    .gigya-entitlement-checkbox {
      position: relative;
      text-align: center;
      min-height: 130px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 20px;
      width: 100%;

      &::before {
        content: '';
        position: absolute;
        top: 30%;
        display: block;
      }

      .gigya-input-checkbox {
        position: absolute !important;
        top: -99999px !important;
        left: -99999px !important;

        +label {

          &::before,
          &::after {
            position: relative;
          }

          &::after {
            left: calc(-100% - -12px);
          }
        }
      }

      &.gigya-delonghi-checkbox::before,
      &.gigya-nutribullet-checkbox::before {
        background-image: url('#{$pathAssets}/images/logos/logo-delonghi.svg');
        background-size: 144px 46px;
        background-repeat: no-repeat;
        width: 144px;
        height: 46px;
        top: 20%;

        @media only screen and (min-width: 600px) {
          top: 30%;
        }
      }

      &.gigya-nutribullet-checkbox::before {
        background-image: url('#{$pathAssets}/images/logos/logo-nutribullet.svg');
      }

      &.gigya-braun-checkbox::before {
        background-image: url('#{$pathAssets}/images/logos/logo-braun.svg');
        background-size: 108px 44px;
        background-repeat: no-repeat;
        width: 108px;
        height: 44px;
        top: 20%;

        @media only screen and (min-width: 600px) {
          top: 30%;
        }
      }

      &.gigya-kenwood-checkbox::before {
        background-image: url('#{$pathAssets}/images/logos/logo-kenwood.svg');
        background-size: 160px 24px;
        background-repeat: no-repeat;
        width: 160px;
        height: 24px;
        top: 30%;

        @media only screen and (min-width: 600px) {
          top: 37%;
        }
      }

      &.gigya-nutribullet-checkbox::before {
        background-image: url('#{$pathAssets}/images/logos/logo-nutribullet.svg');
        background-size: 160px 26px;
        background-repeat: no-repeat;
        width: 160px;
        height: 26px;
        top: 28%;

        @media only screen and (min-width: 600px) {
          top: 35%;
        }
      }

      .gigya-label {
        display: flex;

        .gigya-label-text {
          margin-top: 3px;
        }
      }

      @media (min-width: 600px) {
        min-height: 180px;
        max-height: 180px;
        width: 45%;
      }

      @media (min-width: 900px) {
        width: 33.33%;
      }

      @media (min-width: 1200px) {
        width: 22%;
      }
    }

    .gigya-visible-when {
      width: 100%;

      @media (min-width: 600px) {
        min-height: 180px;
        max-height: 180px;
        width: 45%;
      }

      @media (min-width: 900px) {
        width: 33.33%;
      }

      @media (min-width: 1200px) {
        width: 22%;
      }

      .gigya-entitlement-checkbox {
        width: 100%;
      }
    }
  }

  // SMS checkboxes with labels
  .gigya-delonghi-checkbox-sms,
  .gigya-braun-checkbox-sms,
  .gigya-kenwood-checkbox-sms,
  .gigya-nutribullet-checkbox-sms {
    padding: 0 !important;
    margin: 0 !important;
    height: 20px !important;
    min-height: 20px !important;
    left: calc(-50% - 1px) !important;
    top: 125px !important;
    width: 0 !important;

    @media (min-width: 600px) {
      top: 175px !important;
      left: calc(-22% - 5px) !important;
    }

    @media (min-width: 900px) {
      left: calc(-16% - 5px) !important;
    }

    @media (min-width: 1200px) {
      left: calc(-11% - 1px) !important
    }
  }

  .dln-btn--update {
    text-align: right;
    margin-top: 40px;
    padding-top: 30px;
    border-top: 1px solid var(--ui-01);
    width: 100%;

    input {
      @include gigya-dln-btn;
      width: 280px;
      font-family: var(--fontNameBold);
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      height: 40px;
    }
  }
}

// SMS disabled UI
#js-gigya-screenset-marketing-privacy.gigya-sms-disabled {
  .subscribe-wrapper {

    .gigya-delonghi-checkbox-sms,
    .gigya-braun-checkbox-sms,
    .gigya-kenwood-checkbox-sms,
    .gigya-nutribullet-checkbox-sms {
      display: none !important;
    }

    .gigya-checkbox-text {
      display: none !important;
    }
  }
}

// Braun fix
.dln-braun {

  .gigya-delonghi-checkbox-sms,
  .gigya-braun-checkbox-sms,
  .gigya-kenwood-checkbox-sms,
  .gigya-nutribullet-checkbox-sms {
    display: none !important; // To remove after release on this country
    left: calc(-50% - 2px) !important;

    @media (min-width: 600px) {
      top: 175px !important;
      left: calc(-22% - 7px) !important;
    }

    @media (min-width: 900px) {
      left: calc(-16% - 6px) !important;
    }

    @media (min-width: 1200px) {
      left: calc(-11% - 3px) !important
    }
  }
}

// Nutribullet fix
.dln-nutribullet {

  .gigya-delonghi-checkbox-sms,
  .gigya-braun-checkbox-sms,
  .gigya-kenwood-checkbox-sms,
  .gigya-nutribullet-checkbox-sms {
    display: none !important; // To remove after release on this country

    left: calc(-50% - 3px) !important;

    @media (min-width: 600px) {
      left: calc(-22% - 7px) !important;
    }

    @media (min-width: 900px) {
      left: calc(-16% - 7px) !important;
    }

    @media (min-width: 1200px) {
      left: calc(-11% - 3px) !important
    }
  }
}

// Kenwood fix
.dln-kenwood {
  .gigya-layout-row.subscribe-wrapper {
    .gigya-label-text {
      width: auto !important;
    }

    .gigya-delonghi-checkbox-sms,
    .gigya-braun-checkbox-sms,
    .gigya-kenwood-checkbox-sms,
    .gigya-nutribullet-checkbox-sms {
      display: none !important; // To remove after release on this country
      left: calc(-50% - 4px) !important;

      @media (min-width: 600px) {
        left: calc(-22% - 8px) !important;
      }

      @media (min-width: 768px) {
        left: calc(-22% - 3px) !important;
      }

      @media (min-width: 900px) {
        left: calc(-16% - 3px) !important;
      }

      @media (min-width: 1200px) {
        left: calc(-11%) !important
      }
    }
  }
}


.language-zh {
  #js-gigya-screenset-marketing-privacy {
    .gigya-screen-content {
      .gigya-profile-form {
        >.gigya-layout-row:nth-of-type(2) {
          display: flex;
          flex-wrap: wrap;
          padding: 21px;
          border: 1px solid var(--ui-02);
        }

        .gigya-layout-row:nth-child(4) {
          display: none;
        }
      }

      .gigya-toggle {
        align-self: center;
      }

      .dln-checkbox__confirmText {
        margin-top: 30px;
        margin-bottom: 20px;
        font-family: var(--fontNameBold);
        font-weight: 700;
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        width: 100%;
      }

      .dln-page__sectionHeadTitle {
        text-align: center;
        font-family: var(--fontNameBold);
        font-weight: 700;
        font-style: normal;
        font-size: 32px;
        line-height: 32px;
        margin: 0 0 33px;
        width: 100%;
      }

      .dln-page__sectionHeadInfoText {
        text-align: center;
        font-family: var(--fontNameRoman);
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        margin-bottom: 30px;
      }

      .dln-btn--link {
        width: 20%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
      }

      .dln-btn--confirm {
        width: 80%;
        display: flex;
        justify-content: flex-end;

        .gigya-input-submit {
          width: 280px;
          outline: none;
          color: var(--brand-03);
          background-color: var(--brand-01);
          text-align: center;
          border: none;
          box-shadow: none;
          transition: all .3s linear;
          overflow: hidden;
          cursor: pointer;
          height: 40px;
        }
      }

      .is-hidden {
        display: none;
      }

      .gigya-composite-control.gigya-composite-control-link.gigya-toggle {
        width: 30px;
        text-align: right;
        margin: 0;
      }

      .gigya-composite-control-link:focus {
        border: none;
      }

      .gigya-checkbox-text {
        position: relative;
      }
    }

    .gigya-layout-ro {
      display: flex;
      justify-content: center;
      margin: 50px 0 20px;
      flex-wrap: wrap;

      .gigya-entitlement-checkbox {
        position: relative;
        text-align: center;
        min-height: 130px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 20px;
        width: 100%;

        .gigya-label-text.gigya-checkbox-text {
          display: none;
        }

        &::before {
          content: '';
          position: absolute;
          top: 30%;
          display: block;
        }

        .gigya-input-checkbox {
          position: absolute;
          top: -99999px;
          left: -99999px;

          +label {

            &::before,
            &::after {
              position: relative;
            }

            &::after {
              top: -12px;
            }
          }
        }

        &.gigya-delonghi-checkbox::before,
        &.gigya-nutribullet-checkbox::before {
          background-image: url('#{$pathAssets}/images/logos/logo-delonghi.svg');
          background-size: 144px 46px;
          background-repeat: no-repeat;
          width: 144px;
          height: 46px;
          top: 20%;

          @media only screen and (min-width: 600px) {
            top: 30%;
          }
        }

        &.gigya-nutribullet-checkbox::before {
          background-image: url('#{$pathAssets}/images/logos/logo-nutribullet.svg');
        }

        &.gigya-braun-checkbox::before {
          background-image: url('#{$pathAssets}/images/logos/logo-braun.svg');
          background-size: 108px 44px;
          background-repeat: no-repeat;
          width: 108px;
          height: 44px;
          top: 20%;

          @media only screen and (min-width: 600px) {
            top: 30%;
          }
        }

        &.gigya-kenwood-checkbox::before {
          background-image: url('#{$pathAssets}/images/logos/logo-kenwood.svg');
          background-size: 160px 24px;
          background-repeat: no-repeat;
          width: 160px;
          height: 24px;
          top: 30%;

          @media only screen and (min-width: 600px) {
            top: 37%;
          }
        }

        &.gigya-nutribullet-checkbox::before {
          background-image: url('#{$pathAssets}/images/logos/logo-nutribullet.svg');
          background-size: 160px 26px;
          background-repeat: no-repeat;
          width: 160px;
          height: 26px;
          top: 28%;

          @media only screen and (min-width: 600px) {
            top: 35%;
          }
        }

        @media (min-width: 600px) {
          min-height: 180px;
          max-height: 180px;
          width: 45%;
        }

        @media (min-width: 900px) {
          width: 33.33%;
        }

        @media (min-width: 1200px) {
          width: 22%;
        }
      }

      .gigya-visible-when {
        width: 100%;

        @media (min-width: 600px) {
          min-height: 180px;
          max-height: 180px;
          width: 45%;
        }

        @media (min-width: 900px) {
          width: 33.33%;
        }

        @media (min-width: 1200px) {
          width: 22%;
        }

        .gigya-entitlement-checkbox {
          width: 100%;
        }
      }

      .dln-btn--update {
        text-align: right;
        margin-top: 40px;
        padding-top: 30px;
        border-top: 1px solid var(--ui-01);
        width: 100%;
        text-align: center;

        input {
          @include gigya-dln-btn;
          width: 280px;
          font-family: var(--fontNameBold);
          font-weight: 700;
          font-style: normal;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
}

.language-zh.dln-kenwood {
  #js-gigya-screenset-marketing-privacy {
    .gigya-input-submit {
      background-color: #d11b59;
    }

    .dln-btn--update {
      padding-top: 30px !important;
      text-align: right;
    }

    .gigya-screen-content {
      .gigya-profile-form {
        >.gigya-layout-row:nth-of-type(2) {
          border: none;
          border-bottom: 1px solid var(--ui-02);
        }

        .gigya-layout-row:nth-child(4) {
          display: none;
        }
      }
    }
  }
}

.dln-braun.language-zh {
  #js-gigya-screenset-marketing-privacy {

    .dln-page__sectionHeadTitle,
    .dln-page__sectionHeadInfoText {
      text-align: left;
    }

    .gigya-input-submit {
      border-radius: 20px;
    }
  }
}

// When the user doesn't have a phone number in their profile
.gigya-sms-hidden-no-phone {

  .gigya-delonghi-checkbox-sms,
  .gigya-braun-checkbox-sms,
  .gigya-kenwood-checkbox-sms,
  .gigya-nutribullet-checkbox-sms {
    display: none !important;
  }
}
.dln-delonghi {
  .dln-select {
    &__option:first-of-type {
      &::before {
      }
    }
    &__container {
      height: auto;

      .dln-select__head {
        height: 40px;
      }

      .dln-select__head,
      .dln-select__body {
        border-color: $ui-02;
      }
    }
    &__lbl {
      //transform: none;
      font-size: REM(14px);
      line-height: 16px;
      padding: 0 9px;
    }
    &--invalid {
      .dln-select__lbl--error {
        padding-left: 0;
      }
    }

    .dln-select--has-value + .dln-select__lbl {
      font-size: REM(12px);
      line-height: 13px;
      transform: translateY(calc(50% - 24px));
    }

    .dln-select--open +  .dln-select__lbl--active {
      transform: translateY(calc(50% - 28px));
    }

    .dln-select--open +  .dln-select__lbl--error {
      padding-left: 9px;
    }
  
    // &--has-value {
    //     .dln-select__head {
    //         border-color: $brand-04;
    //     }
    //     .dln-select__value {
    //         color: $brand-04;
    //     }
    // }
    // &--open {
    //     .dln-select__head {
    //         border-width: 1px 1px 0;
    //         border-color: $brand-04;
    //     }
    //     .dln-select__body {
    //         border-width: 0 1px 1px;
    //         border-color: $brand-04;
    //     }
    // }
    // &--extraLight {
    //     .dln-select--open {
    //         .dln-select__body {
    //             border-width: 1px 1px 1px;
    //         }
    //     }
    //     .dln-select__container {
    //         height: 20px;
    //     }
    // }
    // &--invalid {
    //     .dln-select__body {
    //         border-color: $support-01;
    //     }
    // }
    // &--labeled {
    //     .dln-select__head {
    //         border-radius: 0;
    //     }
    // }
    // &--light,
    // &--extraLight {
    //     &.dln-select--open.dln-select--open-above {
    //         .dln-select__head {
    //             border-width: 0;
    //         }
    //     }
    //     &.dln-select--open-above {
    //         .dln-select--open {
    //             .dln-select__body {
    //                 border-width: 1px;
    //             }
    //             .dln-select__head {
    //                 border-bottom: 1px solid var(--brand-04);
    //             }
    //         }
    //     }
    // }
    // &--open-above {
    //     &.dln-select--open {
    //         .dln-select__body {
    //             border-width: 1px 1px 1px;
    //         }
    //         .dln-select__head {
    //             border-top: 0;
    //             border-bottom: 1px solid var(--brand-04);
    //         }
    //     }
    // }
    // &.dln-select--light,
    // &.dln-select--extraLight {
    //     .dln-select--open-above,
    //     .dln-select--open-below {
    //         &.dln-select--open {
    //             .dln-select__body {
    //                 border-width: 1px;
    //             }
    //             .dln-select__head {
    //                 border-bottom: 0;
    //             }
    //         }
    //     }
    // }
  }

  &.dln-nutribullet {
    .dln-select--open +  .dln-select__lbl {
      line-height: 30px;
    }

    .dln-select--invalid {
      .dln-select__lbl--error {
        padding-left: 4px;
        margin-top: 6px;
      }
    }
  }
}

.dln-kenwood {
    & .dln-page.dln-order {
        &--cancellation {
            & .dln-wrapper {
                & .dln-order__content {
                    padding-top: 27px;
                    padding-bottom: 100px;

                    & .dln-order__backToOrderHistory {
                        margin-bottom: 40px;

                        & .dln-icn {
                            color: $brand-04;
                            margin-right: 4px;
                        }
                        & span {
                            color: $brand-04;
                        }
                    }
                    & .dln-order__cancellationProducts {
                        & .dln-order__cancellationTitle {
                            @include fontExtra;
                            line-height: 46px;
                            font-size: 38px;
                            padding: 0;
                            margin-bottom: 30px;
                        }
                        & .dln-order__cancellationData {
                            line-height: 24px;
                            color: $brand-04;
                            margin-bottom: 20px;
                        }
                        & .dln-order__cancellationMessage {
                            font-size: 14px;
                        }
                        & .dln-order__cancellationWrap {
                            margin-bottom: 30px;

                            & .dln-select--selectAMotivation {
                                width: calc(50% - 20px);
                                max-width: none;
                                margin-right: 20px;
                            }
                            & > .dln-btn {
                                width: 143px;
                                background-color: $brand-01;
                                margin: 0 auto 0 0;
                            }
                            & + .dln-orderHistory  {
                                padding-top: 30px;
                            }
                        }
                        & .dln-orderHistory  {
                            border: none;
                            background: $ui-03;
                            padding: 0 20px 30px 20px;
                            margin-bottom: 0;

                            & .dln-orderRev {
                                margin-bottom: 0;

                                & .dln-orderRev__wrap {
                                    &--main {
                                        margin-bottom: 15px;

                                        & .dln-orderRev__detailsTitle {
                                            margin-bottom: 15px;
                                        }
                                        & .dln-price {
                                            &--oldPrice {
                                                color: $ui-08;
                                                margin-right: 10px;
                                            }
                                            &--discounted {
                                                @include fontBold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    & .dln-totalSect {
                        &--lite {
                            background-color: $brand-03;
                            padding: 0;
                            margin-top: 10px;

                            & .dln-totalSect__wrap {
                                width: 100%;
                                padding: 0;

                                & .dln-totalSect__row {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-page.dln-order {
            &--cancellation {
                & .dln-wrapper {
                    & .dln-order__aside {
                        width: 100%;
                    }
                    & .dln-order__content {
                        width: 100%;
                    }
                }
            }
        }
    }
}


@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-page.dln-order {
            &--cancellation {
                & .dln-wrapper {
                    & .dln-order__content {
                        padding-top: 42px;
                        padding-bottom: 0;

                        & .dln-order__backToOrderHistory {
                            margin-bottom: 20px;
                        }
                        & .dln-order__cancellationProducts {
                            & .dln-order__cancellationTitle {
                                line-height: 40px;
                                font-size: 32px;
                                text-align: center;
                            }
                            & .dln-order__cancellationData {
                                font-size: 16px;
                                margin: 0 auto 20px 0;

                                & + .dln-order__cancellationData {
                                    margin: 0 0 20px auto;
                                }
                            }
                            & .dln-order__cancellationMessage {
                                margin-bottom: 30px;
                            }
                            & .dln-order__cancellationWrap {
                                & .dln-select--selectAMotivation {
                                    width: 100%;
                                    margin: 0 0 20px 0;
                                }
                                & > .dln-btn {
                                    margin: 0 0 0 auto;
                                }
                            }
                            & .dln-orderHistory  {
                                width: calc(100% + 40px);
                                min-width: calc(100% + 40px);
                                max-width: calc(100% + 40px);
                                margin-left: -20px;

                                & .dln-orderHistory__content {
                                    & .dln-orderRev {
                                        & .dln-orderRev__content {
                                            & .dln-orderRev__details {
                                                margin: 0;

                                                & .dln-orderRev__wrap {
                                                    &--main {
                                                        margin-bottom: 16px;

                                                        & .dln-orderRev__detailsTitle {
                                                            margin-bottom: 32px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-contactUs--thankYou {
        & .dln-wrapper{
            &--fullWidth {
                padding-top: 16px;
                padding-bottom: 65px;
            }
            &--thankYouMessage {
                margin-bottom: 115px;
            }
        }
        & .dln-breadcrumbs {
            margin-bottom: 10px;

            &__li span {
                line-height: 16px;
                font-size: 10px;
                text-decoration: underline;
            }
        }   
        & .dln-contactUs__title {
            line-height: 21px;
            font-size: 21px;
            margin-bottom: 16px;
        }
        & .dln-contactUs__thankYouMessage {
            &IconWrap {
                margin: 0 20px 0 0;
            }
            &Wrap {
                width: calc(100% - 48px - 20px);
            }
        }
        /*& .dln-contactUs__title {
            font-size: 35px;
            line-height: 41px;
        }*/
        & .dln-btn--backToContactUs {
            font-size: 15px;
        }
    }
}
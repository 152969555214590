@media only screen and (max-width:599px) {
    $prefix: dln;

    .#{$prefix}-popup {
        /* Mobile alignment */
        &--mTop {
            & .#{$prefix}-popup__content {
                margin: 0 0 auto 0 !important;
            }
        }
        &--mCenter {
            & .#{$prefix}-popup__content {
                margin: auto 0 auto 0 !important;
            }
        }
        &--mBottom {
            & .#{$prefix}-popup__content {
                margin: auto 0 0 0 !important;
            }
        }

        /* fullsize */
        &--fullsize {
            padding: 0 !important;

            & .#{$prefix}-popup__content {
                max-width: none !important;
                min-height: 100vh !important;
                border-radius: 0 !important;
            }
            & .#{$prefix}-popup__wrapper {
                min-height: 100vh !important;
                border-radius: 0 !important;
            }
        }
        &--alert {
            background-color: transparent;
            width: 100%;
            max-width: 585px;
            height: auto;
            min-height: auto;
            position: fixed;
            right: auto;
            left: 0;
            top: 30px;
            bottom: auto;
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-ordersHist {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 32px 0;
        }
    }

}
.dln-prodReg {
    .dln-breadcrumbs {
        margin: 24px 0 0;
    }
    &__head {
        width: 100%;
        max-width: 600px;
        margin: 24px 0 0;
        .dln-btn {
            max-width: 280px;
        }
    }
    .dln-title {
        margin: 0 0 16px;
    }

    &__paragraph {
        @include small;
        font-size: 14px;
        margin: 0 0 24px;
    }
    &__content {
        width: 100%;
        margin: 0 0 100px;
    }
    &__subTitle {
        width: 100%;
        @include large;
        font-size: 26px;
        @include fontBold;
        margin: 60px 0 24px;
    }
    &__advantage {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .dln-advtBox {
        /* width: calc(25% - 15px); */
        width: 100%;
        margin: 0 20px 0 0;
        padding: 15px 30px;
        &:nth-child(4n+4) {
            margin: 0 0 0 0;
        }
    }
    &__categoryBreadcrumbs {
        width: 100%;
        @include small;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        .dln-select {
            margin: 0 24px 0 0;

            &__value {
                @include fontBold;
            }
        }
    }
    &__categoryLabel {
        color: $brand-02;
        display: flex;
        align-self: center;
        margin: 0 24px 0 0;
        .dln-icn {
            transform: rotate(-90deg);
            margin: 0 0 0 8px;
            font-size: 13px;
        }
    }
    .dln-breadCrumbSel {
        margin: 0 24px 0 0;
    }
}
.dln-savedRec {
    &--empty {
        .dln-savedRec {
            align-content: flex-start;

            & .dln-profileCard {
                margin: 0 0 32px 0;
            }
            &__title {
                @include big;
                @include fontBold;
                margin: 0 0 0;
            }
            &__subTitle {
                @include big;
                @include fontBold;
                font-size: 32px;
                margin: 0 0 8px;
            }
            &__content {
                & .dln-title {
                    margin: 0 0 32px 0;
                    line-height: normal;
                }
            }
            &__section {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;
                width: 100%;
                margin: 0 0 64px 0;

                &Title,
                & .dln-title {
                    display: block;
                    width: 100%;
                    height: auto;
                    line-height: 32px;
                    font-size: 32px;
                    @include fontBold;
                    margin: 0 0 0 0;
                    &--labeled {
                        display: flex;
                    }
                }

            }
            .dln-title {
                &+.dln-savedRec__paragraph {
                    margin-bottom: 0;
                }
            }
            &__paragraph {
                display: block;
                width: 100%;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: $brand-04;
                margin: 0 0 20px 0;

                &--info {
                    color: $brand-02;
                    margin: 0 0 32px 0;
                }
                &--noMarg {
                    margin: 0;
                }
            }
            &__underlinedText {
                color: $brand-02;
                text-decoration: underline;

                &:hover {
                    color: $brand-04;
                }
            }
            &__subtitle {
                display: block;
                width: 100%;
                height: auto;
                line-height: 20px;
                font-size: 13px;
                @include fontBold;
                // text-transform: uppercase;
            }
            .dln-tab__content {
                .dln-savedRec__title {
                    margin: 32px 0;
                    opacity: 0;
                    transition: opacity .3s ease .1s;
                }
                &--active {
                    .dln-savedRec__title {
                        opacity: 1;
                    }
                }
            }
            &__foot {
                text-align: center;
                width: 100%;
                margin: 0 0 32px;
                &--divider {
                    margin: 32px 0 32px;
                    border-top: 1px solid $ui-01;
                    text-align: right;
                    padding: 32px 0 0;
                }
                .dln-btn {
                    margin: 0;
                    width: auto;
                }
            }
            &--empty {
                .dln-savedRec__content {
                    & .dln-title {
                        margin: 0 0 28px 0;
                    }
                }
            }
        }
    }
}
.dln-categoryBox {
    width: 100%;
    max-width: 200px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    transition: .3s;
    padding: 10px;
    position: relative;

    &:hover {
        border-color: $brand-02;
    }
    &__imgWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 163px;
        background-color: $ui-03;
        position: relative;
        z-index: 1;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.04);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
        & > img {
            width: 100%;
            max-width: 166px;
            height: 145px;
            margin: 0 auto;
            object-fit: contain;
        }
    }
    &__title {
        @include small;
        @include fontBold;
        display: block;
        width: 100%;
        font-size: 14px;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 10px;
        margin: 8px 0 0;
    }
    a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
    }
}

@media only screen and (max-width:599px) {
    .dln-checkServiceBox {
        width: 100%;
        &__iconWrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 48px;
            height: 48px;
            margin: 0 0 10px 0;
        }
        &__icon {
            display: block;
        }
        &__text {
            text-align: left;
            margin: 0 auto 8px 0;
        }
        .dln-btn {
            margin: 0 auto 0 0;
            &__cardList {
                list-style: inside none;
                display: inline-flex;
                flex-wrap: wrap;
                width: auto;
                margin: 0;
            }
            &__title {
                text-align: left;
                margin: 0 auto 8px 0;
            }
            &__iconWrap {
                margin: 0 24px 0 0;
            }
        }
    }
}


@media only screen and (max-width:599px) {
    .dln-checkServiceBox {
        width: 100%;
        padding: 32px 24px;
        min-height: auto;
        &__content {
            width: calc(100% - 48px - 24px) !important;
            text-align: left;
        }
        &__text {
            text-align: left;
            margin: 0 auto 8px 0;
        }
        .dln-btn {
            margin: 0 auto 0 0;
        }
        &__title {
            text-align: left;
            margin: 0 auto 8px 0;
            width: 100%;
        }
        &__iconWrap {
            margin: 0 24px 0 0;
        }
    }
}
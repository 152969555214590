.dln-site-search {
    &.dln-page {
        // overflow: visible;
        // overflow-y: hidden;
        overflow: initial;
    }
    .dln-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        margin: 0 auto;
    }
    .dln-title {
        @include fontBold;
        font-stretch: normal;
        line-height: 1.25;
        font-size: 44px;
        font-style: normal;
        letter-spacing: normal;
        padding-bottom: 25px;
    }
    &__results {
        font-stretch: normal;
        display: block;
        width: 100%;
        line-height: 1.4;
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: normal;
        padding-top: 10px;
        padding-bottom: 42px;
    }
    &__no-results-paragraph {
        font-stretch: normal;
        line-height: 1.25;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        letter-spacing: normal;
        padding-bottom: 30px;
    }
    &__no-results-title {
        @include fontBold;
        font-stretch: normal;
        line-height: 1.25;
        font-size: 44px;
        font-style: normal;
        letter-spacing: normal;
        padding-bottom: 10px;
    }
    .dln-inputs {
        max-width: 589px;

        .dln-icn {
            color: black;
        }
    }
    .dln-tabs {
        margin-bottom: 35px;

        // &__links {
        //     font-stretch: normal;
        //     line-height: 1.4;
        //     font-size: 18px;
        //     font-style: normal;
        //     letter-spacing: normal;
        //     margin-right: 55px;
        // }
    }
    .simple-list {
        padding-top: 30px;
    }

    .dln-filters-bar {
        margin-bottom: 33px;
    }

    &__products {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;

        & .bra-prodTile {
            width: calc(25% - 15px);
            height: auto;
            margin: 0 20px 20px 0;

            &:nth-of-type(4n+4) {
                margin: 0 0 20px 0;
            }
        }
    }
}

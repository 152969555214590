@media only screen and (max-width:599px) {
    .dln-checkout {
        &--editing {
            .dln-checkout__formData {
                width: 100%;
                &:nth-child(odd),
                &:nth-child(even) {
                    margin-right: 0;
                }
            }

            .dln-btn--editing {
                width: 100%;
                margin-bottom: 32px;
            }

            .dln-accordion--payment {

                &.dln-accordion--editing {
                    .dln-accordion__content {
                        &--submit {
                            padding: 0 0 !important;
                        }
                        .dln-checkout__dataResult {
                            display: flex;
                            margin: 0 0 32px;
                            padding: 0;
                            .dln-btn {
                                width: 100%;
                                margin: 16px auto 0;
                            }
                        }
                    }
                    .dln-checkout__formField {
                        .dln-checkout__formData {
                            margin: 16px 0 32px;

                            &:nth-of-type(odd) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .dln-accordion.dln-accordion--payment.dln-accordion--editing.dln-accordion--open.dln-accordion--showInfo {
                padding: 0 20px;
                border-bottom: none;
            }

            .dln-checkout__content .dln-accordion--editing .dln-accordion__title:not(.dln-stepTitle) {
                flex-flow: column;
                align-items: flex-start;
                gap: 20px;
            }
    
        }
    }
}
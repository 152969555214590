.dln-checkout {
  align-content: flex-start;

  &__amazonpayDesc {
    margin: 20px 0 40px;
  }

  &__aside {
    .dln-orderSumm {
      &__itemDetails {
        .prices {
          display: flex;

          .realPrice {
            color: $brand-01;

            .dln-price--discounted {
              color: $brand-01;
            }
          }

          .dln-price--oldPrice {
            margin: 0 9px 0 0;
          }
        }
      }
    }
  }

  > .dln-wrapper {
    align-content: flex-start;
    padding-top: 48px;
    padding-bottom: 64px;
    width: auto;
  }

  #oneyPlaceholder {
    margin-top: 25px;
    margin-bottom: 35px;
  }

  .dln-title {
    margin: 0 0 24px 0;

    &.firstStep {
      text-align: center;
      padding: 0 200px;
      @media only screen and (max-width: 599px) {
        padding: 0;
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(100% - 387px - 20px);
    margin: 0 20px 0 0;

    .dln-checkout__form {
      .msg {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 30px;
        color: var(--brand-01);
        cursor: pointer;

        span {
          font-weight: 700;
          text-decoration: underline;
        }

        svg {
          color: var(--brand-01);
        }
      }

      .sectionTitle {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: 30px;
      }

      .dln-address_info {
        display: inline-flex;
        color: var(--brand-01);
        gap: 5px;
        margin-bottom: 40px;
        line-height: 10px;
        height: 40px;
        align-items: center;

        p {
          text-decoration: underline;
        }

        .dln-tooltip {
          display: flex;

          &__anchor {
            margin-left: 5px;
          }
        }
      }
    }

    .dln-address_verify,
    .dln-vatnumber_check,
    .dln-no_delivery {
      width: 100%;
      padding: 1em;
      color: var(--brand-04);
      border: 1px solid var(--support-04);
      background: var(--ui-03);

      p {
        display: flex;
        font-size: 0.9rem;

        &::before {
          color: var(--support-04);
          font-family: var(--fontNameIcons);
          margin: 0 0.5em 0 0;
          content: 'y';
        }
      }

      .--product {
        margin-top: 5px;
        font-size: 0.9rem;
        margin-left: 21px;

        &:first-of-type {
          margin-top: 10px;
        }
      }
    }

    .dln-address_verify {
      display: none;
      margin: 0.5em auto 1em;
    }

    .dln-vatnumber_check {
      display: inherit;
      margin: 0 auto 3em;

      &:not(.visible) {
        display: none !important;
      }
    }

    .dln-invoiceCheck {
      margin-bottom: 20px;
      width: 100%;
    }

    .dln-invoiceRadio {
      position: relative;
      display: flex;
      gap: 20px;
      margin: 25px 0 15px;

      .dln-radioBtn {
        .dln-radioBtn__lbl {
          height: 22px;
          padding-left: 31px;
          align-items: center;

          &:before {
            width: 22px;
            height: 22px;
            border: 1px solid var(--ui-02);
          }

          &:after {
            width: 14px;
            height: 14px;
            top: 5px;
            left: 5px;
          }
        }
      }
    }

    .dln-invoiceForm,
    .dln-guestChoiceForm {
      position: relative;
      width: 85%;

      > span {
        display: block;
        margin-bottom: 30px;
        font-size: REM(13px);
      }

      .row {
        display: flex;
        gap: 15px;
        margin-bottom: 30px;

        > div {
          display: flex;
          align-items: center;
          gap: 15px;
          width: 100%;
        }

        &.region {
          width: 50%;
        }

        i {
          color: var(--brand-01);
        }

        .dln-select {
          max-width: 100%;
        }

        .dln-inputs {
          margin-bottom: 0 !important;
        }
      }
    }

    &.stepOne {
      width: 100%;
      margin: 0;
      padding: 0 200px;
      @media only screen and (max-width: 599px) {
        padding: 0;
      }
    }

    .dln-accordion {
      &--payment {
        margin: 0 0 64px 0;
      }

      &__title {
        cursor: auto;
      }

      &--open {
        max-height: none;

        .dln-accordion__content {
          border-top: none;
        }

        .dln-accordion__title {
          padding: 22px 0 8px;

          &.dln-stepTitle {
            justify-content: space-between;

            .dln-mandatory {
              display: block;
              font-size: REM(15px);
            }
          }
        }

        .dln-accordion__subTitle {
          display: block;
        }

        .dln-accordion__titleLabel {
          display: inline-block;
        }
      }

      &__content {
        width: 100%;
        padding: 32px 0 64px;
      }

      &__title {
        @include cta;
        display: flex;
        align-items: center;
        height: auto;
        @include fontBold;
        padding: 22px 0;

        .dln-icn {
          height: auto;
          color: $support-02;
          margin: 0 10px 0 0;
        }

        .dln-btn {
          display: none;
          margin: 0 0 0 auto;

          .dln-icn {
            color: $brand-04;
            margin: 0 2px 0 0;
          }
        }
      }

      &__subTitle {
        display: none;
      }

      &--showInfo {
        max-height: none;

        .dln-checkout__dataResult {
          display: flex;

          .mailInfo {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 20px;
          }
        }

        .dln-accordion__content {
          display: none;
        }

        .dln-accordion__title {
          display: flex;
          color: $brand-04;
          padding: 22px 0 8px;

          .dln-icn {
            display: inline-flex;
            align-items: center;
          }

          .dln-btn {
            display: inline-block;
          }
        }

        .dln-mandatory {
          display: none;
        }
      }

      &__titleLabel {
        @include small;
        display: none;
        font-size: 10px;
        color: $brand-02;
        margin: 0 0 0 8px;

        .dln-icn {
          @include small;
          display: inline;
          font-size: 10px;
          color: $brand-02;
          margin: 0 0 0 0;
        }
      }
    }

    .dln-checkout__innerCol {
      width: calc(50% - 35px);
      margin: 0 70px 0 0;
      padding: 0 70px 0 0;
      border-right: 1px solid var(--ui-01);

      &:nth-child(2n + 2) {
        margin: 0;
        padding: 0;
        border: none;
      }
    }

    .dln-checkout__innerColTitle {
      @include mainMenu;
      width: 100%;
      @include fontBold;
      border-bottom: none;
      padding: 0 0 20px;
      margin: 0;
    }
  }

  .dln-divider {
    margin: 0 0 32px;
  }

  .dln-accordion--access-information {
    border-top: none;
    border-bottom: none;

    .dln-checkout__dataResult {
      .mailInfo {
        p {
          padding: 9px;
          &:nth-child(2) {
            background-color: $ui-05;
          }
        }
      }
    }

    &.dln-accordion--showInfo {
      .dln-checkout__dataResult {
        align-items: center;
      }
    }
  }

  .dln-accordion--shipping-information {
    .dln-checkout__paragraph--smaller {
      text-align: right;
    }

    .dln-accordion__content {
      .dln-checkout__form {
        > div {
          &:nth-last-child(1),
          &:nth-last-child(2),
          &:nth-last-child(3),
          &:nth-last-child(4),
          &:nth-last-child(6),
          &:nth-last-child(7) {
            @media only screen and (max-width: 599px) {
              width: 100% !important;
              margin: 0 0 30px 0;
            }

            width: 45% !important;
          }
        }
        .dln-address_info {
          height: 40px;
          align-items: center;
        }
      }
    }

    .dln-accordion--open {
      .dln-accordion__content {
        border-top-color: none;
      }
    }

    .dln-accordion__content {
      .dln-btn {
        max-width: 280px;
        margin: 32px 0 0;
      }
    }

    .dln-checkout__dataResult {
      margin: 20px 0 32px;
    }

    .dln-checkout__formData {
      border: 1px solid $ui-01;
      padding: 24px 16px;
    }

    .dln-checkout__dataResult {
      p.dln-checkout--mobileView {
        display: none;
      }
    }
  }

  .dln-accordion--shipping-method {
    &.dln-accordion--open {
      .dln-accordion__content {
        border-top-color: transparent;
        margin-top: 20px;
      }
    }

    .dln-checkout__formField {
      display: flex;
      flex-wrap: wrap;
      padding: 20px;
      margin: 0 0 24px;
      background-color: var(--ui-03);

      &__row {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &__radioWrapper {
        position: relative;

        .dln-radioBtn {
          &__lbl {
            padding-left: 35px;
            &::before {
              border: 1px solid var(--ui-02);
              width: 22px;
              height: 22px;
            }
            &::after {
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .dln-radioBtn__lbl:before {
        top: 36%;
      }

      // .dln-radioBtn__lbl:after {
      //   top: 53%;
      // }

      .dln-checkout__formLabel {
        @media only screen and (max-width: 599px) {
          line-height: 19px;
          font-size: REM(15px);
        }
        margin: 0 0 0 auto;

        &--small {
          width: 100%;
          padding: 10px 0 0 31px;
        }
      }

      .dln-checkout__formFree {
        @media only screen and (max-width: 599px) {
          line-height: 19px;
          font-size: REM(15px);
        }
        margin: 0 0 0 auto;
        color: $support-02;
        text-transform: uppercase;
      }
    }

    .dln-accordion__content {
      .dln-checkout__paragraph {
        margin: 16px 0 0;
      }

      .dln-btn {
        max-width: 280px;
        margin: 32px 0 0;
      }
    }

    .dln-checkout__dataResult {
      margin: 0 0 32px;
    }

    .dln-checkout__formData {
      border: 1px solid $ui-01;
      padding: 32px 20px;

      .dln-btn--editing {
        width: calc(50% - 16px);
      }
    }
  }

  .dln-accordion--payment {
    .dln-accordion__title {
      justify-content: space-between;
      color: var(--brand-04);
    }

    .dln-checkout__formField {
      &--billingAddress {
        .dln-checkbox {
          align-items: initial;
          min-height: 24px;

          &__wrap {
            padding-left: 24px;
          }

          &__inputPlaceholder {
            width: 24px;
            height: 24px;

            &:after {
              top: 0;
              left: 0;
              content: 'à';
              @include iconspseudoelements;
            }
          }
        }

        .dln-checkout__form {
          > div {
            &:nth-last-child(1),
            &:nth-last-child(2),
            &:nth-last-child(3),
            &:nth-last-child(4),
            &:nth-last-child(6),
            &:nth-last-child(7) {
              @media only screen and (max-width: 599px) {
                width: 100% !important;
                margin: 0 0 30px 0;
              }

              width: 45% !important;
            }
          }
        }
      }
    }

    &.dln-accordion--open {
      border-bottom: none;

      .amazonChange {
        display: flex;
      }

      .dln-accordion__content {
        border-top-color: transparent;
        margin-top: 30px;
      }

      .dln-checkout__formField.dln-checkout__formField--billingAddress {
        flex-flow: wrap;
        align-items: flex-start;
        margin: 12px 0 30px;
        padding: 0;

        .dln-checkout__formData {
          margin: 20px 0 0;
        }
      }

      .dln-checkout__form .dln-checkout__formField {
        background-color: var(--ui-03);

        .dln-radioBtn {
          &__lbl {
            height: 22px;
            line-height: 22px;
            padding-left: 35px;
            &::before {
              border: 1px solid var(--ui-02);
              width: 22px;
              height: 22px;
            }
            &::after {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }

    .amazonChange {
      display: none;
      gap: 5px;
      flex-direction: column;
      text-align: center;
      font-size: REM(16px);

      #changeButton2 {
        padding: 6px 12px;
        cursor: pointer;
        font-family: (--fontNameDelonghi);
        border: none;
        border-radius: 3px;
        background-image: radial-gradient(
          circle at 51% 50%,
          #f2d792,
          #eabf4b 20%
        );
      }
    }

    &.dln-accordion--showInfo {
      border-bottom: none;
    }

    .dln-accordion__content {
      padding: 0;

      .dln-checkout {
        &__paragraph {
          margin: 16px 0 0;
        }
      }

      .dln-btn {
        max-width: 380px;
        margin: 32px 0 0;
      }

      &--submit {
        display: flex;
        opacity: 1;
        padding: 0 0 32px;
        margin: 0;

        .dln-checkout__paragraph {
          margin: 0;
          a {
            @include fontBold;
          }
        }

        .dln-btn {
          margin: 24px 0 0;
        }
      }
    }

    .dln-checkout__formField {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 10px;
      padding: 20px;

      .dln-checkout__formGroup {
        margin: 0 10px 20px 0;
      }

      &--invoice {
        @media only screen and (max-width: 599px) {
          width: 100%;
        }
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-bottom: 0px;

        .dln-checkbox {
          @media only screen and (max-width: 599px) {
            margin-bottom: 18px !important;
          }
          margin-bottom: 27px !important;
          align-items: normal;
          min-height: 24px;

          &__wrap {
            padding-left: 0px;

            .dln-checkbox__label {
              padding-left: 25px;
            }
          }

          & span.dln-checkbox__inputPlaceholder {
            width: 22px;
            height: 22px;

            &:after {
              top: 0;
              left: 0;
              content: 'à';
              @include iconspseudoelements;
            }
          }
        }

        .dln-checkout__paragraph {
          color: #000000;
        }

        > .dln-divider {
          margin: 10px 0 32px;
        }

        .dln-checkout__paragraph {
          margin: 0 0 16px;
        }

        .dln-checkout__formField {
          align-items: start;
          padding: 0;
          margin-bottom: 0;

          &--fiscalCode__row {
            padding: 0;
            margin-bottom: 0;
            width: 40%;
            align-self: flex-start;
          }

          &--fiscalCode__ita {
            display: flex;
            flex-direction: column;
            padding: 0;

            .dln-checkout {
              &__generalities,
              &__birthPlace {
                @media only screen and (max-width: 599px) {
                  display: inline-block;
                  width: 100%;
                }
                display: flex;
                width: 75%;
              }

              &__birthPlace {
                margin-bottom: 32px;
              }

              &__third {
                @media only screen and (max-width: 599px) {
                  display: inline-block;
                  width: 100%;
                  margin-bottom: 32px;
                }
                display: flex;
                width: 50%;

                & div:nth-child(2) {
                  & label {
                    top: 12px;
                  }
                }
              }

              &__fiscalCode {
                @media only screen and (max-width: 599px) {
                  width: 100%;
                }
                width: 27%;
              }
            }
          }

          .dln-inputs {
            margin: 0 20px 32px 0;
          }

          &--fiscalCode {
            margin: 0;
          }
        }

        .dln-checkbox__input:disabled {
          pointer-events: none;

          & ~ label.dln-checkbox__label,
          & ~ dln-checkbox__inputPlaceholder {
            opacity: 0.6;
            pointer-events: none;
          }
        }
      }

      .dln-inputs {
        &--securityCode {
          max-width: 180px;
        }
      }

      .dln-btn--vatnumber_check {
        min-width: auto;
        max-width: 25%;

        @media only screen and (max-width: map-get($grid-breakpoints, lg)) {
          max-width: 100%;
        }
      }

      .dln-btn:not(.dln-btn--vatnumber_check) {
        margin: 0;
      }

      .dln-checkout__formLabel {
        margin: 0 0 0 auto;

        .dln-checkServiceBox__cardImg {
          max-width: 40px;
          height: 26px;
        }
      }

      .dln-checkout__formData {
        margin: 16px 0 0 24px;
      }
    }

    .dln-checkout__dataResult {
      margin: 0 0 32px;

      .dln-checkout__defaultCard {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @media only screen and (max-width: 599px) {
          justify-content: space-between;

          .dln-checkout__formData {
            width: 80% !important;
          }
        }

        .dln-btn {
          color: var(--support-02);
          height: fit-content;
          &__lbl {
            font-weight: 400;
          }
        }
      }

      .dln-checkServiceBox__cardItem {
        margin: 0 0 16px;
      }

      .dln-checkout__formField {
        margin: 32px 0 0;
        background-color: var(--ui-03);

        .dln-inputs {
          margin: 0 24px 0 0;
        }
      }
    }

    .dln-checkout__formData {
      border: 1px solid $ui-01;
      padding: 24px 16px;
    }

    .dln-checkServiceBox__cardItem {
      width: 46px;
      height: 30px;
      border: 1px solid $ui-01;

      .dln-checkServiceBox__cardImg {
        display: block;
        width: auto;
        height: auto;
        margin: 0 auto;
      }
    }

    .-billingAddress {
      > .dln-checkout__formData {
        &--hidden {
          display: none;
        }
      }
    }

    .dln-checkout__billingAddressForm {
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      width: 100%;
      height: auto;
      padding-top: 32px;

      &--hidden {
        display: none;
      }

      & .dln-inputs {
        margin-bottom: 32px;
      }
    }
  }

  &__formLabel {
    @include small;
    color: $brand-04;

    &--small {
      color: $ui-04;
    }
  }

  &__formGroup {
    width: 100%;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    div[class*='--oneThird'],
    .dln-inputs--selectInside,
    > .dln-select {
      width: calc(33.333% - 13.333px);
      max-width: none;
      margin: 0 20px 21px 0;
    }

    div[class*='--twoThird'] {
      width: calc(66.666% - 7px);
      margin: 0 20px 32px 0;

      + div[class*='--oneThird'] {
        width: calc(33.333% - 13.333px);
      }
    }

    div[class*='--last'] {
      margin: 0 0 32px 0;
    }

    div[class*='--country'] {
      margin-left: 20px;
    }
    div[class*='--jpFormat'] {
      margin-left: 0;
      width: 45%;
    }
    div[class*='--uppercase'] {
      input {
        text-transform: uppercase;
      }
    }
  }

  &__formField {
    width: 100%;
  }

  &__paragraph {
    @include small;
    width: 100%;
    color: $brand-02;

    a {
      color: $brand-02;
    }

    &--smaller {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .dln-loginForm {
    padding: 0 32px 0 0;

    &__paragraph {
      &--mainTitle {
        text-align: left;
      }
    }

    &__formFoot {
      margin: 32px 0 0;
    }

    & + .dln-btn {
      width: calc(100% - 32px);
      margin: 32px 0 0;
    }
  }

  .dln-newUserForm {
    padding: 0 32px 0 0;
  }

  &__dataResult {
    display: none;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 16px;

    > p {
      @include small;
      display: inline-block;
      width: 100%;
      max-width: calc(100% - 50px);
      margin: 8px 0 16px;
    }

    span {
      margin: 0 0 4px auto;
    }

    a {
      color: $brand-04;
      text-decoration: none;
      overflow-wrap: anywhere;

      // &:not(.dln-btn--link) {
      //   @include fontBold;
      // }
    }

    .dln-chips {
      max-width: 380px;
      margin: 16px 0 0 0;

      & span {
        margin: 0 0;
      }
    }
  }

  &__formValue {
    @include small;
    display: block;
    width: 100%;
    color: $brand-04;
  }

  &__formData {
    width: 100%;
    max-width: 380px;

    p {
      margin: 0 0 5px;

      &:last-child {
        margin: 0 0;
      }
    }
  }

  &__title {
    display: block;
    width: 100%;
    height: auto;
    line-height: 20px;
    font-size: 17px;
    color: $brand-04;
  }

  &__subtitle {
    display: block;
    width: 100%;
    height: auto;
    line-height: 20px;
    font-size: 13px;
    color: $brand-04;
  }

  &__infoText {
    display: block;
    width: 100%;
    height: auto;
    line-height: 20px;
    font-size: 13px;
    color: $brand-02;
  }

  &__services {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;

    .dln-checkServiceBox {
      width: calc(33.33% - 13.33px);
      margin: 0 20px 0 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__aside {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 387px;

    &--secondary {
      display: none;
      width: 100%;
    }
  }

  &__noticeBox {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 387px;
    text-align: center;
    padding: 40px 0 32px;
    margin: 0 auto;

    &Wrap {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    p {
      @include cta;
      width: 100%;
      color: $brand-02;
      margin: 0 0 0;
    }

    .dln-noticeBox {
      width: 50%;
      position: relative;

      &:nth-child(even) {
        &::after {
          content: '';
          width: 1px;
          height: calc(100% - 48px);
          background-color: $ui-01;
          position: absolute;
          top: 24px;
          right: 0;
        }
      }

      &__icon {
        height: 58px;
      }
    }
  }
  .full-space {
    width: 100%;
    @media screen and (min-width:900px) {
      width: calc(100% - 20px);
    }
  }

  .dln-giftNoteMessage {
    position: relative;
    width: 100%;

    &__drawer {
      transform: scaleY(0);
      transform-origin: top;
      transition: all 0.3s ease;
      max-height: 0;
      overflow: hidden;

      &.--active {
        transform: scaleY(1);
        max-height: 1000px;

        .dln-giftNoteMessage__counter {
          opacity: 1;
        }
      }

      .dln-inputs {
        margin-bottom: 0;
      }

      .dln-textarea__input {
        height: unset;
      }
    }

    &__counter {
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: var(--brand-02);
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
    }
  }
}

form.dln-checkout__content {
  width: 100%;
  display: block;

  iframe {
    width: 100%;
  }
}

.dln-divider {
  width: 100%;
  height: 1px;
  background-color: $ui-01;
  clear: both;
  margin: 0 0 32px;
}

/* BRAUN STYLE*/
.dln-braun {
  .dln-checkout .dln-accordion__title > span {
    font-weight: 700;
  }

  .dln-checkout__content .dln-checkout__form .dln-address_info {
    line-height: REM(18px);
    color: var(--support-02);
    height: 40px;
    align-items: center;
    margin-bottom: 0;
  }

  .dln-checkout {
    .dln-accordion--shipping-method
      .dln-checkout__formField
      .dln-radioBtn__lbl:after {
      background-color: $support-02;
      top: 60%;
    }

    &__formDataHead {
      .dln-btn--link {
        color: $support-02;
      }
    }

    &__content {
      .dln-checkout__form {
        .msg {
          color: var(--support-02);

          svg {
            color: var(--support-02);
          }
        }
      }

      .dln-invoiceForm,
      .dln-guestChoiceForm {
        .row i {
          color: var(--support-02);
        }
      }

      .dln-accordion {
        &__title {
          color: var(--brand-04);

          span {
            @include fontBold;

            &.dln-mandatory {
              font-weight: 400;
            }
          }

          button span {
            font-weight: normal;
          }
        }
      }
    }

    .dln-accordion--showInfo .dln-accordion__title .dln-btn {
      color: $support-02;
      font-weight: 400;

      i {
        color: $support-02;
      }
    }

    .dln-radioBtn__lbl {
      color: var(--brand-04);
    }

    .dln-checkout__formField {
      .dln-checkout__formLabel--small {
        padding-left: 35px;
        color: var(--brand-04);
      }

      &.isChecked {
        border: 1px solid $support-02;
      }
    }

    .dln-checkbox
      .dln-checkbox__input:checked
      + .dln-checkbox__inputPlaceholder {
      border-color: $support-02;
      border-radius: 2px;

      &:after {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        font-size: 11px;
        background-color: $support-02;
        color: var(--brand-03);
      }
    }

    .dln-giftNoteMessage {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .dln-checkbox__wrap {
        padding-left: 0 !important;
      }

      .dln-checkbox__inputPlaceholder {
        width: 24px !important;
        height: 24px !important;
        position: relative !important;

        &:after {
          top: 0 !important;
          left: 0 !important;
          content: '\E0' !important;
          font-family: var(--fontNameIcons) !important;
        }
      }
    }
  }
}

// Express checkout with active credit card
.dln-accordion.dln-accordion--payment.dln-accordion--showInfo:not(.dln-accordion--editing) {
  .dln-checkout__formField--billingAddress {
    flex-wrap: wrap !important;
    padding: 0 !important;
  }

  .dln-invoiceRadio,
  .dln-checkout__formField--billingAddress,
  .dln-checkout__formField--invoice,
  .dln-accordion__subTitle,
  .dln-accordion__content--submit {
    @media only screen and (max-width: 599px) {
      padding: 0 20px !important;
    }
  }

  .dln-checkout__formData {
    @media only screen and (max-width: 599px) {
      margin: 0 0 20px 0 !important;
    }
  }

  .dln-btn--newBillingAddress,
  .dln-btn--placeOrder {
    @media only screen and (max-width: 599px) {
      width: 100% !important;
    }
  }
}

.dln-checkout {
  .dln-orderSumm__contentRow--taxes,
  .dln-orderSumm__contentRow--shipping {
    .dln-tooltip {
      & .dln-tooltip__container {
        width: 210px;
        min-width: unset;
      }

      & .dln-tooltip__content {
        width: calc(100% - 9px);
        text-align: left;
      }

      & .dln-tooltip__text {
        max-width: calc(100% - 24px);
      }
    }
  }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-order {
        &--return {
            & .dln-order__aside {
                width: 100%;
                margin-right: 0;

                & .dln-profileCard {
                    margin-bottom: 30px;
                }
            }
            & .dln-order__content {
                width: 100%;
            }
        }
    }
}

.dln-delonghi {
  & .dln-loginForm {
    &__content {
      & .dln-loginForm__paragraph {
        line-height: 24px;
        font-size: REM(16px);
        margin-bottom: 25px;
      }
    }
    &__btnGroup {
      & .dln-btn--social {
        width: calc(50% - 6px);
        min-width: initial;
        box-sizing: border-box;

        &-fb {
          margin: 0 12px 20px 0;
        }
        &-inst {
          margin: 0 0 20px 0;
        }
        &-google {
          margin: 0 12px 0 0;
        }
        &-tw {
          margin: 0;
        }
      }
    }
    &__form {
      & .dln-inputs--checkoutLogin {
        margin-bottom: 21px;
      }
      & .dln-checkbox {
        margin: 0 !important;

        &__wrap {
          max-width: none;
        }
        &__inputPlaceholder {
          border-color: $ui-02;
        }
        &__label {
          color: $ui-02;
        }
        & + .dln-btn {
          @include fontSemibold;
        }
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-loginForm {
      &__content {
        & .dln-loginForm__paragraph {
          line-height: 23px;
          font-size: REM(15px);
        }
      }
      &__paragraph {
        margin-bottom: 16px !important;
      }
      &__section {
        &--main {
          & .dln-loginForm__btnGroup {
            & .dln-btn--social {
              min-width: initial;

              &-fb,
              &-google {
                margin: 0 12px 12px 0;
              }
              &-inst,
              &-tw {
                margin: 0 0 12px 0;
              }
            }
          }
          & .dln-loginForm__formContainer {
            margin-top: 20px;

            & .dln-loginForm__paragraph {
              &--divider {
                font-size: REM(14px);
                color: $ui-02;
                margin-bottom: 34px !important;
              }
            }
            & .dln-loginForm__form {
              & .dln-inputs--checkoutLogin {
                margin-bottom: 14px;
              }
            }
          }
          & .dln-newUserForm__section {
            margin-top: 30px;
          }
        }
      }
    }
  }
}

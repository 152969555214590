@media only screen and (max-width:599px) {
    .dln-savedCart {

        &--empty {
            .dln-savedCart__content {
                margin: 0 0 40px;
                & .dln-title {
                    margin: 32px 0;
                }
            }
            .dln-savedCart__subTitle {
                font-size: 21px;
            }
            .dln-savedCart__paragraph {
                font-size: 14px;
            }
        }
    }

}
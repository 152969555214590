.dln-kenwood {
    .dln-carousel--mobileOnly,
    .dln-carousel {
        &.swiper-container {
            .swiper-slide {
                overflow: hidden;
            }
            .swiper-pagination {
                width: 60%;
                padding: 0;
                margin: 0 auto;
                height: 3px;
                background-color: $ui-01;
                display: flex;
                display: none;
                &-progressbar {
                    margin: 32px auto 0;
                }
                &-bullet {
                    height: 3px;
                    margin: 0 8px 0 0;
                    cursor: pointer;
                    display: inline-block;
                    border-radius: 0;
                    background-color: $brand-01;
                    transition: width .3s;

                    &:first-child {
                        margin: 0;
                    }
                    &-active {
                        width: 26px;
                        border-radius: 0;
                    }
                }
            }
            .swiper-scrollbar {
                width: 80%;
                max-width: 400px;
                padding: 0;
                margin: 32px auto 0;
                height: 3px;
                background-color: $ui-01;
                display: flex;
                .swiper-scrollbar-drag {
                    height: 3px;
                    background-color: $brand-01;
                }
            }
            & .swiper-button-next,
            & .swiper-button-prev {
                .dln-icn {
                    color: $brand-01;
                    font-size: 24px;
                }
            }
        }
    }
    
    .dln-page {
        &--myArea {
            @include makePageKenwood;
            .dln-wrapper {
                .dln-page__content {
                    @include makeContentKenwood;
                }
                .dln-page__aside {
                    @include makeAsideKenwood;
                }
            }
        }
    }
    .dln-page {
        .dln-title {
            @include fontExtra;
            text-align: center;
            font-size: 38px;
            line-height: 46px;
        }
        .dln-breadcrumbs {
            a {
                color: $brand-04;
                font-size: 12px;
            }
        }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        .dln-page {
            .dln-title {
                font-size: 32px;
                line-height: 40px;
                margin: 30px auto 20px;
                padding: 0;
            }
            /* .dln-wrapper {
                >div[class$="__content"] {
                    width: 100%;
                }
                >div[class$="__aside"] {
                    width: 100%;
                    margin: 0;
                }
            } */
        }
    }

    @media only screen and (max-width:map-get($grid-breakpoints, md)) {
      .dln-page {
          &--myArea {
            .dln-vertMenu:not(.dln-vertMenu--open) {
              margin: 0;
            }
          }
      }
    }
    
    /* @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
        .dln-page {
            .dln-wrapper {
                >div[class$="__content"] {
                    width: 100%;
                }
                >div[class$="__aside"] {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    } */
}

@media only screen and (min-width: map-get($grid-breakpoints, xl)) {
    .dln-wrapper {
        max-width: calc(#{map-get($grid-breakpoints, xl)} + 40px);
    }
}

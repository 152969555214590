.dln-kenwood {
    .dln-registration {
        &__subtitle {
            font-size: 20px;
            line-height: 24px;
        }
        &__paragraph {
            font-size: 14px;
            line-height: 20px;
        }
        &__btnGroup {
            border-bottom: 1px solid $ui-01;
            padding-bottom: 24px;
        }
        .dln-title {
            margin-top: 30px;
        }
        &__section {
            .dln-registration__subtitle {
                &+.dln-registration__paragraph {
                    margin-bottom: 30px;
                }
            }
        }
        &__form {
            border-top: 0;
            margin-top: 0;

            .dln-registration__paragraph {
                font-size: 14px;
                margin: 40px auto;

                &--secondary {
                    font-size: 12px;
                }
            }
            .dln-registrationForm {
                &__formContainer {
                    .dln-select,
                    .dln-inputs {
                        margin-bottom: 40px;
                    }

                    .dln-select__lbl {
                        line-height: 16px;
                    }

                    .dln-inputs__extraLbl {
                        top: 45px;
                        bottom: auto;
                        color: $ui-07;
                        line-height: 14px;
                    }
                }

                &__btnGroup {
                    border-bottom: 1px solid $ui-01;
                    padding-bottom: 24px;
                }

                &__form {
                    border-top: 0;
                    margin-top: 0;

                    .dln-checkbox {
                        margin: 24px auto;

                        &+.dln-registrationForm__paragraph.dln-registrationForm__paragraph--secondary {
                            a {
                                color: $ui-05;
                                text-decoration: underline;
                            }
                        }
                    }
                }
                &__paragraph {
                    &.dln-registrationForm__paragraph--secondary {
                        font-size: 12px;
                        margin: 0px auto 16px auto;
                        line-height: 16px;
                    }

                    a {
                        color: $brand-01;
                        text-decoration: none;
                    }
                }


                &__formOptions {
                    color: $ui-05;

                    .dln-registrationForm__subtitle {
                        font-size: 12px;
                        margin-bottom: 0px;
                        @include fontBold;
                    }
                    .dln-registrationForm__paragraph {
                        font-size: 12px;
                        margin-bottom: 16px;
                        color: $ui-05;
                    }

                    .dln-radioBtn {
                        margin-bottom: 24px;
                    }
                }

                &__formFoot {
                    margin-top: 20px;
                    .dln-btn {
                        width: 160px;
                    }
                    .dln-registrationForm__paragraph.dln-registrationForm__paragraph--secondary {
                        margin: 32px auto;

                        @media only screen and (max-width:599px) {
                            font-size: 14px;
                        }
                    }

                    .dln-registrationForm__paragraph.dln-registrationForm__paragraph--secondary {
                        margin: 50px 0 30px;
                    }
                }
            }
        }

        @media only screen and (max-width:599px) {
            .dln-breadcrumbs {
                margin-bottom: 0;
            }
            .dln-title {
                display: block;
                margin-top: 20px;
                margin-bottom: 14px;
            }

            .dln-registration__subtitle {
                display: none;
            }

            .dln-registration__paragraph {
                margin-bottom: 30px;
                text-align: center;
                line-height: 16px;
            }

            .dln-registrationForm__paragraph--secondary,
            .dln-registrationForm__formOptions .dln-registrationForm__subtitle,
            .dln-registrationForm__formOptions .dln-registrationForm__paragraph {
                color: $ui-08;
                line-height: 16px;
            }

            &__form {
                .dln-registrationForm__formContainer {
                    .dln-select {
                        width: 100%;
                        max-width: 100%;
                        margin-right: 0;
                    }
                    .dln-checkbox {
                        margin-top: 10px;
                    }
                    .dln-btn {
                        width: 100%;
                    }
                    .dln-inputs--extralabel {
                        margin-bottom: 70px;
                    }
                    .dln-inputs__extraLbl {
                        line-height: 16px;
                    }
                }
                .dln-registrationForm__formFoot {
                    margin-top: 20px;
                    .dln-registrationForm__paragraph.dln-registrationForm__paragraph--secondary {
                        margin: 50px 0 30px;
                    }
                }
            }
        }
    }
}
.dln-search-results {
    margin-bottom: 20px;
    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-left: -20px;

        .dln-search-result {
            margin: 20px 20px 0;
            width: calc(50% - 40px);
        }
    }
    &__view-all {
        display: inline-block;
        line-height: 1;
        color: $brand-02;
        font-size: 12px;
        margin-top: 15px;
    }
}
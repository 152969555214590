@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
  .dln-cart__aside {
    #AmazonPayButton {
      margin: 10px auto 0;
    } 
  }

  .dln-orderSumm__contentRow--taxes,
  .dln-orderSumm__contentRow--shipping {
    .dln-tooltip {
      & .dln-tooltip__container {
          width:135px;
      }

      & .dln-tooltip__content {
          width:100%;
          text-align: left;
      }

      & .dln-tooltip__text {
          max-width: none;
      }
    }
  }
}
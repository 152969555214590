.dln-wishlist--public {
	.dln-wrapper {
		margin-right: auto;
		margin-left: auto;
	}
	.dln-wishlist__content {
		width: 100%;
        // max-width: calc(100% - 464px);
        max-width: 793px;
        margin: 48px auto 268px auto;

		@media only screen and (max-width: 1200px) and (min-width: 901px) {
			max-width: 100%;
		}
		.dln-title {
			margin-bottom: 29px;
		}
		.dln-wishlist__signInNotice {
			margin-bottom: 53px;
			font-size: 14px;
			line-height: 20px;
		}
		.dln-wishlist__productWrap {
			padding: 0;
            border: 0;

			.dln-wishProd {
                // width: calc(50% - 7.5px);
                margin: 0;
                width: 50%;
                box-sizing: border-box;
                margin-bottom: 24px;

                &__data {
                    & .dln-btn.dln-btn--link {
                        font-size: 13px;
                    }
                    & .dln-btn--stroked {
                        width: 122px;
                    }
                    & .dln-btnTextIcon {
                        display: inline-flex;
                        width: 122px;

                        &__wrap {
                            margin: 0 auto;
                        }
                        &__iconWrap {
                            width: 16px;

                            // & .dln-icn {
                            //     width: 12px;
                            // }
                        }
                        &__text {
                            font-size: 13px;
                            max-width: none;
                            margin-left: 5px;
                        }
                    }
                }
			}
		}
	}

	// @media only screen and (max-width: 1366px) {
	// 	.dln-wishlist__content {
	// 		max-width: 100%;

	// 		.dln-wishlist__productWrap {
	// 			.dln-wishProd {
	// 				width: calc(50% - 7.5px);
	// 			}
	// 		}
	// 	}
	// }
	@media only screen and (max-width: 900px) and (min-width: 599px) {
		.dln-title {
			margin: 32px 0;
			margin-bottom: 30px;
		}
	}
}

.dln-delonghi {
  .dln-search-result {
    &__content{
      position: relative;
    }

    &__itemPic {
      max-width: 124px;
    }
    &__category {
      @include fontMedium;
      text-transform: uppercase;
      color: var(--brand-07);
      font-size: REM(12px);
      line-height: 1;
    }
    
    &__detailsTitle {
      @include fontMedium;
      color: $brand-04;
      font-size: REM(20px);
      line-height: 1;
      margin-bottom: 5px;
    }

    &__modelNumber{
      @include fontMedium;
      color: var(--brand-07);
      font-size: REM(12px);
      line-height: 1;
    }

    & .dln-ratingStars .dln-icn {
      width: 15px;
      height: 15px;

      &:before {
        font-size: REM(15px);
      }
    }
    &__priceWrapper {
      margin-top: 7px;

      .dln-price {
        font-size: REM(16px);
        line-height: 1.13;
        color: $ui-02;

        & + .dln-price {
          margin-right: 0;
        }
        &:after {
          background: $ui-02;
        }
        &--discounted {
          color: $brand-04;
          font-size: REM(19px);
          line-height: 0.95;
        }
      }
    }

    &__bv-review {
      transform: scale(0.8);
      transform-origin: left center;
    }
  }
}

.dln-kenwood {
    .dln-quickLinks--slider {
        justify-content: center;

        & .dln-wrapper {
            & .dln-carousel {
                height: auto;

                &.swiper-container-horizontal {
                    .swiper-slide {
                        .dln-quickLink {
                            border: 0
                        }
                    }
                }
            }
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        align-items: stretch;
                        .dln-quickLink {
                            min-height: none;
                            height: auto;
                        }
                    }
                }
            }
            & .dln-quickLinks__title {
                @include fontSemibold;
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 12px;

                @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
                    @include fontSemibold;
                    font-size: 18px;
                    line-height: 22px;
                }
            }
            & .dln-quickLink {
                border-bottom: none;
                padding-top: 0;
                padding-bottom: 0;
                padding: 16px 8px 16px 8px;

                @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
                    border: 0;
                    padding-bottom: 8px;
                }

                &__link {
                    @include fontMedium;
                }
            }
        }
    }
}
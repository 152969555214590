.dln-braun .dln-ordFeatSlider,
.dln-delonghi .dln-ordFeatSlider {
    display: none;
}

.dln-kenwood {
    & .dln-ordFeatSlider {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        max-width: 794px;
        // border-top: 1px solid $ui-01;
        padding: 30px calc((100% - 794px) / 2);
        box-sizing: content-box;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 100%;
            max-width: 1200px;
            height: 1px;
            background-color: $ui-01;
            transform: translateX(-50%);
            position: absolute;
            top: -1px;
            left: 50%;
        }
        &__swiper {
            width: 100%;
        }
        &__wrapper {
            justify-content: space-between;
            width: 100%;
        }
        &__slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 33.33%;
            max-width: 200px;
            box-sizing: border-box;
            padding: 0 8px;
        }
        &__imgWrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            margin-bottom: 5px;

            & > .dln-icn {
                display: inline-block;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-size: 40px;
            }
        }
        &__title {
            @include fontRoman;
            display: block;
            width: 100%;
            line-height: 22px;
            font-size: 14px;
            text-align: center;
        }
        &__text {
            @include fontLight;
            display: block;
            width: 100%;
            line-height: 22px;
            font-size: 13px;
            text-align: center;
        }
        &__scrollbar {
            display: none;
        }
    }
}

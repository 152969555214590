.dln-kenwood {
    & .dln-registration.dln-return-authorization {
        & .dln-wrapper {
            &:first-child {
                padding-top: 30px;
                padding-bottom: 100px;
            }
        }
        & .dln-registration__section {
            box-sizing: content-box;

            & .dln-registration__subtitle {
                @include fontSemibold;
                line-height: 22px;
                font-size: 16px;
                margin-bottom: 10px;
            }
            & .dln-registration__paragraph {
                line-height: 20px;
            }
            & > .dln-btn,
            & .dln-registration__foot .dln-btn {
                width: 100%;
            }
            &--main {
                width: calc(50% - 24px);
                max-width: 358px;
                border-right: 0;
                padding: 0 24px 0 calc(50% - 24px - 358px);
            }
            &:not(.dln-registration__section--main) {
                width: calc(50% - 24px);
                max-width: 358px;
                padding: 0 calc(50% - 24px - 358px) 0 24px;

                & .dln-inputs {
                    & + .dln-inputs {
                        margin-bottom: 30px !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-registration.dln-return-authorization {
            & .dln-wrapper {
                &:first-child {
                    padding-bottom: 0;

                    & .dln-title {
                        margin-top: 0;
                        margin-bottom: 20px;
                    }
                }
            }
            & .dln-registration__section {
                border-top: none;

                & .dln-registration__subtitle {
                    display: block;
                    width: 100%;
                    text-align: center;
                    margin-bottom: 12px;
                }
                &--main {
                    width: 100%;
                    max-width: none;
                    padding: 0;
                }
                &:not(.dln-registration__section--main) {
                    width: 100%;
                    max-width: none;
                    padding: 0;
                    margin-top: 60px;

                    & .dln-registration__paragraph {
                        margin-bottom: 20px;
                    }
                }
            }
            & .dln-ordFeatSlider {
                margin-top: 70px;
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-registration.dln-return-authorization {
            & .dln-ordFeatSlider {
                margin-top: 0;
            }
        }
    }
}

.dln-btn {
    @include cta;
    @include fontBold;
    outline: none;
    width: auto;
    line-height: 1;
    font-size: 14px;
    color: $brand-03;
    text-align: center;
    /* reset */
    border: none;
    background-color: $brand-01;
    box-shadow: none;
    transition: all 0.3s linear;
    padding: 8px 16px 8px 16px;
    // margin: 10px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    overflow: hidden;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    &--link {
        font-weight: 400;
        color: $brand-04;
        color: $brand-04;
        text-decoration: underline;
        background-color: transparent;
        padding: 0;
        padding: 0;
        border: none;

        &.dln-btn--icon {
            text-decoration: none;
        }
        .dln-icn {
            font-size: inherit;
            text-decoration: none;
        }
        .dln-btn__lbl {
            text-decoration: underline;
        }
    }
    &:not(.dln-btn--link) {
        width: 100%;
        height: 40px;
        /* line-height: 17px; */
        /*  padding-top: 13px; */
        text-decoration: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: 20px;
        overflow: hidden;

        &.dln-btn--secondary {
            color: $brand-03;
            background-color: $brand-02;
        }
        &.dln-btn--disabled {
            color: $brand-03;
            background-color: $ui-02;
        }
    }
    &:hover {
        opacity: 0.8;
    }
    &:active {
        opacity: .7;
    }

    &--secondary,
    &--secondary.dln-btn--link {
        color: $brand-02;
    }
    &--disabled {
        color: $ui-02;
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
    }
    &--brand-03 {
        color: $brand-04;
        background-color: $brand-03;
    }
    &--stroked {
        color: $brand-01;
        border: 1px solid $brand-01;
        background-color: $brand-03;
    }
    &--light {
        color: $brand-02;
        border: 1px solid $ui-01;
        background-color: transparent;
        .dln-icn {
            font-size: 16px;
            height: auto;
            color: $ui-01;
        }
    }
    &--validate {
        .dln-icn {
            color: $support-02;
        }
    }
    &__lbl {
        display: inline-block;
        // height: 12px;
        // line-height: 12px;
        // padding: 4px 0 0 0;
        margin: 0 auto 0;
    }
    &--social {
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 10px 16px 10px 16px;
        position: relative;

        & .dln-icn {
            width: 18px;
            height: 18px;
            line-height: 18px;
            font-size: 16px;
            color: $brand-03;
            position: absolute;
            top: calc(50% - 9px);
            left: 16px;

            &::before {
                background-size: auto 90%;
            }
        }
        &.dln-btn--social-fb {
            background-color: #3B5998;
        }
        &.dln-btn--social-tw {
            background-color: #1DA1F2;
        }
        &.dln-btn--social-inst {
            background-color: #FB3958;
        }
        &.dln-btn--social-google {
            color: $brand-04;
            border: 1px solid $ui-02;
            background-color: $brand-03;

            & .dln-btn__icon {
                position: absolute;
                top: calc(50% - 8px);
                left: 16px;
            }
        }
        @media (-ms-high-contrast: none) {
            & .dln-btn__lbl {
                margin: 0;
            }
        }
    }
    &--action-button {
        & .dln-icn {
            font-size: 18px;
            color: $ui-02;
        }
        &.dln-btn--action-done {
            color: $ui-02;
            border: 0;
            animation: smoothFadeInAndScale .5s forwards;
        }
    }
    &--centeredIcn {
        .dln-icn {
            font-size: 16px;
            line-height: 16px;
            color: $brand-03;
            margin: 0 auto;
            position: relative;
            top: auto;
            left: auto;
        }
        .dln-btn__icon {
            margin: 0 auto;
            position: relative !important;
            top: auto !important;
            left: auto !important;
        }
    }
    &--email-icn {
        .dln-icn {
            font-size: 26px;
            line-height: 26px;
        }
    }
    &--paypal {
        display: flex;
        align-items: center;
        height: 32px !important;
        color: $brand-04;
        background-color: $support-03;
        padding: 4px 0;
        .dln-btn__icon {
            width: auto;
            height: 85%;
            margin: 0 auto 0 0;
        }
        .dln-btn__lbl {
            order: -1;
            font-size: 9px;
            margin: 0 8px 0 auto;
        }
    }
    &--support01 {
        border-color: $support-01;
        .dln-icn {
            color: $support-01;
        }
    }
    &--support02 {
        border-color: $support-02;
        .dln-icn {
            color: $support-02;
        }
    }
    &--vatnumber_check {
        margin: 0 0 30px 0;
    }
}


@media only screen and (max-width:599px) {
    .dln-btn {
        &--action-button {
            &.dln-btn--action-done {
                display: block;
                width: 100% !important;
            }
        }
    }
}

@import "./_buttons-kenwood";
@import "./_buttons-delonghi";

@media only screen and (max-width:599px) {
    .dln-changePssw {
        .dln-profileCard {
            display: none;
        }
        .dln-breadcrumbs {
            margin: 16px 0;
        }
        &__content {
            & .dln-title {
                line-height: normal;
                margin: 0 0 24px;
            }
        }
        &__section {
            & .dln-title {
                font-size: 21px;
                line-height: 24px;
                margin: 0 0 24px;
            }
        }
        &__field {
            margin: 0 0;
            &>.dln-inputs,
            &>.dln-select {
                width: 100%;
                margin: 0 0 24px 0;
                max-width: none;
                &:nth-child(2n+2) {
                    margin: 0 0 24px;
                }
            }
        }
    }
}
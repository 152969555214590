@media only screen and (max-width: 599px) {
  .dln-cartItem {
    padding: 32px 20px;
    margin-bottom: 30px;

    &__chips,
    &__price,
    &__quantity,
    &__accessories,
    &__warranty {
      width: 100%;
    }
    &__picture {
      width: 124px;
      max-height: 100px;
    }

    &__title {
      @include mainMenu;
      width: 90%;
    }

    &__price {
      margin: 16px 0 16px;
      font-size: 13px;
      flex-direction: column;

      .prices {
        display: flex;
        flex-direction: row;
      }

      .dln-cart-order-item__vat-included {
        margin: 10px 0 0;
      }
    }

    &__accessories {
      display: none;

      .dln-select,
      .dln-btn,
      .dln-btnTextIcon {
        width: 100%;
        margin: 0 0 20px;
      }
    }

    &__accessoriesMobile {
      display: block;
      margin-top: 20px;
      width: 100%;

      .dln-btnTextIcon {
        width: 100%;

        &__text {
          max-width: 100%;
        }
      }
    }

    &__accessories,
    &__warranty {
      float: none;
      width: 100%;
      margin: 8px 0 0;
    }
  }
}

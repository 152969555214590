.dln-kenwood {
    .dln-recipe-by-products {
        margin-top: 0;

        &__title {
            text-align: center;
            font-size: 24px;
        }
        &__browse-product {
            text-align: center;
        }

        &__search-section {
            display: flex;
            flex-direction: column;
            margin-bottom: 50px;
        }

        &__load-more {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            p {
                text-align: center;
                b {
                    @include fontRoman;

                    font-weight: normal;
                }
            }

            .dln-btn {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .dln-recipe-slides{
                margin-top: 50px;
        }
        .dln-recipe-by-products__selects-wrap {
            /*    display: flex;
            flex-direction: column; */

            margin: 0 auto 49px auto;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;

            .dln-select.dln-select--labeled {
                margin-right: 16px;
                margin-left: 0;
                max-width: 300px;
                background: $brand-03;

                .dln-select__lbl.dln-select__lbl--active{
                    top: 13.5px;
                    left: 8px;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .dln-wrapper {
            max-width: unset;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > div,
            > h1 {
                width: 100%;
                max-width: 1240px;
                padding: 0 30px;
            }
            > h1,
            .dln-breadcrumbs,
            .dln-recipe-by-products__search-section {
                margin: 0;
                background: $ui-03;
                padding: 0 calc((100% - 1180px) / 2);
                max-width: none;
            }

            .dln-breadcrumbs {
                padding-top: 15px;
                padding-bottom: 30px;
            }

            > h1 {
                padding-bottom: 99px;
                text-align: center;
            }

            .dln-filters-bar {
                margin-top: 0;
                padding: 0;
                max-width: none;

                &__desktop {
                    margin: 0;
                    padding: 16px calc((100% - 1180px) / 2);
                    padding-bottom: 0;

                    max-width: none;

                    border-bottom: 1px solid $ui-03;
                }

                &__applied-filters,
                &__sort-section {
                    padding: 0 calc((100% - 1180px) / 2);
                    max-width: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 993px) {
    .dln-kenwood {
        .dln-recipe-by-products {
            .dln-recipe-by-products__selects-wrap {
                flex-direction: column;

                .dln-select.dln-select--labeled {
                    &:last-of-type {
                        margin-right: 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .dln-kenwood {
        .dln-recipe-by-products {
            &__title {
                text-align: center;
                font-size: 20px;
            }
            .dln-wrapper {
                .dln-breadcrumbs {
                    padding-right: 0;
                }
            }

            .dln-recipe-slides{
                margin-top: 30px;
        }
        }
    }
}

@media only screen and (max-width: 1240px) {
    .dln-kenwood {
        .dln-recipe-by-products {
            .dln-wrapper {
                .dln-recipe-by-products__search-section,
                > h1 {
                    padding: 0 30px;
                }

                .dln-breadcrumbs {
                    padding: 30px;
                }

                .dln-recipe-by-products__search-section {
                    text-align: center;
                }

                .dln-filters-bar {
                    padding: 0;

                    &__desktop {
                        padding-left: 30px;
                        padding-right: 30px;
                    }

                    &__applied-filters,
                    &__sort-section {
                        padding: 0 30px;
                    }
                }
            }
        }
    }
}

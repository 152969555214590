.dln-delonghi {
  .dln-writeReview {
    .dln-orderRev {
      padding-bottom: 16px;
      margin-bottom: 30px;
    }
    &__subTitle {
      @include fontMedium;
      line-height: 19px;
      font-size: REM(18px);
      text-align: left;

      margin-bottom: 10px;
    }
    .dln-ratingStars {
      margin: 0;
    }
    &__form {
      .dln-writeReview__subTitle {
        @include fontMedium;
        line-height: 19px;
        font-size: REM(18px);
        text-align: left;

        margin-top: 0;
        margin-bottom: 12px;
      }
      .dln-inputs.dln-textarea {
        margin-bottom: 0;
      }

      &-footer {
        padding-top: 24px;
        margin-top: 24px;
      }
    }

    &__item {
      @media only screen and (max-width: 599px) {
        .dln-orderRev {
          flex-direction: row;

          &__itemPic {
            flex-shrink: 0;
            width: 80px;
            height: 80px;

            & > img {
              object-fit: contain;
            }
          }
          .dln-orderRev__content {
            flex: 1;
            width: auto;
            margin-left: 10px;

            .dln-orderRev__detailsTitle {
              @include fontMedium;
              line-height: 19px;
              font-size: REM(19px);
              text-align: left;
            }
          }
        }
      }
    }
  }
}

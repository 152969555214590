.dln-head--delonghi {
  display: none;
}
.dln-delonghi {
  .dln-head:not(.dln-head--delonghi) {
    display: none;
  }

  /*====================================================

        ASPETTANDO GRAFICA DEFINITIVA

    */

  .dln-head--delonghi {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: REM(37px);
    line-height: 1;
    letter-spacing: -0.7px;

    background: rgba(black, 0.03);
    border-bottom: 1px solid #e5e5e5;
  }

  /*====================================================*/

  .bra-header {
    display: none;
  }
}

.dln-kenwood {
    .dln-terms {
        .dln-breadcrumbs {
            a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            } 
        }
        &__content {
            margin-bottom: 70px;
            max-width: 100%;
        }
        &__head {
            text-align: center;
            .dln-title {
                padding-bottom: 15px;
            }
        }
        &__note {
            @include fontLight;
            font-size: 14px;
            line-height: 20px;
            color: $brand-04;
        }
        &__block {
            margin-top: 40px;
        }
        &__subTitle {
            @include fontSemibold;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 10px;
        }
        &__paragraph {
            @include fontLight;
            font-size: 14px;
            line-height: 20px;
            color: $brand-04;
        }
    }
}
.dln-delonghi {
  .dln-page {
    .dln-wrapper {
      .dln-page__content {
        .dln-title {
          display: block;
          width: 100%;
          text-align: center;

          @include fontMedium;
          font-size: REM(50px);
          line-height: 52px;
          letter-spacing: -1px;

          @media only screen and (max-width: 599px) {
            font-size: REM(37px);
            line-height: 40px;
            letter-spacing: -0.74px;
            padding: 0;
            margin-bottom: 24px;
            margin-top: 20px;
          }

          color: $brand-04;
        }

        [class$='__subtitle'],
        [class$='__subTitle'] {
          display: block;
          width: 100%;

          text-align: center;
          @include fontBook;
          font-size: REM(16px);
          line-height: 24px;
          margin: 0;

          @media only screen and (max-width: 599px) {
            font-size: REM(14px);
            line-height: 18px;
          }
        }

        [class$='__paragraph'] {
          text-align: center;
          @include fontBook;
          font-size: REM(16px);
          line-height: 24px;
          margin: 0;
        }

        [class$='__contentWrap'] {
          margin-top: 36px;
        }

        [class$='__section'] {
          margin-bottom: 40px;

          [class$='__sectionTitle'] {
            @include fontMedium;
            font-size: REM(37px);
            line-height: 37px;
            letter-spacing: -0.7px;

            @media only screen and (max-width: 599px) {
              font-size: REM(28px);
              line-height: 30px;
              letter-spacing: -0.53px;
              margin-bottom: 30px;
            }

            text-align: center;
          }

          .dln-title--labeled {
            position: relative;
            @include fontMedium;
            font-size: REM(28px);
            line-height: 34px;
            letter-spacing: 0;
            text-align: left;

            margin-bottom: 23px;

            @media only screen and (max-width: 599px) {
              margin-top: 0;
            }

            .dln-title__innerLabel {
              @include fontBook;
              font-size: REM(13px);
              line-height: 16px;
              letter-spacing: 0;
              color: $ui-02;

              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }

        [class$='__foot'] {
          margin-top: 40px;
          padding-top: 24px;
          text-align: center;
        }
        [class$='__foot--divider'] {
          margin-top: 40px;
          padding-top: 24px;
          text-align: center;

          border-top: 1px solid $ui-01;
        }
      }
    }
  }

  .dln-carousel--mobileOnly,
  .dln-carousel {
    &.swiper-container {
      .swiper-slide {
        overflow: hidden;
      }
      .swiper-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-top: 30px;

        &-bullet {
          margin-right: 18px;
          width: 8px;
          height: 8px;
          border: 0 solid transparent;
          background-color: $ui-02;

          &:last-of-type {
            margin: 0;
          }

          &-active {
            border: 2px solid $brand-04;
            background-color: $brand-04;
          }
          
          & .swiper-button-next,
          & .swiper-button-prev {
              display: none
          }
        }
      }
      .swiper-scrollbar {
      }
      & .swiper-button-next,
      & .swiper-button-prev {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-page {
      min-height: calc(100vh - 140px);
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, xl)) {
  .dln-wrapper {
    max-width: map-get($grid-breakpoints, xl);
  }
}

.dln-contactUs {
  min-height: calc(100vh - 90px);
  overflow: unset!important;
  margin-top: 80px!important;

  & .dln-wrapper--fullWidth {
    max-width: none;
    background-color: $ui-03;
    padding: 48px 40px 64px;
    margin-bottom: 35px;

    & .dln-wrapper {
      max-width: calc(#{map-get($grid-breakpoints, xl)} - 80px);
    }
  }
  &__head {
    width: 100%;

    & .dln-breadcrumbs {
      margin-bottom: 20px;
    }
    &Title {
      @include fontBold;
      display: block;
      width: 100%;
      height: auto;
      line-height: 56px;
      font-size: 44px;
    }
  }
  // & .dln-wrapper--contact {
  & .dln-contactUs__form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // border-bottom: 1px solid $ui-01;
    background-color: $brand-03;
    padding-top: 20px;
    padding-bottom: 48px;
    box-sizing: border-box;
    transition: opacity .5s ease-in;
    opacity: 0;
  }
  & .dln-titleIcon {
    margin: 0 0 24px 0;
  }
  &__title {
    @include fontBold;
    display: block;
    width: 100%;
    height: auto;
    line-height: 32px;
    font-size: 26px;
    margin: 0 0 16px 0;
  }
  &__text {
    display: block;
    width: 100%;
    height: auto;
    line-height: 20px;
    font-size: 14px;
    margin: 0 0 32px 0;

    &--brand-02 {
      color: $brand-02;
    }
  }
  &__mandatoryFields {
    @include fontLight;
    display: block;
    line-height: 16px;
    font-size: 12px;
    width: 100%;
    text-align: right;
    margin-bottom: 15px;
  }
  & .dln-btn--send {
    width: 280px;
    margin: 0 0 0 auto;
  }
  & .dln-contactUs__privacyWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & .dln-contactUs__text {
      font-size: 13px;
    }

    .dln-checkbox__input {
      width: 30px;
    }
  }
  & .dln-checkbox {
    margin: 0 0 16px 0;

    &__label {
      color: $brand-04;

      & a {
        color: $brand-04;
      }
    }
    &__input:checked {
      & + .dln-checkbox__inputPlaceholder + .dln-checkbox__label a {
        color: $support-02;
      }
    }
    & + .dln-contactUs__text + .dln-btn {
      width: 288px;
      margin: 0 0 0 auto;
    }
  }
  &__link {
    display: inline-block;
    color: $brand-02;
  }
  & .dln-wrapper--callUs {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 104px;

    // & .dln-wrapper {
    //     width: 100%;
    // }
  }
  &__callUsTitle {
    @include fontBold;
    display: block;
    width: 100%;
    height: auto;
    line-height: 22px;
    font-size: 18px;
    margin: 0 0 8px 0;
  }
  &__callUsText {
    display: block;
    width: 100%;
    line-height: 18px;
    font-size: 13px;
    color: $brand-02;
    margin: 0 0 24px 0;

    &--noMargin {
      margin: 0;
    }
    &--brand-01 {
      color: $brand-04;
    }
    strong {
      font-weight: 400;
      color: $brand-04;
    }
  }
  &__callUsList {
    display: block;
    width: 100%;
    height: auto;
    list-style: inside disc;
    padding: 0 0 0 16px;
    margin: 0 0 24px 0;
    box-sizing: border-box;
  }
  &__callUsListItem {
    // display: block;
    width: 100%;
    line-height: 18px;
  }
  &__callUsLink {
    line-height: 20px;
    font-size: 13px;
    color: $brand-04;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .dln-accordion__content {
    opacity: 1!important;
  }
}

.dln-order {
    & .dln-order__aside {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 264px;
        height: auto;
        padding-top: 0;
        margin: 0 40px 0 0;
    }
    & .dln-order__content {
        width: calc(100% - 264px - 40px);
        padding: 0;
    }
    &--history {
        & .dln-wrapper {
            margin-bottom: 100px;
        }
        & .dln-order__content {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            & .dln-title {
                margin: 0 0 16px 0;
            }
            & .dln-paginationSlide {
                align-self: center;
            }
        }
        & .dln-order__historyCardWrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 0 50px 0;
        }
    }
}

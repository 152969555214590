.dln-delonghi {
  & .dln-prodReg--productDetails {
    & .dln-prodReg__head {
      & .dln-title {
        @include fontSemibold;
        line-height: 52px;
        font-size: REM(50px);
        text-align: center;
      }
    }
    & .dln-prodReg__content {
      & .dln-prodReg__subTitle {
        @include fontSemibold;
        line-height: 34px;
        font-size: REM(28px);
        margin-bottom: 66px;
      }
      & .dln-prodReg__lbl {
        line-height: 16px;
        font-size: REM(13px);
      }
    }
    & .dln-itemRegDetails--productRegistration {
      padding-bottom: 24px;
      margin-bottom: 25px;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg {
      &--productDetails {
        & .dln-prodReg__head {
          padding-top: 34px;
          margin-top: 0;

          & .dln-title {
            font-size: REM(37px);
            line-height: 40px;
            margin-bottom: 13px;
          }
        }
        & .dln-prodReg__content {
          margin-top: 28px;

          & .dln-prodReg__subTitle {
            font-size: REM(21px);
            line-height: 23px;
            margin-bottom: 25px;

            & .dln-prodReg__lbl {
              line-height: 23px;
            }
          }
        }
      }
    }
  }
}

.dln-kenwood {
    & .dln-order {
        &--detail {
            & > .dln-wrapper {
                padding-top: 32px;
                padding-bottom: 100px;

                & .dln-order__aside {
                    padding-top: 0;
                }
                & .dln-order__content {
                    padding-top: 0;

                    & .dln-order__backToOrderHistory {
                        margin-bottom: 40px;

                        & .dln-icn {
                            color: $brand-04;
                            margin-right: 4px;
                        }
                        & span {
                            color: $brand-04;
                        }
                    }
                    & .dln-orderDetailHead {
                        margin-bottom: 20px;
                    }
                    & .dln-order__detailProducts {
                        border: none;
                        background-color: $ui-03;
                        padding: 30px 20px 0 20px;

                        &::after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 1px;
                            background-color: $ui-01;
                        }
                        &--last {
                            margin-bottom: 20px;

                            &::after {
                                display: none;
                            }
                        }
                        & .dln-order__detailTitle {
                            line-height: 24px;
                            font-size: 20px;
                            color: $brand-04;
                            margin-bottom: 20px;
                        }
                        & .dln-order__prodCancelledBox {
                            padding: 0;
                            margin-bottom: 32px;

                            &Title {
                                line-height: 20px;
                                font-size: 14px;
                                margin-bottom: 6px;
                            }
                            &Text {
                                line-height: 20px;
                                font-size: 14px;
                            }
                        }
                        & .dln-orderHistory  {
                            border: none;
                            background: $ui-03;
                            padding: 0 0 30px 0;
                            margin-bottom: 0;

                            & .dln-orderRev {
                                margin-bottom: 0;

                                & .dln-orderRev__wrap {
                                    &--main {
                                        margin-bottom: 15px;

                                        & .dln-orderRev__detailsTitle {
                                            margin-bottom: 15px;
                                        }
                                        & .dln-price {
                                            &--oldPrice {
                                                color: $ui-08;
                                                margin-right: 10px;
                                            }
                                            &--discounted {
                                                @include fontBold;
                                            }
                                        }
                                    }
                                    &--quantity {
                                        & .dln-orderRev__quantity {
                                            color: $ui-08;
                                        }
                                    }
                                }
                                &__actions {
                                    & .dln-btn--stroked {
                                        background: none;
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }
                    & .dln-totalSect {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-order {
            &--detail {
                & > .dln-wrapper {
                    & .dln-order__aside {
                        width: 100%;
                        margin: 0;
                    }
                    & .dln-order__content {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-order {
            &--detail {
                & > .dln-wrapper {
                    padding-bottom: 0;

                    & .dln-order__content {
                        padding-top: 42px;

                        & .dln-order__backToOrderHistory {
                            margin-bottom: 20px;
                        }
                        & .dln-orderDetailHead {
                            width: calc(100% + 40px);
                            min-width: calc(100% + 40px);
                            max-width: calc(100% + 40px);
                            margin-left: -20px;
                        }
                        & .dln-order__detailProducts {
                            width: calc(100% + 40px);
                            min-width: calc(100% + 40px);
                            max-width: calc(100% + 40px);
                            margin-bottom: 0;
                            margin-left: -20px;

                            &--last {
                                margin-bottom: 20px;
                            }
                            & .dln-order__detailTitle {
                                line-height: 22px;
                                font-size: 18px;
                                margin-bottom: 42px;
                            }
                            & .dln-orderHistory {
                                & .dln-orderHistory__content {
                                    & .dln-orderRev {
                                        & .dln-orderRev__content {
                                            & .dln-orderRev__details {
                                                margin: 0;
                                            }
                                            & .dln-orderRev__actions {
                                                margin-top: 15px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        & .dln-totalSect {
                            width: calc(100% + 40px);
                            min-width: calc(100% + 40px);
                            max-width: calc(100% + 40px);
                            margin-left: -20px;
                        }
                        & .dln-orderDetailInfo {
                            width: calc(100% + 40px);
                            min-width: calc(100% + 40px);
                            max-width: calc(100% + 40px);
                            margin-left: -20px;
                        }
                    }
                }
            }
        }
    }
}

.simple-list {
    &__title {
        font-size: 13px;
        line-height: 1;
        letter-spacing: 0;
        color: $brand-02;
    }
    &__lists {
        margin-top: 16px;
        list-style: none;
    }
    &__item {
        margin-left: 10px;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0;

        + .simple-list__item {
            padding-top: 16px;
        }
    }
    &__item,
    &__item > a {
        text-decoration: underline;
        color: $brand-04;
        @include fontSemibold;
        cursor: pointer;

        &:visited {
            color: $brand-04;
        }
    }
    &__view-all {
        color: $brand-02;
        font-size: 12px;
    }
}

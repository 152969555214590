.dln-kenwood {
    & .dln-paginationSlide {
        & .dln-paginationSlide__btn {
            font-size: 12px;

            & .dln-paginationSlide__btnIconWrap {
                display: inline-flex;
                align-items: center;

                & .dln-paginationSlide__btnIcon {
                    height: 7px;
                    width: 4px;
                }
            }
        }
        & .dln-paginationSlide__tabs {
            & .dln-paginationSlide__tab  {
                font-size: 12px;
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-inputsFile {

        .dln-btn {
            max-width: 100px;
        }
        &__label {
            width: calc(100% - 100px - 16px);
        }
    }
}
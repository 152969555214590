.dln-kenwood {
	.dln-wishlist--public {
		.dln-wrapper {
			max-width: 1200px;
			margin: 0 auto;
		}
		.dln-wishlist__content {
			max-width: 100%;
			margin: 27px auto 70px auto;

			.dln-title {
				margin-top: 0;
			}

			.dln-wishlist__signInNotice {
				margin-bottom: 60px;

				.dln-btn--link {
					padding: 0;
				}
            }
            .dln-wishlist__text {
								@include fontLight;
            }
		}

		&.dln-wishlist--empty {
			.dln-wishlist__content {
				min-height: 560px
			}
			.dln-wishlist__foot {
				margin-bottom: 0
			}
		}

		.dln-wishlist__productWrap {
            border-bottom: 0;

            & .dln-wishProd {
                &__data {
                    & .dln-btn--stroked,
                    & .dln-btnTextIcon {
                        width: auto;
                    }
                }
            }
		}
	}

	.dln-wishlist--public.dln-wishlist--shared {
		.dln-wishProd:not(.dln-wishProd--profile) .dln-btn--link {
			display: none
		}
	}

	@media only screen and (max-width: 1366px) {
		.dln-wishlist__content {
			max-width: 100%;

			.dln-wishlist__productWrap {
				.dln-wishProd {
					width: calc(33.333% - 14px);
					border-bottom: 0;
				}
			}
		}
	}
	@media only screen and (max-width: 900px) and (min-width: 599px) {
		.dln-title {
			margin: 32px 0;
			margin-bottom: 30px;
		}
		.dln-wishlist__content {
			max-width: 100%;

			.dln-wishlist__productWrap {
				.dln-wishProd {
					width: calc(33.333% - 14px);
					border-bottom: 0;
				}
			}
		}
	}

	@media only screen and (max-width:599px) {
		.dln-wishlist--public {
			.dln-wishlist__content {
				width: 100%;
				margin: 27px 10px 70px 10px;

				.dln-wishlist__signInNotice {
					margin-bottom: 30px;
				}
			}

			.dln-wishlist__productWrap {
				.dln-wishProd {
					width: 100%;
					border-bottom: 1px solid var(--ui-01);

					&:last-of-type {
						border-bottom: 0;
						margin-bottom: 0;
						padding-bottom: 0;
					}
				}
			}
		}
	}
}

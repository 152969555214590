.dln-miniCart {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    width: 100%;
    max-width: 400px;

    &__arrow {
        width: 0;
        height: 0;
        border-right: 7px solid transparent;
        border-bottom: 9px solid $brand-03;
        border-left: 7px solid transparent;
        margin-right: 8px;
        margin-left: auto;
    }
    &__wrapper {
        width: 100%;
        border-radius: 2px;
        background-color: $brand-03;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1);
        padding: 24px 0;
    }
    &__head {
        width: 100%;
        padding: 0 32px 16px;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: 200px;
        max-height: 289px;
        border-top: 1px solid $ui-01;
        border-bottom: 1px solid $ui-01;
        padding: 24px 32px;
        overflow: hidden;
        overflow-y: auto;
    }
    &__footer {
        padding: 0 32px 8px;

        & .dln-orderSumm {
            & .dln-orderSumm__contentRow {
                &:not(.dln-orderSumm__contentRow--total) {
                    border-bottom: 1px solid $ui-01;
                }
                &--total {
                    border-top: none;
                }
            }
        }
    }
    &__mainTitle {
        @include cta;
        color: $brand-04;
        margin: 0 0;
    }
    &__cta {
        display: flex;
        flex-wrap: wrap;
        padding: 0 32px 16px;

        .dln-btn {
            width: calc(50% - 8px);
            margin: 0 16px 0 0;

            &:nth-child(even) {
                margin: 0;
            }
        }
    }
    .dln-addItem {
        border: none;
        padding: 0;
        &+.dln-addItem {
            border-top: 1px solid $ui-01;
            padding: 24px 0 0;
            margin: 16px 0 0;
        }
    }
    .dln-orderSumm {
        border: none;
        background-color: transparent;
        margin: 24px 0 0;
        &__contentRow--total {
            border-width: 1px 0 0;
            border-color: $ui-02;
        }
        &__rowTitle {
            color: $brand-04;
        }
        .dln-price {
            &--freeshippingtarget {
                color: $brand-02;
                margin: 0 0 0 auto;
            }
        }
    }
    &__message {
        @include cta;
        display: block;
        width: 100%;
        font-size: 18px;
        color: $brand-02;
    }
    &--empty {
        .dln-miniCart__content {
            align-items: center;
            align-content: center;
            text-align: center;
            border-bottom: none;
            .dln-icn {
                font-size: 48px;
                color: $brand-02;
                margin: 0 auto 24px;
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
  .dln-club-membership {
    &__section {
      padding: 0 20px;
    }

    &__column {
      width: calc(50% - 20px);
    }
  }
}

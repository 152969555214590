.dln-kenwood {
    & .dln-page.dln-order {
        &--detail {
            & .dln-wrapper {
                & .dln-order__content {
                    & .dln-order__detailProducts--cancelled  {
                        & .dln-orderHistory {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-page.dln-order {
            &--detail {
                & .dln-wrapper {
                    & .dln-order__content {
                        & .dln-order__detailProducts--cancelled  {
                            & .dln-order__detailTitle {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

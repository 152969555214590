.dln-verification {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    height: auto;
    border-radius: 2px;
    background-color: $brand-03;
    padding: 0 40px 24px 40px;

    &__form {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        margin: 0;

        .dln-inputs {
            margin: 0 0 24px 0;
        }
        .dln-btn[type="button"] {
            margin: 0 0 24px 0;
        }
        .dln-btn--link {
            margin: 0 auto;
        }
    }
    &__text {
        display: block;
        width: 100%;
        line-height: 20px;
        margin: 24px 0 32px 0;
    }
    &__wrap {
        width: 100%;
        //margin: 32px auto 0;
        text-align: center;
    }
}
.dln-kenwood {
  .dln-prdReview {


      &--empty {
        .dln-prdReview__section{
          width: calc(82% - 140px);
          display: block;
          text-align: center;
          margin: 0 auto;
        }
  
        .dln-prdReview__foot--divider {
          text-align: center;
        }

        .dln-prdReview__subTitle {
          @include fontExtra;
          font-size: 28px;
          margin: 0 0 8px;
        }

        .dln-prdReview__foot {
          text-align: center;
          border: none;
        }

        .dln-prdReview__content {
          display: block;
          text-align: center;
        }
      }

      @media only screen and (max-width:599px) {
        &--empty {
        .dln-prdReview__content {
          margin: 0 0 40px;
          & .dln-title {
              margin: 32px 0;
          }
        }

        .dln-prdReview__section{
          width: 100%;
          display: block;
          text-align: center;
          margin: 0 auto;
        }

        .dln-prdReview__subTitle {
          font-size: 24px;
          text-align: center
        }

        .dln-prdReview__paragraph {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}
.dln-kenwood {
    .dln-connectAcc {
        padding: 0 40px 70px 40px;
        &__text {
            font-size: 14px;
            line-height: 20px;
            margin: 0 0 40px;
            text-align: center;
        }
        &__form {
            .dln-inputs {
                margin-bottom: 34px;
                &+.dln-inputs {
                    margin-bottom: 0;
                }
            }
            .dln-btn[type="button"] {
                margin: 30px 0 40px;
            }
            max-width: 380px;
            margin: 0 auto;
            .dln-inputs {
                margin: 0 0 35px 0;
            }

        }
        &__wrap {
            max-width: 380px;
            margin: 0 auto 0;
            .dln-btn--link {
                font-size: 14px;
                line-height: 20px;
            }
        }
        .dln-popup__head {
            padding: 0;
            .dln-popup__headTitle {
                font-size: 32px;
                line-height: 40px;
                width: 100%;
                margin: 0;
                max-width: 100%;
                text-align: center;
                padding-top: 80px;
                padding-bottom: 0;
            }
            .dln-btnIcon {
                position: absolute;
                top: 20px;
                right: 20px;
                .dln-icn {
                    font-size: 22px;
                    width: 22px;
                    height: 22px;
                    text-indent: 999px;
                    overflow: hidden;
                    @include definePseudoElems;
                    &::before {
                        @include fontIcon;
                        content: 'J';
                        text-indent: 0;
                        color: $brand-01;
                    }
                }
            }
            .dln-popup__headLine {
                display: none;
            }
        }
        @media only screen and (max-width:599px) {
            padding: 0 22px 50px 22px;
            &__text {
                margin: 32px 0 40px;
                text-align: center;
            }
            &__form {
                .dln-inputs {
                    margin-bottom: 34px;
                    &+.dln-inputs {
                        margin-bottom: 0;
                    }
                }
                .dln-btn[type="button"] {
                    width: 100%;
                }
            }
            &__wrap {
                .dln-btn--link {
                    width: auto;
                    max-width: 50%;
                    margin: 0;
                    text-align: left;
                    font-size: 13px;
                    &:nth-child(odd) {
                        margin: 0 auto 0 0;
                    }
                }
            }
            .dln-popup__head {
                .dln-popup__headTitle {
                    font-size: 28px;
                    line-height: 34px;
                }
                .dln-btnIcon {
                    top: 30px;
                    right: 30px;
                }
            }
        }
    }
}
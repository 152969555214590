.dln-order {
    &--cancellation {
        & .dln-order__cancellationProducts {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            width: 100%;
        }
        & > .dln-ordEditHead {
            margin-bottom: 20px;
        }
        // & .dln-order__cancellationTitle {
        //     @include fontBold;
        //     display: block;
        //     width: 100%;
        //     line-height: 55px;
        //     font-size: 44px;
        //     padding-top: 10px;
        //     margin: 0 0 20px 0;
        // }
        // & .dln-order__cancellationData {
        //     display: inline-flex;
        //     flex-wrap: wrap;
        //     width: auto;
        //     line-height: 20px;
        //     font-size: 16px;
        //     color: $brand-01;
        //     margin: 0 50px 30px 0;

        //     & strong {
        //         margin: 0 0 0 3px;
        //     }
        // }
        // & .dln-order__cancellationMessage {
        //     display: block;
        //     width: 100%;
        //     height: auto;
        //     line-height: 20px;
        //     font-size: 13px;
        //     margin: 0 0 40px 0;
        // }
        & .dln-order__cancellationWrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 45px;
            margin: 0 0 20px 0;

            & .dln-select--selectAMotivation {
                width: calc(100% - 30px - 184px);
                max-width: 285px;
                margin: 0 30px 0 0;
            }
            & .dln-btn--cancelOrder {
                width: 184px;
                margin: 0 0 0 auto;
            }
        }
        & .dln-orderHistory {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 30px;
            }
            & .dln-orderRev {
                margin: 0;

                &__actions {
                    display: none;
                }
            }
        }
    }
}

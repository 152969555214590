.dln-kenwood {
  & .dln-cartItem {
    &__content {
      & .dln-cartItem__quantity {
        z-index: 3;

        & .dln-inputIncr {
          height: 17px;

          &__btn {
            height: 17px;

            & .dln-icn {
              line-height: 17px;
              font-size: 10px;
            }

            &--decrement {
              & .dln-icn {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    &__price {
      > .dln-cartItem__chips {
        margin-top: 5px;
      }

      .dlg-omnibus-price {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .dln-popup-entry {
      font-weight: bold;
    }

    &__closeBtn {
      z-index: 3;
    }
  }

  &
    .dln-cart
    > .dln-wrapper
    .dln-cart__content
    .dln-cart__items
    .dln-cartItem__picture {
    height: unset;
  }
}

@media only screen and (max-width: 599px) {
  .dln-kenwood {
    & .dln-cartItem {
      .dln-popup-entry {
        font-weight: normal;
        font-size: REM(13px);
        font-stretch: normal;
        font-style: normal;
        line-height: 1.46;
        letter-spacing: normal;
        color: #000;
      }
    }
  }
}

.dln-kenwood {
    & .dln-manuals-list {
        & .dln-manuals-list__item {
            // border-bottom: 1px solid $ui-01;
            & > .dln-icn {
                text-indent: -5px;
                width: 38px;
                height: 50px;
                line-height: 50px;
                font-size: 49px;
                color: $brand-01;
                margin-right: 16px;
            }
            & .dln-manuals-list__title {
                font-size: 14px;
            }
            & .dln-manuals-list__details {
                line-height: 16px;
                font-size: 12px;
            }
        }
    }
}

.dln-cartItem {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 32px 0 32px;
  position: relative;
  border-bottom: 1px solid $ui-01;
  background-color: $brand-03;

  &__closeBtn {
    position: absolute;
    top: 32px;
    right: 24px;

    & .dln-btnIcon {
      height: auto;
      width: auto;

      &__wrap {
        height: auto;
        width: auto;
      }
    }
  }

  &__article {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__picture {
    width: 180px;
    max-height: 144px;
    background-color: $brand-03;
    display: inline-flex;
    align-items: baseline;
    float: left;
    position: relative;
    z-index: 1;
    top: 0;

    & > img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__description {
    flex: 1;
  }

  &__bundleWrapper {
    border: 2px solid var(--ui-01);
    margin-bottom: 20px;

    .dln-cartItem__bundleHeader {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
      padding: 15px 40px 15px 15px;
      background-color: var(--ui-03);

      button {
        position: absolute;
        top: 15px;
        right: 15px;
        background: none;
        border: none;
        font-size: 22px;
        cursor: pointer;
      }

      .priceBox {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .prices .dlg-omnibus-price {
          flex-direction: row;
          align-items: center;
        }

        .discountedPrice {
          background-color: var(--brand-01);
          padding: 4px 12px;
          color: var(--brand-04);
          font-size: 12px;
          font-family: var(--fontNameBold);
          font-weight: 700;
          border-radius: 2px;
        }
      }
    }

    .dln-cartItem {
      border: none;
    }
  }

  &__bundleDescription {
    font-size: 12px;
  }

  &__bundleDescription,
  &__subscriptionDescription {
    line-height: 1.5;
    margin-top: 15px;
    overflow: hidden;
    transition: max-height 0.3s;
    will-change: max-height;

    &--toggle-accordion {
      border: 0;
      background: transparent;
      margin-top: 15px;
      margin-bottom: 20px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__discount,
  &__discount p {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    > .dln-chips {
      margin: 24px 0 0;
    }
  }

  &__accessories,
  &__warranty {
    width: calc(100% - 180px - 24px);
    margin: 0 0 0 24px;
  }

  &__accessoriesMobile {
    display: none;
  }

  &__title {
    @include cta;
    @include fontBold;
    margin-bottom: 16px;
  }

  &__subTitle {
    @include small;
    @include fontBold;
  }

  &__price {
    display: flex;
    flex-direction: column;
    order: 2;
    margin-top: 0;
    margin-bottom: 12px;

    .priceBox {
      display: flex;
      flex-direction: column;
      width: 100%;

      .prices {
        display: flex;
      }
    }

    .dln-price {
      font-size: 16px;
      white-space: nowrap;
      text-align: right;
    }

    .dln-cart-order-item__vat-included {
      margin-top: 10px;
    }
  }

  &__quantity {
    display: flex;
    align-items: center;
    position: static;

    .dln-inputIncr {
      margin-left: 20px;
    }

    > i {
      margin-left: 15px;
      cursor: pointer;
    }
  }

  &__bundle-references {
    > p {
      font-size: 13px;
      margin-top: 10px;
    }

    > button {
      font-size: 12px;
      width: 100%;
      max-width: 220px;
      margin-top: 6px;
      padding: 8px;
      border: 2px solid #000;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  &__label {
    @include small;
  }

  &__accessories {
    display: flex;
    flex-wrap: wrap;
    margin: 32px 0 24px 0;

    .dln-select,
    .dln-btn,
    .dln-btnTextIcon {
      width: 300px;
      max-width: none;

      &__text {
        max-width: 100%;
      }
    }
    .dln-select {
      margin: 0 20px 0 0;
    }
  }

  &__warranty {
    width: calc((100% - 204px - 10px) / 2);
    margin: 32px 0 0 204px;
  }

  &__warrantyHead {
    border-bottom: 1px solid $ui-01;
    padding: 0 0 16px;
    margin: 0 0 16px;
  }

  &__plan {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 24px;

    .dln-radioBtn {
      &__input {
        &:checked ~ .dln-radioBtn__lbl {
          color: $brand-04;
        }
      }
    }
    .dln-cartItem__tooltip {
      margin: 0 0 0 8px;
    }

    .dln-price {
      @include small;
      margin: 0 0 0 auto;
    }
  }
}

.dln-braun {
  & .dln-cartItem {
    &__price {
      .dln-cartItem__chips {
        margin-top: 5px;

        .dln-chips--lightGray {
          border-radius: 2px;
        }
      }
    }
  }
}

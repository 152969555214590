.dln-delonghi {
    .dln-repairCenter {
        .dln-title {
            margin-bottom: 24px;

            @media only screen and (max-width:599px) {
                margin-top: 0;
                margin-bottom: 16px;
            }
        }

        &__content {
            &Wrap {
                padding-bottom: 24px;

                @media only screen and (max-width:599px) {
                    margin-top: 24px !important
                }
            }
            .dln-btn {
                margin: 0 auto;

                @media only screen and (max-width:599px) {
                    width: auto;
                }
            }
        }

    }
}
.dln-wishlist--shared.dln-page--myArea {
	.dln-wrapper {
		.dln-wishlist__content {
			width: 100%;
			max-width: calc(100% - 464px);
			margin: 0 auto;

			.dln-wishlist__productWrap {
				padding: 0;
				border: 0;

				.dln-wishProd {
					width: calc(50% - 7.5px);

					.dln-btn--link {
						display: none
					}
				}
			}
		}

		@media only screen and (min-width:map-get($grid-breakpoints, md)) and (max-width:map-get($grid-breakpoints, lg)) {
			.dln-wishlist__content {
				max-width: 100%;

				.dln-wishlist__productWrap {
					.dln-wishProd {
						// width: calc(50% - 7.5px);
					}
				}
			}
		}
	}
}
.dln-salesTax {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: auto;
    // padding: 70px 20px 70px 20px;
    //padding: 0 20px 70px 20px;
    padding: 14px 40px 40px;

    &__title {
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 26px;
        @include fontBold;
        text-align: center;
        padding: 4px 0 0 0;
        margin: 0 0 32px 0;
    }
    & .dln-inputs {
        margin: 0 16px 0 0;
        width: calc(70% - 16px);
    }
    & .dln-btn {
        width: 30%;
        margin: 0;
    }
    &__inputs {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    &__paragraph {
        font-size: 14px;
        line-height: 20px;
        margin: 0 0 30px;
        &:last-of-type {
            margin: 0;
        }
    }
    // &__content {
    //     padding: 0 60px;
    // }
}
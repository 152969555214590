@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-prodReg {

        &--confirmation {
            .dln-prodReg {
                &__content {
                    .dln-prodReg__subTitle {
                        margin: 0px 0 10px;
                    }
                    .dln-prodReg__notice {
                        margin: 80px 0 100px;
                    }
                }
            }
        }

    }
}
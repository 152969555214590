.dln-delonghi {
  .dln-orderCancellationConfirm {
    &__text {
      font-size: REM(18px);
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-orderCancellationConfirm {
      &__text {
        font-size: REM(16px);
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, xl)) {
  .dln-delonghi {
    .dln-instruction-manuals-02.dln-page {
      .dln-wrapper {
        margin: 0 auto;
        margin-left: 0;
      }

      .dln-quickLinks--slider.dln-quickLinks--slider--default {
        padding: 36px 70px 0 70px;
        border-top: 2px solid $ui-03;
      }
    }
  }
}

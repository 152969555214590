.dln-kenwood {
    .dln-categoryBox {
        border: none;

        &__imgWrap {
            height: auto;
            @media screen and (max-width: 599px) {
                max-width: 128px;
            }
            > img {
                width: 100%;
                height: auto;
                object-fit: none;   
            }
        }

        &__title {
            border-bottom: none;
            padding-bottom: 0;
            display: block;
            text-align: center;
        }
        &:hover {
            & .dln-categoryBox__title {
                text-decoration: underline;
            }
        }
    }
}
.dln-filtersPopup {
    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 80px;
        border-bottom: 1px solid $brand-01;
        padding: 0 10px 0 20px;
        box-sizing: border-box;
    }
    &__title {
        @include fontBold;
        display: inline-block;
        width: auto;
        line-height: 30px;
        font-size: 18px;
        text-transform: uppercase;
    }
    &__head {
        & .dln-btn {
            display: inline-flex;
            width: auto;
            border-radius: 0;
            padding: 0;
            margin: 0 23px 0 auto;
            font-size: 15px;
        }
        & .dln-btnIcon--close {
            width: 32px;
            height: 32px;

            & .dln-btnIcon__wrap {
                width: 32px;
                height: 32px;
            }
            & .dln-icn {
                font-size: 24px;
            }
        }
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;

        .dln-inputs {
            margin-bottom: 25px;
        }
    }
    & .dln-accordion {
        &__title {
            @include fontBold;
            font-size: 18px;
            color: $brand-01;
        }
        &__head {
            border-bottom: none;

            & .dln-icn {
                display: inline-flex;
                justify-content: center;
                width: 16px;
                height: 10px;
                line-height: 10px;
                font-size: 24px;
                top: calc(50% - 5px);
                right: 20px;
            }
        }
        &__content {
            padding: 0 20px 5px 20px;
        }
        &--extraText {
            & .dln-accordion__title {
                display: inline-block;
                width: auto;
                max-width: calc(50% - 48px);
                padding: 0 0 0 20px;
                margin: 0 auto 0 0;
            }
        }
        &__extraText {
            display: inline-block;
            width: auto;
            max-width: calc(50% - 48px);
            line-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0 45px 0 16px;
            overflow: hidden;
        }
        &--activated {
            background-color: #F5F5F5;
            transition: max-height 0.22s ease-out, background-color 0.12s linear;

            &.dln-accordion--open {
                background-color: $brand-03;
            }
        }
        &--open {
            overflow: visible;
        }
    }
    &__actions {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid $brand-01;
        background-color: $brand-03;
        padding: 20px;
        border-radius: 0 0 5px 5px;
        box-sizing: border-box;

        & .bra-btn {
            display: inline-block;
            width: auto;
            min-width: 198px;
        }
    }

    &__range {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        padding: 0 10px;
        margin-bottom: 20px;
        box-sizing: border-box;

        &Bar {
            width: 100%;
            // max-width: calc(366px - 16px);
            margin-bottom: 20px;
            position: relative;

            &::before,
            &::after {
                content: '';
                width: 8px;
                height: 6px;
                background-color: $ui-07;
                position: absolute;
                top: calc(50% - 3px);
                left: -8px;
            }
            &::after {
                left: initial;;
                right: -8px;
            }
        }
        &LabelWrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: 15px;
            position: relative;

            & .dln-filtersPopup__rangeLabel {
                display: inline-block;
                height: 15px;
                line-height: 15px;
                transform: translateX(-50%);
                position: absolute;
                top: 0;

                &.-min {
                    left: 0;
                }
                &.-max {
                    // transform: translateX(-50%);
                    left: 100%;
                }
            }
        }
    }
    &__colors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        & .dln-colorRadioBtn  {
            width: 100%;
            padding: 15px 0;
            min-height: 48px;
            border-bottom: 1px solid $ui-01;

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
    &__rangeBar.noUi-target.noUi-ltr.noUi-horizontal {
        height: 6px;
        border: none;
        border-radius: 0;
        background-color: $ui-07;
        box-shadow: none;
    }
    &__rangeBar.noUi-target.noUi-ltr.noUi-horizontal {
        height: 6px;
        border: none;
        border-radius: 0;
        background-color: $ui-07;
        box-shadow: none;
    }
    & .noUi-connect {
        background-color: $brand-01;
    }
    & .noUi-handle.noUi-handle-lower,
    & .noUi-handle.noUi-handle-upper {
        width: 20px;
        height: 20px;
        border: 1px solid $ui-09;
        border-radius: 50%;
        box-shadow: none;
        box-sizing: border-box;
        top: -6px;
        right: -10px !important;
    }
    & .noUi-handle.noUi-handle-lower::before,
    & .noUi-handle.noUi-handle-lower::after,
    & .noUi-handle.noUi-handle-upper::before,
    & .noUi-handle.noUi-handle-upper::after {
        display: none;
    }
}

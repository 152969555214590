.dln-order {
    &--return {
        & .dln-order__content {
            & > .dln-btn {
                width: 184px;
                margin-top: 20px;
            }
        }
    }
}

.dln-kenwood {
    & .dln-popup {
        & .dln-popup__content {
            & .dln-popup__wrapper {
                .dln-verification {
                    & .dln-popup__head {
                        width: 100%;
                        margin: 0;
                    }
                    .dln-inputs {
                        max-width: 267px;
                        margin-right: 10px;
                        margin-bottom: 40px;
                    }
                    &__form {
                        .dln-btn[type="button"] {
                            margin: 0 0 24px 0;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__content {
                & .dln-popup__wrapper {
                    .dln-verification {
                        padding-bottom: 50px;

                        & .dln-popup__head {
                            &Title {
                                padding-top: calc(22px + 40px);
                                padding-bottom: 32px;
                            }
                        }
                        &__text {
                            margin-bottom: 40px;
                        }
                        &__form {
                            & > .dln-inputs {
                                max-width: none;
                                margin: 0 0 20px 0;
                            }
                            & > .dln-btn {
                                width: 100%;
                                margin-bottom: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-instruction-manuals-03 {
        .dln-instruction-manuals {
            &__product {
                border: 0;
            }
            &__list {
                margin-top: 32px;
                &:last-child {
                    border: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}
@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-foot {
        & .dln-wrapper {
            width: 100%;
            padding: 0;
        }
        &__copyright {
            width: calc(100% - 40px);
            padding: 0 20px 0 20px;
            margin: 0;
            order: 100;
            &Logo {
                margin: 0 auto 20px auto;
            }
            &Text {
                text-align: center;
            }
        }
        &__creditCards {
            margin: 0 auto 24px;
        }
        &__infoList {
            margin: 0 auto;
            height: 24px;
            width: 100%;
            &Item {
                &::after {
                    top: 9px;
                    right: 0;
                }
            }
            &Link {
                height: auto;
                line-height: normal;
            }
        }
        & .dln-accordion {
            max-height: 56px;
            border-color: $brand-02;

            &--open {
                .dln-accordion__head {
                    padding-bottom: 0;
                }
                .dln-accordion__content {
                    padding-top: 0;
                }
            }
            &__head {
                height: 57px;

                & .dln-accordion__title {
                    height: 53px;
                    line-height: 53px;
                    font-size: 13px;
                    border-color: $brand-02;
                    border-width: 0;
                }
            }
            &__content {
                width: calc(100% - 40px);
                padding: 16px 20px 16px 20px;
            }
        }
        &__subscribe {
            display: none;
        }
        & .dln-select {
            display: none;
            width: 100%;
            margin: 0 auto;
        }
        &__socialIcn {
            margin: 0 auto 24px;
            width: 100%;
            & ul {
                margin: 0 auto;
            }
        }
        &__footer-selects {
            flex-wrap: wrap;
            margin: 24px auto;
            padding: 24px 0 0 0;
            width: 100%;
            border-top: 1px solid $brand-02;
            & .dln-btn:not(.dln-btn--selects) {
                display: none;
            }
            & .dln-btn--selects {
                display: inline-block;
                width: auto;
                margin: 0 auto;
            }
            &.-showContent {
                & .dln-select {
                    display: inline-block;
                }
                & .dln-btn--selects {
                    display: none;
                }
            }
        }
        &--kenwood {
            padding-top: 24px;
            padding-bottom: 24px;
            .dln-foot__copyright {
                margin-right: 10px;
                margin-left: 10px;
                padding-right: 10px;
                padding-left: 10px;
                padding-bottom: 24px;
                align-items: center;
                &+.dln-accordion {
                    border-top: 1px solid $ui-01;
                }
            }
            .dln-accordion {
                border-color: $ui-01;
                &__head {
                    .dln-icn {
                        color: $brand-04;
                    }
                }

            }
            .dln-foot__middleSection {
                border-width: 0;
                .dln-foot__socialIcn {
                    display: none;
                }
                .dln-foot__creditCards {
                    width: auto;
                    margin: 0 auto;
                    max-width: 100%;
                }
                .dln-foot__subscribe {
                    display: inline-flex;
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                    flex-wrap: wrap;
                    margin: 24px auto 0;
                    &Text {
                        width: 100%;
                        height: auto;
                        line-height: normal;
                        margin: 0 0 8px;
                        display: block;
                        text-align: center;
                    }
                    .dln-inputs {
                        margin: 0 10px 0 auto;
                    }
                    .dln-btn {
                        margin: 0 auto 0 0;
                    }
                }
            }
            .dln-foot__lang {
                text-align: center;
                display: flex;
                padding: 0;
                .dln-select {
                    display: inline-block;
                    width: auto;
                    order: -1;
                    margin: 8px auto 16px;
                }
                .dln-foot__rights {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-foot {
        &--kenwood {
            .dln-foot__middleSection {
                border-width: 0;
                .dln-foot__socialIcn {
                    display: none;
                }
                .dln-foot__creditCards {
                    width: auto;
                    margin: 0 auto;
                    max-width: 100%;
                }
                .dln-foot__subscribe {
                    display: inline-flex;
                    width: 100%;
                    height: auto;
                    max-width: 100%;
                    flex-wrap: wrap;
                    margin: 24px auto 0;
                    &Text {
                        width: 100%;
                        height: auto;
                        line-height: normal;
                        margin: 0 0 8px;
                        display: block;
                        text-align: center;
                    }
                    .dln-inputs {
                        margin: 0 10px 0 auto;
                    }
                    .dln-btn {
                        margin: 0 auto 0 0;
                    }
                }
            }
        }
    }
}
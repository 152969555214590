@media only screen and (max-width: map-get($grid-breakpoints, md)){
    .dln-country-stripe {
        position: fixed;

        & .dln-country-stripe__text {
            width: calc(100% - 33px);
            margin: 0 0 24px  0;
        }
        & .dln-country-stripe__container {
            flex-direction: column;
            align-items: flex-start;
        }
        & .dln-btnIcon.dln-btnIcon--close {
            position: absolute;
            top: 10px;
            right: 14px;
        }
        & .dln-icn__legacy-close:before {
            font-size: 14px;
            color: $brand-02;
        }
        & .dln-country-stripe__buttons {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;
            margin: 0;

            & .dln-btn {
                width: 100%;
                margin-bottom: 16px;
            }
        }
    }
}

.dln-kenwood {
    .dln-prodReg--confirmation {
        padding-bottom: 72px;

        & .dln-prodReg__head {
            margin-top: 60px;
            width: 100%;
            min-width: initial;
            background-color: $brand-03;
            padding-top: 0;
            padding-bottom: 0;

            & .dln-title {
                margin: 0 0 30px 0;
            }
            & .dln-timeline {
                margin: 0;
            }
        }
        & .dln-prodReg__content {
            margin-top: 72px;
            margin-bottom: 0;

            .dln-prodReg__confirmation:last-child {
                margin: 30px 0;
            }

            & .dln-prodReg__subTitle {
                @include fontExtra;
                display: block;
                width: 100%;
                max-width: none;
                line-height: 40px;
                font-size: 25px;
                text-align: left;
                margin: 0 0 10px 0;
            }
            & .dln-chips {
                padding: 0;
                background: transparent;

                & .dln-icn--mainIcon {
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    font-size: 50px;
                    margin: 0 auto 10px auto;
                    color: $support-02 !important;
                }
                &__content {
                    width: 50%;
                    line-height: 20px;
                    font-size: 14px;
                    text-align: center;
                    padding: 0;
                    margin: 0 25% 30px 25%;

                    & span {
                        @include fontBold;
                        line-height: 24px;
                        font-size: 20px;
                        color: $brand-04;
                        margin: 0 0 10px 0;
                    }
                    & br {
                        display: none;
                    }
                }
            }
            & .dln-prodReg__chips {
                border-color: $ui-02;

                &+.dln-btn {
                    width: auto;
                    margin: 0 auto 72px auto;
                }
            }
            & .dln-prodReg__notice {
                width: 50%;
                justify-content: center;

                .dln-noticeBox {
                    .dln-icn {
                        color: $brand-01;
                    }
                    &__icon {
                        margin-bottom: 20px;
                    }
                    &__content {
                        width: 100%;
                        height: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-prodReg--confirmation {
            & .dln-prodReg__head {
                padding: 0;
                margin-top: 35px;

                & .dln-title {
                    width: 100%;
                    line-height: 40px;
                    padding: 0;
                }
                & .dln-timeline {
                    max-width: none;
                    min-height: 74px;

                    &__step {
                        &::after {
                            width: calc(50% - 8px);
                            left: 72px;
                        }
                    }
                    &__label {
                        & span {
                            display: block;
                            width: 100%;
                            line-height: 14px;
                            font-size: 12px;
                            text-align: center;
                            opacity: 1;
                        }
                    }
                }
            }
            & .dln-prodReg__content {
                margin-top: 80px;

                & .dln-prodReg__subTitle {
                    line-height: 34px;
                    margin-bottom: 40px;
                }
                & .dln-chips__content {
                    width: 100%;
                    line-height: 20px;
                    font-size: 14px;
                    margin: 0 0 32px 0;
                }
                & .dln-prodReg__chips {
                    &+.dln-btn {
                        margin: 0 auto 64px auto;
                    }
                }
                & .dln-prodReg__notice {
                    width: 100%;
                    margin: 0;

                    & .dln-noticeBox {
                        height: auto;
                        min-height: initial;
                        padding: 44px 32px;
                        box-sizing: content-box;

                        &__icon {
                            align-self: flex-start;
                            width: 50px;
                            height: 50px;

                            & .dln-icn {
                                font-size: 50px;
                            }
                        }
                        &__copy {
                            min-height: initial;
                        }
                    }
                }
            }
        }
    }
}

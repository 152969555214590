.dln-kenwood {
    & .dln-popup__wrapper {
        & > .dln-faqAnnouncement {
            padding-bottom: 70px !important;

            & > .dln-popup__head {
                padding: 0;
                padding-bottom: 24px;

                & .dln-popup__headTitle {
                    & span {
                        @include fontExtra;
                        line-height: 36px;
                        font-size: 28px;
                    }
                }
            }
            & .dln-faqAnnouncement__content {
                padding: 0;

                & .dln-faqAnnouncement__text {
                    &:last-of-type {
                        margin-bottom: 30px;
                    }
                }
            }
            & .dln-faqAnnouncement__actions{
                padding-top: 30px;
                margin: 0;

                & .dln-btn--link {
                    @include fontMedium;
                    margin: 0 0 0 auto;
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-popup__wrapper {
            & > .dln-faqAnnouncement {
                padding-top: 0 !important;
                padding-bottom: 49px !important;

                & .dln-popup__head {
                    padding-top: 90px !important;
                    margin-top: 0 !important;

                    & .dln-btnIcon--close {                        
                        right: 0;
                    }
                    &Title {
                        & span {
                            line-height: 32px !important;
                            font-size: 24px !important;
                        }
                    }
                }
                & .dln-faqAnnouncement__content {
                    & .dln-faqAnnouncement__text {
                        &:last-of-type {
                            margin-bottom: 25px;
                        }
                    }
                }    
                & .dln-faqAnnouncement__actions {
                    width: 100% !important;
                }            
            }
        }
    }    
}
.dln-checkout {
    &--editing {
        & .dln-checkout__formData {
            width: calc(50% - 8px);
            max-width: none;
            margin-bottom: 16px;
            &:nth-of-type(odd) {
                margin-right: 16px;
            }
        }
        & .dln-btn--editing {
            width: calc(50% - 8px);
            margin-bottom: 32px;
        }
        & .dln-accordion--shipping-information {
            & .dln-btn--showOtherAddress {
                display: none;
            }
        }
        & .dln-accordion--payment {
            &.dln-accordion--editing {
                & .dln-accordion__content {
                    opacity: 1;
                    display: flex;

                    & .dln-checkout__dataResult {
                        display: flex;
                    }
                    &--submit {
                        & .dln-checkout__paragraph {
                            display: none;
                        }
                        & .dln-btn {
                            margin: 0;
                        }
                    }
                }
                & .dln-checkout__formField {
                    & .dln-checkServiceBox__cardList {
                        display: flex;
                    }
                    &--billingAddress {
                        .dln-checkout__formData{
                            margin-left: 0px;

                            &:nth-of-type(odd){
                                margin-left: 0;
                            }


                        }
                        & .dln-btn {
                            margin: 16px 0 0 0px;
                        }
                    }
                }
                & .dln-checkout__formDataHead {
                    & .dln-btn {
                        margin: 0 0;
                    }
                }
                & .dln-checkout__formGroup {
                    & .dln-inputs,
                    & .dln-checkout__formField:not(.dln-checkout__formField--radio) {
                        display: none;
                    }
                }
                & .dln-checkout__dataResult {
                    display: none;
                }
            }
        }
    }

    & .dln-accordion.dln-accordion--payment.dln-accordion--showInfo.dln-accordion--editing{
        .dln-accordion__subTitle{
            display: flex;
            margin-top: 8px;
        }
    }
    &__formDataHead {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 16px 0;

        & .dln-radioBtn {
            width: calc(100% - 60px - 16px);
            margin: 0 auto 0 0;

            &__input {
                &:checked {
                    &+.dln-radioBtn__lbl {
                        @include fontBold;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    // .dln-recipe-grid {
    //     padding-bottom: 53px;

    //     .dln-recipe {
    //         width: 50%;
    //         padding: 5px;
    //     }
    // }
    .dln-recipe-grid {
        & .dln-recipe {
            width: calc(50% - 5px);
            margin: 0 10px 20px 0;

            &:nth-of-type(4n+4) {
                margin: 0 10px 20px 0;
            }
            &:nth-of-type(3n+3) {
                margin: 0 10px 20px 0;
            }
            &:nth-of-type(2n+2) {
                margin: 0 0 20px 0;
            }
        }
        &--detail {
            .dln-recipe {
                width: 100%;
                margin: 0 0 30px 0;
            }
        }
    }
}
.dln-shipDelivery {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 28px 40px 40px 40px;

    & .dln-popup__head {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 16px;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    &__title,
    &__text {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        margin-bottom: 20px;
    }
    &__title {
        @include fontBold;        
    }
    &__text {
        &:last-child {
            margin: 0;
        }
    }
}
.dln-delonghi {
  .dln-orderRev {
    align-items: flex-start;

    .dln-ratingStars {
      .dln-icn {
        width: 16px;
        height: 16px;

        &::before {
          font-size: REM(16px);
        }
      }
    }

    &__productCode {
      color: $ui-02;
      @include fontMedium;
      font-size: REM(14px);
    }

    @media only screen and (max-width: 599px) {
      border: 0;
    }

    &__itemPic {
      background-color: transparent;
      max-height: none;
      height: unset;

      @media only screen and (min-width: 1024px) {
        width: unset;
        margin: 0;
      }
    }

    .dln-orderRev__content {
      flex-direction: column;
      padding: 14px 0;
    }
    .dln-orderRev__details {
      flex: 0;
      border-bottom: 0;
      padding: 0;
      position: relative;
    }
    .dln-orderRev__detailsTitle {
      @include fontMedium;
      font-size: REM(19px);
      line-height: 1.3;
    }
    .dln-orderRev__sku {
      @include fontMedium;
      font-size: REM(14px);
      line-height: 14px;
      color: $ui-02;

      margin-bottom: 13px;
    }
    .dln-orderRev__desc {
      font-size: REM(14px);
      line-height: 18px;
    }

    .dln-orderRev__wrap {
      display: block;

      .dln-price,
      .dln-price--oldPrice,
      .dln-price--discounted {
        width: auto;
        flex: 0;
        display: inline-block;
      }

      .dln-price--oldPrice {
        @include fontBook;
        font-size: REM(16px);
        line-height: 18px;
        letter-spacing: -0.17px;
        color: $ui-02;
        margin-right: 17px;

        text-decoration: line-through;
        &::after {
          content: none;
        }
      }
      .dln-price--discounted {
        @include fontBook;
        font-size: REM(19px);
        line-height: 18px;
        letter-spacing: -0.2px;
        color: $brand-04;
      }

      &.dln-orderRev__wrap--delivery {
        position: absolute;
        bottom: 10px;
        right: 0;

        @include fontBook;
        font-size: REM(13px);
        line-height: 16px;
        color: $ui-02;

        border-bottom: 1px solid var(--ui-01);
        padding-bottom: 4px;

        @media only screen and (max-width: 599px) {
          position: relative;
          border: 0;
          bottom: initial;
          right: initial;
          padding: 0;
          margin-bottom: 16px;

          .dln-orderRev__delivery {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    & .dln-orderRev__actions {
      & .dln-orderRev__howItWorks {
        margin-top: 6px;
      }
    }

    .dln-orderRev__wrap--main {
      position: relative;
      flex-direction: column;
      margin-bottom: 14px;

      @media only screen and (max-width: 599px) {
        display: flex;
      }

      .dln-ratingStars {
        position: absolute;
        right: 0;
        top: 0;

        & + .dln-orderRev__detailsTitle {
          max-width: calc(100% - 100px);

          @media only screen and (max-width: 599px) {
            max-width: initial;
          }
        }

        @media only screen and (max-width: 599px) {
          position: relative;
          max-width: initial;
        }
      }
    }
    .dln-orderRev__actions {
      flex: 0;
      margin-top: auto;

      .dln-btn:not(:last-child):not(a) {
        margin: 0 16px 0 0;

        @media only screen and (max-width: 599px) {
          margin: 0;
          margin-bottom: 10px;
        }
      }

      .dln-btn--action-done {
        border: 0;
        @include fontBook;
        font-size: REM(14px);
        line-height: 18px;
        i {
          color: $support-02;
        }
      }
    }

    &--registeredproduct {
      .dln-orderRev__content {
        padding-bottom: 0;
      }
      .dln-orderRev__details {
        .dln-orderRev__wrap {
          display: flex;
          flex-direction: column;
          @media only screen and (max-width: 599px) {
            flex-direction: row;
          }

          &:nth-of-type(2) {
            position: relative;
            bottom: initial;
            right: initial;
          }

          .dln-orderRev__purchaseDate {
            color: $ui-02;
            @include fontBook;
            font-size: REM(13px);
            line-height: 16px;

            b {
              @include fontBook;
              color: $brand-04;
            }
          }
        }
      }

      .dln-orderRev__detailsTitle {
      }
      .dln-orderRev__sku {
        margin-bottom: 20px;
      }

      .dln-orderRev__actions {
        border-top: 1px solid $ui-08;
        margin-top: 10px;
        padding-top: 16px;

        .dln-orderRev__wrap--links {
          &:last-of-type {
            width: 70%;
            @media only screen and (max-width: 599px) {
              width: 100%;
            }
          }
        }

        @media only screen and (max-width: 599px) {
          border: 0;
          margin-top: 23px;
          padding-top: 0;

          .dln-btn {
            &:first-child {
              margin-top: 0;
              margin-bottom: 10px;
            }

            &:not(.dln-btn--stroked) {
              width: auto;
              margin: 0;
              @include fontMedium;
              font-size: REM(14px);
              line-height: 18px;
            }
          }

          .dln-orderRev__wrap--links {
            justify-content: space-between;
            display: flex;
            margin: 0;

            &:last-of-type {
              margin: 0;
            }
          }
        }

        .dln-btn:not(:last-child):not(a) {
          margin: 0 auto 0 0;

          @media only screen and (max-width: 599px) {
            margin: 0;
            margin-bottom: 10px;
          }
        }

        .dln-btn + .dln-btn {
          @media only screen and (min-width: 599px) {
            margin-left: 16px;
          }
        }
      }
    }

    &--cart-order {
      .dln-orderRev__content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
      .dln-orderRev__details {
        margin: 0;
        width: 50%;
        flex: auto;
      }
      .dln-orderRev__actions {
        margin: 0;
        width: 50%;
        flex: auto;
        max-width: none;
        flex-direction: column;
        align-items: flex-end;
        // max-width: none;

        .dln-btn {
          max-width: none;
          width: 200px;
          padding: 0;
          min-width: 0;
          box-sizing: border-box;
        }

        .dln-btn:not(:last-child):not(a) {
          margin: 0;
          margin-bottom: 10px;
        }
      }
    }

    &--simpleview {
      border-bottom: 1px solid $ui-02;
    }

    &--productDescription {
      .dln-orderRev {
        &__itemPic {
          max-width: 190px;
        }
        &__content {
          width: calc(100% - 200px);
        }
        &__desc {
          width: calc(100% - 440px);
          font-size: REM(14px);
          line-height: 1.3;
          color: $brand-04;
        }
        .dln-btn {
          max-width: 190px;
        }

        &__wrap {
          display: flex;
          flex-direction: row;
          margin-bottom: 0;
        }

        &__sku {
          width: calc(50% - 20px);
          margin-bottom: 0;
          font-size: REM(14px);
          line-height: 1.3;
          color: $ui-02;
        }
      }
    }

    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
      &--productDescription {
        .dln-orderRev {
          &__desc {
            width: calc(100% - 365px);
          }
          &__detailsTitle {
            width: calc(60% - 20px);
            margin-bottom: 10px;
          }
          &__content {
            width: calc(100% - 136px);
          }
          &__sku {
            width: calc(40% - 20px);
          }
        }
      }
    }

    @media only screen and (max-width: 599px) {
      padding: 0 0px;
      margin: 0;
      &--cart-order {
        .dln-orderRev__content {
          flex-wrap: wrap;
        }

        .dln-image.dln-orderRev__itemPic {
          width: 61px;
          height: 50px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .dln-orderRev__productCode {
          font-size: REM(13px);
        }

        .dln-orderRev__wrap.dln-orderRev__wrap--main {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          text-align: left;
          align-items: flex-start;
          padding-left: 70px;
          position: relative;

          .dln-orderRev__detailsTitle {
            order: 1;
          }

          .dln-orderRev__productCode {
            order: 2;
          }

          .dln-price {
            order: 3;
            width: auto;
            flex: initial;
            margin: 0;
          }
        }

        .dln-orderRev__wrap.dln-orderRev__wrap--quantity {
          padding-left: 70px;
          text-align: left;
          margin-bottom: 24px;
          color: $ui-02;
        }
        .dln-orderRev__actions {
          width: 100%;
        }
      }

      .dln-orderRev__content {
        padding: 0;
      }

      .dln-orderRev__itemPic {
        padding: 0;
      }

      .dln-orderRev__wrap--main {
        margin-bottom: 16px;
      }
      .dln-ratingStars {
        position: relative;
        order: 3;

        margin-top: 15px;
        margin-bottom: 17px;

        & + .dln-orderRev__detailsTitle {
          max-width: initial;
        }
      }
      .dln-orderRev__detailsTitle {
        order: 1;

        font-size: REM(16px);
      }
      .dln-orderRev__sku {
        order: 2;
        margin: 0;
        font-size: REM(13px);
      }
      .dln-orderRev__reviewTitle {
        order: 4;
      }
      .dln-orderRev__desc {
        order: 5;
      }
      .dln-orderRev__actions {
        .dln-btn:not(.dln-btn--stroked) {
          margin: 0;
          margin-top: 20px;
        }
      }

      &--productDescription {
        padding: 0;
        + .dln-orderRev--productDescription {
          padding-top: 15px;
          margin-top: 15px;
          border-top: 1px solid $ui-01;
        }
        .dln-orderRev {
          &__itemPic,
          &__details {
            height: auto;
          }
          &__detailsTitle {
            margin-bottom: 10px;
          }
          &__content {
            width: calc(100% - 106px);
          }
          &__desc {
            width: calc(100% - 124px);
            margin-top: 10px;
          }
        }
        .dln-btn:not(.dln-btn--link) {
          min-width: 100px;
          max-width: 100px;
          width: 100px;
          background: red;
        }
      }
    }
  }
}

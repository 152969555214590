.dln-head {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 85px;
    background-color: $brand-04;
    /*  box-sizing: content-box; */

    & .dln-wrapper {
        align-items: flex-end;
    }
    &__logo {
        display: inline-flex;
        width: auto;
        height: auto;
        margin: 0 0 20px 0;
        cursor: pointer;

        &Icon {
            display: block;
            width: 85px;
            height: 35px;
        }
    }
    &__nav {
        display: inline-block;
        width: auto;
        height: 90px;
        padding: 16px 0 5px 0;
        margin: 0 auto 0 112px;

        &TopList {
            list-style: inside none;
            vertical-align: top;
            display: inline-block;
            width: auto;
            height: 15px;
            font-size: 0;
            margin: 0 0 14px 0;
        }
        &TopItem {
            display: inline-block;
            width: auto;
            height: 15px;
            margin: 0 17px 0 0;
            position: relative;

            &::after {
                content: "";
                display: block;
                width: 1px;
                height: 15px;
                background-color: $brand-02;
                position: absolute;
                top: 0;
                right: -8px;
            }
            &:last-of-type {
                margin: 0;

                &::after {
                    display: none;
                }
            }
        }
        &TopLink {
            display: inline-block;
            width: auto;
            height: 15px;
            font-size: 13px;
            color: $brand-02;
            text-decoration: none;
        }
        &List {
            list-style: inside none;
            vertical-align: top;
            display: inline-block;
            width: auto;
            height: 40px;
            font-size: 0;
        }
        &Item {
            vertical-align: top;
            display: inline-block;
            height: 40px;
            margin: 0 16px 0 16px;

            &:first-of-type {
                margin: 0 16px 0 0;
            }
            &:last-of-type {
                margin: 0 0 0 16px;
            }
        }
        &Link {
            display: inline-block;
            width: auto;
            height: 40px;
            line-height: 40px;
            font-size: 15px;
            @include fontBold;
            color: $brand-02;
            color: $brand-03;
            text-decoration: none;
            /* padding: 4px 0 0 0; */
        }
    }
    &__btnNav {
        display: inline-flex;
        width: auto;
        margin: 0 0 4px auto;

        & .dln-btnIcon {
            margin: 0 20px 0 0;

            &:last-of-type {
                margin: 0 0 0 0;
            }
        }
    }
    & .dln-btnIcon--mobileMenu {
        display: none;
    }
    & .dln-select {
        &__container {
            &.dln-select--has-value {
                .dln-select__head {
                    border-color: transparent;
                }
            }
        }
        &__head {
            padding: 0;
        }
        &__value {
            line-height: 40px;
            color: $brand-03;
            /* padding: 4px 0 0 0; */
        }
    }
    &--kenwood {
        display: none;
        background-color: $brand-03;
        height: auto !important;
        max-height: 110px;
        border-bottom: 1px solid $ui-01;
        align-content: flex-start;
        .dln-wrapper {
            align-items: center;
        }
        .dln-head__topListWrap {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $ui-01;
            text-align: right;
        }
        .dln-head__logo {
            margin: 0 auto;
            &Icon {
                width: 180px;
                height: auto;
            }
        }
        .dln-btnIcon--mobileMenu {
            width: auto;
            .dln-btnIcon__wrap {
                width: auto;
            }
        }
        .dln-head__nav {
            height: auto;
            &List {
                height: auto;
            }
            &Link {
                height: auto;
                color: $brand-04;
                font-size: 14px;
                line-height: normal;
                padding: 32px 0 22px;
            }
            &TopItem {
                height: auto;
                line-height: normal;
                &::after {
                    display: none;
                }
            }
            &TopLink {
                height: auto;
                line-height: normal;
                padding: 8px 0;
            }
            &TopList {
                box-sizing: border-box;
                margin-right: 20px;
                margin-left: 20px;
                margin-bottom: 0;
                padding-right: 20px;
                padding-left: 20px;
                width: 100%;
                height: auto;
                background-color: $brand-03;

            }
            &Item {
                height: auto;
                text-transform: uppercase;
            }
        }
        .dln-head__btnNav {
            margin: 0 0 0 0;
            .dln-btnIcon {
                margin: 0 30px 0 0;
                &:last-of-type {
                    margin: 0 0 0 0;
                }
            }
            .dln-btnIcon {
                .dln-btnIcon__wrap {
                    width: auto;
                }
                width: auto;
                .dln-icn {
                    color: $brand-04;
                    font-size: 22px;
                }
                &--user {
                    .dln-btnIcon__user {
                        background-color: $brand-04;
                        color: $brand-03;
                    }
                    .dln-btnIcon__userLetter {
                        color: $brand-03;
                    }
                }
                &--search {
                    .dln-icn {
                        color: transparent;
                        &::before {
                            content: 'Q';
                            color: $brand-04;
                        }
                    }
                }
            }
        }
    }
}

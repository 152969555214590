[data-screenset-element-id="gigya-newsletter-screen"] {

  .gigya-input-text {
    padding: 25px 20px;
  }

  .dlg-gigya-button-wrapper input[type=submit] {
    background-color: var(--brand-04);
    margin: 25px 0;
    font-size: 15px;
    width: 50%;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }

  .gigya-custom-checkbox {
    .gigya-input-checkbox {
      opacity: 0;

      + label {
        font-size: 14px;
        padding-left: 10px;

        &::before {
          width: 20px;
          height: 20px;
          margin-top: -7px;
          border-radius: 5px;
        }

        &::after {
          background-color: transparent;
          margin-top: -5px;
        }
      }

      &:checked {
        + label {
          &::after {
            content: 'à';
            font-family: var(--fontNameIcons);
          }

          .gigya-checkbox-text {
            color: var(--brand-04);
            font-weight: 400;
          }
        }
      }
    }

    .gigya-checkbox-text {
      font-size: 14px;
      color: var(--brand-04);
    }
  }

  .gigya-toggle-content {
    a {
      color: var(--brand-02);
    }
  }

	// SMS Label styling
	.gigya-composite-control.js-sms-component.dlg-gigya-input--active {
		label {
			transform: none;
			background-color: transparent;
		}
	}

	.gigya-composite-control.js-sms-component {
		label {
			top: calc(50% - 21px);
			color: var(--brand-02) !important;
		}

		input {
			padding-left: 65px !important;
		}
	}

  #gigya-subscribe-with-email-form {
    > .gigya-layout-row {

      .gigya-layout-row:nth-child(5) {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        i {
          background: black;
          width: 15px;
          height: 15px;
          display: inline-block;
          color: #fff;
          border-radius: 50%;
          text-align: center;
          font-size: 13px;
          font-weight: 700;
          cursor: pointer;
          margin-left: 5px;
        }

        .gigya-toggle-content {
          width: 100%;
          color: var(--brand-02);
        }
      }
    }
  }

  @media (min-width: 1024px) {
    #gigya-subscribe-with-email-form {
      > .gigya-layout-row {
          display: flex;
          flex-wrap: wrap;

        .gigya-layout-row {
          width: 25%;
          margin-right: 24px;
        }

        .gigya-layout-row:nth-child(1),
        .gigya-layout-row:nth-child(2)  {
          width: 46% !important;
          margin-right: 0 !important;
        }

        .gigya-layout-row:nth-child(2) {
          margin-left: 8%;
        }
  
        .gigya-layout-row:nth-child(3) {
          display: none;
        }

        .gigya-layout-row:nth-child(4) {
          width: 100% !important;
        }

        .gigya-layout-row:nth-child(4),
        .gigya-layout-row:nth-child(5) {
          margin-right: 0;
        }

        .gigya-layout-row:nth-child(5) {
          width: 100%;
        }
      }
    }
  }
}

.dln-braun [data-screenset-element-id="gigya-newsletter-screen"] {
	form {
		.gigya-composite-control.js-sms-component {
			label {
				top: calc(50% - 20px);
			}
		}
	}
}

.dln-kenwood [data-screenset-element-id="gigya-newsletter-screen"] {
	form {
		.gigya-composite-control.js-sms-component {
			label {
        position: relative;
				top: 37px;
				left: 5px;
			}

			input {
				padding-left: 50px !important;
			}
		}
	}
}

.dln-persDataRecap__wrap {
  width: 100%!important;

  .gigya-screen-caption {
    display: none;
  }

  .gigya-profile-form {
    display: flex;
    flex-flow: row wrap;

    .gigya-layout-row:first-child,
    div:nth-child(11){
      width: 100%; // hack
      margin: 0; // hack
    }

    div.gigya-container.gigya-container-enabled {
      display: inline-block;
    }

    .gigya-layout-row {
      width: 100%;

      @media (min-width: 900px) {
        width: 50%;
      }

      margin-bottom: 10px;

      .dlg-profile-label {
        color: var(--brand-02);
        font-size: 13px;
        line-height: 125%;
      }

      .dlg-profile-label-value {
        font-family: var(--fontNameBold);
        font-weight: 700;
        font-style: normal;
        color: var(--brand-04);
        margin-left: 5px;
      }
    }

  }

  .dln-btn--link {
    float: right;
  }
}

.language-zh {
  .dln-persDataRecap__wrap {
    .gigya-profile-form {
      .gigya-layout-row:first-child,
      div:nth-child(10){
        width: 100%;
        margin: 0;
      }
    }
  } 

&.dln-kenwood {
  .dln-persDataRecap__wrap {
    .gigya-profile-form {
      .gigya-layout-row:first-child,
        div:nth-child(9){
          display: none;
        }
      }
    } 
  }
}

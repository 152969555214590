.dln-wishlist {
    &--empty {
        .dln-wishlist {
            align-content: flex-start;

            & .dln-wrapper {
                padding-top: 48px;
                padding-bottom: 0;
            }
            &__aside {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;
                width: 264px;
                margin: 0 40px 0 0;
            }
            & .dln-profileCard {
                margin: 0 0 32px 0;
            }
            &__content {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                width: calc(100% - 264px - 40px);
            }
            &__section {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;
                width: 100%;
                margin: 0 0 64px 0;

                &Title,
                & .dln-title {
                    display: block;
                    width: 100%;
                    height: auto;
                    line-height: 32px;
                    font-size: 32px;
                    @include fontBold;
                    margin: 0 0 0 0;
                    &--labeled {
                        display: flex;
                    }
                }

            }
            .dln-title {
                &+.dln-wishlist__paragraph {
                    margin-bottom: 0;
                }
            }
            &__paragraph {
                display: block;
                width: 100%;
                line-height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: $brand-04;
                margin: 0 0 20px 0;

                &--info {
                    color: $brand-02;
                    margin: 0 0 32px 0;
                }
                &--noMarg {
                    margin: 0;
                }
            }
            &__underlinedText {
                color: $brand-02;
                text-decoration: underline;

                &:hover {
                    color: $brand-04;
                }
            }
            &__text {
                display: block;
                width: 100%;
                height: auto;
                line-height: 20px;
                font-size: 13px;
                @include fontBold;
                // text-transform: uppercase;
            }
            .dln-tab__content {
                .dln-wishlist__title {
                    margin: 32px 0;
                    opacity: 0;
                    transition: opacity .3s ease .1s;
                }
                &--active {
                    .dln-wishlist__title {
                        opacity: 1;
                    }
                }
            }

            &--empty {
                .dln-wishlist__content {
                    & .dln-title {
                        margin: 0 0 28px 0;
                    }
                }
            }
        }
    }
}
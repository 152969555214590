.dln-delonghi {
    & .dln-addressResult {
        &__company {
            @include fontSemibold;
        }

        &__productCategory {
            text-align: start;
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-thankYou {
        &__innerWrap {
            .dln-thankYou__content {
                &--delivery,
                &--payment,
                &--aside {
                    width: 100%;
                    margin: 0;
                    &:nth-child(4n+4) {
                        margin: 0 0;
                    }
                }
            }
            >.dln-btn {
                max-width: 100%;
                margin: 24px 20px 0;
            }
        }
    }
}
.dln-kenwood {
    .dln-salesTax {
        .dln-popup__headTitle {
            display: block;
            @include fontExtra;
            font-size: 32px;
            margin-top: 32px;
        }
        .dln-btn {
            height: 45px;
        }
        &__content {
            padding: 0;
        }
        @media screen and (max-width: 599px) {
            .dln-popup__headTitle {
                font-size: 28px;
                margin-right: 0;
                margin-left: 0;
                padding: 0;
                width: 100%;
                max-width: 100%;
            }
            .dln-inputs {
                width: 100%;
                margin: 0 0 16px;
            }
            .dln-btn {
                margin: 0 0 0 auto;
                width: auto;
            }
        }
    }
}
.dln-instSteps {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    list-style: inside none;

    &__step {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
        &Num {
            display: block;
            width: 30px;
            height: 30px;
            line-height: 30px;
            color: $brand-03;
            text-align: center;
            font-size: 12px;
            border-radius: 50%;
            background-color: $brand-01;
            margin-right: 15px;
        }
        &Text {
            display: block;
            width: calc(100% - 30px - 15px);
            line-height: 20px;
            font-size: 14px;
            color: $brand-01;
            padding: 5px 0;
        }
    }
}

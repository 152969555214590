.dln-delonghi {
    & .dln-wishlist.dln-wishlist--shared  {
        & > .dln-wrapper {
            padding-top: 40px;
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-delonghi {
        & .dln-wishlist.dln-wishlist--shared  {
            & .dln-wrapper {
                & .dln-wishlist__content {
                    padding-top: 0;
    
                    & .dln-title {
                        margin-top: 0;
                        margin-bottom: 24px;
                    }
                    & .dln-wishlist__text {
                        margin-bottom: 40px;
                    }
                } 
            }
        }
    }
}
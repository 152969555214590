.dln-delonghi {
  & .dln-prodReg {
    &__notice {
      & .dln-noticeBox {
        min-height: 144px;
      }
    }
    // &__content {
    //   & .dln-prodReg__chips {
    //     & .dln-chips {
    //       & .dln-icn {
    //         align-self: flex-start;
    //       }
    //       & .dln-chips__content {
    //         line-height: 24px;
    //         font-size: REM(18px);
    //       }
    //     }
    //   }
    // }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg {
      &__content {
        & .dln-prodReg__chips {
          & .dln-chips {
            & .dln-chips__content {
              font-size: REM(16px);
            }
          }
        }
      }
    }
  }
}

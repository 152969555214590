.dln-orderCancellationConfirm {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & .dln-popup__head {
        padding: 17px 19px 44px 19px;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        padding: 0 100px 70px 100px;
        box-sizing: border-box;

        & > .dln-icn {
            width: 48px;
            height: 48px;
            line-height: 48px;
            font-size: 48px;
            color: $support-02;
        }
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 48px - 36px);
        margin: 0 0 0 36px;
    }
    &__text {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 30px;
    }
    & .dln-btn--orderDetail {
        width: 180px;
    }
}

@media only screen and (max-width:599px) {
    .dln-footCheckout {
        padding: 20px 0 10px 0;

        .dln-wrapper {
            width: calc(100% - 32px);
            /* padding: 0 16px 0 16px; */

        }
        &__wrap {
            justify-content: center;
            width: 100%;
            border-bottom: 1px solid $brand-02;
            padding: 0 0 24px 0;
            margin: 0 0 10px 0;
        }
        &__logo {
            width: 68px;
            height: 28px;

            &Icon {
                width: 68px;
                height: 28px;
            }
        }
        &__description {
            text-align: center;
        }
        &__list {
            display: flex;
            width: 100%;
        }
        &__item {
            padding: 0 5px 0 5px;

            &:first-of-type {
                padding: 0 5px 0 0;
            }
            &:last-of-type {
                padding: 0 0 0 5px;
            }
            &::after {
                top: 16px;
            }
        }
        &__link {
            height: 37px;
            line-height: 37px;
            margin: 0 auto;
        }
    }
}
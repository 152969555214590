.dln-delonghi {
  .dln-registration {
    .dln-registration__btnGroup {
      .dln-btn {
        width: calc(50% - 10px);
        min-width: auto;
      }
    }
    .dln-radioBtn__lbl::after {
      background: $brand-04;
    }
    .dln-inputs {
      border-color: $brand-04;
    }

    .dln-registrationForm__formFoot {
      .dln-btn {
        max-width: 285px;
      }
      .dln-registrationForm__paragraph--secondary {
        @include fontMedium;
        font-size: REM(16px);
        color: $brand-04;
        text-decoration: underline;
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-registrationForm__formFoot {
        .dln-btn {
          max-width: none;
        }
      }
    }
  }
}

[data-screenset-element-id="gigya-update-profile-screen"]{
  .gigya-layout-row{
	// title
	.dlg-gigya-input-select-wrapper{
	  @include breakpoint('gigya'){
		width:49%;
	  }
	}
	// firstName lastName
	.gigya-layout-cell:nth-child(2) .gigya-layout-row{
	  margin-top:16px;
	  @include breakpoint('gigya'){
		display:flex;
		justify-content:space-between;

		> .dlg-gigya-input-text-wrapper, .gigya-visible-when{
		  width:49%;
		}
	  }
	}
  }
  // homePhoneNumber mobilePhoneNumber
  form > .gigya-layout-row:nth-child(2){
	@include breakpoint('gigya'){
	  display:flex;
	  justify-content:space-between;

	  > div:first-of-type, div:nth-child(2){
		width:49%;
	  }
	}
  }
  // emailAddress dateOfBirth
  form > .gigya-layout-row:nth-child(3){
	@include breakpoint('gigya'){
	  display:flex;
	  justify-content:space-between;

	  > .dlg-gigya-input-text-wrapper{
		width:49%;
	  }
	}
  }
  .gigya-profile-form {

	.dln-btn--cancel {
	  float:left;
	}

	.dln-btn--update {
	  max-width:184px;
	  float: right;
	  padding: 0!important;

	  .gigya-input-submit {
		font-size: 14px;
		font-family: var(--fontNameBold);
		font-weight: 700;
		font-style: normal;
		color: var(--brand-03);
		text-align: center;
		border: none;
		background-color: var(--brand-01);
		outline: none;
		cursor: pointer;
		width: 100%;
		height: 100%;
	  }
	}
  }
}

.dln-kenwood {
	[data-screenset-element-id="gigya-update-profile-screen"] {
		.dln-btn--update {
			.gigya-input-submit {
				text-transform: uppercase;
			}
		}
	}
}
.dln-kenwood {
  & .dln-orderDetailHead {
    padding: 30px 20px;
    position: relative;

    &__number {
      @include fontExtra;
      width: calc(100% - 20px - 56px);
      line-height: 36px;
      font-size: 28px;
      margin-right: 20px;
      margin-bottom: 30px;
    }
    &__cancel {
      @include fontBold;
      color: $brand-04;
      position: absolute;
      right: 24px;
      top: 24px;
      width: auto;
      cursor: pointer;
    }
    &__wrap {
      & .dln-orderDetailHead__dataCell {
        & .dln-orderDetailHead__dataLabel {
          line-height: 24px;
          margin-bottom: 0;
        }
        & .dln-orderDetailHead__data {
          line-height: 24px;
        }
      }
    }
    &--shipped {
      & .dln-orderDetailHead__number {
        margin-bottom: 12px;
      }
      & .dln-orderDetailHead__email {
        margin-bottom: 25px;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-kenwood {
    & .dln-orderDetailHead {
      &__number {
        width: 100%;
        line-height: 32px;
        font-size: 24px;
        margin: 0 0 25px 0;
      }
      & .dln-orderDetailHead__cancel {
        margin-top: 28px;
        position: static;
      }
      &__wrap {
        margin-bottom: 0;

        & .dln-orderDetailHead__dataCell {
          padding-bottom: 12px;
          padding-right: 8px;

          &:nth-of-type(2n + 2) {
            padding-right: 0;
            padding-left: 8px;
          }
          &:nth-last-of-type(2),
          &:last-of-type {
            padding-bottom: 0;
          }
          & .dln-orderDetailHead__dataLabel {
            font-size: 16px;
          }
          & .dln-orderDetailHead__data {
            font-size: 16px;
          }
        }
      }
      &--shipped {
        & > * {
          order: initial;
        }
        & .dln-orderDetailHead__number {
          order: 1;
          margin-bottom: 10px;
        }
        & .dln-orderDetailHead__email {
          order: 2;
          line-height: 24px;
          font-size: 16px;
        }
        & .dln-orderDetailHead__wrap {
          order: 3;
        }
        & .dln-orderDetailHead__cancel {
          order: 4;
        }
      }
    }
  }
}

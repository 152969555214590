.dln-delonghi {
    .dln-page.dln-address--editing {
        .dln-wrapper {
            .dln-page__content {
                .dln-address__foot {
                    margin: 0;
                    padding: 0;

                    @media only screen and (max-width:599px) {
                        margin-top: 20px;
                    }

                    .clearer {
                        margin-top: 30px;
                        margin-bottom: 24px;
                    }
                }

                .dln-title {
                    @media only screen and (max-width: 599px) {
                        border-bottom: 1px solid var(--ui-11);
                        text-align: left;
                        font-size: REM(18px) !important;
                        line-height: REM(22px) !important;
                        display: inline-block !important;
                        padding: 21px 0;
                        margin-top: 0px;
                    }
                }

                .dln-address__section {
                    .dln-address__form {
                        .dln-address_info {
                            .dln-tooltip {
                                color: var(--brand-01);
                            }
                        }
                    }
                }
            }
        }
    }
    .dln-address--editing {
        .dln-address__foot .dln-checkbox__inputPlaceholder:after {
            top: 50%;
            left: 50%;
            color: $brand-04;
        }
    }
}
@media only screen and (max-width:599px) {
  .dln-club-membership {
    &__section {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    &__column {
      width: 100%;
      padding: 0 20px 25px;

      .dln-btn {
        width: 100%;
      }
    }
  }
}

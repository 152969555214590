.dln-delonghi {
    .dln-socialAcc {
        &__socialbtnGroup {
            .dln-btn {
                width: calc(50% - 5px);
                min-width: auto;
            }
        }

        .dln-inputs {
            width: 100%;
            min-width: 100%;
        }

        @media only screen and (max-width:599px) {
            // .dln-registrationForm__formFoot {
            //     .dln-btn {
            //         max-width: none;
            //     }
            // }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-faqAnnouncement {
        & .dln-popup__head {
            width: calc(100% - 40px);
            padding-top: 54px;
            padding-bottom: 20px;
            margin-right: 20px;
            margin-left: 20px;
            position: relative;

            & .dln-btnIcon--close {
                position: absolute;
                top: 14px;
                right: 0;
            }
            &Title {
                font-size: 32px;
                padding: 0;
                margin: 0;
            }
        }
        &__content {
            padding: 0 20px;
        }
        &__actions {
            width: calc(100% - 40px);
            padding: 32px 0 0;
            margin: 0 auto 32px;
        }
    }
}
.dln-delonghi {
  .dln-accordion {
    &:nth-child(even):not(:empty),
    &:nth-child(odd):not(:empty) {
      background-color: transparent;
    }

    &--item-review {
      & .dln-accordion__head {
        padding: 5px 0;

        & .dln-accordion__imgWrap {
          margin-right: 10px;
        }
        & .dln-accordion__title {
          @include fontRoman;
          line-height: 19px;
          font-size: REM(19px);
          color: $ui-02;
        }
      }
      & .dln-accordion__content {
        padding: 0 0 10px 70px;

        & .dln-price {
          @include fontBook;
          line-height: 16px;
          font-size: REM(13px);
          padding: 0;
          margin: 0;

          &--secondary {
            color: $brand-02;
          }
        }
        & .dln-price + .dln-price {
          margin-top: 16px;
        }
      }
    }
    & .dln-accordion__title {
      @include fontBook;
      line-height: 24px;
      font-size: REM(18px);
      color: $ui-02;
      padding: 18px 0 0 0;
    }
    &--open,
    &--showInfo {
      & .dln-accordion__title {
        @include fontMedium;
        color: $brand-04;
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    .dln-accordion {
      &--item-review {
        .dln-accordion__head {
          .dln-accordion__title {
            line-height: 16px;
            font-size: REM(16px);
          }
        }
        .dln-accordion__content {
          padding: 0 0 12px 70px;
        }
      }
    }
  }
}

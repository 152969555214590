@media only screen and (max-width: 599px) {
  .dln-cart__aside .dln-cart__orderRecap {
    padding: 32px 0;

    & .dln-orderSumm__contentRow--promo {
      padding: 24px 20px;
    }

    & .dln-orderSumm__price-wrapper {
      padding: 24px 20px;
    }

    & .dln-cart_paymentBox {
      padding: 20px 20px;
    }

    & .dln-cart__button-wrapper {
        margin: 30px 20px 20px;
        padding: 0;

        #AmazonPayButton {
            margin: 10px auto 0;
        } 
    }
    
    & .dln-cart__button-wrapper {
      margin: 30px 20px 20px;
      padding: 0;
    }
  }

  .dln-orderSumm {
    transition: max-height 0.4s linear 0.1s;
    overflow: hidden;
    padding-top: 0;

    &__head {
      display: flex;
      cursor: pointer;
      justify-content: space-between;
      padding: 0 20px;
      height: 75px;

      .titleCounter {
        display: flex;
        align-items: center;
      }

      .dln-orderSumm__title {
        @include mainMenu;
        margin: 0 0;
      }
      
      .dln-icn--arrow-down {
        display: inline-block;
        margin: 0 0 0 auto;
      }
    }

    &:not(.dln-orderSumm--light) {
      .dln-orderSumm__content {
        opacity: 0;
        transition: opacity 0.2s linear;
      }

      .dln-icn {
        &--cart {
          display: none;
        }
        &--arrow-down {
          display: inline-block;
          margin: 0 0 0 auto;
        }
      }
    }

    &--checkout {
      .dln-orderSumm {
        &__head {
          cursor: pointer;
          & .dln-icn {
            &--cart {
              display: inline-block;
              margin: 0 8px 0 0;
            }
            &--arrow-down {
              margin: 0 0 0 8px;
            }
          }
          > span {
            margin: 0 0 0 auto;
          }
        }
      }
    }

    &--open {
      transition-delay: 0s;
      & .dln-orderSumm__content {
        opacity: 1 !important;
        transition-delay: 0s;
      }
      & .dln-icn {
        &--arrow-down {
          transform: rotate(-180deg);
        }
      }
    }

    &__contentRow--taxes,
    &__contentRow--shipping {
      .dln-tooltip {
        & .dln-tooltip__container {
            width:100%;
            min-width: 120px;
        }
  
        & .dln-tooltip__content {
            width:100%;
            text-align: left;
        }
  
        & .dln-tooltip__text {
            max-width: none;
        }
      }
    }
  }
}
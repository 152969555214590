.dln-addBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    border: 1px solid $ui-04;
    background: $brand-03;
    padding: 20px 20px 20px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    .dln-icn {
        margin: 0 0 20px 0;
        font-size: REM(48px);
        color: $ui-04;
    }
    &__icon {
        margin: 0 0 20px 0;

        &Fill {
            transition: fill 0.12s linear;
            fill: $ui-04;
        }
    }
    &__title {
        display: block;
        width: 100%;
        height: auto;
        line-height: REM(20px);
        font-size: REM(14px);
        @include fontBold;
        color: $support-02;
        text-align: center;
        transition: color 0.12s linear;
        margin: 0 0 16px 0;
    }
    &__text {
        display: block;
        width: 100%;
        height: auto;
        line-height: REM(20px);
        font-size: REM(13px);
        color: $ui-04;
        text-align: center;
        transition: color 0.12s linear;
    }
    &:hover {
        border-color: $brand-02;

        & .dln-addBox__iconFill {
            fill: $brand-02;
        }
        & .dln-addBox__title,
        & .dln-addBox__text {
            color: $brand-02;
        }
    }
    &__button {
        display: -webkit-box;
        height: fit-content;

        .dln-icn {
            color: $support-02
        }
    }

}
@media only screen and (max-width:599px) {
    .dln-head {
        height: 60px;

        & .dln-wrapper {
            align-items: center;
            height: 60px;
        }
        &__logo {
            width: 58px;
            height: 24px;
            margin: 0;

            &Icon {
                width: 58px;
                height: 24px;
            }
        }
        &__nav {
            display: none;
        }
        &__btnNav {
            margin: 0 0 0 auto;

            & .dln-btnIcon {
                margin: 0;
            }
        }
        & .dln-btnIcon--mobileMenu {
            display: block;
        }
        &--kenwood {
            .dln-head__topListWrap {
                display: none;
            }
            .dln-head__navList {
                display: none;
            }
            .dln-head__logo {
                width: 150px;
                height: auto;
                margin: 0 auto;

                &Icon {
                    width: 100%;
                    height: auto;
                }
            }
            .dln-head__btnNav {
                margin: 0;
                .dln-btnIcon {
                    margin: 0 20px 0 0;
                }
            }
            .dln-btnIcon--search {
                display: none;
            }
            .dln-btnIcon--mobileMenu {
                .dln-icn {
                    font-size: 24px;
                }
            }
        }
    }
}

@media only screen and (max-width:320px) {
    .dln-head {
        &--kenwood {
            .dln-head__logo {
                width: 120px;
            }
        }
    }
}










@media only screen and (min-width:320px) and (max-width:599px) {
    .bra-header {
        padding: 18px 20px 17px 20px;

        .bra-header__logo {
            font-size: 25px
        }

        .bra-quickLinks__wrapper {
            display: none;
        }
    }
}
@media only screen and (max-width:320px) {
    .bra-header {
        padding: 18px 20px 17px 20px;

        .bra-btnIcon {
            font-size: 20px
        }
        .bra-quickLinks__wrapper {
            display: none;
        }

        &--shrink {
            padding: 20px 30px;
        }
    }
}
.dln-table {
  padding: 0;

  &__table {
    thead {
      th {
        @include fontMedium;
        border-color: $ui-01;

        font-size: REM(16px);

        padding: 5px 20px !important;

        &:nth-child(1) {
          padding: 5px 20px;
        }
        &:nth-last-child(1) {
          padding: 5px 20px;
          padding-left: 0;
        }
      }
    }
    tbody {
      tr {
        @media only screen and (max-width: 599px) {
          border-top: 1px solid $ui-01;
        }
        &:nth-last-child(1) {
          @media only screen and (max-width: 599px) {
            border-bottom: 1px solid $ui-01;
          }
        }
      }
      td {
        border-bottom: 1px solid $ui-01;

        padding: 12px 20px !important;

        font-size: 0;
        line-height: 0;

        &:nth-last-child(1) {
          padding: 12px 20px;
          padding-left: 0;
        }

        &:not(.dln-table__fullwidth),
        &.dln-table__fullwidth {
          @media only screen and (max-width: 599px) {
            width: calc(100% - 40px);
            border: 0;
            padding: 10px 20px !important;
          }
        }

        @media only screen and (max-width: 599px) {
          border: 0;
          padding: 10px 20px !important;

          &:nth-child(1) {
            width: calc(100% - 30px);
            padding: 10px 20px !important;
            padding-right: 10px !important;
          }

          &:nth-child(4) {
            grid-area: 1 / 2 / 1 / 2;
            width: calc(100% - 20px);
            padding: 10px !important;
          }
          &:nth-child(2) {
            grid-area: 1 / 3 / 1 / 3;
            width: calc(100% - 30px);
            padding: 10px 20px !important;
            padding-left: 10px !important;
          }
        }

        .dln-table__tdValue {
          display: block;

          @include fontBook;

          font-size: REM(16px);
          line-height: 24px;

          @media only screen and (max-width: 599px) {
            font-size: REM(13px);
            line-height: 16px;
          }
        }
        .dln-table__tdHeading {
          @media only screen and (max-width: 599px) {
            font-size: REM(13px);
            line-height: 16px;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-shipDelivery {
    padding-bottom: 70px;

    &__title,
    &__text {
      font-size: REM(14px);
    }

    .dln-popup {
      &__head {
        &Title {
          font-size: REM(16px);
        }

        &Line {
          background: $ui-01;
        }
      }
    }
  }
}

@media screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    & .dln-shipDelivery {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.dln-delonghi {
    & .dln-country-list {
        & .dln-flag__list {
            &Item {
                & .dln-flag__languages {
                    & .dln-flag__listLink {
                        & .dln-flag__lang {
                            color: $brand-02;

                            & strong {
                                color: $brand-04;
                            }
                        }
                    }
                }
            }
        }
        & .dln-accordion {
            &__head {
                & .dln-accordion__title {
                    @include fontMedium;
                    color: $brand-04;
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-delonghi {
        & .dln-country-list {
            & .dln-accordion {
                max-height: 60px;

                &:first-child {
                    & .dln-accordion__head {
                        border-top: none;
                    }
                }
                &__head {
                    padding: 16px 22px 16px 16px;

                    & .dln-accordion__title {
                        line-height: 28px;
                    }
                }
                &__content {
                    padding-top: 8px;

                    & .dln-flag__list {
                        padding: 0 16px;

                        & .dln-flag__listItem {
                            margin-bottom: 34px;
                        }
                    }
                    & > p {
                        padding: 0 16px;
                    }
                    & > .dln-btn {
                        margin: 0 auto 0 16px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .dln-recipe-single {
        &__buy-now-btn,
        &__plusIcon {
            display: inline-block;
            align-self: center;
        }

        .dln-wrapper {
            padding: 20px 20px 0;
        }

        .dln-breadcrumbs {
            font-size: 13px;
            line-height: 1.38;
        }

        &__grid {
            flex-direction: column-reverse;
            max-height: none;
            width: calc(100% + 40px);
            margin-left: -20px;
            margin-right: -20px;
        }

        &__col {
            flex: 1 1 100%;
            min-width: 100%;
            max-width: 100%;
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: space-between;

            &.dln-recipe-single__col-gray,
            &.dln-recipe-single__col-white {
                flex-direction: row;
                display: flex;
                padding: 0;
                .dln-recipe-single__col-content {
                    padding: 40px 20px;
                }
            }

            &-gray {
                justify-content: flex-end;

                .dln-recipe-single__col-content {
                    background: $ui-03;
                    padding-right: 30px;
                }
            }
            &-white {
                .dln-recipe-single__title-content {
                    margin-bottom: 17px;
                }
            }
            &-3 {
                flex: 1 1 100%;
                max-width: 100%;

                &.padding {
                    padding: 0;
                }
            }
            &-9 {
                flex: 1 1 100%;
                max-width: 100%;
            }
        }

        &__bottom-info {
            margin-top: 45px;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            height: auto;

            &-col {
                flex: 1 1 50%;
                margin-bottom: 15px;
            }
            &-col + .dln-recipe-single__bottom-info-col  {
                border-left: none;
                margin-left: 0;
                padding-left: 0;
            }
        }

        &__title-recipe {
            margin-top: 20px;
            font-size: 24px;
            line-height: 1.13;
        }

        &__description {
            margin-top: 15px;
            margin-bottom: 0;
        }

        &__product-attachment {
            flex-direction: column;
        }
        &__product {
            flex-direction: column;

            &-content {
                height: auto;
                border-right: none;
                padding: 15px 0 30px;
                border-bottom: 1px solid #e6e6e6;
                // padding-right: 0;
            }

            &-is-prepared {
                @include fontBold;
                color: $brand-01;
                font-size: 24px;
                line-height: 1.13;
                align-items: center;
                flex-direction: row;
                padding: 60px 0 10px;
                border-bottom: none;
            }
            .dln-btn {
                max-width: 160px;
                width: 100%;
            }
        }

        &__buy-now-btn {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        &__attachment {
            padding: 15px 20px 30px;

            .dln-btn {
                margin: 0 auto;
                max-width: 160px;
                width: calc(100% - 20px);
            }

            .dln-recipe-single__product {
                &-content {
                    border-bottom: none;
                }
            }
        }

        &__product,
        &__attachment {
            color: $brand-02;
            span {
                @include fontMedium;
                margin-bottom: 5px;
            }

            .dln-image {
                width: 80px;
                height: 80px;
            }
            .dln-recipe-single__product-title {
                @include fontBold;
                color: $brand-01;
                font-size: 15px;
                line-height: 1.4;
                text-align: center;
                margin-top: 5px;
            }
        }

        &__servings-section {
            padding-top: 0;
        }

        &__grid-how-to {
            .swiper-slide {
                width: 80%;
            }
            &.swiper-container {
                flex-direction: column;
                width: 100%;
                overflow: visible;
                margin: 0;
                .swiper-scrollbar {
                    margin-top: 40px;
                    position: relative;
                }
            }
            .swiper-wrapper {
                flex-wrap: nowrap;
            }
            .swiper-slide {
                padding: 0;
            }
            .swiper-pagination {
                display: none;
            }
            .dln-image {
                height: 185px;
            }
        }

        &__step {
            & .dln-recipe-single__videoContainer {
                height: 315px;
            }
        }

        &__title-content {
            font-size: 24px;
            line-height: 1.13;
            padding: 0;
        }

        &__products-links {
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
            width: 100%;
            font-size: 15px;
            line-height: 1.4;

            &-label {
                padding: 0;
            }
            &-title {
                font-size: 24px;
                line-height: 1.13;
            }
            &-description {
                order: 3;

                &-dk {
                    display: none;
                }
                &-mb {
                    display: block;
                }
            }
            .dln-recipe-single__col-3 {
                border: none;
            }
            .dln-recipe-single__col-9 {
                width: 100%;
            }

            .dln-recipe-single__grid {
                margin-left: 0;
                margin-right: 0;
            }
            .dln-recipe-single__grid-products {
                display: flex;
                flex-direction: row;
                padding: 0;
                width: calc(100% + 60px);
                margin-top: -15px;
                margin-left: -30px;
                margin-right: -30px;
                .dln-recipe-single__col-3 {
                    flex: 1 1 calc(50% - 15px);
                    max-width: calc(50% - 15px);
                    padding: 0 15px;
                }
            }
            .dln-recipe-single__col {
                padding: 0;
            }
        }

        &__accordion {
            list-style: none;

            .dln-recipe-single__accordion-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .dln-recipe-single__accordion-content {
                max-height: 0;
                transition: all 0.5s ease;
                overflow: hidden;
                padding-top: 0;
            }

            &.active {
                .dln-recipe-single__plusIcon::after {
                    transform: translateX(-50%) rotate(90deg);
                }

                .dln-recipe-single__accordion-content {
                    max-height: 1000px;
                    padding-top: 20px;
                }
            }

            // + .bra-multi-accordion__item .bra-multi-accordion__expander {
            //     border-top: 1px solid $ui-08;
            // }
        }

        &__plusIcon {
            height: 20px;
            width: 20px;
            min-width: 20px;
            position: relative;

            &::before,
            &::after {
                content: ' ';
                position: absolute;
                display: block;
                background: black;
                border-radius: 2px;
            }

            &::before {
                width: 20px;
                height: 3px;
                top: 50%;
                transform: translateY(-50%);
            }
            &::after {
                height: 20px;
                width: 3px;
                left: 50%;
                transform: translateX(-50%);
                transition: transform 0.5s ease;
            }
        }

        &__play-button {
            height: 60px;
            width: 60px;

            &:before {
                border-width: 10px 17px 10px 0;
            }
        }

        .dln-recipe-slides {
            margin-top: 50px;
        }
    }
}

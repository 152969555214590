.dln-delonghi {
  & .dln-cart {
    & > .dln-wrapper {
      & .dln-cart__head {
        & .dln-cart__title {
          @include fontMedium;
          line-height: 52px;
          font-size: REM(50px);

          & > .dln-icn {
            color: $brand-04;
          }

          & .dln-cart__titleLbl {
            font-size: REM(18px);
            margin-left: 10px;

            @media screen and (max-width: map-get($grid-breakpoints, xs)) {
              font-size: REM(12px);
            }
          }

          & + .dln-btn {
            & .dln-icn {
              font-weight: bold;
            }

            & .dln-btn__lbl {
              @include fontMedium;
              text-decoration: none;
            }
          }
        }

        .dln-chips.dln-chips--advantage .close {
          font-size: 1rem;
        }
      }

      & .dln-cart__content {
        & .dln-cart__info {
          margin-bottom: 30px;
          padding: 20px 20px 10px;
          width: 100%;
          color: #757678;
          border: 1px solid #e5e5e5;

          @media screen and (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
            margin: 0 auto;
            width: 475px;
            text-align: center;
            padding: 5px 0;
          }
        }
      }
    }

    &__orderRecap {
      & .dln-btn {
        &.dln-btn--checkout {
          width: 100%;
          background: $brand-01 !important;
          height: 48px;
          font-size: REM(21px);
        }

        &--paypal {
          width: 100%;
          height: 40px !important;

          & .dln-btn__lbl {
            font-size: REM(13px);
          }

          & .dln-btn__icon {
            margin-top: 4px;
            height: 70%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, xs)) and (max-width: 1100px) {
  .dln-delonghi {
    & .dln-cart {
      & > .dln-wrapper {
        padding: 0;
        margin: 0;

        & .dln-cart__head {
          padding: 12px 20px;
          margin-bottom: 0;
          min-height: 0;

          & .dln-cart__title {
            font-size: REM(21px);
            line-height: 1.38;
          }

          .dln-btn.dln-btn--link.dln-btn--icon {
            display: inline-block;
          }
        }

        .dln-cart {
          &__content,
          &__services,
          &__aside--secondary {
            padding-left: 20px;
            padding-right: 20px;
          }

          &__services {
            .dln-checkServiceBox__title {
              text-align: left;
            }
          }
        }

        .dln-chips.dln-chips--advantage {
          .dln-chips__content {
            span {
              b {
                font-weight: normal;
              }
            }
          }
        }
      }

      &__orderRecap {
        & .dln-btn {
          &--paypal {
            height: 40px !important;

            .dln-btn__lbl {
              font-size: REM(13px);
            }

            .dln-btn__icon {
              margin-top: 4px;
              height: 70%;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-recipe-single {
    & .dln-recipe-single__videoContainer {
      background-color: $brand-04;
    }
    @media only screen and (max-width: 599px) {
      .dln-recipe-single__title-content {
        font-size: REM(21px);
      }

      .dln-recipe-single__servings-col {
        font-size: REM(18px);
      }
    }
    & > .dln-wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      padding-top: 10px;

      & .dln-breadcrumbs {
        margin-bottom: 10px !important;
      }
    }
    & .dln-recipe-details {
      margin-top: 0;

      & .dln-wrapper {
        & .dln-recipe-details__grid {
          & .dln-recipe-details__col {
            & .dln-recipe-details__info {
              & .dln-recipe-details__title-recipe {
                line-height: 52px;
                font-size: REM(50px);
              }
            }
          }
        }
      }
      & + .dln-wrapper {
        border-bottom: 1px solid $ui-01;
      }
    }
    & .dln-recipe-single__product-attachment {
      align-items: flex-start;
      width: 100%;
      padding-top: 14px;

      & .dln-recipe-single__col {
        margin-right: auto;

        &.dln-recipe-single__attachment {
          display: none;
        }
        & .dln-recipe-single__product-content {
          & > span:not(.dln-recipe-single__product-title) {
            color: $ui-02;
            text-transform: uppercase;
          }
        }
      }
    }
    & .dln-recipe-single__grid-info {
      & .dln-recipe-single__col {
        & .dln-recipe-single__col-content {
          padding-top: 42px;
        }
        &-gray {
          & .dln-recipe-single__col-content {
            & .dln-recipe-single__accordion {
              &__content {
                & .dln-recipe-single__servings-section {
                  & .dln-recipe-single__servings-col {
                    & .dln-select {
                      & .dln-select__value {
                        font-size: REM(21px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    & .dln-recipe-single__grid.dln-recipe-single__products-links {
      margin-bottom: 100px;

      & .dln-recipe-single__col-9 {
        & .dln-recipe-single__products-links-label {
          color: $ui-02;
        }
      }
    }
    & .dln-recipe-slides {
      & .dln-recipe-slides__header {
        margin-bottom: 22px;

        & .dln-recipe-slides__title {
          width: 100%;
          text-align: center;
        }
      }
      & .swiper-container.recipe-slides {
        & .swiper-wrapper {
          & .swiper-slide {
            align-items: flex-start;
          }
        }
      }
    }
    &__product-is-prepared,
    &__product-title {
      @include fontMedium;
      color: $brand-04;
    }
    &__col-content {
      background: transparent;

      p {
        line-height: 1.5;
        font-size: REM(16px);
      }

      strong {
        @include fontMedium;
      }
    }
    &__title-content {
      @include fontMedium;
      line-height: 1.21;
      font-size: REM(28px);
    }
    &__servings-col {
      span,
      .dln-select__value {
        @include fontMedium;
        color: $brand-04;
      }
    }
    &__step-title {
      @include fontRoman;
      line-height: 1.33;
      font-size: REM(18px);
      color: $brand-04;
      span {
        line-height: 1.33;
        font-size: REM(18px);
        color: $ui-02;
      }
    }
    &__products-links {
      &-title {
        // @include fontRoman;
        @include fontMedium;
        line-height: 1.12;
        font-size: REM(21px);
        padding-bottom: 0;
      }
      &-description {
        line-height: 1.3;
        font-size: REM(14px);
        margin-top: 25px;
      }
    }
    &__grid-products {
      padding-top: 7px;
    }
    &__col-3.padding {
      margin-top: 0;

      a {
        @include fontMedium;
        line-height: 1.29;
        font-size: REM(14px);
        color: $brand-04;
      }
    }

    .dln-image {
      border-radius: 0;
    }

    hr {
      border: 0.5px solid #e6e6e6;
    }

    .dln-recipe-single__servings-col {
      font-size: REM(18px);
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-recipe-single {
      & .dln-wrapper {
      }
      & .dln-recipe-details {
        border-top: none;

        & > .dln-wrapper {
          padding: 11px 20px 0 20px !important;
        }
        & .dln-recipe-details__col {
          &:nth-child(2) {
            padding: 0;
          }
        }
        & + .dln-wrapper {
          border-bottom: none;
        }
      }
      & .dln-recipe-single__product-attachment {
        width: 100%;
        margin: 0;

        & > .dln-recipe-single__col {
          padding: 0;

          & .dln-recipe-single__product {
            & .dln-recipe-single__col {
              & > .dln-recipe-single__product-content {
                // text-align: center;
                justify-content: center;
                line-height: 20px;
                font-size: REM(18px);
                padding-top: 16px;
              }
              &:nth-child(2) {
                & .dln-recipe-single__product-content {
                  padding-bottom: 40px;

                  & > span:not([class]) {
                    display: none;
                  }
                  & .dln-recipe-single__product-title {
                    margin-bottom: 24px;
                  }
                  & .dln-recipe-single__buy-now-btn {
                    & .dln-btn {
                      width: 166px;
                      min-width: initial;
                      background-color: $brand-01;
                    }
                  }
                }
              }
            }
          }
        }
      }
      & .dln-recipe-single__products-links {
        flex-direction: row;
        margin-bottom: 64px !important;

        & .dln-recipe-single__col-3 {
          margin-bottom: 24px;
        }
        & .dln-recipe-single__col-9 {
          & .dln-recipe-single__products-links-label {
            text-transform: uppercase;
            margin-bottom: 8px;
          }
          & .dln-recipe-single__products-links-description {
            margin: 12px 0 0 0;
            width: 100%;
          }
        }
        & + .dln-recipe-slides {
          margin-top: 0;
        }
      }
    }
  }
}

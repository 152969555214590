.dln-cart {
  align-content: flex-start;

  .dln-wrapper {
    align-content: flex-start;
    min-height: calc(100vh - 90px - 48px - 64px - 125px);
    padding-top: 48px;
    padding-bottom: 64px;
    margin: 0 auto;
  }

  .dln-title {
    margin: 0 0 24px 0;
  }

  &__head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin: 0 0 32px 0;

    & .dln-chips.dln-chips--advantage {
      margin-top: 8px;
      margin-bottom: 20px;
      padding: 8px 16px;

      .dln-chips {
        margin: 0 0 32px;
      }

      & .dln-chips__content {
        span:not(.dln-chips__strong) {
          color: $brand-04;
        }

        & > span {
          display: flex;
          align-items: baseline;
          font-size: 16px;

          .dln-icn {
            color: $brand-04;
          }

          & b {
            font-weight: normal;
          }
        }
      }

      & .close {
        color: $brand-04;
        cursor: pointer;
        margin: 0;
      }

      & button i {
        color: $brand-04;
        font-size: REM(30px);
      }
    }
  }

  &__services {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    width: calc(100% - 387px - 20px);
    height: auto;
    margin: 72px 0 0;
    & .dln-checkServiceBox {
      width: calc(33.33% - 13.33px);
      margin: 0 20px 0 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: calc(100% - 387px - 20px);
    margin: 0 20px 0 0;
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
      width: 100%;
      margin: 0;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
      margin: 0;
    }
    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) and (orientation: portrait) {
      width: 100%;
      margin: 0;
    }
  }

  &__paragraph {
    @include small;
    width: 100%;
    color: $brand-02;
    a {
      color: $brand-02;
    }
  }

  &__titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
      align-items: baseline;
    }
  }

  &__title {
    @include big;
    display: flex;
    align-items: baseline;
    width: calc(100% - 100px);
    @include fontBold;

    .dln-cart--title {
      @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
        font-size: 32px;
      }
    }

    .dln-icn {
      font-size: 32px;
      color: $ui-02;
      margin-right: 10px;

      @media only screen and (max-width: map-get($grid-breakpoints, md)) {
        margin-right: 5px;
      }
    }
  }

  &__titleLbl {
    @include cta;
  }

  &__subtitle {
    display: block;
    width: 100%;
    height: auto;
    line-height: 20px;
    font-size: 13px;
    color: $brand-04;
    background: rgba(0, 0, 0, 0.1);
  }

  &__infoText {
    display: block;
    width: 100%;
    height: auto;
    line-height: 20px;
    font-size: 13px;
    color: $brand-02;
    background: rgba(0, 0, 0, 0.1);
  }

  &__items {
    width: 100%;

    & + .dln-btn {
      margin: 32px 0;
    }
  }

  &__aside {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 387px;

    @media only screen and (max-width: 900px) {
      width: 100%;
      margin: 40px 0 0;
    }

    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
      margin: 0;
    }

    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) and (orientation: portrait) {
      width: 100%;
      margin: 0;
    }

    .dln-orderSumm {
      border-width: 0;
      margin: 0;

      &__content {
        padding: 0;
      }

      .dln-inputs__input,
      .dln-inputs--validate {
        background-color: #fff;
      }
    }

    .dln-cart__orderRecap {
      width: 100%;

      > .dln-btn:last-child {
        margin: 30px 0 0;
      }
    }

    &--secondary {
      display: none;
      .dln-checkout__noticeBox {
        display: none;
      }
    }
  }

  &__noticeBox {
    width: 100%;
    max-width: 387px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0 32px;
    text-align: center;

    p {
      @include cta;
      color: $brand-02;
      margin: 0 0 0;
      width: 100%;
    }

    .dln-noticeBox {
      width: 100%;
      position: relative;

      &:nth-child(even) {
        &::after {
          content: '';
          position: absolute;
          height: calc(100% - 48px);
          width: 1px;
          background-color: $ui-01;
          right: 0;
          top: 24px;
        }
      }
    }
  }

  &__emptyMessage {
    width: 100%;
    max-width: 70%;
    display: block;
    margin: 70px auto 0;

    .dln-icn,
    p {
      @include large;
      font-size: 18px;
      color: $ui-01;
      margin: 0 auto;
      text-align: center;
      width: 100%;
    }

    .dln-icn {
      font-size: 60px;
      margin: 0 0 16px;
    }

    .dln-cart__btnGroup {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 40px auto 0;

      .dln-btn {
        width: calc(50% - 10px);
        margin: 0 20px 0 0;
        &:last-of-type {
          margin: 0 0;
        }
      }
    }
  }
}

.dln-braun .dln-cart__title .dln-icn {
  color: inherit;
}

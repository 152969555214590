.dln-subscription-flow {
  display: flex;
  min-height: 620px;
  border-radius: 2px;

  &__column-one {
      display: flex;
      flex-wrap: wrap;
      width: 259px;
      background-image: url('/assets/images/subscription-flow__girl.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 2px 0 0 2px;
  }
  &__column-two {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      width: calc(100% - 259px);
      padding: 40px 63px 0 31px;
      box-sizing: border-box;
      border-radius: 0 2px 2px 0;
      position: relative;

      & .dln-btnIcon--close {
          width: 40px;
          height: 40px;
          margin: 0;
          position: absolute;
          top: 14px;
          right: 13px;

          & .dln-btnIcon__wrap {
              padding: 0;

              & .dln-icn {
                  font-size: 19px;
              }
          }
      }
      & .dln-title {
          display: block;
          width: 100%;
          line-height: 32px;
          font-size: 26px;
          margin: 0 0 22px 0;
      }
      & .dln-subscription-flow__sub-title {
          font-size: 16px;
      }
      & .dln-subscription-flow__service-container {
          display: flex;
          font-size: 16px;
          margin-top: 11px;
          margin-bottom: 30px;

          .dln-icn {
            font-size: 20px;
            font-weight: 700;
            color: $support-02;
          }
          div {
              display: flex;
              align-items: center;

              + div {
                  margin-left: 35px;
              }
              span {
                  margin-left: 6px;
              }
          }
      }
      .dln-subscription-flow__input-container {
        width: 100%;

        .dln-subscription-flow__extendedTos {
          line-height: 10px;
          font-size: 9px;
          color: $brand-02;
          letter-spacing: -0.1;
          padding-top: 8px;

          + .dln-subscription-flow__button-container + button {
              margin-top: 18px;
          }
        }
        .dln-checkbox__wrap {
          margin-top: 18px;
        }
        .dln-subscription-flow__row {
          display: flex;
          align-content: space-between;
          margin: 0 20px 30px 0;

          .dln-inputs + .dln-inputs {
              margin-left: 20px;
          }
          & + .dln-inputs {
              width: calc(100% - 20px);
              margin: 0 20px 2px 0;
          }
        }
        .dln-subscription-flow__checkbox-container {
          display: flex;
          align-items: flex-end;
          color: $brand-02;
          margin-top: 20px;
        }
      }
      .dln-subscription-flow__button-container button {
        width: 183px;
        margin: 40px 0 20px;
      }

      .dln-checkbox__tooltipWrap {
        font-size: 10px;
        line-height: 11px;
      }
      .gigya-input-text {
        padding: 12px 8px !important;
      }
  }
  &--thankYouPage {
      .dln-subscription-flow__column-two {
        align-content: center;

        & .dln-title {
            margin-bottom: 25px;
        }
        & .dln-subscription-flow__sub-title {
            margin: 0 0 60px 0;
        }
        & .dln-subscription-flow__button-container {
            & .dln-btn {
                margin: 0 0 40px 0;
            }
        }
    }
  }

  .gigya-label-text.gigya-checkbox-text i {
    background: transparent !important;
    color: var(--ui-06) !important;
    border: 1px solid var(--ui-06);

    &:hover {
      color: #1a1d1f !important;
      border: 1px solid #1a1d1f;
    }
  }

  .subscription-checkbox {
    position: absolute;
    z-index: 8;
    opacity: 1 !important;
    width: 0;
    height: 0;

  + .subscription-name-label {
      font-size: 13px !important;
      color: var(--brand-02) !important;
      margin-left: 1px;
      font-weight: normal !important;
      padding-left: 0 !important;

      &:before {
        content: "";
        display: inline-block;
        margin-right: 12px;
        border-radius: 2px !important;
        border: 1px solid var(--ui-02);
      }

      &:after {
        display: none;
      }

      i {
        background: transparent !important;
        color: var(--ui-06) !important;
        border: 1px solid var(--ui-06);
        position: absolute;     
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
        cursor: pointer;
        position: relative;
      }
    } 
      &:before {
        content: "";
        position: relative;
        transform: scale(0);
        transition: transform .08s ease;
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 1px;
      }  
  } 

  .subscription-checkbox:checked:before {
    content: "\E0";
    font-family: var(--fontNameIcons);
    position: relative;
    left: 7px;
    transform: scale(1);
  }
}

.dln-braun {
  .dln-subscription-flow {
    .gigya-subscribe-with-email-form {
      .gigya-input-checkbox {
        + label.gigya-label {
          .gigya-checkbox-text { 
            font-size: 13px;
            color: var(--brand-02) !important;
            position: relative;
            left: 5px;
            top: -25px;
            font-weight: normal !important;

            i {
              width: 13px !important;
              height: 13px !important;
              font-size: 9px !important;
            }
          }

          &:before {
            position: absolute;
            content: '';
            width: 14px;
            height: 14px;
            border-radius: 2px;
            left: 0;
          }
        }
      }
    }
    .subscription-checkbox {
    + .subscription-name-label {
        &:before {
          width: 13px !important;
          height: 13px !important;
          border-radius: 2px !important;
        }
      }
    }  
  }

  .dln-subscription-flow {
    .gigya-custom-checkbox {
      margin-top: 5px;
      margin-bottom: 0;
    }

    .gigya-input-checkbox {      
      + label {
          display: flex;
      }
    }

    .gigya-input-checkbox:checked {      
      + label .gigya-checkbox-text {
          color: var(--support-02);
          font-weight: bold;
      }

      + label.gigya-label:after { 
          content:'';
          position: absolute;             
          width: 9px;
          height: 9px;
          top: 2px;
          left: 4px;
          border-radius: 2px;
          background-color: var(--support-02);
          transform: scale(1,1);
      }
    }

    .subscription-checkbox:checked:before {
      content: '';
      width: 9px;
      height: 9px;
      border-radius: 2px;
      position: absolute;
      top: 1px;
      left: 4px;
      background-color: var(--support-02);
      transform: scale(1);
    }
  }

  .newsletter-popup-opened.dln-popup--open {
    .dln-popup__content {
      max-width: none !important;
      width: auto;
      @media screen and (min-width: 600px) {
        max-width: 720px !important;
      }
    }
  }
}

@media screen and (min-width: 599px) and (max-width:700px) {
  .dln-subscription-flow {
    .dln-subscription-flow__column-one {
      width: 210px;
    }
    .dln-subscription-flow__column-two .dln-subscription-flow__service-container div+div {
      margin-left: 15px;
    }
    .dln-subscription-flow__column-two {
      width: calc(100% - 210px);
      padding: 40px 46px 0 21px;
    }
  }
}
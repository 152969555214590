@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
  .dln-cart {
    .dln-wrapper {
      padding: 0;
    }

    &__head {
      padding: 16px;

      & > .dln-chips .close {
        font-size: 10px;
      }
    }

    &__content {
      padding: 0 20px;

      .dln-cartItem {
        &__warranty {
          width: calc((100% - 204px));
        }
        
        &__accessories {
          .dln-select,
          .dln-btnTextIcon {
            width: 300px;
            margin: 0 0 16px;
          }
        }
      }
    }

    &--empty {
      .dln-cart__content {
        width: 100%;
        margin-right: 0;
      }

      .dln-cart__aside {
        margin: 0 20px;
        width: 100%;
      }
    }

    &__services {
      width: 100%;

      .dln-checkServiceBox {
        width: 100%;
        margin: 0 0 16px 0 !important;
        padding: 32px 24px;
        min-height: auto;
        .dln-checkServiceBox__iconWrap {
          margin: 0 24px 0 0;
        }

        .dln-checkServiceBox__text,
        .dln-btn {
          width: 100%;
          text-align: left;
          text-align: center;
        }
      }
    }

    &__aside {
      .dln-cart__orderRecap {
        width: 100%;
        border-width: 0;
        padding: 0 20px;
      }

      > .dln-btn {
        display: block;
        margin: 32px 0 0 20px;
      }

      .dln-cart__noticeBox {
        gap: 20px;
        margin: 0 20px 20px;
      }
    }

    &__noticeBox {
      max-width: 100%;

      > p {
        display: none;
      }

      .dln-noticeBox {
        width: 100%;

        &:nth-child(even) {
          &::after {
            content: '';
            width: 0;
          }
        }
      }
    }
  }
}

.dln-braun {
  & .dln-cart__title {
    margin-bottom: 0;

    & .dln-cart__titleLbl {
      font-size: 13px;
      margin: 0 0 0 10px;
    }
  }
}

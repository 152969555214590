.dln-kenwood {
    .dln-radioBtn {
        &__lbl {
            color: $brand-04;
            &::before {
                border-color: $brand-04;
                border-width: 1px;
                left: 0px;
                top: 0;
                width: 14px;
                height: 14px;
            }
            &::after {
                background-color: $brand-01;
                top: 4px;
                left: 4px;
            }
        }
        input[type="radio"] {
            &:checked~.dln-radioBtn__lbl::before {
                border-color: $brand-01;
            }
            &:checked~.dln-radioBtn__lbl::after {
                background-color: $brand-01;
            }
        }
        &--square {
            .dln-radioBtn__lbl {
                height: 16px;
            }
        }
    }
}
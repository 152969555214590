.dln-kenwood {
    .dln-singProductReview {
        .dln-orderRev {
            &__details {
                max-width: 100%;

                &Title {
                    @include fontSemibold;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            &--productReview {
                margin-top: 0 !important;
            }
        }
        &__content {
            border: 0;
            padding-top: 6px;
        }
        &__title {
            display: none;
        }
        &__detailsTitle {
            margin-top: 15px;
            margin-bottom: 5px;
            @include fontMedium;
            font-size: 14px;
            line-height: 20px;
        }
        &__desc {
            @include fontLight;
        }
        .dln-ratingStars {
            .dln-icn {
                width: 24px;
                height: 24px;
                &--iconfont {
                    font-size: 22px;
                }
            }
        }
        & .dln-popup__head .dln-btnIcon--close {
            transform: none;
        }
    }
    @media only screen and (max-width:599px) {
        .dln-orderRev__itemPic {
            margin-right: 20px;
        }
    }
}
.dln-delonghi {
  .dln-inputs {
    height: 40px;
    border-color: $ui-02;
    margin-bottom: 30px;

    &--active {
      border-color: $brand-04;

      & .dln-inputs__lbl {
        line-height: 12px;
        font-size: REM(12px);
        transform: translateY(calc(50% - 26px));
      }
    }
    &--disabled {
      & .dln-inputs__lbl {
        height: auto;
        transform: none;
      }
      & .dln-inputs__input {
        &[value] {
          @include fontBook;
          line-height: 18px;
          font-size: REM(14px);
        }
      }
    }
    &__input {
      @include fontBook;
      padding: 10px 35px 10px 16px;
      height: 38px;
      line-height: 18px;
      font-size: REM(14px);
      border-color: $ui-02;
      background-color: #fff
      // padding: 10px 16px;
    }
    &__lbl {
      @include fontBook;
      line-height: 16px;
      font-size: REM(14px);
      color: $ui-02;
      padding: 0px 9px;
    }
    &__extraLbl {
      @include fontBook;
      font-size: REM(12px);
      transform: none;
      padding: 3px 0 0 0;
      left: 0;
      right: auto;
    }
    &--searchBar {
      .dln-inputs__lbl {
        left: 8px;
      }
      .dln-inputs__button {
        left: auto;
        right: 8px;

        .dln-icn {
          color: $brand-04;
        }
      }
      & .dln-inputs__button {
        width: 25px;
        height: 25px;

        & .dln-icn {
          width: 25px;
          height: 25px;
          line-height: 25px;
          font-size: 0;

          &::before {
            @include fontIcon;
            content: '7';
            width: 25px;
            height: 25px;
            font-size: REM(25px);
          }
        }
      }
    }
    &--icon {
      & > .dln-icn {
        color: $ui-02;
      }
    }
    &--validate {
      border-color: $support-02;

      & .dln-icn {
        color: $support-02;
      }
      &.dln-inputs--validated {
        border-color: $support-02;

        & .dln-icn {
          color: $support-02;
        }
      }
    }
    .dln-select {
      &__head {
        height: auto;
        height: auto;
        border-color: $ui-01;
        padding: 4px 8px;
        margin: 6px 0;
      }
      &__container {
        height: auto;

        &.dln-select--has-value {
          .dln-select__head {
            height: auto;
            border-color: $ui-01;
          }
        }
      }
    }
    &--selectInside {
      .dln-select {
      }
      .dln-inputs__input {
      }
      .dln-inputs__lbl {
        line-height: 12px;
        font-size: REM(12px);
        transform: translateY(calc(50% - 26px));
      }
    }
  }

  .flatpickr-day {
    &.today {
      border-color: $brand-01 !important;
    }
    &.selected {
      border-color: $brand-01 !important;
      background-color: $brand-01 !important;
    }
  }
}

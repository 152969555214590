.dln-delonghi {
  .dln-filters-bar {
    &__filters-wrap {
      border-bottom: 1px solid $ui-01;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    &__desktop {
      padding-bottom: 15px;
    }
    .section-label {
      padding-right: 17px;
      color: $brand-04;
    }

    .dln-filterDropdown {
      border-left: 1px solid $ui-01;
      padding-left: 15px;
      + .dln-filterDropdown {
        margin-left: 15px;
      }
    }
    &__applied-filters {
      margin-top: 15px;
      .dln-btn {
        @include fontRoman;
        text-decoration: underline;
        font-size: REM(16px);
        line-height: normal;
      }
    }

    &__sort-section span {
      font-size: REM(16px);
      line-height: 1.5;
    }

    &__sorted-by-label {
      color: $brand-04;
      font-size: REM(16px);
    }

    .dln-select {
      &__value {
        @include fontMedium;
        color: $brand-04;
        font-size: REM(16px);
        line-height: 1.5;
      }
      &__head {
        align-items: center;
      }
      &__arrow {
        width: 18px;
        height: 15px;
        font-size: REM(18px);
      }
    }
    .dln-filterDropdown--open .dln-filterDropdown__trigger {
      border-bottom: 2px solid $brand-04;
    }
    @media only screen and (max-width: 599px) {
      &__mobile {
        .bra-btnView {
          display: inline-flex;
          &__button {
            border: none;
            padding: 0 3px;
          }

          .bra-icn {
            font-size: REM(16px);
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

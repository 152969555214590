.dln-prodReg--confirmation {
  .dln-prodReg__loading {
    margin: 100px 0;
    width: 100%;
    text-align: center;
  }
  .dln-chips {
    .dln-icn.dln-icn--mainIcon.dln-icn--mainIcon--error {
      color: var(--support-01);
    }
  }
}

.dln-braun {
  .dln-confirmation-page__wrapper-content {
    a {
      border-radius: 20px;
    }
  }
}

.dln-kenwood {
  .dln-confirmation-page__wrapper-content {
    a:hover {
      opacity: 1;
      background-color: var(--brand-04);
      border-color: var(--brand-04);
    }
    a.stroked:hover {
      background-color: var(--brand-03);
      color: var(--brand-04);
      border-color: var(--brand-04);
    }
  }
}

.dln-confirmation-page__wrapper-content {
  a {
    box-sizing: border-box;
    display: inline-block;
    text-decoration: none;
    background-color: var(--brand-01);
    color: var(--brand-03);
    border: 1px solid var(--brand-01);
    padding: 12px;
    font-family: var(--fontNameBold);
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    text-align: center;
    margin: 10px;
    min-width: 220px;
    transition: all .3s linear;
    &:hover {
      opacity: .8;
    }
  }
  a.stroked {
    background-color: var(--brand-03);
    color: var(--brand-01);
  }
}
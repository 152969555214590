.dln-thankYou {
    &__content {
        width: 100%;
        border-bottom: 1px solid $ui-01;
    }
    .dln-wrapper {
        >.dln-thankYou__content {
            border: none;
            display: flex;
            padding: 0 75px;
            margin: 0 75px 0;
            justify-content: space-between;
        }
        &--fullWidth {
            margin-top: 0px;
            margin-bottom: 40px;
            border-top: 1px solid $ui-01;
            border-bottom: 1px solid $ui-01;
            background-color: $ui-03;
            justify-content: center;
        }
    }
    &__successMess {
        text-align: center;
        border: none;
        padding: 30px;
        margin: 20 0 20px;
        width: 100%;

        .dln-icn {
            font-size: 32px;
            color: var(--support-02);
            margin: 0 0 28px;
            border: 2px solid var(--support-02);
            border-radius: 50%;
            width: 77px;
            height: 45px;
            padding-top: 32px;
        }
    }
    &__title {
        @include large;
        font-size: 24px;
        line-height: 30px;
        @include fontBold;
        margin: 0 0 16px;
    }
    &__subTitle {
        @include cta;
        display: flex;
        align-items: center;
        width: 100%;
        @include fontBold;
        &--smaller {
            @include small;
        }
        &InnerWrap {
            display: inline-flex;
            align-items: center;
            margin: 0 0 0 auto;
        }
        a {
            @include small;
            @include fontBold;
            color: $brand-04;
            margin: 0 0 0 4px;
        }
    }
    &__paragraph {
        @include small;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 30px;

        a {
            @include fontBold;
            color: $brand-04;
            // display: block;
            margin: 0;
        }
    }
    &__orderNr {
        width: 100%;
        text-align: center;
        .dln-btn {
            max-width: 380px;
            margin: 40px auto 0;
            display: none;
        }
    }
    &__orderInfo {
        font-size: 15px;
        line-height: 22px;
        margin: 0 0 64px;
        span {
            @include small;
            display: block;
            color: $brand-02;
            margin: 8px 0 0;
        }
        &--user {
            @include small;
            display: inline-block;
            width: 100%;
            border-top: none;
            padding: 0;
            margin: 0 auto;
            box-sizing: border-box;
            a {
                @include fontBold;
                color: var(--support-02);
            }
        }
    }
    &--orderSummary{
        margin-bottom: 48px;
    }
    &__innerWrap {
        margin-top: 26px;
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        i,a{
            color: var(--support-02);
        }

        >.dln-btn {
            max-width: 380px;
        }
        .dln-thankYou__content {
            width: 100%;
            margin: 0 0 32px;
            & .dln-thankYou__items{
                p{
                    font-size: 15px;
                    line-height: 22px;
                    margin-bottom: 15px;
                    color: var(--brand-02);
                }
            }
            &--delivery,
            &--payment,
            &--aside {
                width: calc(33.333% - 27px);
                border: none;
                margin: 0 40px 0 0;
                .dln-thankYou__subTitle {
                    font-size: 15px;
                    line-height: 22px;
                    margin: 0 0 24px;
                }
                &:nth-child(4n+4) {
                    margin: 0 0;
                }
            }
        }
        .dln-thankYou__subTitle {
            font-size: 24px;
            line-height: 30px;
            margin: 0;
        }
        .dln-thankYou__giftNote {
            width: 100%;
            margin-bottom: 15px;

            &__title {
                display: flex;
                align-items: center;
                margin: 0 0 10px;
                font-size: 16px;
                line-height: 22px;
                color: var(--brand-02);
            }

            &__icon {
                margin: 0 5px 0 0;
                font-size: 24px;
                color: var(--brand-02);

                &::before {
                    content: "w";
                    position: static;
                }
            }

            &__message {
                margin-bottom: 10px;
                font-size: 14px;
                line-height: 20px;
                color: var(--brand-06);
            }
        }
    }
    .dln-orderSumm {
        border: none;
        &__content {
            padding: 0;
            &Row:first-of-type{
                border-top: none;
            }
        }
    }
    &__shareContent {
        width: 100%;
        max-width: 380px;
        text-align: center;
        margin: 0 auto 70px;
        .dln-thankYou__paragraph,
        .dln-thankYou__subTitle,
        .dln-thankYou__socialBtn {
            width: 100%;
            text-align: center;
            margin: 0 auto 24px;
        }
        .dln-thankYou__subTitle {
            display: none;
            text-align: center;
            margin: 24px 0 0;
        }
        .dln-thankYou__socialBtn {
            display: flex;
            flex-wrap: wrap;
            margin: 16px 0 32px;
            display: none;
            .dln-btn {
                width: calc(33.333% - 14px);
                padding: 0 8px;
                margin: 0 20px 0 0;
                &:last-of-type {
                    margin: 0 0;
                }
            }
        }
        .dln-thankYou__paragraph {
            a {
                display: inline;
            }
        }
    }
    &__orderDetails {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 40px;
        margin: 0 40px;
    }
    .dln-addressBox {
        width: 100%;
        background-color: transparent;
        margin: 0 0 32px;
        .dln-addressBox__data:last-of-type {
            margin: 0 0;
        }
    }
    .dln-orderRev {
        align-items: flex-start;
        border-top: 1px solid #e5e5e5;
        border-width: 1px 0 0 0;
        padding: 0;
        padding: 32px 0 42px;
        &:hover {
            border-width: 1px 0 0 0;
        }
        .dln-orderRev__wrap {
            margin: 0 0;
        }
        .dln-orderRev__advice {
            width: 100%;
            color: $ui-02;
            margin: 0 0 8px;
        }
        & .dln-price {
            color: $brand-04;
            font-size: 15px;
            line-height: 22px;

            &--discounted {
                @include fontBold;
                color: $support-01;
            }
        }
        &__details {
            max-width: 100%;
            &Title{
                font-size: 18px;
                line-height: 22px;
            }
        }
        .dln-cartItem__quantity{
            margin: 15px 0 0 0;
            width: 100%;
        }
        &__itemPic {
            margin-right: 16px;
            max-height: 144px;
        }
    }

    &__aside {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 375px;
    }
    &__orderStatus {
        display: inline-block;
        width: 100%;
        max-width: 400px;
        border-top: 1px solid $brand-01;
        padding: 24px 4px 0;
        margin: 16px auto 0;
        .dln-thankYou__orderInfo {
            @include small;
            a {
                @include fontBold;
                color: $brand-04;
            }
        }
    }
    .dln-carousel {
        width: 100%;
        height: auto;
        margin: 60px 0 40px;
        .dln-advtBox {
            max-width: 100%;
            text-align: left;
            .dln-advtBox__title,
            .dln-advtBox__desc {
                padding: 0 10px;
                box-sizing: border-box;
            }
            .dln-icn {
                font-size: 80px;
                color: $support-02;
            }
        }
        .swiper-pagination {
            margin: 32px 0 0;
        }
    }

    .bra-picture {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;

        &__image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            top: 0;
            left: 0;
        }
    }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)){
    .dln-thankYou {
        &__content {
            width: 100%;
            border-bottom: 1px solid $ui-01;
        }
        .dln-wrapper {
            >.dln-thankYou__content {
                border: none;
                display: flex;
                padding: 0 65px;
                margin: 14px 65px 0;
                justify-content: space-between;
            }
            &--fullWidth {
                margin-top: 0px;
                margin-bottom: 40px;
                border-top: 1px solid $ui-01;
                border-bottom: 1px solid $ui-01;
                background-color: $ui-03;
            }
        }
        &__successMess {
            text-align: center;
            border: none;
            padding: 40px;
            margin: 0 0 10px;
            width: 100%;
    
            .dln-icn {
                font-size: 32px;
                color: var(--support-02);
                margin: 0 0 35px;
                border: 2px solid var(--support-02);
                border-radius: 50%;
                width: 77px;
                height: 45px;
                padding-top: 32px;
            }
        }
        &__title {
            @include large;
            font-size: 18px;
            line-height: 22px;
            @include fontBold;
            margin: 0 0 26px;
        }
        &__subTitle {
            @include cta;
            display: flex;
            align-items: center;
            width: 100%;
            @include fontBold;
            &--smaller {
                @include small;
            }
            &InnerWrap {
                display: inline-flex;
                align-items: center;
                margin: 0 0 0 auto;
            }
            a {
                @include small;
                @include fontBold;
                color: $brand-04;
                margin: 0 0 0 4px;
            }
        }
        &__paragraph {
            @include small;
            margin-bottom: 56px;
    
            a {
                @include fontBold;
                color: $brand-04;
                // display: block;
                margin: 0;
            }
        }
        &__orderNr {
            width: 100%;
            text-align: center;
            .dln-btn {
                max-width: 380px;
                margin: 40px auto 0;
                display: none;
            }
        }
        &__orderInfo {
            font-size: 15px;
            line-height: 19px;
            margin: 0 0 64px;
            span {
                @include small;
                display: block;
                color: $brand-02;
                margin: 8px 0 0;
            }
            &--user {
                @include small;
                display: inline-block;
                width: 100%;
                border-top: none;
                padding: 0;
                margin: 0 auto ;
                box-sizing: border-box;
                a {
                    @include fontBold;
                    color: var(--support-02);
                }
            }
        }
        &__innerWrap {
            margin-top: 26px;
            margin-bottom: 40px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
    
            >.dln-btn {
                max-width: 380px;
            }
            .dln-thankYou__content {
                width: 100%;
                margin: 0 0 26px;
                &--delivery,
                &--payment,
                &--aside {
                    width: calc(33.333% - 27px);
                    border: none;
                    margin: 0 40px 0 0;
                    &:nth-child(4n+4) {
                        margin: 0 0;
                    }
                }
            }
            .dln-thankYou__subTitle {
                margin: 0 0 10px;
                font-size: 15px;
                line-height: 24px;

                i,a{
                    color: var(--support-02);
                }
            }

            .dln-thankYou__itemParagraph{
                color: var(--brand-02);
            }
        }
        .dln-orderSumm {
            border: none;
            &__content {
                padding: 0;
                &Row:first-of-type{
                    border-top: none;
                }
            }
        }
        &__shareContent {
            width: 100%;
            max-width: 380px;
            text-align: center;
            margin: 0 auto 70px;
            .dln-thankYou__paragraph,
            .dln-thankYou__subTitle,
            .dln-thankYou__socialBtn {
                width: 100%;
                text-align: center;
                margin: 0 auto 24px;
            }
            .dln-thankYou__subTitle {
                display: none;
                text-align: center;
                margin: 24px 0 0;
            }
            .dln-thankYou__socialBtn {
                display: flex;
                flex-wrap: wrap;
                margin: 16px 0 32px;
                display: none;
                .dln-btn {
                    width: calc(33.333% - 14px);
                    padding: 0 8px;
                    margin: 0 20px 0 0;
                    &:last-of-type {
                        margin: 0 0;
                    }
                }
            }
            .dln-thankYou__paragraph {
                a {
                    display: inline;
                }
            }
        }
        &__orderDetails {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 10px;
            padding: 0 10px;
        }
        .dln-addressBox {
            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #e5e5e5;
            /* max-width: 390px; */
            margin: 0 0 32px;
            padding: 0 0 24px;
            .dln-addressBox__data:last-of-type {
                margin: 0 0;
            }
        }
        .dln-orderRev {
            align-items: flex-start;
            border-top: 1px solid $ui-01;
            border-width: 1px 0 0 0;
            padding: 15px 0 11px;
            &:hover {
                border-width: 1px 0 0 0;
            }
            &:first-of-type {
                border-top: none;
            }
            .dln-orderRev__wrap {
                margin: 0 0;
            }
            .dln-orderRev__advice {
                width: 100%;
                color: $ui-02;
                margin: 0 0 8px;
            }
            & .dln-price {
                color: $brand-02;
                font-size: 13px;
                line-height: 19px;
    
                &--discounted {
                    @include fontBold;
                    color: $support-01;
                }
            }
            &__details {
                max-width: 100%;
            }
            .dln-cartItem__quantity{
                margin: 15px 0 0 0;
                width: 100%;
            }
            &__itemPic {
                max-height: 144px;
            }
        }
    
        &__aside {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            width: 375px;
        }
        &__orderStatus {
            display: inline-block;
            width: 100%;
            max-width: 400px;
            border-top: 1px solid $brand-01;
            padding: 24px 4px 0;
            margin: 16px auto 0;
            .dln-thankYou__orderInfo {
                @include small;
                a {
                    @include fontBold;
                    color: $brand-04;
                }
            }
        }
        .dln-carousel {
            width: 100%;
            height: auto;
            margin: 60px 0 40px;
            .dln-advtBox {
                max-width: 100%;
                text-align: left;
                .dln-advtBox__title,
                .dln-advtBox__desc {
                    padding: 0 10px;
                    box-sizing: border-box;
                }
                .dln-icn {
                    font-size: 80px;
                    color: $support-02;
                }
            }
            .swiper-pagination {
                margin: 32px 0 0;
            }
        }
    }
}

.dln-braun {
  .dln-orderSumm {
    &__content {
      .dln-icn--coupon {
        color: var(--support-02);
      }
    }

    &__rowTitle {
      color: $brand-01;
    }
  }

  .dln-cart__noticeBox {
    .dln-noticeBox__description {
      color: $brand-01;
    }
  }
}

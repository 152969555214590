body {
    * {
        -webkit-user-select: auto;
        -moz-user-select: auto;
        -ms-user-select: auto;
        user-select: auto;
    }
    img {
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
    }
}

[role="button"] {
    cursor: pointer;
}

[class*="--mobileView"] {
    display: block;
}

[class*="--desktopView"] {
    display: none;
}

.dln-note {
    display: inline-flex;
    width: auto;
    height: 40px;
    line-height: 40px;
    font-size: 10px;
    color: black;
    background: aquamarine;
    padding: 0 0 0 0;
    box-sizing: border-box;
}

@keyframes smoothFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes smoothFadeInAndScale {
    0% {
        opacity: 0;
        transform: scale(.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.dln-carousel--mobileOnly,
.dln-carousel {
    &.swiper-container {

        overflow: hidden;
        list-style: none;

        position: relative;
        list-style: none;
        z-index: 1;
        width: 100%;
        height: 100%;
        .swiper-wrapper {
            position: relative;
            width: 100%;
            /* height: 100%; */
            z-index: 1;
            display: flex;
            transition-property: transform;
            box-sizing: content-box
        }
        .swiper-slide {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            position: relative;
            transition-property: transform;
        }
        .swiper-pagination {
            position: relative;
            text-align: center;
            transition: .3s opacity;
            transform: translate3d(0, 0, 0);
            z-index: 10;
            width: auto;
            display: inline-block;
            margin: 5px 0 0;
            padding: 0 0 0 10px;
            &-bullet {
                width: 10px;
                height: 10px;
                margin: 0 8px 0 0;
                cursor: pointer;
                display: inline-block;
                border-radius: 100%;
                background-color: $brand-01;
                transition: width .3s;
                &:first-child {
                    margin: 0 8px 0 0;
                }
                &-active {
                    width: 26px;
                    border-radius: 10px;
                }
            }
            &-progressbar {
                margin: 32px auto 0;
                .swiper-pagination-progressbar-fill {
                    background: $brand-01;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    transform: scale(0);
                    transform-origin: left top;
                }
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            height: 100%;
            top: 0;
            display: inline-flex;
            align-items: center;
            z-index: 9;
            padding: 0 8px;
            &.swiper-button-disabled {
                opacity: .5;
                pointer-events: none;
            }
            .dln-icn {
                color: $ui-02;
            }
        }
        .swiper-button-prev {
            left: 0;
        }
        .swiper-button-next {
            right: 0;
        }
        .dln-btn--stroked,
        .dln-btnTextIcon {
            background-color: transparent;
        }
    }
}


/* Toggle Content */

.-extraContent {
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    position: relative;
    pointer-events: none;
    transform: translateY(-10px);
    transition: all .4s;
}

.-showContent {
    transition: all .3s;
    .-extraContent {
        opacity: 1;
        max-height: none;
        position: relative;
        pointer-events: auto;
        transform: translateY(0);
    }
}


/*  */

.dln-page {
    /*  &--myArea {
        margin-bottom: 32px;
        >.dln-wrapper {
            padding-top: 32px;
            padding-bottom: 70px;
        }
    } */
    .dln-title {
        text-align: center;
        font-size: 44px;
        line-height: 55px;
        text-align: left;
    }
    // .dln-breadcrumbs {
    //     a {
    //         color: $brand-04;
    //         font-size: 12px;
    //     }
    // }
    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
        .dln-title {
            font-size: 32px;
            line-height: 40px;
            margin: 30px auto 20px;
            padding: 0;
        }
    }
}


.dln-head,
.dln-foot {
    @include make-container();
    overflow: visible;
    .dln-wrapper {
        width: 100%;
        @include make-row();
    }
}

.dln-page {
    margin-top: var(--headerHeight);
    @include make-container();
    background-color: $brand-03;
}

.dln-wrapper {
    @include make-row();
    & .dln-wrapper,
    &--fullWidth {
        @include make-row--fullWidth();
    }
}

.dln-page {
  &--myArea {
      @include makePage;
      .dln-wrapper {
          .dln-page__content {
              @include makeContent;
          }

          .dln-page__aside {
              @include makeAside;
          }

        @media only screen and (min-width:map-get($grid-breakpoints, md)) {
            .dln-address__content {
                padding-right: 200px;
            }
        }
      }

      @media only screen and (max-width:map-get($grid-breakpoints, md)) {
        .dln-profileCard {
          display: none;
        }

        .dln-page__content {
          padding-top: 0px;
         }

        .dln-vertMenu {
          &:not(.dln-vertMenu--open) {
            & .dln-vertMenu__list {
                margin-bottom: 0;
            }
          }
        }
      }
    }

    &--imageHeader {
        &__header {
            background-color: #1d1d1d;
            color: white;
            padding: 23px 0 35px 0;

            .dln-wrapper--main {
                justify-content: space-between;
                align-items: center;
                flex-wrap: nowrap;

                width: 85%;
                margin: 0 auto;

                .dln-mainTitle {
                    display: flex;
                    width: 60%;
                    margin-right: 1em;

                    @include fontBold;
                    font-size: 44px;
                    line-height: 55px;
                }
                .dln-page--imageHeader__picture {
                    display: flex;
                    width: auto;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        &__content {
            flex-direction: column;

            width: 85%;
            margin: 0 auto;

            padding: 48px 0 90px 0;

            padding-right: 10px;
            padding-left: 10px;

            @media only screen and (min-width: map-get($grid-breakpoints, md)) {
                padding-right: 20px;
                padding-left: 20px;
            }

            @media only screen and (min-width: map-get($grid-breakpoints, xl)) {
                padding-right: 40px;
                padding-left: 40px;
            }


            .dln-page__title {
                display: block;

                @include fontBold;
                font-size: 22px;
                line-height: 26px;

                & + .dln-page__paragraph {
                    margin-top: 23px;
                }
            }
            .dln-page__paragraph {
                display: block;
                margin-bottom: 10px;

                @include fontBold;
                font-size: 18px;
                line-height: 22px;
            }
            .dln-page__text {
                display: block;

                @include fontRoman;
                font-size: 14px;
                line-height: 20px;
            }
            .dln-page__actions {
                flex-direction: row;
                display: flex;

                margin-top: 38px;

                .dln-btn {
                    width: auto;
                    margin: 0 10px;

                    &:nth-last-child(1) {
                        margin-right: 0;
                    }
                    &:nth-child(1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }



    &__content {
        .dln-page__section {
            width: 100%;

            margin-top: 50px;
            margin-bottom:20px;

            &:last-of-type {
                margin-bottom: 0;
            }

            @media only screen and (max-width:599px) {
                margin-top: 0;
                margin-bottom: 60px;
            }

            .dln-page__sectionHead {
                width: 100%;
                margin: 0 0 30px 0;

                .dln-page__sectionHeadTitle {
                    @include fontBold;
                    font-size: 32px;
                    line-height: 32px;
                    margin: 0 0 33px 0;

                    @media only screen and (max-width:599px) {
                        @include fontBold;
                        font-size: 21px;
                        line-height: 1.3;
                        margin: 0 0 20px 0;
                    }
                }
                .dln-page__sectionHeadInfoText {
                    @include fontRoman;
                    font-size: 14px;
                    line-height: 18px;
                }
            }
            .dln-page__sectionContent {
                width: 100%;
            }
            .dln-page__sectionFooter {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                height: auto;
                margin-top: 40px;

                @media only screen and (max-width:599px) {
                    margin-top: 42px;
                }

                &::before {
                    content: '';
                    width: 100%;
                    height: 1px;
                    background-color: $ui-01;
                    margin: 0 0 30px 0;

                    @media only screen and (max-width:599px) {
                        margin: 0 0 32px 0;
                    }
                }

                & .dln-btn--link {
                    align-self: flex-end;
                    margin: 0 auto 0 0;
                }
                & .dln-btn--confirm {
                    width: 280px;
                    margin: 0 0 0 auto;

                    @media only screen and (max-width:599px) {
                        width: 183px;
                    }
                }
            }
        }

    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-page--imageHeader {
        &__header {
            .dln-wrapper--main {
                .dln-mainTitle {
                    width: auto;

                    font-size: 35px;
                    line-height: 40px;
                }
                .dln-page--imageHeader__picture {
                    display: flex;
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-page--imageHeader {
        &__header {
            padding: 18px 0 51px 0;

            .dln-wrapper--main {
                width: 100%;
                margin: 0 20px;
                padding: 0;
                flex-direction: column;

                .dln-mainTitle {
                    width: 100%;
                    margin-right: 0;

                    font-size: 35px;
                    line-height: 40px;

                    margin-bottom: 45px;
                }
                .dln-page--imageHeader__picture {
                    display: flex;
                    width: calc( 100% - 40px );
                }
            }
        }

        &__content {
            flex-direction: column;
            width: calc(100% - 40px);
            margin: 0 auto;
            padding: 24px 0 73px 0;

            .dln-page__title {
                width: 100%;

                & + .dln-page__paragraph {
                    margin-top: 30px;
                }
            }
            .dln-page__paragraph {
                width: 100%;
                font-size: 15px;
                line-height: 22px;
            }
            .dln-page__text {
                width: 100%;
            }
            .dln-page__actions {
                display: block;
                margin-top: 27px;

                .dln-btn {
                    width: auto;
                    margin: 0;
                    margin-right: 20px;
                    margin-bottom: 15px;

                    &:nth-last-child(1) {
                        margin-bottom: 0;
                    }

                }
            }
        }
    }
}




/* @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .dln-page {
        .dln-wrapper {
            >div[class$="__content"] {
                width: 100%;
            }
            >div[class$="__aside"] {
                width: 100%;
                margin: 0;
            }
        }
    }
} */

@media only screen and (min-width: map-get($grid-breakpoints, md)) {
    .dln-head,
    .dln-foot {
        /* padding-right: ($grid-gutter-width);
        padding-left: ($grid-gutter-width); */
        .dln-wrapper {
            width: 100%;
            margin-right: ($grid-gutter-width);
            margin-left: ($grid-gutter-width);
            padding-right: ($grid-gutter-width);
            padding-left: ($grid-gutter-width);
            & .dln-wrapper,
            &--fullWidth {
                @include make-row--fullWidth();
            }
        }
    }
    /*  .dln-page {
        padding-right: ($grid-gutter-width);
        padding-left: ($grid-gutter-width);
    } */
    .dln-wrapper {
        width: 100%;
        margin-right: ($grid-gutter-width);
        margin-left: ($grid-gutter-width);
        padding-right: ($grid-gutter-width);
        padding-left: ($grid-gutter-width);
        & .dln-wrapper,
        &--fullWidth {
            @include make-row--fullWidth();
        }
    }
}

@media only screen and (min-width: map-get($grid-breakpoints, xl)) {
    .dln-wrapper {
        width: 100%;
        max-width: calc(#{map-get($grid-breakpoints, xl)} + 80px);
        margin: 0 auto;
        padding-right: 40px;
        padding-left: 40px;
        & .dln-wrapper,
        &--fullWidth {
            @include make-row--fullWidth();
        }
    }
    .dln-head,
    .dln-foot {
        //.dln-head__navTopList,
        .dln-wrapper {
            width: 100%;
            max-width: calc(#{map-get($grid-breakpoints, xl)} + 80px);
            margin: 0 auto;
            padding-right: 40px;
            padding-left: 40px;
            & .dln-wrapper,
            &--fullWidth {
                @include make-row--fullWidth();
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    [class*="--mobileView"] {
        display: none;
    }
    [class*="--desktopView"] {
        display: block;
    }
}

/* @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-page {
        .dln-wrapper {
            &>div[class$="__content"] {
                width: 100%;
                margin: 0;
            }
            &>div[class$="__aside"] {
                width: 100%;
                margin: 0;
            }
        }
    }
} */

/* @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) and (orientation: portrait) {
    .dln-page {
        .dln-wrapper {
            &>div[class$="__content"] {
                width: 100%;
            }
            &>div[class$="__aside"] {
                width: 100%;
                margin: 0;
            }
        }
    }
} */

.theme-directory {
    margin: 24px 24px 24px 0;
    display: block;
    float: right;
}




.bra-item--breakpoints {
        .bra-item--breakpoints_default {display:block}
        .bra-item--breakpoints_l {display:none}
        .bra-item--breakpoints_m {display:none}
        .bra-item--breakpoints_s {display:none}
        .bra-item--breakpoints_xs {display:none}
    /*L*/
    @media only screen and (min-width: map-get($grid-breakpoints, lg) ) and (max-width: map-get($grid-breakpoints, xl) ) {
        .bra-item--breakpoints_default {display:none}
        .bra-item--breakpoints_l {display:block}
        .bra-item--breakpoints_m {display:none}
        .bra-item--breakpoints_s {display:none}
        .bra-item--breakpoints_xs {display:none}
    }
    /*M*/
    @media only screen and (min-width: map-get($grid-breakpoints, md) ) and (max-width: map-get($grid-breakpoints, lg)) {
        .bra-item--breakpoints_default {display:none}
        .bra-item--breakpoints_l {display:none}
        .bra-item--breakpoints_m {display:block}
        .bra-item--breakpoints_s {display:none}
        .bra-item--breakpoints_xs {display:none}
    }
    /*S*/
    @media only screen and (min-width: map-get($grid-breakpoints, sm) ) and (max-width: map-get($grid-breakpoints, md) ) {
        .bra-item--breakpoints_default {display:none}
        .bra-item--breakpoints_l {display:none}
        .bra-item--breakpoints_m {display:none}
        .bra-item--breakpoints_s {display:block}
        .bra-item--breakpoints_xs {display:none}
    }
    /*XS*/
    @media only screen and (max-width: map-get($grid-breakpoints, sm) ) {
        .bra-item--breakpoints_default {display:none}
        .bra-item--breakpoints_l {display:none}
        .bra-item--breakpoints_m {display:none}
        .bra-item--breakpoints_s {display:none}
        .bra-item--breakpoints_xs {display:block}
    }
}

@import '_template-kenwood';
@import '_template-delonghi';

#faq-article-content {
  span {
    font-family: var(--fontNameRoman)!important;
    font-weight: 400!important;
    font-style: normal!important;
    font-size: 16px!important;
    line-height: 20px!important;
  }

  p {
    font-size: 16px;
    margin-top: 10px;
  }  
}

// no comment
.dln-faq {
  &__main--noResults {
    & .dln-search-wrapper {
        width: 100%;
        @media (min-width: 600px) {
         width: 50%;
      }
    }
  }

  &__mainSubtitle {
    & +.dln-search-wrapper {
        width: 100%;
        @media (min-width: 600px) {
          width: 50%;
        }
    }
  }

  @media screen and (max-width: 768px) {
    &__video-container {
      position: relative;
      padding-bottom: 54.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
      }
    }
  }
}

.dln-delonghi {
  .dln-site-search {
    .dln-title,
    &__no-results-title {
      @include fontMedium;
      font-size: REM(50px);
      line-height: 1.04;
    }

    .dln-title {
      padding-bottom: 20px;
    }

    .dln-inputs {
      margin-bottom: 0;
    }

    &__results {
      font-size: REM(16px);
      line-height: 1.5;
    }

    .simple-list {
      padding-top: 20px;
      margin-bottom: 60px;
    }

    .dln-listItem {
      margin-bottom: 14px;
      .dln-icn {
        color: $brand-04;
      }
    }
    .dln-manuals-list {
      margin-bottom: 60px;
    }

    .dln-tabs__list.swiper-wrapper {
      .dln-tabs__btn {
        font-size: REM(18px);
      }
    }

    .dln-icn.dln-icn--iconfont.dln-icn--check-circle.dln-icn--T {
      font-size: REM(20px);
    }

    .dln-site-search__no-results-paragraph {
      font-size: REM(18px);
    }

    .simple-list {
      padding-top: 30px;
    }

    .dln-tabs__item {
      &--current {
        & .dln-tabs__btn {
          & .--amount,
          & .--amount i {
            font-weight: 500;
          }
        }
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-tabs.swiper-container-horizontal.swiper-container-android {
        margin-bottom: 14px;

        &::before {
          height: 1px;
        }
      }

      .dln-filters-bar__filters-wrap {
        padding-bottom: 10px;
        margin-bottom: 0;
      }

      .dln-filters-bar {
        margin-bottom: 18px;
      }

      .dln-tabs__currentLine {
        height: 1px;
      }

      .dln-site-search__no-results-title {
        font-size: REM(37px);
      }

      .dln-site-search__no-results-paragraph {
        font-size: REM(16px);
      }
    }
  }
}

.dln-kenwood {
    .dln-textarea {
        &__lbl {
            top: -18px;
        }
        &__input {
            &::placeholder {
                opacity: 0;
                color: $ui-07;
            }
        }
        &.dln-inputs--active {
            border-color: $ui-02;

            & .dln-inputs__input {
                color: $brand-02 !important
            }
            .dln-textarea__lbl {
                transform: none;
                top: -18px;
            }
        }
    }
}
.dln-marketingPreferences {
	#marketingComunicationSources__wrapper {
		&.disabled {
			opacity: .5;
			pointer-events: none;
			cursor: default;
		}
	}

	#dln-checkbox__checkbox_delonghi--wrapper {
		.dln-checkbox--image__image {
            width: 144px;
            height: 46px;
        }
	}
	#dln-checkbox__checkbox_braun--wrapper {
		.dln-checkbox--image__image {
            width: 108px;
            height: 44px;
        }
	}
	#dln-checkbox__checkbox_kenwood--wrapper {
		.dln-checkbox--image__image {
            width: 160px;
            height: 24px;
        }
	}
}
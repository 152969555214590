@media only screen and (max-width:599px) {
    .dln-findProductNumber {
        padding: 0 20px 100px 20px;
        .dln-popup__head {
            padding-bottom: 58px;
            .dln-btnIcon--close {
                transform: translateX(0);
            }
        }
        &__imgWrapper {
            display: flex;
            align-items: center;
            margin: 0;

            & > img {
                max-width: 90%;
                margin: 0 auto;
            }
        }
    }
}
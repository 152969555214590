@media only screen and (max-width:599px) {
    .dln-return-order {
        .dln-profileCard {
            display: none;
        }

        .dln-orderHistory--multiple .dln-orderRev:nth-child(odd) {
            background-color: transparent;
        }

        &__content {
            .dln-btnTextIcon {
                margin: 30px 0;
            }
        }

        &__section-title {
            @include fontBold;
            font-size: 18px;
            line-height: 1.18;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #e6e6e6;
            padding-top: 30px;
            margin-bottom: 20px;
        }

        &-box-detail {
            padding: 20px 20px 85px;
            position: relative;

            &__header {
                .dln-btn--link {
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                }
            }
            &__title {
                font-size: 22px;
                line-height: 1.23;
            }
            &__body {
                flex-wrap: wrap;
                margin-top: 0;
            }
            &__col {
                flex: 0 0 50%;
                margin-top: 20px;
                font-size: 15px;
                line-height: 1.2;
            }
        }

        &-product-list {
            .dln-orderRev--cart-order .dln-orderRev__actions .dln-btn {
                max-width: none;
            }
        }

        &-total-price {
            display: block;

            &__grid {
                width: 100%;
            }
        }

        &-receipt {
            width: 100%;

            .dln-btn--link {
                display: inline-block;
                margin-top: 25px;
            }

            &__content {
                display: block;
            }
        }

        &-info {
            flex-wrap: wrap;

            &__col {
                flex: 0 0 100%;

                &:nth-child(3),
                &:nth-child(4) {
                    flex: 0 0 50%;
                }

                + .dln-return-order-info__col {
                    margin-top: 30px;
                }
            }
            &__title {
                @include fontBold;
                font-size: 14px;
                line-height: 1.29;
            }
            &__description {
                font-size: 13px;
                line-height: 1.38;
                margin-top: 10px;
            }
        }
        &-returned {
            & .dln-orderRev--returned {
                & .dln-orderRev__actions {
                    & > * {
                        order: initial;
                    }
                    & .dln-btn:last-of-type {
                        margin-bottom: 18px;
                    }
                }
            }
        }
    }
}

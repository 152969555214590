.dln-kenwood {
    & .dln-instruction-manuals.dln-page {
        padding-top: 59px;
        padding-bottom: 70px;
        justify-content: center;

        @media only screen and (max-width:map-get($grid-breakpoints, md)) {
            padding-top: 32px;
        }

        & > .dln-wrapper {
            display: none;
        }

        & .dln-instruction-manuals__main {
            margin-bottom: 70px;
            justify-content: center;

            @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                margin-bottom: 59px;
            }

            & .dln-wrapper {
                flex-direction: column;
                justify-content: center;
                text-align: center;

                & .dln-instruction-manuals__mainTitle {
                    @include fontExtra;
                    line-height: 46px;
                    font-size: 38px;
                    text-align: center;
                    margin-bottom: 0px;

                    @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                        font-size: 32px;
                        line-height: 40px;
                        margin-top: 0;
                        margin-bottom: 0px;
                    }
                }
                & .dln-instruction-manuals__mainTitle + .dln-instruction-manuals__mainSubtitle {
                    margin-top: 32px;
                    @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                        margin-top: 30px;
                    }
                }
                & .dln-instruction-manuals__mainSubtitle {
                    line-height: 30px;
                    font-size: 24px;
                    text-align: center;
                    margin: 0;

                    @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                & .dln-instruction-manuals__searchWrapper + .dln-btn--link {
                    max-width: 199px;
                    margin-left: 24%;

                    @media screen and (min-width:321px) and (max-width:800px) {
                        max-width: 386px;
                        margin-left: 25%;
                    }

                    @media screen and (min-width: 801px) {
                        max-width: 386px;
                        margin: 4px auto;
                    }
                }
                & .dln-instruction-manuals__spellCheck {
                    margin-top: 22px;
                    margin-bottom: 0;
                    color: var(--brand-04);
                    @include fontLight;
                }
                & .dln-inputs--searchBar {
                    max-width: 386px;
                    border-color: $ui-01;
                    margin: 30px auto 0 auto;
                    width: 50%;

                    @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                        margin-top: 20px;
                    }

                    & .dln-inputs__input {
                        line-height: 39px;
                        padding: 2px 13px 2px 46px;

                        &::placeholder {
                            opacity: 1;
                        }

                    }
                    & .dln-inputs__button {
                        width: 23px;
                        height: 23px;
                        left: 13px;
                        
                        & .dln-icn {
                            font-size: 23px;
                        }
                    }
                }

                & .dln-inputs--searchBar + a.dln-btn {
                    margin: 4px auto;
                    max-width: 386px;

                    @include fontMedium;
                    font-size: 14px;
                    line-height: 20px
                }
            }
        }

        & .dln-instruction-manuals__content {
            padding-top: 9px;

            @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                padding-top: 0;
                margin-bottom: 58px;
            }

            & > .dln-wrapper {
                max-width: calc(1080px + 30px);

                & > .dln-btn--link {
                    position: absolute;
                    transform: translateY(-100%);
                }
            }

            .dln-instruction-manuals__product {
                width: 100%;
                padding: 30px 0;
                margin: 0;

                @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                    border-bottom: 1px solid $ui-01;
                }

                .dln-instruction-manuals__productImage {
                    @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                        margin-bottom: 10px;
                    }
                }

                .dln-instruction-manuals__mainSubtitle {
                   @include fontSemibold;
                   font-size: 20px;
                   line-height: 24px;
                   margin-bottom: 10px;
                }
                .dln-instruction-manuals__productSku {
                    @include fontLight;
                   font-size: 14px;
                   line-height: 20px;
                   margin: 0;
                }

                .dln-instruction-manuals__list {
                    margin: 30px 0;

                    li:nth-child(1) {
                        padding-top: 0
                    }

                    li {
                        .dln-icn--iconfont {
                            color: $brand-01;
                        }
                        .dln-instruction-manuals__listText {
                            @include fontBold;
                            color: $brand-04;
                            font-size: 14px;
                            line-height: 16px;

                            &--details {
                                @include fontLight;
                                color: $ui-04;
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                }
            }
        }

        & .dln-instruction-manuals__quickLinks {
            justify-content: center;

            & .dln-wrapper {
                max-width: calc(1080px + 30px);
            }
        }

        & .dln-instruction-manuals__helpSection {
            justify-content: center;
            margin-bottom: 70px;

            @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                margin-bottom: 60px;
            }

            & .dln-wrapper {
                max-width: calc(1080px + 30px);

                @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                    text-align: center;
                    justify-content: center;
                }

                &::after {
                    content: '';
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 1px;
                    background: $ui-01;
                    margin-top: 70px;

                    @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                        margin-top: 60px;
                    }
                }
            }

            &Title {
                width: 100%;
                padding-right: 0;
                margin-bottom: 20px;

                @include fontExtra;
                font-size: 28px;
                line-height: 36px;

                @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
            &Text {
                @include fontLight;
                margin-bottom: 30px;
                font-size: 14px;
                line-height: 20px;

                @media only screen and (max-width:map-get($grid-breakpoints, md)) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .dln-btn.dln-btn--backToSection {
                width: auto
            }
        }

        & .dln-category-browser__breadcrumbs {
            div {
                display: inline-block;
            }
         
            & .dln-breadCrumbSel {  
                display: inline-block;
         
                &__text {
                    display: inline-block;
                }
            
                &__text--selected {
                    display: inline-block;
                    color: var(--ui-07);
         
                    & + .dln-breadCrumbSel__arrow {
                        transform: rotate(360deg);
                        display: inline-block;
                    }
                }
         
                &__arrow {
                    transform: rotate(360deg);
                    display: inline-block;
                    top: -1px;
                    font-size: REM(8px);
                    color: var(--ui-07);
         
                    &--search {
                        top: -1px;
                    }
                }   
             }
        }
    }
}
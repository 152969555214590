@media only screen and (max-width:599px) {
    .dln-itemRegDetails {
        &__itemName {
            font-size: 15px;
        }
        &__itemImg {
            display: flex;
            align-content: center;
            justify-content: center;
            width: 100%;
            max-width: 559px;
            height: 489px;
            margin: 0 auto;

            & > img {
                // width: 100%;
                height: auto;
            }
        }
        &__details {
            width: 100%;
            margin: 16px 0 0;
            .dln-itemRegDetails__title {
                margin: 0 0 16px;
            }
            .dln-inputs,
            .dln-inputsFile,
            .dln-select {
                margin: 0 0 20px;
            }
            .dln-inputsFile {
                margin: 0 0 32px;
            }
        }
        &--productRegistration {
            .dln-itemRegDetails__itemImg {
                order: 1;
                width: 100%;
                max-width: 100%;
            }
            .dln-itemRegDetails__details {
                order: 3;
                width: 100%;
                max-width: 100%;
                margin: 16px 0 0;

                &--extra {
                    order: 2;
                    width: 100%;
                    max-width: 100%;
                    .dln-itemRegDetails__paragraph {
                        width: 100%;
                    }
                }
            }
        }
    }
}
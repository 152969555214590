.dln-orderDetailHead {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-radius: 6px;
  background-color: $ui-03;
  padding: 32px 20px;
  position: relative;
  box-sizing: border-box;

  &__number {
    @include fontBold;
    display: block;
    width: calc(100% - 32px - 48px);
    height: auto;
    line-height: 32px;
    font-size: 32px;
    margin: 0 32px 50px 0;
  }
  &__cancel {
    display: flex;
    flex-direction: column;
    width: 48px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    align-items: flex-end;
    color: $brand-01;
    text-decoration: underline;
    @include fontMedium;
    position: absolute;
    right: 24px;
    top: 24px;
    width: auto;
    border: none;
    background: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;

    a {
      color: var(--brand-04);
      height: 20px;
    }
  }
  &__email {
    display: block;
    width: 100%;
    font-size: 13px;
    line-height: 18px;
    margin: 0 0 37px 0;
    @include fontBold;
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  &__dataCell {
    display: flex;
    flex-wrap: wrap;
    width: 25%;
    padding: 0 16px 0 0;
    box-sizing: border-box;
    align-content: flex-start;
  }
  &__dataLabel,
  &__data,
  &__data p {
    display: block;
    width: 100%;
    line-height: 20px;
    font-size: 16px;
  }
  &__dataLabel {
    margin: 0 0 2px 0;
  }
  &__data ul {
    list-style: none;
  }
  &__data,
  &__data li {
    @include fontSemibold;
    font-weight: 600;
  }
  &--shipped {
    & .dln-orderDetailHead__number {
      margin-bottom: 15px;
    }
  }
}

.dln-delonghi {
  & .dln-faq {
    &--alert {
      padding-top: 10px !important;

      & > .dln-wrapper {
        & .dln-breadcrumbs {
          margin-bottom: 10px !important;
        }
      }
      & .dln-faq__chipsWrap {
        & > .dln-wrapper--fullWidth {
          & .dln-wrapper {
            padding: 0;
            margin: 0 auto;

            & .dln-chips {
              background-color: $ui-05;
              padding: 29px 21px 18px 21px;
              margin-bottom: 53px;

              &__wrapper {
                & .dln-icn {
                  color: $brand-04;
                  margin-bottom: 11px;
                }
              }
              &__content {
                & .dln-chips__info {
                  @include fontMedium;
                  line-height: 19px;
                  font-size: REM(18px);
                  color: $ui-02;
                  padding-top: 0;
                  margin-bottom: 16px;
                }
                & .dln-chips__title {
                  @include fontBook;
                  line-height: 24px;
                  padding-top: 0;
                  margin-bottom: 0;
                }
                & .dln-chips__description {
                  @include fontBook;
                  line-height: 18px;
                  margin-bottom: 24px;
                }
                & .dln-btn {
                  line-height: 18px;
                  font-size: REM(14px);
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      & .dln-faq__main {
        & .dln-wrapper {
          & .dln-inputs--searchBar {
            margin-bottom: 73px;
          }
        }
      }
      & .dln-category-browser__wrapper {
        & .dln-wrapper {
          & .dln-category-browser__breadcrumbs {
            align-items: center;
            margin-bottom: 15px;

            & .dln-select {
              & .dln-select__container {
                height: 20px;

                & .dln-select__head {
                  height: 20px;

                  & .dln-select__value {
                    line-height: 20px;
                  }
                }
              }
            }
            & .dln-breadCrumbSel {
              &__text {
                @include fontBook;
                font-size: REM(16px);
                color: $brand-04;
              }
              & .dln-breadCrumbSel__arrow {
                height: 20px;
                line-height: 20px;
                color: $brand-04;
              }
            }
          }
          & .dln-category-browser__category {
            & .dln-categoryBox {
              &__title {
                padding-bottom: 0;
              }
            }
          }
        }
      }
      & .dln-quickLinks--slider {
        & .swiper-wrapper {
          & .swiper-slide {
            & .dln-quickLink {
              min-height: 199px;
              padding: 21px 12px 28px 12px;
              box-sizing: border-box;

              & .dln-quickLink__wrap {
                & .dln-quickLink__iconWrap {
                  width: 48px;
                  height: 48px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    & .dln-faq {
      &--alert {
        & .dln-faq__chipsWrap {
          & .dln-wrapper {
            & .dln-wrapper {
              padding: 0;
              margin: 0;

              & .dln-chips {
                padding: 32px 19px 24px 21px;
                margin-bottom: 24px;

                &__wrapper {
                  padding: 0;

                  & .dln-btnIcon {
                    & .dln-icn {
                      font-size: REM(16px);
                    }
                  }
                  & .dln-chips__content {
                    padding: 0;

                    & .dln-chips__info {
                      line-height: 18px;
                      font-size: REM(16px);
                      margin-bottom: 11px;
                    }
                    & .dln-chips__title {
                      font-size: REM(16px);
                      margin-bottom: 24px;
                    }
                  }
                }
              }
            }
          }
        }
        & .dln-faq__main {
          & .dln-wrapper {
            & .dln-faq__mainTitle {
              line-height: 43px;
              font-size: REM(35px);
              margin-top: 0;
            }
            & .dln-inputs--searchBar {
              margin-bottom: 43px;
            }
          }
        }
        & .dln-category-browser__wrapper {
          & .dln-wrapper {
            & .dln-category-browser__breadcrumbs {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}

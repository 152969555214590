.dln-kenwood {
    & .dln-order {
        &--return {
            & .dln-order__content {
                & .dln-orderHistory.dln-orderHistory--multiple {
                    & .dln-orderRev-input-wrapper {
                        align-items: initial;

                        & .dln-checkbox {
                            transform: translateY(75px - 9px);
                            height: 18px;
                        }
                        & .dln-orderRev--productDescription {
                            & .dln-image.dln-orderRev__itemPic {
                                position: relative;
                                top: auto;
                                left: auto;
                            }
                            & .dln-orderRev__content {
                                width: calc(100% - 180px - 16px);
                                padding-right: 20px;
                                box-sizing: border-box;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-order {
            &--return {
                & .dln-order__content {
                    & .dln-orderHistory.dln-orderHistory--multiple {
                        & .dln-orderRev-input-wrapper {
                            align-items: initial;

                            & .dln-orderRev {
                                min-height: initial;
                            }
                            & .dln-checkbox {
                                transform: translateY(40px - 9px);
                                height: 18px;
                                margin-top: 0;
                            }
                            & .dln-orderRev--productDescription {
                                & .dln-orderRev__content {
                                    width: calc(100% - 100px - 20px);
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

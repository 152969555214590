.dln-kenwood {
    .dln-recipe {
        &:hover {
            border-color: $brand-04;
            .dln-recipe__title {
                border-width: 0;
            }
        }
        &__title {
            @include fontBold;
            // margin-bottom: 8px;
            margin: 0;
            padding: 15px 0 10px 0;
            height: auto;

            a {
                line-height: 17px;
                font-size: 14px !important;
                color: $brand-04 !important;
                display: block;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
        &__topic {
            font-size: 12px;
            color: $ui-07;
        }
        &__img {
            height: 170px;
            background: transparent;
            margin-bottom: 0;

            & .dln-btnIcon {
                top: 10px;
                right: 10px;
                background-color: rgba(255,255,255, 0.8);
                border-radius: 50%;

                & .dln-btnIcon__wrap {
                    & .dln-icn {
                        width: 40px;
                        height: 40px;
                        font-size: 24px;
                    }
                }
                &--bookmark-active {
                    & .dln-btnIcon__wrap {
                        & .dln-icn {
                            font-size: 0;

                            &::before {
                                @include fontIcon;
                                content: 'c';
                                font-size: 24px;
                                color: $brand-01;
                                line-height: 40px;
                            }
                        }
                    }
                }
                &--inactive {
                    & .dln-btnIcon__wrap {
                        & .dln-icn {
                            font-size: 0;

                            &::before {
                                @include fontIcon;
                                content: 'c';
                                font-size: 24px;
                                color: $brand-01;
                                line-height: 40px;
                            }
                        }
                    }
                }
            }
            .dln-btnIcon--delete {
                display: inline-flex;
                align-items: center;

                .dln-btnIcon__wrap {
                    width: auto;
                    height: auto;
                    display: inline-block;
                    margin: 0;
                    border-radius: 50%;
                }
            }
            .dln-icn {
                color: $brand-01;
                font-size: 24px;
                background-color: transparent;
                background: none;
                width: 40px;
                height: 40px;

                &:before {
                    color: $brand-03;
                    font-size: 18px;
                }
            }
        }

        &__detail {
            font-size: 12px !important;
        }

        .dln-image {
            // max-height: 264px;
            // max-width: 286px;
            border-radius: 0;
        }
        &__details {
            & .dln-recipe__detail {
                // &--difficultyLevel {
                //     & .dln-icn {
                //         font-size: 0;

                //         &::before {
                //             @include fontIcon;
                //             font-size: 24px;
                //             content: '';
                //         }
                //     }
                // }
                &--preparationTime {

                }
            }
        }
        @media only screen and (max-width: 599px) {
            &__img {
                height: auto;

                .dln-icn {
                    width: 40px;
                    height: 40px;

                    &:before {
                        font-size: 20px;
                    }
                }
            }
            &__title {
                font-size: 13px;
                padding: 10px 0;

                & > a {
                    text-overflow: initial;
                    white-space: normal;
                    overflow: initial;
                }
            }
        }
    }
}

/* @media screen and (max-width: 600px) {
    .dln-kenwood {
        .dln-recipe {
            .dln-image {
                max-height: 149px;
            }
        }
    }
}
 */
// @media screen and (max-width: 1000px) {
//     .dln-kenwood {
//         .dln-recipe {
//             &__title {
//                 margin-top: 10px;
//             }
//         }
//     }
// }

.dln-kenwood {
    .dln-contactUs {

        @media only screen and (max-width:599px) {
            &__head {
                &Title {
                    @include fontExtra;
                    line-height: 40px;
                    font-size: 32px;
                    margin: 0 0 40px 0;
                }
            }
            &__form {
                &Row {
                    .dln-inputs {
                        margin-top: 20px !important;
                        &:last-of-type {
                            margin-top: 20px !important;
                        }
                    }
                    .dln-inputs,
                    .dln-select {
                        margin-top: 20px !important;
                    }
                    &--first {
                        .dln-inputs:first-of-type {
                            margin-top: 20px !important;
                        }
                    }
                    &--address {
                        .dln-contactUs__formSplitWrap {
                            .dln-inputs,
                            .dln-select {
                                margin-top: 20px !important;
                            }
                        }
                    }
                }
                .dln-textarea {
                    margin-top: 20px !important;
                    margin-bottom: 0;
                }
            }

            &__title {
                @include fontSemibold;
                line-height: 22px;
                font-size: 18px;
            }

            &__callUs {
                &Title {
                    @include fontSemibold;
                    line-height: 22px;
                    font-size: 18px;
                    margin: 0 0 12px 0;
                }
                &Text {
                    @include fontLight;
                    line-height: 20px;
                    font-size: 14px;
                    margin: 0 0 15px 0;
                }
            }
        }
        .dln-wrapper--fullWidth {
            background-color: transparent;
            padding-top: 59px;
            padding-bottom: 0;
        }
        &__head {
            &Title {
                @include fontExtra;
                line-height: 46px;
                font-size: 38px;
                text-align: center;
            }
        }
        &__form {
            padding-top: 25px;

            &Row {
                .dln-inputs {
                    margin-top: 20px;
                    &:last-of-type {
                        margin-top: 20px;
                    }
                }
                .dln-inputs,
                .dln-select {
                    margin-top: 20px;
                }
                &--first {
                    .dln-inputs:first-of-type {
                        margin-top: 20px;
                    }
                }
                &--address {
                    .dln-contactUs__formSplitWrap {
                        .dln-inputs,
                        .dln-select {
                            margin-top: 20px;
                        }
                    }
                }
            }
            .dln-textarea {
                margin-top: 20px;
            }
        }

        &__title {
            @include fontSemibold;
            line-height: 24px;
            font-size: 20px;
            margin: 0 0 10px 0;
        }
        &__text {
            @include fontLight;
            margin: 0 0 25px 0;
        }
        &__mandatoryFields {
            color: $ui-08;
        }
        &__privacyWrap {
            .dln-inputsFile {
                margin-bottom: 20px;
            }
            .dln-contactUs__text {
                line-height: 16px;
                font-size: 12px;
                margin-bottom: 20px;
            }
        }
        .dln-btn--send {
            width: auto;
            margin: 0 auto;
            margin-top: 30px;
        }

        &__callUs {
            &Title {
                @include fontSemibold;
                line-height: 24px;
                font-size: 20px;
                margin: 0 0 10px 0;
            }
            &Text {
                @include fontLight;
                line-height: 20px;
                font-size: 14px;
                margin: 0 0 25px 0;
            }
        }
    }
}

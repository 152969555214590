.dln-kenwood {
    .dln-regProductList {

        &__content {
            & .dln-title {
                margin: 0 0 21px 0;
            }
        }

        &__paragraph {
            text-align: center;
            font-size: 14px;
            padding-bottom: 10px;
        }

        &__foot {
            border-top: 0;
            text-align: center;
            margin: 0;
            padding: 0;
            padding-top: 30px;
        }

        &__section {
            margin: 31px 0 50px 0;

            .dln-orderRev {
                &__content {
                    margin-top: 10px;
                }

                &__itemPic {
                    & > img {
                        object-fit: none;
                    }
                }

                &__details {
                    flex-direction: column;
                    display: flex;
                    height: 100%;
                    padding-right: 10px;

                    .dln-orderRev__wrap {
                        .dln-orderRev__purchaseDate {
                            b {
                                @include fontLight;
                            }
                        }
                        &--registeredproduct {
                            width: 100%;
                        }
                        &--links {
                            margin-top: auto;
                            width: 100%;
                            text-align: left;

                            & .dln-btn--link {
                                display: inline-flex;
                                width: auto;
                                text-align: left;

                                @include fontMedium;

                                line-height: 20px;
                            }

                            & .dln-btn--link + .dln-btn--link {
                                margin-left: 31px;
                            }
                        }
                    }
                }

                &__actions {
                    flex-direction: column-reverse;
                    display: flex;
                    height: 100%;

                    .dln-orderRev__wrap {
                        display: flex;
                        max-width: initial;
                        width: 100%;

                        &--links {
                            margin-top: auto;
                        }
                        &:first-of-type {
                            align-content: flex-end;
                            flex-direction: row-reverse;

                            & .dln-btn--link + .dln-btn--link {
                                margin-right: 31px;
                            }
                        }
                        &:nth-of-type(2) {
                            flex-direction: column-reverse;
                        }

                        & .dln-btn--link {
                            display: inline-flex;
                            width: auto;
                            text-align: left;

                            @include fontMedium;
                            line-height: 20px;
                            margin: 0;
                        }


                    }
                }


            }
        }

        &__section + .dln-regProductList__foot {
            border-top: 1px solid $ui-07;
        }



        @media only screen and (max-width:599px) {
            &__content {
                .dln-title {
                    margin: 30px 0 0 0;
                }
            }
            &__section {
                margin: 20px 0 50px 0;

                .dln-orderRev {
                    margin: 0;
                    padding: 29px 0 30px 0;

                    &:first-of-type {
                        padding-top: 0;
                    }

                    &:last-of-type {
                        border-bottom: 0;
                        padding-bottom: 0;
                    }

                    &__itemPic {
                        width: 100%;
                        height: 272px;
                        max-height: none;
                        padding: 0;
                        margin: 0 auto;

                        @media screen and (max-width:599px) {
                            max-width: 335px;
                            height: 272px;
                            margin: 0 auto;
                        }
                        
                    }

                    &__content {
                        width: 100%;
                    }

                    &__wrap {
                        &--main {
                            margin: 0 0 10px 0;
                        }
                        &--registeredproduct {
                            border-bottom: 0;
                            padding: 0;
                            margin: 0 0 40px 0;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    &__detailsTitle {
                        margin: 0;
                    }

                    &__details,
                    &__actions {
                        height: auto;
                        width: 100%;
                        max-width: 100%;
                    }

                    &__details {
                        padding-right: 0;
                        .dln-orderRev__wrap {
                            &--links {
                                display: block;

                                & .dln-btn--link {
                                    display: block;
                                    text-align: center;
                                    margin: 0 0 10px 0;
                                }

                                & .dln-btn--link + .dln-btn--link {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                    &__actions {
                        flex-direction: column;
                        width: auto;
                        margin: 20px auto 0 auto;

                        .dln-btn:first-child {
                            margin: 0;
                        }

                        .dln-orderRev__wrap {
                            &:first-of-type {
                                .dln-btn--link {
                                    display: inline-block;
                                    width: 50%;
                                    text-align: right;
                                    text-align-last: right;
                                }
                                .dln-btn--link + .dln-btn--link {
                                    margin-right: 0;
                                    text-align: left;
                                    text-align-last: left;
                                }
                            }

                            &:last-of-type {
                                margin: 0 0 16px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dln-title {
    display: block;
    width: 100%;
    height: auto;
    line-height: 100%;
    font-size: 44px;
    @include fontBold;
    color: $brand-04;
    &--labeled {
        display: flex;
        width: 100%;
    }
    &__innerLabel {
        font-size: 10px;
        color: $brand-02;
        margin: 0 0 0 auto;
    }
}

@media only screen and (max-width:599px) {
    .dln-title {
        font-size: 35px;
    }
}

@import "_title-delonghi";
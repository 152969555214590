.dln-faqShare {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 0 56px 0;

    & .dln-popup__head {
        margin-left: 24px;
        margin-right: 24px;
        padding-bottom: 24px;

        &Title {
            text-align: left;
            padding: 0 96px 0 96px;
        }
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 120px;
        box-sizing: border-box;

        & .dln-inputs {
            margin: 0 0 24px 0;

            &:last-of-type {
                margin: 0 0 48px 0;
            }
        }
    }
    &__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        width: 100%;
        padding: 0 120px;
        box-sizing: border-box;
        
        & .dln-btn--cancel {
            @include fontBold;
            margin: 0 auto 0 0;
        }
        & .dln-btn--confirm {
            width: 184px;
            margin: 0 0 0 auto;
        }
    }
}
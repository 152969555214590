.dln-delonghi {
  & .dln-checkServiceBox {
    display: inline-block;
    padding: 24px 22px;

    &__iconWrap {
      margin-bottom: 22px;

      & > .dln-icn {
            line-height: 48px;
            font-size: REM(34px);
            color: $brand-01;
      }

      & > .dln-icn.dln-icn--iconfont.dln-icn--B, 
        > .dln-icn.dln-icn--iconfont.dln-icn--x {
            font-size: REM(48px);
      }
    }
    &__content {
      & .dln-checkServiceBox__title {
        @media only screen and (max-width: 599px) {
          text-align: left;
        }
        width: 100%;
        line-height: 18px;
        font-size: REM(14px);
        text-align: center;
        margin: 0 0 2px 0;
      }
      & .dln-checkServiceBox__text {
        line-height: 18px;
        font-size: REM(14px);
        margin-bottom: 10px;

        & + .dln-btn--secondary {
          color: $brand-04;
          line-height: 18px;
        }
      }
    }
  }
}

.dln-delonghi {
  .dln-return-order {
    .dln-btnTextIcon__wrap {
      i,
      span {
        font-weight: 500;
      }

      i {
        margin-top: 2px;
      }
    }

    &__title {
      @include fontMedium;
      font-size: REM(50px);
    }

    .dln-return-order-box-detail {
      &__header {
        .dln-return-order-box-detail__title {
          @include fontMedium;
        }

        .dln-btn--link {
          @include fontMedium;
          color: $brand-04;
        }
      }

      .dln-return-order-box-detail__col-value {
        @include fontSemibold;
      }
    }

    .dln-return-order-product-list {
      background: $ui-05;
      padding: 20px;
      border: none;
      margin-bottom: 24px;

      .dln-return-order__section-title {
        @include fontMedium;
      }

      .dln-btn--link {
        @include fontMedium;
        color: $brand-04;
      }

      .dln-orderRev__actions {
        button {
          background: $ui-05 !important;
        }
      }

      .dln-return-order__header {
        padding-top: 0;
        border: none;
      }
      & .dln-orderHistory {
        margin-bottom: 0;

        & .dln-orderHistory__content {
          & .dln-orderRev {
            &:last-child {
              margin-bottom: 0;
            }
            &__content {
              padding: 0;
            }
          }
        }
      }
    }

    .dln-totalSect,
    .dln-return-order-receipt,
    .dln-return-order-info {
      margin-bottom: 20px;
      border: none;
      background: $ui-05;
      padding: 20px;

      .dln-return-order-receipt__text,
      .dln-return-order-info__title {
        font-size: REM(16px);
      }

      .dln-return-order__header {
        border: none;
        padding-top: 0;
        margin-bottom: 22px;
      }

      h3 {
        font-size: REM(21px);
      }
      h3,
      .dln-return-order-info__title {
        @include fontMedium;
      }

      .dln-return-order-info__description,
      .dln-btn--link {
        font-size: REM(14px);
      }
    }

    &-returned {
      background: $ui-05;
      padding: 20px;
      margin-bottom: 24px;

      .dln-btn--link {
        color: $brand-04;
        @include fontMedium;
      }

      &__box {
        padding: 0;
        &-title {
          @include fontMedium;
          font-size: REM(16px);
        }
        &-description {
          font-size: REM(14px);
        }
      }

      .dln-orderRev--returned {
        .dln-image.dln-orderRev__itemPic,
        .dln-orderRev__details {
          opacity: 0.7;
        }

        .dln-orderRev__actions {
          .dln-btn {
            background: $ui-05;
          }
        }

        .dln-orderRev__howItWorks {
          color: $brand-04;
          @include fontMedium;
        }
      }

      .dln-return-order {
        &__section-title {
          @include fontMedium;
        }

        &__header {
          border: none;
          padding-top: 0;
          margin-bottom: 8px;

          & .dln-return-order__section-title {
            font-size: REM(21px);
            line-height: 24px;
          }
        }
      }
      & .dln-return-order-returned__box {
        margin-bottom: 19px;

        &-title {
          @include fontSemibold;
          line-height: 24px;
          margin-bottom: 0;
        }
      }
      & .dln-orderHistory {
        margin-bottom: 0;

        & .dln-orderRev {
          margin-bottom: 0;
        }
      }
    }
    &-returned--accept {
      & .dln-return-order__header {
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: 1000px) {
  .dln-delonghi {
    .dln-return-order {
      .dln-orderRev.dln-orderRev--cart-order,
      .dln-orderRev--returned {
        .dln-orderRev__content {
          flex-direction: column;
          align-items: flex-start;
        }

        .dln-orderRev__actions {
          margin-top: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    .dln-return-order {
      .dln-orderRev.dln-orderRev--cart-order {
        padding-left: 0;
        padding-right: 0;
      }

      .dln-image.dln-orderRev__itemPic {
        top: 9px;
      }

      .dln-return-order-info {
        flex-direction: column;

        .dln-return-order-info__col {
          margin-top: 0;
          padding-bottom: 12px;
          padding-top: 12px;
          border-bottom: 1px solid $ui-01;

          &:first-of-type,
          &:last-of-type {
            border: none;
          }
        }
      }
      &-returned {
        padding-bottom: 30px;

        & .dln-return-order__header {
          margin-bottom: 19px;

          & .dln-return-order__section-title {
            font-size: REM(18px);
          }
        }
        & .dln-return-order-returned__box {
          margin-bottom: 30px;

          &-title {
            margin-bottom: 4px;
          }
        }
        & .dln-orderHistory {
          &__content {
            & .dln-orderRev {
              padding: 0;

              &__content {
                & .dln-orderRev__details {
                  & .dln-orderRev__wrap {
                    &--quantity {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &-returned--accept {
        & .dln-orderRev__actions {
          padding-top: 24px;
        }
      }
    }
  }
}

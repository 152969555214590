.dln-kenwood {
    .dln-customerSupport {
        &__head {
            &Title {
                @include fontExtra;
                text-align: center;
                font-size: 38px;
                line-height: 46px;
                margin: 0 0 12px 0;
            }
        }
        &__title {
            @include fontExtra;
            text-align: center;
            font-size: 28px;
            line-height: 36px;
        }

        &__content {
            margin: auto;
        }

        & .dln-wrapper {
            &:not(&--fullWidth) {
                > .dln-breadcrumbs {
                    margin-top: 40px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            &--content {
                .dln-customerSupport__inner {
                    padding-top: 70px;
                    padding-bottom: 70px;
                }
                .dln-customerSupport__subtitle {
                    @include fontSemibold;
                    font-size: 20px;
                    line-height: 24px;
                }
            }
            &--quickLinks {
                .dln-customerSupport__inner {
                    padding-top: 70px;
                    padding-bottom: 70px;
                }
                .dln-customerSupport__subtitle {
                    text-align: center;
                }
                .dln-carousel {
                    .dln-quickLink {
                        height: auto;
                        min-height: 170px;
                        border: 0;
                        text-align: center;
                    }

                    &.swiper-container {
                        .swiper-pagination {
                            margin: 32px 0 0;
                            // width: 100%;
                            // display: flex;

                            // .swiper-pagination-bullet {
                            //     margin: 0;
                            //     width: 50%;
                            //     background-color: transparent;

                            //     &-active{
                            //         margin: 0;
                            //         width: 50%;
                            //         background-color: $brand-01;
                            //     }
                            // }

                        }
                    }
                }
            }
        }

        & .dln-customerSupport__searchWrapper {
            max-width: 100%;
            margin: 0;
            background: $ui-03;
            padding-top: 6px;
            padding-bottom: 48px;

            & .dln-customerSupport__inner {
                padding-top: 0;
                padding-bottom: 0;

                & .dln-customerSupport__search {
                    width: 100%;

                    &Title {
                        width: 100%;
                        @include fontBold;
                        font-size: 24px;
                        line-height: 30px;
                        text-align: center;
                        padding-bottom: 30px;
                        margin: 0;
                    }

                    & .dln-inputs--searchBar {
                        display: block;
                        margin: 0 auto;
                        width: 30%;
                    }
                }

                & .dln-inputs--searchBar {
                    .dln-inputs__lbl {
                        top:  15px;
                        margin-left: 12px;
                    }
                }
            }
        }

        @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, lg)) {
            & .dln-customerSupport__searchWrapper {
                & .dln-customerSupport__inner {
                    & .dln-customerSupport__search {
                        & .dln-inputs--searchBar {
                            width: 50%;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width:599px) {
            &__head {
                &Title {
                    font-size: 32px;
                    line-height: 40px;
                }
            }

            &__title {
                font-size: 24px;
                line-height: 32px;
            }

            &__subtitle {
                font-size: 18px;
                line-height: 22px;
            }

            & .dln-wrapper {
                &--content {
                    padding-bottom: 0;
                    .dln-customerSupport__inner {
                        padding-top: 60px;
                        padding-bottom: 60px;
                    }
                }
            }

            & .dln-customerSupport__searchWrapper {
                background: transparent;
                padding-top: 28px;
                padding-bottom: 60px;
                padding-right: 0;
                padding-left: 0;
                border-bottom: 1px solid $ui-01;

                margin-right: 20px;
                margin-left: 20px;

                & .dln-customerSupport__inner {
                    padding-top: 0;
                    padding-bottom: 0;

                    & .dln-customerSupport__search {
                        &Title {
                            font-size: 20px;
                            line-height: 24px;
                            padding-bottom: 20px;
                        }

                        & .dln-inputs--searchBar {
                            width: 100%;
                        }
                    }

                }
            }

            & .dln-wrapper {
                &--fullWidth {
                    background: transparent;
                }
                &--quickLinks {
                    padding-top: 60px;
                    padding-bottom: 70px;

                    .dln-customerSupport__inner {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    .dln-customerSupport__subtitle {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}






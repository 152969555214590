.dln-kenwood {
    .dln-addBox {
        width: 387px;
        min-height: 220px;
        border-style: dashed;
        .dln-icn {
            text-indent: 9999px;
            width: 50px;
            height: 50px;
            border: 1px solid $brand-01;
            border-radius: 50%;
            margin: 0 0 8px 0;
            overflow: hidden;
            &::before {
                text-indent: 0;
                content: '8';
                width: auto;
                height: auto;
                font-size: REM(16px);
                color: $brand-01;
                top: calc(50% - 8px);
                left: calc(50% - 8px);
            }
        }
        &__title {
            line-height: REM(19px);
            font-size: REM(13px);
            color: $brand-01;
            margin: 0 0 8px 0;
            @include fontSemibold;
        }
        &__text {
            width: 80%;
            line-height: REM(16px);
            font-size: REM(12px);
            color: $ui-08;
        }
        &:hover {
            .dln-addBox__title {
                color: $brand-04;
            }
            .dln-addBox__text {
                color: $ui-04;
            }
        }
        @media screen and (max-width: 599px) {
            &__title {
                font-size: REM(18px);
            }
        }
        &__button {
            height: fit-content;
            .dln-icn {
                color: $brand-01;
            }
        }
    }
}
.dln-kenwood {
    & .dln-faq {
        padding-top: 59px;
        padding-bottom: 70px;
        justify-content: center;

        & > .dln-wrapper {
            display: none;
        }
        & .dln-faq__main {
            margin-bottom: 70px;

            & .dln-wrapper {  
                & .dln-faq__mainTitle {
                    @include fontExtra;
                    line-height: 46px;
                    font-size: 38px;
                    text-align: center;
                }
                & .dln-faq__mainSubtitle {
                    line-height: 30px;
                    font-size: 24px;
                    text-align: center;          
                    margin: 0;          
                }
                & .dln-search-wrapper {
                    width: 100%;
                }
                & .dln-faq__spellCheck {             
                    @include fontLight;
                    line-height: 20px;
                    font-size: 14px;
                    color: $brand-04;
                    text-align: center;       
                    margin: 22px 0 0 0;
                }
                & .dln-inputs--searchBar {
                    max-width: 386px;
                    border-color: $ui-01;
                    margin: 30px auto 0 auto;

                    & .dln-inputs__input {
                        line-height: 39px;
                        padding: 2px 13px 2px 46px;

                        &::placeholder {
                            opacity: 1;
                        }

                    }
                    & .dln-inputs__button {
                        width: 23px;
                        height: 23px;
                        left: 13px;
                        
                        & .dln-icn {
                            font-size: 23px;
                        }
                    }
                }
            }
        }
        & .dln-faq__content { 
            padding-top: 0;
            margin-bottom: 70px;

            &  .dln-wrapper {
                border-top: 1px solid $ui-01;
                padding-top: 70px;
                padding-bottom: 48px;

                &:last-child {
                    border-bottom: 1px solid $ui-01;
                }

                & .dln-faq__title {
                    @include fontSemibold;
                    line-height: 24px;
                    font-size: 20px;
                    text-align: center;
                    margin-bottom: 46px;
                }
                & .dln-faq__category {
                    & .dln-categoryBox {
                        border: none;
                        
                        & .dln-categoryBox__title {
                            line-height: 16px;
                            text-align: center;
                            border-bottom: none;
                            padding-bottom: 0;
                            margin-top: 10px;
                        }
                    }
                }
                & .dln-faq__breadCrumbSelWrap {
                    margin-bottom: 35px !important;

                    & .dln-faq__text {
                        line-height: 24px;
                        font-size: 20px;
                        text-align: center;
                        margin-bottom: 46px;
                    }
                    & .dln-select {
                        &__value {
                            @include fontSemibold;
                        }
                    }
                }
                & .dln-faq__text {
                    @include fontLight;
                }
                & .dln-faq__textListItem {
                    @include fontLight;
                }
            }
        }
        & .dln-faq__helpSection {
            justify-content: center;
            margin: 0;

            & .dln-wrapper {
                border-top: none;
                border-bottom: 1px solid $ui-01 !important;
                padding-top: 0;
                padding-bottom: 70px;
            }
            &Title {
                @include fontExtra;
                width: 100%;
                line-height: 36px;
                font-size: 28px;
                padding: 0;
                margin-bottom: 20px;
            }
            &Text {
                line-height: 20px;
                font-size: 14px;
                margin-bottom: 30px;
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-faq {
            padding-top: 37px;

            &--alert {
                padding-top: 20px !important;
            }
            & > .dln-faq__chipsWrap {
                margin-bottom: 40px;

                & .dln-wrapper {
                    & .dln-chips--recallInformation {
                        padding: 23px 18px 33px 18px;

                        & .dln-chips__wrapper {
                            align-items: center;
                            padding-top: 0;

                            & .dln-chips__content {

                            }
                        }
                    }
                }
            }
            &__main {
                margin-bottom: 60px !important;

                & .dln-wrapper {
                    & .dln-faq__mainTitle {
                        line-height: 40px !important;
                        font-size: 32px !important;
                        margin-top: 0;
                        margin-bottom: 30px;
                    }
                    & .dln-faq__mainSubtitle {
                        line-height: 24px !important;
                        font-size: 20px !important;
                    }
                    & .dln-inputs--searchBar {
                        margin-top: 20px !important;

                        & .dln-inputs__input {
                            height: 45px;
                        }
                    }
                }
            }
            &__content {
                padding: 0 10px;
                margin-bottom: 60px !important;

                & .dln-wrapper {
                    padding-top: 60px !important;
                    padding-bottom: 60px !important;

                    & .dln-faq__title {
                        line-height: 22px !important;
                        font-size: 18px !important;
                        margin-bottom: 30px !important;
                    }
                    & .dln-faq__category {
                        & .dln-categoryBox {
                            padding: 15px 0;
                        }
                    }
                }
                & .dln-faq__breadCrumbSelWrap {
                    margin-bottom: 35px !important;

                    & .dln-faq__text {
                        line-height: 22px !important;
                        font-size: 18px !important;                         
                        margin-bottom: 30px !important;
                    }
                    & .dln-select {
                        &__value {
                            font-size: 18px !important;
                        }
                    }
                }
            }
        }
    }    
}
.dln-addAccessories {
  width: 100%;
  border-radius: 2px;
  background-color: $brand-03;
  padding: 16px 40px 40px;

  &__content {
    @include small;
    width: 100%;
    padding: 16px 0 0;
  }
  
  &--addedItem {
    padding: 0 0;

    .dln-popup__head {
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      margin: 0 22px;
      padding: 19px 0 14px;
      width: auto;
      border-bottom: 1px solid $ui-01;

      .dln-btnIcon {
        margin: 0;
        order: 1;
        width: 16px;
        height: 16px;
      }

      .dln-popup__headTitle {
        margin: 0;
        width: auto;
        font-size: 16px;
      }
    }

    .dln-addAccessories__content {
      padding: 15px 20px 11px;
      border-bottom: 1px solid $ui-01;

      &--extra {
        display: flex;
        flex-wrap: wrap;
        background-color: $brand-03;
        padding: 32px 20px 23px;

        .dln-btn {
          margin: 13px auto;

          .dln-icn {
            height: auto;
          }
          
        }

        .dln-btn--support02 {
          border: 1px solid $ui-02;
          background: $brand-03 !important;
          color: #0f8a0f;
          align-self: auto;
          justify-content: center;

          .dln-btn__lbl {
            color: $support-02;
            margin-bottom: 5px;
          }

          i {
            display: inline-block;
          }
        }

        .dln-addAccessories__subTitle {
          @include mainMenu;
          @include fontBold;
          width: 100%;
          color: $brand-02;
          border-bottom: 1px solid $ui-01;
          padding: 0 0 16px;
          margin: 0 0 24px;
          display: none;
        }
      }
    }

    &.dln-addAccessories--suggestion {
      .dln-cartItem__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row;
      }

      .dln-cartItem__picture {
        margin: 0 0 0 0;
      }

      .dln-cartItem__title {
        margin: 0 0 8px 0;
        align-self: flex-start;
        width: 50%;
      }
    }
  }

  .dln-addItem {
    margin: 0 0 19px 0;

    &:nth-child(odd) {
      margin: 0 0 19px;
    }
  }

  .dln-cartItem {
    border-width: 0;
    padding: 0 0 0;

    &__picture {
      width: 120px;
      height: 96px;

      & > img {
        object-fit: contain;
      }
    }

    &__price {
      width: 80px;
      text-align: right;
      margin: 0 0 0 16px;

      .dln-price {
        display: inline-block;
        font-size: 13px;
        margin: 0 0 8px;
      }
    }

    &__btn {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-top: 1px solid $ui-01;
      padding: 16px 0 0;
      margin: 24px 0 0;

      .dln-btn {
        width: calc(50% - 10px);
        margin: 16px 20px 0 0;
      }
    }

    .dln-csmDropdown {
      width: calc(100% - 120px - 24px);
      float: left;
      margin: 8px 0 0 24px;
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, lg)) {
  .dln-addAccessories {
    width: 100%;
    border-radius: 2px;
    background-color: $brand-03;
    padding: 16px 40px 40px;

    &__content {
      @include small;
      width: 100%;
      padding: 16px 0 0;
    }

    &--addedItem {
      padding: 0 0;

      .dln-popup__head {
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 40px;
        padding: 26px 0 16px;
        width: auto;
        border-bottom: 1px solid $ui-01;

        .dln-btnIcon {
          margin: 0;
          order: 1;
          width: 16px;
          height: 16px;
        }

        .dln-popup__headTitle {
          margin: 0;
          width: auto;
          font-size: 16px;
        }
      }

      .dln-addAccessories__content {
        padding: 20px 80px 24px;
        border-bottom: 1px solid $ui-01;

        &--extra {
          display: grid;
          grid-template-columns: repeat(2, 240px);
          background-color: $brand-03;
          column-gap: 21px;
          row-gap: 14px;
          padding: 31px 40px 30px;

          .dln-btn {
            height: 32px;
            align-self: flex-end;

            .dln-icn {
              height: auto;
            }
          }

          .dln-btn--link {
            margin: 0 auto;
            grid-column: span 2;
          }

          .dln-btn--support02 {
            border: 1px solid $ui-02;
            background: $brand-03 !important;
            color: #0f8a0f;
            align-self: auto;
            justify-content: center;

            .dln-btn__lbl {
              color: $support-02;
              margin-bottom: 5px;
            }

            i {
              display: inline-block;
            }
          }

          .dln-addAccessories__subTitle {
            @include mainMenu;
            @include fontBold;
            width: 100%;
            color: $brand-02;
            border-bottom: 1px solid $ui-01;
            padding: 0 0 16px;
            margin: 0 0 24px;
            display: none;
          }
        }
      }

      &.dln-addAccessories--suggestion {
        .dln-cartItem__content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .dln-cartItem__picture {
          margin: 0 0 0 0;
        }

        .dln-cartItem__title {
          margin: 0 0 8px 11px;
          align-self: auto;
          font-size: 18px;
          width: 50%;
        }
      }
    }

    .dln-addItem {
      margin: 0;
      align-items: flex-start;

      &:nth-child(odd) {
        margin: 0;
      }
    }

    .dln-cartItem {
      border-width: 0;
      padding: 0 0 0;

      &__picture {
        width: 120px;
        height: 96px;

        & > img {
          object-fit: contain;
        }
      }

      &__price {
        width: 80px;
        text-align: right;
        margin: 0 0 0 16px;

        .dln-price {
          display: inline-block;
          font-size: 13px;
          margin: 0 0 8px;
        }
      }

      &__btn {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid $ui-01;
        padding: 16px 0 0;
        margin: 24px 0 0;

        .dln-btn {
          width: calc(50% - 10px);
          margin: 16px 20px 0 0;

          &:last-of-type {
            margin: 16px 0 0;
          }
        }
      }
      .dln-csmDropdown {
        width: calc(100% - 120px - 24px);
        float: left;
        margin: 8px 0 0 24px;
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
  .dln-addAccessories {
    width: 100%;
    border-radius: 2px;
    background-color: $brand-03;
    padding: 16px 40px 40px;

    &__content {
      @include small;
      width: 100%;
      padding: 16px 0 0;
    }

    &--addedItem {
      padding: 0 0;

      .dln-popup__head {
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 22px;
        padding: 19px 0 14px;
        width: auto;
        border-bottom: 1px solid $ui-01;

        .dln-btnIcon {
          margin: 0;
          order: 1;
          width: 16px;
          height: 16px;
        }

        .dln-popup__headTitle {
          margin: 0;
          width: auto;
          font-size: 15px;
        }
      }

      .dln-addAccessories__content {
        padding: 13px 85px 40px;
        border-bottom: 1px solid $ui-01;

        &--extra {
          display: flex;
          flex-wrap: wrap;
          background-color: $brand-03;
          padding: 28px 137px;

          .dln-btn {
            height: 32px;
            margin: 13px auto;

            .dln-icn {
              height: auto;
            }
          }

          .dln-btn--support02 {
            border: 1px solid $ui-02;
            background: $brand-03 !important;
            color: #0f8a0f;
            align-self: auto;
            justify-content: center;

            .dln-btn__lbl {
              color: $support-02;
              margin-bottom: 5px;
            }

            i {
              display: inline-block;
            }
          }

          .dln-addAccessories__subTitle {
            @include mainMenu;
            @include fontBold;
            width: 100%;
            color: $brand-02;
            border-bottom: 1px solid $ui-01;
            padding: 0 0 16px;
            margin: 0 0 24px;
            display: none;
          }
        }
      }

      &.dln-addAccessories--suggestion {
        .dln-cartItem__content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          flex-direction: row;
        }

        .dln-cartItem__picture {
          margin: 0 0 0 0;
        }

        .dln-cartItem__title {
          margin: 0 0 8px 0;
          align-self: flex-start;
          font-size: 18px;
          width: 50%;
        }
      }
    }

    .dln-addItem {
      margin: 0 0 19px 0;

      &:nth-child(odd) {
        margin: 0 0 19px;
      }
    }

    .dln-cartItem {
      border-width: 0;
      padding: 0 0 0;

      &__picture {
        width: 120px;
        height: 96px;

        & > img {
          object-fit: contain;
        }
      }

      &__price {
        width: 80px;
        text-align: right;
        margin: 0 0 0 16px;
        .dln-price {
          display: inline-block;
          font-size: 13px;
          margin: 0 0 8px;
        }
      }

      &__btn {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border-top: 1px solid $ui-01;
        padding: 16px 0 0;
        margin: 24px 0 0;

        .dln-btn {
          width: calc(50% - 10px);
          margin: 16px 20px 0 0;

          &:last-of-type {
            margin: 16px 0 0;
          }
        }
      }

      .dln-csmDropdown {
        width: calc(100% - 120px - 24px);
        float: left;
        margin: 8px 0 0 24px;
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .dln-cartItem {
    &__accessories,
    &__warranty {
      width: 100%;
      margin: 8px 0 0;
    }
    &__accessories {
      margin: 32px 0 0;
      display: none;

      .dln-select,
      .dln-btn,
      .dln-btnTextIcon {
        width: 300px;
        margin: 0 0 20px;
      }
    }

    &__price {
      flex-direction: column;

      .priceBox {
        flex-direction: column;
      }

      .dln-cart-order-item__vat-included {
        margin: 10px 0 0;
      }
    }

    &__accessoriesMobile {
      display: block;
      margin-top: 20px;
      width: 100%;

      .dln-btnTextIcon {
        width: 100%;

        &__text {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, lg)) {
  .dln-cartItem {
    &__accessories,
    &__warranty {
      width: 100%;
      margin: 8px 0 0;
    }
    &__accessories {
      min-width: initial;
      margin: 32px 0 0;

      .dln-select,
      .dln-btn,
      .dln-btnTextIcon {
        width: 100%;
        margin: 0 0 20px;
      }
    }
  }
}

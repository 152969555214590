.dln-tabs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 32px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $ui-01;
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &__gradient {
        display: block;
        width: 32px;
        height: 32px;
        opacity: 0;
        transition: opacity 0.12s linear;
        position: absolute;
        top: 0;
        z-index: 4;

        &.-left {
            background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            left: 0;
        }
        &.-right {
            background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
            right: 0;
        }
        &.-visible {
            opacity: 1;
        }
    }
    &__list {
        display: inline-block;
        width: auto;
        height: 30px;
        font-size: 0;
        white-space: nowrap;
        list-style: inside none;
        border-bottom: 2px solid transparent;
        position: relative;
        z-index: 2;
    }
    &__item {
        display: inline-block;
        width: auto;
        height: 30px;
        margin: 0 35px 0 0;

        &--current {
          & .dln-tabs__btn {
              font-weight: 700;

              & .--amount,
              & .--amount i {
                  font-weight: 700;
              }
          }
        }
    }
    &__btn {
        display: inline-block;
        width: auto;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        border: none;
        background: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;

        &Amount {
            display: inline-block;
            width: auto;
            margin: 0 0 0 3px;
        }
    }
    &__currentLine {
        display: block;
        width: 0;
        height: 2px;
        background-color: $brand-01;
        transition: transform 0.3s ease;
        position: absolute;
        bottom: -2px;
        left: 0;
        z-index: 3;
    }
    &__content {
        display: none;

        &--current {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
    }
    &--icon {
        width: 118px;
        height: 40px;
        background-color: $ui-03;

        // & * {
        //     background: rgba(black, .2);
        // }
        // & *:nth-of-type(odd) {
        //     background: rgba(black, .1);
        // }

        &::before {
            display: none;
        }
        & .dln-tabs__gradient {
            display: none;
        }
        & .dln-tabs__list {
            height: 40px;
        }
        & .dln-tabs__item {
            width: 57px;
            height: 40px;
            border-bottom: none;
            margin: 0;

            &--current {
                & .dln-icn {
                    color: $brand-01;
                }
            }
            &:first-child {
                border-right: 4px solid $brand-03;
            }
        }
        & .dln-tabs__btn {
            align-items: center;
            justify-content: center;
            width: 57px;
            height: 40px;
        }
        & .dln-icn {
            color: $ui-07;
        }
    }
}
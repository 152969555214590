.dln-dropdown {
    width: 100%;
    max-width: 400px;
    background-color: transparent;
    &__arrow {
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 9px solid $brand-03;
        margin-left: 20px;
    }
    &__wrapper {
        width: 100%;
        padding: 40px;
        border-radius: 2px;
        background-color: $brand-03;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1);
    }
    &__head {
        width: 100%;
        padding: 0 0 16px;
    }
    &__content {
        width: 100%;
        padding: 24px 0 0;
        border-top: 1px solid $ui-01;
        display: flex;
        flex-wrap: wrap;
    }
    &__mainTitle {
        @include cta;
        color: $brand-04;
        margin: 0 0;
    }
    &__list {
        list-style: none;
        width: calc(50% - 16px);
        padding: 0 16px 0 0;
        &:nth-child(2n)~.dln-dropdown__list {
            margin: 32px 0 0;
        }
        &--closing {
            margin: auto 0 0 0 !important;
            padding-top: 24px;
            border-top: 2px solid $ui-01;
        }
    }
    &__liHeading {
        @include mainMenu;
        @include fontBold;
        margin: 0 0 8px;
    }
    &__li {
        a {
            @include small;
            color: $brand-02;
            text-decoration: none;
            padding: 8px 0;
            display: inline-block;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
.bra-videoBanner {
  &.bra-videoBanner--double-video-aside.bra-videoBanner--centered {
    @media only screen and (min-width:map-get($grid-breakpoints, sm)) {
      width: calc(50% - 45px);
      margin: 0;
      height: 100%;
      display: inline-flex;

      &:first-child {
        margin: 0 30px 0 0;
      }
    }
  }
}

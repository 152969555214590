.dln-kenwood {
    .dln-recipe-plp-see-all {
        margin-top: 0;

        .dln-wrapper {
            max-width: unset;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > div,
            > h1 {
                width: 100%;
                max-width: 1240px;
                padding: 0 30px;
            }
            > h1,
            .dln-breadcrumbs {
                margin: 0;
                background: $ui-03;
                padding: 0 calc((100% - 1180px) / 2);
                max-width: none;

                @media only screen and (max-width: 1240px) {
                    padding: 0 30px;
                }
            }

            .dln-breadcrumbs {
                padding-top: 15px;
                padding-bottom: 70px;
            }

            > h1 {
                padding-bottom: 99px;
                text-align: center;
            }

            .dln-filters-bar {
                margin-top: 0;
                padding: 0;
                max-width: none;

                &__desktop {
                    margin: 0;
                    padding: 16px calc((100% - 1180px) / 2);
                    padding-bottom: 0;

                    max-width: none;

                    border-bottom: 1px solid $ui-03;

                    @media only screen and (max-width: 1240px) {
                        padding: 16px 30px 0 16px;
                    }
                }

                &__applied-filters,
                &__sort-section {
                    padding: 0 calc((100% - 1180px) / 2);
                    max-width: none;

                    @media only screen and (max-width: 1240px) {
                        padding: 0 30px;
                    }
                }
            }
        }

        .dln-recipe-page__title {
            box-sizing: border-box;
            @include fontExtra;
        }

        &__load-more {
            display: flex;
            flex-direction: column;

            p {
                text-align: center;
                b {
                    @include fontRoman;

                    font-weight: normal;
                }
            }

            .dln-btn {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}

@media only screen and (max-width: 1240px) {
    //padding: 0 30px;
    .dln-kenwood {
        .dln-recipe-plp-see-all {
            .dln-wrapper{

            }
        }
    }
}

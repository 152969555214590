@media only screen and (max-width:599px) {
    .dln-persDataRecap {
        width: calc(100% - 48px - 2px);
        &__wrap {
            flex-direction: row;
            max-height: none;
            overflow: auto;
            & .dln-inputs {
                width: 100%;
                flex-wrap: nowrap;
                max-width: none;
                margin: 0 0 16px 0 !important;
            }
            & .dln-inputs.dln-inputs--disabled {
                .dln-inputs__input,
                .dln-inputs__lbl {
                    width: auto;
                    max-width: none;
                    padding: 0;
                    max-width: 50%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .dln-inputs__lbl {
                    margin-right: 4px;
                }
            }
            .dln-select {
                width: 100%;
                max-width: none;
                padding: 0;
                margin: 0 0 16px 0 !important;
            }
        }
        &--editing {
            & .dln-persDataRecap__wrap {
                max-height: none;
            }
        }
    }
}
@media only screen and (max-width: 599px) {
    .dln-prodReg {
        & .dln-title {
            max-width: 100%;
            font-size: 35px;
            margin: 0;
        }
        & .dln-prodReg__subTitle {
            margin: 0 0 10px;
        }
        .dln-prodReg__confirmation:last-child {
            margin: 19px 0 0 0;
        }
        &--confirmation {
            padding: 0;
            .dln-prodReg {
                &__content {
                    margin: 0;

                    .dln-prodReg__contentRow,
                    .dln-prodReg__notice,
                    .dln-prodReg__marketingUpdate {
                        align-items: center;
                        line-height: 25px;
                        width: auto;
                        max-width: unset;
                        margin: 0 -10px;
                    }
                    .dln-prodReg__contentRow {
                        padding: 0 0 50px;
                        margin-right: 10px;
                        margin-left: 10px;
                        border-bottom: none;
                        div {
                            word-break: break-word;
                            width: 70%;
                        }
                    }
                    .dln-prodReg__lastRow {
                        flex-direction: column-reverse;
                        overflow: hidden;
                    }
                    > .dln-prodReg__subTitle {
                        width: auto;
                        max-width: 100%;
                        margin: 0;
                    }
                    .dln-orderRev__itemPic {
                        max-width: 30% !important;
                        margin: 0 10px 0 0 !important;
                    }

                    .dln-prodReg__notice {
                        .dln-noticeBox {
                            justify-content: center;
                            width: 100%;
                            padding: 16px;
                            // height: 120px;
                            margin: 0 0 20px 0;

                            &__icon {
                                margin-right: 15px;
                            }

                            &:nth-child(2n + 2) {
                                margin: 0 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

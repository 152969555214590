
@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
    .dln-order--detail {
        & .dln-order__detailProducts--returned {
            & .dln-orderHistory {
                & .dln-orderRev__content {
                    & .dln-orderRev__actions {
                        & .dln-btn--stroked {
                            order: 1;

                            &[title*="Print return label"] {
                                margin-bottom: 18px;
                            }
                        }
                        & .dln-orderRev__howItWorks {
                            order: 2;
                        }
                    }
                }
            }
            & .dln-order__trackingCode {
                margin-left: 0;
                position: static;
            }
        }
    }
}

.consent_blackbar {
  &_braun, &_delonghi, &_kenwood {
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    position: fixed;
    top: 0;
    display: block;
    height: 100%;
    z-index: 999999;

    &:empty {
      display: none;
    }

    #truste-consent-track {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 100%;
    }

    p {
      font-size: 14px;
      margin-bottom: 1.4666666667rem;
    }
  }

  &_delonghi {
    #truste-consent-buttons {
      .truste-button {
        &1 {
          padding: 13.5px 80px;
        }

        &2 {
          padding: 13.5px 20px;
        }
      }
    }
  }
}

.dln-kenwood {
    .dln-filters-bar {
        // min-height: 50px;
        // height: auto;

        // &__filters-wrap,
        // &__desktop {
        //     min-height: 49px;
        //     height: auto;
        // }
        &__desktop {
            .dln-filterDropdown.dln-filterDropdown--search {
                padding-right: 15px;
            }

            .dln-filterDropdown {
                margin-left: 0;
                padding-left: 15px;
                padding-right: 15px;
                border-left: 2px solid $ui-03;
                margin-bottom: 16px;

                &__item {
                    padding-bottom: 10px;

                    &:last-of-type {
                        opacity: 0.5;
                    }
                }



                &__label {
                    font-size: 13px;
                }

                &__dropdown{
                    .dln-inputs__input{
                        padding-right: 30px;
                    }

                }

                &__placeholder{
                   .dln-icn--checkOutline{
                       color: $support-02;
                   }
                }

                &__actions {
                    align-items: flex-end;

                    .dln-btn--apply{
                        opacity: 0.5;
                    }
                    .dln-btn--reset {
                        height: auto;
                        padding-left: 0;
                        text-decoration: underline;
                        font-size: 14px;
                        text-transform: capitalize;

                        &:hover{
                            background: $brand-03 !important;
                            color: $brand-04 !important;
                        }
                    }
                }
            }
        }

        &__applied-filters {
            .dln-btn {
                font-size: 12px;
                text-decoration: underline;
            }
        }

        &__sort-section {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            margin-top: 10px;
        }
    }
}

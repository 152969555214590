.dln-delonghi {
  & .dln-prodReg--confirmation {
    align-content: flex-start;
    min-height: calc(100vh - 165px);
    padding-top: 0;

    & .dln-confirmation-page__title-container {
      background-color: $brand-03;
      padding-bottom: 0;
      margin-bottom: 68px;

      & .dln-wrapper {
        & .dln-confirmation-page__breadCrumbs-wrapper {
          padding-top: 0;
          margin-bottom: 20px;

          & .dln-breadcrumbs {
            padding-top: 10px;
            margin-bottom: 0 !important;
          }
        }
      }
    }
    & .dln-confirmation-page__wrapper-content {
      & .dln-wrapper {
        & .dln-chips {
          border-bottom: 1px solid $ui-01;
          padding-bottom: 24px;
          margin-bottom: 40px;

          &__wrapper {
            & .dln-icn {
              margin-right: 24px;
            }
            & .dln-chips__content {
              line-height: 24px;
              font-size: REM(18px);
            }
          }
        }
        & .dln-confirmation-page__button-container {
          & .dln-btn {
            width: 100%;
            max-width: 282px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg--confirmation {
      padding-top: 64px;
      & .dln-confirmation-page__title-container {
        margin-bottom: 40px;

        & .dln-wrapper {
          & .dln-confirmation-page__breadCrumbs-wrapper {
            margin-bottom: 24px;

            & .dln-breadcrumbs {
              padding-bottom: 10px;
            }
          }
        }
      }
      & .dln-confirmation-page__wrapper-content {
        & .dln-wrapper {
          & .dln-chips {
            padding-bottom: 40px;

            &__wrapper {
              & .dln-icn {
                width: 42px;
                height: 42px;
                line-height: 42px;
                font-size: REM(42px);
              }
              & .dln-chips__content {
                width: calc(100% - 42px - 24px);
                font-size: REM(16px);
              }
            }
          }
          & .dln-confirmation-page__button-container {
            & .dln-btn {
              max-width: 184px;
              padding: 0 16px;
            }
          }
        }
      }
    }
  }
}

.dln-delonghi {
    .dln-contactUs--guest {
        .dln-contactUs__text--brand-02 {
            color: $ui-02;
            strong {
                @include fontRoman;
            }
        }
        & .dln-contactUs__privacyWrap {
            & .dln-inputsFile {
                margin-bottom: 20px;
            }
        }
    }
}
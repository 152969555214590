.dln-recipe-grid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // margin: 0 -10px;

    & .dln-recipe {
        flex-direction: column;
        width: calc(25% - 15px);
        margin: 0 20px 40px 0;

        &:nth-of-type(4n+4) {
            margin: 0 0 40px 0;
        }

        &__details{
            margin-top: auto;
        }
    }
}

.dln-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    list-style: inside none;
    margin: 0 0 72px 0;

    & + .dln-btn {
        width: 184px;
    }

    &Item {
        display: flex;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: auto;
        margin: 0 0 16px 0;

        .dln-icn {
            // @include fontBold;
            display: block;
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-weight: bold;
            color: $support-02;
            text-align: center;
            margin: 0 15px 0 0 !important;
        }
    }
    &Link {
        display: inline-block;
        width: auto;
        max-width: calc(100% - 20px - 16px);
        line-height: 16px;
        font-size: 14px;
        color: $brand-04;
        text-decoration: underline;
        padding: 0 0 0 0;
    }

    @media only screen and (max-width:599px) {
        margin-top: 8px;
        margin-bottom: 32px;

        &Item {
            align-items: flex-start;
        }
        &Link {
            font-size: 14px;
            line-height: 20px;
        }

        & + .dln-btn {
            margin: 0 0 0 36px;
        }
    }
}

.dln-kenwood {
    .dln-list {
        margin: 0 0 30px 0;

        &Item {
            height: auto;
            margin: 0 0 19px 0;

            .dln-icn {
                width: 24px;
                height: 24px;
                @include definePseudoElems;
                // text-indent: 2000px;
                font-size: 0;
                overflow: hidden;

                &::before {
                    @include fontIcon;
                    content: 'π';
                    text-indent: 0;
                    color: $brand-01;
                    font-size: 24px;
                    line-height: 1;
                }

            }
        }
        &Link {
            @include fontMedium;
            line-height: 20px;
            max-width: calc(100% - 20px - 24px);
        }

        @media only screen and (max-width:599px) {
            & + .dln-btn {
                margin: 0;
            }
        }
    }
}

@import "./_list-delonghi.scss";
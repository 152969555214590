.dln-quickLink {
    &:link,
    &:visited,
    &:hover,
    &:active {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        width: calc(100% - 16px);
        width: 100%;
        min-height: 180px;
        border-bottom: 1px solid $ui-02;
        padding: 16px 8px 16px 8px;
        text-decoration: none;
    }    
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        height: auto;
        margin: 0 0 8px 0;
    }
    &__iconWrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 90px;
        border: 2px solid $support-02;
        border-radius: 50%;
        margin: 0 auto 16px auto;
        .dln-icn {
            font-size: 48px;
            color: $support-02;
            margin: 0 auto;
        }
    }
    &__title {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        color: $brand-02;
    }
    &__link {
        display: none;
        width: auto;
        line-height: 20px;
        @include fontBold;
        color: $brand-04;
        text-decoration: underline;
    }
    // &:hover {
    //     & .dln-quickLink__title,
    //     & .dln-quickLink__link {
    //         color: $support-02;        
    //     }
    // }
}
.dln-orderInfoCard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid $ui-01;
  padding: 24px 0;

  &__number {
    @include fontBold;
    display: block;
    width: 100%;
    line-height: 26px;
    font-size: 22px;
    color: $brand-01;
    margin: 0 0 15px 0;
  }
  &__table {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  &__cell {
    display: flex;
    flex-wrap: wrap;
    width: calc(22.5% - 40px);
    height: auto;
    padding: 0 15px 0 0;
    box-sizing: content-box;

    &.dln-subscriptionInfoCard__cell {
      align-content: flex-start;

      .dln-subscriptionInfoCard__entryList {
        @include fontBold;

        ul {
          list-style: none;
        }

        p,
        li {
          @include fontSemibold;
          font-weight: 600;
        }
      }
    }

    &:nth-of-type(2) {
      width: calc(30% - 40px);
    }
    &:nth-of-type(4) {
      width: calc(25% - 40px);
    }
    &:last-of-type {
      align-items: flex-end;
      justify-content: flex-end;
      // width: calc(15% - 12px);
      width: 100px;
      padding: 0;
    }
    &Title,
    &Data {
      @include fontBold;
      display: block;
      width: 100%;
      line-height: 20px;
      font-size: 13px;
    }
    &Title {
      @include fontRoman;
    }

    // Override nth-of-type(x) instructions
    .dln-subscriptionInfoCard__date-cell {
      width: calc(22.5% - 40px);
    }
    .dln-subscriptionInfoCard__status-cell {
      width: calc(22.5% - 40px);
    }
  }
  &__viewDetail {
    display: inline-block;
    width: auto;
    line-height: 21px;
    font-size: 14px;
    color: $brand-01;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 599px) {
  .dln-orderInfoCard__table
    .dln-orderInfoCard__cell.dln-subscriptionInfoCard__cell.dln-subscriptionInfoCard__entryListContainer {
    flex-direction: column;
  }
}

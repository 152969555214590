.dln-alert {
    &--addedItem {
        padding: 0 0;
        .dln-popup__head {
            padding: 16px 40px 0;
        }
        .dln-alert__content {
            padding: 24px 40px 32px;
            &--extra {
                background-color: $ui-03;
                padding: 24px 40px 40px;
                display: flex;
                flex-wrap: wrap;
                .dln-btn {
                    margin: 16px auto;
                    .dln-icn {
                        height: auto;
                    }
                }
                .dln-alert__subTitle {
                    @include mainMenu;
                    color: $brand-02;
                    @include fontBold;
                    width: 100%;
                    padding: 0 0 16px;
                    border-bottom: 1px solid $ui-01;
                    margin: 0 0 24px;
                }
            }
        }
        &.dln-alert--suggestion {
            .dln-cartItem__content {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
            .dln-cartItem__picture {
                margin: 0 0 0 auto;
            }
            .dln-cartItem__title {
                margin: 0 auto 8px 24px;
            }
        }
    }

    .dln-addItem {
        width: calc(50% - 8px);
        margin: 0 16px 16px 0;
        &:nth-child(odd) {
            margin: 0 0 16px;
        }
    }

    .dln-cartItem {
        border-width: 0;
        padding: 0 0 0;
        &__picture {
            width: 120px;
        }
        &__title {
            width: calc(100% - 120px - 24px - 80px - 16px);
        }
        &__price {
            width: 80px;
            margin: 0 0 0 16px;
            text-align: right;
            .dln-price {
                margin: 0 0 8px;
                display: inline-block;
                font-size: 13px;
            }
        }
        &__btn {
            padding: 16px 0 0;
            margin: 24px 0 0;
            display: flex;
            flex-wrap: wrap;
            border-top: 1px solid $ui-01;
            width: 100%;

            .dln-btn {

                width: calc(50% - 10px);
                margin: 16px 20px 0 0;
                &:last-of-type {
                    margin: 16px 0 0;
                }
            }
        }
        .dln-csmDropdown {
            float: left;
            width: calc(100% - 120px - 24px);
            margin: 8px 0 0 24px;
        }
    }

    &__title {
        display: flex;
    }

    &__icon {
        flex-grow: 1;
        flex-shrink: 0;
    }

    &__text {
        flex-shrink: 1;
    }
}
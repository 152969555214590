.dln-cart-order-item {
  &__vat-included {
    @include fontRoman;
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--ui-06);
    display: block;
    width: 100%;
    margin-top: 5px;
  }
}
.dln-addItem {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  &__picture {
    position: relative;
    width: 80px;
    height: 63px;
    z-index: 1;
    margin-right: 10px;

    @media only screen and (min-width: 599px) {
      height: auto;
      margin: 0 12px 0 0;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.04);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    & > img {
      width: 100%;
      max-width: none;
      height: 100%;
      object-fit: contain;

      @media only screen and (min-width: 599px) {
        max-width: 100%;
        height: auto;
        object-fit: initial;
      }
    }
  }

  &__content {
    width: calc(100% - 80px - 12px);
    align-self: flex-start;
  }

  &__title {
    @include small;
    @include fontBold;
    color: $brand-04;
  }

  &__price {
    display: flex;
    width: 100%;

    .dln-price {
      margin: 0 8px 0 0;
      font-size: REM(16px);

      @media only screen and (min-width: 599px) {
        font-size: REM(15px);
      }

      &--discounted {
        color: $brand-04;
      }

      &--oldPrice {
        color: $ui-02;

        &::after {
          background-color: $ui-02;
        }
      }
    }
  }

  &__quantity {
    @include small;
    color: $brand-04;
  }

  .dln-btn {
    margin: 16px 0 0;
  }

  &--basic {
    align-items: flex-start;
    border-bottom: 1px solid $ui-01;
    padding: 32px 0 32px;

    .dln-addItem__picture {
      width: 120px;
      margin: 0 16px 0 0;
    }

    .dln-addItem__content {
      width: calc(100% - 136px);
    }

    .dln-addItem__title {
      font-size: 15px;
    }

    .dln-addItem__price {
      margin: 16px 0;
    }
  }
}

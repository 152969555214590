.dlg-gigya-input-select-wrapper {
  position: relative;

  .gigya-label {
    @include gigya-label;
    top: -6px;
    background-color: var(--brand-03);
    z-index: 2;
    transform: scale(0.75);
  }
}

.dln-delonghi {
  & .dln-list {
    &Item {
      margin-bottom: 14px;
      align-items: flex-start;

      & .dln-icn--check-circle {
        color: $brand-04;
        font-weight: bold;
        font-size: REM(20px);
      }
      & .dln-listLink {
        @include fontMedium;
        line-height: 20px;
      }
    }
  }
}

.dln-shareWishList {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: auto;
    // padding: 70px 20px 70px 20px;
    padding: 0 20px 52px 20px;

    &__title {
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 26px;
        @include fontBold;
        text-align: center;
        padding: 4px 0 0 0;
        margin: 0 0 32px 0;
    }
    & .dln-inputs {
        margin: 0 16px 0 0;
        width: calc(70% - 16px);
    }
    & .dln-btn {
        width: 30%;
        margin: 0;
    }
    &__inputs {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
}

.dln-copyClipboard__message {
    width: 100%;
    @include small;
    color: $brand-02;
    padding: 16px 4px;
    text-align: center;
    opacity: 0;
    animation: smoothFadeInAndScale .5s linear forwards;
}
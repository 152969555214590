.dln-kenwood {
  #js-dln-popup__wrapper.dln-popup__wrapper {
    .dln-addedItem {
      padding: 20px;
      margin-top: 0;

      @media only screen and (min-width: 1199px) {
        padding: 20px 40px;
      }

      .dln-popup {
        &__head {
          padding: 0;
          margin-top: 0;

          &Title {
            @include fontSemibold;
            height: auto;
            width: auto;
            font-size: REM(15px);
            position: relative;

            @media only screen and (min-width: 1199px) {
              height: 35px;
              display: flex;
              text-align: center;
            }

            .dln-icn {
              top: 0;
              color: var(--support-02);
              background-color: var(--brand-03);
              width: 35px;
              height: 35px;
              line-height: 35px;
              font-size: REM(16px);
              margin: 0;
            }
          }

          .dln-btnIcon--close {
            position: relative;
            top: 0;
            right: 0;
          }
        }
      }

      &__content {
        padding: 20px;
        margin: 0 -20px;
        width: calc(100% + 40px);
        border-bottom: 1px solid $ui-01;

        @media only screen and (min-width: 1199px) {
          padding: 20px 40px;
          margin: 0 -40px;
          width: calc(100% + 80px);
        }

        .dln-cartItem {
          width: 100%;

          &__content {
            display: block;
            width: 100%;
            padding: 0;

            @media only screen and (min-width: 599px) {
              max-width: 560px;
              margin: 0 auto;
            }

            @media only screen and (min-width: 900px) {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
            }

            @media only screen and (min-width: 1199px) {
              max-width: none;
            }
          }

          &__figure {
            @media only screen and (min-width: 900px) {
              max-width: 70%;
            }
          }

          &__picture {
            display: flex;
            order: 1;
            top: 0;
            align-items: stretch;
            position: relative;
            max-height: none;
            margin: 0;

            > img {
              object-fit: contain;
            }
          }

          &__img {
            width: 216px;
            height: 176px;
            @media only screen and (min-width: 599px) and (max-width: 1199px) {
              width: 100%;
            }
          }

          &__title {
            @include fontSemibold;
            display: flex;
            align-self: flex-start;
            order: 2;
            line-height: 22px;
            font-size: REM(16px);
            margin-bottom: 20px;
            padding: 0 0 0 15px;
            width: auto;
          }

          &__priceBox {
            position: relative;
            display: flex;
            margin: 0;
            padding-left: 15px;
            width: auto;

            @media only screen and (min-width: 900px) {
              justify-content: flex-end;
              max-width: 30%;
              padding: 0;
            }

            .dln-cartItem__price {
              margin: 0;

              .prices {
                display: flex;
                flex-direction: row;

                .dlg-omnibus-price {
                  @media only screen and (min-width: 900px) {
                    flex-direction: column;
                    align-items: flex-end;
                  }

                  &__old-price {
                    flex-direction: row;
                  }
                }

                br {
                  display: none;
                }

                @media only screen and (min-width: 900px) {
                  flex-direction: column;
                }
              }
            }

            .dln-price--oldPrice {
              margin-right: 10px;
              text-decoration: line-through;

              &::after {
                display: none;
              }

              @media only screen and (min-width: 900px) {
                margin-right: 0;
              }
            }
          }

          &__free-gift {
            display: flex;
            align-items: center;
            font-family: var(--fontNameBold);
            font-size: REM(12px);
            line-height: 12px;
            padding: 5px;
            padding-top: 7px;
            background-color: var(--brand-01);
            color: var(--brand-03);
            text-align: center;
            border-radius: 2px;
          }

          &__price {
            width: auto;

            @media only screen and (min-width: 599px) {
              width: auto;
            }
          }

          &__quantity,
          .dln-csmDropdown {
            width: 100%;
            float: none;

            @media only screen and (min-width: 1199px) {
              width: calc(100% - 200px);
            }

            &__selected {
              line-height: 20px;
            }

            + .clearer {
              display: none;
            }
          }

          &__quantity {
            justify-content: center;
          }

          &__warningMessage {
            text-align: center;
          }

          &__btn {
            width: 100%;
            padding-top: 20px;
            margin: 0;
            border-top: none;

            @media only screen and (min-width: 599px) {
              max-width: 480px;
              margin: 0 auto;
            }

            @media only screen and (min-width: 1199px) {
              max-width: none;
            }

            .dln-notification {
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              padding: 10px 0;
              border-top: solid 1px $ui-01;

              @media only screen and (min-width: 1199px) {
                padding: 15px 0 20px;
              }

              .dln-btnIcon__checkoutCounter {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                width: 18px;
                height: 18px;
                color: $brand-03;
                border-radius: 50%;
                background-color: $brand-01;

                &Text {
                  @include fontBold;
                  font-size: REM(13px);
                  line-height: 13px;
                  display: inline-block;
                  text-align: center;
                }
              }

              &__desc,
              .dln-popup-entry {
                @include fontRoman;
                color: $brand-04;
                font-size: REM(13px);
                display: inline-block;
              }
            }

            .dln-btn {
              width: 100%;
              margin: 10px 0 0;

              @media only screen and (min-width: 599px) {
                width: calc(50% - 10px);
                margin-top: 0;
              }

              &--stroked {
                margin: 0 0 10px 0;

                @media only screen and (min-width: 599px) {
                  margin: 0 20px 20px 0;
                }
              }
            }
          }
        }
      }

      &__content--extra {
        padding: 20px;
        margin: 0 -20px;
        width: calc(100% + 40px);
        background-color: $brand-03;
        border-bottom: none;

        @media only screen and (min-width: 599px) {
          margin: 0 auto;
          max-width: 480px;
          padding: 20px 0;
        }

        @media only screen and (min-width: 1199px) {
          max-width: none;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .dln-addedItem__subTitle {
          @include fontRoman;
          border-bottom: solid 1px $ui-01;
          font-size: REM(13px);
          color: $brand-04;
          margin-bottom: 20px;
        }

        .dln-addItem {
          width: 100%;
          align-items: flex-start;
          max-width: none;
          justify-content: space-between;
          margin: 0 0 15px 0;

          @media only screen and (min-width: 599px) {
            margin-bottom: 25px;
          }

          @media only screen and (min-width: 1199px) {
            width: calc(50% - 10px);
          }

          &__content {
            width: calc(100% - 120px);
          }

          &__picture {
            width: 98px;
            height: 80px;
          }

          &__title,
          &__price {
            width: 100%;
          }

          &__title {
            display: block;
            font-size: REM(14px);
            margin-bottom: 8px;
          }

          .dln-btn {
            align-self: flex-end;
            justify-content: center;
            margin: 10px 0 0;
            width: 100%;
            border: solid 1px $brand-01;
            color: $brand-01;

            .dln-icn {
              width: 13px;
              height: 13px;
              font-size: REM(13px);
              color: $brand-01;
            }

            .dln-btn__lbl {
              @include fontBold;
            }

            &:hover {
              background-color: $brand-01;

              .dln-icn {
                color: $brand-03;
              }

              .dln-btn__lbl {
                color: $brand-03;
              }
            }
          }

          + .dln-btn {
            margin: 20px auto 0 auto;
          }
        }
      }
    }
  }

  /* Kenwood popup override */

  .dln-popup {
    padding: 20px !important;

    &__wrapper > div:first-child.dln-addedItem .dln-popup__head {
      margin: 0;
      width: 100%;

      &Line {
        display: block;
      }
    }

    &__content {
      margin: auto !important;

      @media only screen and (min-width: 1199px) {
        //max-width: 997px !important;
      }
    }
  }
}

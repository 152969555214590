.dln-orderDetailInfo {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    padding: 30px 0;

    &__col {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 25%;
        height: auto;
    }
    &__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 26px;
        font-size: 22px;
        color: $brand-01;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;

        &--shippingMethod,
        &--shippingAddress,
        &--billingAddress,
        &--paymentMethod {
            padding: 8px 0 0 0;
        }
        &--shippingAddress,
        &--billingAddress {
            & .dln-orderDetailInfo__data:nth-child(2) {
                @include fontBold;
            }
        }
    }
    &__subtitle {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 18px;
        font-size: 14px;
        margin: 0 0 11px 0;
    }
    &__data {
        display: block;
        width: 100%;
        line-height: 18px;
        font-size: 13px;
    }
}

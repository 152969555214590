.dln-serviceLocator {
    padding: 24px 0 70px 0;
    & > .dln-wrapper {
        width: auto;
        max-width: 1080px;
    }
    .dln-addressResult {
        padding: 8px;
        background: none;
        border: 3px solid transparent;

        &:focus {
            border: 3px solid $support-02;
            outline: 3px solid $support-02;
        }
    }
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
        // min-height: calc(100vh - 90px - 448px);
        //min-height: calc(100vh - 538px);
    }
    &__title {
        display: block;
        width: 100%;
        line-height: 56px;
        font-size: 44px;
        @include fontBold;
        margin: 0 0 32px 0;
    }
    &__description {
        margin: 0 0 32px 0;
    }
    &__mobileBnt {
        display: none;
        width: auto;
        max-width: calc(100% - 100px - 16px);
        margin: 0 0 0 16px;
        align-items: center;
        >ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            height: 100%;
        }
        // .dln-tab__links {
        //     width: 60px;
        //     min-width: auto;
        //     padding: 0;
        //     height: 100%;
        //     display: inline-flex;
        //     align-items: center;
        //     margin: 0;
        //     border-left: 4px solid $brand-03;
        //     box-sizing: border-box;
        //     .dln-icn {
        //         margin: 0 auto;
        //         font-size: 16px;
        //         color: $brand-02;
        //     }
        //     &--active {
        //         .dln-icn {
        //             color: $brand-04;
        //         }
        //     }
        //     .dln-btnIcon__checkoutCounter {
        //         display: none;
        //         background-color: $brand-01;
        //         color: $brand-03;
        //         width: 13px;
        //         height: 13px;
        //         top: 21px;
        //         left: 11px;
        //     }
        // }
        .dln-btnIcon--list {
            position: relative;
        }
    }
    &__listCounter {
        position: absolute;
    }
    &__results {
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 386px;
    }
    &__aside {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 386px;

        .dln-inputs--searchBar {
            width: calc(100% - 128px);
            margin: 0 0 16px 0;
        }
        .dln-btnTextIcon--nearMe {
            width: 112px;
            margin: 0 0 16px 16px;
        }
        .dln-btn--reset {
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
            display: flex;
            align-items: center;
            margin: 0 8px 0 auto;
            text-decoration: none;
        }
        .dln-accordion {
            margin: 0 0 10px 0;
            max-height: 50px;
            position: relative;
            &--filters-active {
                .dln-accordion__head {
                    .dln-accordion__title span {
                        display: inline-flex;
                    }
                    .dln-btn--reset {
                        display: inline-flex;
                    }
                }
            }
            .dln-checkbox {
                .dln-checkbox__input:focus+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
                    outline: 3px solid $support-02;
                }
                .dln-checkbox__input:checked+.dln-checkbox__inputPlaceholder+.dln-checkbox__label {
                    color: $brand-04;
                    @include fontRoman;
                }
            }
            &__head {
                background: none;
                border: none;
                .dln-btnIcon--map {
                    width: 57px;
                    border-right: 4px solid $brand-03;
                    border-left: 4px solid $brand-03;
                    margin: 0 0 0 auto;

                    .dln-btnIcon__wrap {
                        width: 57px;
                    }
                    .dln-btnIcon__icon {
                        opacity: 0.32;
                        transition: opacity 0.12s linear;
                    }
                    &--selected {
                        .dln-btnIcon__icon {
                            opacity: 1;
                        }
                    }
                }
                .dln-accordion__title {
                    span {
                        display: none;
                        @include fontBold;
                    }
                }
                .dln-btnIcon--list {
                    width: 57px;
                    position: relative;

                    .dln-btnIcon__wrap {
                        width: 57px;
                    }
                    .dln-btnIcon__icon {
                        opacity: 0.32;
                        transition: opacity 0.12s linear;
                    }
                    &--selected {
                        .dln-btnIcon__icon {
                            opacity: 1;
                        }
                    }
                }
                .dln-serviceLocator__listCounter {
                    display: block;
                    width: 14px;
                    height: 14px;
                    line-height: 2;
                    font-size: 9px;
                    @include fontBold;
                    color: $brand-03;
                    text-align: center;
                    border-radius: 50%;
                    background-color: $brand-01;
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 14px);
                }
                &:focus .dln-icn--u {
                    outline: 3px solid $support-02;
                }
            }
            &__content {
                width: 100%;
                max-height: 429px;
                padding: 0;
                display: none;
                overflow-y: auto;
            }
            &--open {
                .dln-accordion__content {
                    display: flex;
                }
            }
            &__wrap {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                height: auto;
                border-bottom: 1px solid $ui-01;
                // background: rgba(0,0,0,.1);
                padding: 16px 12px;
                box-sizing: border-box;

                &:last-of-type {
                    border-bottom: none;
                }
                &Title {
                    display: block;
                    width: 100%;
                    line-height: 20px;
                    font-size: 12px;
                    @include fontBold;
                    margin: 0 0 4px 0;
                }
            }
            .dln-checkbox {
                margin: 0 0 8px 0;

            }
            .dln-radioBtn {
                width: 100%;
                margin: 0 0 8px 0;
                display: block;
                z-index: 5;
                .dln-radioBtn__lbl {
                    color: $brand-02;
                    &::before {
                        border: 1px solid $ui-02;
                        width: 14px;
                        height: 14px;
                        background-color: $brand-03;
                    }
                }
                .dln-radioBtn__input {
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    position: absolute;
                    opacity: 0;
                    z-index: 1;
                    display: block;
                }
                .dln-radioBtn__input:focus + .dln-radioBtn__lbl {
                    outline: 3px solid $support-02;
                }
                .dln-radioBtn__input:checked + .dln-radioBtn__lbl {
                    color: $brand-01;
                }
            }
        }
    }
    &__map {
        // display: flex;
        // flex-wrap: wrap;
        // height: 536px;
        width: calc(100% - 386px - 16px);
        min-height: 536px;
        background: $ui-03;
        // background-image: url('#{$pathAssets}/images/bg/bg-service-locator-map.jpg');
        // background-repeat: no-repeat;
        // background-size: cover;
        margin: 0 0 0 16px;

        .gm-style .gm-style-iw-c {
            border-radius: 2px !important;
            padding: 0 !important;
        }
    }
    &__resultCounter {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        color: $brand-02;
        margin: 12px 0;
        box-sizing: border-box;
    }
    &--results {
        .dln-serviceLocator__aside {
            .dln-accordion {
                max-height: 50px;
            }
        }
    }

    .dln-quickLinks--slider {
        margin-top: 83px;
    }

    .dln-mapPopup {
        a {
            color: $brand-02;
            text-decoration: underline;
        }
    }
}

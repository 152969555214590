.dln-kenwood {
    .dln-savedRec {
        &__subtitle {
            text-align: center;
            margin-bottom: 32px;
        }
        &__recipeWrap {
            border-bottom: 0;
            &+.dln-btn {
                margin: 0 auto;
            }
        }
        @media only screen and (max-width:599px) {
            &__subtitle {
                font-size: 14px;
            }
            &__recipeWrap {
                &+.dln-btn {
                    margin: 32px auto 0;
                }
            }
        }
    }
}
.dln-delonghi {
    .dln-regProductList {
        &--editing {

            .dln-regProductList {
                &__section {
                    margin-bottom: 40px;
                }
                &__sectionFoot {
                    margin-top: 24px;
                    padding-top: 24px;
                }
            }
        }
    }
}
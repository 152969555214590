.dln-checkout {
    &--express {
        & .dln-accordion--shipping-information {
            & .dln-chips {
                max-width: none;

                &__content {
                    & span {
                        color: $ui-06;
                    }
                }
            }
        }
    }
}
.dln-delonghi {
  .dln-page--imageHeader__header {
    background-color: $brand-05;

    .dln-mainTitle {
      @include fontMedium;
      font-size: REM(50px);
      font-weight: 500;
      line-height: 1.04;
      width: 548px;
    }
  }

  .dln-unsubscribePage {
    .dln-page--imageHeader__picture {
      width: 197px;
    }

    .dln-page__title {
      @include fontMedium;
      font-size: REM(21px);
      line-height: 1.12;
    }

    .dln-page__paragraph {
      @include fontMedium;
      font-size: REM(18px);
      line-height: 1.07;
    }

    .dln-page__text {
      @include fontRoman;
      font-size: REM(18px);
      line-height: 1.33;
    }

    .dln-wrapper {
      &--content {
        max-width: 1300px;
        margin: 0 auto;
      }

      &--fullWidth {
        .dln-wrapper {
          max-width: 1300px;
        }
      }
    }
  }

  @media only screen and (max-width: 599px) {
    .dln-page--imageHeader__header {
      .dln-mainTitle {
        max-width: 334px;
        text-align: left;
      }
    }

    .dln-unsubscribePage {
      .dln-page__title {
        font-size: REM(18px);
        line-height: 1.11;
      }

      .dln-page__paragraph {
        font-size: REM(16px);
        line-height: 1.13;
      }

      .dln-page__text {
        font-size: REM(16px);
        line-height: 1.5;
      }

      .dln-wrapper {
        display: flex;
        align-items: stretch;
        flex-direction: column-reverse;
        padding: 0;

        &.dln-wrapper--fullWidth {
          padding: 20px 21px 58px 20px;

          .dln-wrapper .dln-mainTitle {
            width: 100%;
            max-width: 100%;
          }
        }

        &.dln-wrapper--main {
          padding: 0;
          margin: 0;

          .dln-page--imageHeader__picture {
            align-self: center;
            padding: 0;
          }

          .dln-mainTitle {
            margin-bottom: 0;
          }
        }

        &.dln-wrapper--content {
          flex-direction: column;
          padding: 40px 20px 70px 20px;
          margin: 0;
          width: 100%;

          .dln-page__title {
            font-size: REM(18px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.11;
          }

          .dln-page__actions {
            margin-top: 30px !important;

            .dln-btn {
              font-size: REM(18px);
              line-height: 1.33;
            }

            .dln-btn--stroked,
            .dln-btn--delonghi {
              width: 283px;
            }
          }
        }
      }
    }
  }

  .dln-active-subscriptions {
    .dln-gigya-checkbox-wrapper {
      margin: 10px 0;

      .dln-gigya-label {
        position: relative;
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        color: $brand-02;

        &:before {
          content: "";
          position: relative;
          height: 14px;
          width: 14px;
          border: 1px solid $ui-02;
          background-color: $brand-03;
          display: inline-block;
          border-radius: 2px;
        }

        &:after {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          top: 4px;
          left: 4px;
          border-radius: 1px;
          background-color: $support-02;
          transform: scale(0);
          transition: transform .08s ease;
        }

        span {
          display: inline-block;
          margin-left: 3px;
          position: relative;
          top: -2px;
        }
      }

      .dln-gigya-checkbox {
        display: none;

        &:checked+label:after {
          transform: scale(1);
        }
      }
    }
  }
}
.dln-registrationForm {
    &__paragraph {
        @include small;
        width: 100%;
        color: $brand-04;
        margin: 0 0 20px 0;
        &--info {
            color: $brand-02;
            margin: 0 0 32px 0;
        }
        &--noMarg {
            margin: 0;
        }
        &--secondary {
            @include extraExtraSmall;
            color: $ui-05;
            margin: 8px 0 24px;
        }
        a {
            color: $ui-05;

        }
    }
    &__subtitle {
        @include cta;
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 8px;
        @include fontBold;
        // text-transform: uppercase;
    }
    &__form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    &__formContainer {
        width: 100%;
        padding: 32px 0 0;
        margin: 24px 0 0;
        border-top: 1px solid $ui-01;
        .dln-select {
            width: calc(50% - 10px);
            margin: 0 50% 32px 0;
        }
        .dln-inputs {
            margin: 0 0 16px 0;
            &--halfCol {
                width: calc(50% - 10px);
                margin: 0 0 32px 0;
                &:nth-child(even) {
                    margin: 0 20px 32px 0;
                }
            }

            .dln-inputs__extraLbl {
                width: calc(95% - 12px);
                max-width: 300px;
                white-space: normal;
            }
        }
        .dln-checkbox {
            margin: 70px 0 32px;
        }
    }
    &__formFoot {
        width: 100%;
        margin: 60px 0 0;
        .dln-btn {
            width: 50%;
        }
        .dln-registrationForm__paragraph--secondary {
            margin: 32px 0;
        }
    }
    &__formOptions {
        display: flex;
        flex-wrap: wrap;
        .dln-registrationForm__subtitle,
        .dln-registrationForm__paragraph {
            font-size: 10px;
        }
        .dln-radioBtn {
            margin: 0 40px 32px 0;
        }
    }

}
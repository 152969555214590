.dln-recipe {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    transition: border-color 0.12s linear;
    cursor: pointer;

    &:hover {
        border-color: $ui-02;

        & .dln-recipe__title {
            border-color: $ui-01;
        }
    }
    &__img {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 0;
        background: $ui-03;
        padding: 0 0 100% 0;
        margin: 0 0 10px 0;
        position: relative;

        & .dln-btnIcon--delete {
            width: auto;
            margin: 5px 5px 0 auto;
        }
        .dln-btnIcon {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 10;

            .dln-icn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                font-size: 18px;
                color: $ui-07;
                border-radius: 50%;
                background-color: $ui-03;
            }
            &--bookmark-active {
                & .dln-icn {
                    color: $support-02;
                }
            }
        }
        picture {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            position: absolute;
            z-index: 0;
        }
    }
    &__title {
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        margin: 0 0 13px 0;
        overflow: hidden;

        & a {
            @include fontBold;
            display: block;
            width: 100%;
            line-height: 24px;
            font-size: 18px;
            color: $brand-01;
            text-decoration: none;
        }
    }

    &__details {
        @include fontRoman;
        justify-items: center;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        line-height: 16px;
        font-size: 14px;
        color: $brand-02;
        margin-top: auto;

        .dln-icn {
            height: 22px;
            line-height: normal;
            font-size: 22px;
            margin-right: 7px;
        }

        .dln-recipe__detail {
            display: flex;
            align-items: center;
            align-content: center;
            width: auto;
            line-height: 22px;
            margin-bottom: 8px;

            &--difficultyLevel {
                color: $ui-07;
                margin-right: 24px;
            }
            &--preparationTime {
                color: $ui-07;
            }
            & .dln-icn {
                color: $ui-07;
            }
        }

    }

    // &--v2 {
    //     .dln-recipe__img {
    //         .dln-btnIcon--bookmark-active {
    //             .dln-icn {
    //                 color: $support-02;
    //             }
    //         }
    //         .dln-btnIcon {
    //             .dln-icn {
    //                 font-size: 12px;
    //             }
    //         }
    //     }
    // }

    // &--big-card {
    //     .dln-btnIcon--bookmark-active {
    //         .dln-icn {
    //             color: $support-02;
    //         }
    //     }
    //     .dln-btnIcon {
    //         top: 10px;
    //         right: 10px;

    //         .dln-icn {
    //             width: 40px;
    //             height: 40px;
    //             font-size: 18px;
    //             border-radius: 50%;
    //             background-color: $brand-03;
    //         }
    //     }
    //     .dln-recipe__img {
    //         height: auto;
    //         padding-top: 100%;
    //         position: relative;

    //         & .dln-image {
    //             top: 0;
    //         }
    //     }

    //     .dln-recipe__title {
    //         line-height: 22px;
    //         font-size: 18px;
    //         font-weight: bold;
    //         padding-bottom: 7px;
    //         margin-bottom: 7px;
    //     }

    //     .dln-recipe__details {
    //         justify-content: flex-start;
    //         line-height: 24px;
    //         font-size: 15px;
    //         color: $ui-07;

    //         .dln-recipe__detail {
    //             margin: 0;
    //         }
    //         .dln-recipe__detail--difficultyLevel {
    //             flex: 5;
    //             margin-right: 10px;
    //         }
    //         .dln-recipe__detail--preparationTime {
    //             flex: 7;
    //         }

    //         .dln-icn {
    //             height: 20px;
    //             font-size: 20px;
    //             &:before {
    //                 width: 20px;
    //                 height: 20px;
    //             }
    //         }
    //     }
    // }

    // &--no-border {
    //     @include hover-supported() {
    //         &:hover {
    //             & .dln-image__image {
    //                 transform: scale3d(1.09,1.09,1.09) translate3d(0, 0, 0);
    //             }
    //             & .dln-recipe-box__title {
    //                 border-color: $ui-01;
    //             }
    //         }
    //     }
    //     border: none;
    //     padding: 0;

    //     & .dln-image__image {
    //         transition: transform .35s ease-in-out;
    //     }
    // }
}
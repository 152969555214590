.dln-kenwood {
    .dln-orderHistory {
        border-bottom: 2px solid $ui-02;
        //padding-bottom: 32px;

        &:last-of-type,
        &:only-of-type {
            border-bottom: none;
        }
        &__reference {
            margin-bottom: 32px;
            border-bottom: 1px solid $ui-01;
            padding-bottom: 4px;
        }
        &__code {
            font-size: 12px;
            line-height: 20px;
            padding: 0 6px !important;

            &:first-of-type {
                @include fontMedium;
                font-size: 14px;
                color: $brand-04;
                padding: 0 8px 0 0 !important;
            }
        }
        &__totalprice {
            @include fontRoman;
            font-size: 14px;
        }
        & .dln-orderRev {
            &:last-of-type,
            &:only-of-type {
                margin-bottom: 32px;
            }
            &__quantity {
                font-size: 14px;
            }
        }
        & .dln-orderRev__details {
            &Title {
                margin-bottom: 2px !important;
            }
            & .dln-orderRev__productCode {
                // display: none;
                font-size: 12px !important;
                line-height: 16px !important;
                color: $brand-02;
            }
            & .dln-price {
                @include fontRoman;
                font-size: 14px;

                &--oldPrice {
                    // color: $brand-02;

                    &::after {
                        // background-color: $brand-02;
                    }
                }
                &--discounted {
                    color: $brand-04;
                }
            }
        }
        &--openorder {
            .dln-orderHistory__reference {
                width: 100%;
                max-height: none;
                position: initial;
                top: initial;
                left: initial;
            }
            & .dln-orderRev__wrap {
                &--main {
                    margin-bottom: 32px;
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-orderHistory {
            &__reference {
                margin: 0 0 24px 0;
                position: relative;
            }
            &__codes {
                width: 100%;
                max-width: none;
                padding: 0 64px 0 0;
                margin: 0;
            }
            &__code {
                line-height: 16px;

                &:first-of-type {
                    width: 100%;
                    font-size: 14px;
                    padding: 0 !important;

                    &::after {
                        display: none;
                    }
                }
                &:nth-of-type(1n+2) {
                    font-size: 12px;
                }
                &:nth-of-type(2) {
                    padding: 0 8px 0 0 !important;
                    margin: 0 8px 0 0;
                }
                &:nth-of-type(3) {
                    padding: 0 !important;
                }
            }
            &__total {
                width: auto;
                position: absolute;
                top: 0;
                right: 0;
            }
            & .dln-orderRev {
                background: none;
                background-color: transparent !important;
                padding: 0;
                margin: 0 0 32px 0;
                align-items: center;
                &__detailsTitle {
                    margin: 0 0 8px 0;
                }
                &__actions {
                    & .dln-btn {
                        &:last-of-type {
                            margin: 0;
                        }
                    }
                }
            }
            &--openorder {
                & .dln-orderHistory__reference {
                    padding: 0 0 8px 0;

                    & .dln-orderHistory__codes {
                        width: auto;
                        margin: 0 auto 0 0;

                        & .dln-orderHistory__code {
                            line-height: 20px;
                        }
                    }
                }
                & .dln-orderRev__wrap {
                    &--main {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}

.dln-delonghi {
  .dln-prodReg {
    &__subTitle {
      @include fontMedium;
      font-size: REM(28px);
      line-height: 1.21;
    }

    &__paragraph {
      font-size: REM(16px);
      line-height: 1.5;
    }
    &__content {
      width: 100%;
      margin: 0 0 100px;
    }

    &__head {
      & .dln-title,
      .dln-prodReg__paragraph {
        text-align: center;
      }
      & .dln-title {
        @include fontSemibold;
      }
      &__landing {
        & .dln-title,
        .dln-prodReg__paragraph {
          text-align: left;
        }
      }
    }

    .dln-prodReg__subTitle {
      @include fontMedium;
      font-size: REM(28px);
      line-height: 1.21;
    }

    .dln-carousel.swiper-container {
      height: auto;
      .swiper-pagination {
        margin-right: auto;
        margin-left: auto;
      }
    }

    .dln-tooltip__text {
      font-size: REM(13px);
      line-height: 1.23;
      color: $brand-04;
    }

    @media only screen and (max-width: 599px) {
      &__subTitle {
        font-size: REM(21px);
        line-height: 1.1;
      }

      .dln-carousel.swiper-container {
        height: auto;
        .swiper-pagination {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-prodReg {
      & .dln-wrapper {
        & .dln-prodReg__subTitle {
          font-size: REM(21px);
          line-height: 1.1;
        }
        & .dln-carousel.swiper-container {
          height: auto;

          & .swiper-pagination {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }
  }
}

.dln-kenwood {
    & .dln-writeReview {
        @media only screen and (max-width:599px) {
            .dln-writeReview__subTitle,
            .dln-ratingStars {
                text-align: left;
                position: relative;
                bottom: auto;
                left: auto;
            }
            .dln-orderRev__detailsTitle {
                text-align: left;
                white-space: normal;
            }
            .dln-ratingStars {
                position: relative;
            }
            .dln-writeReview__item {
                height: auto;
            }
            .dln-orderRev {
                margin-bottom: 0;
                &__wrap--main {
                    margin: 0;
                }
            }
            .dln-writeReview__form {
                & .dln-inputs {
                    width: 100%;
                    margin-right: 0;
                }
                .dln-btn:not(.dln-btn--link) {
                    width: auto;
                    min-width: 120px;
                    max-width: calc(100% - 50px);
                }
            }
            .dln-orderRev__itemPic {
                max-width: 100%;
                height: auto;
                margin: 0 20px 20px 0;

                & > img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .dln-orderRev__content {
                width: calc(100% - 120px);
            }
        }
        padding-top: 60px;
        padding-bottom: 48px;

        & .dln-popup__head {
            & .dln-btnIcon--close {
                transform: none;
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }
        & .dln-popup__headTitle {
            @include fontExtra;
            line-height: 40px;
            font-size: 32px;
            font-weight: 400;
        }
        &__item {
            height: 150px;
            position: relative;
        }
        & .dln-orderRev {
            border-bottom: none;
            padding-bottom: 0;

            &__itemPic {
                max-width: 100%;
                width: auto;
                height: auto;

                & > img {
                    object-fit: none;
                }
            }
            &__content {
                align-content: initial;
                width: calc(100% - 200px);
            }
            &__details {
                width: 100%;
                max-width: none;
            }
            &__wrap--main {
                width: 100%;
            }
            &__detailsTitle {
                display: block;
                width: 100%;
            }
        }
        &__subTitle {
            @include fontRoman;
            line-height: 20px;
            font-size: 14px;
            margin-bottom: 4px;
            position: absolute;
            bottom: 24px;
            left: 200px;
        }
        & .dln-ratingStars {
            height: 24px;
            margin: 0;
            position: absolute;
            bottom: 0;
            left: 200px;

            &__star {
                width: 24px;
                height: 24px;
                margin-right: 5px;

                &:first-of-type {
                    margin-right: 0;
                }
                & .dln-icn--iconfont {
                    width: 24px;
                    height: 24px;

                    &::before {
                        line-height: 24px;
                        font-size: 24px;
                    }
                }
            }
        }
        &__content {
            & .dln-writeReview__subTitle {
                display: none;

                &:nth-of-type(3) {
                    display: block;
                    line-height: 20px;
                    font-size: 12px;
                    font-weight: lighter;
                    color: $ui-07;
                    margin: 0 0 4px 0;
                    position: initial;
                }
            }
        }
        &__form {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            & .dln-inputs {
                width: calc(50% - 10px);
                height: 50px;
                margin-bottom: 16px;
                box-sizing: border-box;

                &:first-of-type {
                    margin-right: 20px;
                }
                &__input {
                    width: 100%;
                    height: 50px;
                    box-sizing: border-box;
                }
                &__lbl {
                    width: 100%;
                    line-height: 20px;
                    color: $ui-07;
                    padding: 0;
                    padding-bottom: 4px;
                    top: -24px;
                }
                &.dln-textarea {
                    width: 100%;
                    height: auto;
                    margin-top: 0;
                    margin-bottom: 32px;

                    & .dln-inputs__input {
                        color: $brand-02;
                        padding: 8px 8px;
                    }
                }
            }
            &-footer {
                border-top: none;
                padding: 0;
                margin: 0;
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__wrapper {
                & .dln-writeReview {
                    & .dln-orderRev {
                        &__itemPic {
                            max-width: 100%;
                            height: auto;
                            margin: 0 20px 0 0;

                            & > img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                        &__content {
                            align-items: center;
                            width: calc(100% - 118px);
                        }
                        &__wrap--main {
                            margin: 0;
                        }
                    }
                    &__subTitle {
                        width: 100%;
                        position: initial;
                        top: initial;
                        left: initial;
                    }
                    &__item {
                        margin-top: 0 !important;

                        & .dln-ratingStars {
                            position: initial;
                            top: initial;
                            left: initial;
                        }
                    }
                    &__form {
                        & .dln-inputs {
                            width: 100%;
                            margin: 20px 0 16px 0;
                        }
                        & .dln-textarea {
                            margin-top: 0;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:map-get($grid-breakpoints, sm)) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__wrapper {
                & .dln-writeReview {
                    &__item {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;

                        & .dln-orderRev {
                            &__itemPic {
                                width: 98px;
                                height: 80px;
                            }
                            &__content {
                                width: calc(100% - 119px);
                            }
                            &--simpleview {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: nowrap;
                                align-items: center;
                            }
                        }

                        & .dln-ratingStars {
                            margin: 0 0 0 auto;
                            position: initial;
                            left: auto;
                        }
                    }
                    &__form {
                        & .dln-inputs:first-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
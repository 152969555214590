.dln-smsCheck {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    max-height: 20px;
    // background: lightcyan;
    transition: max-height 0.12s ease-out 0.12s;
    will-change: max-height;
    overflow: hidden;

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-height: 20px;
        // background: rgba(0,0,0,.1);

        & .dln-checkbox {
            display: inline-flex;
            width: auto;
            width: 56px;

            &__label {
                color: $brand-04;

            }
        }
        &:nth-of-type(2) {
            width: calc(100% - 64px);
            height: auto;
            // background: lightcyan;
            opacity: 0;
            transition: opacity 0.12s linear;
            padding: 4px 0 0 64px;
            margin: 0;
            box-sizing: content-box;
        }
    }
    &__text {
        display: inline-flex;
        width: auto;
        max-width: calc(100% - 56px - 8px);
        min-height: 15px;
        line-height: 15px;
        color: $ui-02;
        opacity: 0;
        transition: opacity 0.12s linear;
        padding: 5px 0 0 0;
        margin: 0 0 0 8px;
    }
    &__input {
        display: block;
        width: 304px;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
        border: 1px solid $ui-02;
        border-radius: 2px;
        background-color: $brand-03;
        padding: 4px 20px 0 20px;
        margin: 0 10px 0 0;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    & .dln-btn--stroked {
        width: 160px;
    }
    &--showInput {
        transition: max-height 0.12s ease-out;

        & .dln-smsCheck__text {
            opacity: 1;
            transition: opacity 0.12s linear 0.12s;
        }
        & .dln-smsCheck__wrap:nth-of-type(2) {
            opacity: 1;
            transition: opacity 0.12s linear 0.12s;
        }
    }
}

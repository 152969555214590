@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-serviceLocator {
        &__quickLinks {
            @include make-container;
            margin: 60px auto;
            width: 100%;
            max-width: 100%;
            &>.dln-wrapper {
                width: calc(100% - 20px);
            }
        }
        &__aside {
            width: 100%;
        }
        .dln-tabs__content {
            opacity: 0;
            display: none;
            flex-wrap: nowrap;

            &--current {
                opacity: 1;
                display: flex;
                flex-wrap: wrap;
            }
        }
        >.dln-wrapper {
            padding: 0;
            margin: 0;

            .dln-breadcrumbs {
                width: calc(100% - 40px);
                margin: 0 20px;
            }
            .dln-serviceLocator__title {
                line-height: 40px;
                font-size: 35px;
                padding: 0 20px;
                margin: 8px 0 24px 0;
            }
            .dln-serviceLocator__description {
                padding: 0 20px;
                margin: 8px 0 24px 0;
            }
        }
        &__results {
            width: 100%;
            max-height: calc(100vh - 40px);
            padding: 20px 0;
            .dln-addressResult {
                padding: 8px 20px;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: calc(100% - 40px);
                    left: 20px;
                    bottom: 0;
                    background-color: $ui-01;
                }
            }
        }
        &__map {
            width: 100%;
            margin: 0;
            display: block;
            opacity: 1;
        }
        &__mobileBnt {
            display: inline-flex;
            >ul {
                border: none;
            }
        }
        &__aside {
            height: auto;
            min-height: auto;
            .dln-inputs--searchBar {
                width: calc(100% - 158px);
                margin: 0 20px 24px 20px;
            }
            .dln-btnTextIcon--nearMe {
                width: 98px;
                margin: 0 20px 24px 0;
            }
            .dln-accordion {
                margin: 0;
                max-height: 40px;
                border-bottom: 2px solid $brand-03;
                &__head {
                    height: 40px;
                    width: calc(100% - 120px - 16px - 10px);
                    border-bottom: none;
                    padding-left: 10px;
                }
                &__wrap {
                    border: none;
                    padding: 8px 20px 16px;
                    &Title {
                        padding-top: 8px;
                        border-top: 1px solid $ui-01;
                    }
                }
            }
        }
        &__resultCounter {
            padding: 0 20px;
        }
        &--results {
            .dln-serviceLocator__aside {
                .dln-accordion {
                    max-height: 40px;
                }
            }
        }
    }
}

.dln-kenwood {
    .dln-recipe-page {
        > .dln-wrapper:last-of-type {
            padding: 0;
            max-width: none;

            .dln-recipe-slides,
            .dln-recipe-page__title-filters,
            .dln-browse-by {
                max-width: 1240px;
                margin-left: auto;
                margin-right: auto;
            }

            .dln-browse-by {
                border-bottom: none;
            }

            .dln-site-search__toolbar--recipes {
                border-bottom: 1px solid $ui-01;
                // border-top: 1px solid $ui-01;

                & .dln-recipe-page__title-filters {
                    margin-top: 0;
                }
            }
        }

        .dln-breadcrumbs {
            .dln-breadcrumbs__li:last-of-type() {
                opacity: 0.5;
            }
        }
        .dln-recipe-page__title {
            text-align: center;
            @include fontExtra();
            font-size: 38px;
        }

        .dln-recipe-details {
            width: 100%;
            padding: 0 30px;

            .dln-wrapper {
                max-width: 1240px;
            }
        }

        .dln-recipe-details--constrain {
            max-height: 500px;
            padding: 0;

            .dln-image__image {
                max-height: 500px;
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .dln-kenwood {
        .dln-recipe-page {
            > .dln-wrapper:last-of-type {
                padding: 30px;
                max-width: 1240px;

                .dln-recipe-slides,
                .dln-recipe-page__title-filters,
                .dln-browse-by {
                    max-width: none;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 599px) {
    .dln-kenwood {
        .dln-recipe-page {
            > .dln-wrapper:last-of-type {
                padding: 20px;
                max-width:100%;

                .dln-recipe-slides,
                .dln-recipe-page__title-filters,
                .dln-browse-by {
                    max-width: none;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}


@media screen and (max-width: 900px) {
    .dln-kenwood {
        .dln-recipe-page {
            &--constrain {
                max-height: unset;
            }
        }
    }

    .dln-kenwood .dln-recipe-page .dln-recipe-details--constrain {
        max-height: unset;
    }
}

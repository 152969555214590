.dln-checkout__guest-email-form {
  flex-direction: column;
  max-width: 390px;

  .dln-checkbox--consent {
    .dln-checkbox__wrap {
      display: block;
    }

    .dln-checkbox__inputPlaceholder {
      display: inline-block;
      width: 15px;
      height: 15px;
      box-sizing: border-box;
    }

    .dln-checkbox__inputPlaceholder:after {
      color: transparent !important;
      background-color: var(--support-02) !important;
      width: 8px !important;
      height: 8px !important;
    }

    .dln-checkbox__label {
      display: inline;
      font-size: 13px;
      font-weight: 400;
      color: var(--brand-02) !important;
    }
  }

  .dln-icn {
    border: 0;
    background: none;
    width: 20px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .dln-paragraph {
    font-size: 10px;
    line-height: 125%;
    color: var(--brand-02);
    margin: 8px 0 24px;

    a {
      color: var(--brand-02) !important;
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-tab {
        // overflow-x: scroll;
        // overflow-y: hidden;

        & > ul {
            // display: table;
            display: block;
            width: 100%;
            font-size: 0;
            text-align: left;
            white-space: nowrap;
            padding: 0;
            overflow-x: scroll;
            position: relative;
        }
        &__listGradient {
            display: block;
        }
        &__links {
            // display: inline-table;
            display: inline-block;
            box-sizing: content-box;
        }
    }
}
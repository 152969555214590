.dln-delonghi {
  & .dln-instSteps {
    &__step {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
    &__stepNum {
      @include fontBold;
      font-size: REM(13px);
      background-color: $brand-04;
    }
    &__stepText {
      font-size: REM(16px);
      color: $brand-04;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-instSteps {
      &__stepNum {
        margin-right: 20px;
      }
      &__stepText {
        width: calc(100% - 30px - 20px);
        font-size: REM(15px);
      }
    }
  }
}

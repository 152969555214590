@media only screen and (max-width:599px) {
    .dln-singProductReview {
        padding: 24px;

        & .dln-orderRev {
            &__itemPic {
                // width: 140px;
                // max-width: 100%;
                width: 100%;
                max-width: 140px;
                // height: auto;
                height: 112px;
                margin: 0 32px 0 0;
            }
            &__content {
                width: auto;
            }
        }
    }
}
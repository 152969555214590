.dln-wishProd {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    border: 1px solid;
    border-color: transparent;
    transition: border-color 0.12s linear;
    padding: 10px 10px 10px 10px;

    &:hover {
        border-color: $ui-02;
    }
    &__imgLink {
        display: flex;
        flex-wrap: wrap;
        width: 182px;
        height: 140px;
        margin: 0 20px 0 0;
    }
    &__imgWrap {
        display: block;
        width: 182px;
        height: 140px;
        // background-color: $ui-03;
        background-color: $brand-03;
        position: relative;
        z-index: 1;

        &::after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.04);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
        & > img {
            display: block;
            object-fit: contain;
            // width: 182px;
            // height: 140px;
        }
    }
    &__data {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        width: calc(100% - 182px - 20px);
        height: auto;
        min-height: 114px;
        padding: 0 0 0 0;
    }
    &__name {
        @include fontBold;
        display: block;
        width: 100%;
        height: auto;
        line-height: 16px;
        font-size: 14px;
        color: $brand-04;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 10px 0;
        margin: 0 0 10px 0;

        &:link,
        &:visited,
        &:hover,
        &:active {
            text-decoration: none;
        }
    }
    &__priceWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        margin: 0 0 17px 0;
    }
    &__price,
    &__priceDiscounted {
        display: inline-block;
        width: auto;
        line-height: 20px;
        font-size: 14px;
        color: $brand-04;
        padding: 0;
    }
    &__price--discounted {
        color: $brand-02;
        margin: 0 10px 0 0;
        position: relative;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $brand-02;
            position: absolute;
            top: calc(50% + 1px);
            left: 0;
        }
    }
    &__priceDiscounted {
        color: $support-01;
    }
    &__outOfStock {
        display: block;
        width: 100%;
        height: 15px;
        line-height: 15px;
        font-size: 13px;
        color: $support-01;


    }

    & .dln-btn--link {
        display: inline-flex;
        align-items: center;
        margin: 0 auto 0 0;
    }
    & .dln-btn--stroked {
        width: 100%;
        max-width: 140px;
        margin: 0;
    }
    &--outOfStock {
        & .dln-wishProd__imgWrap > img {
            opacity: 0.3;
        }

        & .dln-wishProd__price {
            opacity: 0.3;
        }

        .dln-wishProd__name {
            color: rgba(black,.3);
            opacity: 1;
        }
        .dln-wishProd__price {
            display: none;
        }
        .dln-wishProd__priceWrap + .dln-wishProd__priceWrap {
            display: none;
        }

    }
    & .dln-btnTextIcon {
        width: 128px;
    }
}

.swiper-slide {
    & .dln-wishProd {
        width: calc(100% - 20px);
        margin: 0 auto;

        &__imgLink {
            width: 100%;
            margin: 0 auto;
        }
        &__imgWrap {
            width: 100%;
            text-align: center;

            & > img {
                width: 182px;
                height: 140px;
                margin: 0 auto;
            }
        }
        &__data {
            width: 100%;
            text-align: center;
        }
        &__name {
            min-height: 45px;
            font-size: 15px;
            border-width: 0;
            padding: 0 32px;
            margin: 16px auto 0;
        }
        &__priceWrap {
            display: block;
        }
        & .dln-btn--link {
            display: none;
        }
        & button {
            margin: 24px auto 0;
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-registrationForm {
        &__formContainer {
            .dln-select {
                width: calc(50% - 10px);
            }
            .dln-inputs {
                margin: 0 0 16px 0;
                &--halfCol {
                    width: 100%;
                    margin: 0 0 32px 0;
                    &:nth-child(even) {
                        margin: 0 0 32px 0;
                    }
                }
                .dln-inputs__extraLbl {
                    width: 100%;
                    max-width: 100%;
                }
                &--extralabel {
                    margin: 0 0 60px 0 !important;
                }
            }
            .dln-checkbox {
                margin: 70px 0 32px;
            }
        }
        &__formFoot {
            .dln-btn {
                width: 100%;
            }
            .dln-registrationForm__paragraph--secondary {
                text-align: center;
            }
        }
    }


}
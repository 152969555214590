.dln-custServiceCard {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    border-radius: 6px;
    background-color: $ui-05;
    padding: 24px 20px 48px 20px;
    box-sizing: border-box;

    &__title {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 32px;
        margin-bottom: 33px;
    }
    &__text {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 33px;
    }
    &__actions {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        & > .dln-btn {
            width: calc(33.33% - ((20px * 2) / 3));
            max-width: 184px;
            margin: 0 20px 0 0;
            box-sizing: border-box;

            &--stroked {
                background-color: transparent;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

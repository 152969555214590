@media only screen and (max-width:599px) {
    .dln-table {
        padding: 0;
        &__tdHeading {
            display: block;
            font-size: 10px;
            color: $ui-02;
        }
        &__table {
            thead {
                display: none;
            }
            tbody {
                tr {
                    /* display: flex;
                    flex-wrap: wrap; */
                    display: grid;
                    grid-template-rows: auto;
                    grid-template-columns: repeat(3, 33.333%);
                }
                td {
                    display: inline-block;
                    border: none;
                    &:not(.dln-table__fullwidth) {
                        /*  width: calc(33.333% - 32px); */
                        width: calc(100% - 32px);
                        padding: 16px !important;
                    }
                    span {
                        display: block;
                    }
                }
            }
            & .dln-table__fullwidth {
                /* width: 100%; */
                width: calc(100% - 32px);
                display: block;
                order: 1;
                padding: 16px !important;
                grid-area: auto / 1 / span 1 / -1;
            }
        }
    }
}
.dln-kenwood {
    .dln-subscription-flow {
        padding: 0 !important;

        .dln-title {
            @include fontExtra;
            font-size: 28px;
        }

        .dln-subscription-flow__sub-title {
            font-size: 14px;
        }

        .dln-subscription-flow__service-container {
            margin-bottom: 17px;
            justify-content: space-between;
            flex-wrap: wrap;
            span,
            i {
                font-size: 14px;
            }

            span {
                margin-left: 20px;
                white-space: nowrap;
            }

            i {
                font-size: 0;
                &::before {
                    content: "H";
                    color: $brand-01;
                    font-size: 14px;
                    position: absolute;
                    top: -9px;
                }
            }

            & > div {
                margin-left: 0;
            }
        }

        .dln-inputs__input {
            border-color: $brand-04;
        }

        .dln-checkbox__input,
        .dln-checkbox__label {
            font-size: 12px;
            margin-right: 0;
        }

        .dln-icn.dln-icn--iconfont.dln-icn--faqs.dln-icn--i {
            color: $brand-02;
            font-weight: 600;
        }

        .dln-subscription-flow__row {
            margin-bottom: 20px;
        }

        .dln-subscription-flow__column-two {
            padding-right: 59px;
        }

        .dln-checkbox__tooltipWrap {
            font-size: 8px;
        }

        .dln-btn {
            margin-top: 35px;
        }

        .dln-btnIcon.dln-btnIcon--close {
            top: 10px !important;
            right: 10px !important;
            .dln-icn.dln-icn--iconfont {
                &::before {
                    content: "æ";
                    color: $brand-04 !important;
                }
            }
        }

        @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
            .dln-subscription-flow__column-one {
                display: none;
            }

            .dln-subscription-flow__column-two {
                width: 100% !important;
            }
        }

        @media only screen and (max-width: 599px) {
            .dln-popup {
                background: $brand-03 !important;
            }

            .dln-subscription-flow__column-one {
                display: none;
            }

            .dln-subscription-flow__column-two {
                width: 100% !important;
                padding: 90px 20px 0 20px;
            }

            .dln-subscription-flow__service-container {
                width: calc(100% - 40px);
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;

                div + div {
                    margin-left: 10px;
                }
            }

            .dln-subscription-flow__input-container {
                margin-top: 35px;
            }

            .dln-checkbox__label {
                margin-right: 30px;
            }

            .dln-subscription-flow__row {
                margin-bottom: 0;
            }

            .dln-checkbox--without-border .dln-checkbox__tooltipIconWrap {
                top: 6px;
            }

            .dln-title {
                font-size: 28px;
                line-height: 1.21;
            }


            .dln-btn{
                margin: 0 auto !important;
            }
        }
    }
}

.dln-kenwood {
    .dln-breadcrumbs {
        font-size: 12px;
        line-height: 15px;

        &__li {
            color: $ui-07;

            &::before {
                padding-right: 0.05em;
                padding-left: 0.05em;
                color: $brand-04;
            }

            a,
            span {
                font-size: 12px;
                line-height: 15px;
            }
            span {
                text-decoration: underline;
            }

            &:last-of-type(){
                opacity: 0.5;
            }
        }
    }
}

/* Fonts */
$fontNameIconsBraunNew: 'bra-icons';

$fontNameIcons: var(--fontNameIcons);

$fontPath: var(--fontPath);

/* Brand Colors */
$brand-01: var(--brand-01);
$brand-02: var(--brand-02);
$brand-03: var(--brand-03);
$brand-04: var(--brand-04); // text color
$brand-05: var(--brand-05);

/* UI Colors */
$ui-01: var(--ui-01);
$ui-02: var(--ui-02);
$ui-03: var(--ui-03);
$ui-04: var(--ui-04);
$ui-05: var(--ui-05);
$ui-06: var(--ui-06);
$ui-07: var(--ui-07);
$ui-08: var(--ui-08);
$ui-09: var(--ui-09);
$ui-10: var(--ui-10);

/* Support Colors */
$support-01: var(--support-01);
$support-02: var(--support-02);
$support-03: var(--support-03);
$support-04: var(--support-04);
$support-01--light: var(--support-01--light);
$support-02--light:var(--support-02--light);
$support-02--bright: var(--support-02--bright);

$fontPath: var(--fontPath);

/* Breakpoints */
$grid-columns: 12;
$grid-gutter-width: 20px;

$grid-breakpoints: ( // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 599px,
    // Medium screen / tablet
    md: 900px,
    // Large screen / desktop
    lg: 1199px,
    // Extra large screen / wide desktop
    xl: 1480px);

$container-max-widths: ( // container max-width
    sm: 560px,
    md: 860px,
    lg: 984px,
    xl: 1480px);

// third party library

// flag-icon
$flag-icon-css-path: '#{$pathAssets}/images/flags' !default;
$flag-icon-rect-path: '/4x3' !default;
$flag-icon-square-path: '/1x1' !default;

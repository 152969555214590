@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-prodReg {
        .dln-advtBox {
            // width: calc(50% - 10px);
            margin: 0 20px 20px 0;
            padding: 8px 16px;
            height: 200px;
            &:nth-child(2n+2) {
                margin: 0 0 20px 0;
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-ordFeatSlider {
            max-width: none;
            padding: 70px 20px 40px 20px;
            box-sizing: border-box;

            &::before {
                display: none;
            }
            &__slide {
                width: 100%;
                max-width: none;
            }
            &__scrollbar {
                display: flex;
                width: 100%;
                height: 3px;
                background-color: $ui-09;
                opacity: 1 !important;
                margin-top: 30px;

                & .swiper-scrollbar-drag {
                    background-color: $brand-01;
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-smsCheck {
        &__wrap:nth-of-type(2) {
            width: calc(100% - 24px);
            padding: 0 0 0 24px;

            & .dln-smsCheck__input {
                width: calc(100% - 104px - 10px);
            }
            & .dln-btn--stroked {
                width: 104px;
            }
        }
        & .dln-checkbox {
            width: 100%;
            margin: 0 0 4px 0 !important;
        }
        &__text {
            width: calc(100% - 24px);
            max-width: initial;
            padding: 0 0 0 24px;
            margin: 0 0 8px 0;
        }
    }
}

.dln-kenwood {
    .dln-shareWishList {
        padding: 80px 50px 70px 50px !important;

        .dln-popup__head {
            padding: 0 0 32px 0;

            &Title {
                @include fontExtra;
                font-size: 32px;
                margin-top: 32px;
            }
        }

        .dln-btn {
            height: 45px;
        }
        @media screen and (max-width: 599px) {
            padding: 90px 20px !important;

            .dln-popup__headTitle {
                font-size: 28px;
                margin-right: 0;
                margin-left: 0;
                padding: 0;
                width: 100%;
                max-width: 100%;
            }
            .dln-inputs {
                width: 100%;
                margin: 0 0 16px;
            }
            .dln-btn {
                margin: 0 0 0 auto;
                width: auto;
            }
        }
    }
}
.dln-delonghi {
  & .dln-faq {
    &--alert.dln-faq--stepTwo {
      & .dln-faq__mainSubtitle {
        margin-bottom: 13px;
      }
      & .dln-faq__spellCheck {
        line-height: 24px;
        font-size: REM(18px);
        color: $brand-04;
        margin-bottom: 23px;
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {
  .dln-delonghi {
    & .dln-faq {
      &--alert.dln-faq--stepTwo {
        & .dln-faq__mainSubtitle {
          margin-bottom: 16px;
        }
        & .dln-faq__spellCheck {
          font-size: REM(16px);
        }
      }
    }
  }
}

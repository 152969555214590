.dln-kenwood {
    .dln-prodReg--productDetails {
        padding-top: 60px;

        & .dln-prodReg__head {
            margin-top: 0;
            width: 100%;
            min-width: initial;
            background-color: $brand-03;
            padding-top: 0;

            & .dln-title {
                margin: 0 0 30px 0;
            }
            & .dln-timeline {
                margin: 0;
            }
        }
        & .dln-prodReg__content {
            margin-top: 0;
            margin-bottom: 0;

            & .dln-prodReg__subTitle {
                @include fontExtra;
                display: block;
                line-height: 40px;
                font-size: 32px;
                font-weight: 400;
                text-align: center;
                margin: 0 0 45px 0;

                & .dln-prodReg__lbl {
                    display: none;
                }
            }
        }

        .dln-inputs--datepicker {
          margin-bottom: 30px;
        }

        .dln-inputsFile {
          padding: 0;
          margin-bottom: 30px; 
        }

        .dln-select--labeled {
          margin: 0;
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-prodReg--productDetails {
            padding-top: 40px;

            & .dln-prodReg__head {
                padding: 0 0 0 0;

                & .dln-title {
                    width: 100%;
                    line-height: 40px;
                    padding: 0;
                }
                & .dln-timeline {
                    margin-bottom: 60px;
                    max-width: none;
                    min-height: 74px;

                    &__step {
                        &::after {
                            width: calc(50% - 8px);
                            left: 72px;
                        }
                    }
                    &__label {
                        & span {
                            display: block;
                            width: 100%;
                            line-height: 14px;
                            font-size: 12px;
                            text-align: center;
                            opacity: 1;
                        }
                    }
                }
            }
            & .dln-prodReg__content {
                & .dln-prodReg__subTitle {
                    margin-bottom: 32px;
                }

                & .dln-btn[title="Add details"] {
                    width: 127px;
                    padding: 0;
                }
            }
        }
    }
}
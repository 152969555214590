@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    //
}

@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-filters-bar {
        &__desktop {
            display: none;
        }
        &__mobile {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-filter {
                display: flex;
                align-items: center;

                & .dln-icn {
                    font-weight: bold;
                }
            }
            & .section-label {
                @include fontBold;
                color:black;
                padding-right: 10px;
            }
            & .bra-btnView {
                display: none;
            }
        }
        &__applied-filters {
            &.swiper-container {
                margin-top: 20px;
            }
        }
        &__sorted-by {
            display: none;
        }
    }
}


@media only screen and (min-width: 600px) {
    .dln-filters-bar {
        &__mobile {
            & .dln-btnView {
                display: none;
            }
        }
    }
}

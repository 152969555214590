.dlg-gigya-input-text-wrapper {
  @include dlg-gigya-input-text-wrapper;
}

.dlg-gigya-input-date-wrapper:before {
  font-family: var(--fontNameIcons);
  content: 'b';
  width: 17px;
  height: 17px;
  background-repeat: no-repeat;
  background-size: auto 95%;
  background-position: 50% 50%;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 17px;
  pointer-events: none;
}
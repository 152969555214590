@media only screen and (max-width:599px) {
    .dln-search-results {
        &__grid {
            flex-direction: column;
            .dln-search-result:nth-last-child(odd) {
                width: calc(100% - 40px);
                margin-top: 20px;
            }
            .dln-search-result:nth-last-child(even) {
                display: none;
            }
        }
    }
}
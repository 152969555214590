@media only screen and (max-width:599px) {
    .dln-order {
        &--status {
            & .dln-wrapper--heading {
                padding-top: 25px;
            }
            & .dln-wrapper--userCards {
                padding-top: 40px;
                padding-bottom: 45px;
            }
        }
        & .dln-title {
            line-height: 27px;
            font-size: 24px;
            margin: 0 0 10px 0;
        }
        &__userCard {
            width: 100%;
            border: none;
            padding: 0;

            &--registered {
                border-bottom: 1px solid $ui-01;
                padding-bottom: 40px;
                margin-bottom: 40px;
            }
            &Form {
                margin-bottom: 21px;
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-wishlist {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
        }
        &__productWrap {
            & .dln-wishProd {
                width: calc(100% - 7.5px);
                &:nth-of-type(2n+2) {
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .dln-wishlist {
        &__productWrap {
            & .dln-wishProd {
                width: calc(100% - 7.5px);
                margin: 0 0 10px 0;
                &:nth-of-type(2n+2) {
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .dln-wishlist {
        &__productWrap {
            & .dln-wishProd {
                width: calc(100% - 7.5px);
                margin: 0 0 10px 0;
                &:nth-of-type(2n+2) {
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}

@media only screen and (max-width:1366px) {
    .dln-wishlist {
        &__productWrap {
            & .dln-wishProd {
                width: calc(100% - 7.5px);
                &:nth-of-type(2n+2) {
                    margin: 0 0 10px 0;
                }
            }
        }
    }
}
.dln-kenwood {
    .dln-prdReview {
        &__sectionTitle {
            @include fontExtra;
            line-height: 40px;
            margin-bottom: 25px;
        }
        .dln-orderRev {
            &__wrap--main {
                margin-top: 10px;
                flex-direction: row;
                .dln-ratingStars.dln-ratingStars--disabled {
                    order: 2;
                }
                .dln-orderRev__detailsTitle {
                    order: 1;
                }
                .dln-orderRev__reviewTitle {
                    order: 3;
                }
                .dln-orderRev__desc {
                    order: 4;
                    @include fontLight;
                    font-size: 14px;
                    line-height: 20px;
                    width: 100%;
                }
            }
            &__detailsTitle {
                line-height: 16px;
                margin-bottom: 7px;
            }
            &__itemPic {
                background: none;
            }
            .dln-ratingStars {
                .dln-icn {
                    width: 24px;
                    height: 24px;
                    &--iconfont {
                        font-size: 22px;
                    }
                }
            }
            .dln-orderRev__actions {
                display: flex;
                flex-direction: column;
                align-self: center;
                .dln-btn--link {
                    order: 2;
                    text-align: right;
                }
                .dln-btn--stroked {
                    order: 1;
                    margin-bottom: 20px;
                }
            }
        }
        @media only screen and (max-width:599px) {
            .dln-title {
                margin-top: 6px;
                margin-bottom: 30px;
            }
            &__sectionTitle {
                font-size: 28px;
                line-height: 34px;
            }
            .dln-orderRev {
                &__itemPic {
                    width: 100%;
                    height: auto;
                    padding: 0;
                    margin: 0;

                    img {
                        width: auto;
                        max-width: 100%;
                    }
                }
                &__content {
                    width: 100%;
                }
                &__actions {
                    .dln-btn {
                        max-width: 184px;
                    }
                }
                &--disabled {
                    padding: 0;
                }
                &__wrap--main {
                    margin-bottom: 10px;
                }
                .dln-orderRev__actions {
                    margin-top: 0;
                    .dln-btn--stroked {
                        margin-bottom: 15px;
                    }
                    .dln-btn--link {
                        line-height: 20px;
                        margin-bottom: 0px;
                        text-align: center;
                    }
                }
                &--disabled {
                    padding-bottom: 30px;
                    border-bottom: 1px solid $ui-01;
                    margin-bottom: 20px;
                    &:last-child {
                        border: 0;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
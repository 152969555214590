@media only screen and (max-width:599px) {
    .dln-manuals-list {
        & .dln-icn {
            line-height: 48px;
            margin: 0 15px 0 0;
        }
    }
    .dln-instruction-manuals__list {
        .dln-instruction-manuals__listText {
            padding-left:0;
        }
    }
}
.dln-kenwood {
    .dln-wishlist {
        &__content {
            text-align: center;
            .dln-title {
                @include fontExtra;
                margin: 0 0 32px 0;
            }
        }
        &__subtitle {
            margin: 0 0 35px 0;
            font-size: 14px;
        }

        &__productWrap {
            border-bottom-color: $ui-07;
            padding: 0 0 20px 0;

            .dln-wishProd {
                width: calc(33.333% - 14px);
                margin: 0 20px 40px 0;

                @media only screen and (max-width:599px) {
                    &:nth-of-type(2n+2) {
                        margin: 0 20px 20px 0;
                    }
                }
                @media only screen and (min-width:599px) {
                    &:nth-of-type(3n+3) {
                        //margin: 0 0 20px 0;
                        margin-right: 0
                    }
                }

                &:last-child {
                    margin-right: auto !important;
                }

                &__name {
                    font-size: 14px;
                    min-height: 16px;
                }
            }
        }
        &__foot {
            margin: 20px 0 0;
            .dln-btn {
                margin: 0 auto;
                width: auto;

            }
        }
        &--empty {
            .dln-wishlist__content {
                text-align: center;
            }
            .dln-wishlist__subTitle {
                @include fontExtra;
                @include definePseudoElems;
                position: relative;
                margin: 0 auto 24px;
                font-size: 28px;
                line-height: 36px;
                padding-top: 100px;
                &::before {
                    @include fontIcon;
                    content: '=';
                    font-size: 80px;
                    line-height: 80px;
                    color: $ui-01;
                    top: 0;
                    left: calc(50% - 40px);
                }
            }
            .dln-wishlist__paragraph {
                font-size: 14px;
                line-height: 20px;
                margin: 0 auto;
            }
            .dln-wishlist__foot {
                margin-top: 30px;
                padding: 0;
                border-width: 0;
            }
        }



        @media only screen and (min-width:map-get($grid-breakpoints, md)) and (max-width:map-get($grid-breakpoints, lg)) {
            .dln-wishlist__content {
                .dln-wishlist__productWrap {
                    .dln-wishProd {
                        width: calc(33.333% - 14px);
                        border-bottom: 0;
                    }
                }
            }
        }
        @media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
            .dln-wishlist__content {
                margin-bottom:0;
                padding-bottom:0;

                .dln-title {
                    margin-top: 7px;
                }
                .dln-wishlist__productWrap {
                    .dln-wishProd {
                        margin: 0 0 30px 0;
                        padding-bottom: 30px;

                        // &:last-of-type {
                        //     padding-bottom: 0;
                        //     border-bottom: 0;
                        //     margin-bottom:0;
                        // }
                    }
                }
            }
        }
        @media only screen and (max-width:map-get($grid-breakpoints, sm)) {
            .dln-wishlist__content {
                margin-bottom:0;
                padding-bottom:0;

                .dln-title {
                    margin-top: 7px;
                    margin-bottom: 17px;
                }
                .dln-wishlist__text,
                .dln-wishlist__signInNotice {
                    margin-bottom: 30px;
                }

                .dln-wishlist__productWrap {
                    .dln-wishProd {
                        &:last-of-type {
                            padding-bottom: 0;
                            border-bottom: 0;
                            margin-bottom:0;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width:599px) {
            .dln-wishlist__content {
                .dln-wishlist__productWrap {
                    .dln-wishProd {
                        //width: 100%;
                    }
                }
            }

            .dln-wishlist__content {
                .dln-title {
                    margin: 30px 0 20px 0;
                }

                .dln-wishlist__productWrap {
                    padding-bottom: 0;
                    .dln-wishProd {
                        width: 100%;
                        margin: 0 0 30px 0;
                        border-bottom: 1px solid $ui-01;
                        &:nth-of-type(2n+2) {
                            margin: 0 0 32px 0;
                        }
                    }
                }
            }
            .dln-wishlist__subtitle {
                margin: 0 0 20px 0;
            }
            
            .dln-wishlist__foot {
                margin: 0;
                padding: 0;
            }
            .dln-wishlist--empty {
                .dln-wishlist__content {
                    margin-bottom: 0;
                }
                .dln-wrapper {
                    padding-bottom: 0;
                }
                .dln-wishlist__aside .dln-vertMenu {
                    margin-bottom: 0;
                }
                .dln-wishlist__subTitle {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            &.dln-page--myArea:not(.dln-wishlist--empty) {
                > .dln-wrapper {
                    padding-bottom: 100px
                }
                .dln-wishlist__foot {
                    padding: 0;

                    .dln-btn {
                        width: 100%;
                        max-width: initial;
                        height: 60px;
                    }
                }
            }

        }
    }
}
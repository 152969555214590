.dln-kenwood {
    & .dln-inputsFile {
        padding-top: 20px;
        
        &__label {
            &Text {
                display: block;            
            }
        }
    }
}
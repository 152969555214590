.dln-cart-promo-badge {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 12px;

  &__title {
    margin: 20px 0 10px;
    font-size: REM(19px);
  }

  &__badge {
    width: fit-content;
    padding: 5px 10px;
    border: 1px solid $support-02;
    border-radius: 5px;
    font-size: REM(14px);
    line-height: 1;
    color: $support-02;
  }
}

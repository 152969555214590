.dln-prodReg-lite {
  padding: 50px 15px;

  &__wrapper {
    margin: 0 auto;
    max-width: 1100px;
  }

  .dln-select {
    cursor: default;
  }

  .dln-inputs__input[disabled],
  .dln-select--disabled,
  .dln-inputsFile__label {
    opacity: 1 !important;
    background-color: $custom-disabled-color !important;
  }

  .dln-regProductList__sectionFoot {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $ui-01;
  }
}
.dln-btnView {
    display: inline-flex;
    width: auto;
    height: auto;

    &__button {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: none;
        background: none;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        & .dln-icn {
            display: inline-block;
            width: 20px;
            height: 20px;
            color: $ui-04;
            line-height: 20px;
            font-size: 20px;

            &::before {
                display: inline-block;
                width: 20px;
                height: 20px;
            }
        }
        &.-active {
            & .dln-icn {
                color: $brand-01;
            }
        }
    }
}

// .dln-popup {
//     display: flex;
//     flex-wrap: wrap;
//     align-content: flex-start;
//     width: 100%;
//     height: auto;
//     border-radius: 2px;

//     &__head {
//         display: flex;
//         flex-wrap: wrap;
//         width: 100%;
//         height: auto;
//         border-radius: 2px 2px 0 0;
//         padding: 16px 20px 16px 20px;

//         & .dln-btnIcon {
//             margin: 0 0 0 auto;
//         }
//     }    
//     &__content {
//         display: flex;
//         flex-wrap: wrap;
//         align-content: flex-start;
//         width: 100%;
//         height: auto;
//         border-radius: 0 0 2px 2px;  
//         padding: 0 20px 0 20px;
//     }
// }

.dln-payMethCreate {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    height: auto;     
    // padding: 20px 20px 32px 20px;
    padding: 0 20px 32px 20px;
    
    &__title {
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 26px;
        @include fontBold;
        text-align: center;
        padding: 4px 0 0 0;
        margin: 0 0 32px 0;
    }
    & .dln-inputs {
        margin: 0 0 24px 0;

        &--datepicker {
            width: calc(50% - 10px);
            margin: 0 20px 24px 0;
        }
        &--securityCode {
            width: calc(50% - 10px);
            margin: 0 0 24px 0;
        }
    }
    & .dln-checkbox {
        margin: 0 0 24px 0;
    }
    & .dln-btn {
        width: calc(50% - 12px);
        margin: 0 0 48px auto;
    }
    &__foot {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        height: auto;
        border-radius: 0 0 2px 2px;
        padding: 0 0 32px 0;

        & .dln-icn {
            line-height: 24px;
            font-size: 18px;
            color: $ui-02;
            margin: 0 4px 0 0;
        }
        &Text {
            height: auto;
            line-height: 20px;
            color: $ui-02;
            padding: 4px 0 0 0;
        }
    }
}
.dln-noticeBox {
    width: 100%;
    padding: 24px 16px;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid $ui-04;
    color: $ui-04;

    &__title {
        @include large;
        font-size: 26px;
        @include fontBold;
        margin: 0 0 8px;
        width: 100%;
        display: block;
    }
    &__copy {
        @include small;
        width: 100%;
        display: block;
    }
    &__content {
        width: calc(100% - 48px - 24px);
        margin: 0 0 0 auto;
        display: block;
        color: $ui-04;
        text-decoration: none;
    }
    &__icon {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
        
        .dln-icn {
            font-size: 48px;
        }
    }
    &--light {
        border: none;
        text-align: center;
        padding: 24px;
        .dln-noticeBox__icon,
        .dln-noticeBox__content {
            width: 100%;
            margin: 0 auto 8px;
        }
        .dln-noticeBox__copy {
            @include small;
            font-size: 12px;
            margin: 0 auto 8px;
        }
    }
    &__link {
        @include small;
        font-size: 12px;
        color: $brand-04;
        margin: auto auto 0 auto;
    }
}
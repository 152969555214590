@media only screen and (max-width:599px) {
    .dln-createShippingAddress {
        padding: 20px;

        .dln-mandatory {
            margin-bottom: 20px;    
        }

        .dln-divider~.dln-inputs {
            margin-bottom: 0;
        }
        .dln-createShippingAddress__form {
            &>.dln-select {
                margin-right: 0;
                width: 100%;
                max-width: 100%;
            }
            .dln-inputs {
                width: 100%;
                margin: 0 0 32px;
                &:last-of-type {
                    margin: 0;
                }
            }
            .dln-btn {
                width: 100%;
                margin: 0 0 16px 0;
                &:nth-of-type(even) {
                    margin: 0 0;
                }

                &--confirm {
                    margin: 16px 0 0 0;
                }
            }

            .dln-checkout_rowForm {
                flex-flow: column;
                gap: 30px;
            }
        }
    }
}

.del-error500 {
  &__wrapper {
    min-height: calc(100vh - (61px + 122px));  // vert-viewport minus (header+footer) heights
  }
}

.ken-error500 {
  &__wrapper {
    min-height: calc(100vh - (67px + 106px));  // vert-viewport minus (header+footer) heights

    @media screen and (min-width: 1200px) {
      min-height: calc(100vh - (56px + 128px));  // vert-viewport minus (header+footer) heights
    }

    .ken-hero {
      @media screen and (min-width: 768px) {
        height: 100%;
      }

      &__wrapper {
        @media screen and (min-width: 768px) {
          height: 100%;
        }
      }

      &__picture {
        @media screen and (min-width: 768px) {
          justify-content: flex-start;
          align-items: flex-end;
          height: 100%;
        }

        &-image {
          @media screen and (min-width: 768px) {
            min-width: unset !important;
          }
        }
      }

      &__textblock {
        background-color: transparent;

        @media screen and (min-width: 768px) {
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          max-width: 100%;
          margin: 0;
          padding: 85px 0 0;
        }

        &-titles {
          @media screen and (min-width: 768px) {
            align-items: center;
          }
        }

        &-title {
          font-size: 32px;

          @media screen and (min-width: 768px) {
            font-size: 38px;
            line-height: 40px;
          }
        }

        &-content {
          @include fontExtra;
          padding-top: 30px;
          font-size: 24px;
          line-height: 32px;

          @media screen and (min-width: 768px) {
            margin: 0;
            padding-top: 31px;
            font-size: 32px;
            line-height: 40px;
            text-align: center;
          }
        }
      }
    }
  }
}

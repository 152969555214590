.dln-delonghi {
  .dln-page.dln-changePssw {
    .dln-wrapper .dln-page__content {
      .dln-changePssw {
        &__section {
          &Foot {
            margin-top: 8px;
          }
          & .dln-title {
            font-size: REM(28px) !important;
          }
          &--deleteAccount {
            & .dln-changePssw__paragraph {
              @include fontBook;
              font-size: REM(14px);
              line-height: 18px;
              text-align: left;
            }
            ul {
              margin: 0;
              li {
                @include fontBook;
                font-size: REM(14px);
                line-height: 18px;
                margin: 0;

                &::before {
                  background: $brand-04;
                }
              }
            }
          }
        }
        &__confirmDeleting {
          &.-extraContent {
            width: 100%;
          }

          .dln-changePssw__middleTitle {
            @include fontMedium;
            font-size: REM(18px);
            line-height: 19px;
          }
        }
      }

      @media only screen and (max-width: 599px) {
        .dln-changePssw {
          &__section {
            margin-bottom: 0;

            &Foot {
              margin-top: 16px;
              padding-top: 24px;
            }
          }
        }

        .dln-changePssw__section
          + .dln-changePssw__section:not(.dln-changePssw__section--deleteAccount) {
          margin-top: 64px;
        }
        .dln-changePssw__section + .dln-changePssw__section--deleteAccount {
          margin-top: 40px;
        }
      }
    }
  }
}

.dln-kenwood {
    .dln-country-list {
        padding: 0 80px;
       
        .dln-page__content{
          
            margin: 0;
        }


        .dln-accordion__head {
            width: 100%;
            text-align: left;
            padding-bottom: 25px;
            border-bottom: 1px solid $ui-01;
        }

        .dln-accordion__title {
            color: $brand-04 !important; 
            font-family: 'Merriweather' !important;
            font-size: 30px !important;
          
            
            

        }

        .dln-accordion__content{
            padding-top: 34px;
        }

        .dln-accordion--arrow {
            border-bottom: none;
        }

        .dln-flag__lang {
            color: $ui-07 !important;
            strong {
                color: $brand-04 !important;
            }
        }
        @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
           padding: 0 30px;
        }

        @media only screen and (max-width: 599px) {

            padding: 0 20px 80px 20px;
         
            .dln-accordion__head {
                border-top: none;
                border-bottom: none;
            }    
            .dln-accordion--arrow {
               border-bottom: 1px solid $ui-01;
            }

            .dln-accordion__title {

                font-weight: normal !important;
                font-size:24px !important;
            }
        }
    }
}

.dln-kenwood {
    & .dln-payMethCreate {
        padding: 80px 101px 49px 102px !important;

        & .dln-popup {
            &__head {
                padding: 0;
                margin: 0 -51px 49px -52px;
                width: calc(100% + 51px + 52px);
            }
        }
        & .dln-inputs {
            margin-bottom: 41px;

            &--datepicker,
            &--securityCode {
                margin-bottom: 30px;
            }
        }
        & .dln-checkbox {
            margin: 0 0 24px 0;
        }
        & .dln-btn {
            width: initial;
            margin: 0 0 40px auto;
        }
        &__foot {
            padding: 0;

            & .dln-icn {
                color: $ui-10;
            }
            &Text {
                color: $brand-02;
            }
        }

        @media only screen and (max-width:599px) {
            padding: 90px 20px !important;

            .dln-popup {
                &__head {
                    margin: 0 0 51px 0  !important;
                    width: 100%;
                }
            }

            & .dln-inputs {
                margin: 0 0 24px 0;

                &--datepicker {
                    width: calc(50% - 10px);
                    margin: 0 20px 32px 0;
                }
                &--securityCode {
                    width: calc(50% - 10px);
                    margin: 0 0 32px 0;
                }
            }
        }
    }
}


@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__wrapper {
                & .dln-payMethCreate {
                    padding: 80px 50px 50px 50px !important;
                }
            }
        }
    }
}
@media only screen and (max-width:599px) {
    .dln-countrySelection--modal {
        padding: 20px;

        .dln-popup__head {
            margin-bottom: 20px;

            .dln-popup__headTitle {
                font-size: 35px;
                line-height: 43px;
            }
            button.dln-btnIcon--close {
                height: 20px;
                width: 20px;
            }
        }
    }
}
.dln-timeline {
    display: flex;
    flex-wrap: wrap;
    // width: 100%;
    width: 100%;
    min-width: 654px;
    max-width: 980px;
    // max-width: 100%;
    margin: 40px auto 0 auto ;

    &__container {
        display: flex;
        width: 100%;
        // height: 30px;
        height: auto;
        list-style: none;
        padding: 16px;
        margin: 0 auto;
        box-sizing: border-box;
        position: relative;
    }
    &__label {
        @include fontBold;
        width: 100%;
        color: $ui-02;
        // transform: translateY(24px);
        padding: 38px 0 0px;
        margin: 0 auto;
        pointer-events: none;
        position: relative;
        
        &::before,
        &::after {
            content: '';
            position: absolute;
        }
        &::before {
            content: attr(data-ref);
            width: 30px;
            max-width: 30px;
            height: 30px;
            max-height: 30px;
            line-height: 2.5;
            font-size: 12px;
            color: $brand-03;
            text-align: center;
            border-radius: 50%;
            background: $ui-02;
            // padding: 15px 12px;
            box-sizing: border-box;
            // top: -30px;
            top: 0;
            left: calc(50% - 15px);
        }
        span {
            @include fontBold;
            display: inline-block;
            width: 100%;
            word-wrap: break-word;
        }
    }
    &__step {
        display: inline-flex;
        width: calc(33.333% - 26.67px);
        // min-width: 180px;
        text-align: center;
        // width: auto;
        // width: 140px;
        // padding: 0 40px 0 0;
        margin: 0 40px 0 0;
        // box-sizing: border-box;
        position: relative;

        &:last-of-type {
            margin: 0 0;

            &::after {
                display: none;
            }
        }
        &::before,
        &::after {
            content: '';
            position: absolute;
        }
        &::after {
            // width: calc(100% - 50px);
            width: calc(100% - 13px);
            height: 1px;
            background-color: $ui-02;
            // top: calc(50% - 8px);
            top: 15px;
            // left: calc( 50% + 4px );
            left: calc(50% + 15px + 13px);
            // left: calc(50% + 23px);
        }

        &--active {
            .dln-timeline__label::before {
                background-color: $brand-01;
            }
            .dln-timeline__label {
                color: $brand-04;
            }
        }
    }
}
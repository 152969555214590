.dln-popup__head {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    // background: lightblue;
    padding: 16px 16px 32px 16px;
    padding: 16px 0 32px 0;
    // margin: 0 0 24px 0;
    box-sizing: border-box;
    position: relative;

    .dln-btnIcon {
        margin: 0 0 8px auto;
        width: auto;
        height: auto;
        z-index: 99;
        .dln-btnIcon__wrap {
            width: auto;
            height: auto;
        }
        .dln-icn {
            font-size: 20px;
            color: $brand-01;
        }
    }
    .dln-btnIcon--closeBtn {
        z-index: 99;
        img {
            pointer-events: none;
        }
        .dln-icn {
            font-size: 20px;
        }
    }
    &Title {
        display: block;
        width: 100%;
        max-width: calc(100% - 24px);
        margin: 0 auto;
        height: auto;
        /* line-height: 32px; */
        font-size: 26px;
        @include fontBold;
        text-align: center;
        padding: 0 4px 0 4px;
        .dln-icn {
            width: 38px;
            font-size: 24px;
            margin: 0 8px;
        }
    }
    &--support01 {
        .dln-popup__headTitle {
            .dln-icn {
                color: $support-01;
            }
        }
    }
    &--support02 {
        .dln-popup__headTitle {
            .dln-icn {
                color: $support-02;
            }
        }
    }
    &--bigTitle {
        padding: 16px 0 32px 0;
        & .dln-popup__headTitle {
            /* line-height: 48px; */
            font-size: 44px;
            line-height: normal;
            text-align: left;
            /*  padding: 0 64px 0 64px; */
        }
    }
    &--circleClose {
        align-items: center;
        /*  padding: 20px 28px 20px 40px; */

        & .dln-popup__headTitle {
            width: calc(100% - 48px);
            line-height: 20px;
            font-size: 17px;
            text-align: left;
            padding: 10px 0 10px 0;
            margin: 0 8px 0 0;
            display: flex;
            align-items: center;
        }
        /* & .dln-btnIcon {
            margin: 0 0 6px 0;
        } */
        & .dln-popup__headLine {
            display: block;
            width: 100%;
            height: 1px;
            background-color: $ui-02;
            margin: 6px 0 0 0;
        }
    }
    &--closeOnly {
        /* padding: 16px 16px 16px 16px; */

        & .dln-btnIcon {
            margin: 0 0 0 auto;
        }
    }
}

.dln-alert {
    .dln-btnIcon {
        .dln-icn {
            color: $ui-02;
        }
    }
}
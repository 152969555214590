@media only screen and (max-width:599px) {
    .dln-order {
        &--detail {
            & > .dln-wrapper:first-child {
                padding-top: 20px;
                padding-bottom: 19px;
            }
            & .dln-order__content {
                width: 100%;
                padding: 42px 0 0;
            }
            & .dln-order__detailProducts {
                border-top: none;
                border-bottom: 1px solid $ui-01;
                padding: 0;
                margin-bottom: 36px;

                &--last {
                    border-bottom: none;
                    margin-bottom: 0;
                }
                & .dln-orderHistory {
                    margin-bottom: 40px;

                    &:last-child {
                        margin-bottom: 30px;
                    }
                    & .dln-orderRev {
                        padding: 0;

                        &__details {
                            margin: 0 0 0 70px;
                        }
                        &__detailsTitle {
                            order: 1;
                            margin-bottom: 2px;
                        }
                        & .dln-price {
                            order: 3;
                        }
                        &__productCode {
                            order: 2;
                            margin: 0 0 8px 0;
                        }
                        &__actions {
                            max-width: none;
                            margin-top: 26px;

                            & .dln-btn {
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
            & .dln-order__detailTitle {
                font-size: 18px;
                margin-bottom: 20px;
            }
        }
        & .dln-orderDetailHead {
            margin-bottom: 26px;
        }
        & .dln-order__backToOrderHistory {
            margin-bottom: 31px;
        }
    }
}

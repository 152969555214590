.dln-newUserForm {
    width: 100%;
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
    }
    &__paragraph {
        @include small;
        width: 100%;
        color: $brand-02;
        margin: 0 0 0 0;
    }
    &__section {
        width: 100%;
        margin: 32px 0 0 0;
        &--main {
            margin: 0 0 0 0;
        }
        .dln-btn {
            margin: 20px 0 0;
        }
        .dln-inputs {
            margin: 24px 0 0;
        }
    }
}
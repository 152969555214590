.dln-kenwood {
    & .dln-faq {        
        &__content--noResultsCategory {
            & .dln-wrapper {
                & .dln-faq__breadCrumbSelWrap {
                    & > .dln-faq__text {
                        @include fontSemibold;
                    }
                }
            }
        }
    }
}
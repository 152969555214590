.dln-titleIcon {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 48px;

    &__iconWrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border: 1px solid $support-02;
        border-radius: 50%;
        margin: 0 10px 0 0;
        box-sizing: border-box;
        .dln-icn {
            font-size: 30px;
            line-height: normal;
            color: $support-02;
        }
    }
    &__icon {
        display: block;
    }
    &__title {
        width: calc(100% - 48px - 10px);
        height: auto;
        line-height: 44px;
        font-size: 32px;
        @include fontBold;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-sizing: border-box;
        overflow: hidden;
    }
}

@import "./_title-icon-kenwood";
.dln-savedRec {
    align-content: flex-start;

    & .dln-profileCard {
        margin: 0 0 32px 0;
    }
    &__content {
        & .dln-title {
            margin: 0 0 16px 0;
        }
        .dln-btn {
            max-width: 200px;
            margin: 32px 0 0 auto;

        }
    }
    &__subtitle {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 14px;
        margin: 0 0 16px 0;
    }
    &__section {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
    }
    &__recipeWrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        border-bottom: 1px solid $ui-01;
        padding: 0 0 30px 0;
    }
}
.dln-delonghi {
  .dln-category-browser {
    &__category {
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 28px;

      .dln-categoryBox__title {
        @include fontMedium;
        font-size: REM(19px);
        line-height: 1;
        border-bottom: none;
      }
    }
    &__wrapper {
      margin-bottom: 50px;

      .dln-wrapper {
        max-width: map-get($grid-breakpoints, xl);
        padding-bottom: 0;
      }

      .dln-select {
        &__value {
          @include fontMedium;
          font-size: REM(16px);
          line-height: 1.5;
        }
        &__arrow {
          line-height: 27px;
          font-size: REM(18px);
          width: 20px;
          height: 20px;
          margin-top: 3px;
        }
      }
      .dln-breadCrumbSel {
        &__text {
          font-size: REM(16px);
          line-height: 1.5;
          &--selected {
            color: $brand-04;
          }
        }
        &__arrow {
          line-height: 27px;
          font-size: REM(8px);
          &--selected {
            color: $brand-04;
            top:-2px;
          }
        }
      }
    }
    &__header--text {
      font-size: REM(18px);
      line-height: 1.33;
      color: $brand-04;
    }
  }
}

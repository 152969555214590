.dln-kenwood {
    .dln-socialAcc {
        padding: 0px 40px 70px 40px;

        .dln-popup__head--circleClose {
            padding: 0;
            margin-top: 80px;
            margin-bottom: 25px;
        }

        .dln-popup__headLine {
            display: none;
        }

        .dln-popup__headTitle {
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
        }

        .dln-btnIcon {
            position: absolute;
            top: 20px;
            right: 20px;
            .dln-icn {
                font-size: 22px;
                width: 22px;
                height: 22px;
                text-indent: 999px;
                overflow: hidden;
                @include definePseudoElems;
                &::before {
                    @include fontIcon;
                    content: 'J';
                    text-indent: 0;
                    color: $brand-01;
                }
            }
        }
        &__form {
            max-width: 380px;
            margin: 0 auto;
            .dln-inputs {
                margin: 0 0 35px 0;
            }
            .dln-inputs~.dln-btn[type="button"]:not(.dln-btn--social) {
                margin: 0 0 0;
            }
        }
        &__text {
            font-size: 14px;
            margin: 0 0 25px 0;
            text-align: center;
            &--divider {
                margin: 15px auto;
                &+.dln-inputs {
                    margin: 15px 0 35px 0;
                }
            }
        }
        &__wrap {
            max-width: 380px;
            margin: 30px auto 0;
        }
        @media only screen and (max-width:599px) {
            padding: 0px 20px 50px 20px;
            .dln-popup__headTitle {
                font-size: 28px;
                line-height: 34px;
            }
            &__wrap {
                margin: 20px auto 0;
                .dln-btn {
                    width: 100%;
                    margin: 10px auto;
                }
            }
        }
    }
}
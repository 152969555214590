@media only screen and (max-width:599px) {
    // .dln-popup {
    //     align-content: initial;
    //     min-height: 100vh;

    //     &__head {
    //         align-self: flex-start;
    //     }
    //     &__content {
    //         align-self: center;
    //         margin: 0 0 auto 0;
    //     }
    // }    
    .dln-payMethCreate {                        
        &__title {
            line-height: 20px;
            font-size: 15px;
        }
        & .dln-inputs {
            &--datepicker {
                width: 100%;
                margin: 0 0 24px 0;
            }
            &--securityCode {
                width: 100%;
                margin: 0 0 32px 0;
            }        
        }
        & .dln-btn {
            width: calc(50% - 12px);
            margin: 0 0 48px auto;
        }
        &__foot {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            height: auto;
            border-radius: 0 0 2px 2px;
            padding: 0 0 32px 0;
    
            & .dln-icn {
                line-height: 24px;
                font-size: 18px;
                color: $ui-02;
                margin: 0 4px 0 0;
            }
            &Text {
                height: auto;
                line-height: 20px;
                color: $ui-02;
                padding: 4px 0 0 0;
            }
        }    
    }
}
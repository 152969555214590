[data-screenset-element-id="gigya-forgot-password-screen"],
[data-screenset-element-id="gigya-forgot-password-popup-screen"],
[data-screenset-element-id="gigya-forgot-password-popupHK-screen"] {
  .dlg-gigya-input-text-wrapper {
    margin: 0 0 4px 0;
  }

  .dlg-gigya-button-wrapper {
    input[type='submit'] {
      margin: 0 0 24px 0;
    }
  }

  .dlg-gigya-link {
    margin: 0 auto;
    display: block;
    padding-top: 10px !important;
    color: var(--brand-02);
  }
}

[data-screenset-element-id="gigya-reset-password-success-screen"] {
  .dlg-gigya-button-wrapper {
    input[type='button'] {
      width: 241px;
    }
  }

  .dln-chips--largeIcon .dln-icn--mainIcon {
    color: var(--support-02);
  }
}



@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-vertMenu {
        border: 1px solid $ui-02;
        border-radius: 2px;

        &:not(.dln-vertMenu--open) {
            margin: 0 0 32px;
            & .dln-vertMenu__li {
                padding: 0 16px;
                display: none;
                &--active {
                    display: block;
                }
                &::before {
                    display: none;
                }
                &:not(.dln-vertMenu__li--heading) {
                    border-left: none;
                }
                a {
                    pointer-events: none;
                }
            }
            & .dln-icn {
                transition: none;
            }

        }
        &__container {
            max-height: 50px;
            overflow: hidden;
            padding: 0;
            box-sizing: border-box;
            position: relative;
            & .dln-icn {
                display: inline-block;
                position: absolute;
                right: 4px;
                top: calc(50% - 6.5px);

            }
        }
        &__li {
            &:not(.dln-vertMenu__li--heading) {
                a {
                    padding: 16px 0;
                    display: inline-block;
                }
            }
            &--heading {
                padding: 16px 0;
                display: none;
            }
            &--active {

                border-left: none !important;
            }
        }
        &--open {
            border-color: transparent;
            & .dln-vertMenu__container {
                overflow: auto;
                // height: 100vh;
                height: calc(100vh - var(--headerHeight));
                padding: 40px 32px;
                position: fixed;
                max-height: none;
                left: 0;
                top: var(--headerHeight);
                opacity: 0;
                animation: smoothFadeIn .3s linear forwards;
                background-color: $brand-03;
                z-index: 9;
            }
            & .dln-icn {
                right: 24px;
                top: 24px;
                opacity: 0;
                animation: smoothFadeInAndScale .5s linear forwards .4s;
            }
            & .dln-vertMenu__li--heading {
                display: block;
            }
            & .dln-vertMenu__li {
                a {
                    padding: 16px 0 16px 16px;
                }
            }
        }
    }
}

.dln-kenwood {
    .dln-completeForm {
        padding: 0 40px 70px 40px;
        &__text {
            font-size: 14px;
            line-height: 20px;
            margin: 25px 0 40px;
        }
        &__form {
            padding: 0 40px;
            .dln-inputs {
                margin-top: 0;
                margin-bottom: 0;
                &~.dln-inputs {
                    margin-top: 34px;
                }
            }
            .dln-btn[type="button"] {
                margin: 30px 0 0;
                width: 100%;
            }
            .dln-checkbox {
                margin: 25px 0 30px;
            }
        }
        &__wrap {
            .dln-btn--link {
                font-size: 14px;
                line-height: 20px;
            }
        }
        &__formOptions {
            &+.dln-completeForm__paragraph--secondary {
                @include fontRoman;
                margin-bottom: 0;
                a {
                    color: $brand-01;
                }
            }
        }
        &__paragraph {
            @include fontMedium;
            font-size: 12px;
            line-height: 16px;
            color: $brand-02;
            &--secondary {
                font-size: 12px;
                line-height: 16px;
                color: $brand-02;
            }
        }
        .dln-popup__head {
            padding: 0;
            &+.dln-completeForm__text {
                padding: 0 40px;
                text-align: center;
            }
            .dln-popup__headTitle {
                font-size: 32px;
                line-height: 40px;
                width: 100%;
                margin: 0;
                max-width: 100%;
                text-align: center;
                padding-top: 80px;
                padding-bottom: 0;
                display: block;
            }
            .dln-btnIcon {
                position: absolute;
                top: 20px;
                right: 20px;
                .dln-icn {
                    font-size: 22px;
                    width: 22px;
                    height: 22px;
                    text-indent: 999px;
                    overflow: hidden;
                    @include definePseudoElems;
                    &::before {
                        @include fontIcon;
                        content: 'J';
                        text-indent: 0;
                        color: $brand-01;
                    }
                }
            }
            .dln-popup__headLine {
                display: none;
            }
        }
        @media only screen and (max-width:599px) {
            padding: 0 20px 50px 20px;
            &__text {
                margin: 32px 0 40px;
                text-align: center;
            }
            &__form {
                padding: 0 0;
                .dln-btn[type="button"] {
                    width: 100%;
                }
            }
            &__wrap {
                .dln-btn--link {
                    width: auto;
                    max-width: 50%;
                    margin: 0;
                    text-align: left;
                    font-size: 13px;
                    &:nth-child(odd) {
                        margin: 0 auto 0 0;
                    }
                }
            }
            .dln-popup__head {
                &+.dln-completeForm__text {
                    padding: 0;
                    text-align: center;
                }
                .dln-popup__headTitle {
                    font-size: 28px;
                    line-height: 34px;
                }
                .dln-btnIcon {
                    top: 30px;
                    right: 30px;
                }
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-popup__wrapper > div:first-child {
            & .dln-completeForm {
                &__text {
                    padding: 0;
                }
                &__form {
                    padding: 0;
                }
            }
        }
    }
}
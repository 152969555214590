@media only screen and (max-width:599px) {
    .dln-address--editing {
        .dln-profileCard {
            display: none;
        }
        & .dln-address__aside {
            & .dln-vertMenu {
                margin: 0 0 16px 0;
            }
        }
        & .dln-address__content {
            & .dln-btn--address {
                margin: 0 0 10px 0;
            }
        }
        & .dln-title {
            margin: 0 0 32px 0;
        }
        & .dln-address__section {
            &Head {
                height: 20px;
                margin: 0 0 10px 0;

                &Title {
                    height: 18px;
                    line-height: REM(19px);
                    font-size: REM(15px);
                    padding: 0 0 0 0;
                }
            }
            &:nth-last-of-type(2) {
                margin-bottom: 0 !important;
            }

        }
        & .dln-address__form {
            padding: 0 0 21px 0;
            >.dln-select {
                width: 100%;
                margin: 0 0 20px 0 !important;
            }
            & .dln-inputs {
                width: 100%;
                margin: 0 0 20px 0 !important;

                &:last-of-type {
                    margin: 0 !important;
                }
            }
        }
        & .dln-address__foot {
            & .dln-checkbox {
                width: 100%;
                margin: 0 0 20px 0;

                &:nth-of-type(2) {
                    margin: 0 0 40px 0;
                }
            }
            & .dln-btn--confirm {
                width: calc(50% - 10px);
            }
        }
    }
}
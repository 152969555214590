.dln-writeReview {
    width: 100%;
    // padding: 70px 80px;
    padding: 0 80px 70px 80px;
    .dln-popup__head {

        .dln-btnIcon--close {
            transform: translateX(60px);
        }

        .dln-popup__headTitle {
            text-align: center;
        }
    }

    &__title {
        @include extraBig;
        @include fontBold;
        margin: 0 0 32px;
    }
    &__subTitle {
        @include cta;
        @include fontBold;
        margin: 0 0 16px;
    }
    .dln-orderRev__rating,
    .dln-ratingStars {
        margin: 10px 0;

        .dln-icn {
            width: 35px;
            height: 35px;
            &::before {
                font-size: 35px;
            }

        }
    }
    .dln-orderRev {
        border-width: 0 0 1px 0;
        border-color: $ui-01;
        padding: 0 0 24px;
        margin: 0 0 24px;

        &__itemPic {
            width: 80px;
            height: 64px;

            & > img {
                object-fit: contain;
            }
        }
    }
    &__content {
        width: 100%;
        margin: 32px 0 0;
    }
    &__form {
        .dln-writeReview__subTitle {
            margin: 32px 0 8px;
        }
        &-footer {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            width: 100%;
            border-top: 1px solid $ui-01;
            padding: 32px 0 0;
            margin: 32px 0 0;
        }
        .dln-btn {
            width: calc(100% - 50px);
            max-width: 185px;
            &--link {
                width: auto;
                margin: 0 auto 0 0;
            }
        }
    }
}

.dln-delonghi {
  .dln-miniCart {
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    max-width: 387px;

    &__wrapper {
      padding-top: 27px;
      padding-bottom: 37px;
    }

    &__content {
      padding: 20px 37px 0 37px;
      max-height: none;
      min-height: 0;

      .dln-addItem {
        margin: 0;

        &__title {
          font-size: REM(19px);
        }

        &__price {
          .dln-price {
            font-size: REM(19px);
          }
        }

        &__quantity {
          b {
            font-weight: normal;
          }
        }

        &__picture {
          width: 124px;
          display: flex;
          align-items: stretch;
          height: 103px;
        }

        &--basic {
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;
          padding-bottom: 23px;
        }
      }
    }

    &__mainTitle {
      b {
        font-weight: normal;
      }
    }

    &__footer {
      .dln-orderSumm {
        margin-top: 0;

        .dln-price--free {
          font-weight: normal;
          @include fontRoman;
        }

        .dln-price {
          font-size: REM(19px);
          margin-bottom: 8px;
        }

        .dln-orderSumm__rowTitle,
        .dln-price--freeshippingtarget {
          font-size: REM(14px);
          margin-bottom: 0;
        }
      }

      .dln-orderSumm__contentRow.dln-orderSumm__contentRow--total {
        .dln-orderSumm__rowTitle {
          @include fontMedium;
          text-transform: uppercase;
          font-size: REM(18px);
        }

        .dln-price--total {
          @include fontMedium;
          font-weight: normal;
        }

        span a {
          font-size: REM(14px);
          text-transform: capitalize;
        }
      }
    }

    &__cta {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .dln-btn {
        width: calc(50% - 8px);
        padding: initial 0;
        margin: 0;
        min-width: initial;
        max-width: none;

        &:last-of-type {
          background: $brand-01 !important;
        }
      }
    }

    .dln-price--oldPrice {
      &::after {
        top: 11px;
      }
    }

    &--empty {
      .dln-miniCart {
        &__content {
          min-height: 200px;

          .dln-icn {
            color: $brand-04;
          }
        }

        &__mainTitle {
          @include fontRoman;
          b {
            @include fontMedium;
          }
        }
      }
    }
  }
}

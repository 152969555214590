@media only screen and (max-width:599px) {
    /* .dln-timeline {
        &__container {
            width: 100%;
            height: auto;
            padding: 16px;
            flex-wrap: wrap;
            margin: 0;
        }
        &__label {
            transform: none;
            padding: 0 0 0 40px;
            margin: 0;
            &::before {
                left: 0;
                top: calc(50% - 16px);
            }
        }
        &__step {
            width: 100%;
            padding: 30px 0;
            margin: 0;
            &:last-child {
                padding: 30px 0;
                margin: 0;
            }
            &::after {
                height: calc(100% - 50px);
                width: 1px;
                background-color: $ui-02;
                top: 60px;
                left: 15px;
            }
        }
    } */
    .dln-timeline {
        min-width: initial;
        
        &__container {
            width: 100%;
            padding: 0;
        }
        &__label {
            span {
                opacity: 0;
                font-size: 12px;
            }
            &::before {
                left: 0;
                opacity: 1;
            }
        }
        &__step {
            width: 33.333%;
            padding: 0;
            margin: 0 40px 0 0;
            min-width: initial;

            &::after {
                width: calc(100% - 30px);
                left: 44px;
                z-index: 9;
            }
            &:nth-of-type(1) {
                margin: 0;
                /* width: auto; */
                .dln-timeline__label {
                    margin: 0 auto 0 0;
                    text-align: left;
                }
            }
            &:nth-of-type(2) {
                text-align: center;
                margin: 0 auto;
                .dln-timeline__label {
                    margin: 0 auto 0;
                    &::before {
                        left: calc(50% - 15px);
                        right: auto;
                    }
                }
                &::after {
                    left: calc(50% + 33px);
                }
            }
            &:last-of-type {
                margin: 0 0;
                /*  width: auto; */
                text-align: right;
                .dln-timeline__label {
                    margin: 0 0 0 auto;
                    &::before {
                        left: auto;
                        right: 0;
                    }
                }
            }
            &--active {
                .dln-timeline__label {
                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
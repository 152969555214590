.dln-kenwood {
    & .dln-timeline {
        &__container {
            height: 51px;
        }
        &__step {
            position: relative;
            z-index: 1;

            &::after {
                // left: 95px;
                width: 100%;
                height: 0 !important;
                border-top: 2px dashed $ui-02;
                background: none;
                background-color: transparent;
                left: 50%;
                z-index: 2;
            }
            & .dln-timeline__label {
                line-height: 20px;
                font-size: 14px;
                padding-top: 42px;
                position: relative;
                z-index: 3;
                color: $ui-02;

                &::before {
                    @include fontSemibold;
                    top: -10px;
                    width: 35px;
                    max-width: none;
                    height: 35px;
                    max-height: none;
                    font-size: 16px;
                    line-height: 35px;
                    font-weight: bold;
                    border: 8px solid $brand-03;
                    background-color: $ui-02;
                    box-sizing: content-box;
                    left: calc(50% - 26px);
                }
            }
            &--completed {
                & .dln-timeline__label {
                    color: $support-02;

                    &::before {
                        @include fontIcon;
                        content: 'H';
                        line-height: 2.5;
                        font-size: 14px;
                        font-weight: lighter;
                        border: 8px solid $brand-03;
                        background-color: $support-02;
                    }
                }
            }
            &--active {
                & .dln-timeline__label {
                    color: $brand-01;

                    &::before {
                        border: 8px solid #f1bacd;
                        background-color: $brand-01;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-timeline {
            &__step {
                &::after {
                    width: 100% !important;
                    left: 50% !important;
                }
                & .dln-timeline__label {
                    width: 100%;
                    margin: 0;
                    padding: 44px 20px 0;
                    &::before {}
                }
            }
        }
    }
}
.dln-headCheckout {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: $brand-01;

    & .dln-wrapper {
        align-items: flex-end;
    }
    &__logo {
        display: inline-flex;
        width: auto;
        height: auto;
        margin: 0 0 20px 0;
        cursor: pointer;

        font-size: 35px;

        &Icon {
            display: block;
            width: 85px;
            height: 35px;
        }
    }
}
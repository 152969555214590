#drop-in-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: 30px;

  #drop-in-inner-wrapper {
    position: relative;
  }

  #drop-in-container {
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in;

    &.--loaded {
      opacity: 1;
    }
  }
}

.skel-pulse {
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #efefef 0%, #fcfcfc 50%, #efefef 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
}

@media only screen and (max-width:599px) {
    .dln-quickLinks--slider {
        & .dln-wrapper {
            width: calc(100% - 20px) !important;

            // .dln-carousel.swiper-container .swiper-wrapper .swiper-slide {
            //     width: calc(100vw / 2 - 30px);
            // }
        }
        & .dln-quickLinks {
            &__title {
                font-size: 15px;
                margin-bottom: 4px;
                //text-align: center;
            }
            &__link {
                width: auto;
                //margin: 0 auto;
            }
        }
    }
}
.gigya-link-account-screen {

  .gigya-style-modern {
		width:100%;
  }

  .dln-popup__headTitle {
	line-height: 20px;
	font-size: 17px;
	text-align: left;
	padding: 26px 0 10px 0;
	margin: 0;
	width: 100%;
	max-width: 100%;
  }

  .dln-socialAcc__text {
	font-size:13px;
  }

  .gigya-layout-cell.with-site-login .gigya-layout-row:nth-child(2) {
	display:flex;
	justify-content:space-between;
	margin-top:24px;

	.gigya-layout-cell {
	  width:49%;

	  &:nth-child(2) {
		text-align:right;
	  }
	}

  }
}

.dln-delonghi {
  .dln-payCardBox {
    &__content {
      padding: 14px 20px 24px 20px;
    }

    &__cardInfo {
      @include fontMedium;
      font-size: REM(14px);
      line-height: 16px;
      padding: 0;
    }
    &__field {
      margin: 0;

      &Label {
        @include fontBook;
        font-size: REM(13px);
        line-height: 16px;
        color: $ui-02;
      }
      &Data {
        @include fontBook;
        font-size: REM(14px);
        line-height: 18px;
      }

      & + .dln-payCardBox__field {
        margin-top: 9px;
      }
    }
    &__foot {
      margin-top: 16px;

      .dln-checkbox {
        margin-bottom: 12px;
      }
    }
  }
}

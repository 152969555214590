@media only screen and (max-width: 599px) {
    .dln-site-search {
        .dln-wrapper {
            padding: 20px 20px 0 20px;
        }

        &.dln-page .dln-title {
            margin: 4px 0 0 0;
            text-align: left;
            font-size: 24px;
            padding-bottom: 15px;
        }

        &__results {
            padding-bottom: 34px;
        }

        &__no-results-title {
            font-size: 24px;
            line-height: 1.13;
        }

        &__no-results-paragraph {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
        }

        .dln-inputs {
            width: 100%;
        }

        .dln-tabs {
            // margin-bottom: 34px;

            &__btn {
                font-size: 15px;
                line-height: 21px;
            }
        }

        .dln-filters-bar {
            padding-bottom: 0;
        }

        .simple-list {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
        }

        &__products {
            & .bra-prodTile {
                width: calc(50% - 5px);
                margin: 0 10px 10px 0;

                &:nth-of-type(4n+4) {
                    margin: 0 0 10px 0
                }
                &:nth-of-type(3n+3) {
                    margin: 0 10px 10px 0
                }
                &:nth-of-type(2n+2) {
                    margin: 0 0 10px 0
                }
            }
        }
        & .bra-btnView {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 0 0 auto;
        }
    }
}

@media only screen and (max-width:599px) {
    .swiper-slide {
        & .dln-wishProd {
            &__name {
                min-height: auto;
            }
            & button {
                margin: 0 auto 0;
            }
        }
    }
    .dln-wishProd {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 100%;
        position: relative;

        &__imgLink {
            width: 100%;
            height: 146px;
            margin: 0;
        }
        &__imgWrap {
            display: flex;
            align-items: center;
            width: 100%;
            height: 146px;
            background-color: $ui-03;

            & > img {
                display: block;
                max-width: 182px;
                height: auto;
                padding: 0;
                margin: 0 auto;
                object-fit: initial;
            }
        }
        &__data {
            flex: 1;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            margin: 8px 0 0;
        }
        &__name {
            font-size: 13px;
            border: 0;
            padding: 0;
        }
        &__price,
        &__priceDiscounted {
            font-size: 13px;
        }
        &__priceWrap {
            margin-bottom: 10px;
        }
        & .dln-btnTextIcon,
        & .dln-btn--stroked {
            font-size: 13px;

            margin-top: auto;

            .dln-btnTextIcon__text {
                font-size: 13px;
            }
        }
        & .dln-btn--link {

            @include definePseudoElems;
            text-indent: 9999px;

            width: 30px;
            height: 30px;
            color: rgba(white,0);
            border-radius: 150px;
            background-color: rgba(white,.7);
            padding: 0;
            margin: 0 auto 0 0;
            margin: 0;
            overflow: hidden;

            position: absolute;
            top: 10px;
            right: 10px;

            &::before {
                @include fontIcon;
                text-indent: 0;
                content: 'J';
                width: 100%;
                height: 100%;
                font-size: 30px;
                color: $brand-02;
            }
        }
        & .dln-btnTextIcon {
            margin-top: auto;
        }

    }
}

.dln-country-stripe {
  display: none;
  height: auto;
  position: fixed;
  width: 100%;
  z-index: 9999;
  background: $ui-03;
  padding: 16px 30px;
  top: 0;

  + .dln-header {
    top: 70px;

    & + .dln-navigation {
      top: 70px;
    }
  }

  &.visible {
    display: block;
  }

  &.hide {
    display: none;

    & + .dln-header {
      top: 0;

      & + .dln-navigation {
        top: 0;
      }
    }
  }

  .dln-country-stripe__text {
    width: calc(100% - 32px - 424px - 88px);
    line-height: 21px;
    font-size: 13px;
    margin: 0 32px 0 0;

    & b {
      display: block;
    }
  }

  .dln-country-stripe__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
  }

  .dln-icn__legacy-close:before {
    font-size: 14px;
    color: $brand-02;
  }

  .dln-country-stripe__buttons {
    display: flex;
    flex-wrap: nowrap;
    width: 424px;
    margin: 0 88px 0 0;

    .dln-btn {
      display: block;
      width: 50%;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 16px;
      margin: 0 20px 0 0;
      box-sizing: border-box;
      overflow: hidden;
      min-width: 162px;

      .dln-btn--inverted {
        border: 1px solid #000;
      }

      &:last-child {
        margin: 0 0 0 0;
      }
    }
  }
}

.dln-kenwood {
    & .dln-site-search {
        & > .dln-wrapper {
            &:first-child {
                max-width: none;
                padding: 60px 0 0 0;
            }
            & .dln-title {
                padding-bottom: 0;
                margin-bottom: 31px;

                & + .dln-inputs.dln-inputs--searchBar {
                    width: 100%;
                    max-width: 591px;
                    height: 45px;
                    border: 1px solid $brand-02;
                    margin: 0 auto 4px auto;

                    & .dln-inputs__lbl {
                        display: none;
                    }
                    & .dln-inputs__button {
                        right: 13px;

                        & > .dln-icn {
                            width: 17px;
                            height: 17px;
                            font-size: 0;

                            &::before {
                                @include fontIcon;
                                content: 'Q';
                                width: 17px;
                                height: 17px;
                                font-size: 17px;
                            }
                        }
                    }
                }
            }
            & .dln-site-search__results {
                line-height: 20px;
                font-size: 14px;
                text-align: center;
                padding: 0 0 40px 0;
            }
        }
        & .dln-tabs {
            justify-content: center;
            height: 50px;
            border: 1px solid $ui-01;
            border-width: 1px 0 1px 0;
            margin-bottom: 0;

            &::before {
                display: none;
            }
            & .dln-tabs__list {
                max-width: 1300px;
                height: 48px;
                border-bottom: none;
                padding: 0 calc((100% - 1300px) / 2);

                & .dln-tabs__item {
                    height: 48px;
                    padding: 0 40px;
                    margin: 0;

                    & .dln-tabs__btn {
                        height: 48px;
                        font-size: 16px;
                    }

                    &--current {
                        &::after {
                            display: none;
                        }
                    }
                }
            }
            &__currentLine {
                height: 3px;
                bottom: 0;
            }
        }
        & .dln-filters-bar {
            min-height: 50px;
            height: auto;
            border-bottom: 1px solid $ui-01;
            margin-bottom: 0;
            // max-width: 1300px;

            // & * {
            //     background: rgba(0,0,0,.1);
            //     &:nth-child(odd) {
                //         background: rgba(0,0,0,.07);
            //     }
            // }
            &__filters-wrap {
                min-height: 49px;
                height: auto;
            }
            &__desktop {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                max-width: 1300px;
                min-height: 49px;
                height: auto;
                // padding: 0 calc((100% - 1300px) / 2);
                margin: 0 auto;
                // box-sizing: content-box;
                box-sizing: border-box;
                padding-top: 10px;

                & .section-label {
                    line-height: 24px;
                    font-size: 14px;
                    color: $brand-02;
                    padding-right: 0;
                    margin-right: 8px;
                }
                & .dln-filterDropdown {
                    height: 24px;
                    border: 1px solid $ui-01;
                    border-width: 0 1px 0 0;
                    padding: 0 20px;
                    margin: 0 0 10px 0;

                    &:last-child {
                        border: none;
                    }
                    &__triggerText {
                        margin: 0 12px 0 0;
                    }
                    & .dln-icn {
                        width: 12px;
                        height: 8px;
                        line-height: 8px;
                        font-size: 12px;
                    }
                }
            }
        }
        & .dln-site-search__products {
            justify-content: center;
            background-color: $ui-03;
            padding: 30px 0 80px 0;

            & .bra-prodTilesGrid {
                max-width: 1300px;
                padding: 0 calc((100% - 1300px) / 2);

                & .bra-prodTile {
                    background-color: $brand-03;
                }
            }
        }
        .dln-tabs__item {
          &--current {
            & .dln-tabs__btn {
                @include fontBold;

                & .--amount,
                & .--amount i {
                    @include fontBold;
                }
            }
          }
        }
    }

    /* recipes */
    & .dln-recipe-grid {
        width: 100%;
        max-width: 1300px;
        padding: 0 20px;
        padding-top: 30px;
        margin: 0 auto;
    }
    /* editorial */
    & .dln-category-list {
        width: 100%;
        max-width: 1300px;
        padding: 10px 20px 0;
        margin: 0 auto;
    }
    /* faqs */
    & .dln-list--faqs {
        max-width: 1300px;
        padding: 30px 20px 80px 20px;
        margin: 0 auto;
        box-sizing: border-box;

        & .dln-listItem {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
            & .dln-icn {
                margin-right: 12px;
            }
            & .dln-listLink {
                @include fontBold;
            }
        }
    }
    /* manuals */
    & .dln-manuals-list {
        width: 100%;
        max-width: 1300px;
        padding: 15px 50% calc(80px - 15px) 20px;
        margin: 0 auto;
        box-sizing: border-box;

        &__item {
            border-bottom: 1px solid $ui-01;
            padding: 15px 0;
            margin-bottom: 0;

            &:last-child {
                border-bottom: none;
            }
        }
        &__wrapText {
            border: none;
            padding: 10px 0 0 0;
        }
    }
    /* no-results */
    & .dln-site-search__no-results-title,
    & .dln-site-search__no-results-paragraph,
    & .dln-inputs--searchBar,
    & .simple-list {
        width: 100%;
        max-width: 1300px;
        padding-right: 20px;
        padding-left: 20px;
        margin: 0 auto;
        box-sizing: border-box;
    }
    & .dln-site-search__no-results-title {
        @include fontExtra;
        line-height: 46px;
        font-size: 38px;
        text-align: center;
        padding-bottom: 0;
        margin-bottom: 20px;
    }
    & .dln-site-search__no-results-paragraph {
        line-height: 20px;
        font-size: 14px;
        text-align: center;
    }
    & .dln-inputs--searchBar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 591px;
        border: none;
        border: 1px solid $brand-02;
        padding: 0;
        position: relative;
        z-index: 13;

        & .dln-inputs__lbl {
            display: none;
        }
        & .dln-inputs__input {
            width: 100%;
            // border-color: $brand-02;
            border: none;
            padding: 0 51px 0 12px;
            margin: 0 auto;
        }
    }
    & .simple-list {
        max-width: 591px;
        padding: 0 calc((100% - 591px) / 2);
        padding-top: 40px;
        padding-bottom: 163px;
        box-sizing: content-box;

        &__title {
            line-height: 20px;
            font-size: 14px;
            color: $ui-10;
            margin-bottom: 10px;
        }
        &__lists {
            margin-top: 0;
        }
        &__item {
            line-height: 20px;
            padding-left: 20px;
            margin: 0;
        }
    }
}

@media only screen and (max-width:1340px) {
    .dln-kenwood {
        & .dln-site-search {
            & .dln-tabs {
                & .dln-tabs__list {
                    width: 100%;
                    max-width: none;
                    padding: 0 20px;
                    box-sizing: border-box;
                }
            }
            & .dln-filters-bar {
                &__desktop {
                    max-width: none;
                    padding: 10px 20px 0;
                }
            }
            & .dln-site-search__products {

                & .bra-prodTilesGrid {
                    max-width: none;
                    padding: 0 20px;
                }
            }
        }
    }
}

@media only screen and (max-width:map-get($grid-breakpoints, md)) {
    .dln-kenwood {
        & .dln-site-search {
            & .dln-filters-bar {
                &__desktop {
                    display: none;
                }
                &__mobile {
                    display: flex;

                    & .dln-btnTextIcon {
                        height: 49px;
                    }
                }
            }
            /* manuals */
            & .dln-manuals-list {
                padding: 15px 20px calc(80px - 15px) 20px;
            }
        }
    }
}

@media only screen and (max-width: 599px) {
    .dln-kenwood {
        & .dln-site-search {
            & > .dln-wrapper:first-child {
                padding-top: 37px;

                & .dln-title {
                    margin-top: 0;

                    & + .dln-inputs.dln-inputs--searchBar {
                        width: calc(100% - 40px);
                        max-width: none;
                    }
                }
            }
            & .dln-tabs {
                &__gradient {
                    width: 48px;
                    height: 48px;
                }
                & .dln-tabs__list {
                    & .dln-tabs__item {
                        padding: 0;
                        margin: 0 40px 0 0;

                        &::after {
                            display: none;
                        }
                        &:last-child {
                            margin: 0;
                        }
                        & .dln-tabs__btn {
                            border: none;
                        }
                        &--current {
                            .dln-tabs__btn {
                                & .dln-tabs__btnAmount {
                                    @include fontBold;
                                }
                            }
                        }
                    }
                    & .dln-tabs__currentLine {
                        height: 3px;
                    }
                }
            }
            & .dln-filters-bar {
                &__mobile {
                    // & .dln-btnTextIcon {
                    //     & .bra-icn {
                    //         @include fontIcon;

                    //         &::before {
                    //             @include fontIcon;
                    //         }
                    //     }
                    // }
                }
            }

            & .dln-category-list {
                margin-bottom: 70px;
            }
            /* faqs */
            & .dln-list--faqs {
                padding-bottom: 70px;
            }
            /* manuals */
            & .dln-manuals-list {
                &__wrapText {
                    padding: 0;
                }
            }
            /* no results */
            & .dln-site-search__no-results-title {
                line-height: 40px;
                font-size: 32px;
            }
            & .dln-inputs--searchBar {
                width: calc(100% - 40px);
                max-width: none;
            }
            & .simple-list {
                width: calc(100% - 40px);
                max-width: none;
                padding: 40px 20px 70px 20px;
            }
        }
    }
}

.dln-foot--delonghi {
  display: none;
}
.dln-delonghi {
  .bra-footer {
    display: none;
  }
  .dln-foot:not(.dln-foot--delonghi) {
    display: none;
  }

  /*====================================================

        ASPETTANDO GRAFICA DEFINITIVA

    */

  .dln-foot--delonghi {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: REM(37px);
    line-height: 1;
    letter-spacing: -0.7px;

    height: 80px;

    border-top: 1px solid #e5e5e5;
  }

  /*====================================================*/
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-column-center {
    @include flex-center;
    flex-direction: column;
}

@mixin flex-basis-100 {
    flex-basis: 100%;
}

.dln-prodReg__userDetails {
    max-width: 25vw;
    margin: 30px auto;
    @include flex-center;
    flex-direction: column;
    .dln-prodReg_form_logged {
        @include flex-column-center;
    }

    .dln-prodReg_form_logged-row,
    .dln-prodReg_form_logged_changeAcc {
        @include flex-center;        
        a {
            all: unset;
            text-decoration: underline;
            color: black;
            font-weight: 700;
            cursor: pointer;
        }

        &.dln-prodReg_form_logged-row {
            line-height: 40px;
            .dln-prodReg__label_userInfo {
                @include flex-basis-100;
                white-space: nowrap;
                margin-right: 100px;
            }

            .dln-prodReg_userInfo {
                @include flex-basis-100;
                font-weight: 700;
            }
        }

        &.dln-prodReg_form_logged_changeAcc {
            line-height: 30px;
            max-width: 80%;
            margin: 30px auto;
            text-align: center;
        }
    }

    .dln-prodReg_form_noLogin {
        text-align: center;
        line-height: 30px;

        .gigya-custom-checkbox {
            width: auto;
            text-align: left;
            line-height: 15px;
            .gigya-composite-control-checkbox {
                position: unset;
            }
            .gigya-input-checkbox {
                width: unset;
                height: unset;
            }
            ::before {
                box-sizing: unset ;
            }
            .js-toggle-info {
                color: var(--brand-02);
            }
        }
        .dln-registrationForm__paragraph--secondary {
            text-align: justify;
        }

        .dln-prodReg_loginCTA {
            all: unset;
            text-decoration: underline;
            color: black;
            font-weight: 700;
            cursor: pointer;
        }

        .separator {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 40px -10%;
        }

        .separator::before,
        .separator::after {
            content: '';
            flex: 1;
            border-bottom: 1px solid #000;
        }

        .separator:not(:empty)::before {
            margin-right: 0.50em;
        }

        .separator:not(:empty)::after {
            margin-left: 0.50em;
        }
    }

    .dln-btn--goBack{
        align-self: flex-start;
        margin-top: 60px;
        margin: 60px 0 0 -10%;
    }
}

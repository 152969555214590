.dln-delonghi {
  .dln-customerSupport {
    & .dln-wrapper {
      > div {
        max-width: calc(#{map-get($grid-breakpoints, xl)} - 80px);
      }
    }

    &__content {
      margin: auto;
    }

    &__head {
      &Title {
        @include fontMedium;
        font-size: REM(50px);
        line-height: 1.04;
      }
      &.dln-wrapper {
        @media only screen and (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl)) {
          & {
            margin: 0;
            padding-left: 40px;
            padding-right: 40px;
          }
        }
      }
    }
    &__title,
    &__searchTitle {
      @include fontMedium;
      font-size: REM(28px) !important;
      line-height: 1.21;
      margin-top: 15px;
    }
    &__subtitle {
      @include fontMedium;
      font-size: REM(21px);
      line-height: 1.12;
    }

    .dln-customerSupport__searchWrapper,
    .dln-wrapper--content {
      max-width: none;

      .dln-customerSupport__inner {
        margin: 0 auto;
      }

      & .dln-inputs--searchBar {
        .dln-inputs__lbl {
          margin-left: 0;
        }
      }
    }

    .dln-customerSupport__searchWrap {
      background: $ui-03;
      .dln-customerSupport__inner {
        padding-top: 0;
        padding-bottom: 25px;
      }
    }

    .dln-list .dln-listItem {
      .dln-listLink,
      .dln-icn {
        font-size: REM(14px);
        line-height: 1.43;
        font-weight: 500;
      }
    }

    .dln-customerSupport__contentWrap {
      .dln-customerSupport__inner {
        padding-top: 65px;
        padding-bottom: 25px;
      }

      .dln-listItem .dln-icn {
        color: $brand-04;
      }
    }

    .dln-quickLinks--slider .dln-wrapper {
      max-width: none;
      width: calc(100% - 40px);

      .dln-quickLinks__inner {
        margin: 0 auto;
      }
      .dln-carousel.swiper-container .swiper-slide:not(.hide-items) {
        width: calc(20% - 40px);
      }
      .dln-quickLinks__title {
        @include fontMedium;
        font-size: REM(28px);
        line-height: 1.21;
        text-align: center;
        margin-bottom: 0;
      }

      .dln-quickLink {
        text-align: center;
        border-bottom: none;

        &__title {
          @include fontMedium;
          color: $brand-04;
          font-size: REM(18px);
          line-height: 1.11;
        }
        &__iconWrap {
          border-color: transparent;
          .dln-icn {
            color: $brand-04;
          }
        }
      }
    }

    @media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg)) {
      .dln-quickLinks--slider .dln-wrapper {
        width: 100%;
        .dln-carousel.swiper-container .swiper-slide {
          margin: 0;
        }
      }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, sm)) {
      &__head {
        &Title {
          font-size: REM(37px);
          line-height: 1.08;
        }
      }
      &__title,
      &__searchTitle {
        font-size: REM(21px) !important;
        line-height: 1.1;
      }

      .dln-list + .dln-btn {
        margin: 0;
      }

      .dln-quickLinks--slider .dln-wrapper {
        width: 100%;

        .dln-quickLinks__title {
          font-size: REM(21px);
          line-height: 1.1;
        }
      }
    }
  }
}

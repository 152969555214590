@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-savedCart {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 32px 0;
        }
    }

}
@media only screen and (max-width:599px) {
    .dln-instruction-manuals {
        padding-top: 0;
        padding-bottom: 90px;
        & .dln-chips {
            & .dln-icn--mainIcon {
                margin: 0 20px 0 0;
            }
            &__wrapper {
                align-items: flex-start;
                padding-top: 16px;
            }
            &__content {
                width: calc(100% - 70px);
                padding: 0 8px 0 0;
            }
        }
        & .dln-inputs--searchBar {
            width: 100%;
            // margin-bottom: 50px;
        }
        & .dln-btn--link {
            padding-top: 4px;
        }
        &__mainTitle {
            font-size: 35px;
            line-height: 43px;
            margin: 32px 0 20px 0;
        }
        & .dln-instruction-manuals__mainSubtitle {
            line-height: 21px;
            margin: 0 0 20px 0;
        }
        &__content {
            padding-top: 48px;
            margin-bottom: 58px;
            & .dln-wrapper {
                width: calc(100% - 20px);
            }
        }
        &__questionList {
            margin-top: 8px;
            margin-bottom: 32px;
        }
        &__questionItem {
            align-items: flex-start;
        }
        &__questionLink {
            font-size: 14px;
            line-height: 20px;
        }
        & .dln-category-browser__category {
            & .dln-categoryBox {
                &__imgWrap {
                    height: 132px;
                    width: auto;
                    object-fit: contain;
                }
            }
        }

        &__title {
            font-size: 15px;
            margin-bottom: 0;
            &--related {
                font-size: 22px;
            }
        }
        &__mainSubtitle {
            font-size: 15px;
        }
        &__videoContainer {
            width: 100%;
            height: 0;
            background-color: $brand-01;
            padding-bottom: 56.25%;
            margin: 0 0 48px 0;
            position: relative;
        }
        &__video {
            display: block;
            width: 100%;
            max-width: none;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &__quickLinks {
            & .dln-wrapper {
                width: calc(100% - 20px);
            }
            & .dln-instruction-manuals__title {
                margin-bottom: 4px;
            }
        }
        &__inner {
            margin-right: 10px;
            margin-left: 10px;
            padding-right: 10px;
            padding-left: 10px;
        }
        &__breadCrumbSelWrap {
            margin: 0 0 8px 0;
        }

        .dln-breadcrumbs {
            display: none;
        }
        .dln-instruction-manuals__main {
            .dln-chips {
                margin: 0;
                padding-right: 0;
                padding-left: 0;
            }
            >.dln-wrapper--fullWidth {
                background-color: $ui-03;
                margin-bottom: 0;
            }
        }
        &__list {
            width: 100%;
        }
        &__helpSection {
            margin-bottom: 58px;
            &Title {
                width: 100%;
                padding-right: 0;
            }
        }
        &__spellCheck {
            font-size: 14px;
            margin-bottom: 18px;
        }
        &__searchByCategory {
            font-size: 14px;
            margin-bottom: 10px;
        }
        &__product {
            flex-wrap: wrap;
            margin-top: 24px;
            &Image {
                width: 100%;
                margin: 0 0 25px 0;
            }
            .dln-instruction-manuals__mainSubtitle {
                font-size: 21px;
                margin-bottom: 16px;
            }
        }
    }
}

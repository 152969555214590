.dln-singProductReview {
    // padding: 70px 80px;
    padding: 0 80px 70px 80px;
    .dln-popup__head {
        .dln-btnIcon--close {
            transform: translateX(60px);
        }
    }
    & .dln-orderRev {
        padding: 0 0 16px;
        border: none;

        &:hover {
            border: none;
        }
        &__details {
            border: none;
        }
        &__content {
            align-content: center;
            max-width: calc(100% - 140px - 32px);
        }
        &__detailsTitle {
            @include fontBold;
            height: auto;
            white-space: normal;
            text-align: left;
        }
        &__itemPic {
            & > img {
                object-fit: contain;
            }
        }
    }
    &__title {
        font-size: 32px;
        line-height: 35px;
        color: $brand-04;
        @include fontBold;
        margin: 0 0 0;
    }
    &__content {
        width: 100%;
        border-top: 1px solid $ui-01;
        padding: 24px 0 0;
    }
    &__detailsTitle {
        font-size: 15px;
        line-height: 20px;
        color: $brand-04;
        @include fontBold;
        margin: 40px 0 8px;
    }
    &__desc {
        font-size: 14px;
        line-height: 20px;
        color: $brand-04;
    }
    .dln-orderRev__rating,
    .dln-ratingStars {
        margin: 0 0;
    }
}
@mixin gigya-dln-btn {
  outline: none;
  width: auto;
  line-height: normal;
  font-size: 13px;
  font-weight: bold;
  color: var(--brand-03);
  text-align: center;
  border: none;
  background-color: var(--brand-01);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  padding: 10px 8px 10px 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

@mixin gigya-dln-btn-not-link {
  width: 100%;
  height: 40px;
  line-height: 17px;
  padding-top: 13px;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 2px;
  overflow: hidden;
}

@mixin gigya-dln-btn-link {
  font-weight: 400;
  background-color: transparent;
  padding: 0 !important;
  color: var(--brand-04);
  text-decoration: underline;
}

@mixin gigya-label {
  line-height: 150%;
  font: inherit;
  display: inline-block;
  color: var(--brand-02);
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 0 4px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: calc(50% - 21px);
  left: 8px;
  z-index: 1;

  &.gigya-error {
    color: var(--support-01) !important;
  }
}

@mixin dlg-gigya-input-text-wrapper {
  font-size: 13px;
  line-height: 150%;
  display: inline-block;
  width: 100%;
  text-align: left;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;

  .gigya-label {
    @include gigya-label;
  }

  &.dlg-gigya-input--active {
    .gigya-label {
      background-color: var(--brand-03);
      transform: translateY(calc(50% - 29px)) scale(0.75);
    }
  }

  input {
    border-radius: 2px !important;
    border: 1px solid var(--ui-01) !important;
    color: var(--brand-04) !important;
    font-size: 13px;
    outline: 0;
    width: 100%;
    line-height: 16px;
    background-color: transparent !important;
    padding: 14px 8px 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    appearance: none;

    &:focus {
      border-color: var(--ui-02) !important;
    }

    &.gigya-error {
      padding: 14px 8px 8px !important;
      border-radius: 0 !important;
      border: 1px solid var(--support-01) !important;
    }

    &.gigya-valid {
      padding-right: 40px !important;
      background-image: url('#{$pathAssets}/images/icons/icon-valid.svg') !important;
      background-position-x: 100%;
      background-position-y: 50%;
      background-position: right 16px center;
      background-repeat: no-repeat;
    }
  }

  .gigya-error-msg {
    min-height: 24px !important;
    color: var(--support-01) !important;
    text-align: left;
  }
}

@media only screen and (max-width:599px) {
    .dln-editOrdHead {
        &__title {
            line-height: 27px;
            font-size: 24px;
            margin-bottom: 16px;
        }
        &__data {
            font-size: 15px;
            line-height: 18px;
            margin-right: 42px;

            &:last-of-type {
                margin-right: 0;
            }
        }
        &__text {
            font-size: 13px;
            line-height: 20px;
            margin: 0;
        }
    }
}

.dln-delonghi {
  .dln-vertMenu {
    &__list {
      margin-bottom: 40px;
    }

    &__li {
      padding: 10px 16px 10px 0;
      margin: 0;
      border: 0 !important;

      font-size: 0;
      line-height: 0;

      a {
        padding: 0;
        display: inline-block;
        position: relative;
        @include fontBook;
        font-size: REM(18px);
        line-height: 24px;
      }

      &::before {
        display: none;
        content: none;
      }
      &--heading {
        padding: 0;
      }
      &Heading {
        padding: 10px 16px 10px 0;

        color: $brand-04;
        opacity: 0.6;
        @include fontMedium;
        font-size: REM(18px);
        line-height: 24px;

        &:empty {
          display: none;
        }
      }

      &--active {
        a {
          &::after {
            content: '';

            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            width: 100%;
            height: 1px;

            background-color: $brand-04;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, md)) {
  .dln-delonghi {
    .dln-vertMenu {
      border: 1px solid $brand-04;
      margin-bottom: 0 !important;

      &:not(.dln-vertMenu--open) {
        .dln-vertMenu__container {
          max-height: 40px;
        }
        .dln-vertMenu__li {
          padding: 13px 16px;

          a {
            &:after {
              content: none;
            }
          }
        }
        & .dln-icn {
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          right: 12px;
        }
      }

      &__li {
        transition: none;
        a {
          @include fontBook;
          font-size: REM(18px);
          line-height: 16px;
          transition: none;
        }
      }
    }
  }
}

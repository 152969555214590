.dln-kenwood {
    .dln-recipe-by-ingredients {
        margin-top: 0;

        &__title {
            text-align: center;
            font-size: 24px;
        }

        &__search-section {
            display: flex;
            flex-direction: column;
        }

        &__load-more {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            p {
                text-align: center;
                b {
                    @include fontRoman;

                    font-weight: normal;
                }
            }

            .dln-btn {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .dln-recipe-slides {
            margin-top: 50px;
        }
        .dln-inputs.dln-inputs--searchBar.dln-inputs--rightIcon {
            max-width: 387px;
            margin: 0 auto 49px auto;

            .dln-inputs__lbl {
                position: absolute;
                left: 10px;
                top: 13px;
            }

            .dln-inputs__input {
                padding-left: 9px;
                padding-top: 0;
            }
        }

        .dln-wrapper {
            max-width: unset;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > div,
            > h1 {
                width: 100%;
                max-width: 1240px;
                padding: 0 30px;
            }
            > h1,
            .dln-breadcrumbs,
            .dln-recipe-by-ingredients__search-section {
                margin: 0;
                background: $ui-03;
                padding: 0 calc((100% - 1180px) / 2);
                max-width: none;
            }

            .dln-breadcrumbs {
                padding-top: 15px;
                padding-bottom: 30px;
            }

            > h1 {
                padding-bottom: 99px;
                text-align: center;
            }

            .dln-filters-bar {
                margin-top: 0;
                padding: 0;
                max-width: none;

                &__desktop {
                    margin: 0;
                    padding: 16px calc((100% - 1180px) / 2);
                    padding-bottom: 0;

                    max-width: none;

                    border-bottom: 1px solid $ui-03;
                }

                &__applied-filters,
                &__sort-section {
                    padding: 0 calc((100% - 1180px) / 2);
                    max-width: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .dln-kenwood {
        .dln-recipe-by-ingredients {
            &__title {
                text-align: center;
                font-size: 20px;
            }
            .dln-wrapper {
                .dln-breadcrumbs {
                    padding-right: 0;
                }
            }

            .dln-recipe-slides {
                margin-top: 30px;
            }
        }
    }
}

@media only screen and (max-width: 1240px) {
    .dln-kenwood {
        .dln-recipe-by-ingredients {
            .dln-wrapper {
                .dln-recipe-by-ingredients__search-section,
                > h1 {
                    padding: 0 30px;
                }

                .dln-breadcrumbs {
                    padding: 30px;
                }

                .dln-recipe-by-ingredients__search-section {
                    text-align: center;

                    .dln-inputs.dln-inputs--searchBar.dln-inputs--rightIcon {
                        max-width: 387px;
                    }
                }

                .dln-filters-bar {
                    padding: 0;

                    &__desktop {
                        padding-left: 30px;
                        padding-right: 30px;
                    }

                    &__applied-filters,
                    &__sort-section {
                        padding: 0 30px;
                    }
                }
            }
        }
    }
}

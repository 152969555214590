.dln-delonghi {
  .dln-contactUs {
    & .dln-wrapper--fullWidth {
      background-color: transparent;
      padding-bottom: 48px;
    }
    &__head {
      &Title {
        @include fontMedium;
        line-height: 1.04;
        font-size: REM(50px);
      }
    }
    &__title {
      @include fontMedium;
      line-height: 1.07;
      font-size: REM(18px);
    }
    &__text {
      line-height: 1.5;
      font-size: REM(16px);
    }
    &__callUs {
      &Title {
        @include fontMedium;
        line-height: 1.07;
        font-size: REM(18px);
        color: $brand-04;
      }
      &Text {
        line-height: 1.3;
        font-size: REM(14px);
        color: $brand-04;
        margin: 0 0 25px 0;
      }
    }
    .dln-btn--send {
      width: 100%;
      
      @media screen and (min-width: map-get($grid-breakpoints, sm)) {
        max-width: 280px;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-contactUs {
      & .dln-wrapper--fullWidth {
        background-color: transparent;
        padding-bottom: 22px;

        & .dln-breadcrumbs {
          padding-bottom: 0;
          margin-bottom: 34px !important;
        }
      }
      &__head {
        &Title {
          line-height: 1.08;
          font-size: REM(37px);
        }
      }
      &__title {
        line-height: 1.13;
        font-size: REM(16px);
      }
      &__text {
        line-height: 1.3;
        font-size: REM(14px);
      }

      &__callUs {
        &Text {
          line-height: 1.3;
          font-size: REM(14px);
        }
      }
    }
  }
}

.dln-ordReturnHowItWorks {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 2px;

    & .dln-popup__head {
        & .dln-btnIcon {
            margin-right: 30px;
        }
        &Title {
            text-align: left;
            padding: 0 100px;
        }
    }
    &__content {
        padding: 0 100px 96px 100px;

        & > .dln-btn--trackingCode {
            @include fontRoman;
            margin-bottom: 30px;
        }
    }
}

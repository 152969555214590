[data-screenset-element-id="gigya-verification-pending-screen"]{
  .gigya-screen-caption {
    margin: 20px 0;
    color: black;
    padding-bottom: 15px;
    font-family: var(--fontNameBold);
    font-weight: 700;
    font-size: 17px;
    width: auto!important;
  }
}

[data-screenset-element-id="gigya-resend-verification-code-form"]{

  .gigya-layout-row:nth-child(5){
	text-align:center;
  }

}
.dln-kenwood {
    .dln-ordersHist {
        &--empty {
            .dln-ordersHist__subTitle {
                @include fontExtra;
                font-size: 28px;
                margin: 0 0 20px;
            }
            .dln-ordersHist__foot {
                text-align: center;
                border: none;
                padding: 0;
                margin-top: 30px;
                margin-bottom: 0;
            }
            .dln-ordersHist__content {
                display: block;
                text-align: center;

                .dln-title {
                    margin: 0 0 32px 0;
                    line-height: normal;
                }
            }
            .dln-ordersHist__paragraph {
                padding-bottom: 10px;
            }
            @media only screen and (max-width:599px) {
                .dln-ordersHist__content {
                    margin: 0;
                    & .dln-title {
                        font-size: 32px;
                        line-height: 40px;
                        padding: 0;
                        margin: 30px 0 20px 0;
                    }
                }
                .dln-ordersHist__subTitle {
                    font-size: 24px;
                    text-align: center
                }
                .dln-ordersHist__paragraph {
                    font-size: 14px;
                    text-align: center;
                }

            }
        }
    }
}
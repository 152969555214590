@media only screen and (max-width:599px) {
    .dln-savedRec {
        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 32px 0;
        }
        &--empty {
            .dln-savedRec__content {
                margin: 0 0 40px;
                & .dln-title {
                    margin: 32px 0;
                }
            }
            .dln-savedRec__subTitle {
                font-size: 21px;
            }
            .dln-savedRec__paragraph {
                font-size: 14px;
            }
        }
    }

}
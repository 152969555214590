.dln-kenwood {
  & .dln-cart {
    & > .dln-wrapper {
      padding: 0 60px;

      & .dln-cart__head {
        margin: 27px 0 44px 0;

        & .dln-chips {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          background-color: $brand-03;

          & .dln-chips__wrapper {
            width: 100%;

            & .dln-chips__content {
              margin: 0 auto;
              width: auto;

              & span {
                display: inline-flex;
                align-items: baseline;
                width: auto;
                line-height: 2;

                .dln-icn {
                  font-size: 32px;
                  color: $brand-01;
                }

                & b {
                  margin-left: 5px;
                }
              }
            }
          }
        }
        & .dln-cart__title {
          width: auto;

          &Lbl {
            color: $brand-04;
          }
        }
        & .dln-btn--link.dln-btn--icon {
          & .dln-icn {
            font-weight: bold;
          }
          & .dln-btn__lbl {
            @include fontBold;
            font-weight: bold !important;
          }
        }
      }
      & .dln-cart__content {
        align-self: flex-start;

        & .dln-cart__items {
          margin-top: 0;

          & .dln-cartItem {
            padding: 32px 0;

            &__content {
              min-height: 144px;
              padding-left: 200px;
              position: relative;
            }

            &__picture {
              position: absolute;
              top: 0;
              left: 0;
            }

            &__title {
              line-height: 16px;
              font-size: 14px;
              margin-bottom: 9px;
              margin-left: 0;
            }

            &__closeBtn {
              top: 0;
              right: 10px;

              & .dln-icn {
                width: 22px;
                height: 22px;
                font-size: 0;

                &::before {
                  @include fontIcon;
                  content: 'æ';
                  line-height: 22px;
                  font-size: 22px;
                  color: $brand-01;
                }
              }
            }

            &__chips {
              margin-left: 0;

              & .dln-chips {
                height: 16px;
                background-color: transparent;
                padding: 0;

                &__content {
                  & span {
                    @include fontRoman;
                    line-height: 16px;
                    font-size: 14px;
                    color: $ui-06 !important;
                  }
                }
              }
            }

            &__price {
              margin-top: 0;
              margin-bottom: 15px;
              margin-left: 0;

              & .dln-price {
                &--oldPrice {
                  margin: 0 12px 0 0;
                }
                &--discounted {
                  @include fontRoman;
                }
              }
            }

            &__quantity {
              margin-left: 0;

              & .dln-cartItem__label {
                font-size: 14px;
                @include fontLight;
              }
              & .dln-inputIncr {
                &__input {
                  @include fontBold;
                }
              }
            }

            &__accessories {
              width: 100%;
              min-width: 424px;
              max-width: 488px;
              padding-bottom: 20px;
              margin-bottom: 17px;
              margin-left: 0;
              position: relative;

              &::after {
                content: '';
                width: 100%;
                max-width: 488px;
                /* height: 1px; */
                background-color: $ui-01;
                position: absolute;
                bottom: 0;
                left: 0;
              }

              & .dln-select {
                // max-width: 285px;
                width: calc(100% - 20px - 183px);
                max-width: none;
                margin-right: 20px;
              }

              .dln-btnTextIcon {
                &__wrap {
                  margin: 0 10px 0 0;
                }

                &__iconWrap {
                  width: 16px;
                  height: 16px;

                  .dln-icn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 16px;
                    height: 16px;
                    color: $brand-03;
                    background-color: $brand-01;

                    &::before {
                      content: '4';
                      width: initial;
                      height: initial;
                      position: initial;
                      top: initial;
                      left: initial;
                    }
                  }
                }

                &__text {
                  margin: 0 0 0 10px;
                }

                &:hover {
                  .dln-btnTextIcon__iconWrap {
                    .dln-icn {
                      color: $brand-03;
                    }
                  }
                }
              }
            }

            &__warranty {
              width: 280px;
              padding-right: calc(100% - 280px);
              margin-top: 0;
              margin-left: 0;
              box-sizing: content-box;

              &Head {
                border-bottom: none;
                padding-bottom: 0;

                & .dln-cartItem__subTitle {
                  @include fontMedium;
                }
              }
            }
            &__plan {
              margin-bottom: 10px;

              & .dln-radioBtn {
                &__lbl {
                  @include fontLight;
                }
              }
            }
          }
          & + .dln-btn {
            margin-top: 50px;

            & .dln-btn__lbl {
              @include fontBold;
            }
          }
        }
      }
      & .dln-cart__aside {
        & .dln-cart__orderRecap {
          border: none;

          & .dln-orderSumm {
            background-color: transparent;

            & .dln-orderSumm__field {
              margin-top: 0;

              & .dln-inputs {
                width: calc(100% - 115px);
                margin: 0 10px 0 0;

                &--validate {
                  background-color: transparent;
                }

                &__input {
                  height: 48px;
                }
              }

              .dln-inputs--active .dln-inputs__lbl {
                transform: translateY(calc(50% - 28px)) scale(0.75);
              }

              & .dln-inputs__lbl {
                line-height: 16px;
                top: 16px;
                left: 20px;
                width: auto;
                background-color: $brand-03;
              }

              .dln-inputs--active .dln-inputs__lbl & .dln-btn {
                color: $brand-03;
                border: 1px solid $brand-01;
                background-color: $brand-01;
                height: 48px;
              }
            }

            &__content {
              &Row {
                &--promo {
                  & .dln-orderSumm__rowTitle {
                    line-height: 22px;
                    margin: 0 0 8px 0;
                  }
                  & .dln-price--removeLink {
                    line-height: 22px;
                    margin: 0 auto 8px 10px;

                    & a {
                      @include fontBold;
                    }
                  }
                  & .dln-price--checkout {
                    & a {
                      @include fontBold;
                    }
                  }
                }
                // &--subtotal {

                // }
                &--taxes {
                  padding-bottom: 7px;

                  & .dln-orderSumm__rowTitle {
                    margin-bottom: 8px;

                    & + .dln-price {
                      margin-bottom: 8px;
                    }
                    & .dln-price.dln-price {
                      margin: 0 0 0 auto;
                    }
                    & .dln-price.dln-price--checkout {
                      margin-left: 15px;
                    }
                  }
                  & .dln-price--checkout {
                    & a {
                      @include fontBold;
                    }
                  }
                  & .dln-orderSumm__field {
                    margin-top: 21px;

                    & .dln-inputs {
                      &__lbl {
                        line-height: 16px;
                      }
                    }
                  }
                  & .dln-btn {
                    color: $brand-03;
                    border: 1px solid $brand-01;
                    background-color: $brand-01;
                  }
                }
                &--total {
                  border-bottom: none;
                  padding-bottom: 0;
                  margin-bottom: 10px;

                  & .dln-orderSumm__rowTitle {
                    line-height: 24px;
                    font-size: 20px;
                    color: $brand-04;

                    & .dln-icn,
                    & a {
                      color: $brand-02;
                    }
                    & a {
                      font-size: 12px;
                    }
                  }
                  & .dln-price--total {
                    line-height: 24px;
                    font-size: 20px;
                  }
                }
              }
            }
            & + .dln-btn {
              width: 100%;

              & + .dln-btn--paypal {
                display: flex;
                justify-content: center;
                width: 100%;

                & .dln-btn__icon {
                  margin: 0 8px 0 0;
                }
                &::after {
                  margin: 0 0 3px 0;
                }
              }
            }
          }
        }
        & .dln-cart__noticeBox {
          & .dln-noticeBox {
            &__link {
              @include fontBold;
            }
          }
        }
      }
      & .dln-cart__services {
        margin-top: 0 !important;
      }
    }
    &--taxes {
      & > .dln-wrapper {
        & .dln-cart__aside {
          & .dln-cart__orderRecap {
            & .dln-orderSumm {
              &__content {
                &Row {
                  &--taxes {
                    padding-bottom: 7px;

                    & .dln-orderSumm__rowTitle {
                      width: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    & .dln-cart__button-wrapper {
      button {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: map-get($grid-breakpoints, sm)) and (max-width: 1100px) {
  .dln-kenwood {
    .dln-cart {
      & > .dln-wrapper {
        padding: 0;

        .dln-cart__content {
          margin-right: 0;
          width: 100%;

          .dln-cart__items {
            .dln-cartItem {
              &__accessories {
                .dln-select {
                  width: 100%;
                  margin: 0 0 20px 0;
                }

                .dln-btnTextIcon {
                  width: 100%;
                }
              }
            }
          }
        }

        .dln-cart__head {
          margin: 0 0 20px;

          .dln-chips--advantage .close {
            font-size: 16px;
          }
        }

        .dln-cart__aside {
          width: 100%;

          .dln-cart__orderRecap {
            padding-top: 30px;

            & + .dln-btn {
              display: none;
            }
          }

          &--secondary {
            .dln-cart__noticeBox {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;

              .dln-noticeBox {
                width: 50%;
                max-width: 160px;
              }
            }
          }
        }

        .dln-cart__services {
          margin-bottom: 50px;

          .dln-checkServiceBox {
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-kenwood {
    .dln-cart {
      padding: 0;

      & > .dln-wrapper {
        padding: 22px 0;

        .dln-cart__head {
          margin: 0 0 20px;
          padding: 0 20px;
          border-bottom: none;

          .dln-chips {
            height: auto;
            padding: 8px 16px;
            margin: 0 0 20px 0;

            .dln-chips__wrapper {
              & .dln-chips__content {
                margin: 0;

                span {
                  width: 100%;
                  position: relative;

                  .dln-icn {
                    margin-bottom: 0;
                  }
                }

                b {
                  font-size: 13px;
                }
              }
            }

            &.dln-chips--advantage .close {
              font-size: 18px;
            }
          }
        }

        .dln-cart__content {
          margin-bottom: 0;

          .dln-cart__items {
            padding: 0 20px;

            .dln-cartItem {
              padding-bottom: 0;

              &:first-child {
                border-top: 1px solid $ui-01;
              }

              &__content {
                padding-left: 0;
                margin-bottom: 30px;
              }

              &__picture {
                position: relative;
                width: 98px;
                height: 80px;
                position: initial;
              }

              &__closeBtn {
                right: 0;
              }

              &__accessories {
                padding-top: 20px;
                padding-bottom: 0;

                & .dln-select {
                  width: 100%;
                  max-width: none;
                  margin-right: 0;
                }

                & .dln-btnTextIcon {
                  width: 100%;
                  max-width: none;
                }
              }

              &__warranty {
                & .dln-cartItem__plan {
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

        & .dln-cart__aside {
          & .dln-cart__orderRecap {
            margin-bottom: 30px;
            padding: 30px 0 0;
            width: 100vw;
            min-width: 100vw;

            & + .dln-btn {
              margin: 0 auto 60px 0;

              & .dln-btn__lbl {
                @include fontBold;
              }
            }
          }

          .dln-cart__noticeBox {
            margin: 0;
          }
        }
      }
    }
  }
}

.dln-kenwood {
    & .dln-popup {
        & .dln-popup__content {
            & .dln-popup__wrapper {
                & .dln-search {
                    padding: 0;

                    & .dln-inputs--searchBar {
                        margin: 0 0 30px 0;
                        margin-top: 0 !important;
                        max-width: none;

                        // &::after {
                        //     color: $brand-01;
                        // }
                    }
                    & .dln-popup__head {
                        padding-top: 110px;

                        & .dln-btnIcon--close {
                            position: static;
                            margin: -90px 0 0 auto;

                            & .dln-icn {
                                &::before {
                                    color: $brand-04;
                                }
                            }
                        }
                    }
                    & > .simple-list {
                        padding-top: 10px;
                        margin-top: 0;
                    }
                    & .dln-search-results {
                        border-bottom: 1px solid $ui-01;
                        padding-bottom: 10px;
                        margin-bottom: 30px;

                        & .dln-search-results__grid {
                            margin: 0;

                            & .dln-search-result {
                                width: calc(50% - 10px);
                                margin: 0 20px 20px 0;

                                &:nth-child(2n+2) {
                                    margin: 0 0 20px 0;
                                }
                                &:nth-last-of-type(2),
                                &:last-of-type {
                                    margin-bottom: 0;
                                }
                                & .dln-search-result__itemPic{
                                    width: 115px;
                                }
                                & .dln-search-result__content {
                                    padding-left: 10px;

                                    .dln-search-result__modelNumber {
                                        color: $brand-02;
                                        font-size: REM(12px);
                                        font-weight: 400;
                                    }

                                    & .dln-search-result__category {
                                        line-height: 16px;
                                        font-size: 12px;
                                        color: $brand-02;
                                        margin-bottom: 2px;
                                    }
                                    & .dln-search-result__detailsTitle {
                                        @include fontBold;
                                        line-height: 18px;
                                        font-size: 14px;
                                        color: $brand-04;
                                        margin-bottom: 10px;
                                    }
                                    & .dln-search-result__priceWrapper {
                                        & .dln-price {
                                            line-height: 18px;

                                            &--oldPrice {
                                                order: 2;
                                                line-height: 20px;

                                                &:after {
                                                    width: 100%;
                                                }
                                            }
                                            &--discounted {
                                                @include fontBold;
                                                order: 1;
                                                margin-right: 8px;
                                            }
                                        }
                                        & .prices {
                                            flex-flow: row;
                                        }
                                    }
                                }
                            }
                        }
                        & .dln-search-results__view-all {
                            @include fontBold;
                            color: $brand-04;

                        }
                    }
                    & .dln-search__withResult-section {
                        & .simple-list {
                            padding: 0;
                            margin-top: 0;

                            & .simple-list__title {
                                line-height: 16px;
                                font-size: 12px;
                                text-transform: uppercase;
                                margin-bottom: 11px;
                            }
                            & .simple-list__item {
                                line-height: 20px;
                                font-size: 14px;
                                padding: 0;
                                margin-bottom: 10px;

                                &:nth-last-of-type(2) {
                                    margin-bottom: 0;
                                }
                                &.simple-list__view-all {
                                    padding-top: 20px;
                                    margin-bottom: 0;

                                    & a {
                                        @include fontBold;
                                        color: $brand-04;
                                    }
                                }
                            }
                        }
                    }
                    &--noResults {
                        & .dln-inputs--searchBar {

                        }
                        & .dln-search--noResults__subTitle {
                            @include fontBold;
                            text-align: center;
                            padding: 0;
                            margin-bottom: 20px;
                        }
                        & .dln-search--noResults__paragraph {
                            text-align: center;
                        }
                    }

                    .simple-list {
                        padding: 40px 0 0 0;
                        margin-left: 0;
                    }
                }
            }
        }
    }
}



@media only screen and (max-width:599px) {
    .dln-kenwood {
        & .dln-popup {
            & .dln-popup__content {
                & .dln-popup__wrapper {
                    & .dln-search {
                        & .dln-popup__head {
                            padding-top: 90px;
                            margin-top: 0;
                            margin-bottom: 23px;
                            
                            & .dln-btnIcon--close {
                                position: absolute;
                                top: 24px;
                                margin: 0 0 0 auto;
                            }
                        }
                        & .dln-inputs--searchBar {
                            border-color: $ui-01;
                        }
                        & .dln-search-results {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;

                            & .dln-search-results__grid {
                                width: 100%;

                                & .dln-search-result {
                                    width: 100%;
                                    margin-bottom: 20px !important;

                                    & .dln-search-result__content {
                                        & .dln-search-result__ratingWrapper {
                                            height: 15px;
                                            line-height: initial;

                                            & .dln-ratingStars {
                                                height: 15px;

                                                & .dln-ratingStars__star {
                                                    height: 15px;

                                                    & .dln-icn {
                                                        height: 15px;
                                                        line-height: 15px;
                                                        font-size: 15px;
                                                    }
                                                }
                                            }
                                            & .dln-search-result__reviews {
                                                line-height: 15px;
                                            }
                                        }
                                    }
                                }
                            }
                            & .dln-search-results__view-all {
                                margin-left: auto;
                            }
                        }
                        & .dln-search__withResult-section {
                            margin: 0;

                            & .simple-list {
                                border-bottom: 1px solid $ui-01;
                                padding-bottom: 8px;
                                margin-bottom: 30px;

                                & .simple-list__view-all {
                                    display: flex;
                                    justify-content: flex-end;
                                    padding-top: 25px;
                                }
                                &:last-child {
                                    border-bottom: none;
                                    padding-bottom: 0;
                                    margin-bottom: 0;
                                }
                            }
                        }
                        &--noResults {
                            & .dln-inputs--searchBar {
                                margin-bottom: 35px;
                            }
                            & .dln-search--noResults__subTitle {
                                font-size: 20px;
                                line-height: 24px;
                                margin-bottom: 23px;
                            }
                        }
                    }
                }
            }
        }
    }
}

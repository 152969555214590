.dln-delonghi {
  & .dln-timeline {
    &__step {
      &::after {
        background-color: $ui-01;
      }
      & .dln-timeline__label {
        &::before {
          @include fontBold;
        }
        & span {
          @include fontBook;
          font-size: REM(14px);
        }
      }
      &--completed {
        & .dln-timeline__label {
          &::before {
            @include fontIcon;
            content: 'à';
            color: $brand-03;
            background-color: $support-02;
          }
        }
      }
      &--active {
        & .dln-timeline__label {
          &::before {
            background-color: $brand-04;
          }
        }
      }
    }
  }
}

.dln-delonghi {
  & .dln-cart__orderRecap {
    .dln-cart__payment-wrapper {
      background-color: $brand-03;

      & .dln-cart_paymentBox {
        margin: 0;
      }
    }
  }
  & .dln-orderSumm {
    & .dln-orderSumm__contentRow.dln-orderSumm__contentRow--promo {
      & .dln-orderSumm__field {
        & .dln-btn {
          width: 91px;
          min-width: initial;
          padding: 0;
        }
      }
    }
    & .dln-orderSumm__content {
      .dln-price {
        @include fontRoman;
        line-height: 18px;
        font-size: REM(13px);

        .dln-price--total {
          font-size: REM(18px);
        }

        &--removeLink {
          display: inline-flex;
          flex-wrap: wrap;
          width: auto;
          height: 19px;
          max-height: none;
          line-height: 19px;

          & > a {
            @include fontMedium;
            display: inline-block;
            width: auto;
            line-height: 18px;
            font-size: REM(14px);
            font-weight: 500;
            padding-bottom: 1px;
          }
        }
        &--checkout,
        &--discounted {
          color: $brand-04;
        }
        &--discounted {
          font-size: REM(15px);
        }
        &--oldPrice {
          font-size: REM(15px);
          color: $ui-02;

          &::after {
            background-color: $ui-02;
          }
        }
        &--secondary {
          font-size: REM(14px);
          color: $ui-02;
        }
        &--taxes {
          font-size: 14px !important;

          > i {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }

      .dln-orderSumm__contentRow--taxes .dln-icn {
        color: $brand-01;
      }
    }

    &--checkout {
      border: none;

      .dln-orderSumm__head {
        border-bottom: 1px solid $ui-01;
        background-color: transparent;
      }

      .dln-orderSumm__title {
        @include fontRoman;
        font-size: REM(18px);
      }

      .dln-orderSumm__content {
        padding: 15px 20px 43px 20px;

        @media only screen and (min-width: 599px) and (max-width: 900px) {
          padding: 15px 0 0;
        }

        .dln-orderSumm__contentRow--shipping .dln-icn {
          color: $brand-01;
        }

        &Row {
          padding: 10px 0;

          &:first-child {
            padding: 16px 0 12px 0;
          }
          & .dln-orderSumm__lbl {
            font-size: REM(14px);

            & > b {
              font-weight: normal;
            }
          }
          & .dln-orderSumm__item {
            padding: 0;

            &Details {
              & > h5 {
                @include fontMedium;
                line-height: 21px;
                font-size: REM(19px);
              }
            }
          }
          & > .dln-orderSumm__rowTitle {
            line-height: 22px;
            font-size: REM(15px);
            color: $ui-02;
          }
          &--promo {
            & .dln-orderSumm__rowTitle {
              margin-right: 16px;
            }
            .dln-icn {
              color: $brand-01;
            }
          }
          &--total {
            border-color: $brand-02;
            padding: 14.5px 0;

            & .dln-orderSumm__rowTitle {
              @include fontMedium;
              line-height: 19px;
              font-size: REM(18px);
              color: $brand-04;
              text-transform: uppercase;
            }
          }
        }
      }
      .dln-icn--coupon {
        color: $support-02;
      }
    }

    &--light {
      & .dln-orderSumm__content {
        & .dln-orderSumm__contentRow {
          position: relative;

          & .coupon {
            line-height: 13px !important;

            & i {
              color: $support-02;
            }
          }

          .dln-orderSumm__rowTitle {
            line-height: 18px;
            font-size: REM(14px);
            color: var(--brand-04);
          }

          .dln-price {
            @include fontBook;
            line-height: 18px;
            font-size: REM(19px);

            &--checkout,
            &--discounted {
              color: $brand-04;
            }

            &--oldPrice {
              line-height: 19px;
              font-size: REM(16px);
              color: $ui-02;

              &::after {
                background-color: $ui-02;
              }
            }

            &--secondary {
              line-height: 19px;
              font-size: REM(16px);
              color: $ui-02;
            }

            & > a {
              display: inline-block;
              width: auto;
              height: 18px;
              line-height: 18px;
              font-size: REM(14px);
            }
          }

          &--shipping {
            & .dln-price:not(.dln-price--secondary) {
              @include fontRoman;
            }
          }

          &--taxes {
            & .dln-price {
              @include fontRoman;
            }
          }

          &--total {
            & .dln-orderSumm__rowTitle {
              @include fontRoman;
              line-height: 24px;
              font-size: REM(18px);
              text-transform: uppercase;
            }
            & .dln-price {
              @include fontRoman;
            }
          }

          &--promo {
            & .dln-orderSumm__rowTitle {
              font-size: 18px;
            }
          }
        }
      }

      .dln-orderSumm__content {
        .dln-price--checkout {
          margin-bottom: 1px;
        }

        .dln-price--removeLink {
          margin-bottom: 3px;
        }

        .dln-price {
          font-size: REM(18px);
          font-weight: normal;
        }

        .dln-price--secondary {
          font-size: REM(14px);
        }

        .dln-orderSumm__contentRow--shipping .dln-icn {
          color: $brand-01;
        }

        .dln-orderSumm__contentRow--taxes {
          .dln-price--checkout {
            display: inline-block;
            margin-bottom: 5px;
          }

          .dln-icn {
            color: $brand-01;
          }
        }

        .dln-orderSumm__contentRow.dln-orderSumm__contentRow--total {
          padding: 13px 0;

          .dln-orderSumm__rowTitle {
            a {
              font-size: REM(14px);
              text-transform: capitalize;
            }
          }
        }
      }
    }

    &--cart {
      .dln-orderSumm__content {
        .dln-orderSumm {
          &__field {
            display: flex;
            flex-wrap: nowrap;

            .dln-btn.dln-btn--active {
              width: 91px;
              padding: 0;
              min-width: initial;
            }
          }
        }

        .dln-inputs--invalid {
          margin-bottom: 18px;
        }
      }
    }
  }
}

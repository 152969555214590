@media only screen and (max-width:599px) {
    .dln-ordReturnHowItWorks {
        border-radius: 0;

        & .dln-popup__head {
            padding: 20px 20px 40px 20px;

            & .dln-btnIcon {
                margin: 0 0 20px auto;
            }
            &Title {
                max-width: none;
                line-height: 36px;
                font-size: 32px;
                padding: 0;
            }
        }
        &__content {
            padding: 0 20px 20px 20px;

            & > .dln-btn--trackingCode {
                margin-bottom: 40px;
            }
        }
    }
}

[data-screenset-element-id="gigya-change-password-screen"]{

  .gigya-profile-form{

	.gigya-layout-row{

	  @include breakpoint('gigya'){
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;

		> .dlg-gigya-input-text-wrapper, .h-emptyBlock{
		  width:49%;
		}
	  }
	}

	.dln-btn--cancel{
	  float:left;
	}

	.dln-btn--update{
	  max-width:184px;
	  float:right;
	  padding: 0!important;

	  .gigya-input-submit{
		font-size:14px;
		font-family: var(--fontNameBold);
		font-weight:700;
		font-style:normal;
		color:var(--brand-03);
		text-align:center;
		border:none;
		background-color:var(--brand-01);
		outline:none;
		cursor:pointer;
		width: 100%;
		height: 100%;
	  }
	}
  }
}

.dln-kenwood {
	[data-screenset-element-id="gigya-change-password-screen"] {
		.gigya-profile-form {
			.dln-btn--update {
				.gigya-input-submit {
					text-transform: uppercase;
				}
			}
		}
	}
}

.dln-regProductList {
    &--editing {
        .dln-regProductList__section {
            align-items: stretch;
            margin: 24px 0 64px 0;
        }
        .dln-regProductList__sectionFoot {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            border-top: 1px solid $ui-01;
            padding: 40px 0 0;
            margin: 40px 0 0;
            .dln-btn {
                max-width: 180px;
                &--link {
                    margin: 0 auto 0 0;
                }
            }

            .dln-chips {
                margin-bottom: 15px;
            }
        }
        .dln-regProductList__content {}
        .dln-noticeBox {
            width: calc(50% - 10px);
            margin: 0 20px 0 0;
            &:last-of-type {
                margin: 0 0;
            }
        }
    }
    & .dln-regProductList__section {
        & .dln-noticeBox  {
            align-items: center;
            padding: 28px 16px 33px 11px;

            &__icon {
                width: 44px;
                height: 44px;
                margin: 0 26px 0 0;

                & .dln-icn {
                    width: 44px;
                    height: 44px;
                    line-height: 44px;
                    font-size: 44px;
                }
            }
            &__content {
                width: calc(100% - 44px - 26px);
            }
        }
    }
}
.dln-delonghi {
    .dln-browse-by {
        border-bottom: 1px solid $ui-08;

        &__link {
            max-width: none !important;
            width: 12% !important;
            border-right: 1px solid $ui-08;
            padding-bottom: 0;
            height: 24px;
            line-height: 24px;
            margin-bottom: 26px;
            @include fontMedium;

            &:first-of-type {
                text-align: left;
                padding-left: 0;
            }
            &:nth-of-type(3) {
                width: 16% !important;
            }
            &:last-of-type {
                text-align: right;
                border-right: none;
                padding-right: 0;
            }

            &Prefix {
                @include fontMedium;
                display: initial;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .dln-delonghi {
        & .dln-browse-by{
            & .dln-browse-by{
                &__linksWrapper {
                    margin-top: 0;
                }
                &__link {
                    width: auto !important;
                    border-right: none;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.dln-delonghi {
    .dln-textarea {
        height: auto!important;

        &__lbl {
            transform: translateY(-50%);
            top: 0;
            width: auto;
            background: white;
        }
        &__input {
            &::placeholder {
                opacity: 0;
                color: $ui-07;
            }
        }
        &.dln-inputs--active {
            border-color: $ui-02;

            & .dln-inputs__input {
                color: $brand-02 !important
            }
            .dln-textarea__lbl {
                transform: translateY(-50%);
                top: 0;
            }
        }

        &--error,
        &--error.dln-inputs--active {
            border-color: $support-01;
        }
    }
}
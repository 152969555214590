@media only screen and (max-width:599px) {
    .dln-contactUs {
        min-height: none;
        min-height: initial;

        & .dln-wrapper {
            &--fullWidth {
                padding: 24px 20px 65px;
                margin-bottom: 20px;
            }
        }
        &__head {
            padding: 0 !important;

            &Title {
                line-height: 40px;
                font-size: 35px;

                & br {
                    display: none;
                }
            }
        }
        & .dln-titleIcon {
            &__title {
                font-size: 26px;
            }
        }
        &__title {
            line-height: 20px;
            font-size: 20px;
        }
        &__form {
            padding-bottom: 0 !important;

            .dln-inputsFile {
                .dln-btn {
                    max-width: 118px;
                }
            }
            .dln-inputsFile__label {
                width: calc(100% - 118px - 16px);
            }
        }

        & .dln-checkbox+.dln-contactUs__text+.dln-btn {
            width: 100%;
            margin: 0 auto;
        }
        & .dln-btn--send {
            width: 100%;
            margin: 0;
        }

        &__callUsText,
        &__callUsList {
            line-height: 18px;

            & + h4 {
                margin-top: 10px
            }
        }
        &__callUsList {
            &Item {
                line-height: 18px;
            }
        }
    }
}

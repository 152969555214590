@media only screen and (max-width:599px) {
    .dln-address {
        .dln-profileCard {
            display: none;
        }
        /* & .dln-wrapper {
            padding-top: 20px;
            padding-bottom: 100px;
        } */
        &__aside {

            & .dln-profileCard {
                display: none;
            }
            & .dln-vertMenu {
                margin: 0 0 24px 0;
            }
        }
        &__content {

            & .dln-title {
                line-height: 34px;
                font-size: 35px;
                padding: 6px 0 0 0;
                margin: 0 0 24px 0;
            }
            & .dln-addressBox,
            & .dln-addBox {
                width: 100%;
                margin: 0 0 20px 0;
                &:nth-child(even) {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}
.dln-resetPssw {
    align-content: flex-start;

    .dln-title {
        text-align: left !important
    }
    &__title {
        @include big;
        @include fontBold;
        margin: 0 0 0;
    }
    &__subTitle {
        @include big;
        @include fontBold;
        font-size: 32px;
        margin: 0 0 8px;
    }
    &__content {
        min-height: calc(100vh - 150px - 250px);

        .dln-title {
            margin: 0 0 32px 0;
            line-height: normal;
        }
    }

    &__paragraph {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 13px;
        font-weight: 400;
        color: $brand-01;
        margin: 0 0 20px 0;
    }

    &__subtitle {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 13px;
        @include fontBold;
    }
    &__form {
        width: 100%;
        max-width: 600px;
        margin: 0;
        .dln-inputs {
            margin: 0 0 32px;
        }
        .dln-btn {
            max-width: 280px;
        }
    }
}
.dln-multiSelectList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    list-style: inside none;

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        min-height: 48px;
        border-bottom: 1px solid $ui-01;
        background: rgba(0,0,0,.1);
        background-color: $brand-03;
        position: relative;
        z-index: 1;

        &:last-of-type {
            border-bottom: none;
        }
    }
    &__input {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        width: 100%;
        transition: background-color 0.12s linear;
        padding: 0 10px;
        box-sizing: border-box;
    }
    &__label {
        display: block;
        width: calc(100% - 16px - 16px);
        height: auto;
        line-height: 16px;
        font-size: 15px;
        padding: 16px 0 16px 0;
    }
    & .dln-icn--checkOutline {
        display: inline-block;
        width: 16px;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        transform: scale(0);
        transform-origin: center;
        transition: transform 0.12s ease;
        margin: 17px 0 0 16px;
    }
    &__input:checked {
        & + .dln-multiSelectList__wrap {
            background-color: $ui-03;

            & .dln-multiSelectList__label {
                @include fontBold;
            }
            & .dln-icn--checkOutline {
                transform: scale(1);
            }
        }
    }
}
.dln-accordion {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    max-height: 60px;
    border-bottom: 1px solid $ui-01;
    background-color: $brand-03;
    transition: max-height 0.22s ease-out;
    will-change: max-height;
    box-sizing: content-box;
    overflow: hidden;

    &__head {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 50px;
        padding-bottom: 10px;
        box-sizing: content-box;
        background: none;
        border: none;
        cursor: pointer;
        // >* {
        //     pointer-events: none;
        // }
    }
    &__icon {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
    }
    &__title {
        display: block;
        width: 100%;
        height: 57px;
        line-height: 57px;
        font-size: 17px;
        color: $brand-02;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: color 0.12s linear;
        padding: 0 0 0 0;
        margin: 0 auto 0 0;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            color: $brand-04;
        }
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% - 32px);
        opacity: 0;
        transition: opacity 0.12s linear;
        padding: 32px 16px 32px 16px;
        box-sizing: content-box;
    }
    &--ui-03 {
        background-color: $ui-03;
    }
    &--titleIcon {
        & .dln-accordion__head {
            align-items: center;
            padding-bottom: 0;
        }
        & .dln-accordion__icon {
            padding-top: 0;
            pointer-events: none;
        }
        & .dln-accordion__title {
            width: auto;
            height: auto;
            line-height: normal;
            font-size: 13px;
            @include fontBold;
            color: $brand-04;
            padding: 0;
            margin: 0 8px 0 0;
            pointer-events: none;
        }
        & .dln-icn--arrow-down {
            padding-top: 0;
            pointer-events: none;
            position: initial;
        }
        & .dln-icn:before {
            display: none;
        }
    }
    &--open {
        transition: max-height 0.2s ease-in;

        & .dln-accordion__title {
            color: $brand-04;
        }
        & .dln-accordion__content {
            opacity: 1;
            transition: opacity 0.32s linear;
        }
        /* .dln-accordion__head {
            >* {
                pointer-events: auto;
            }
        } */
    }
    &--open {
        .dln-accordion__head {
            border-bottom: 1px solid $ui-01;
            .dln-icn--arrow-down {
                transform: rotateX(180deg);
            }
        }
    }
    &--arrow {
        & .dln-accordion__head {
            align-items: center;
            position: relative;

            & .dln-accordion__title {
                /* width: calc(100% - 41px - 20px - 20px); */
                width: 100%;
                padding: 0 61px 0 20px;
            }
            & .dln-icn {
                width: 24px;
                transform-origin: center;
                transition: transform 0.12s ease-in-out;
                pointer-events: none;
                position: absolute;
                /* position: absolute; */
                /* top: calc(50% - 18px); */
                right: 20px;
                right: 8px;
            }
        }
        &.dln-accordion--open {
            & .dln-accordion__head {
                & .dln-icn--arrow-down {
                    transform: rotateX(180deg);
                }
            }
        }
    }
    &--filters {
        &.dln-accordion--open {
            .dln-accordion__head {
                .dln-icn--arrow-down {
                    transform: rotateX(180deg);
                }
            }
        }
    }
    &--brand-01 {
        background-color: $brand-01;

        & .dln-accordion__head {
            background: $brand-01;
        }
        & .dln-accordion__title {
            @include fontBold;
            color: $brand-03;
        }
        & .dln-accordion__head:hover {
            & .dln-accordion__title {
                color: $brand-02;
            }
        }
        & .dln-accordion__content {
            background: $brand-01;
        }
        &.dln-accordion--open {
            & .dln-accordion__head:hover {
                & .dln-accordion__title {
                    color: $brand-03;
                }
            }
        }
        & .dln-icn {
            color: $brand-03;
        }
    }
    &--item-review {
        & .dln-accordion__head {
            padding: 5px 0 5px 10px;
            & .dln-accordion__title {
                display: flex;
                align-items: center;
                /* max-width: calc(100% - 16px - 60px - 24px - 20px); */
                max-width: calc(100% - 16px - 60px);
                height: auto;
                line-height: normal;
                font-size: 15px;
                @include fontBold;
                color: $brand-04;
                white-space: normal;
                border: 0;
                padding: 0;
                padding: 0 24px 0 0;
            }
        }
        & .dln-accordion__content {
            padding: 0 10px 16px;
            & .dln-price {
                display: block;
                width: 100%;
                padding-left: 76px;
                margin: 0 0 16px;
            }
        }
        &.dln-accordion--open {
            & .dln-accordion__head {
                border-width: 0;
            }
        }
    }
    &__imgWrap {
        display: inline-flex;
        align-items: center;
        width: 60px;
        height: 50px;
        margin: 0 16px 0 0;
        position: relative;
        z-index: 1;

        &::after {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.04);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }
    &__titleImg {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
}

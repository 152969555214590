@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-registration--login {

        .dln-registration__section {
            width: 50%;
            margin: 0 0 0 0;
            padding: 0 0 0 24px;
            &--main {
                margin: 0 0 0 0;
                padding: 0 24px 0 0;
                border-right: 1px solid $ui-01;
            }
            .dln-regAdv {
                margin: 0 0 64px;
            }
        }
        .dln-registration__formFoot {
            margin: 48px 0 0;
            .dln-btn {
                width: 100%;
            }
        }
        .dln-loginForm__section--registration {
            display: none;
        }
    }
}

@media only screen and (min-width : map-get($grid-breakpoints, sm)) and (max-width : map-get($grid-breakpoints, lg)) and (orientation : landscape) {
    .dln-registration--login {
        .dln-registration__section {
            width: 50%;
            margin: 0 0 0 0;
            padding: 0 0 0 24px;
            &--main {
                margin: 0 0 0 0;
                padding: 0 24px 0 0;
                border-right: 1px solid $ui-01;
            }
            .dln-regAdv {
                margin: 0 0 64px;
            }
        }
        .dln-registration__formFoot {
            margin: 48px 0 0;
            .dln-btn {
                width: 100%;
            }
        }
        .dln-loginForm__section--registration {
            display: none;
        }
    }
}
.gigya-input-radio{
  position:relative;
  width:16px;
  height:16px;
  visibility:hidden;

  + label{

    cursor:pointer;
    color:var(--ui-04);

    &:before{
      position:absolute;
      content:'';
      display:block;
      left:0;
      top:0;
      width:12px;
      height:12px;
      border:2px solid var(--ui-02);
      border-radius:50%;
    }

    &:after{
      position:absolute;
      content:'';
      display:block;
      top:4px;
      left:4px;
      width:8px;
      height:8px;
      transform:scale(0);
      background:var(--support-02);
      border-radius:50%;
      transition:transform .08s ease;
    }

  }

  &:checked{

    + label{

      &:after{
        transform:scale(1);
      }
    }
  }
}

.dln-order {
    & .dln-order__backToOrderHistory {
        display: inline-flex;
        flex-wrap: wrap;
        width: auto;
        text-decoration: none;
        margin-bottom: 20px;

        & > .dln-icn {
            width: 10px;
            height: 21px;
            line-height: 21px;
            font-size: 10px;
            color: $brand-01;
            text-align: center;
            text-decoration: none;
            margin: 0 8px 0 0;
        }
        & > span {
            @include fontBold;
            line-height: 21px;
            font-size: 14px;
            font-weight: bold;
            color: $brand-01;
            text-decoration: underline;
        }
    }
    .dln-order__detailProducts .dln-orderHistory .dln-orderRev__quantity {
        line-height: 1.67;
        font-size: 12px;
        color: $brand-02;
    }
}

.dln-order--detail {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;

    & .dln-wrapper {
        padding-top: 48px;
        padding-bottom: 85px;
    }
    & .dln-order__content {
        width: calc(100% - 304px);
        padding: 0;

        & .dln-custServiceCard {
            margin-bottom: 30px;
        }
        & .dln-orderDetailHead {
            margin-bottom: 30px;
        }
    }
    & .dln-order__detailProducts {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: auto;
        border-top: 1px solid $ui-01;
        padding: 28px 0 19px 0;

        &:last-child {
            border-bottom: 1px solid $ui-01;
        }
        & .dln-orderHistory {
            margin-bottom: 20px;

            & .dln-orderRev {
                margin: 0;

                &__detailsTitle {
                    line-height: 20px;
                }
                & .dln-price {
                    line-height: 20px;
                    font-size: 14px;
                }
                &__productCode {
                    line-height: 20px;
                    font-size: 15px;
                }
                &__quantity {
                   color: $brand-01;
                }
                &__actions {
                   max-width: 184px;
                }
            }
        }
    }
    & .dln-order__detailTitle {
        @include fontBold;
        display: block;
        width: 100%;
        height: auto;
        line-height: 26px;
        font-size: 22px;
        color: $brand-01;
        margin-bottom: 34px;
    }
}

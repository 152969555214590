.dln-delonghi {
    & .dln-prodReg {
        &--selectProduct {
            & .dln-prodReg__subTitle {
                margin-bottom: 25px;
            }
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-delonghi {
        & .dln-prodReg--selectProduct {
            & .dln-prodReg__productHeading {
                position: relative;

                & span:first-of-type {
                    width: calc(60% - 20px);
                }
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-delonghi {
        & .dln-prodReg--selectProduct {
            & .dln-wrapper {
                & .dln-prodReg__head {
                    margin-bottom: 16px;
                }
                & .dln-prodReg__content {
                    margin-top: 0;
                }
            }
        }
    }
}
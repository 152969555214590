@media only screen and (max-width:599px) {
    .dln-regProductList {

        .dln-profileCard {
            display: none;
        }
        .dln-title {
            margin: 20px 0;
        }
        .dln-breadcrumbs {
            margin: 16px 0 0;
        }
        &__section {
            margin-top: 0;
        }
    }
}
.dln-kenwood {
    .dln-headCheckout {
        display: none;
        &.dln-head--kenwood {
            display: flex;
            .dln-headCheckout__logo {
                &Icon {
                    display: block;
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}
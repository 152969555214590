.dln-delonghi {
  .dln-recipe-page {
    @media only screen and (max-width: 599px) {
      &__title {
        line-height: 1.08;
        font-size: REM(37px);
      }
      // &__title-filters,
      // .dln-filters-bar {
      //     display: none;
      // }
      .dln-recipe-details {
        .dln-wrapper {
          padding: 0 !important;

          .dln-recipe-details__col:nth-of-type(1) {
            .dln-recipe-details__info,
            .dln-recipe-details__bottom-info {
              padding-right: 20px;
              padding-left: 20px;
            }
          }

          .dln-recipe-details__title-recipe {
            font-size: REM(28px);
          }

          .dln-recipe-details__bottom-info {
            margin-bottom: 34px;
          }

          .dln-recipe-details.dln-recipe-details--inverted.dln-recipe-details--constrain {
            .dln-recipe-details__col:nth-of-type(2) {
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
        .dln-recipe__details {
          .dln-recipe__detail.dln-recipe__detail--preparationTime {
            height: 24px;
          }
        }
      }
    }
    &__title {
      @include fontMedium;
      line-height: 1.04;
      font-size: REM(50px);
    }
    &__title-filters {
      @include fontMedium;
      line-height: 1.07;
      font-size: REM(18px);
    }
    .dln-recipe-details__info-button-desktop {
      a {
        font-size: REM(18px);
      }
    }
    .dln-filterDropdown__triggerText {
      font-size: REM(16px);
    }
    .dln-filterDropdown.dln-filterDropdown--search:nth-of-type(1) {
      border-left: 0;
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
    & .dln-recipe-page {
      &__title {
        line-height: 1.08;
        font-size: REM(37px);
      }
      // &__title-filters,
      // &.dln-filters-bar {
      //     display: none;
      // }
      & .dln-recipe-details {
        padding-bottom: 26px;

        .dln-wrapper {
          padding: 0 !important;

          .dln-recipe-details__col:nth-of-type(1) {
            .dln-recipe-details__info,
            .dln-recipe-details__bottom-info {
              padding-right: 20px;
              padding-left: 20px;
            }
          }
          .dln-recipe-details__title-recipe {
            font-size: REM(28px);
          }
          .dln-recipe-details__bottom-info {
            margin-bottom: 34px;

            &-col:last-child {
              margin-bottom: 0;
            }
          }
          .dln-recipe-details.dln-recipe-details--inverted.dln-recipe-details--constrain {
            .dln-recipe-details__col:nth-of-type(2) {
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
        .dln-recipe__details {
          .dln-recipe__detail.dln-recipe__detail--preparationTime {
            height: 24px;
          }
        }
      }
    }
  }
}

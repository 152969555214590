.dln-delonghi {
  .dln-checkout.dln-checkout--editing {
    .dln-accordion {
      &--access-information {
        .dln-accordion__title {
          > .dln-icn {
            width: 17px;
            height: 16px;
            line-height: 16px;
            font-size: REM(17px);
            text-align: center;
          }
        }
      }

      &__content {
        border-top: none;
      }

      .dln-checkout__dataResult {
        > p {
          line-height: 18px;
          font-size: REM(14px);

          > a {
            @include fontSemibold;
            line-height: 18px;
            font-size: REM(14px);
            text-decoration: none;
          }
        }

        > .dln-btn {
          min-width: 350px;
          
          &--newShippingAddress,
          &[title='Proceed'] {
            min-width: 350px;
          }

          &--newShippingAddress {
            margin-bottom: 28px;
          }

          &[title='Proceed'] {
            margin-bottom: 10px;
          }
        }
      }
      .dln-checkout__formData {
        padding: 20px 20px 32px 20px;

        > p {
          line-height: 18px;
          font-size: REM(14px);
        }
      }
      &--shipping-method {
        .dln-checkout__dataResult {
          margin-bottom: 30px;

          > p {
            margin-bottom: 24px;

            .dln-checkout__formValue {
              @include fontBold;
              line-height: 18px;
              font-size: REM(14px);
            }
          }
        }

        .dln-checkout__formData {
          padding: 20px;
          margin-bottom: 0;
        }
      }
      &--payment {
        .dln-checkout__dataResult {
          margin-bottom: 20px;

          .dln-checkout__formData {
            width: calc(50% - 10px);
          }

          > .dln-btn {
            width: calc(50% - 10px);
            max-width: none;
            margin-top: 0;
          }
        }

        .dln-accordion__subTitle {
          display: inline-block;
        }

        .dln-checkout__formField {
          &--billingAddress {
            #dln-checkbox__checkboxbillingaddress--wrapper {
              .dln-checkbox__label {
                line-height: 24px;
                margin-left: 12px;
              }
            }

            > .dln-btn {
              width: calc(50% - 46px);
              max-width: none;
              margin: 0 0 0 36px;
            }
            
            .dln-checkout__formData {
              //width: calc(50% - 46px);
              margin: 20px 20px 20px 0px;

              &:nth-of-type(odd) {
                margin-left: 0px;
              }
            }

            .dln-btn {
              margin: 0;
            }
          }
        }
        .dln-accordion__content--submit {
          padding-bottom: 8px;

          > .dln-btn {
            width: calc(50% - 10px - 36px);
            max-width: none;
            margin: 0 0 0 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .dln-delonghi {
      .dln-checkout {
        &--editing {
          & .dln-checkout__content {
            & .dln-accordion {
              &--shipping-information {
                & .dln-checkout__dataResult {
                  & > .dln-btn {
                    width: 100% !important;
                    margin: 15px 0 15px;
                  }
                  & > .dln-btn[title='Proceed'] {
                    margin-bottom: 0 !important;
                  }
                }
              }
              &--shipping-method {
                & .dln-checkout__formData {
                  & > p:nth-child(2) .dln-checkout__formValue b {
                    @include fontBook;
                  }
                }
              }
              &--payment {
                .dln-checkout__dataResult {

                  > .dln-btn {
                    min-width: 100%;
                  }
        
                  .dln-checkout__formData {
                    width: 100%;
                  }
                }
              }
              &--editing {
                margin-bottom: 36px;

                .dln-accordion__title {
                  margin-bottom: 10px;
                }

                .dln-accordion__subTitle {
                  padding: 0 20px;
                }

                .dln-checkout__formGroup {
                  .dln-checkout__formField {
                    margin-bottom: 20px;
                  }

                  .dln-checkout__dataResult {
                    margin-bottom: 24px;

                    .dln-checkout__formData {
                      width: 100%;
                      margin: 0 0 24px 0;

                      & > p:last-child {
                        margin-bottom: 0;
                      }
                    }

                    & > .dln-btn {
                      width: 100%;
                    }
                  }
                }
                .dln-checkout__formField {
                  &--billingAddress {
                    margin-bottom: 12px;

                    & .dln-checkbox {
                      margin-bottom: 21px !important;
                    }

                    .dln-checkout__formData {
                      width: 100% !important;
                      margin: 0 0 24px 0 !important;
                    }

                    & > .dln-btn {
                      width: 100% !important;
                      margin: 0 !important;
                    }
                  }
                }
                
                .dln-accordion__content--submit {
                  padding: 0 10px !important;
                  margin-bottom: 0;

                  & > .dln-btn {
                    width: 100% !important;
                    margin: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
  }
}

@media only screen and (min-width: 900px) and (max-width: map-get($grid-breakpoints, xl)) {
    .dln-quickLinks--slider {
        & .dln-wrapper {
            width: calc(100% - 40px);
        }
    }
}

@media only screen and (min-width:map-get($grid-breakpoints, sm)) and (max-width:map-get($grid-breakpoints, md)) {
    .dln-quickLinks--slider {
        & .dln-wrapper {
            width: calc(100% - 20px);
        }
    }
}

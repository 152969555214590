@media only screen and (max-width:599px) {
    .dln-login-wishlist {
        .dln-popup__head {
            margin-bottom: 21px;
            &Title {
                font-size: 13px;
                line-height: 19px;
            }
        }
        .dln-loginForm__paragraph--mainTitle {
            text-align: left;
        }
    }
}
.dln-delonghi {
  .dln-terms {
    &__head,
    &__content {
      max-width: 80%;
      margin: 0 auto;
    }
    &__note {
      @include small;
      color: $brand-02;
      font-size: REM(16px);
      line-height: 1.5;
    }
    &__content {
      margin-bottom: 90px;
    }
    &__subTitle {
      @include fontMedium;
      font-size: REM(18px);
      line-height: 1.07;
    }
    &__paragraph {
      font-size: REM(14px);
      line-height: 1.3;
    }
    .dln-title--delonghi {
      font-size: REM(50px);
      @include fontMedium;
    }
  }
}

.dln-delonghi {
  .dln-search {
    .dln-popup__headTitle {
      @include fontMedium;
      font-size: REM(37px);
      line-height: 1;
      letter-spacing: -0.7px;
    }

    .dln-inputs {
      max-width: 100% !important;
    }

    &--noResults {
      &__subTitle {
        @include fontMedium;
        font-size: REM(21px);
        line-height: 1.12;
        padding-top: 0;
      }

      &__paragraph {
        font-size: REM(16px);
        line-height: 1.5;
      }
    }

   .dln-search-result {
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 6px;
      }

      &__category {
        position: unset;
      }

      &__priceWrapper {
        position: unset;

        & .dln-price:not(.dln-price--oldPrice) {
            color: var(--brand-04);
            font-size: REM(18px);
        }

        .dln-price--oldPrice {
          font-size: REM(14px);
        }

        & .prices {
            margin-bottom: 0;
        }
      }
    }

    @media only screen and (max-width: 599px) {
      .dln-search--noResults__subTitle {
        font-size: REM(18px);
      }

      /*  .dln-inputs.dln-inputs--searchBar.dln-inputs--rightIcon.dln-inputs--validate.dln-inputs--validated.dln-inputs--active{
                margin-bottom: 10px !important;
            } */

      .dln-search-result {
        width: 100%;
      }

      .dln-search-results__view-all {
        font-size: REM(14px);
      }

      .simple-list__title {
        font-size: REM(13px);
      }

      .simple-list__item {
        b {
          font-size: REM(16px);
        }
        font-size: REM(16px);
      }
    }
  }
}

[hidden] {
  display: none!important;
}

// player paused state after video has been played, does not cover any focusable elements for mouse users
.plyr--paused {
  .plyr__poster {
    width: auto !important;
  }
}

// player paused state on initial page load to keep poster image intact, before the video has been played
.plyr--stopped.plyr__poster-enabled {
  .plyr__poster {
    width: 100% !important;
    background-size: cover;
  }
}
.dln-kenwood {
    .dln-checkout {
        &--editing {
            .dln-checkout__dataResult {
                margin-bottom: 50px;
            }
            
            .dln-checkout__content {
                .dln-checkout__formData {
                    border: 1px solid $ui-01;
                    padding: 20px;

                    &Head {
                        .dln-radioBtn {
                            &__lbl {
                                @include fontRoman;
                                color: $brand-04;
                                padding-left: 33px;
                                font-size: 15px;
                                line-height: 22px;
                            }
                        }
                        .dln-btn--link {
                            top: 0;
                            right: 0;
                            position: relative;

                            .dln-btn__lbl {
                                @include fontBold;
                                margin-bottom: 0;
                            }
                        }
                    }

                    &--selected {
                        border: 1px solid $ui-07;
                    }
                }

                .dln-btn--stroked {
                    border: 1px solid $brand-05;
                }

                .dln-btn--editing {
                    margin-bottom: 0;
                    min-height: unset;
                    height: 45px;
                    padding: 0 20px;
                    justify-content: center;
                    text-transform: none;
                    font-size: 18px;
                }
            }
        }

        .dln-accordion--payment {
            margin-bottom: 70px;

            .dln-checkout__formData {
                .dln-checkServiceBox__cardItem {
                    .dln-checkServiceBox__cardImg[alt="Visa"] {
                        width: 42px;
                        height: 14px;
                    }
                }

                p:first-of-type .dln-checkout__formValue {
                    &:first-of-type {
                        // @include fontBold;
                    }
                }
            }

            .dln-checkout__formField--billingAddress {
                .dln-checkout__formData {
                    .dln-btn--link {
                        .dln-icn {
                        }
                    }
                }
            }
        }

        .dln-accordion.dln-accordion--payment.dln-accordion--showInfo.dln-accordion--editing{
            .dln-accordion__subTitle{
                display: flex;
                margin-top: 8px;
            }
        }
    }
}

@media only screen and (max-width:599px) {
    .dln-kenwood {
        .dln-checkout {
            &--editing {
                .dln-checkout__content {
                    .dln-accordion {
                        .dln-checkout__dataResult {
                            > p {
                                padding-left: 0px !important;
                            }
                        }
                        &--shipping-information {
                            .dln-btn--showOtherAddress {
                                display: inline-block;
                                margin: 0 auto 30px auto;

                                &::after {
                                    @include fontIcon;
                                    content: 'u';
                                    margin-left: 7px;
                                    display: inline-block;
                                }
                            }
                        }
                        &--shipping-method {
                            .dln-checkout__dataResult {
                                .dln-checkout__formData {
                                    &:last-of-type,
                                    &:only-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }

                        &--payment {
                            .dln-checkout__dataResult {
            
                              > .dln-btn {
                                min-width: 100%;
                              }
                    
                              .dln-checkout__formData {
                                width: 100%;
                              }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dln-vertMenu {
    width: 100%;
    position: relative;

    &__container {
        width: 100%;
        background-color: $brand-03;

        & .dln-icn {
            display: none;
            cursor: pointer;
            font-size: 12px;
        }
    }
    // &__list {
    //     &.-activeList {
    //         background: yellow;
    //     }
    // }
    &__li {
        font-size: 14px;
        color: $brand-04;
        text-decoration: none;
        position: relative;
        overflow: hidden;
        transition: .2s;

        a {
            font-size: 14px;
            color: $brand-04;
            text-decoration: none;
            display: block;
            transition: .2s;
        }
        &::before {
            content: "";
            position: absolute;
            left: -4px;
            top: 0;
            background-color: $brand-01;
            width: 2px;
            height: 100%;
            transition: .3s;
        }
        &:not(.dln-vertMenu__li--heading) {
            border-left: 1px solid $ui-01;

            a {
                padding: 16px;
            }
        }
        &--heading {
            padding: 16px 16px 16px 0;
            color: $ui-04;
            font-size: 14px;
            @include fontBold;
        }
        &--active {
            @include fontBold;
            border-left: 1px solid $brand-01 !important;

            a {
                @include fontBold;
            }
            &::before {
                left: 0;
            }
        }
        &Heading {
            color: $ui-04;
            font-size: 14px;
            @include fontBold;
        }
    }
    &--open {
        & .dln-vertMenu__list {
            margin-bottom: 40px;
        }
    }
    // &:not(.dln-vertMenu--open) {
    //     & .dln-vertMenu__list {
    //         &:not(.-activeList) {
    //             margin-bottom: 0;
    //         }
    //         &.-activeList {
    //             margin-bottom: 40px;
    //         }
    //     }
    // }
}

@media only screen and (max-width:599px) {
    .dln-profile {
        .dln-profileCard {
            display: none;
        }
        &--01 {

            & .dln-profile__sectionHead {
                &Title {
                    &--smallMobile {
                        font-size: 20px;
                        max-width: 50%;
                    }
                }
                & .dln-btn--link {
                    height: 16px;
                    line-height: 16px;
                    padding: 4px 0 0 0;
                }
            }
            & .dln-wishProd {
                border: none;
                // width: 50%;
                padding: 0 !important;

                &__imgWrap {
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                    margin: 0 0 10px 0;
                }
                &__img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                }
                &__data {
                    width: 100%;

                    .dln-btnTextIcon.dln-btnTextIcon--profile{
                        max-width: 100%;

                        .dln-btnTextIcon__text{
                            margin-top: 3px;
                        }
                    }
                }
                &__name {
                    font-weight: 400;
                    border: none;
                    padding: 0;
                }
            }
            & .dln-profile__section--recipes {
                & .dln-recipe {
                    width: calc(50% - 5px);
                    border: none;
                    padding: 0 !important;
                    margin: 0 20px 10px 0;

                    &:nth-last-of-type(2),
                    &:nth-last-of-type(4) {
                        margin: 0 0 10px 0;
                    }
                    &:nth-last-of-type(3) {
                        margin: 0 20px 10px 0;
                    }
                    &:nth-child(even) {
                        margin-right: 0;
                    }
                    /* margin: 0 10px 10px 0 !important;

                    &:nth-child(2n+2) {
                        margin: 0 0 10px 0 !important;
                    }
                    &:only-child {
                        margin: 0 auto 0 0 !important;
                    }
                    &:last-child {
                        margin: 0 0 0 0 !important;

                        &:nth-child(odd) {
                            margin: 0 auto 0 0 !important;
                        }
                    } */
                    //    &:nth-lat-of-type(2) {
                    //        margin: 0
                    //    }
                }
                & .swiper-pagination {
                    padding: 0;
                }
            }
        }
        & .dln-wrapper--carousel {
            & .swiper-pagination {
                margin: 32px auto 0;
            }
        }
        &__itemsCarousel {
            &Title {
                margin: 0 0 24px;
            }
        }
    }
}
